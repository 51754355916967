@font-face {
  font-family: "EuclidCircularA";
  src: local("EuclidCircularA-Full"),
    url(./fonts/EuclidCircularA-Full.woff) format("woff");
}

.network-logo {
  height: 5rem;
  margin-top: 1rem;
}

.network-logo-position {
margin-left: -1rem;
}

.compare-logo-position {
  margin-left: -1rem;
}

ul li {
  border-bottom: 0rem solid transparent !important;
}

.inline-text {
  display: contents;
  text-align: center!important;
}

.white {
  color: white!important;
}

.quiz-hr {
    border: 1.5px solid var(--hr);
    border-radius: 5px;
    width: 90%;
    margin: auto;
    box-shadow: 0 0 10px 3px var(--hover-purple);
    position: relative;
    margin-bottom: 1rem;
}

.flashcards-links {
  margin-top: 140px!important;
}
.suggestions-list {
  list-style-type: none;
  padding: 0;
  margin: 0;
  border: 1px solid #ccc;
  border-top: none;
  position: absolute;
  background-color: #fff;
  box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.1);
  width: 100%;
}

.suggestions-list li {
  padding: 8px;
  cursor: pointer;
}

.suggestions-list li:hover {
  background-color: #f2f2f2;
}

.expand {
  cursor: pointer;
}

.expand:hover {
  cursor: pointer;
  font-weight: 700;
  }

  .advocate-details {
    font-size: 1.5rem;
  }

:root {
  --primary-purple: #0c0b23;
  --secondary-purple: #53466c;
  --hover-purple: #7a6695;
  --text-purple: #d8d2e4;
  --transparent: rgba(10, 8, 8, 0);
  --background: linear-gradient(90deg, rgba(16,3,75,1) 0%, rgba(33,11,94,1) 50%, rgba(40,16,104,1) 70%, rgba(43,17,108,1) 90%, rgba(43,17,108,1) 100%)!important;
  --hr: #d8d2e4;
}

::-webkit-scrollbar {
  width: 10px;
  background: transparent;
}

::-webkit-scrollbar-track {
  box-shadow: inset 0 0 5px var(--transparent);
}

::-webkit-scrollbar-thumb {
  box-shadow: inset 0 0 5px var(--text-purple);
  border-radius: 20px;
}

/* Flipcards */

.flip-card {
  perspective: 1000px;
  width: 100%;
  height: 300px;
}

.flip-card-inner {
  position: relative;
  width: 100%;
  height: 100%;
  text-align: center;
  transition: transform 0.6s;
  transform-style: preserve-3d;
}

.flip-card.flipped .flip-card-inner {
  transform: rotateY(180deg);
}

.flip-card-front,
.flip-card-back {
  position: absolute;
  width: 100%;
  height: 100%;
  backface-visibility: hidden;
}

.flip-card-front {
  background-color: #f2f2f2;
  color: #333;
}

.flip-card-back {
  background-color: #333;
  color: #fff;
  transform: rotateY(180deg);
}

.card-content {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
  padding: 20px;
  font-size: 18px;
}

.flashcards-wrapper {
  display: flex;
  flex-direction: column;
  min-height: 100vh;
  background-color: #f9f9f9;
}

.flashcards-container {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.flashcards-controls {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 20px;
}

.flashcards-instructions {
  margin-right: 10px;
  font-size: 18px;
}

.flashcards-button {
  padding: 10px 20px;
  margin: 0 5px;
  font-size: 16px;
  background-color: #333;
  color: #fff;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  margin-bottom: 2rem;
  margin-top: 1rem;
}

.flashcards-button:hover {
  padding: 10px 20px;
  margin: 0 5px;
  font-size: 16px;
  background-color: #6D2DA6;
  color: white;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  margin-bottom: 2rem;
  margin-top: 1rem;
}

.flashcards-button:disabled {
  opacity: 0.5;
  cursor: not-allowed;
  margin-bottom: 2rem;
  margin-top: 1rem;
}

.flashcards-button:active {
  background-color: #52108d;
  margin-bottom: 2rem;
  margin-top: 1rem;
}

.flip-card-container {
  width: 100%;
  max-width: 400px;
  height: 300px;
  margin: 0 auto;
}

.flip-card.flipped .flip-card-inner {
  transform: rotateY(180deg);
}

/* flashcard stop */

/* Quiz start */
.slider-container {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.slider-label {
  display: inline-block;
  cursor: pointer;
  padding-left: 10px;
}

.slider-label-text {
  font-size: 14px;
  color: #000000;
  margin-left: 6px;
}

.toggle-switch-container {
  margin-top: 10px;
}

.wrong-answer-popup-wrapper {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: rgba(0, 0, 0, 0.5);
  z-index: 999; /* Adjust the z-index as needed */
}

.wrong-answer-popup {
  max-width: 400px;
  padding: 20px;
  background-color: #fff;
  border-radius: 8px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2);
}

.wrong-answer-popup.show {
  opacity: 1;
  visibility: visible;
  display: flex;
  justify-content: center; 
  align-items: center; 
  position: fixed;
  width: 75%; 
  height: 50%;
  background-color: rgba(0, 0, 0, 0.95); 
}

.popup-content {
  text-align: center;
}

.wrong-answer-message {
  font-size: 16px;
  margin-bottom: 20px;
}

.popup-button {
  padding: 10px 20px;
  color: #fff;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  font-size: 16px;
  background-color: #333;
}

.popup-button:hover {
  padding: 10px 20px;
  margin: 0 5px;
  font-size: 16px;
  background-color: #6D2DA6;
  color: white;
  border: none;
  border-radius: 4px;
  cursor: pointer;
}

.popup-button:focus {
  outline: none;
}

.popup-button:active {
  background-color: #52108d;
}

.close-button {
  background-color: transparent;
  border: none;
  color: #fff;
  font-size: 16px;
  cursor: pointer;
}

.close-button:focus {
  outline: none;
}

.quiz-button-position {
  /* remove for mobile */
  margin-top: 0.25rem;
  margin-left: -3.5rem;
}

.quiz-title {
  color: #d8d2e4;
  top: 0;
  margin-top: -10px;
}

.quiz-position {
  padding-top: 50px;
}
.quiz-subtitle {
  color: #d8d2e4;
  width: 90%;
  text-align: center;
  margin: auto;
}

.quiz-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin: auto;
  margin-top: 90px;
  width: 90%;
}

.final-exam {
  margin-top: 120px;
}

.question-container{
  font-size: 1.5rem;
    text-align: center;
    color: #d8d2e4;
    max-width: 100%;
    padding: 0 10px;
}


.quiz-header {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-bottom: 30px;
}

.quiz-question-container {
  display: flex;
  /* delete for mobile */
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 500px;
  margin-top: -40px;
}

.quiz-question {
  font-size: 1.3rem;
  text-align: center;
  color: #d8d2e4;
  /* remove for mobile */
  margin-top: 0rem;
  /* remove for mobile */
}

.quiz-button-position {
  margin-top: 0.25rem;
  margin-left: -3.5rem;
  padding-bottom: 2rem;
}

.final-exam-question {
    font-size: 1.3rem;
    text-align: center;
    color: #d8d2e4;
    /* remove for mobile */
    margin-top: 0rem;
    /* remove for mobile */
}


.quiz-answer-form {
  /* display: flex; */
  columns: 2!important;
  width: 200%;
  /* Remove for mobile */
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-top: 0rem;
}

.final-exam-answer-form {
  /* display: flex; */
  columns: 2!important;
  width: 200%;
  /* Remove for mobile */
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-top: 0rem;
}

.form-check-label {
  font-size: 20px;
  /* remove for mobile */
  height: 7rem;
  width: 100%;
  /* remove for mobile */
  text-align: center;
}

.form-check {
  display: flex;
  align-items: center;
  margin-top: 10px;
}

.form-check-input {
  margin-right: 10px;
  width: 20px;
  height: 20px;
}

.btn-primary {
  background-color: #6D2DA6;
  border: none;
  margin-top: 30px;
}

.btn-primary:hover {
  background-color: #5e0da4;
}

.quiz-footer {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 20px;
}

.quiz-score {
  margin-top: 5px;
  font-size: 20px;
  color: #d8d2e4;
  margin-left: -20px;
}

.quiz-timer {
  margin-top: -20px;
  font-size: 1.2rem;
  color: #d8d2e4;
}

.switch-align {
  display: flex;
  align-items: end;
}

.toggle-switch-wrapper {
  display: flex;
  align-items: center;
  margin-top: -10px;
}

.quiz-timer-label,
.quiz-score-label {
  font-weight: bold;
  color: #d8d2e4
}


.quiz-status {
  font-size: 20px;
  color: #d8d2e4;
}

.quiz-option {
  font-weight: bold;
  color: #d8d2e4;
  display: contents;
  max-width: 80%;
}

.quiz-results-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-top: 50px;
}

.quiz-results-header {
  font-size: 28px;
  margin-bottom: 30px;
  color: #d8d2e4;
}

.quiz-results-text {
  font-size: 20px;
  text-align: center;
  margin-bottom: 30px;
  color: #d8d2e4;
}

.quiz-form-label, .flashcards-instructions {
  color: #d8d2e4;
}

.quiz-form-container {
  display: flex;
  justify-content: center;
  align-items: center!important;
  text-align: center!important;
}

.quiz-form-input {
  width: 200px!important;
}

.form-group {
  display: flex;
  justify-content: center;
  align-items: center!important;
  text-align: center!important;
}

.Quiz-button a {
  position: relative;
  display: inline-block;
  padding: 10px 20px;
  color: green;
  font-size: 1.5rem;
  font-weight: 700;
  text-decoration: none;
  overflow: hidden;
  transition: .40s;
  margin-top: 00px;
  letter-spacing: 4px;
  max-width: 90%;
} 

.Quiz-button a:hover {
  background: transparent;
  color: #53466C;
  border-radius: 5px;
  -webkit-box-reflect:below 0px linear-gradient(transparent, #0005);
}

.Quiz-button a span {
  position: absolute;
  display: block;
}

.Quiz-button a:hover span:nth-child(1) {
  top: 0;
  left: -100%;
  width: 100%;
  height: 2px;
  background: linear-gradient(90deg, transparent, green);
  animation: btn-anim1 1s linear infinite;
}

.Quiz-button a:hover span:nth-child(2) {
  top: -100%;
  right: 0;
  width: 2px;
  height: 100%;
  background: linear-gradient(180deg, transparent ,green);
  animation: btn-anim2 1s linear infinite;
  animation-delay: .25s
}

.Quiz-button a:hover span:nth-child(3) {
  bottom: 0;
  right: -100%;
  width: 100%;
  height: 2px;
  background: linear-gradient(270deg, transparent, green);
  animation: btn-anim3 1s linear infinite;
  animation-delay: .5s
}

.Quiz-button a:hover span:nth-child(4) {
  bottom: -100%;
  left: 0;
  width: 2px;
  height: 100%;
  background: linear-gradient(360deg, transparent, green);
  animation: btn-anim4 1s linear infinite;
  animation-delay: .75s
}

.quiz-option input[type="radio"] {
  position: absolute;
  opacity: 0;
  pointer-events: none;
}

.quiz-option label {
  display: inline-block;
  margin: 10px 10px 10px 0px;
  padding: 10px;
  border: 2px solid #ccc;
  border-radius: 5px;
  cursor: pointer;
  font-size: 1rem;
}

.quiz-option label:hover {
  background-color: rgba(124, 79, 162, 0.25);
}

.quiz-option label.selected {
  background-color: #6D2DA6;
  color: #fff;
  border-color: #6D2DA6;
}

.toggle-switch {
  padding-left: 10px;
  padding-right: 10px;
}

/* Quiz stop */

/* Documentation Finder */


.delegator-dash {
  padding-top: 10rem;
}
.curator-dash {
  padding-top: 10rem;
}
.indexer-dash {
  padding-top: 2rem;
}

/* Chatbot Bottom Buttons Start */
.new-button a {
  position: relative;
  display: inline-block;
  padding: 10px 20px;
  color: #0C0B23;
  font-size: 1rem;
  text-decoration: none;
  text-transform: uppercase;
  overflow: hidden;
  transition: .40s;
  margin-top: 40px;
  letter-spacing: 4px;
  max-width: 90%;
}

.ENG-button a, .GER-button a, .ESP-button a, .FRA-button a, .TUR-button a, .URD-button a, .UKR-button a, .HIN-button a {
  position: relative;
  display: inline-block;
  padding: 10px 20px;
  color: #0C0B23;
  font-size: 1.5rem;
  font-weight: 700;
  text-decoration: none;
  overflow: hidden;
  transition: .40s;
  margin-top: 00px;
  letter-spacing: 4px;
  max-width: 90%;
} 

.new-button a:hover {
  background: #22143B;
  color: #fff;
  border-radius: 5px;
  box-shadow: 0 0 5px #22143B,
              0 0 15px #22143B,
              0 0 25px #22143B,
              0 0 50px #22143B;
}

.ENG-button a:hover, .GER-button a:hover, .ESP-button a:hover, .FRA-button a:hover, .TUR-button a:hover, .URD-button a:hover, .UKR-button a:hover, .HIN-button a:hover {
  background: transparent;
  color: #53466C;
  border-radius: 5px;
  -webkit-box-reflect:below 0px linear-gradient(transparent, #0005);
}

.new-button a span, .ENG-button a span, .GER-button a span, .ESP-button a span, .FRA-button a span, .TUR-button a span, .URD-button a span, .UKR-button a span, .HIN-button a span {
  position: absolute;
  display: block;
}

.new-button a span:nth-child(1) {
  top: 0;
  left: -100%;
  width: 100%;
  height: 2px;
  background: linear-gradient(90deg, transparent, #0C0B23);
  animation: btn-anim1 1s linear infinite;
}

@keyframes btn-anim1 {
  0% {
    left: -100%;
  }
  50%,100% {
    left: 100%;
  }
}

.new-button a span:nth-child(2) {
  top: -100%;
  right: 0;
  width: 2px;
  height: 100%;
  background: linear-gradient(180deg, transparent, #0C0B23);
  animation: btn-anim2 1s linear infinite;
  animation-delay: .25s
}

@keyframes btn-anim2 {
  0% {
    top: -100%;
  }
  50%,100% {
    top: 100%;
  }
}

.new-button a span:nth-child(3) {
  bottom: 0;
  right: -100%;
  width: 100%;
  height: 2px;
  background: linear-gradient(270deg, transparent, #0C0B23);
  animation: btn-anim3 1s linear infinite;
  animation-delay: .5s
}

@keyframes btn-anim3 {
  0% {
    right: -100%;
  }
  50%,100% {
    right: 100%;
  }
}

.new-button a span:nth-child(4) {
  bottom: -100%;
  left: 0;
  width: 2px;
  height: 100%;
  background: linear-gradient(360deg, transparent, #0C0B23);
  animation: btn-anim4 1s linear infinite;
  animation-delay: .75s
}

@keyframes btn-anim4 {
  0% {
    bottom: -100%;
  }
  50%,100% {
    bottom: 100%;
  }
}

.ENG-button a:hover span:nth-child(1) {
  top: 0;
  left: -100%;
  width: 100%;
  height: 2px;
  background: linear-gradient(90deg, transparent, #0C0B23);
  animation: btn-anim1 1s linear infinite;
}

.ENG-button a:hover span:nth-child(2) {
  top: -100%;
  right: 0;
  width: 2px;
  height: 100%;
  background: linear-gradient(180deg, transparent ,#0C0B23);
  animation: btn-anim2 1s linear infinite;
  animation-delay: .25s
}

.ENG-button a:hover span:nth-child(3) {
  bottom: 0;
  right: -100%;
  width: 100%;
  height: 2px;
  background: linear-gradient(270deg, transparent, #0C0B23);
  animation: btn-anim3 1s linear infinite;
  animation-delay: .5s
}

.ENG-button a:hover span:nth-child(4) {
  bottom: -100%;
  left: 0;
  width: 2px;
  height: 100%;
  background: linear-gradient(360deg, transparent, #0C0B23);
  animation: btn-anim4 1s linear infinite;
  animation-delay: .75s
}

.GER-button a:hover span:nth-child(1) {
  top: 0;
  left: -100%;
  width: 100%;
  height: 2px;
  background: linear-gradient(90deg, transparent, black);
  animation: btn-anim1 1s linear infinite;
}

.GER-button a:hover span:nth-child(2) {
  top: -100%;
  right: 0;
  width: 2px;
  height: 100%;
  background: linear-gradient(180deg, transparent ,black, red, yellow);
  animation: btn-anim2 1s linear infinite;
  animation-delay: .25s
}

.GER-button a:hover span:nth-child(3) {
  bottom: 0;
  right: -100%;
  width: 100%;
  height: 2px;
  background: linear-gradient(270deg, transparent, yellow);
  animation: btn-anim3 1s linear infinite;
  animation-delay: .5s
}

.GER-button a:hover span:nth-child(4) {
  bottom: -100%;
  left: 0;
  width: 2px;
  height: 100%;
  background: linear-gradient(360deg, transparent, yellow, red, black);
  animation: btn-anim4 1s linear infinite;
  animation-delay: .75s
}

.ESP-button a:hover span:nth-child(1) {
  top: 0;
  left: -100%;
  width: 100%;
  height: 2px;
  background: linear-gradient(90deg, transparent, red);
  animation: btn-anim1 1s linear infinite;
}

.ESP-button a:hover span:nth-child(2) {
  top: -100%;
  right: 0;
  width: 2px;
  height: 100%;
  background: linear-gradient(180deg, transparent , red, yellow, red);
  animation: btn-anim2 1s linear infinite;
  animation-delay: .25s
}

.ESP-button a:hover span:nth-child(3) {
  bottom: 0;
  right: -100%;
  width: 100%;
  height: 2px;
  background: linear-gradient(270deg, transparent, red);
  animation: btn-anim3 1s linear infinite;
  animation-delay: .5s
}

.ESP-button a:hover span:nth-child(4) {
  bottom: -100%;
  left: 0;
  width: 2px;
  height: 100%;
  background: linear-gradient(360deg, transparent, red, yellow, red);
  animation: btn-anim4 1s linear infinite;
  animation-delay: .75s
}

.FRA-button a:hover span:nth-child(1) {
  top: 0;
  left: -100%;
  width: 100%;
  height: 2px;
  background: linear-gradient(90deg, transparent, #002551, white, red);
  animation: btn-anim1 1s linear infinite;
}

.FRA-button a:hover span:nth-child(2) {
  top: -100%;
  right: 0;
  width: 2px;
  height: 100%;
  background: linear-gradient(180deg, transparent , red);
  animation: btn-anim2 1s linear infinite;
  animation-delay: .25s
}

.FRA-button a:hover span:nth-child(3) {
  bottom: 0;
  right: -100%;
  width: 100%;
  height: 2px;
  background: linear-gradient(270deg, transparent, red, white, #002551);
  animation: btn-anim3 1s linear infinite;
  animation-delay: .5s
}

.FRA-button a:hover span:nth-child(4) {
  bottom: -100%;
  left: 0;
  width: 2px;
  height: 100%;
  background: linear-gradient(360deg, transparent, #002551);
  animation: btn-anim4 1s linear infinite;
  animation-delay: .75s
}

.TUR-button a:hover span:nth-child(1) {
  top: 0;
  left: -100%;
  width: 100%;
  height: 2px;
  background: linear-gradient(90deg, transparent, #DC0A16);
  animation: btn-anim1 1s linear infinite;
}

.TUR-button a:hover span:nth-child(2) {
  top: -100%;
  right: 0;
  width: 2px;
  height: 100%;
  background: linear-gradient(180deg, transparent ,  #DC0A16);
  animation: btn-anim2 1s linear infinite;
  animation-delay: .25s
}

.TUR-button a:hover span:nth-child(3) {
  bottom: 0;
  right: -100%;
  width: 100%;
  height: 2px;
  background: linear-gradient(270deg, transparent,  #DC0A16);
  animation: btn-anim3 1s linear infinite;
  animation-delay: .5s
}

.TUR-button a:hover span:nth-child(4) {
  bottom: -100%;
  left: 0;
  width: 2px;
  height: 100%;
  background: linear-gradient(360deg, transparent,  #DC0A16);
  animation: btn-anim4 1s linear infinite;
  animation-delay: .75s
}

.URD-button a:hover span:nth-child(1) {
  top: 0;
  left: -100%;
  width: 100%;
  height: 2px;
  background: linear-gradient(90deg, transparent, white, white, green, green, green);
  animation: btn-anim1 1s linear infinite;
}

.URD-button a:hover span:nth-child(2) {
  top: -100%;
  right: 0;
  width: 2px;
  height: 100%;
  background: linear-gradient(180deg, transparent ,  green);
  animation: btn-anim2 1s linear infinite;
  animation-delay: .25s
}

.URD-button a:hover span:nth-child(3) {
  bottom: 0;
  right: -100%;
  width: 100%;
  height: 2px;
  background: linear-gradient(270deg, transparent,  green, green, green, white, white);
  animation: btn-anim3 1s linear infinite;
  animation-delay: .5s
}

.URD-button a:hover span:nth-child(4) {
  bottom: -100%;
  left: 0;
  width: 2px;
  height: 100%;
  background: linear-gradient(360deg, transparent, white);
  animation: btn-anim4 1s linear infinite;
  animation-delay: .75s
}

.UKR-button a:hover span:nth-child(1) {
  top: 0;
  left: -100%;
  width: 100%;
  height: 2px;
  background: linear-gradient(90deg, transparent, #0054B1);
  animation: btn-anim1 1s linear infinite;
}

.UKR-button a:hover span:nth-child(2) {
  top: -100%;
  right: 0;
  width: 2px;
  height: 100%;
  background: linear-gradient(180deg, transparent ,  #0054B1, #0054B1, #FFD800, #FFD800);
  animation: btn-anim2 1s linear infinite;
  animation-delay: .25s
}

.UKR-button a:hover span:nth-child(3) {
  bottom: 0;
  right: -100%;
  width: 100%;
  height: 2px;
  background: linear-gradient(270deg, transparent,  #FFD800);
  animation: btn-anim3 1s linear infinite;
  animation-delay: .5s
}

.UKR-button a:hover span:nth-child(4) {
  bottom: -100%;
  left: 0;
  width: 2px;
  height: 100%;
  background: linear-gradient(360deg, transparent, #FFD800, #FFD800, #0054B1, #0054B1);
  animation: btn-anim4 1s linear infinite;
  animation-delay: .75s
}

.HIN-button a:hover span:nth-child(1) {
  top: 0;
  left: -100%;
  width: 100%;
  height: 2px;
  background: linear-gradient(90deg, transparent, #F79431);
  animation: btn-anim1 1s linear infinite;
}

.HIN-button a:hover span:nth-child(2) {
  top: -100%;
  right: 0;
  width: 2px;
  height: 100%;
  background: linear-gradient(180deg, transparent ,  #F79431, #F79431, white, white, #118407, #118407);
  animation: btn-anim2 1s linear infinite;
  animation-delay: .25s
}

.HIN-button a:hover span:nth-child(3) {
  bottom: 0;
  right: -100%;
  width: 100%;
  height: 2px;
  background: linear-gradient(270deg, transparent,  #118407);
  animation: btn-anim3 1s linear infinite;
  animation-delay: .5s
}

.HIN-button a:hover span:nth-child(4) {
  bottom: -100%;
  left: 0;
  width: 2px;
  height: 100%;
  background: linear-gradient(360deg, transparent, #118407, #118407, white, white, #F79431, #F79431);
  animation: btn-anim4 1s linear infinite;
  animation-delay: .75s
}
/* Chatbot Bottom Buttons End */
.home-link {
  letter-spacing: 0.0375rem;
    font-weight: 400;
    color: var(--text-purple);
    text-align: center;
    text-shadow: 2.5px 2.5px 4px var(--hover-purple);
}
.home-link:link {
  letter-spacing: 0.0375rem;
    font-weight: 400;
    color: var(--text-purple);
    text-align: center;
    text-shadow: 2.5px 2.5px 4px var(--hover-purple);
  cursor: pointer;
}
.home-link:visited {
  letter-spacing: 0.0375rem;
    font-weight: 400;
    color: var(--text-purple);
    text-align: center;
    text-shadow: 2.5px 2.5px 4px var(--hover-purple);
  cursor: pointer;
}
.home-link.active,
.home-link:focus {
  letter-spacing: 0.0375rem;
    font-weight: 400;
    color: var(--text-purple);
    text-align: center;
    text-shadow: 2.5px 2.5px 4px var(--hover-purple);
  cursor: pointer;
}
.home-link:hover {
  letter-spacing: 0.0375rem;
  font-weight: 400;
  color: grey;
  text-align: center;
  text-shadow: 2.5px 2.5px 4px var(--hover-purple);
  transform: scale(1.1);
  cursor: pointer;
}

.community-logo{
  width: 90%;
  margin-top: 65%!important;
  margin-bottom: 2rem;
}

.community-logo-div {
  text-align: center!important;
  margin: auto!important;
}

body {
  margin-top: 80px;
  font-size: 16px;
  position: relative;
  overflow: overlay;
  background: linear-gradient(90deg, rgba(16,3,75,1) 0%, rgba(33,11,94,1) 50%, rgba(40,16,104,1) 70%, rgba(43,17,108,1) 90%, rgba(43,17,108,1) 100%)!important;
  font-family: "Euclid Circular A", "Rubik", sans-serif;
  src: local("EuclidCircularA-Full"),
  url(./fonts/EuclidCircularA-Full.woff) format("woff");
}
input, select { font-size: 100%; }
.error {
  color: white!important;
  text-align: center;
}

.GRTDailyTransactions {
  background-image: radial-gradient(#1B0D33,#D8D2E4);
  margin-bottom: -40px!important;
}
html {
  background: linear-gradient(90deg, rgba(16,3,75,1) 0%, rgba(33,11,94,1) 50%, rgba(40,16,104,1) 70%, rgba(43,17,108,1) 90%, rgba(43,17,108,1) 100%)!important;
}
#root > div > div > div > div > div.react-chatbot-kit-chat-message-container > div:nth-child(3) > div.react-chatbot-kit-chat-bot-message > span > a > div {
  margin-left: 6px!important; 
}
#root > div > div > div > div > div.react-chatbot-kit-chat-message-container > div:nth-child(1) {
  margin-top: 20px!important;
}
#root > div > div > div > div > div.react-chatbot-kit-chat-header {
  padding-left: 1rem!important;
}
#more {display: none;}
.chatbot-footer {
  margin-bottom: 0!important;
  padding-bottom: 0!important;
}
.GRTDailyTransactions {
  background-color: var(--text-purple)!important;
}
.HoldersGraph {
  background-image: linear-gradient(90deg, #1B0D33, #D8D2E4, #D8D2E4,#D8D2E4, #D8D2E4,#D8D2E4,#D8D2E4,#D8D2E4,#D8D2E4,#D8D2E4,#D8D2E4,#D8D2E4,#D8D2E4,#D8D2E4,#D8D2E4,#D8D2E4,#D8D2E4,#D8D2E4)
}
.HoldersGraphBottom {
  background-image: linear-gradient(90deg, #1B0D33, #D8D2E4,#D8D2E4,#D8D2E4,#D8D2E4,#D8D2E4,#D8D2E4,#D8D2E4,#D8D2E4,#D8D2E4,#D8D2E4,#D8D2E4,#D8D2E4,#D8D2E4,#D8D2E4,#D8D2E4,#D8D2E4,#D8D2E4, #1B0D33);
}
.admin-mobile-center {
  text-align: center;
  margin: auto;
  margin-top: 1rem!important;
}
/* Chatbot Links start */
.cla{
  color: var(--text-purple);
}
.cla:link{
  color: var(--text-purple);
  cursor: pointer;
}
.cla:visited{
  color: var(--text-purple);
  cursor: pointer;
}
.cla.active, .cla:focus {
  border-bottom: 0rem solid var(--text-purple);
  cursor: pointer;
}
.cla:hover{
  color: var(--hover-purple);
  transform: scale(1.05);
  cursor: pointer;
}

.chatbot-button {
  color: var(--secondary-purple);
  margin-top: 0.5rem;
  margin-bottom: 0.5rem;
  margin-left: 0.5rem;
  margin-right: 0.5rem;
}
.chatbot-button:link {
  color: var(--secondary-purple);
  cursor: pointer;
}
.chatbot-button:visited {
  color: var(--secondary-purple);
  cursor: pointer;
}
.chatbot-button.active, chatbot-button:focus {
  border-bottom: 0rem solid var(--secondary-purple);;
  cursor: pointer;
}
.chatbot-button:hover {
  color: var(--background);
  transform: scale(1.05);
  cursor: pointer;
}

.chatbot-link-button {
  color: var(--secondary-purple);
}
.chatbot-link-button:link {
  color: var(--secondary-purple);
  cursor: pointer;
}
.chatbot-link-button:visited {
  color: var(--secondary-purple);
  cursor: pointer;
}
.chatbot-link-button.active, chatbot-link-button:focus {
  border-bottom: 0rem solid var(--secondary-purple);;
  cursor: pointer;
}
.chatbot-link-button:hover {
  color: var(--hr);
  transform: scale(1.05);
  cursor: pointer;
}
.graphtronauts-link {
  color: var(--hr);
  z-index: 999;
  position: absolute;
  display: inline;
  margin-left: 0.5rem;
}
.graphtronauts-link:link {
  color: var(--hr);
  cursor: pointer;
}
.graphtronauts-link:visited {
  color: var(--hr);
  cursor: pointer;
}
.graphtronauts-link.active, graphtronauts-link:focus {
  border-bottom: 0rem solid var(--hr);;
  cursor: pointer;
}
.graphtronauts-link:hover {
  color: var(--hr);
  transform: scale(1.025);
  cursor: pointer;
}
.chatbot-link {
  color: var(--secondary-purple);
}
.chatbot-link:link {
  color: var(--secondary-purple);
  cursor: pointer;
}
.chatbot-link:visited {
  color: var(--secondary-purple);
  cursor: pointer;
}
.chatbot-link.active,
.chatbot-link:focus {
  border-bottom: 0rem solid var(--secondary-purple);;
  cursor: pointer;
}
.chatbot-link:hover {
  color: var(--background);
  cursor: pointer;
}
/* Chatbot links end */
.admin-mobile-center-line {
  text-align: center;
  margin: auto;
  margin-top: 9rem!important;
}

.admin-subtitle-centre {
  text-align: center;
  margin: auto;
}

/* Navbar */
.offcanvas-backdrop.show {
  opacity: 0.9!important;
}

.navbar {
  background: linear-gradient(90deg, rgba(16,3,75,1) 0%, rgba(33,11,94,1) 50%, rgba(40,16,104,1) 70%, rgba(43,17,108,1) 90%, rgba(43,17,108,1) 100%);
  color: var(--text-purple);
  z-index: 9999;
  position: absolute;
}

.new-link {
  margin-right: 2rem;
  margin-top: -0.25rem;
}
.mobile-nav-link {
  padding-right: 2rem!important;
}
.mobile-nav-pages {
  color: var(--hover-purple)!important;
  font-size: 1.5rem!important;
}
.mobile-nav-dropdown {
  margin-right: 2rem!important;
}
.mobile-nav-pages:link {
  cursor: pointer;
}
.mobile-nav-pages:visited {
  cursor: pointer;
}
.mobile-nav-pages.active,
.mobile-nav-pages:focus {
  cursor: pointer;
  color: var(--text-purple)!important;
}
.mobile-nav-pages:hover {
  color: var(--text-purple)!important;
  cursor: pointer;
}

.offcanvas {
  width: 60%!important;
  background-color: var(--background)!important;
  z-index: 100000!important;
}
.offcanvas-body {
  width: 100%!important;
  margin-top: -3rem!important;
  opacity: 0.9;
}
.offcanvas-button {
  margin-right: 2rem;
  border: none!important;
}
.btn-close {
  z-index: 10000!important;
  margin-right: 3.25rem;
  margin-top: 2rem;
}

.mobile-dropdown {
  margin-left: 1.5rem!important;
}
.dropdown-toggle, .export {
  text-align: right;
  margin: auto;
  font-size: 1.5rem!important;
  color: var(--hover-purple)!important;
}

.dropdown-toggle:hover, .export:hover {
  text-align: right;
  margin: auto;
  font-size: 1.5rem!important;
  color: white!important;
}

#root > div.about-page-container > div > div {
  position: relative;
  width: 95%;
  margin: auto;
  border: 1px solid var(--primary-purple);
  border-radius: 5px;
}

#root > div.question-bank-container > div > div {
  position: relative;
  width: 95%;
  margin: auto;
  border-radius: 5px;
}

/* ***** */
/* START */
/* ***** */

@media screen and (min-width: 3200px) {
  .monitor-line-carousel {
    width: 80%;
    height: 500px;
    margin: auto auto;
    padding-top: 5rem;
    margin-bottom: 5rem;
  }
  
  .monitor-bar-carousel {
    width: 80%;
    height: 500px;
    margin: auto auto;
    padding-top: 5rem;
    margin-bottom: 5rem;
  }
  
  .monitor-other-carousel {
    width: 80%;
    height: 500px;
    margin: auto auto;
    margin-bottom: 10rem;
    z-index: 999;
    /* position: absolute; */
  }
  
  .monitor-other-charts-position {
    padding-top: 2rem;
    z-index: 999;
  }
  
  
  .mobile-line-carousel{
    width: 80%;
    height: 500px;
    margin: auto auto;
    padding-top: 5rem;
  }
  
  .mobile-bar-carousel {
    width: 80%;
    height: 500px;
    margin: auto auto;
  }
  
  .mobile-other-carousel {
    width: 80%;
    height: 500px;
    margin: auto auto;
  }
  
  .mobile-other-charts-position {
    padding-top: 20rem;
  }
  
  .Holders {
    margin-top: -15rem;
  }
  .no-show-monitor {
    display: none!important;
  }
  .no-monitor {
    display: none!important;
  }
  /* dune start */
.HoldersGraph {
  background-image: linear-gradient(90deg, #1B0D33, #D8D2E4, #D8D2E4,#D8D2E4, #D8D2E4,#D8D2E4,#D8D2E4,#D8D2E4,#D8D2E4,#D8D2E4,#D8D2E4,#D8D2E4,#D8D2E4,#D8D2E4,#D8D2E4,#D8D2E4,#D8D2E4,#D8D2E4,#1B0D33);
  margin-bottom: -36px!important;
  padding-left: 20px!important;
  padding-right: 20px!important;
}
.HoldersGraphBottom {
  background-image: linear-gradient(90deg, #1B0D33, #D8D2E4,#D8D2E4,#D8D2E4,#D8D2E4,#D8D2E4,#D8D2E4,#D8D2E4,#D8D2E4,#D8D2E4,#D8D2E4,#D8D2E4,#D8D2E4,#D8D2E4,#D8D2E4,#D8D2E4,#D8D2E4,#D8D2E4, #1B0D33);
  padding-left: 20px!important;
  padding-right: 20px!important;
}
/* dune end */
  .chatbot-footer-bottom {
    margin-bottom: -80px!important;
   }
  .chatbot-link-logo{
    margin-top: 13rem;
    z-index: 9998;
    position: fixed;
    max-width: 6rem;
    background: white;
    -moz-border-radius: 50px;
    -webkit-border-radius: 50px;
    border-radius: 50px;
    padding: -1rem;
    right: 2%;
    border: 3px var(--secondary-purple) solid;
    box-shadow: 0 0 0 var(--hover-purple);
    animation: pulse 2s infinite;
  }
  .advocate-link-logo{
    margin-top: 21rem;
    z-index: 9998;
    position: fixed;
    max-width: 6rem;
    background: white;
    -moz-border-radius: 50px;
    -webkit-border-radius: 50px;
    border-radius: 25px;
    padding: -1rem;
    right: 2%;
    border: 3px var(--secondary-purple) solid;
    box-shadow: 0 0 0 var(--hover-purple);
    animation: pulse 2s infinite;
  }
  @-webkit-keyframes pulse {
    0% {
      -webkit-box-shadow: 0 0 0 0  var(--text-purple);
    }
    70% {
        -webkit-box-shadow: 0 0 0 15px rgba(244,157,22, 0);
    }
    100% {
        -webkit-box-shadow: 0 0 0 0 rgba(244,157,22, 0);
    }
  }
  @keyframes pulse {
    0% {
      -moz-box-shadow: 0 0 0 0  var(--text-purple);
      box-shadow: 0 0 0 0  var(--text-purple);
    }
    70% {
        -moz-box-shadow: 0 0 0 15px rgba(244,157,22, 0);
        box-shadow: 0 0 0 15px rgba(244,157,22, 0);
    }
    100% {
        -moz-box-shadow: 0 0 0 0 rgba(244,157,22, 0);
        box-shadow: 0 0 0 0 rgba(244,157,22, 0);
    }
  }
  
  .chatbot-link-export{
    margin-top:18rem;
    z-index: 9998;
    position: fixed;
    max-width: 6rem;
    background: white;
    -moz-border-radius: 50px;
    -webkit-border-radius: 50px;
    border-radius: 50px;
    padding: -1rem;
    right: 2%;
    border: 3px var(--secondary-purple) solid;
    box-shadow: 0 0 0 var(--hover-purple);
    animation: pulse 2s infinite;
  }
  
 /* Chatbot */
.feedback-text {
  margin-top: 2rem;
}
.feedback-button {
  border: 2px var(--primary-purple) solid!important;
  background-color: var(--text-purple)!important;
  color: var(--primary-purple)!important;
  padding: auto!important;
  margin: auto!important;
}

  .feedback-button:link {
    cursor: pointer;
  }
  .feedback-button:visited {
    cursor: pointer;
  }
  .feedback-button.active,
  .feedback-button:focus {
    cursor: pointer;
  }
  .feedback-button:hover {
    background-color: var(--background)!important;
    color: var(--text-purple)!important;
    transform: scale(1.015);
    cursor: pointer;
  }
  .feedback-button-text {
    font-size: 1.5rem;
  }

.react-chatbot-kit-chat-inner-container {
  background-color: var(--text-purple)!important;
}

.react-chatbot-kit-chat-header {
  font-size: 1.1rem!important;
  color: var(--text-purple)!important;
  background-color: var(--primary-purple)!important;
  padding: 0rem!important;
}

.react-chatbot-kit-chat-input-container {
  background-color:var(--secondary-purple)!important;
  border: 1px solid var(--primary-purple)!important;
}

.react-chatbot-kit-chat-btn-send {
  background-color:var(--primary-purple)!important;
}

.react-chatbot-kit-chat-btn-send-icon:hover {
transform: scale(1.25);
}

.react-chatbot-kit-chat-bot-message-container {
  width: 100%;
}

.react-chatbot-kit-chat-bot-avatar-container {
}

.react-chatbot-kit-chat-bot-avatar-icon {
}

.react-chatbot-kit-chat-bot-avatar-letter {
  display: none;
}
.react-chatbot-kit-chat-container {
  width: 98%!important;
  text-align: center;
  margin: auto;
}

.react-chatbot-kit-chat-bot-avatar-container {
  width: 3.5rem!important;
  height: 3.5rem!important;
  background-color: white!important;
  border: 2px solid var(--primary-purple);
  background-image: url('./GRTDataHubChatBot.png')!important;
  background-repeat: no-repeat!important;
  background-size: 3rem;
  background-position-x: 50%;
  background-position-y: 20%;
}

.react-chatbot-kit-chat-bot-message {
  text-align: left!important;
  width: 100%!important;
  color: var(--text-purple)!important;
  background-color: #0C0B23!important;
  font-size: 1rem!important;
}

.react-chatbot-kit-chat-bot-message-arrow {
  color: #0C0B23!important;
  border-right: 8px solid #0C0B23!important;
}
  .indexer-table-header {
    text-align: center;
    margin-left: 1rem;
  }
  .indexer-table {
    text-align: center;
  }
  .chatbot-data {
    display: inline!important;
  }
  .telegramActiveDelegationCount {
    max-width: 92%;
    margin: auto; 
    text-align: center;
    margin-top: 0.5%;
    }
    .top-telegram {
      margin-top: 0.25rem;
    }
    .chatbot-top {
      margin-top: 5rem;
    }
    .chatbot-page-container {
      background: var(--background);
      color: var(--text-purple);
      width: 100%;
      margin: auto;
      margin-top: 150px;
      padding-left: 10px;
      padding-right: 10px;
      text-align: center;
    }
    .chatbot-text-container {
      background: whitesmoke;
      border-radius: 1rem;
      color: var(--secondary-purple);
      margin: auto!important;
      padding-bottom: 10px;
      padding-top: 10px;
      text-align: center!important;
    }
    .chatbot-page-title {
      font-size: 1.25rem;
      font-weight: 600;
      color: var(--secondary-purple);
      padding-bottom: 0.25rem;
      padding-left: 5px;
      padding-right: 5px;
    }
    .chatbot-page-subtitle {
      font-size: 1.75rem;
      padding-left: 5px;
      padding-right: 5px;
    }
    .chatbot-page-text {
      font-size: 1.25rem;
      margin-bottom: 1rem;
      margin-top: 1rem;
      padding-left: 10px;
      padding-right: 10px;
    }
    /* chatbot end */
  .dataHub-logos {
    max-width: 6rem !important;
    max-height: 35rem !important;
    position: absolute;
    top: 0;
    margin-top: 1rem;
  }
  .mobile-config {
    display: none!important;
  }
  .offcanvas-button, .offcanvas-menu {
    display: none!important;
  }
  .navbar-toggler {
    display: none;
  }
  .pages {
    font-size: 3.25rem;
    margin-bottom: 0.1rem;
    margin-right: 2rem;
}
.indexer-title {
  text-align: center!important;
  font-weight: 400;
  font-size: 4rem;
  padding-top: 2rem;
  color: var(--text-purple);
  
  margin-top: 6rem;
}
.indexer-subtitle {
  text-align: center!important;
  font-weight: 400;
  font-size: 3rem;
  color: var(--text-purple);
  
  margin-top: 1rem;
}
.indexer {
  z-index: 999;
  position: absolute;
  left: 15%;
  top: 50%;
}

.curator {
  z-index: 999;
  position: absolute;
  text-align: center!important;
  top: 50%;
  margin: auto!important;
}

.map, .tokens, .rewards, .uncollected-rewards {
  margin-top: 2rem;
  width: 100%;
  height: 30rem;
  margin-left: 5%;
  box-shadow: #4D1D86 4px 5px 10px 3px;
}

.c-map {
  margin-top: 2rem;
  width: 100%;
  height: 30rem;
  margin-left: 5%;
  box-shadow: #4D1D86 4px 5px 10px 3px;
}

.d-map {
  margin-top: -3rem;
  margin-left: 5%;
  width: 100%;
  height: 30rem;
  text-align: center!important;
  box-shadow: #4D1D86 4px 5px 10px 3px;
}

.rewards-per-hour {
  margin-top: 2rem;
  padding-bottom: 2rem;
  width: 100%;
  height: 30rem;
  margin-left: 5%;
  box-shadow: #4D1D86 4px 5px 10px 3px;
}

.indexers {
  padding-left: 1rem;
  padding-left: 1rem;
  color: grey;
  font-size:1.25rem;
  margin-right: 1rem;
}
.indexers:link{
  color: grey;
  cursor: pointer;
  font-size:1.25rem;
  margin-right: 1rem;
}
.indexers:visited {
  color: grey;
  cursor: pointer;
  font-size:1.25rem;
  margin-right: 1rem;
}
.indexers.active,
.indexers:focus {
  border-bottom: 0rem solid var(--hover-purple);
  cursor: pointer;
  font-size:1.25rem;
  margin-right: 1rem;
}
.indexers:hover {
  color: var(--text-purple);
  transform: scale(1.05);
  cursor: pointer;
  font-size:1.25rem;
  margin-right: 1rem;
}
.indexer-link {
  color: white;
}
.indexer-link:link {
  color: white;
  cursor: pointer;
}
.indexer-link:visited {
  color: var(--text-purple);
  cursor: pointer;
}
.indexer-link.active,
.indexer-link:focus {
  border-bottom: 0rem solid var(--text-purple);
  cursor: pointer;
}
.indexer-link:hover {
  color: var(--text-purple);
  transform: scale(1.05);
  cursor: pointer;
}
  .cookie-page-text {
    padding-top: 1rem;
    font-size: 1.5rem;
    margin-bottom: 1rem;
    padding-left: 20px;
    padding-right: 20px;
  }
  .cookies-video {
    padding-top: 2rem;
  }
  .cookie-button {
    z-index: 10000;
    position: absolute;
  }
  body > div:nth-child(1) > button {
    z-index: 10000;
    font-size: 3rem;
    position: absolute;
    padding: auto;
    margin-top: -60px;
    width: 20px;
    height: 80px;
    margin-left: 95%;
    background-color: transparent;
    border: none;
  }
  body > div:nth-child(1) > button:hover {
    z-index: 10000;
    font-size: 3rem;
    position: absolute;
    padding: auto;
    margin-top: -60px;
    width: 20px;
    height: 80px;
    margin-left: 95%;
    background-color: transparent;
    border: none;
    cursor: help;
  }
  .extra-data {
    margin-top: 25rem;
    font-size: 3rem;
    text-align: center;
    color: var(--text-purple);
    
  }
  .extra-data2 {
    font-size: 3rem;
    text-align: center;
    color: var(--text-purple);

  }
  .data-link {
    color: grey;
  }
  .data-link:link {
    color: grey;
    cursor: pointer;
  }
  .data-link:visited {
    color: grey;
    cursor: pointer;
  }
  .data-link.active,
  .data-link:focus {
    border-bottom: 0rem solid white;
    cursor: pointer;
  }
  .data-link:hover {
    color: white;
    transform: scale(1.05);
    cursor: pointer;
  }
  .question-bank-container {
    background: var(--background);
    color: var(--text-purple);
    width: 100%;
    margin: auto;
    margin-top: 185px;
    margin-bottom: 50px;
    padding-left: 50px;
    padding-right: 50px;
    text-align: center;
  }
  .about-page-container {
    background: var(--background);
    color: var(--text-purple);
    width: 100%;
    margin: auto;
    margin-top: 185px;
    margin-bottom: 50px;
    padding-left: 50px;
    padding-right: 50px;
    text-align: center;
  }
  .QB-text-container {
    background: white;
    border-radius: 1rem;
    padding: 30px;
    color: var(--secondary-purple);
  }
  .text-container {
    background: white;
    border-radius: 1rem;
    padding: 30px;
    color: var(--secondary-purple);
  }
  .QB-about-hr {
    border: 1.5px solid var(--background);
    color: var(--background);
    border-radius: 5px;
    width: 35%;
    margin: auto;
    margin-bottom: 1rem;
    box-shadow: 0 0 5px 1px var(--background);
  }
  .QB-about-page-title {
    font-size: 4rem;
    font-weight: 600;
    color: var(--secondary-purple);
    padding-bottom: 3rem;
  }
  .QB-about-page-subtitle {
    font-size: 1.75rem;
  }
  .QB-about-page-text {
    font-size: 1.25rem;
    margin-bottom: 1rem;
  }
  .about-hr {
    border: 1.5px solid var(--background);
    color: var(--background);
    border-radius: 5px;
    width: 35%;
    margin: auto;
    margin-bottom: 1rem;
    box-shadow: 0 0 5px 1px var(--background);
  }
  .about-page-title {
    font-size: 4rem;
    font-weight: 600;
    color: var(--secondary-purple);
    padding-bottom: 3rem;
  }
  .about-page-subtitle {
    font-size: 1.75rem;
  }
  .about-page-text {
    font-size: 1.25rem;
    margin-bottom: 1rem;
  }
  .graphiql-link {
    color: var(--secondary-purple);
  }
  .graphiql-link:link {
    color: var(--secondary-purple);
    cursor: pointer;
  }
  .graphiql-link:visited {
    color: var(--secondary-purple);
    cursor: pointer;
  }
  .graphiql-link.active,
  .graphiql-link:focus {
    border-bottom: 0rem solid var(--hover-purple);
    cursor: pointer;
  }
  .graphiql-link:hover {
    color: var(--primary-purple);
    transform: scale(1.05);
    cursor: pointer;
  }
  .cookies-policy-container {
    background: white;
    width: 90%;
    padding-bottom: 1rem;
    margin: auto;
    border-radius: 2rem;
    margin-top: 250px;
    margin-bottom: 50px;
  }
  .termly-cookie-preference-button {
    z-index: 999 !important;
    margin-top: 1rem;
    background: white;
    width: 165px;
    height: 30px;
    border-radius: 3px;
    border: 1px solid var(--background);
    font-family: Roboto, Arial;
    font-size: 10px;
    color: var(--background);
    font-weight: 600;
    box-sizing: border-box;
    cursor: pointer;
    padding: 0;
    outline: none;
  }
  .termly-cookie-preference-button:hover {
    z-index: 999 !important;
    margin-top: 1rem;
    background: var(--background);
    width: 165px;
    height: 30px;
    border-radius: 3px;
    border: 1px solid black;
    font-family: Roboto, Arial;
    font-size: 10px;
    color: var(--text-purple);
    font-weight: 600;
    box-sizing: border-box;
    cursor: pointer;
    padding: 0;
    outline: none;
  }

  .privacy-policy-container {
    background: white;
    width: 90%;
    padding-bottom: 1rem;
    margin: auto;
    border-radius: 2rem;
    margin-top: 150px;
    margin-bottom: 50px;
  }
  .privacy-policy {
    margin-top: 110px;
    font-weight: 700;
    font-size: 1.1rem;
    text-align: center;
    background-color: var(--background);
    color: var(--secondary-purple);
  }
  .privacy-policy-link,
  .about {
    padding-left: 1rem;
    padding-left: 1rem;
    color: grey;
  }
  .privacy-policy-link:link,
  .about:link {
    color: grey;
    cursor: pointer;
  }
  .privacy-policy-link:visited,
  .about:visited {
    color: grey;
    cursor: pointer;
  }
  .privacy-policy-link.active,
  .privacy-policy-link:focus,
  .about.active,
  .about:focus {
    border-bottom: 0rem solid var(--hover-purple);
    cursor: pointer;
  }
  .privacy-policy-link:hover,
  .about:hover {
    color: var(--text-purple);
    transform: scale(1.05);
    cursor: pointer;
  }
  .subscribeNote {
    margin-left: -35rem;
    width: 60rem;
    font-size: 1.75rem;
    color: white;
  }
  /* loader new start  */
  .loader:empty {
    position: absolute;
    top: calc(50% + 15.5rem);
    left: calc(50% + 2em);
    width: 4em;
    height: 4em;
    border: 0.35em solid white;
    border-left: 0.35em solid #10034B;
    border-radius: 50%;
    animation: load8 0.8s infinite linear;
  }
  .loadingTitle {
    position: absolute;
    top: 27rem;
    left: calc(50% - 3em);
    font-size: 2rem;
    color: #10034B;
    -webkit-box-reflect:below 0px linear-gradient(transparent, #0005);
  }
  .loaderLogo {
    position: absolute;
    top: 14rem;
    left: calc(50% - 8em);
    width: 15em;
    height: 12em;
  }
  @keyframes load8 {
    0% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(360deg);
    }
  }
  /* loader new end  */
  .C {
    font-size: 2rem;
  }
  .logo {
    width: 9rem;
    height: 10rem;
    margin-left: 5rem;
    padding-top: 0.5rem;
  }
  .data-logo {
    max-width: 2.5rem !important;
    height: 2.5rem !important;
    position: absolute;
  }
  .brand-text {
    color: var(--text-purple);
    display: inline;
    margin-left: -0.5rem;
    letter-spacing: 0.0375rem;
    font-size: 3rem;
  }
  .header-text {
    font-weight: 400;
    font-size: 4rem;
    margin-left: 10rem;
    padding-top: 2rem;
    color: var(--text-purple);
    margin-top: 6rem;
  }
  .header-sub-text {
    font-weight: 400;
    font-size: 3rem;
    text-align: center!important;
    margin: auto!important;
    width: 90%;
    color: var(--text-purple);
    display: block;
  }
  .adminHeader-text {
    font-weight: 400;
    font-size: 4rem;
    text-align: center;
    margin: auto;
    padding-top: 2rem;
    color: var(--text-purple);
    margin-top: 6rem;
  }
  .adminHeader-text2 {
    font-weight: 400;
    font-size: 4rem;
    color: var(--text-purple);
    text-align: center;
    margin: auto;
    margin-top: 1rem;
  }
  .adminSubheader-text {
    font-weight: 400;
    font-size: 3.25rem;
    margin-left: 6rem;
    color: var(--text-purple);
    text-shadow: 2px 2px var(--hover-purple);
    margin-top: 1.25rem;
  }
  .adminSubheader-text2 {
    font-weight: 400;
    font-size: 2.4rem;
    margin-left: 6rem;
    margin-right: 6rem;
    color: var(--text-purple);
    margin-top: 1.25rem;
  }
  .note {
    text-decoration: underline;
  }
  .TGN {
    margin-left: 4rem;
    margin-right: -3rem;
    color: var(--primary-purple);
    font-weight: 400;
    font-size: 2.5rem;
    cursor: default;
  }
  .TGN:link {
    color: var(--primary-purple);
    cursor: default;
    margin-left: 4rem;
    margin-right: -3rem;
  }
  .TGN:visited {
    color: var(--primary-purple);
    cursor: default;
    margin-left: 4rem;
    margin-right: -3rem;
  }
  .TGN.active,
  .TGN:focus {
    border-bottom: 0rem solid var(--primary-purple);
    cursor: default;
    margin-left: 4rem;
    margin-right: -3rem;
  }
  .TGN:hover {
    border-bottom: 0rem solid var(--primary-purple);
    padding: 0;
    color: var(--primary-purple);
    cursor: default;
    margin-left: 4rem;
    margin-right: -3rem;
  }
  .adminPortal {
    font-weight: 400;
    font-size: 2.5rem;
    color: var(--text-purple);
  }
  .adminPortal {
    padding: 0;
    margin-right: -2rem;
    margin-left: 2rem;
  }
  .adminPortal:link {
    color: var(--hover-purple);
  }
  .adminPortal:visited {
    color: var(--text-purple);
  }
  .adminPortal.active,
  .adminPortal:focus {
    padding: 0;
    transition: all 0.5s ease-in-out;
  }
  .adminPortal:hover {
    padding: 0;
    color: var(--hover-purple);
    transform: scale(1.05);
  }
  .logOut {
    display: inline;
  }
  .logOutButton {
    margin-left: 4rem;
    margin-right: -4rem;
  }
  nav a.nav-link {
    font-weight: 400;
    font-size: 2.5rem;
    color: var(--text-purple);
  }
  .navbar-nav > li > a {
    border-bottom: 0.01875rem solid var(--transparent);
    padding: 0;
  }
  .navbar-nav > li > a:link {
    color: var(--hover-purple);
  }
  .navbar-nav > li > a:visited {
    color: var(--text-purple);
  }
  .navbar-nav > li > a.active,
  .navbar-nav > li > a:focus {
    border-bottom: 0.01875rem solid var(--hover-purple);
    padding: 0;
    transition: all 0.5s ease-in-out;
  }
  .navbar-nav > li > a:hover {
    border-bottom: 0.01875rem solid var(--hover-purple);
    padding: 0;
    color: var(--hover-purple);
    transform: scale(1.05);
  }
  .line {
    margin-left: 2rem;
  }
  .links {
    margin-left: auto;
    padding-right: 10rem;
  }
  .menu-icon {
    color: var(--secondary-purple);
  }
  .main-info {
    background-image: url("TopBackground.svg");
    background-repeat: no-repeat;
    background-size: 100% auto;
    width: 100%;
    height: 100%;
    margin-top: 90px;
  }
  .community-links {
    margin-left: 12.5rem;
    padding-top: 1rem;
    position: absolute;
    z-index: 999;
  }
  .telegram-icon,
  .twitter-icon,
  .reddit-icon,
  .youtube-icon {
    display: inline;
    padding: 1rem;
    transition: all 0.5s ease-in-out;
    z-index: 999;
  }
  .icon:hover {
    color: var(--hover-purple);
    transform: scale(1.15);
  }
  .icon-youtube:hover {
    color: var(--text-purple);
    transform: scale(1.1);
  }
  .news-title {
    text-shadow: 2.25px 2.25px 3px var(--hover-purple);
    color: var(--text-purple);
    text-align: center;
    margin-top: 1rem;
    margin-bottom: 3rem;
    font-size: 4rem;
    margin-left: -10rem;
  }
  .youtube {
    z-index: 999;
    display: inline;
    position: absolute;
    text-align: center;
    color: var(--text-purple);
  }
  span.anchor {
    display: block;
    visibility: hidden;
    position: relative;
    padding-top: 17.5%;
  }
  .activeDelegators,
  .holders,
  .curatorCount,
  .subgraphCount {
    font-weight: 400;
    color: var(--text-purple);
    letter-spacing: 0.0375rem;
    font-size: 6rem;
  }
  .bottom {
    padding-top: 1.25rem;
  }
  .hr {
    border: 3px solid var(--hr);
    border-radius: 5px;
    width: 90%;
    margin: auto;
    box-shadow: 0 0 10px 3px var(--hover-purple);
    position: relative;
    margin-top: 10rem;
  }
  .hr2 {
    border: 3px solid var(--hr);
    border-radius: 5px;
    width: 90%;
    margin: auto;
    box-shadow: 0 0 10px 3px var(--hover-purple);
    position: relative;
    margin-top: 4rem;
  }
  .adminHr2 {
    border: 3px solid var(--hr);
    border-radius: 5px;
    width: 70%;
    margin: auto;
    box-shadow: 0 0 10px 3px var(--hover-purple);
    margin-top: 1rem;
    margin-left: 1.9rem;
  }
  .video {
    position: absolute;
    left: 40%;
    margin-top: 1rem;
    top: 0%;
    width: 45rem;
    height: 28rem;
  }
  .g-ytsubscribe {
    position: absolute;
    font-weight: 400;
    margin-top: 0.75rem;
    left: 73%;
    top: 43.5%;
  }
  .admin-video {
    position: absolute;
    left: 60%;
    top: 19%;
  }
  .data-title {
    letter-spacing: 0.0375rem;
    font-weight: 400;
    color: var(--text-purple);
    font-size: 7rem;
    text-align: center;
    position: relative;
  }
  .chartsTitle {
    letter-spacing: 0.0375rem;
    font-weight: 400;
    color: var(--text-purple);
    font-size: 7rem;
    text-align: center;
    position: relative;
  }
  .chartsTitle2 {
    letter-spacing: 0.0375rem;
    font-weight: 400;
    color: var(--text-purple);
    font-size: 7rem;
    text-align: center;
  }
  .adminChartsTitle {
    letter-spacing: 0.0375rem;
    font-weight: 400;
    color: var(--text-purple);
    font-size: 7rem;
    text-align: center;
    padding-top: 1rem;
    text-shadow: 2.5px 2.5px 4px var(--hover-purple);
    position: relative;
  }
  .adminChartsTitle2 {
    letter-spacing: 0.0375rem;
    font-weight: 400;
    color: var(--text-purple);
    font-size: 7rem;
    margin-top: 6rem;
    text-align: center;
    text-shadow: 2.5px 2.5px 4px var(--hover-purple);
  }
  .photo-wrap {
    width: 200%;
  }
  .newsGraph1,
  .newsGraph3 {
    width: 100%;
    margin-left: -20rem;
    box-shadow: #4D1D86 4px 5px 10px 3px;
  }
  .chartImg {
    width: 150%;
    box-shadow: #4D1D86 6px 6px 10px 3px;
    margin: auto;
  }
  .center {
    margin: auto;
  }
  .line1 {
    margin-top: 5rem;
  }
  .chartImg1 {
    text-align: center;
    height: 80%;
    margin-top: -170px;
    margin-bottom: -120px;
    margin-left: -8rem;
  }
  .chartImg2,
  .chartImg3,
  .chartImg4,
  .chartImg5 {
    text-align: center;
    margin-left: -20rem;
    width: 80%;
    margin-bottom: -120px;
    margin-left: -8rem;
  }
  .chartImg6 {
    text-align: center;
    padding-top: 1rem;
    padding-bottom: 1rem;
    width: 80%;
    margin-left: -8rem;
  }
  .reveal {
    position: relative;
    transform: translateY(50px);
    opacity: 0;
    transition: 0.75s all ease;
  }
  .reveal.active {
    transform: translateY(0);
    opacity: 1;
  }
  .email {
    color: var(--hover-purple);
    font-weight: 500;
  }
  .footerLink {
    color: var(--hover-purple);
  }
  .footerLink:link {
    color: var(--hover-purple);
    cursor: pointer;
  }
  .footerLink:visited {
    color: var(--hover-purple);
    cursor: pointer;
  }
  .footerLink.active,
  .footerLink:focus {
    border-bottom: 0rem solid var(--hover-purple);
    cursor: pointer;
  }
  .footerLink:hover {
    color: var(--text-purple);
    transform: scale(1.05);
    cursor: pointer;
  }
  .footer {
    margin-bottom: -1rem;
    padding: 1rem;
    font-weight: 700;
    margin-top: 9rem;
    font-size: 2.2rem;
    text-align: center;
    background-color: var(--primary-purple);
    color: var(--secondary-purple);
  }
  .dropdown-item {
    font-size: 2rem;
  }
  .tsparticles {
    position: absolute;
    height: 100%;
    width: 100%;
  }
  .rotate-position,
  .about-rotate-position,
  .phone,
  .about-phone,
  .message,
  .about-message, .home-message {
    display: none;
  }
}
/* *************************************************************************
****************************************************************************
***************************************************************************/
/* *************************************************************************
****************************************************************************
***************************************************************************/
@media screen and (min-width: 2402px) and (max-width: 3199px) {
  .monitor-line-carousel {
    width: 80%;
    height: 500px;
    margin: auto auto;
    padding-top: 5rem;
    margin-bottom: 5rem;
  }
  
  .monitor-bar-carousel {
    width: 80%;
    height: 500px;
    margin: auto auto;
    padding-top: 5rem;
    margin-bottom: 5rem;
  }
  
  .monitor-other-carousel {
    width: 80%;
    height: 500px;
    margin: auto auto;
    margin-bottom: 10rem;
    z-index: 999;
    /* position: absolute; */
  }
  
  .monitor-other-charts-position {
    padding-top: 2rem;
    z-index: 999;
  }
  
  
  .mobile-line-carousel{
    width: 80%;
    height: 500px;
    margin: auto auto;
    padding-top: 5rem;
  }
  
  .mobile-bar-carousel {
    width: 80%;
    height: 500px;
    margin: auto auto;
  }
  
  .mobile-other-carousel {
    width: 80%;
    height: 500px;
    margin: auto auto;
  }
  
  .mobile-other-charts-position {
    padding-top: 20rem;
  }
  
  .Holders {
    margin-top: -15rem;
  }
  .no-show-monitor {
    display: none!important;
  }
  .no-monitor {
    display: none!important;
  }
  /* dune start */
.HoldersGraph {
  background-image: linear-gradient(90deg, #1B0D33, #D8D2E4, #D8D2E4,#D8D2E4, #D8D2E4,#D8D2E4,#D8D2E4,#D8D2E4,#D8D2E4,#D8D2E4,#D8D2E4,#D8D2E4,#D8D2E4,#D8D2E4,#D8D2E4,#D8D2E4,#D8D2E4,#D8D2E4,#1B0D33);
  margin-bottom: -36px!important;
  padding-left: 20px!important;
  padding-right: 20px!important;
}
.HoldersGraphBottom {
  background-image: linear-gradient(90deg, #1B0D33, #D8D2E4,#D8D2E4,#D8D2E4,#D8D2E4,#D8D2E4,#D8D2E4,#D8D2E4,#D8D2E4,#D8D2E4,#D8D2E4,#D8D2E4,#D8D2E4,#D8D2E4,#D8D2E4,#D8D2E4,#D8D2E4,#D8D2E4, #1B0D33);
  padding-left: 20px!important;
  padding-right: 20px!important;
}
/* dune end */
  .chatbot-footer-bottom {
    margin-bottom: -80px!important;
   }
  .chatbot-link-logo{
    margin-top: 14rem;
    z-index: 9998;
    position: fixed;
    max-width: 6rem;
    background: white;
    -moz-border-radius: 50px;
    -webkit-border-radius: 50px;
    border-radius: 50px;
    padding: -1rem;
    right: 2%;
    border: 3px var(--secondary-purple) solid;
    box-shadow: 0 0 0 var(--hover-purple);
    animation: pulse 2s infinite;
  }
  .advocate-link-logo{
    margin-top: 22rem;
    z-index: 9998;
    position: fixed;
    max-width: 6rem;
    background: white;
    -moz-border-radius: 50px;
    -webkit-border-radius: 50px;
    border-radius: 25px;
    padding: -1rem;
    right: 2%;
    border: 3px var(--secondary-purple) solid;
    box-shadow: 0 0 0 var(--hover-purple);
    animation: pulse 2s infinite;
  }
  @-webkit-keyframes pulse {
    0% {
      -webkit-box-shadow: 0 0 0 0  var(--text-purple);
    }
    70% {
        -webkit-box-shadow: 0 0 0 15px rgba(244,157,22, 0);
    }
    100% {
        -webkit-box-shadow: 0 0 0 0 rgba(244,157,22, 0);
    }
  }
  @keyframes pulse {
    0% {
      -moz-box-shadow: 0 0 0 0  var(--text-purple);
      box-shadow: 0 0 0 0  var(--text-purple);
    }
    70% {
        -moz-box-shadow: 0 0 0 15px rgba(244,157,22, 0);
        box-shadow: 0 0 0 15px rgba(244,157,22, 0);
    }
    100% {
        -moz-box-shadow: 0 0 0 0 rgba(244,157,22, 0);
        box-shadow: 0 0 0 0 rgba(244,157,22, 0);
    }
  }
  
  .chatbot-link-export{
    margin-top:18rem;
    z-index: 9998;
    position: fixed;
    max-width: 6rem;
    background: white;
    -moz-border-radius: 50px;
    -webkit-border-radius: 50px;
    border-radius: 50px;
    padding: -1rem;
    right: 2%;
    border: 3px var(--secondary-purple) solid;
    box-shadow: 0 0 0 var(--hover-purple);
    animation: pulse 2s infinite;
  }
  
 /* Chatbot */
.feedback-text {
  margin-top: 2rem;
}
.feedback-button {
  border: 2px var(--primary-purple) solid!important;
  background-color: var(--text-purple)!important;
  color: var(--primary-purple)!important;
  padding: auto!important;
  margin: auto!important;
}

  .feedback-button:link {
    cursor: pointer;
  }
  .feedback-button:visited {
    cursor: pointer;
  }
  .feedback-button.active,
  .feedback-button:focus {
    cursor: pointer;
  }
  .feedback-button:hover {
    background-color: var(--background)!important;
    color: var(--text-purple)!important;
    transform: scale(1.015);
    cursor: pointer;
  }
  .feedback-button-text {
    font-size: 1.5rem;
  }

.react-chatbot-kit-chat-inner-container {
  background-color: var(--text-purple)!important;
}

.react-chatbot-kit-chat-header {
  font-size: 1.1rem!important;
  color: var(--text-purple)!important;
  background-color: var(--primary-purple)!important;
  padding: 0rem!important;
}

.react-chatbot-kit-chat-input-container {
  background-color:var(--secondary-purple)!important;
  border: 1px solid var(--primary-purple)!important;
}

.react-chatbot-kit-chat-btn-send {
  background-color:var(--primary-purple)!important;
}

.react-chatbot-kit-chat-btn-send-icon:hover {
transform: scale(1.25);
}

.react-chatbot-kit-chat-bot-message-container {
  width: 100%;
}

.react-chatbot-kit-chat-bot-avatar-container {
}

.react-chatbot-kit-chat-bot-avatar-icon {
}

.react-chatbot-kit-chat-bot-avatar-letter {
  display: none;
}
.react-chatbot-kit-chat-container {
  width: 98%!important;
  text-align: center;
  margin: auto;
}

.react-chatbot-kit-chat-bot-avatar-container {
  width: 3.5rem!important;
  height: 3.5rem!important;
  background-color: white!important;
  border: 2px solid var(--primary-purple);
  background-image: url('./GRTDataHubChatBot.png')!important;
  background-repeat: no-repeat!important;
  background-size: 3rem;
  background-position-x: 50%;
  background-position-y: 20%;
}

.react-chatbot-kit-chat-bot-message {
  text-align: left!important;
  width: 100%!important;
  color: var(--text-purple)!important;
  background-color: #0C0B23!important;
  font-size: 1rem!important;
}

.react-chatbot-kit-chat-bot-message-arrow {
  color: #0C0B23!important;
  border-right: 8px solid #0C0B23!important;
}
  .indexer-table-header {
    text-align: center;
    margin-left: 1rem;
  }
  .indexer-table {
    text-align: center;
  }
  .chatbot-data {
    display: inline!important;
  }
  .telegramActiveDelegationCount {
    max-width: 92%;
    margin: auto; 
    text-align: center;
    margin-top: 0.5%;
    }
    .top-telegram {
      margin-top: 0.25rem;
    }
    .chatbot-top {
      margin-top: 5rem;
    }
    .chatbot-page-container {
      background: var(--background);
      color: var(--text-purple);
      width: 100%;
      margin: auto;
      margin-top: 150px;
      padding-left: 10px;
      padding-right: 10px;
      text-align: center;
    }
    .chatbot-text-container {
      background: whitesmoke;
      border-radius: 1rem;
      color: var(--secondary-purple);
      margin: auto!important;
      padding-bottom: 10px;
      padding-top: 10px;
      text-align: center!important;
    }
    .chatbot-page-title {
      font-size: 1.25rem;
      font-weight: 600;
      color: var(--secondary-purple);
      padding-bottom: 0.25rem;
      padding-left: 5px;
      padding-right: 5px;
    }
    .chatbot-page-subtitle {
      font-size: 1.75rem;
      padding-left: 5px;
      padding-right: 5px;
    }
    .chatbot-page-text {
      font-size: 1.25rem;
      margin-bottom: 1rem;
      margin-top: 1rem;
      padding-left: 10px;
      padding-right: 10px;
    }
    /* chatbot end */
  .dataHub-logos {
    max-width: 6rem !important;
    max-height: 35rem !important;
    position: absolute;
    top: 0;
    margin-top: 1rem;
  }
  .mobile-config {
    display: none!important;
  }
  .offcanvas-button, .offcanvas-menu {
    display: none!important;
  }
  .navbar-toggler {
    display: none;
  }
  .pages {
    font-size: 3.25rem;
    margin-bottom: 0.1rem;
    margin-right: 2rem;
}
.indexer-title {
  text-align: center!important;
  font-weight: 400;
  font-size: 4rem;
  padding-top: 2rem;
  color: var(--text-purple);
  
  margin-top: 6rem;
}
.indexer-subtitle {
  text-align: center!important;
  font-weight: 400;
  font-size: 3rem;
  color: var(--text-purple);
  
  margin-top: 1rem;
}
.indexer {
  z-index: 999;
  position: absolute;
  left: 15%;
  top: 50%;
}

.curator {
  z-index: 999;
  position: absolute;
  text-align: center!important;
  top: 50%;
}

.map, .tokens, .rewards, .uncollected-rewards {
  margin-top: 2rem;
  width: 100%;
  height: 30rem;
  margin-left: 5%;
  box-shadow: #4D1D86 4px 5px 10px 3px;
}

.c-map {
  margin-top: 2rem;
  width: 100%;
  height: 30rem;
  margin-left: 5%;
  box-shadow: #4D1D86 4px 5px 10px 3px;
}

.d-map {
  margin-top: -3rem;
  margin-left: 5%;
  width: 100%;
  height: 30rem;
  text-align: center!important;
  box-shadow: #4D1D86 4px 5px 10px 3px;
}

.rewards-per-hour {
  margin-top: 2rem;
  padding-bottom: 2rem;
  width: 100%;
  height: 30rem;
  margin-left: 5%;
  box-shadow: #4D1D86 4px 5px 10px 3px;
}

.indexers {
  padding-left: 1rem;
  padding-left: 1rem;
  color: grey;
  font-size:1.25rem;
  margin-right: 1rem;
}
.indexers:link{
  color: grey;
  cursor: pointer;
  font-size:1.25rem;
  margin-right: 1rem;
}
.indexers:visited {
  color: grey;
  cursor: pointer;
  font-size:1.25rem;
  margin-right: 1rem;
}
.indexers.active,
.indexers:focus {
  border-bottom: 0rem solid var(--hover-purple);
  cursor: pointer;
  font-size:1.25rem;
  margin-right: 1rem;
}
.indexers:hover {
  color: var(--text-purple);
  transform: scale(1.05);
  cursor: pointer;
  font-size:1.25rem;
  margin-right: 1rem;
}
.indexer-link {
  color: white;
}
.indexer-link:link {
  color: white;
  cursor: pointer;
}
.indexer-link:visited {
  color: var(--text-purple);
  cursor: pointer;
}
.indexer-link.active,
.indexer-link:focus {
  border-bottom: 0rem solid var(--text-purple);
  cursor: pointer;
}
.indexer-link:hover {
  color: var(--text-purple);
  transform: scale(1.05);
  cursor: pointer;
}
  .cookie-page-text {
    padding-top: 1rem;
    font-size: 1.5rem;
    margin-bottom: 1rem;
    padding-left: 20px;
    padding-right: 20px;
  }
  .cookies-video {
    padding-top: 2rem;
  }
  .cookie-button {
    z-index: 10000;
    position: absolute;
  }
  body > div:nth-child(1) > button {
    z-index: 10000;
    font-size: 3rem;
    position: absolute;
    padding: auto;
    margin-top: -60px;
    width: 20px;
    height: 80px;
    margin-left: 95%;
    background-color: transparent;
    border: none;
  }
  body > div:nth-child(1) > button:hover {
    z-index: 10000;
    font-size: 3rem;
    position: absolute;
    padding: auto;
    margin-top: -60px;
    width: 20px;
    height: 80px;
    margin-left: 95%;
    background-color: transparent;
    border: none;
    cursor: help;
  }
  .extra-data {
    margin-top: 25rem;
    font-size: 3rem;
    text-align: center;
    color: var(--text-purple);

  }
  .extra-data2 {
    font-size: 3rem;
    text-align: center;
    color: var(--text-purple);
   
  }
  .data-link {
    color: grey;
  }
  .data-link:link {
    color: grey;
    cursor: pointer;
  }
  .data-link:visited {
    color: grey;
    cursor: pointer;
  }
  .data-link.active,
  .data-link:focus {
    border-bottom: 0rem solid white;
    cursor: pointer;
  }
  .data-link:hover {
    color: white;
    transform: scale(1.05);
    cursor: pointer;
  }
  .question-bank-container {
    background: var(--background);
    color: var(--text-purple);
    width: 100%;
    margin: auto;
    margin-top: 185px;
    margin-bottom: 50px;
    padding-left: 50px;
    padding-right: 50px;
    text-align: center;
  }
  .about-page-container {
    background: var(--background);
    color: var(--text-purple);
    width: 100%;
    margin: auto;
    margin-top: 185px;
    margin-bottom: 50px;
    padding-left: 50px;
    padding-right: 50px;
    text-align: center;
  }
  .QB-text-container {
    background: white;
    border-radius: 1rem;
    padding: 30px;
    color: var(--secondary-purple);
  }
  .text-container {
    background: white;
    border-radius: 1rem;
    padding: 30px;
    color: var(--secondary-purple);
  }
  .QB-about-hr {
    border: 1.5px solid var(--background);
    color: var(--background);
    border-radius: 5px;
    width: 35%;
    margin: auto;
    margin-bottom: 1rem;
    box-shadow: 0 0 5px 1px var(--background);
  }
  .QB-about-page-title {
    font-size: 4rem;
    font-weight: 600;
    color: var(--secondary-purple);
    padding-bottom: 3rem;
  }
  .QB-about-page-subtitle {
    font-size: 1.75rem;
  }
  .QB-about-page-text {
    font-size: 1.25rem;
    margin-bottom: 1rem;
  }
  .about-hr {
    border: 1.5px solid var(--background);
    color: var(--background);
    border-radius: 5px;
    width: 35%;
    margin: auto;
    margin-bottom: 1rem;
    box-shadow: 0 0 5px 1px var(--background);
  }
  .about-page-title {
    font-size: 4rem;
    font-weight: 600;
    color: var(--secondary-purple);
    padding-bottom: 3rem;
  }
  .about-page-subtitle {
    font-size: 1.75rem;
  }
  .about-page-text {
    font-size: 1.25rem;
    margin-bottom: 1rem;
  }
  .graphiql-link {
    color: var(--secondary-purple);
  }
  .graphiql-link:link {
    color: var(--secondary-purple);
    cursor: pointer;
  }
  .graphiql-link:visited {
    color: var(--secondary-purple);
    cursor: pointer;
  }
  .graphiql-link.active,
  .graphiql-link:focus {
    border-bottom: 0rem solid var(--hover-purple);
    cursor: pointer;
  }
  .graphiql-link:hover {
    color: var(--primary-purple);
    transform: scale(1.05);
    cursor: pointer;
  }
  .cookies-policy-container {
    background: white;
    width: 90%;
    padding-bottom: 1rem;
    margin: auto;
    border-radius: 2rem;
    margin-top: 250px;
    margin-bottom: 50px;
  }
  .termly-cookie-preference-button {
    z-index: 999 !important;
    margin-top: 1rem;
    background: white;
    width: 165px;
    height: 30px;
    border-radius: 3px;
    border: 1px solid var(--background);
    font-family: Roboto, Arial;
    font-size: 10px;
    color: var(--background);
    font-weight: 600;
    box-sizing: border-box;
    cursor: pointer;
    padding: 0;
    outline: none;
  }
  .termly-cookie-preference-button:hover {
    z-index: 999 !important;
    margin-top: 1rem;
    background: var(--background);
    width: 165px;
    height: 30px;
    border-radius: 3px;
    border: 1px solid black;
    font-family: Roboto, Arial;
    font-size: 10px;
    color: var(--text-purple);
    font-weight: 600;
    box-sizing: border-box;
    cursor: pointer;
    padding: 0;
    outline: none;
  }

  .privacy-policy-container {
    background: white;
    width: 90%;
    padding-bottom: 1rem;
    margin: auto;
    border-radius: 2rem;
    margin-top: 150px;
    margin-bottom: 50px;
  }
  .privacy-policy {
    margin-top: 110px;
    font-weight: 700;
    font-size: 1.1rem;
    text-align: center;
    background-color: var(--background);
    color: var(--secondary-purple);
  }
  .privacy-policy-link,
  .about {
    padding-left: 1rem;
    padding-left: 1rem;
    color: grey;
  }
  .privacy-policy-link:link,
  .about:link {
    color: grey;
    cursor: pointer;
  }
  .privacy-policy-link:visited,
  .about:visited {
    color: grey;
    cursor: pointer;
  }
  .privacy-policy-link.active,
  .privacy-policy-link:focus,
  .about.active,
  .about:focus {
    border-bottom: 0rem solid var(--hover-purple);
    cursor: pointer;
  }
  .privacy-policy-link:hover,
  .about:hover {
    color: var(--text-purple);
    transform: scale(1.05);
    cursor: pointer;
  }
  .subscribeNote {
    margin-left: -35rem;
    width: 60rem;
    font-size: 1.75rem;
    color: white;
  }
  /* loader new start  */
  .loader:empty {
    position: absolute;
    top: calc(50% + 15.5rem);
    left: calc(50% + 2em);
    width: 4em;
    height: 4em;
    border: 0.35em solid white;
    border-left: 0.35em solid #10034B;
    border-radius: 50%;
    animation: load8 0.8s infinite linear;
  }
  .loadingTitle {
    position: absolute;
    top: 27rem;
    left: calc(50% - 3em);
    font-size: 2rem;
    color: #10034B;
    -webkit-box-reflect:below 0px linear-gradient(transparent, #0005);
  }
  .loaderLogo {
    position: absolute;
    top: 14rem;
    left: calc(50% - 8em);
    width: 15em;
    height: 12em;
  }
  @keyframes load8 {
    0% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(360deg);
    }
  }
  /* loader new end  */
  .C {
    font-size: 2rem;
  }
  .logo {
    width: 9rem;
    height: 10rem;
    margin-left: 5rem;
    padding-top: 0.5rem;
  }
  .data-logo {
    max-width: 2.5rem !important;
    height: 2.5rem !important;
    position: absolute;
  }
  .brand-text {
    color: var(--text-purple);
    display: inline;
    margin-left: -0.5rem;
    letter-spacing: 0.0375rem;
    font-size: 3rem;
  }
  .header-text {
    font-weight: 400;
    font-size: 4rem;
    margin-left: 10rem;
    padding-top: 2rem;
    color: var(--text-purple);
 
    margin-top: 6rem;
  }
  .header-sub-text {
    font-weight: 400;
    font-size: 3rem;
    text-align: center!important;
    margin: auto!important;
    width: 90%;
    color: var(--text-purple);
    display: block;
   
  }
  .adminHeader-text {
    font-weight: 400;
    font-size: 4rem;
    text-align: center;
    margin: auto;
    padding-top: 2rem;
    color: var(--text-purple);
    margin-top: 6rem;
  }
  .adminHeader-text2 {
    font-weight: 400;
    font-size: 4rem;
    text-align: center;
    margin: auto;
    color: var(--text-purple);
  
    margin-top: 1rem;
  }
  .adminSubheader-text {
    font-weight: 400;
    font-size: 3.25rem;
    margin-left: 6rem;
    color: var(--text-purple);
    text-shadow: 2px 2px var(--hover-purple);
    margin-top: 1.25rem;
  }
  .adminSubheader-text2 {
    font-weight: 400;
    font-size: 2.4rem;
    margin-left: 6rem;
    margin-right: 6rem;
    color: var(--text-purple);

    margin-top: 1.25rem;
  }
  .note {
    text-decoration: underline;
  }
  .TGN {
    margin-left: 4rem;
    margin-right: -3rem;
    color: var(--primary-purple);
    font-weight: 400;
    font-size: 2.5rem;
    cursor: default;
  }
  .TGN:link {
    color: var(--primary-purple);
    cursor: default;
    margin-left: 4rem;
    margin-right: -3rem;
  }
  .TGN:visited {
    color: var(--primary-purple);
    cursor: default;
    margin-left: 4rem;
    margin-right: -3rem;
  }
  .TGN.active,
  .TGN:focus {
    border-bottom: 0rem solid var(--primary-purple);
    cursor: default;
    margin-left: 4rem;
    margin-right: -3rem;
  }
  .TGN:hover {
    border-bottom: 0rem solid var(--primary-purple);
    padding: 0;
    color: var(--primary-purple);
    cursor: default;
    margin-left: 4rem;
    margin-right: -3rem;
  }
  .adminPortal {
    font-weight: 400;
    font-size: 2.5rem;
    color: var(--text-purple);
  }
  .adminPortal {
    padding: 0;
    margin-right: -2rem;
    margin-left: 2rem;
  }
  .adminPortal:link {
    color: var(--hover-purple);
  }
  .adminPortal:visited {
    color: var(--text-purple);
  }
  .adminPortal.active,
  .adminPortal:focus {
    padding: 0;
    transition: all 0.5s ease-in-out;
  }
  .adminPortal:hover {
    padding: 0;
    color: var(--hover-purple);
    transform: scale(1.05);
  }
  .logOut {
    display: inline;
  }
  .logOutButton {
    margin-left: 4rem;
    margin-right: -4rem;
  }
  nav a.nav-link {
    font-weight: 400;
    font-size: 2.5rem;
    color: var(--text-purple);
  }
  .navbar-nav > li > a {
    border-bottom: 0.01875rem solid var(--transparent);
    padding: 0;
  }
  .navbar-nav > li > a:link {
    color: var(--hover-purple);
  }
  .navbar-nav > li > a:visited {
    color: var(--text-purple);
  }
  .navbar-nav > li > a.active,
  .navbar-nav > li > a:focus {
    border-bottom: 0.01875rem solid var(--hover-purple);
    padding: 0;
    transition: all 0.5s ease-in-out;
  }
  .navbar-nav > li > a:hover {
    border-bottom: 0.01875rem solid var(--hover-purple);
    padding: 0;
    color: var(--hover-purple);
    transform: scale(1.05);
  }
  .line {
    margin-left: 2rem;
  }
  .links {
    margin-left: auto;
    padding-right: 10rem;
  }
  .menu-icon {
    color: var(--secondary-purple);
  }
  .main-info {
    background-image: url("TopBackground.svg");
    background-repeat: no-repeat;
    background-size: 100% auto;
    width: 100%;
    height: 100%;
    margin-top: 90px;
  }
  .community-links {
    margin-left: 12.5rem;
    padding-top: 1rem;
    position: absolute;
    z-index: 999;
  }
  .telegram-icon,
  .twitter-icon,
  .reddit-icon,
  .youtube-icon {
    display: inline;
    padding: 1rem;
    transition: all 0.5s ease-in-out;
    z-index: 999;
  }
  .icon:hover {
    color: var(--hover-purple);
    transform: scale(1.15);
  }
  .icon-youtube:hover {
    color: var(--text-purple);
    transform: scale(1.1);
  }
  .news-title {
    text-shadow: 2.25px 2.25px 3px var(--hover-purple);
    color: var(--text-purple);
    text-align: center;
    margin-top: 1rem;
    margin-bottom: 3rem;
    font-size: 4rem;
    margin-left: -10rem;
  }
  .youtube {
    z-index: 999;
    display: inline;
    position: absolute;
    text-align: center;
    color: var(--text-purple);
  }
  span.anchor {
    display: block;
    visibility: hidden;
    position: relative;
    padding-top: 17.5%;
  }
  .activeDelegators,
  .holders,
  .curatorCount,
  .subgraphCount {
    font-weight: 400;
    color: var(--text-purple);
    letter-spacing: 0.0375rem;
    font-size: 6rem;
  }
  .bottom {
    padding-top: 1.25rem;
  }
  .hr {
    border: 3px solid var(--hr);
    border-radius: 5px;
    width: 90%;
    margin: auto;
    box-shadow: 0 0 10px 3px var(--hover-purple);
    position: relative;
    margin-top: 10rem;
  }
  .hr2 {
    border: 3px solid var(--hr);
    border-radius: 5px;
    width: 90%;
    margin: auto;
    box-shadow: 0 0 10px 3px var(--hover-purple);
    position: relative;
    margin-top: 4rem;
  }
  .adminHr2 {
    border: 3px solid var(--hr);
    border-radius: 5px;
    width: 70%;
    margin: auto;
    box-shadow: 0 0 10px 3px var(--hover-purple);
    margin-top: 1rem;
    margin-left: 1.9rem;
  }
  .video {
    position: absolute;
    left: 40%;
    margin-top: 1rem;
    top: 0%;
    width: 45rem;
    height: 28rem;
  }
  .g-ytsubscribe {
    position: absolute;
    font-weight: 400;
    margin-top: 0.75rem;
    left: 73%;
    top: 43.5%;
  }
  .admin-video {
    position: absolute;
    left: 60%;
    top: 19%;
  }
  .data-title {
    letter-spacing: 0.0375rem;
    font-weight: 400;
    color: var(--text-purple);
    font-size: 7rem;
    text-align: center;
    position: relative;
  }
  .chartsTitle {
    letter-spacing: 0.0375rem;
    font-weight: 400;
    color: var(--text-purple);
    font-size: 7rem;
    text-align: center;
    position: relative;
  }
  .chartsTitle2 {
    letter-spacing: 0.0375rem;
    font-weight: 400;
    color: var(--text-purple);
    font-size: 7rem;
    text-align: center;

  }
  .adminChartsTitle {
    letter-spacing: 0.0375rem;
    font-weight: 400;
    color: var(--text-purple);
    font-size: 7rem;
    text-align: center;
    padding-top: 1rem;
    text-shadow: 2.5px 2.5px 4px var(--hover-purple);
    position: relative;
  }
  .adminChartsTitle2 {
    letter-spacing: 0.0375rem;
    font-weight: 400;
    color: var(--text-purple);
    font-size: 7rem;
    margin-top: 6rem;
    text-align: center;
    text-shadow: 2.5px 2.5px 4px var(--hover-purple);
  }
  .photo-wrap {
    width: 200%;
  }
  .newsGraph1,
  .newsGraph3 {
    width: 100%;
    margin-left: -20rem;
    box-shadow: #4D1D86 4px 5px 10px 3px;
  }
  .chartImg {
    width: 150%;
    box-shadow: #4D1D86 6px 6px 10px 3px;
    margin: auto;
  }
  .center {
    margin: auto;
  }
  .line1 {
    margin-top: 5rem;
  }
  .chartImg1 {
    text-align: center;
    width: 80%;
    margin-top: -170px;
    margin-bottom: -120px;
    margin-left: -8rem;
  }
  .chartImg2,
  .chartImg3,
  .chartImg4,
  .chartImg5 {
    text-align: center;
    margin-left: -20rem;
    width: 80%;
    margin-bottom: -120px;
    margin-left: -8rem;
  }
  .chartImg6 {
    text-align: center;
    padding-top: 1rem;
    padding-bottom: 1rem;
    width: 80%;

    margin-left: -8rem;
  }
  .reveal {
    position: relative;
    transform: translateY(50px);
    opacity: 0;
    transition: 0.75s all ease;
  }
  .reveal.active {
    transform: translateY(0);
    opacity: 1;
  }
  .email {
    color: var(--hover-purple);
    font-weight: 500;
  }
  .footerLink {
    color: var(--hover-purple);
  }
  .footerLink:link {
    color: var(--hover-purple);
    cursor: pointer;
  }
  .footerLink:visited {
    color: var(--hover-purple);
    cursor: pointer;
  }
  .footerLink.active,
  .footerLink:focus {
    border-bottom: 0rem solid var(--hover-purple);
    cursor: pointer;
  }
  .footerLink:hover {
    color: var(--text-purple);
    transform: scale(1.05);
    cursor: pointer;
  }
  .footer {
    margin-bottom: -1rem;
    padding: 1rem;
    font-weight: 700;
    margin-top: 9rem;
    font-size: 2.2rem;
    text-align: center;
    background-color: var(--primary-purple);
    color: var(--secondary-purple);
  }
  .dropdown-item {
    font-size: 2rem;
  }
  .tsparticles {
    position: absolute;
    height: 100%;
    width: 100%;
  }
  .rotate-position,
  .about-rotate-position,
  .phone,
  .about-phone,
  .message,
  .about-message, .home-message {
    display: none;
  }
}
/* *************************************************************************
****************************************************************************
***************************************************************************/
/* *************************************************************************
****************************************************************************
***************************************************************************/
@media screen and (min-width: 2400px) and (max-width: 2401px) {
  .monitor-line-carousel {
    width: 80%;
    height: 500px;
    margin: auto auto;
    padding-top: 5rem;
    margin-bottom: 5rem;
  }
  
  .monitor-bar-carousel {
    width: 80%;
    height: 500px;
    margin: auto auto;
    padding-top: 5rem;
    margin-bottom: 5rem;
  }
  
  .monitor-other-carousel {
    width: 80%;
    height: 500px;
    margin: auto auto;
    margin-bottom: 10rem;
    z-index: 999;
    /* position: absolute; */
  }
  
  .monitor-other-charts-position {
    padding-top: 2rem;
    z-index: 999;
  }
  
  
  .mobile-line-carousel{
    width: 80%;
    height: 500px;
    margin: auto auto;
    padding-top: 5rem;
  }
  
  .mobile-bar-carousel {
    width: 80%;
    height: 500px;
    margin: auto auto;
  }
  
  .mobile-other-carousel {
    width: 80%;
    height: 500px;
    margin: auto auto;
  }
  
  .mobile-other-charts-position {
    padding-top: 20rem;
  }
  
  .Holders {
    margin-top: -15rem;
  }
  .no-show-monitor {
    display: none!important;
  }
  .no-monitor {
    display: none!important;
  }
  /* dune start */
.HoldersGraph {
  background-image: linear-gradient(90deg, #1B0D33, #D8D2E4, #D8D2E4,#D8D2E4, #D8D2E4,#D8D2E4,#D8D2E4,#D8D2E4,#D8D2E4,#D8D2E4,#D8D2E4,#D8D2E4,#D8D2E4,#D8D2E4,#D8D2E4,#D8D2E4,#D8D2E4,#D8D2E4,#1B0D33);
  margin-bottom: -36px!important;
  padding-left: 20px!important;
  padding-right: 20px!important;
}
.HoldersGraphBottom {
  background-image: linear-gradient(90deg, #1B0D33, #D8D2E4,#D8D2E4,#D8D2E4,#D8D2E4,#D8D2E4,#D8D2E4,#D8D2E4,#D8D2E4,#D8D2E4,#D8D2E4,#D8D2E4,#D8D2E4,#D8D2E4,#D8D2E4,#D8D2E4,#D8D2E4,#D8D2E4, #1B0D33);
  padding-left: 20px!important;
  padding-right: 20px!important;
}
/* dune end */
  .chatbot-footer-bottom {
    margin-bottom: -80px!important;
   }
  .chatbot-link-logo{
    margin-top: 14rem;
    z-index: 9998;
    position: fixed;
    max-width: 6rem;
    background: white;
    -moz-border-radius: 50px;
    -webkit-border-radius: 50px;
    border-radius: 50px;
    padding: -1rem;
    right: 2%;
    border: 3px var(--secondary-purple) solid;
    box-shadow: 0 0 0 var(--hover-purple);
    animation: pulse 2s infinite;
  }
  .advocate-link-logo{
    margin-top: 22rem;
    z-index: 9998;
    position: fixed;
    max-width: 6rem;
    background: white;
    -moz-border-radius: 50px;
    -webkit-border-radius: 50px;
    border-radius: 25px;
    padding: -1rem;
    right: 2%;
    border: 3px var(--secondary-purple) solid;
    box-shadow: 0 0 0 var(--hover-purple);
    animation: pulse 2s infinite;
  }
  @-webkit-keyframes pulse {
    0% {
      -webkit-box-shadow: 0 0 0 0  var(--text-purple);
    }
    70% {
        -webkit-box-shadow: 0 0 0 15px rgba(244,157,22, 0);
    }
    100% {
        -webkit-box-shadow: 0 0 0 0 rgba(244,157,22, 0);
    }
  }
  @keyframes pulse {
    0% {
      -moz-box-shadow: 0 0 0 0  var(--text-purple);
      box-shadow: 0 0 0 0  var(--text-purple);
    }
    70% {
        -moz-box-shadow: 0 0 0 15px rgba(244,157,22, 0);
        box-shadow: 0 0 0 15px rgba(244,157,22, 0);
    }
    100% {
        -moz-box-shadow: 0 0 0 0 rgba(244,157,22, 0);
        box-shadow: 0 0 0 0 rgba(244,157,22, 0);
    }
  }
  
  .chatbot-link-export{
    margin-top: 18rem;
    z-index: 9998;
    position: fixed;
    max-width: 6rem;
    background: white;
    -moz-border-radius: 50px;
    -webkit-border-radius: 50px;
    border-radius: 50px;
    padding: -1rem;
right: 2%;
    border: 3px var(--secondary-purple) solid;
    box-shadow: 0 0 0 var(--hover-purple);
    animation: pulse 2s infinite;
  }
  
/* Chatbot */
.feedback-text {
  margin-top: 2rem;
}
.feedback-button {
  border: 2px var(--primary-purple) solid!important;
  background-color: var(--text-purple)!important;
  color: var(--primary-purple)!important;
  padding: auto!important;
  margin: auto!important;
}

  .feedback-button:link {
    cursor: pointer;
  }
  .feedback-button:visited {
    cursor: pointer;
  }
  .feedback-button.active,
  .feedback-button:focus {
    cursor: pointer;
  }
  .feedback-button:hover {
    background-color: var(--background)!important;
    color: var(--text-purple)!important;
    transform: scale(1.015);
    cursor: pointer;
  }
  .feedback-button-text {
    font-size: 1.5rem;
  }

.react-chatbot-kit-chat-inner-container {
  background-color: var(--text-purple)!important;
}

.react-chatbot-kit-chat-header {
  font-size: 1.1rem!important;
  color: var(--text-purple)!important;
  background-color: var(--primary-purple)!important;
  padding: 0rem!important;
}

.react-chatbot-kit-chat-input-container {
  background-color:var(--secondary-purple)!important;
  border: 1px solid var(--primary-purple)!important;
}

.react-chatbot-kit-chat-btn-send {
  background-color:var(--primary-purple)!important;
}

.react-chatbot-kit-chat-btn-send-icon:hover {
transform: scale(1.25);
}

.react-chatbot-kit-chat-bot-message-container {
  width: 100%;
}

.react-chatbot-kit-chat-bot-avatar-container {
}

.react-chatbot-kit-chat-bot-avatar-icon {
}

.react-chatbot-kit-chat-bot-avatar-letter {
  display: none;
}
.react-chatbot-kit-chat-container {
  width: 98%!important;
  text-align: center;
  margin: auto;
}

.react-chatbot-kit-chat-bot-avatar-container {
  width: 3.5rem!important;
  height: 3.5rem!important;
  background-color: white!important;
  border: 2px solid var(--primary-purple);
  background-image: url('./GRTDataHubChatBot.png')!important;
  background-repeat: no-repeat!important;
  background-size: 3rem;
  background-position-x: 50%;
  background-position-y: 20%;
}

.react-chatbot-kit-chat-bot-message {
  text-align: left!important;
  width: 100%!important;
  color: var(--text-purple)!important;
  background-color: #0C0B23!important;
  font-size: 1rem!important;
}

.react-chatbot-kit-chat-bot-message-arrow {
  color: #0C0B23!important;
  border-right: 8px solid #0C0B23!important;
}
  .indexer-table-header {
    text-align: center;
    margin-left: 1rem;
  }
  .indexer-table {
    text-align: center;
  }
  .chatbot-data {
    display: inline!important;
  }
  .telegramActiveDelegationCount {
    max-width: 92%;
    margin: auto; 
    text-align: center;
    margin-top: 0.5%;
    }
    .top-telegram {
      margin-top: 0.25rem;
    }
    .chatbot-top {
      margin-top: 5rem;
    }
    .chatbot-page-container {
      background: var(--background);
      color: var(--text-purple);
      width: 100%;
      margin: auto;
      margin-top: 150px;
      padding-left: 10px;
      padding-right: 10px;
      text-align: center;
    }
    .chatbot-text-container {
      background: whitesmoke;
      border-radius: 1rem;
      color: var(--secondary-purple);
      margin: auto!important;
      padding-bottom: 10px;
      padding-top: 10px;
      text-align: center!important;
    }
    .chatbot-page-title {
      font-size: 1.25rem;
      font-weight: 600;
      color: var(--secondary-purple);
      padding-bottom: 0.25rem;
      padding-left: 5px;
      padding-right: 5px;
    }
    .chatbot-page-subtitle {
      font-size: 1.75rem;
      padding-left: 5px;
      padding-right: 5px;
    }
    .chatbot-page-text {
      font-size: 1.25rem;
      margin-bottom: 1rem;
      margin-top: 1rem;
      padding-left: 10px;
      padding-right: 10px;
    }
    /* chatbot end */
  .dataHub-logos {
    max-width: 6rem !important;
    max-height: 35rem !important;
    position: absolute;
    top: 0;
    margin-top: 1rem;
  }
  .mobile-config {
    display: none!important;
  }
  .pages {
    font-size: 3.25rem;
    margin-bottom: 0.1rem;
    margin-right: 2rem;
}
.indexer-title {
  text-align: center!important;
  font-weight: 400;
  font-size: 4rem;
  padding-top: 2rem;
  color: var(--text-purple);
  
  margin-top: 6rem;
}
.indexer-subtitle {
  text-align: center!important;
  font-weight: 400;
  font-size: 3rem;
  color: var(--text-purple);
  
  margin-top: 1rem;
}
.indexer {
  z-index: 999;
  position: absolute;
  left: 15%;
  top: 50%;
}

.curator {
  z-index: 999;
  position: absolute;
  text-align: center!important;
  top: 50%;
}

.map, .tokens, .rewards, .uncollected-rewards {
  margin-top: 2rem;
  width: 100%;
  height: 30rem;
  margin-left: 5%;
  box-shadow: #4D1D86 4px 5px 10px 3px;
}

.c-map {
  margin-top: 2rem;
  width: 100%;
  height: 30rem;
  margin-left: 5%;
  box-shadow: #4D1D86 4px 5px 10px 3px;
}

.d-map {
  margin-top: -3rem;
  margin-left: 5%;
  width: 100%;
  height: 30rem;
  text-align: center!important;
  box-shadow: #4D1D86 4px 5px 10px 3px;
}

.rewards-per-hour {
  margin-top: 2rem;
  padding-bottom: 2rem;
  width: 100%;
  height: 30rem;
  margin-left: 5%;
  box-shadow: #4D1D86 4px 5px 10px 3px;
}

.indexers {
  padding-left: 1rem;
  padding-left: 1rem;
  color: grey;
  font-size:1.25rem;
  margin-right: 1rem;
}
.indexers:link{
  color: grey;
  cursor: pointer;
  font-size:1.25rem;
  margin-right: 1rem;
}
.indexers:visited {
  color: grey;
  cursor: pointer;
  font-size:1.25rem;
  margin-right: 1rem;
}
.indexers.active,
.indexers:focus {
  border-bottom: 0rem solid var(--hover-purple);
  cursor: pointer;
  font-size:1.25rem;
  margin-right: 1rem;
}
.indexers:hover {
  color: var(--text-purple);
  transform: scale(1.05);
  cursor: pointer;
  font-size:1.25rem;
  margin-right: 1rem;
}
.indexer-link {
  color: white;
}
.indexer-link:link {
  color: white;
  cursor: pointer;
}
.indexer-link:visited {
  color: var(--text-purple);
  cursor: pointer;
}
.indexer-link.active,
.indexer-link:focus {
  border-bottom: 0rem solid var(--text-purple);
  cursor: pointer;
}
.indexer-link:hover {
  color: var(--text-purple);
  transform: scale(1.05);
  cursor: pointer;
}
  .cookie-page-text {
    padding-top: 1rem;
    font-size: 1.25rem;
    margin-bottom: 1rem;
    padding-left: 20px;
    padding-right: 20px;
  }
  .cookies-video {
    padding-top: 2rem;
  }
  .cookie-button {
    z-index: 10000;
    position: absolute;
  }
  body > div:nth-child(1) > button {
    z-index: 10000;
    font-size: 3rem;
    position: absolute;
    padding: auto;
    margin-top: -60px;
    width: 20px;
    height: 80px;
    margin-left: 95%;
    background-color: transparent;
    border: none;
  }
  body > div:nth-child(1) > button:hover {
    z-index: 10000;
    font-size: 3rem;
    position: absolute;
    padding: auto;
    margin-top: -60px;
    width: 20px;
    height: 80px;
    margin-left: 95%;
    background-color: transparent;
    border: none;
    cursor: help;
  }
  .question-bank-container {
    background: var(--background);
    color: var(--text-purple);
    width: 100%;
    margin: auto;
    margin-top: 200px;
    margin-bottom: 50px;
    padding-left: 50px;
    padding-right: 50px;
    text-align: center;
  }
  .about-page-container {
    background: var(--background);
    color: var(--text-purple);
    width: 100%;
    margin: auto;
    margin-top: 200px;
    margin-bottom: 50px;
    padding-left: 50px;
    padding-right: 50px;
    text-align: center;
  }
  .QB-text-container {
    background: white;
    border-radius: 1rem;
    padding: 30px;
    color: var(--secondary-purple);
  }
  .text-container {
    background: white;
    border-radius: 1rem;
    padding: 30px;
    color: var(--secondary-purple);
  }
  .QB-about-hr {
    border: 1.5px solid var(--background);
    color: var(--background);
    border-radius: 5px;
    width: 35%;
    margin: auto;
    margin-bottom: 1rem;
    box-shadow: 0 0 5px 1px var(--background);
  }
  .QB-about-page-title {
    font-size: 4rem;
    font-weight: 600;
    color: var(--secondary-purple);
    padding-bottom: 3rem;
  }
  .QB-about-page-subtitle {
    font-size: 3rem;
  }
  .QB-about-page-text {
    font-size: 2rem;
    margin-bottom: 1rem;
  }
  .about-hr {
    border: 1.5px solid var(--background);
    color: var(--background);
    border-radius: 5px;
    width: 35%;
    margin: auto;
    margin-bottom: 1rem;
    box-shadow: 0 0 5px 1px var(--background);
  }
  .about-page-title {
    font-size: 4rem;
    font-weight: 600;
    color: var(--secondary-purple);
    padding-bottom: 3rem;
  }
  .about-page-subtitle {
    font-size: 3rem;
  }
  .about-page-text {
    font-size: 2rem;
    margin-bottom: 1rem;
  }
  .graphiql-link {
    color: var(--secondary-purple);
  }
  .graphiql-link:link {
    color: var(--secondary-purple);
    cursor: pointer;
  }
  .graphiql-link:visited {
    color: var(--secondary-purple);
    cursor: pointer;
  }
  .graphiql-link.active,
  .graphiql-link:focus {
    border-bottom: 0rem solid var(--hover-purple);
    cursor: pointer;
  }
  .graphiql-link:hover {
    color: var(--primary-purple);
    transform: scale(1.05);
    cursor: pointer;
  }
  .cookies-policy-container {
    background: white;
    width: 90%;
    padding-bottom: 1rem;
    margin: auto;
    border-radius: 2rem;
    margin-top: 200px;
    margin-bottom: 50px;
  }
  .termly-cookie-preference-button {
    z-index: 999 !important;
    margin-top: 1rem;
    background: white;
    width: 165px;
    height: 30px;
    border-radius: 3px;
    border: 1px solid var(--background);
    font-family: Roboto, Arial;
    font-size: 10px;
    color: var(--background);
    font-weight: 600;
    box-sizing: border-box;
    cursor: pointer;
    padding: 0;
    outline: none;
  }
  .termly-cookie-preference-button:hover {
    z-index: 999 !important;
    margin-top: 1rem;
    background: var(--background);
    width: 165px;
    height: 30px;
    border-radius: 3px;
    border: 1px solid black;
    font-family: Roboto, Arial;
    font-size: 10px;
    color: var(--text-purple);
    font-weight: 600;
    box-sizing: border-box;
    cursor: pointer;
    padding: 0;
    outline: none;
  }

  .privacy-policy-container {
    background: white;
    width: 90%;
    padding-bottom: 1rem;
    margin: auto;
    border-radius: 2rem;
    margin-top: 200px;
    margin-bottom: 50px;
  }
  .privacy-policy {
    margin-top: 110px;
    font-weight: 700;
    font-size: 1rem;
    text-align: center;
    background-color: var(--background);
    color: var(--secondary-purple);
  }
  .privacy-policy-link,
  .about {
    padding-left: 1rem;
    padding-left: 1rem;
    color: grey;
  }
  .privacy-policy-link:link,
  .about:link {
    color: grey;
    cursor: pointer;
  }
  .privacy-policy-link:visited,
  .about:visited {
    color: grey;
    cursor: pointer;
  }
  .privacy-policy-link.active,
  .privacy-policy-link:focus,
  .about.active,
  .about:focus {
    border-bottom: 0rem solid var(--hover-purple);
    cursor: pointer;
  }
  .privacy-policy-link:hover,
  .about:hover {
    color: var(--text-purple);
    transform: scale(1.05);
    cursor: pointer;
  }
  .dropdown-item {
    font-size: 2.5rem;
  }
  .subscribeNote {
    margin-left: -33rem;
    margin-top: -1rem;
    width: 40rem;
    font-size: 1.5rem;
    color: white;
  }
   /* loader new start  */
   .loader:empty {
    position: absolute;
    top: calc(50% + 15.5rem);
    left: calc(50% + 2em);
    width: 4em;
    height: 4em;
    border: 0.35em solid white;
    border-left: 0.35em solid #10034B;
    border-radius: 50%;
    animation: load8 0.8s infinite linear;
  }
  .loadingTitle {
    position: absolute;
    top: 27rem;
    left: calc(50% - 3em);
    font-size: 2rem;
    color: #10034B;
    -webkit-box-reflect:below 0px linear-gradient(transparent, #0005);
  }
  .loaderLogo {
    position: absolute;
    top: 14rem;
    left: calc(50% - 8em);
    width: 15em;
    height: 12em;
  }
  @keyframes load8 {
    0% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(360deg);
    }
  }
  /* loader new end  */
  .C {
    font-size: 2rem;
  }
  .logo {
    width: 8rem;
    height: 9rem;
    margin-left: 5rem;
    padding-top: 0.5rem;
  }
  .data-logo {
    max-width: 2.25rem !important;
    height: 2.25rem !important;
    position: absolute;
  }
  .brand-text {
    color: var(--text-purple);
    display: inline;
    margin-left: -0.5rem;
    letter-spacing: 0.0375rem;
    font-size: 3rem;
  }
  .header-text {
    font-weight: 400;
    font-size: 4.5rem;
    margin-left: 10rem;
    padding-top: 2rem;
    color: var(--text-purple);
  
    margin-top: 6rem;
  }
  .header-sub-text {
    font-weight: 400;
    font-size: 3.5rem;
    text-align: center!important;
    margin: auto!important;
    width: 90%;
    color: var(--text-purple);
    display: block;
 
  }
  .adminHeader-text {
    font-weight: 400;
    font-size: 3rem;
    text-align: center;
    margin: auto;
    padding-top: 2rem;
    color: var(--text-purple);
  
    margin-top: 6rem;
  }
  .adminHeader-text2 {
    font-weight: 400;
    font-size: 3rem;
    text-align: center;
    margin: auto;
    color: var(--text-purple);
 
    margin-top: 1rem;
  }
  .adminSubheader-text {
    font-weight: 400;
    font-size: 2.25rem;
    margin-left: 6rem;
    color: var(--text-purple);
    text-shadow: 2px 2px var(--hover-purple);
    margin-top: 1.25rem;
  }
  .adminSubheader-text2 {
    font-weight: 400;
    font-size: 1.5rem;
    margin-left: 6rem;
    margin-right: 6rem;
    color: var(--text-purple);
 
    margin-top: 1.25rem;
  }
  .note {
    text-decoration: underline;
  }
  .TGN {
    display: none;
  }
  .TGN:link {
    display: none;
  }
  .TGN:visited {
    display: none;
  }
  .TGN.active,
  .TGN:focus {
    display: none;
  }
  .TGN:hover {
    display: none;
  }
  .adminPortal {
    display: none;
  }
  .adminPortal {
    display: none;
  }
  .adminPortal:link {
    display: none;
  }
  .adminPortal:visited {
    display: none;
  }
  .adminPortal.active,
  .adminPortal:focus {
    display: none;
  }
  .adminPortal:hover {
    display: none;
  }
  .logOut {
    display: none;
  }
  .logOutButton {
    display: none;
  }
  nav a.nav-link {
    font-weight: 400;
    font-size: 3rem;
    color: var(--text-purple);
  }
  .navbar-nav > li > a {
    border-bottom: 0.01875rem solid var(--transparent);
    padding: 0;
  }
  .navbar-nav > li > a:link {
    color: var(--hover-purple);
  }
  .navbar-nav > li > a:visited {
    color: var(--text-purple);
  }
  .navbar-nav > li > a.active,
  .navbar-nav > li > a:focus {
    border-bottom: 0.01875rem solid var(--hover-purple);
    padding: 0;
    transition: all 0.5s ease-in-out;
  }
  .navbar-nav > li > a:hover {
    border-bottom: 0.01875rem solid var(--hover-purple);
    padding: 0;
    color: var(--hover-purple);
    transform: scale(1.05);
  }
  .line {
    margin-left: 1rem;
  }
  .links {
    margin-left: auto;
    padding-right: 5rem;
  }
  .menu-icon {
    color: var(--secondary-purple);
  }
  .main-info {
    background-image: url("TopBackground.svg");
    background-repeat: no-repeat;
    background-size: 100% auto;
    width: 100%;
    height: 100%;
    margin-top: 90px;
  }
  .community-links {
    margin-left: 13.5rem;
    padding-top: 1rem;
    position: absolute;
    z-index: 999;
  }
  .telegram-icon,
  .twitter-icon,
  .reddit-icon,
  .youtube-icon {
    display: inline;
    padding: 1rem;
    transition: all 0.5s ease-in-out;
    z-index: 999;
  }
  .icon:hover {
    color: var(--hover-purple);
    transform: scale(1.15);
  }
  .icon-youtube:hover {
    color: var(--text-purple);
    transform: scale(1.1);
  }
  .news-title {
    text-shadow: 2.25px 2.25px 3px var(--hover-purple);
    color: var(--text-purple);
    text-align: center;
    margin-top: 1rem;
    font-size: 4rem;
    margin-bottom: 2rem;
    margin-left: -30rem;
  }
  .youtube {
    z-index: 999;
    display: inline;
    position: absolute;
    text-align: center;
    color: var(--text-purple);
  }
  span.anchor {
    display: block;
    visibility: hidden;
    position: relative;
    padding-top: 28%;
  }
  .activeDelegators,
  .holders,
  .curatorCount,
  .subgraphCount {
    font-weight: 400;
    color: var(--text-purple);
    letter-spacing: 0.0375rem;
    font-size: 2rem;
  }
  .bottom {
    padding-top: 1.25rem;
  }
  .hr {
    border: 3px solid var(--hr);
    border-radius: 5px;
    width: 90%;
    margin: auto;
    box-shadow: 0 0 10px 3px var(--hover-purple);
    position: relative;
    margin-top: 2.5rem;
  }
  .hr2 {
    border: 3px solid var(--hr);
    border-radius: 5px;
    width: 90%;
    margin: auto;
    box-shadow: 0 0 10px 3px var(--hover-purple);
    position: relative;
    margin-top: 4rem;
  }
  .adminHr2 {
    border: 3px solid var(--hr);
    border-radius: 5px;
    width: 70%;
    margin: auto;
    box-shadow: 0 0 10px 3px var(--hover-purple);
    margin-top: 1rem;
    margin-left: 1.9rem;
  }
  .video {
    position: absolute;
    left: 45%;
    margin-top: 0.5rem;
    top: 0%;
    width: 45rem;
    height: 26rem;
  }
  .g-ytsubscribe {
    position: absolute;
    font-weight: 400;
    margin-top: 0.75rem;
    left: 82%;
    top: 45%;
  }
  .admin-video {
    position: absolute;
    left: 60%;
    top: 23%;
  }
  .data-title {
    letter-spacing: 0.0375rem;
    font-weight: 400;
    color: var(--text-purple);
    font-size: 4rem;
    text-align: center;
    position: relative;
  }
  .chartsTitle {
    letter-spacing: 0.0375rem;
    font-weight: 400;
    color: var(--text-purple);
    font-size: 4rem;
    text-align: center;

    position: relative;
  }
  .chartsTitle2 {
    letter-spacing: 0.0375rem;
    font-weight: 400;
    color: var(--text-purple);
    font-size: 4rem;
    text-align: center;
  
  }
  .newsGraph1,
  .newsGraph3 {
    margin-left: -20rem;
  }
  .newsGraph1,
  .newsGraph2,
  .newsGraph3,
  .newsGraph4 {
    width: 150%;
  }
  .chartImg {
    width: 150%;
    margin: auto;
    box-shadow: #4D1D86 6px 6px 10px 3px;
  }
  .center {
    margin: auto;
  }
  .line1 {
    margin-top: 5rem;
  }
  .chartImg1 {
    text-align: center;
    width: 75%;
    margin-top: -250px;
    margin-bottom: -180px;
    margin-left: -6rem;
  }
  .chartImg2,
  .chartImg3,
  .chartImg4,
  .chartImg5 {
    text-align: center;
    width: 75%;
    margin-top: -190px;
    margin-bottom: -190px;
    margin-left: -6rem;
  }
  .chartImg6 {
    text-align: center;
    padding-top: 1rem;
    padding-bottom: 1rem;
    width: 75%;

    margin-left: -6rem;
  }
  .reveal {
    position: relative;
    transform: translateY(50px);
    opacity: 0;
    transition: 0.75s all ease;
  }
  .reveal.active {
    transform: translateY(0);
    opacity: 1;
  }
  .email {
    color: var(--hover-purple);
    font-weight: 500;
  }
  .footerLink {
    color: var(--hover-purple);
  }
  .footerLink:link {
    color: var(--hover-purple);
    cursor: pointer;
  }
  .footerLink:visited {
    color: var(--hover-purple);
    cursor: pointer;
  }
  .footerLink.active,
  .footerLink:focus {
    border-bottom: 0rem solid var(--hover-purple);
    cursor: pointer;
  }
  .footerLink:hover {
    color: var(--text-purple);
    transform: scale(1.05);
    cursor: pointer;
  }
  .footer {
    margin-bottom: -1rem;
    padding: 1rem;
    font-weight: 700;
    margin-top: 1rem;
    font-size: 2rem;
    text-align: center;
    background-color: var(--primary-purple);
    color: var(--secondary-purple);
  }
  .tsparticles {
    display: none;
  }
  .rotate-position,
  .about-rotate-position,
  .phone,
  .about-phone,
  .message,
  .about-message, .home-message {
    display: none;
  }
  .extra-data {
    margin-top: 2rem;
    font-size: 1.25rem;
    text-align: center;
    color: var(--text-purple);
   
  }
  .extra-data2 {
    font-size: 1.25rem;
    text-align: center;
    color: var(--text-purple);
   
  }
  .data-link {
    color: grey;
  }
  .data-link:link {
    color: grey;
    cursor: pointer;
  }
  .data-link:visited {
    color: grey;
    cursor: pointer;
  }
  .data-link.active,
  .data-link:focus {
    border-bottom: 0rem solid white;
    cursor: pointer;
  }
  .data-link:hover {
    color: white;
    transform: scale(1.05);
    cursor: pointer;
  }
  .extra-data {
    font-size: 1.25rem;
    text-align: center;
    color: var(--text-purple);
   
  }
  .data-link {
    color: grey;
  }
  .data-link:link {
    color: grey;
    cursor: pointer;
  }
  .data-link:visited {
    color: grey;
    cursor: pointer;
  }
  .data-link.active,
  .data-link:focus {
    border-bottom: 0rem solid white;
    cursor: pointer;
  }
  .data-link:hover {
    color: white;
    transform: scale(1.05);
    cursor: pointer;
  }
}
/* *************************************************************************
****************************************************************************
***************************************************************************/
/* *************************************************************************
****************************************************************************
***************************************************************************/
@media screen and (min-width: 1401px) and (max-width: 2399px) {
  .monitor-line-carousel {
    width: 80%;
    height: 500px;
    margin: auto auto;
    padding-top: 5rem;
    margin-bottom: 5rem;
  }
  
  .monitor-bar-carousel {
    width: 80%;
    height: 500px;
    margin: auto auto;
    padding-top: 5rem;
    margin-bottom: 5rem;
  }
  
  .monitor-other-carousel {
    width: 80%;
    height: 500px;
    margin: auto auto;
    margin-bottom: 10rem;
    z-index: 999;
    /* position: absolute; */
  }
  
  .monitor-other-charts-position {
    padding-top: 2rem;
    z-index: 999;
  }
  
  
  .mobile-line-carousel{
    width: 80%;
    height: 500px;
    margin: auto auto;
    padding-top: 5rem;
  }
  
  .mobile-bar-carousel {
    width: 80%;
    height: 500px;
    margin: auto auto;
  }
  
  .mobile-other-carousel {
    width: 80%;
    height: 500px;
    margin: auto auto;
  }
  
  .mobile-other-charts-position {
    padding-top: 20rem;
  }
  
  .Holders {
    margin-top: -15rem;
  }
  .no-show-monitor {
    display: none!important;
  }
  .no-monitor {
    display: none!important;
  }
  /* dune start */
.HoldersGraph {
  background-image: linear-gradient(90deg, #1B0D33, #D8D2E4, #D8D2E4,#D8D2E4, #D8D2E4,#D8D2E4,#D8D2E4,#D8D2E4,#D8D2E4,#D8D2E4,#D8D2E4,#D8D2E4,#D8D2E4,#D8D2E4,#D8D2E4,#D8D2E4,#D8D2E4,#D8D2E4,#1B0D33);
  margin-bottom: -36px!important;
  padding-left: 20px!important;
  padding-right: 20px!important;
}
.HoldersGraphBottom {
  background-image: linear-gradient(90deg, #1B0D33, #D8D2E4,#D8D2E4,#D8D2E4,#D8D2E4,#D8D2E4,#D8D2E4,#D8D2E4,#D8D2E4,#D8D2E4,#D8D2E4,#D8D2E4,#D8D2E4,#D8D2E4,#D8D2E4,#D8D2E4,#D8D2E4,#D8D2E4, #1B0D33);
  padding-left: 20px!important;
  padding-right: 20px!important;
}
/* dune end */
  .chatbot-footer-bottom {
    margin-bottom: -80px!important;
   }
  .chatbot-link-logo{
    margin-top: 14rem;
    z-index: 9998;
    position: fixed;
    max-width: 6rem;
    background: white;
    -moz-border-radius: 50px;
    -webkit-border-radius: 50px;
    border-radius: 50px;
    padding: -1rem;
    right: 2%;
    border: 3px var(--secondary-purple) solid;
    box-shadow: 0 0 0 var(--hover-purple);
    animation: pulse 2s infinite;
  }
  .advocate-link-logo{
    margin-top: 22rem;
    z-index: 9998;
    position: fixed;
    max-width: 6rem;
    background: white;
    -moz-border-radius: 50px;
    -webkit-border-radius: 50px;
    border-radius: 25px;
    padding: -1rem;
    right: 2%;
    border: 3px var(--secondary-purple) solid;
    box-shadow: 0 0 0 var(--hover-purple);
    animation: pulse 2s infinite;
  }
  @-webkit-keyframes pulse {
    0% {
      -webkit-box-shadow: 0 0 0 0  var(--text-purple);
    }
    70% {
        -webkit-box-shadow: 0 0 0 15px rgba(244,157,22, 0);
    }
    100% {
        -webkit-box-shadow: 0 0 0 0 rgba(244,157,22, 0);
    }
  }
  @keyframes pulse {
    0% {
      -moz-box-shadow: 0 0 0 0  var(--text-purple);
      box-shadow: 0 0 0 0  var(--text-purple);
    }
    70% {
        -moz-box-shadow: 0 0 0 15px rgba(244,157,22, 0);
        box-shadow: 0 0 0 15px rgba(244,157,22, 0);
    }
    100% {
        -moz-box-shadow: 0 0 0 0 rgba(244,157,22, 0);
        box-shadow: 0 0 0 0 rgba(244,157,22, 0);
    }
  }
  
  .chatbot-link-export{
    margin-top: 18rem;
    z-index: 9998;
    position: fixed;
    max-width: 6rem;
    background: white;
    -moz-border-radius: 50px;
    -webkit-border-radius: 50px;
    border-radius: 50px;
    padding: -1rem;
    right: 2%;
    border: 3px var(--secondary-purple) solid;
    box-shadow: 0 0 0 var(--hover-purple);
    animation: pulse 2s infinite;
  }

  /* Chatbot */
.feedback-text {
  margin-top: 2rem;
}
.feedback-button {
  border: 2px var(--primary-purple) solid!important;
  background-color: var(--text-purple)!important;
  color: var(--primary-purple)!important;
  padding: auto!important;
  margin: auto!important;
}

  .feedback-button:link {
    cursor: pointer;
  }
  .feedback-button:visited {
    cursor: pointer;
  }
  .feedback-button.active,
  .feedback-button:focus {
    cursor: pointer;
  }
  .feedback-button:hover {
    background-color: var(--background)!important;
    color: var(--text-purple)!important;
    transform: scale(1.015);
    cursor: pointer;
  }
  .feedback-button-text {
    font-size: 1.5rem;
  }

.react-chatbot-kit-chat-inner-container {
  background-color: var(--text-purple)!important;
}

.react-chatbot-kit-chat-header {
  font-size: 1.1rem!important;
  color: var(--text-purple)!important;
  background-color: var(--primary-purple)!important;
  padding: 0rem!important;
}

.react-chatbot-kit-chat-input-container {
  background-color:var(--secondary-purple)!important;
  border: 1px solid var(--primary-purple)!important;
}

.react-chatbot-kit-chat-btn-send {
  background-color:var(--primary-purple)!important;
}

.react-chatbot-kit-chat-btn-send-icon:hover {
transform: scale(1.25);
}

.react-chatbot-kit-chat-bot-message-container {
  width: 100%;
}

.react-chatbot-kit-chat-bot-avatar-container {
}

.react-chatbot-kit-chat-bot-avatar-icon {
}

.react-chatbot-kit-chat-bot-avatar-letter {
  display: none;
}
.react-chatbot-kit-chat-container {
  width: 98%!important;
  text-align: center;
  margin: auto;
}

.react-chatbot-kit-chat-bot-avatar-container {
  width: 3.5rem!important;
  height: 3.5rem!important;
  background-color: white!important;
  border: 2px solid var(--primary-purple);
  background-image: url('./GRTDataHubChatBot.png')!important;
  background-repeat: no-repeat!important;
  background-size: 3rem;
  background-position-x: 50%;
  background-position-y: 20%;
}

.react-chatbot-kit-chat-bot-message {
  text-align: left!important;
  width: 100%!important;
  color: var(--text-purple)!important;
  background-color: #0C0B23!important;
  font-size: 1rem!important;
}

.react-chatbot-kit-chat-bot-message-arrow {
  color: #0C0B23!important;
  border-right: 8px solid #0C0B23!important;
}
  .indexer-table-header {
    text-align: center;
    margin-left: 1rem;
  }
  .indexer-table {
    text-align: center;
  }
  .chatbot-data {
    display: inline!important;
  }
  .telegramActiveDelegationCount {
    max-width: 92%;
    margin: auto; 
    text-align: center;
    margin-top: 0.5%;
    }
    .top-telegram {
      margin-top: 0.25rem;
    }
    .chatbot-top {
      margin-top: 5rem;
    }
    .chatbot-page-container {
      background: var(--background);
      color: var(--text-purple);
      width: 100%;
      margin: auto;
      margin-top: 150px;
      padding-left: 10px;
      padding-right: 10px;
      text-align: center;
    }
    .chatbot-text-container {
      background: whitesmoke;
      border-radius: 1rem;
      color: var(--secondary-purple);
      margin: auto!important;
      padding-bottom: 10px;
      padding-top: 10px;
      text-align: center!important;
    }
    .chatbot-page-title {
      font-size: 1.25rem;
      font-weight: 600;
      color: var(--secondary-purple);
      padding-bottom: 0.25rem;
      padding-left: 5px;
      padding-right: 5px;
    }
    .chatbot-page-subtitle {
      font-size: 1.75rem;
      padding-left: 5px;
      padding-right: 5px;
    }
    .chatbot-page-text {
      font-size: 1.25rem;
      margin-bottom: 1rem;
      margin-top: 1rem;
      padding-left: 10px;
      padding-right: 10px;
    }
    /* chatbot end */
  .dataHub-logos {
    max-width: 6rem !important;
    max-height: 35rem !important;
    position: absolute;
    top: 0;
    margin-top: 1rem;
  }
  .mobile-config {
    display: none!important;
  }
  .offcanvas-button, .offcanvas-menu {
    display: none!important;
  }
  .navbar-toggler {
    display: none;
  }
  .panel-container {
    background-color: red;
    box-shadow: #4D1D86 4px 5px 10px 3px;
  }
  .indexer-title {
    text-align: center!important;
    font-weight: 400;
    font-size: 2rem;
    padding-top: 2rem;
    color: var(--text-purple);
    
    margin-top: 6rem;
  }
  .indexer-subtitle {
    text-align: center!important;
    font-weight: 400;
    font-size: 1.75rem;
    color: var(--text-purple);
    
    margin-top: 2rem;
  }
  .indexer {
    z-index: 999;
    position: absolute;
    left: 15%;
    top: 50%;
    width: 35rem;
    height: 20rem;
  }

  .curator {
    z-index: 999;
    position: absolute;
    text-align: center!important;
    top: 50%;
  }

  .map, .tokens, .rewards, .uncollected-rewards {
    margin-top: 2rem;
    box-shadow: #4D1D86 4px 5px 10px 3px;
  }

  .c-map {
    margin-top: 2rem;
    width: 100%;
    height: 30rem;
    margin-left: 5%;
    box-shadow: #4D1D86 4px 5px 10px 3px;
  }

  .d-map {
    margin-top: -3rem;
    margin-left: 5%;
    width: 100%;
    height: 30rem;
    text-align: center!important;
    box-shadow: #4D1D86 4px 5px 10px 3px;
  }

  .rewards-per-hour {
    margin-top: 2rem;
    padding-bottom: 2rem;
    box-shadow: #4D1D86 4px 5px 10px 3px;
  }

  .indexers {
    padding-left: 1rem;
    padding-left: 1rem;
    color: grey;
    font-size:1.25rem;
    margin-right: 1rem;
  }
  .indexers:link{
    color: grey;
    cursor: pointer;
    font-size:1.25rem;
    margin-right: 1rem;
  }
  .indexers:visited {
    color: grey;
    cursor: pointer;
    font-size:1.25rem;
    margin-right: 1rem;
  }
  .indexers.active,
  .indexers:focus {
    border-bottom: 0rem solid var(--hover-purple);
    cursor: pointer;
    font-size:1.25rem;
    margin-right: 1rem;
  }
  .indexers:hover {
    color: var(--text-purple);
    transform: scale(1.05);
    cursor: pointer;
    font-size:1.25rem;
    margin-right: 1rem;
  }
  .indexer-link {
    color: white;
  }
  .indexer-link:link {
    color: white;
    cursor: pointer;
  }
  .indexer-link:visited {
    color: var(--text-purple);
    cursor: pointer;
  }
  .indexer-link.active,
  .indexer-link:focus {
    border-bottom: 0rem solid var(--text-purple);
    cursor: pointer;
  }
  .indexer-link:hover {
    color: var(--text-purple);
    transform: scale(1.05);
    cursor: pointer;
  }

  .cookie-page-text {
    padding-top: 1rem;
    font-size: 1.25rem;
    margin-bottom: 1rem;
    padding-left: 20px;
    padding-right: 20px;
  }
  .cookies-video {
    padding-top: 2rem;
  }
  .cookie-button {
    z-index: 10000;
    position: absolute;
  }
  body > div:nth-child(1) > button {
    z-index: 10000;
    font-size: 3rem;
    position: absolute;
    padding: auto;
    margin-top: -60px;
    width: 20px;
    height: 80px;
    margin-left: 95%;
    background-color: transparent;
    border: none;
  }
  body > div:nth-child(1) > button:hover {
    z-index: 10000;
    font-size: 3rem;
    position: absolute;
    padding: auto;
    margin-top: -60px;
    width: 20px;
    height: 80px;
    margin-left: 95%;
    background-color: transparent;
    border: none;
    cursor: help;
  }
  .extra-data {
    margin-top: 2rem;
    font-size: 1.25rem;
    text-align: center;
    color: var(--text-purple);
   
  }
  .extra-data2 {
    font-size: 1.25rem;
    text-align: center;
    color: var(--text-purple);
   
  }
  .data-link {
    color: grey;
  }
  .data-link:link {
    color: grey;
    cursor: pointer;
  }
  .data-link:visited {
    color: grey;
    cursor: pointer;
  }
  .data-link.active,
  .data-link:focus {
    border-bottom: 0rem solid white;
    cursor: pointer;
  }
  .data-link:hover {
    color: white;
    transform: scale(1.05);
    cursor: pointer;
  }
  .question-bank-container {
    background: var(--background);
    color: var(--text-purple);
    width: 100%;
    margin: auto;
    margin-top: 150px;
    margin-bottom: 50px;
    padding-left: 50px;
    padding-right: 50px;
    text-align: center;
  }
  .about-page-container {
    background: var(--background);
    color: var(--text-purple);
    width: 100%;
    margin: auto;
    margin-top: 150px;
    margin-bottom: 50px;
    padding-left: 50px;
    padding-right: 50px;
    text-align: center;
  }
  .QB-text-container {
    background: white;
    border-radius: 1rem;
    padding: 30px;
    color: var(--secondary-purple);
  }
  .text-container {
    background: white;
    border-radius: 1rem;
    padding: 30px;
    color: var(--secondary-purple);
  }
  .QB-about-hr {
    border: 1.5px solid var(--background);
    color: var(--background);
    border-radius: 5px;
    width: 35%;
    margin: auto;
    margin-bottom: 1rem;
    box-shadow: 0 0 5px 1px var(--background);
  }
  .QB-about-page-title {
    font-size: 2rem;
    font-weight: 600;
    color: var(--secondary-purple);
    padding-bottom: 2rem;
  }
  .QB-about-page-subtitle {
    font-size: 1.75rem;
  }
  .QB-about-page-text {
    font-size: 1.25rem;
    margin-bottom: 1rem;
  }
  .about-hr {
    border: 1.5px solid var(--background);
    color: var(--background);
    border-radius: 5px;
    width: 35%;
    margin: auto;
    margin-bottom: 1rem;
    box-shadow: 0 0 5px 1px var(--background);
  }
  .about-page-title {
    font-size: 2rem;
    font-weight: 600;
    color: var(--secondary-purple);
    padding-bottom: 2rem;
  }
  .about-page-subtitle {
    font-size: 1.75rem;
  }
  .about-page-text {
    font-size: 1.25rem;
    margin-bottom: 1rem;
  }
  .graphiql-link {
    color: var(--secondary-purple);
  }
  .graphiql-link:link {
    color: var(--secondary-purple);
    cursor: pointer;
  }
  .graphiql-link:visited {
    color: var(--secondary-purple);
    cursor: pointer;
  }
  .graphiql-link.active,
  .graphiql-link:focus {
    border-bottom: 0rem solid var(--hover-purple);
    cursor: pointer;
  }
  .graphiql-link:hover {
    color: var(--primary-purple);
    transform: scale(1.05);
    cursor: pointer;
  }
  .cookies-policy-container {
    background: white;
    width: 90%;
    padding-bottom: 1rem;
    margin: auto;
    border-radius: 2rem;
    margin-top: 200px;
    margin-bottom: 50px;
  }
  .termly-cookie-preference-button {
    z-index: 999 !important;
    margin-top: 1rem;
    background: white;
    width: 165px;
    height: 30px;
    border-radius: 3px;
    border: 1px solid var(--background);
    font-family: Roboto, Arial;
    font-size: 10px;
    color: var(--background);
    font-weight: 600;
    box-sizing: border-box;
    cursor: pointer;
    padding: 0;
    outline: none;
  }
  .termly-cookie-preference-button:hover {
    z-index: 999 !important;
    margin-top: 1rem;
    background: var(--background);
    width: 165px;
    height: 30px;
    border-radius: 3px;
    border: 1px solid black;
    font-family: Roboto, Arial;
    font-size: 10px;
    color: var(--text-purple);
    font-weight: 600;
    box-sizing: border-box;
    cursor: pointer;
    padding: 0;
    outline: none;
  }

  .privacy-policy-container {
    background: white;
    width: 90%;
    padding-bottom: 1rem;
    margin: auto;
    border-radius: 2rem;
    margin-top: 150px;
    margin-bottom: 50px;
  }
  .privacy-policy {
    margin-top: 110px;
    font-weight: 700;
    font-size: 1.1rem;
    text-align: center;
    background-color: var(--background);
    color: var(--secondary-purple);
  }
  .privacy-policy-link,
  .about {
    padding-left: 1rem;
    padding-left: 1rem;
    color: grey;
  }
  .privacy-policy-link:link,
  .about:link {
    color: grey;
    cursor: pointer;
  }
  .privacy-policy-link:visited,
  .about:visited {
    color: grey;
    cursor: pointer;
  }
  .privacy-policy-link.active,
  .privacy-policy-link:focus,
  .about.active,
  .about:focus {
    border-bottom: 0rem solid var(--hover-purple);
    cursor: pointer;
  }
  .privacy-policy-link:hover,
  .about:hover {
    color: var(--text-purple);
    transform: scale(1.05);
    cursor: pointer;
  }
  .dropdown-item {
    font-size: 1.5rem;
  }
  .subscribeNote {
    margin-left: -22rem;
    width: 40rem;
    font-size: 1.2rem;
    color: white;
  }
  /* loader new start  */
  .loader:empty {
    position: absolute;
    top: calc(50% + 15.5rem);
    left: calc(50% + 2em);
    width: 4em;
    height: 4em;
    border: 0.35em solid white;
    border-left: 0.35em solid #10034B;
    border-radius: 50%;
    animation: load8 0.8s infinite linear;
  }
  .loadingTitle {
    position: absolute;
    top: 27rem;
    left: calc(50% - 3em);
    font-size: 2rem;
    color: #10034B;
    -webkit-box-reflect:below 0px linear-gradient(transparent, #0005);
  }
  .loaderLogo {
    position: absolute;
    top: 14rem;
    left: calc(50% - 8em);
    width: 15em;
    height: 12em;
  }
  @keyframes load8 {
    0% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(360deg);
    }
  }
  /* loader new end  */
  .C {
    font-size: 1.25rem;
  }
  .logo {
    width: 7rem;
    height: 8rem;
    margin-left: 5rem;
    padding-top: 0.5rem;
  }
  .data-logo {
    max-width: 2rem !important;
    height: 2rem !important;
    position: absolute;
  }
  .brand-text {
    color: var(--text-purple);
    display: inline;
    margin-left: -0.5rem;
    letter-spacing: 0.0375rem;
    font-size: 2.5rem;
  }
  .header-text {
    font-weight: 400;
    font-size: 3rem;
    margin-left: 10rem;
    padding-top: 2rem;
    color: var(--text-purple);
  
    margin-top: 6rem;
  }
  .header-sub-text {
    font-weight: 400;
    font-size: 2rem;
    text-align: center!important;
    margin: auto!important;
    width: 90%;
    color: var(--text-purple);
    display: block;
 
  }
  .adminHeader-text {
    font-weight: 400;
    font-size: 3rem;
    text-align: center;
    margin: auto;
    padding-top: 2rem;
    color: var(--text-purple);
   
    margin-top: 6rem;
  }
  .adminHeader-text2 {
    font-weight: 400;
    font-size: 3rem;
    text-align: center;
    margin: auto;
    color: var(--text-purple);
  
    margin-top: 1rem;
  }
  .adminSubheader-text {
    font-weight: 400;
    font-size: 2.25rem;
    margin-left: 6rem;
    color: var(--text-purple);
    text-shadow: 2px 2px var(--hover-purple);
    margin-top: 1.25rem;
  }
  .adminSubheader-text2 {
    font-weight: 400;
    font-size: 1.5rem;
    margin-left: 6rem;
    margin-right: 6rem;
    color: var(--text-purple);
  
    margin-top: 1.25rem;
  }
  .note {
    text-decoration: underline;
  }
  .TGN {
    margin-left: 4rem;
    margin-right: -8rem;
    color: var(--primary-purple);
    font-weight: 400;
    font-size: 2rem;
    cursor: default;
  }
  .TGN:link {
    color: var(--primary-purple);
    cursor: default;
    margin-left: 4rem;
    margin-right: -8rem;
  }
  .TGN:visited {
    color: var(--primary-purple);
    cursor: default;
    margin-left: 4rem;
    margin-right: -8rem;
  }
  .TGN.active,
  .TGN:focus {
    border-bottom: 0rem solid var(--primary-purple);
    cursor: default;
    margin-left: 4rem;
    margin-right: -8rem;
  }
  .TGN:hover {
    border-bottom: 0rem solid var(--primary-purple);
    padding: 0;
    color: var(--primary-purple);
    cursor: default;
    margin-left: 4rem;
    margin-right: -8rem;
  }
  .adminPortal {
    font-weight: 400;
    font-size: 2rem;
    color: var(--text-purple);
  }
  .adminPortal {
    padding: 0;
    margin-right: -2rem;
    margin-left: 2rem;
  }
  .adminPortal:link {
    color: var(--hover-purple);
  }
  .adminPortal:visited {
    color: var(--text-purple);
  }
  .adminPortal.active,
  .adminPortal:focus {
    padding: 0;
    transition: all 0.5s ease-in-out;
  }
  .adminPortal:hover {
    padding: 0;
    color: var(--hover-purple);
    transform: scale(1.05);
  }
  .logOut {
    display: inline;
  }
  .logOutButton {
    margin-left: 4rem;
    margin-right: -4rem;
  }
  nav a.nav-link {
    font-weight: 400;
    font-size: 2rem;
    color: var(--text-purple);
  }
  .navbar-nav > li > a {
    border-bottom: 0.01875rem solid var(--transparent);
    padding: 0;
  }
  .navbar-nav > li > a:link {
    color: var(--hover-purple);
  }
  .navbar-nav > li > a:visited {
    color: var(--text-purple);
  }
  .navbar-nav > li > a.active,
  .navbar-nav > li > a:focus {
    border-bottom: 0.01875rem solid var(--hover-purple);
    padding: 0;
    transition: all 0.5s ease-in-out;
  }
  .navbar-nav > li > a:hover {
    border-bottom: 0.01875rem solid var(--hover-purple);
    padding: 0;
    color: var(--hover-purple);
    transform: scale(1.05);
  }
  .line {
    margin-left: 1rem;
  }
  .links {
    margin-left: auto;
    padding-right: 5rem;
  }
  .menu-icon {
    color: var(--secondary-purple);
  }
  .main-info {
    background-image: url("TopBackground.svg");
    background-repeat: no-repeat;
    background-size: 100% auto;
    width: 100%;
    height: 100%;
    margin-top: 90px;
  }
  .community-links {
    margin-left: 11rem;
    padding-top: 1rem;
    position: absolute;
    z-index: 999;
  }
  .telegram-icon,
  .twitter-icon,
  .reddit-icon,
  .youtube-icon {
    display: inline;
    padding: 1rem;
    transition: all 0.5s ease-in-out;
    z-index: 999;
  }
  .icon:hover {
    color: var(--hover-purple);
    transform: scale(1.15);
  }
  .icon-youtube:hover {
    color: var(--text-purple);
    transform: scale(1.1);
  }
  .news-title {
    text-shadow: 2.25px 2.25px 3px var(--hover-purple);
    color: var(--text-purple);
    text-align: center;
    margin-top: 1rem;
    font-size: 2.5rem;
    margin-bottom: 2rem;
    margin-left: -30rem;
  }
  .youtube {
    z-index: 999;
    display: inline;
    position: absolute;
    text-align: center;
    color: var(--text-purple);
  }
  span.anchor {
    display: block;
    visibility: hidden;
    position: relative;
    padding-top: 17.5%;
  }
  .activeDelegators,
  .holders,
  .curatorCount,
  .subgraphCount {
    font-weight: 400;
    color: var(--text-purple);
    letter-spacing: 0.0375rem;
    font-size: 2rem;
  }
  .bottom {
    padding-top: 1.25rem;
  }
  .hr {
    border: 3px solid var(--hr);
    border-radius: 5px;
    width: 90%;
    margin: auto;
    box-shadow: 0 0 10px 3px var(--hover-purple);
    position: relative;
    margin-top: 2.5rem;
  }
  .hr2 {
    border: 3px solid var(--hr);
    border-radius: 5px;
    width: 90%;
    margin: auto;
    box-shadow: 0 0 10px 3px var(--hover-purple);
    position: relative;
    margin-top: 2rem;
  }
  .adminHr2 {
    border: 3px solid var(--hr);
    border-radius: 5px;
    width: 70%;
    margin: auto;
    box-shadow: 0 0 10px 3px var(--hover-purple);
    margin-top: 1rem;
    margin-left: 1.9rem;
  }
  .video {
    position: absolute;
    left: 47%;
    margin-top: 0.5rem;
    top: 0%;
    width: 30rem;
    height: 18rem;
  }
  .g-ytsubscribe {
    position: absolute;
    font-weight: 400;
    margin-top: 0.75rem;
    left: 76%;
    top: 32%;
  }
  .admin-video {
    position: absolute;
    left: 60%;
    top: 23%;
  }
  .data-title {
    letter-spacing: 0.0375rem;
    font-weight: 400;
    color: var(--text-purple);
    font-size: 3.5rem;
    text-align: center;
    position: relative;
  }
  .chartsTitle {
    letter-spacing: 0.0375rem;
    font-weight: 400;
    color: var(--text-purple);
    font-size: 3.5rem;
    text-align: center;
 
    position: relative;
  }
  .chartsTitle2 {
    letter-spacing: 0.0375rem;
    font-weight: 400;
    color: var(--text-purple);
    font-size: 3.5rem;
    text-align: center;
  
  }
  .adminChartsTitle {
    letter-spacing: 0.0375rem;
    font-weight: 400;
    color: var(--text-purple);
    font-size: 3.5rem;
    text-align: center;
    padding-top: 1rem;
    text-shadow: 2px 2px 4px var(--hover-purple);
    position: relative;
  }
  .adminChartsTitle2 {
    letter-spacing: 0.0375rem;
    font-weight: 400;
    color: var(--text-purple);
    font-size: 3.5rem;
    margin-top: 6rem;
    text-align: center;
    text-shadow: 2px 2px 4px var(--hover-purple);
  }
  .newsGraph {
    width: 100%;
    box-shadow: #4D1D86 4px 5px 10px 3px;
  }
  .chartImg {
    width: 75%;
    box-shadow: #4D1D86 6px 6px 10px 3px;
    margin: auto;
  }
  .center {
    margin: auto;
  }
  .line1 {
    margin-top: 5rem;
  }
  .chartImg1 {
    text-align: center;
    width: 100%;
    margin-top: -210px;
    margin-bottom: -180px;
  }
  .chartImg2,
  .chartImg3,
  .chartImg4,
  .chartImg5 {
    text-align: center;
    width: 100%;
    margin-top: -150px;
    margin-bottom: -180px;
  }
  .chartImg6 {
    text-align: center;
    padding-top: 1rem;
    padding-bottom: 1rem;
    width: 100%;
 
  }
  .reveal {
    position: relative;
    transform: translateY(50px);
    opacity: 0;
    transition: 0.75s all ease;
  }
  .reveal.active {
    transform: translateY(0);
    opacity: 1;
  }
  .email {
    color: var(--hover-purple);
    font-weight: 500;
  }
  .footerLink {
    color: var(--hover-purple);
  }
  .footerLink:link {
    color: var(--hover-purple);
    cursor: pointer;
  }
  .footerLink:visited {
    color: var(--hover-purple);
    cursor: pointer;
  }
  .footerLink.active,
  .footerLink:focus {
    border-bottom: 0rem solid var(--hover-purple);
    cursor: pointer;
  }
  .footerLink:hover {
    color: var(--text-purple);
    transform: scale(1.05);
    cursor: pointer;
  }
  .footer {
    margin-bottom: -1rem;
    padding: 1rem;
    font-weight: 700;
    margin-top: 1rem;
    font-size: 1.1rem;
    text-align: center;
    background-color: var(--primary-purple);
    color: var(--secondary-purple);
  }
  .tsparticles {
    position: absolute;
    height: 100%;
    width: 100%;
  }
  .rotate-position,
  .about-rotate-position,
  .phone,
  .about-phone,
  .message,
  .about-message, .home-message {
    display: none;
  }
}
/* *************************************************************************
****************************************************************************
***************************************************************************/
/* *************************************************************************
****************************************************************************
***************************************************************************/
/* My Laptop */
@media screen and (min-width: 1082px) and (max-width: 1400px) {
  .monitor-line-carousel {
    width: 80%;
    height: 500px;
    margin: auto auto;
    padding-top: 5rem;
    margin-bottom: 5rem;
  }
  
  .monitor-bar-carousel {
    width: 80%;
    height: 500px;
    margin: auto auto;
    padding-top: 5rem;
    margin-bottom: 5rem;
  }
  
  .monitor-other-carousel {
    width: 80%;
    height: 500px;
    margin: auto auto;
    margin-bottom: 10rem;
    z-index: 999;
    /* position: absolute; */
  }
  
  .monitor-other-charts-position {
    padding-top: 2rem;
    z-index: 999;
  }
  
  
  .mobile-line-carousel{
    width: 80%;
    height: 500px;
    margin: auto auto;
    padding-top: 5rem;
  }
  
  .mobile-bar-carousel {
    width: 80%;
    height: 500px;
    margin: auto auto;
  }
  
  .mobile-other-carousel {
    width: 80%;
    height: 500px;
    margin: auto auto;
  }
  
  .mobile-other-charts-position {
    padding-top: 20rem;
  }
  
  .Holders {
    margin-top: -15rem;
  }
  .no-show-monitor {
    display: none!important;
  }
  .no-monitor {
    display: none!important;
  }
  /* dune start */
.HoldersGraph {
  background-image: linear-gradient(90deg, #1B0D33, #D8D2E4, #D8D2E4,#D8D2E4, #D8D2E4,#D8D2E4,#D8D2E4,#D8D2E4,#D8D2E4,#D8D2E4,#D8D2E4,#D8D2E4,#D8D2E4,#D8D2E4,#D8D2E4,#D8D2E4,#D8D2E4,#D8D2E4,#1B0D33);
  margin-bottom: -36px!important;
  padding-left: 20px!important;
  padding-right: 20px!important;
}
.HoldersGraphBottom {
  background-image: linear-gradient(90deg, #1B0D33, #D8D2E4,#D8D2E4,#D8D2E4,#D8D2E4,#D8D2E4,#D8D2E4,#D8D2E4,#D8D2E4,#D8D2E4,#D8D2E4,#D8D2E4,#D8D2E4,#D8D2E4,#D8D2E4,#D8D2E4,#D8D2E4,#D8D2E4, #1B0D33);
  padding-left: 20px!important;
  padding-right: 20px!important;
}
/* dune end */
  .chatbot-footer-bottom {
    margin-bottom: -80px!important;
   }
  .chatbot-link-logo{
    margin-top: 15rem;
    z-index: 9998;
    position: fixed;
    max-width: 6rem;
    background: white;
    -moz-border-radius: 50px;
    -webkit-border-radius: 50px;
    border-radius: 50px;
    padding: -1rem;
    right: 2%;
    border: 3px var(--secondary-purple) solid;
    box-shadow: 0 0 0 var(--hover-purple);
    animation: pulse 2s infinite;
  }
  .advocate-link-logo{
    margin-top: 23rem;
    z-index: 9998;
    position: fixed;
    max-width: 6rem;
    background: white;
    -moz-border-radius: 50px;
    -webkit-border-radius: 50px;
    border-radius: 50px;
    padding: -1rem;
    right: 2%;
    border: 3px var(--secondary-purple) solid;
    box-shadow: 0 0 0 var(--hover-purple);
    animation: pulse 2s infinite;
  }
  @-webkit-keyframes pulse {
    0% {
      -webkit-box-shadow: 0 0 0 0  var(--text-purple);
    }
    70% {
        -webkit-box-shadow: 0 0 0 15px rgba(244,157,22, 0);
    }
    100% {
        -webkit-box-shadow: 0 0 0 0 rgba(244,157,22, 0);
    }
  }
  @keyframes pulse {
    0% {
      -moz-box-shadow: 0 0 0 0  var(--text-purple);
      box-shadow: 0 0 0 0  var(--text-purple);
    }
    70% {
        -moz-box-shadow: 0 0 0 15px rgba(244,157,22, 0);
        box-shadow: 0 0 0 15px rgba(244,157,22, 0);
    }
    100% {
        -moz-box-shadow: 0 0 0 0 rgba(244,157,22, 0);
        box-shadow: 0 0 0 0 rgba(244,157,22, 0);
    }
  }
  
  .chatbot-link-export{
    margin-top: 20rem;
    z-index: 9998;
    position: fixed;
    max-width: 6rem;
    background: white;
    -moz-border-radius: 50px;
    -webkit-border-radius: 50px;
    border-radius: 50px;
    padding: -1rem;
    right: 2%;
    border: 3px var(--secondary-purple) solid;
    box-shadow: 0 0 0 var(--hover-purple);
    animation: pulse 2s infinite;
  }
  
/* Chatbot */
.feedback-text {
  margin-top: 2rem;
}
.feedback-button {
  border: 2px var(--primary-purple) solid!important;
  background-color: var(--text-purple)!important;
  color: var(--primary-purple)!important;
  padding: auto!important;
  margin: auto!important;
}

  .feedback-button:link {
    cursor: pointer;
  }
  .feedback-button:visited {
    cursor: pointer;
  }
  .feedback-button.active,
  .feedback-button:focus {
    cursor: pointer;
  }
  .feedback-button:hover {
    background-color: var(--background)!important;
    color: var(--text-purple)!important;
    transform: scale(1.015);
    cursor: pointer;
  }
  .feedback-button-text {
    font-size: 1.5rem;
  }

.react-chatbot-kit-chat-inner-container {
  background-color: var(--text-purple)!important;
}

.react-chatbot-kit-chat-header {
  font-size: 1.1rem!important;
  color: var(--text-purple)!important;
  background-color: var(--primary-purple)!important;
  padding: 0rem!important;
}

.react-chatbot-kit-chat-input-container {
  background-color:var(--secondary-purple)!important;
  border: 1px solid var(--primary-purple)!important;
}

.react-chatbot-kit-chat-btn-send {
  background-color:var(--primary-purple)!important;
}

.react-chatbot-kit-chat-btn-send-icon:hover {
transform: scale(1.25);
}

.react-chatbot-kit-chat-bot-message-container {
  width: 100%;
}

.react-chatbot-kit-chat-bot-avatar-container {
}

.react-chatbot-kit-chat-bot-avatar-icon {
}

.react-chatbot-kit-chat-bot-avatar-letter {
  display: none;
}
.react-chatbot-kit-chat-container {
  width: 98%!important;
  text-align: center;
  margin: auto;
}

.react-chatbot-kit-chat-bot-avatar-container {
  width: 3.5rem!important;
  height: 3.5rem!important;
  background-color: white!important;
  border: 2px solid var(--primary-purple);
  background-image: url('./GRTDataHubChatBot.png')!important;
  background-repeat: no-repeat!important;
  background-size: 3rem;
  background-position-x: 50%;
  background-position-y: 20%;
}

.react-chatbot-kit-chat-bot-message {
  text-align: left!important;
  width: 100%!important;
  color: var(--text-purple)!important;
  background-color: #0C0B23!important;
  font-size: 1rem!important;
}

.react-chatbot-kit-chat-bot-message-arrow {
  color: #0C0B23!important;
  border-right: 8px solid #0C0B23!important;
}
  .indexer-table-header {
    text-align: center;
    margin-left: 1rem;
  }
  .indexer-table {
    text-align: center;
  }
  .chatbot-data {
    display: inline!important;
  }
  .telegramActiveDelegationCount {
    max-width: 92%;
    margin: auto; 
    text-align: center;
    margin-top: 0.5%;
    }
    .top-telegram {
      margin-top: 0.25rem;
    }
    .chatbot-top {
      margin-top: 5rem;
    }
    .chatbot-page-container {
      background: var(--background);
      color: var(--text-purple);
      width: 100%;
      margin: auto;
      margin-top: 150px;
      padding-left: 10px;
      padding-right: 10px;
      text-align: center;
    }
    .chatbot-text-container {
      background: whitesmoke;
      border-radius: 1rem;
      color: var(--secondary-purple);
      margin: auto!important;
      padding-bottom: 10px;
      padding-top: 10px;
      text-align: center!important;
    }
    .chatbot-page-title {
      font-size: 1.25rem;
      font-weight: 600;
      color: var(--secondary-purple);
      padding-bottom: 0.25rem;
      padding-left: 5px;
      padding-right: 5px;
    }
    .chatbot-page-subtitle {
      font-size: 1.75rem;
      padding-left: 5px;
      padding-right: 5px;
    }
    .chatbot-page-text {
      font-size: 1.25rem;
      margin-bottom: 1rem;
      margin-top: 1rem;
      padding-left: 10px;
      padding-right: 10px;
    }
    /* chatbot end */
  .dataHub-logos {
    max-width: 6rem !important;
    max-height: 35rem !important;
    position: absolute;
    top: 0;
    margin-top: 1rem;
  }
  .mobile-config {
    display: none!important;
  }
  .offcanvas-button, .offcanvas-menu {
    display: none!important;
  }
  .navbar-toggler {
    display: none;
  }
  .indexer-title {
    text-align: center!important;
    font-weight: 400;
    font-size: 2rem;
    padding-top: 2rem;
    color: var(--text-purple);
    
    margin-top: 6rem;
  }
  .indexer-subtitle {
    text-align: center!important;
    font-weight: 400;
    font-size: 1.75rem;
    color: var(--text-purple);
    
    margin-top: 2rem;
  }
  .indexer {
    z-index: 999;
    position: absolute;
    left: 15%;
    top: 50%;
    width: 35rem;
    height: 20rem;
  }
  
  .curator {
    z-index: 999;
    position: absolute;
    text-align: center!important;
    top: 50%;
  }

  .map, .tokens, .rewards, .uncollected-rewards {
    margin-top: 2rem;
    box-shadow: #4D1D86 4px 5px 10px 3px;
  }

  .b-map {
    margin-top: 2rem;
    margin-left: 0.2rem;
    width: 1240px;
    height: 2390px;
    text-align: center!important;
    box-shadow: #4D1D86 4px 5px 10px 3px;
  }

  .c-map {
    margin-top: 2rem;
    margin-left: 0.2rem;
    width: 1240px;
    height: 1490px;
    text-align: center!important;
    box-shadow: #4D1D86 4px 5px 10px 3px;
  }

  .d-map {
    margin-top: -3rem;
    margin-left: 1rem;
    width: 1240px;
    height: 3800px;
    text-align: center!important;
    box-shadow: #4D1D86 4px 5px 10px 3px;
  }

  .rewards-per-hour {
    margin-top: 2rem;
    padding-bottom: 2rem;
    box-shadow: #4D1D86 4px 5px 10px 3px;
  }

  .indexers {
    padding-left: 1rem;
    padding-left: 1rem;
    color: grey;
    font-size:1.25rem;
    margin-right: 1rem;
  }
  .indexers:link{
    color: grey;
    cursor: pointer;
    font-size:1.25rem;
    margin-right: 1rem;
  }
  .indexers:visited {
    color: grey;
    cursor: pointer;
    font-size:1.25rem;
    margin-right: 1rem;
  }
  .indexers.active,
  .indexers:focus {
    border-bottom: 0rem solid var(--hover-purple);
    cursor: pointer;
    font-size:1.25rem;
    margin-right: 1rem;
  }
  .indexers:hover {
    color: var(--text-purple);
    transform: scale(1.05);
    cursor: pointer;
    font-size:1.25rem;
    margin-right: 1rem;
  }
  .indexer-link {
    color: white;
  }
  .indexer-link:link {
    color: white;
    cursor: pointer;
  }
  .indexer-link:visited {
    color: var(--text-purple);
    cursor: pointer;
  }
  .indexer-link.active,
  .indexer-link:focus {
    border-bottom: 0rem solid var(--text-purple);
    cursor: pointer;
  }
  .indexer-link:hover {
    color: var(--text-purple);
    transform: scale(1.05);
    cursor: pointer;
  }


  .cookie-page-text {
    padding-top: 1rem;
    font-size: 1.25rem;
    margin-bottom: 1rem;
    padding-left: 20px;
    padding-right: 20px;
  }
  .cookies-video {
    padding-top: 2rem;
  }
  .cookie-button {
    z-index: 10000;
    position: absolute;
  }
  body > div:nth-child(1) > button {
    z-index: 10000;
    font-size: 3rem;
    position: absolute;
    padding: auto;
    margin-top: -60px;
    width: 20px;
    height: 80px;
    margin-left: 94%;
    background-color: transparent;
    border: none;
  }
  body > div:nth-child(1) > button:hover {
    z-index: 10000;
    font-size: 3rem;
    position: absolute;
    padding: auto;
    margin-top: -60px;
    width: 20px;
    height: 80px;
    margin-left: 94%;
    background-color: transparent;
    border: none;
    cursor: help;
  }
  .question-bank-container {
    background: var(--background);
    color: var(--text-purple);
    width: 100%;
    margin: auto;
    margin-top: 150px;
    margin-bottom: 50px;
    padding-left: 50px;
    padding-right: 50px;
    text-align: center;
  }
  .about-page-container {
    background: var(--background);
    color: var(--text-purple);
    width: 100%;
    margin: auto;
    margin-top: 150px;
    margin-bottom: 50px;
    padding-left: 50px;
    padding-right: 50px;
    text-align: center;
  }
  .QB-text-container {
    background: white;
    border-radius: 1rem;
    padding: 30px;
    color: var(--secondary-purple);
  }
  .text-container {
    background: white;
    border-radius: 1rem;
    padding: 30px;
    color: var(--secondary-purple);
  }
  .QB-about-hr {
    border: 1.5px solid var(--background);
    color: var(--background);
    border-radius: 5px;
    width: 35%;
    margin: auto;
    margin-bottom: 1rem;
    box-shadow: 0 0 5px 1px var(--background);
  }
  .QB-about-page-title {
    font-size: 2rem;
    font-weight: 600;
    color: var(--secondary-purple);
    padding-bottom: 2rem;
  }
  .QB-about-page-subtitle {
    font-size: 1.75rem;
  }
  .QB-about-page-text {
    font-size: 1.25rem;
    margin-bottom: 1rem;
  }
  .about-hr {
    border: 1.5px solid var(--background);
    color: var(--background);
    border-radius: 5px;
    width: 35%;
    margin: auto;
    margin-bottom: 1rem;
    box-shadow: 0 0 5px 1px var(--background);
  }
  .about-page-title {
    font-size: 2rem;
    font-weight: 600;
    color: var(--secondary-purple);
    padding-bottom: 2rem;
  }
  .about-page-subtitle {
    font-size: 1.75rem;
  }
  .about-page-text {
    font-size: 1.25rem;
    margin-bottom: 1rem;
  }
  .graphiql-link {
    color: var(--secondary-purple);
  }
  .graphiql-link:link {
    color: var(--secondary-purple);
    cursor: pointer;
  }
  .graphiql-link:visited {
    color: var(--secondary-purple);
    cursor: pointer;
  }
  .graphiql-link.active,
  .graphiql-link:focus {
    border-bottom: 0rem solid var(--hover-purple);
    cursor: pointer;
  }
  .graphiql-link:hover {
    color: var(--primary-purple);
    transform: scale(1.05);
    cursor: pointer;
  }
  .cookies-policy-container {
    background: white;
    width: 90%;
    padding-bottom: 1rem;
    margin: auto;
    border-radius: 2rem;
    margin-top: 150px;
    margin-bottom: 50px;
  }
  .termly-cookie-preference-button {
    z-index: 999 !important;
    margin-top: 1rem;
    background: white;
    width: 165px;
    height: 30px;
    border-radius: 3px;
    border: 1px solid var(--background);
    font-family: Roboto, Arial;
    font-size: 10px;
    color: var(--background);
    font-weight: 600;
    box-sizing: border-box;
    cursor: pointer;
    padding: 0;
    outline: none;
  }
  .termly-cookie-preference-button:hover {
    z-index: 999 !important;
    margin-top: 1rem;
    background: var(--background);
    width: 165px;
    height: 30px;
    border-radius: 3px;
    border: 1px solid black;
    font-family: Roboto, Arial;
    font-size: 10px;
    color: var(--text-purple);
    font-weight: 600;
    box-sizing: border-box;
    cursor: pointer;
    padding: 0;
    outline: none;
  }

  .privacy-policy-container {
    background: white;
    width: 90%;
    padding-bottom: 1rem;
    margin: auto;
    border-radius: 2rem;
    margin-top: 150px;
    margin-bottom: 50px;
  }
  .privacy-policy {
    margin-top: 110px;
    font-weight: 700;
    font-size: 1.1rem;
    text-align: center;
    background-color: var(--background);
    color: var(--secondary-purple);
  }
  .privacy-policy-link,
  .about {
    padding-left: 1rem;
    padding-left: 1rem;
    color: grey;
  }
  .privacy-policy-link:link,
  .about:link {
    color: grey;
    cursor: pointer;
  }
  .privacy-policy-link:visited,
  .about:visited {
    color: grey;
    cursor: pointer;
  }
  .privacy-policy-link.active,
  .privacy-policy-link:focus,
  .about.active,
  .about:focus {
    border-bottom: 0rem solid var(--hover-purple);
    cursor: pointer;
  }
  .privacy-policy-link:hover,
  .about:hover {
    color: var(--text-purple);
    transform: scale(1.05);
    cursor: pointer;
  }

  .subscribeNote {
    margin-left: -14rem;
    width: 30rem;
    font-size: 0.75rem;
    color: white;
  }
  /* loader new start  */
  .loader:empty {
    position: absolute;
    top: calc(50% + 15.5rem);
    left: calc(50% - 2em);
    width: 4em;
    height: 4em;
    border: 0.35em solid white;
    border-left: 0.35em solid #10034B;
    border-radius: 50%;
    animation: load8 0.8s infinite linear;
  }
  .loadingTitle {
    position: absolute;
    top: 27rem;
    left: calc(50% - 5em);
    font-size: 2rem;
    color: #10034B;
    -webkit-box-reflect:below 0px linear-gradient(transparent, #0005);
  }
  .loaderLogo {
    position: absolute;
    top: 14rem;
    left: calc(50% - 12em);
    width: 15em;
    height: 12em;
  }
  @keyframes load8 {
    0% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(360deg);
    }
  }
  /* loader new end  */
  .C {
    font-size: 1rem;
  }
  .logo {
    width: 6rem;
    height: 5rem;
    margin-left: 5rem;
   margin-top: -0.5rem;
  }
  .data-logo {
    max-width: 1.25rem !important;
    height: 1.25rem !important;
    position: absolute;
  }
  .brand-text {
    color: var(--text-purple);
    display: inline;
    margin-left: -0.5rem;
    letter-spacing: 0.0375rem;
    font-size: 1.5rem;
  }
  .header-text {
    font-weight: 400;
    font-size: 2rem;
    margin-left: 10rem;
    padding-top: 1rem;
    color: var(--text-purple);
 
    margin-top: 6rem;
  }
  .header-sub-text {
    font-weight: 400;
    font-size: 2rem;
    text-align: center!important;
    margin: auto!important;
    width: 90%;
    color: var(--text-purple);
    display: block;

  }
  .adminHeader-text {
    font-weight: 400;
    font-size: 2rem;
    text-align: center;
    margin: auto;
    padding-top: 2rem;
    color: var(--text-purple);
  
    margin-top: 6rem;
  }
  .adminHeader-text2 {
    font-weight: 400;
    font-size: 2rem;
    text-align: center;
    margin: auto;
    color: var(--text-purple);
  
    margin-top: 1rem;
  }
  .adminSubheader-text {
    font-weight: 400;
    font-size: 1.75rem;
    margin-left: 6rem;
    color: var(--text-purple);
    
    margin-top: 1.25rem;
  }
  .adminSubheader-text2 {
    font-weight: 400;
    font-size: 1.2rem;
    margin-left: 6rem;
    margin-right: 6rem;
    color: var(--text-purple);
   
    margin-top: 1.25rem;
  }
  .note {
    text-decoration: underline;
  }
  .TGN {
    margin-left: 4rem;
    margin-right: -8rem;
    color: var(--primary-purple);
    font-weight: 400;
    font-size: 1.25rem;
    cursor: default;
  }
  .TGN:link {
    color: var(--primary-purple);
    cursor: default;
    margin-left: 4rem;
    margin-right: -8rem;
  }
  .TGN:visited {
    color: var(--primary-purple);
    cursor: default;
    margin-left: 4rem;
    margin-right: -8rem;
  }
  .TGN.active,
  .TGN:focus {
    border-bottom: 0rem solid var(--primary-purple);
    cursor: default;
    margin-left: 4rem;
    margin-right: -8rem;
  }
  .TGN:hover {
    border-bottom: 0rem solid var(--primary-purple);
    padding: 0;
    color: var(--primary-purple);
    cursor: default;
    margin-left: 4rem;
    margin-right: -8rem;
  }
  .adminPortal {
    font-weight: 400;
    font-size: 1.25rem;
    color: var(--text-purple);
  }
  .adminPortal {
    padding: 0;
    margin-right: -2rem;
    margin-left: 2rem;
  }
  .adminPortal:link {
    color: var(--hover-purple);
  }
  .adminPortal:visited {
    color: var(--text-purple);
  }
  .adminPortal.active,
  .adminPortal:focus {
    padding: 0;
    transition: all 0.5s ease-in-out;
  }
  .adminPortal:hover {
    padding: 0;
    color: var(--hover-purple);
    transform: scale(1.05);
  }
  .logOut {
    display: inline;
  }
  .logOutButton {
    margin-left: 4rem;
    margin-right: -4rem;
  }
  nav a.nav-link {
    font-weight: 400;
    font-size: 1.25rem;
    color: var(--text-purple);
  }
  .navbar-nav > li > a {
    border-bottom: 0.01875rem solid var(--transparent);
    padding: 0;
  }
  .navbar-nav > li > a:link {
    color: var(--hover-purple);
  }
  .navbar-nav > li > a:visited {
    color: var(--text-purple);
  }
  .navbar-nav > li > a.active,
  .navbar-nav > li > a:focus {
    border-bottom: 0.01875rem solid var(--hover-purple);
    padding: 0;
    transition: all 0.5s ease-in-out;
  }
  .navbar-nav > li > a:hover {
    border-bottom: 0.01875rem solid var(--hover-purple);
    padding: 0;
    color: var(--hover-purple);
    transform: scale(1.05);
  }
  .line {
    margin-left: 1rem;
  }
  .links {
    margin-left: auto;
    padding-right: 5rem;
  }
  .menu-icon {
    color: var(--secondary-purple);
  }
  .main-info {
    background-image: url("TopBackground.svg");
    background-repeat: no-repeat;
    background-size: 100% auto;
    width: 100%;
    height: 100%;
    margin-top: 90px;
  }
  .community-links {
    margin-left: 10rem;
    padding-top: 1rem;
    position: absolute;
    z-index: 999;
  }
  .telegram-icon,
  .twitter-icon,
  .reddit-icon,
  .youtube-icon {
    display: inline;
    padding: 1rem;
    transition: all 0.5s ease-in-out;
    z-index: 999;
  }
  .icon:hover {
    color: var(--hover-purple);
    transform: scale(1.15);
  }
  .icon-youtube:hover {
    color: var(--text-purple);
    transform: scale(1.1);
  }
  .news-title {
    text-shadow: 2.25px 2.25px 3px var(--hover-purple);
    color: var(--text-purple);
    text-align: center;
    margin-top: 1rem;
    margin-bottom: 1rem;
    margin-left: -30rem;
  }
  .youtube {
    z-index: 999;
    display: inline;
    position: absolute;
    text-align: center;
    color: var(--text-purple);
  }
  span.anchor {
    display: block;
    visibility: hidden;
    position: relative;
    padding-top: 17.5%;
  }
  .activeDelegators,
  .holders,
  .curatorCount,
  .subgraphCount {
    font-weight: 400;
    color: var(--text-purple);
    letter-spacing: 0.0375rem;
    font-size: 1.5rem;
  }
  .bottom {
    padding-top: 1.25rem;
  }
  .hr {
    border: 3px solid var(--hr);
    border-radius: 5px;
    width: 90%;
    margin: auto;
    box-shadow: 0 0 10px 3px var(--hover-purple);
    position: relative;
    margin-top: 2.5rem;
  }
  .hr2 {
    border: 3px solid var(--hr);
    border-radius: 5px;
    width: 90%;
    margin: auto;
    box-shadow: 0 0 10px 3px var(--hover-purple);
    position: relative;
    margin-top: 4rem;
  }
  .adminHr2 {
    border: 3px solid var(--hr);
    border-radius: 5px;
    width: 70%;
    margin: auto;
    box-shadow: 0 0 10px 3px var(--hover-purple);
    margin-top: 1rem;
    margin-left: 1.9rem;
  }
  .video {
    position: absolute;
    box-shadow: #4D1D86 4px 8px 10px 0;
    left: 60%;
    top: 1%;
  }
  .g-ytsubscribe {
    position: absolute;
    font-weight: 400;
    margin: 0;
    left: 78%;
    top: 41%;
  }
  .admin-video {
    position: absolute;
    left: 60%;
    top: 30%;
  }
  .data-title {
    letter-spacing: 0.0375rem;
    font-weight: 400;
    color: var(--text-purple);
    font-size: 3.5rem;
    text-align: center;
    position: relative;
  }
  .chartsTitle {
    letter-spacing: 0.0375rem;
    font-weight: 400;
    color: var(--text-purple);
    font-size: 3.5rem;
    text-align: center;
   
    position: relative;
  }
  .chartsTitle2 {
    letter-spacing: 0.0375rem;
    font-weight: 400;
    color: var(--text-purple);
    font-size: 3.5rem;
    text-align: center;
  
  }
  .adminChartsTitle {
    letter-spacing: 0.0375rem;
    font-weight: 400;
    color: var(--text-purple);
    font-size: 3.5rem;
    text-align: center;
    padding-top: 1rem;
    text-shadow: 2.5px 2.5px 4px var(--hover-purple);
    position: relative;
  }
  .adminChartsTitle2 {
    letter-spacing: 0.0375rem;
    font-weight: 400;
    color: var(--text-purple);
    font-size: 3.5rem;
    margin-top: 6rem;
    text-align: center;
    text-shadow: 2.5px 2.5px 4px var(--hover-purple);
  }
  .newsGraph {
    width: 100%;
    height: 100%;
    box-shadow: #4D1D86 4px 5px 10px 3px;
  }
  .chartImg {
    width: 80%;
    box-shadow: #4D1D86 6px 6px 10px 3px;
    margin: auto;
  }
  .center {
    margin: auto;
  }
  .line1 {
    margin-top: 5rem;
  }
  .chartImg1 {
    text-align: center;
    padding-top: -7rem;
    padding-bottom: 1rem;
    width: 100%;
    margin-top: -150px;
    margin-bottom: -150px;
  }
  .chartImg2,
  .chartImg3,
  .chartImg4,
  .chartImg5 {
    text-align: center;
    padding-top: 1rem;
    padding-bottom: 1rem;
    width: 100%;
    margin-top: -150px;
    margin-bottom: -150px;
  }
  .chartImg6 {
    text-align: center;
    padding-top: 1rem;
    padding-bottom: 1rem;
    width: 100%;

  }
  .reveal {
    position: relative;
    transform: translateY(50px);
    opacity: 0;
    transition: 0.75s all ease;
  }
  .reveal.active {
    transform: translateY(0);
    opacity: 1;
  }
  .email {
    color: var(--hover-purple);
    font-weight: 500;
  }
  .footerLink {
    color: var(--hover-purple);
  }
  .footerLink:link {
    color: var(--hover-purple);
    cursor: pointer;
  }
  .footerLink:visited {
    color: var(--hover-purple);
    cursor: pointer;
  }
  .footerLink.active,
  .footerLink:focus {
    border-bottom: 0rem solid var(--hover-purple);
    cursor: pointer;
  }
  .footerLink:hover {
    color: var(--text-purple);
    transform: scale(1.05);
    cursor: pointer;
  }
  .footer {
    margin-bottom: -1rem;
    padding: 1rem;
    font-weight: 700;
    margin-top: 1rem;
    font-size: 1.1rem;
    text-align: center;
    background-color: var(--primary-purple);
    color: var(--secondary-purple);
  }
  .tsparticles {
    position: absolute;
    height: 100%;
    width: 100%;
  }
  .rotate-position,
  .about-rotate-position,
  .phone,
  .about-phone,
  .message,
  .about-message, .home-message {
    display: none;
  }
  .extra-data {
    margin-top: 2rem;
    font-size: 1.25rem;
    text-align: center;
    color: var(--text-purple);
   
  }
  .extra-data2 {
    font-size: 1.25rem;
    text-align: center;
    color: var(--text-purple);
   
  }
  .data-link {
    color: grey;
  }
  .data-link:link {
    color: grey;
    cursor: pointer;
  }
  .data-link:visited {
    color: grey;
    cursor: pointer;
  }
  .data-link.active,
  .data-link:focus {
    border-bottom: 0rem solid white;
    cursor: pointer;
  }
  .data-link:hover {
    color: white;
    transform: scale(1.05);
    cursor: pointer;
  }
}

/* *************************************************************************
****************************************************************************
***************************************************************************/
/* *************************************************************************
****************************************************************************
***************************************************************************/
@media screen and (min-width: 1079px) and (max-width: 1081px) {
  .no-show-monitor {
    display: none!important;
  }
  .no-monitor {
    display: none!important;
  }
  /* dune start */
.HoldersGraph {
  background-image: linear-gradient(90deg, #1B0D33, #D8D2E4, #D8D2E4,#D8D2E4, #D8D2E4,#D8D2E4,#D8D2E4,#D8D2E4,#D8D2E4,#D8D2E4,#D8D2E4,#D8D2E4,#D8D2E4,#D8D2E4,#D8D2E4,#D8D2E4,#D8D2E4,#D8D2E4,#1B0D33);
  margin-bottom: -36px!important;
  padding-left: 20px!important;
  padding-right: 20px!important;
}
.HoldersGraphBottom {
  background-image: linear-gradient(90deg, #1B0D33, #D8D2E4,#D8D2E4,#D8D2E4,#D8D2E4,#D8D2E4,#D8D2E4,#D8D2E4,#D8D2E4,#D8D2E4,#D8D2E4,#D8D2E4,#D8D2E4,#D8D2E4,#D8D2E4,#D8D2E4,#D8D2E4,#D8D2E4, #1B0D33);
  padding-left: 20px!important;
  padding-right: 20px!important;
}
/* dune end */
  .chatbot-footer-bottom {
    margin-bottom: -80px!important;
   }
  .chatbot-link-logo{
    margin-top: 14rem;
    z-index: 9998;
    position: fixed;
    max-width: 6rem;
    background: white;
    -moz-border-radius: 50px;
    -webkit-border-radius: 50px;
    border-radius: 50px;
    padding: -1rem;
    right: 2%;
    border: 3px var(--secondary-purple) solid;
    box-shadow: 0 0 0 var(--hover-purple);
    animation: pulse 2s infinite;
  }
  .advocate-link-logo{
    margin-top: 16rem;
    z-index: 9998;
    position: fixed;
    max-width: 6rem;
    background: white;
    -moz-border-radius: 50px;
    -webkit-border-radius: 50px;
    border-radius: 50px;
    padding: -1rem;
    right: 2%;
    border: 3px var(--secondary-purple) solid;
    box-shadow: 0 0 0 var(--hover-purple);
    animation: pulse 2s infinite;
  }
  @-webkit-keyframes pulse {
    0% {
      -webkit-box-shadow: 0 0 0 0  var(--text-purple);
    }
    70% {
        -webkit-box-shadow: 0 0 0 15px rgba(244,157,22, 0);
    }
    100% {
        -webkit-box-shadow: 0 0 0 0 rgba(244,157,22, 0);
    }
  }
  @keyframes pulse {
    0% {
      -moz-box-shadow: 0 0 0 0  var(--text-purple);
      box-shadow: 0 0 0 0  var(--text-purple);
    }
    70% {
        -moz-box-shadow: 0 0 0 15px rgba(244,157,22, 0);
        box-shadow: 0 0 0 15px rgba(244,157,22, 0);
    }
    100% {
        -moz-box-shadow: 0 0 0 0 rgba(244,157,22, 0);
        box-shadow: 0 0 0 0 rgba(244,157,22, 0);
    }
  }
  
  .chatbot-link-export{
    margin-top: 18rem;
    z-index: 9998;
    position: fixed;
    max-width: 6rem;
    background: white;
    -moz-border-radius: 50px;
    -webkit-border-radius: 50px;
    border-radius: 50px;
    padding: -1rem;
   rihgt: 2%;
    border: 3px var(--secondary-purple) solid;
    box-shadow: 0 0 0 var(--hover-purple);
    animation: pulse 2s infinite;
  }

 /* Chatbot */
.feedback-text {
  margin-top: 2rem;
}
.feedback-button {
  border: 2px var(--primary-purple) solid!important;
  background-color: var(--text-purple)!important;
  color: var(--primary-purple)!important;
  padding: auto!important;
  margin: auto!important;
}

  .feedback-button:link {
    cursor: pointer;
  }
  .feedback-button:visited {
    cursor: pointer;
  }
  .feedback-button.active,
  .feedback-button:focus {
    cursor: pointer;
  }
  .feedback-button:hover {
    background-color: var(--background)!important;
    color: var(--text-purple)!important;
    transform: scale(1.015);
    cursor: pointer;
  }
  .feedback-button-text {
    font-size: 1.5rem;
  }

.react-chatbot-kit-chat-inner-container {
  background-color: var(--text-purple)!important;
}

.react-chatbot-kit-chat-header {
  font-size: 1.1rem!important;
  color: var(--text-purple)!important;
  background-color: var(--primary-purple)!important;
  padding: 0rem!important;
}

.react-chatbot-kit-chat-input-container {
  background-color:var(--secondary-purple)!important;
  border: 1px solid var(--primary-purple)!important;
}

.react-chatbot-kit-chat-btn-send {
  background-color:var(--primary-purple)!important;
}

.react-chatbot-kit-chat-btn-send-icon:hover {
transform: scale(1.25);
}

.react-chatbot-kit-chat-bot-message-container {
  width: 100%;
}

.react-chatbot-kit-chat-bot-avatar-container {
}

.react-chatbot-kit-chat-bot-avatar-icon {
}

.react-chatbot-kit-chat-bot-avatar-letter {
  display: none;
}
.react-chatbot-kit-chat-container {
  width: 98%!important;
  text-align: center;
  margin: auto;
}

.react-chatbot-kit-chat-bot-avatar-container {
  width: 3.5rem!important;
  height: 3.5rem!important;
  background-color: white!important;
  border: 2px solid var(--primary-purple);
  background-image: url('./GRTDataHubChatBot.png')!important;
  background-repeat: no-repeat!important;
  background-size: 3rem;
  background-position-x: 50%;
  background-position-y: 20%;
}

.react-chatbot-kit-chat-bot-message {
  text-align: left!important;
  width: 100%!important;
  color: var(--text-purple)!important;
  background-color: #0C0B23!important;
  font-size: 1rem!important;
}

.react-chatbot-kit-chat-bot-message-arrow {
  color: #0C0B23!important;
  border-right: 8px solid #0C0B23!important;
}
  .indexer-table-header {
    text-align: center;
    margin-left: 1rem;
  }
  .indexer-table {
    text-align: center;
  }
  .chatbot-data {
    display: inline!important;
  }
  .telegramActiveDelegationCount {
    max-width: 92%;
    margin: auto; 
    text-align: center;
    margin-top: 0.5%;
    }
    .top-telegram {
      margin-top: 0.25rem;
    }
    .chatbot-top {
      margin-top: 5rem;
    }
    .chatbot-page-container {
      background: var(--background);
      color: var(--text-purple);
      width: 100%;
      margin: auto;
      margin-top: 150px;
      padding-left: 10px;
      padding-right: 10px;
      text-align: center;
    }
    .chatbot-text-container {
      background: whitesmoke;
      border-radius: 1rem;
      color: var(--secondary-purple);
      margin: auto!important;
      padding-bottom: 10px;
      padding-top: 10px;
      text-align: center!important;
    }
    .chatbot-page-title {
      font-size: 1.25rem;
      font-weight: 600;
      color: var(--secondary-purple);
      padding-bottom: 0.25rem;
      padding-left: 5px;
      padding-right: 5px;
    }
    .chatbot-page-subtitle {
      font-size: 1.75rem;
      padding-left: 5px;
      padding-right: 5px;
    }
    .chatbot-page-text {
      font-size: 1.25rem;
      margin-bottom: 1rem;
      margin-top: 1rem;
      padding-left: 10px;
      padding-right: 10px;
    }
    /* chatbot end */
  .dataHub-logos {
    max-width: 6rem !important;
    max-height: 35rem !important;
    position: absolute;
    top: 0;
    margin-top: 1rem;
  }
  .mobile-config {
    display: none!important;
  }
  .offcanvas-button, .offcanvas-menu {
    display: none!important;
  }
  .navbar-toggler {
    display: none;
  }
  .pages {
    margin-bottom: 0.1rem;
    margin-right: 2rem;
}
.indexer-title {
  text-align: center!important;
  font-weight: 400;
  font-size: 1.75rem;
  padding-top: 2rem;
  color: var(--text-purple);
  
  margin-top: 6rem;
}
.indexer-subtitle {
  text-align: center!important;
  font-weight: 400;
  font-size: 1.5rem;
  color: var(--text-purple);
  
  margin-top: 1rem;
}
.indexer {
  z-index: 999;
  position: absolute;
  left: 0%;
  top: 50%;
}

.curator {
  z-index: 999;
  position: absolute;
  text-align: center!important;
  top: 50%;
}

.map, .tokens, .rewards, .uncollected-rewards {
  margin-top: 2rem;
  width: 90%;
  height: 14rem;
  margin-left: 5%;
  box-shadow: #4D1D86 4px 5px 10px 3px;
}

.c-map {
  margin-top: 2rem;
  width: 100%;
  height: 30rem;
  margin-left: 5%;
  box-shadow: #4D1D86 4px 5px 10px 3px;
}

.d-map {
  margin-top: -3rem;
  margin-left: 1rem;
  width: 1240px;
  height: 3800px;
  text-align: center!important;
  box-shadow: #4D1D86 4px 5px 10px 3px;
}

.rewards-per-hour {
  margin-top: 2rem;
  padding-bottom: 2rem;
  width: 90%;
  height: 14rem;
  margin-left: 5%;
  box-shadow: #4D1D86 4px 5px 10px 3px;
}

.indexers {
  padding-left: 1rem;
  padding-left: 1rem;
  color: grey;
  font-size:1.25rem;
  margin-right: 1rem;
}
.indexers:link{
  color: grey;
  cursor: pointer;
  font-size:1.25rem;
  margin-right: 1rem;
}
.indexers:visited {
  color: grey;
  cursor: pointer;
  font-size:1.25rem;
  margin-right: 1rem;
}
.indexers.active,
.indexers:focus {
  border-bottom: 0rem solid var(--hover-purple);
  cursor: pointer;
  font-size:1.25rem;
  margin-right: 1rem;
}
.indexers:hover {
  color: var(--text-purple);
  transform: scale(1.05);
  cursor: pointer;
  font-size:1.25rem;
  margin-right: 1rem;
}
.indexer-link {
  color: white;
}
.indexer-link:link {
  color: white;
  cursor: pointer;
}
.indexer-link:visited {
  color: var(--text-purple);
  cursor: pointer;
}
.indexer-link.active,
.indexer-link:focus {
  border-bottom: 0rem solid var(--text-purple);
  cursor: pointer;
}
.indexer-link:hover {
  color: var(--text-purple);
  transform: scale(1.05);
  cursor: pointer;
}
  .cookie-page-text {
    padding-top: 1rem;
    font-size: 1.25rem;
    margin-bottom: 1rem;
    padding-left: 20px;
    padding-right: 20px;
  }
  .cookies-video {
    padding-top: 2rem;
  }
  .cookie-button {
    display: none;
  }
  body > div:nth-child(1) > button {
    display: none;
  }
  body > div:nth-child(1) > button:hover {
    display: none;
  }
  .extra-data {
    margin-top: 2rem;
    font-size: 1.25rem;
    text-align: center;
    color: var(--text-purple);
   
  }
  .extra-data2 {
    font-size: 1.25rem;
    text-align: center;
    color: var(--text-purple);
   
  }
  .data-link {
    color: grey;
  }
  .data-link:link {
    color: grey;
    cursor: pointer;
  }
  .data-link:visited {
    color: grey;
    cursor: pointer;
  }
  .data-link.active,
  .data-link:focus {
    border-bottom: 0rem solid white;
    cursor: pointer;
  }
  .data-link:hover {
    color: white;
    transform: scale(1.05);
    cursor: pointer;
  }
  .question-bank-container {
    background: var(--background);
    color: var(--text-purple);
    width: 100%;
    margin: auto;
    margin-top: 150px;
    margin-bottom: 50px;
    padding-left: 50px;
    padding-right: 50px;
    text-align: center;
  }
  .about-page-container {
    background: var(--background);
    color: var(--text-purple);
    width: 100%;
    margin: auto;
    margin-top: 150px;
    margin-bottom: 50px;
    padding-left: 50px;
    padding-right: 50px;
    text-align: center;
  }
  .QB-text-container {
    background: white;
    border-radius: 1rem;
    padding: 30px;
    color: var(--secondary-purple);
  }
  .text-container {
    display: none;
  }
  .QB-about-hr {
    border: 1.5px solid var(--background);
    color: var(--background);
    border-radius: 5px;
    width: 35%;
    margin: auto;
    margin-bottom: 1rem;
    box-shadow: 0 0 5px 1px var(--background);
  }
  .QB-about-page-title {
    font-size: 2rem;
    font-weight: 600;
    color: var(--secondary-purple);
    padding-bottom: 2rem;
  }
  .QB-about-page-subtitle {
    font-size: 1.75rem;
  }
  .QB-about-page-text {
    font-size: 1.25rem;
    margin-bottom: 1rem;
  }
  .about-hr {
    display: none;
  }
  .about-page-title {
    display: none;
  }
  .about-page-subtitle {
    display: none;
  }
  .about-page-text {
    display: none;
  }
  .graphiql-link {
    display: none;
  }
  .graphiql-link:link {
    display: none;
  }
  .graphiql-link:visited {
    display: none;
  }
  .graphiql-link.active,
  .graphiql-link:focus {
    display: none;
  }
  .graphiql-link:hover {
    display: none;
  }
  .cookies-policy-container {
    background: white;
    width: 90%;
    padding-bottom: 1rem;
    margin: auto;
    border-radius: 2rem;
    margin-top: 200px;
    margin-bottom: 50px;
  }
  .termly-cookie-preference-button {
    z-index: 999 !important;
    margin-top: 1rem;
    background: white;
    width: 165px;
    height: 30px;
    border-radius: 3px;
    border: 1px solid var(--background);
    font-family: Roboto, Arial;
    font-size: 10px;
    color: var(--background);
    font-weight: 600;
    box-sizing: border-box;
    cursor: pointer;
    padding: 0;
    outline: none;
  }
  .termly-cookie-preference-button:hover {
    z-index: 999 !important;
    margin-top: 1rem;
    background: var(--background);
    width: 165px;
    height: 30px;
    border-radius: 3px;
    border: 1px solid black;
    font-family: Roboto, Arial;
    font-size: 10px;
    color: var(--text-purple);
    font-weight: 600;
    box-sizing: border-box;
    cursor: pointer;
    padding: 0;
    outline: none;
  }

  .privacy-policy-container {
    background: white;
    width: 90%;
    padding-bottom: 1rem;
    margin: auto;
    border-radius: 2rem;
    margin-top: 150px;
    margin-bottom: 50px;
  }
  .privacy-policy {
    margin-top: 110px;
    font-weight: 700;
    font-size: 1.1rem;
    text-align: center;
    background-color: var(--background);
    color: var(--secondary-purple);
  }
  .privacy-policy-link,
  .about {
    padding-left: 1rem;
    padding-left: 1rem;
    color: grey;
  }
  .privacy-policy-link:link,
  .about:link {
    color: grey;
    cursor: pointer;
  }
  .privacy-policy-link:visited,
  .about:visited {
    color: grey;
    cursor: pointer;
  }
  .privacy-policy-link.active,
  .privacy-policy-link:focus,
  .about.active,
  .about:focus {
    border-bottom: 0rem solid var(--hover-purple);
    cursor: pointer;
  }
  .privacy-policy-link:hover,
  .about:hover {
    color: var(--text-purple);
    transform: scale(1.05);
    cursor: pointer;
  }
  .rotate-position,
  .about-rotate-position {
    margin-top: 10rem;
  }
  .phone {
    height: 125px;
    padding-top: 18.5rem;
    width: 200px;
    border: 4px solid white;
    border-radius: 10px;
    animation: rotate 1.5s ease-in-out infinite alternate;
    text-align: center;
    margin: auto;
  }
  .about-phone {
    height: 125px;
    margin-top: 30rem !important;
    width: 200px;
    border: 4px solid white;
    border-radius: 10px;
    animation: rotate 1.5s ease-in-out infinite alternate;
    text-align: center;
    margin: auto;
  }
  .message,
  .about-message {
    color: var(--text-purple);
    text-shadow: 2.5px 2.5px 4px var(--hover-purple);
    font-weight: 400;
    font-size: 3rem;
    padding-top: 4rem;
    padding-left: 1rem;
    padding-right: 1rem;
    text-align: center;
    margin: auto;
  }
  .home-message {
    color: var(--text-purple);
    text-shadow: 2.5px 2.5px 4px var(--hover-purple);
    font-weight: 400;
    font-size: 3rem;
    padding-top: 4rem;
    padding-bottom: 4rem;
    padding-left: 1rem;
    padding-right: 1rem;
    text-align: center;
    margin: auto;
  }
  @keyframes rotate {
    0% {
      transform: rotate(0deg);
    }
    50% {
      transform: rotate(-90deg);
    }
    100% {
      transform: rotate(-90deg);
    }
  }
  .subscribeNote {
    display: none;
  }
  /* loader new start  */
  .loader:empty {
    position: absolute;
    top: calc(50% + 15.5rem);
    left: calc(50% + 2em);
    width: 4em;
    height: 4em;
    border: 0.35em solid white;
    border-left: 0.35em solid #10034B;
    border-radius: 50%;
    animation: load8 0.8s infinite linear;
  }
  .loadingTitle {
    position: absolute;
    top: 27rem;
    left: calc(50% - 3em);
    font-size: 2rem;
    color: #10034B;
    -webkit-box-reflect:below 0px linear-gradient(transparent, #0005);
  }
  .loaderLogo {
    position: absolute;
    top: 14rem;
    left: calc(50% - 8em);
    width: 15em;
    height: 12em;
  }
  @keyframes load8 {
    0% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(360deg);
    }
  }
  /* loader new end  */
  .C {
    font-size: 1rem;
  }
  .logo {
    width: 8rem;
    height: 8rem;
    margin-left: 1rem;
    padding-top: 0.5rem;
  }
  .data-logo {
    max-width: 1.75rem !important;
    height: 1.75rem !important;
    position: absolute;
  }
  .brand-text {
    color: var(--text-purple);
    display: inline;
    margin-left: -0.5rem;
    letter-spacing: 0.0375rem;
    font-size: 2.5rem;
  }
  .header-text {
    font-weight: 400;
    font-size: 4rem;
    margin-top: 150px;
    padding-top: 2rem;
    color: var(--text-purple);
   
    text-align: center;
  }
  .header-sub-text {
    font-weight: 400;
    font-size: 2.5rem;
    width: 90%;
    text-align: center!important;
    margin: auto!important;
    color: var(--text-purple);
  
    display: block;
    text-align: center;
  }
  .adminHeader-text {
    font-weight: 400;
    font-size: 1.5rem;
    text-align: center;
    margin: auto;
    padding-top: 1rem;
    color: var(--text-purple);
  
    margin-top: 6rem;
  }
  .adminHeader-text2 {
    font-weight: 400;
    font-size: 1.5rem;
    text-align: center;
    margin: auto;
    color: var(--text-purple);
   
    margin-top: 2rem;
  }
  .adminSubheader-text {
    font-weight: 400;
    font-size: 1rem;
    margin-left: 3rem;
    color: var(--text-purple);
    
    margin-top: 1rem;
  }
  .adminSubheader-text2 {
    font-weight: 400;
    font-size: 1rem;
    margin-left: 3rem;
    margin-right: 3rem;
    color: var(--text-purple);
  
    margin-top: 1rem;
  }
  .note {
    text-decoration: underline;
  }

  .adminHr2 {
    border: 3px solid var(--hr);
    border-radius: 5px;
    width: 70%;
    margin: auto;
    box-shadow: 0 0 10px 3px var(--hover-purple);
    margin-top: 1rem;
    margin-left: 1.9rem;
  }

 .admin-video {
    position: absolute;
    left: 48%;
    top: 20%;
    width: 10%;
    height: 10%;
  }

  .adminChartsTitle {
    letter-spacing: 0.0375rem;
    font-weight: 400;
    color: var(--text-purple);
    font-size: 2rem;
    text-align: center;
    padding-top: 1rem;
    text-shadow: 2.5px 2.5px 4px var(--hover-purple);
    position: relative;
  }
  .adminChartsTitle2 {
    letter-spacing: 0.0375rem;
    font-weight: 400;
    color: var(--text-purple);
    font-size: 2rem;
    margin-top: 1rem;
    text-align: center;
    text-shadow: 2.5px 2.5px 4px var(--hover-purple);
  }
  .navbar-toggler {
    display: none;
  }
  nav a.TGN {
    display: none;
  }
  .navbar-nav > li > a.TGN:link {
    display: none;
  }
  .navbar-nav > li > a.TGN:visited {
    display: none;
  }
  .navbar-nav > li > a.TGN.active,
  .navbar-nav > li > a.TGN:focus {
    display: none;
  }
  .navbar-nav > li > a.TGN:hover {
    display: none;
  }
  .dropdown {
    display: none;
  }
  .menu-icon {
    color: var(--secondary-purple);
  }
  .main-info {
    background-image: url("TopBackground.svg");
    background-repeat: no-repeat;
    background-size: 100% auto;
    width: 100%;
    height: 100%;
    margin-top: 90px;
  }
  .community-links {
    margin-left: 12rem;
    padding-top: 8rem;
    position: absolute;
    z-index: 999;
    left: 20%;
  }
  .telegram-icon,
  .twitter-icon,
  .reddit-icon,
  .youtube-icon {
    display: inline;
    padding: 1rem;
    transition: all 0.5s ease-in-out;
    z-index: 999;
  }
  .icon:hover {
    color: var(--hover-purple);
    transform: scale(1.15);
  }
  .icon-youtube:hover {
    color: var(--text-purple);
    transform: scale(1.1);
  }
  .news-title {
    display: none;
  }
  .newsGraph1,
  .newsGraph2,
  .newsGraph3,
  .newsGraph4 {
    display: none;
  }
  .youtube {
    z-index: 999;
    display: inline;
    position: absolute;
    text-align: center;
    color: var(--text-purple);
  }
  span.anchor {
    display: block;
    visibility: hidden;
    position: relative;
    padding-top: 17.5%;
  }
  .activeDelegators,
  .holders,
  .curatorCount,
  .subgraphCount {
    display: none;
  }
  .hr {
    display: none;
  }
  .hr2 {
    display: none;
  }
  .video {
    display: none;
  }
  .g-ytsubscribe {
    display: none;
  }
  .data-title {
    letter-spacing: 0.0375rem;
    font-weight: 400;
    color: var(--text-purple);
    font-size: 7rem;
    text-align: center;
    position: relative;
  }
  .chartsTitle {
    letter-spacing: 0.0375rem;
    font-weight: 400;
    color: var(--text-purple);
    font-size: 3.5rem;
    text-align: center;
   
    position: relative;
  }
  .chartsTitle2 {
    letter-spacing: 0.0375rem;
    font-weight: 400;
    color: var(--text-purple);
    font-size: 3.5rem;
    text-align: center;
  
  }
  .newsGraph {
    display: none;
  }
 
  .center {
    margin: auto;
  }
  .line1 {
    display: none;
  }
 
  .chartImg6 {
    text-align: center;
    padding-top: 1rem;
    width: 100%;
    margin-top: -120px;
  }
  .reveal {
    position: relative;
    transform: translateY(50px);
    opacity: 0;
    transition: 0.75s all ease;
  }
  .reveal.active {
    transform: translateY(0);
    opacity: 1;
  }
  .email {
    color: var(--hover-purple);
    font-weight: 500;
  }
  .footerLink {
    color: var(--hover-purple);
  }
  .footerLink:link {
    color: var(--hover-purple);
    cursor: pointer;
  }
  .footerLink:visited {
    color: var(--hover-purple);
    cursor: pointer;
  }
  .footerLink.active,
  .footerLink:focus {
    border-bottom: 0rem solid var(--hover-purple);
    cursor: pointer;
  }
  .footerLink:hover {
    color: var(--text-purple);
    transform: scale(1.05);
    cursor: pointer;
  }
  .footer {
    margin-bottom: -1rem;
    display: none;
  }
  .tsparticles {
    display: none;
  }
}

/* *************************************************************************
****************************************************************************
***************************************************************************/
/* *************************************************************************
****************************************************************************
***************************************************************************/

@media screen and (min-width: 1026px) and (max-width: 1078px) {
  .no-show-monitor {
    display: none!important;
  }
  .no-monitor {
    display: none!important;
  }
  /* dune start */
.GRTDailyTransactionsPosition {
  margin-bottom: 0.5rem!important;
  margin-left: -15px!important;
 }
 .HoldersGraph {
  background-image: linear-gradient(90deg, #1B0D33, #D8D2E4, #D8D2E4,#D8D2E4, #D8D2E4,#D8D2E4,#D8D2E4,#D8D2E4,#D8D2E4,#D8D2E4,#D8D2E4,#D8D2E4,#D8D2E4,#D8D2E4,#D8D2E4,#D8D2E4,#D8D2E4,#D8D2E4,#1B0D33);
  margin-bottom: -36px!important;
  margin-left: -10px!important;
  padding-left: 20px!important;
  padding-right: 20px!important;
  height: 80px!important;
  width: 100%!important;
}
.HoldersGraphBottom {
  background-image: linear-gradient(90deg, #1B0D33, #D8D2E4,#D8D2E4,#D8D2E4,#D8D2E4,#D8D2E4,#D8D2E4,#D8D2E4,#D8D2E4,#D8D2E4,#D8D2E4,#D8D2E4,#D8D2E4,#D8D2E4,#D8D2E4,#D8D2E4,#D8D2E4,#D8D2E4, #1B0D33);
  padding-left: 20px!important;
  margin-left: -10px!important;
  padding-right: 20px!important;
  height: 80px!important;
  width: 100%!important;
}
/* dune end */
  .chatbot-footer-bottom {
    margin-bottom: -80px!important;
   }
  .chatbot-link-logo{
    margin-top: 13rem;
    z-index: 9998;
    position: fixed;
    max-width: 6rem;
    background: white;
    -moz-border-radius: 50px;
    -webkit-border-radius: 50px;
    border-radius: 50px;
    padding: -1rem;
    right: 2%;
    border: 3px var(--secondary-purple) solid;
    box-shadow: 0 0 0 var(--hover-purple);
    animation: pulse 2s infinite;
  }
  .advocate-link-logo{
    margin-top: 21rem;
    z-index: 9998;
    position: fixed;
    max-width: 6rem;
    background: white;
    -moz-border-radius: 50px;
    -webkit-border-radius: 50px;
    border-radius: 50px;
    padding: -1rem;
    right: 2%;
    border: 3px var(--secondary-purple) solid;
    box-shadow: 0 0 0 var(--hover-purple);
    animation: pulse 2s infinite;
  }
  @-webkit-keyframes pulse {
    0% {
      -webkit-box-shadow: 0 0 0 0  var(--text-purple);
    }
    70% {
        -webkit-box-shadow: 0 0 0 15px rgba(244,157,22, 0);
    }
    100% {
        -webkit-box-shadow: 0 0 0 0 rgba(244,157,22, 0);
    }
  }
  @keyframes pulse {
    0% {
      -moz-box-shadow: 0 0 0 0  var(--text-purple);
      box-shadow: 0 0 0 0  var(--text-purple);
    }
    70% {
        -moz-box-shadow: 0 0 0 15px rgba(244,157,22, 0);
        box-shadow: 0 0 0 15px rgba(244,157,22, 0);
    }
    100% {
        -moz-box-shadow: 0 0 0 0 rgba(244,157,22, 0);
        box-shadow: 0 0 0 0 rgba(244,157,22, 0);
    }
  }
  
  .chatbot-link-export{
    margin-top: 18rem;
    z-index: 9998;
    position: fixed;
    max-width: 6rem;
    background: white;
    -moz-border-radius: 50px;
    -webkit-border-radius: 50px;
    border-radius: 50px;
    padding: -1rem;
   right: 2%;
    border: 3px var(--secondary-purple) solid;
    box-shadow: 0 0 0 var(--hover-purple);
    animation: pulse 2s infinite;
  }

/* Chatbot */
.feedback-text {
  margin-top: 2rem;
}
.feedback-button {
  border: 2px var(--primary-purple) solid!important;
  background-color: var(--text-purple)!important;
  color: var(--primary-purple)!important;
  padding: auto!important;
  margin: auto!important;
}

  .feedback-button:link {
    cursor: pointer;
  }
  .feedback-button:visited {
    cursor: pointer;
  }
  .feedback-button.active,
  .feedback-button:focus {
    cursor: pointer;
  }
  .feedback-button:hover {
    background-color: var(--background)!important;
    color: var(--text-purple)!important;
    transform: scale(1.015);
    cursor: pointer;
  }
  .feedback-button-text {
    font-size: 1.5rem;
  }

.react-chatbot-kit-chat-inner-container {
  background-color: var(--text-purple)!important;
}

.react-chatbot-kit-chat-header {
  font-size: 1.1rem!important;
  color: var(--text-purple)!important;
  background-color: var(--primary-purple)!important;
  padding: 0rem!important;
}

.react-chatbot-kit-chat-input-container {
  background-color:var(--secondary-purple)!important;
  border: 1px solid var(--primary-purple)!important;
}

.react-chatbot-kit-chat-btn-send {
  background-color:var(--primary-purple)!important;
}

.react-chatbot-kit-chat-btn-send-icon:hover {
transform: scale(1.25);
}

.react-chatbot-kit-chat-bot-message-container {
  width: 100%;
}

.react-chatbot-kit-chat-bot-avatar-container {
}

.react-chatbot-kit-chat-bot-avatar-icon {
}

.react-chatbot-kit-chat-bot-avatar-letter {
  display: none;
}
.react-chatbot-kit-chat-container {
  width: 98%!important;
  text-align: center;
  margin: auto;
}

.react-chatbot-kit-chat-bot-avatar-container {
  width: 3.5rem!important;
  height: 3.5rem!important;
  background-color: white!important;
  border: 2px solid var(--primary-purple);
  background-image: url('./GRTDataHubChatBot.png')!important;
  background-repeat: no-repeat!important;
  background-size: 3rem;
  background-position-x: 50%;
  background-position-y: 20%;
}

.react-chatbot-kit-chat-bot-message {
  text-align: left!important;
  width: 100%!important;
  color: var(--text-purple)!important;
  background-color: #0C0B23!important;
  font-size: 1rem!important;
}

.react-chatbot-kit-chat-bot-message-arrow {
  color: #0C0B23!important;
  border-right: 8px solid #0C0B23!important;
}
  .indexer-table-header {
    text-align: center;
    margin-left: 1rem;
  }
  .indexer-table {
    text-align: center;
  }
  .chatbot-data {
    display: inline!important;
  }
  .telegramActiveDelegationCount {
    max-width: 92%;
    margin: auto; 
    text-align: center;
    margin-top: 0.5%;
    }
    .top-telegram {
      margin-top: 0.25rem;
    }
    .chatbot-top {
      margin-top: 5rem;
    }
    .chatbot-page-container {
      background: var(--background);
      color: var(--text-purple);
      width: 100%;
      margin: auto;
      margin-top: 150px;
      padding-left: 10px;
      padding-right: 10px;
      text-align: center;
    }
    .chatbot-text-container {
      background: whitesmoke;
      border-radius: 1rem;
      color: var(--secondary-purple);
      margin: auto!important;
      padding-bottom: 10px;
      padding-top: 10px;
      text-align: center!important;
    }
    .chatbot-page-title {
      font-size: 1.25rem;
      font-weight: 600;
      color: var(--secondary-purple);
      padding-bottom: 0.25rem;
      padding-left: 5px;
      padding-right: 5px;
    }
    .chatbot-page-subtitle {
      font-size: 1.75rem;
      padding-left: 5px;
      padding-right: 5px;
    }
    .chatbot-page-text {
      font-size: 1.25rem;
      margin-bottom: 1rem;
      margin-top: 1rem;
      padding-left: 10px;
      padding-right: 10px;
    }
    /* chatbot end */
  .dataHub-logos {
    max-width: 6rem !important;
    max-height: 35rem !important;
    position: absolute;
    top: 0;
    margin-top: 1rem;
  }
  .mobile-config {
    display: none!important;
  }
  .offcanvas-button, .offcanvas-menu {
    display: none!important;
  }
  .navbar-toggler {
    display: none;
  }
  .pages {
    margin-bottom: 0.1rem;
    margin-right: 2rem;
}
.indexer-title {
  text-align: center!important;
  font-weight: 400;
  font-size: 1.75rem;
  padding-top: 2rem;
  color: var(--text-purple);
  
  margin-top: 6rem;
}
.indexer-subtitle {
  text-align: center!important;
  font-weight: 400;
  font-size: 1.5rem;
  color: var(--text-purple);
  
  margin-top: 1rem;
  margin-right: 2rem;
  margin-left: 2rem;
}
.indexer {
  z-index: 999;
  position: absolute;
  left: 0%;
  top: 50%;
}

.curator {
  z-index: 999;
  position: absolute;
  text-align: center!important;
  top: 50%;
}

.map, .tokens, .rewards, .uncollected-rewards {
  margin-top: 2rem;
  width: 90%;
  height: 14rem;
  margin-left: 5%;
  box-shadow: #4D1D86 4px 5px 10px 3px;
}

.c-map {
  margin-top: 2rem;
  width: 100%;
  height: 30rem;
  margin-left: 5%;
  box-shadow: #4D1D86 4px 5px 10px 3px;
}

.d-map {
  margin-top: -3rem;
  margin-left: 1rem;
  width: 1240px;
  height: 3800px;
  text-align: center!important;
  box-shadow: #4D1D86 4px 5px 10px 3px;
}

.rewards-per-hour {
  margin-top: 2rem;
  padding-bottom: 2rem;
  width: 90%;
  height: 14rem;
  margin-left: 5%;
  box-shadow: #4D1D86 4px 5px 10px 3px;
}

.indexers {
  padding-left: 1rem;
  padding-left: 1rem;
  color: grey;
  font-size:1.25rem;
  margin-right: 1rem;
}
.indexers:link{
  color: grey;
  cursor: pointer;
  font-size:1.25rem;
  margin-right: 1rem;
}
.indexers:visited {
  color: grey;
  cursor: pointer;
  font-size:1.25rem;
  margin-right: 1rem;
}
.indexers.active,
.indexers:focus {
  border-bottom: 0rem solid var(--hover-purple);
  cursor: pointer;
  font-size:1.25rem;
  margin-right: 1rem;
}
.indexers:hover {
  color: var(--text-purple);
  transform: scale(1.05);
  cursor: pointer;
  font-size:1.25rem;
  margin-right: 1rem;
}
.indexer-link {
  color: white;
}
.indexer-link:link {
  color: white;
  cursor: pointer;
}
.indexer-link:visited {
  color: var(--text-purple);
  cursor: pointer;
}
.indexer-link.active,
.indexer-link:focus {
  border-bottom: 0rem solid var(--text-purple);
  cursor: pointer;
}
.indexer-link:hover {
  color: var(--text-purple);
  transform: scale(1.05);
  cursor: pointer;
}
  .cookie-page-text {
    padding-top: 1rem;
    font-size: 1.25rem;
    margin-bottom: 1rem;
    padding-left: 20px;
    padding-right: 20px;
  }
  .cookies-video {
    padding-top: 2rem;
  }
  .cookie-button {
    z-index: 9999;
    position: absolute;
  }

  body > div:nth-child(1) > button {
    z-index: 10000;
    font-size: 3rem;
    position: absolute;
    padding: auto;
    margin-top: -95px;
    width: 45px;
    height: 45px;
    margin-left: 85%;
    background-color: transparent;
    border: none;
    color: var(--text-purple);
  }
  .extra-data {
    margin-top: 2rem;
    font-size: 1.25rem;
    text-align: center;
    color: var(--text-purple);
   
  }
  .extra-data2 {
    font-size: 1.25rem;
    text-align: center;
    color: var(--text-purple);
   
  }
  .data-link {
    color: grey;
  }
  .data-link:link {
    color: grey;
    cursor: pointer;
  }
  .data-link:visited {
    color: grey;
    cursor: pointer;
  }
  .data-link.active,
  .data-link:focus {
    border-bottom: 0rem solid white;
    cursor: pointer;
  }
  .data-link:hover {
    color: white;
    transform: scale(1.05);
    cursor: pointer;
  }
  .question-bank-container {
    background: var(--background);
    color: var(--text-purple);
    width: 100%;
    margin: auto;
    margin-top: 150px;
    margin-bottom: 50px;
    padding-left: 50px;
    padding-right: 50px;
    text-align: center;
  }
  .about-page-container {
    background: var(--background);
    color: var(--text-purple);
    width: 100%;
    margin: auto;
    margin-top: 150px;
    margin-bottom: 50px;
    padding-left: 50px;
    padding-right: 50px;
    text-align: center;
  }
  .QB-text-container {
    background: white;
    border-radius: 1rem;
    padding: 30px;
    color: var(--secondary-purple);
  }
  .text-container {
    background: white;
    border-radius: 1rem;
    padding: 30px;
    color: var(--secondary-purple);
  }
  .QB-about-hr {
    border: 1.5px solid var(--background);
    color: var(--background);
    border-radius: 5px;
    width: 35%;
    margin: auto;
    margin-bottom: 1rem;
    box-shadow: 0 0 5px 1px var(--background);
  }
  .QB-about-page-title {
    font-size: 2rem;
    font-weight: 600;
    color: var(--secondary-purple);
    padding-bottom: 2rem;
  }
  .QB-about-page-subtitle {
    font-size: 1.75rem;
  }
  .QB-about-page-text {
    font-size: 1.25rem;
    margin-bottom: 1rem;
  }
  .about-hr {
    border: 1.5px solid var(--background);
    color: var(--background);
    border-radius: 5px;
    width: 35%;
    margin: auto;
    margin-bottom: 1rem;
    box-shadow: 0 0 5px 1px var(--background);
  }
  .about-page-title {
    font-size: 2rem;
    font-weight: 600;
    color: var(--secondary-purple);
    padding-bottom: 2rem;
  }
  .about-page-subtitle {
    font-size: 1.75rem;
  }
  .about-page-text {
    font-size: 1.25rem;
    margin-bottom: 1rem;
  }
  .graphiql-link {
    color: var(--secondary-purple);
  }
  .graphiql-link:link {
    color: var(--secondary-purple);
    cursor: pointer;
  }
  .graphiql-link:visited {
    color: var(--secondary-purple);
    cursor: pointer;
  }
  .graphiql-link.active,
  .graphiql-link:focus {
    border-bottom: 0rem solid var(--hover-purple);
    cursor: pointer;
  }
  .graphiql-link:hover {
    color: var(--primary-purple);
    transform: scale(1.05);
    cursor: pointer;
  }
  .cookies-policy-container {
    background: white;
    width: 90%;
    padding-bottom: 1rem;
    margin: auto;
    border-radius: 2rem;
    margin-top: 150px;
    margin-bottom: 50px;
  }
  .termly-cookie-preference-button {
   display: none;
  }

  .privacy-policy-container {
    background: white;
    width: 90%;
    padding-bottom: 1rem;
    margin: auto;
    border-radius: 2rem;
    margin-top: 150px;
    margin-bottom: 50px;
  }
  .privacy-policy {
    margin-top: 110px;
    font-weight: 700;
    font-size: 1.1rem;
    text-align: center;
    background-color: var(--background);
    color: var(--secondary-purple);
  }
  .privacy-policy-link,
  .about {
    padding-left: 1rem;
    padding-left: 1rem;
    color: grey;
  }
  .privacy-policy-link:link,
  .about:link {
    color: grey;
    cursor: pointer;
  }
  .privacy-policy-link:visited,
  .about:visited {
    color: grey;
    cursor: pointer;
  }
  .privacy-policy-link.active,
  .privacy-policy-link:focus,
  .about.active,
  .about:focus {
    border-bottom: 0rem solid var(--hover-purple);
    cursor: pointer;
  }
  .privacy-policy-link:hover,
  .about:hover {
    color: var(--text-purple);
    transform: scale(1.05);
    cursor: pointer;
  }
  .subscribeNote {
    margin-left: -15rem;
    width: 30rem;
    font-size: 0.75rem;
    color: white;
  }
  /* loader new start  */
  .loader:empty {
    position: absolute;
    top: calc(50% + 15.5rem);
    left: calc(50% + 2em);
    width: 4em;
    height: 4em;
    border: 0.35em solid white;
    border-left: 0.35em solid #10034B;
    border-radius: 50%;
    animation: load8 0.8s infinite linear;
  }
  .loadingTitle {
    position: absolute;
    top: 27rem;
    left: calc(50% - 3em);
    font-size: 2rem;
    color: #10034B;
    -webkit-box-reflect:below 0px linear-gradient(transparent, #0005);
  }
  .loaderLogo {
    position: absolute;
    top: 14rem;
    left: calc(50% - 8em);
    width: 15em;
    height: 12em;
  }
  @keyframes load8 {
    0% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(360deg);
    }
  }
  /* loader new end  */
  .C {
    font-size: 1rem;
  }
  .logo {
    width:  6.75rem;
    height: 5.75rem;
    margin-left: 2rem;
    margin-top: -0.5rem;
  }
  .data-logo {
    max-width: 1rem !important;
    height: 1rem !important;
    position: absolute;
  }
  .brand-text {
    color: var(--text-purple);
    display: inline;
    margin-left: 0rem;
    letter-spacing: 0.0375rem;
    font-size: 1.25rem;
  }
  .header-text {
    font-weight: 400;
    font-size: 1.75rem;
    margin-left: 5rem;
    margin-top: 105px;
    color: var(--text-purple);

  }
  .header-sub-text {
    font-weight: 400;
    font-size: 1.25rem;
    text-align: center!important;
    margin: auto!important;
    width: 90%;
    color: var(--text-purple);
   
    display: block;
  }
  nav a.TGN {
    display: none;
  }
  .navbar-nav > li > a.TGN:link {
    display: none;
  }
  .navbar-nav > li > a.TGN:visited {
    display: none;
  }
  .navbar-nav > li > a.TGN.active,
  .navbar-nav > li > a.TGN:focus {
    display: none;
  }
  .navbar-nav > li > a.TGN:hover {
    display: none;
  }
  nav a.nav-link {
    font-weight: 400;
    font-size: 1.25rem;
    color: var(--text-purple);
  }
  .navbar-nav > li > a {
    border-bottom: 0.01875rem solid var(--transparent);
    padding: 0;
  }
  .navbar-nav > li > a:link {
    color: var(--hover-purple);
  }
  .navbar-nav > li > a:visited {
    color: var(--text-purple);
  }
  .navbar-nav > li > a.active,
  .navbar-nav > li > a:focus {
    border-bottom: 0.01875rem solid var(--hover-purple);
    padding: 0;
    transition: all 0.5s ease-in-out;
  }
  .navbar-nav > li > a:hover {
    border-bottom: 0.01875rem solid var(--hover-purple);
    padding: 0;
    color: var(--hover-purple);
    transform: scale(1.05);
  }
  .line {
    margin-left: 1rem;
  }
  .links {
    text-align: right;
    padding-right: 0;
    margin: auto;
    padding: auto;
  }
  .dropdown {
    margin-left: auto;
    padding: auto;
  }
  .bar {
    margin-bottom: 0.25rem;
    margin-right: 2rem;
  }
  .line {
    margin-bottom: 0.25rem;
    margin-right: 2rem;
  }
  .about {
    margin-left: auto;
    margin-right: 0.8rem;
  }
  .menu-icon {
    color: var(--secondary-purple);
  }
  .main-info {
    background-image: url("TopBackground.svg");
    background-repeat: no-repeat;
    background-size: 100% auto;
    width: 100%;
    height: 100%;
    margin-top: 90px;
  }
  .community-links {
    margin-left: 4rem;
    padding-top: 1rem;
    position: absolute;
    z-index: 999;
  }
  .telegram-icon,
  .twitter-icon,
  .reddit-icon,
  .youtube-icon {
    display: inline;
    padding: 1rem;
    transition: all 0.5s ease-in-out;
    z-index: 999;
  }
  .icon:hover {
    color: var(--hover-purple);
    transform: scale(1.15);
  }
  .icon-youtube:hover {
    color: var(--text-purple);
    transform: scale(1.1);
  }
  .news-title {
    text-shadow: 2.25px 2.25px 3px var(--hover-purple);
    color: var(--text-purple);
    text-align: center;
    margin-top: 1rem;
    margin-bottom: 1rem;
    font-size: 2rem;
    margin-left: -23rem;
  }
  .newsGraph1,
  .newsGraph2,
  .newsGraph3 {
    margin-bottom: 1rem;
  }
  .youtube {
    z-index: 999;
    display: inline;
    position: absolute;
    text-align: center;
    color: var(--text-purple);
  }
  span.anchor {
    display: block;
    visibility: hidden;
    position: relative;
    padding-top: 20%;
  }
  .activeDelegators,
  .holders,
  .curatorCount,
  .subgraphCount {
    font-weight: 400;
    color: var(--text-purple);
    letter-spacing: 0.0375rem;
  }
  .hr {
    border: 3px solid var(--hr);
    border-radius: 5px;
    width: 90%;
    margin: auto;
    box-shadow: 0 0 10px 3px var(--hover-purple);
    position: relative;
    margin-top: 2.5rem;
  }
  .hr2 {
    border: 3px solid var(--hr);
    border-radius: 5px;
    width: 90%;
    margin: auto;
    box-shadow: 0 0 10px 3px var(--hover-purple);
    position: relative;
    margin-top: 1.5rem;
  }
  .video {
    position: absolute;
    box-shadow: #4D1D86 4px 8px 10px 0;
    left: 55%;
    top: 0%;
  }
  .g-ytsubscribe {
    position: absolute;
    font-family: Euclid Circular A;
    font-weight: 400;
    margin: 0;
    left: 78%;
    top: 41%;
  }
  .data-title {
    letter-spacing: 0.0375rem;
    font-weight: 400;
    color: var(--text-purple);
    font-size: 3.5rem;
    text-align: center;
    position: relative;
  }
  .chartsTitle {
    letter-spacing: 0.0375rem;
    font-weight: 400;
    color: var(--text-purple);
    font-size: 3.5rem;
    text-align: center;
  
    position: relative;
  }
  .chartsTitle2 {
    letter-spacing: 0.0375rem;
    font-weight: 400;
    color: var(--text-purple);
    font-size: 3.5rem;
    text-align: center;

  }
  .newsGraph {
    width: 100%;
    box-shadow: #4D1D86 4px 5px 10px 3px;
  }
  .chartImg {
    width: 100%;
    box-shadow: #4D1D86 6px 6px 10px 3px;
    margin: auto;
  }
  .center {
    margin: auto;
  }
  .line1 {
    margin-top: 5rem;
  }
  .chartImg1 {
    text-align: center;
    padding-top: -7rem;
    padding-bottom: 1rem;
    width: 100%;
    margin-top: -120px;
    margin-bottom: -120px;
  }
  .chartImg2,
  .chartImg3,
  .chartImg4,
  .chartImg5 {
    text-align: center;
    padding-top: 1rem;
    padding-bottom: 1rem;
    width: 100%;
    margin-top: -120px;
    margin-bottom: -120px;
  }
  .chartImg6 {
    text-align: center;
    padding-top: 1rem;
    width: 100%;
    margin-top: -120px;
  }
  .reveal {
    position: relative;
    transform: translateY(50px);
    opacity: 0;
    transition: 0.75s all ease;
  }
  .reveal.active {
    transform: translateY(0);
    opacity: 1;
  }
  .email {
    color: var(--hover-purple);
    font-weight: 500;
  }
  .footerLink {
    color: var(--hover-purple);
  }
  .footerLink:link {
    color: var(--hover-purple);
    cursor: pointer;
  }
  .footerLink:visited {
    color: var(--hover-purple);
    cursor: pointer;
  }
  .footerLink.active,
  .footerLink:focus {
    border-bottom: 0rem solid var(--hover-purple);
    cursor: pointer;
  }
  .footerLink:hover {
    color: var(--text-purple);
    transform: scale(1.05);
    cursor: pointer;
  }
  .footer {
    margin-bottom: -1rem;
    padding: 1rem;
    font-weight: 700;
    margin-top: 1rem;
    font-size: 1.1rem;
    text-align: center;
    background-color: var(--primary-purple);
    color: var(--secondary-purple);
  }
  .tsparticles {
    position: absolute;
    height: 100%;
    width: 100%;
  }
  .rotate-position,
  .about-rotate-position,
  .phone,
  .about-phone,
  .message,
  .about-message, .home-message {
    display: none;
  }
  .adminHeader-text {
    font-weight: 400;
    font-size: 1.5rem;
    text-align: center;
    margin: auto;
    padding-top: 1rem;
    color: var(--text-purple);
 
    margin-top: 6rem;
  }
  .adminHeader-text2 {
    font-weight: 400;
    font-size: 1.5rem;
    text-align: center;
    margin: auto;
    color: var(--text-purple);
 
    margin-top: 2rem;
  }
  .adminSubheader-text {
    font-weight: 400;
    font-size: 1rem;
    margin-left: 3rem;
    color: var(--text-purple);
    
    margin-top: 1rem;
  }
  .adminSubheader-text2 {
    font-weight: 400;
    font-size: 1rem;
    margin-left: 3rem;
    margin-right: 3rem;
    color: var(--text-purple);

    margin-top: 1rem;
  }
  .note {
    text-decoration: underline;
  }

  .adminHr2 {
    border: 3px solid var(--hr);
    border-radius: 5px;
    width: 70%;
    margin: auto;
    box-shadow: 0 0 10px 3px var(--hover-purple);
    margin-top: 1rem;
    margin-left: 1.9rem;
  }

 .admin-video {
    position: absolute;
    left: 48%;
    top: 20%;
    width: 10%;
    height: 10%;
  }

  .adminChartsTitle {
    letter-spacing: 0.0375rem;
    font-weight: 400;
    color: var(--text-purple);
    font-size: 2rem;
    text-align: center;
    padding-top: 1rem;
    text-shadow: 2.5px 2.5px 4px var(--hover-purple);
    position: relative;
  }
  .adminChartsTitle2 {
    letter-spacing: 0.0375rem;
    font-weight: 400;
    color: var(--text-purple);
    font-size: 2rem;
    margin-top: 1rem;
    text-align: center;
    text-shadow: 2.5px 2.5px 4px var(--hover-purple);
  }
}
/* *************************************************************************
****************************************************************************
***************************************************************************/
/* *************************************************************************
****************************************************************************
***************************************************************************/
@media screen and (min-width: 1024px) and (max-width: 1025px) {
  .no-show-monitor {
    display: none!important;
  }
  .no-monitor {
    display: none!important;
  }
  /* dune start */
.GRTDailyTransactionsPosition {
  margin-bottom: 0.5rem!important;
  margin-left: -15px!important;
 }
 .HoldersGraph {
  background-image: linear-gradient(90deg, #1B0D33, #D8D2E4, #D8D2E4,#D8D2E4, #D8D2E4,#D8D2E4,#D8D2E4,#D8D2E4,#D8D2E4,#D8D2E4,#D8D2E4,#D8D2E4,#D8D2E4,#D8D2E4,#D8D2E4,#D8D2E4,#D8D2E4,#D8D2E4,#1B0D33);
  margin-bottom: -36px!important;
  margin-left: -10px!important;
  padding-left: 20px!important;
  padding-right: 20px!important;
  height: 80px!important;
  width: 100%!important;
}
.HoldersGraphBottom {
  background-image: linear-gradient(90deg, #1B0D33, #D8D2E4,#D8D2E4,#D8D2E4,#D8D2E4,#D8D2E4,#D8D2E4,#D8D2E4,#D8D2E4,#D8D2E4,#D8D2E4,#D8D2E4,#D8D2E4,#D8D2E4,#D8D2E4,#D8D2E4,#D8D2E4,#D8D2E4, #1B0D33);
  padding-left: 20px!important;
  margin-left: -10px!important;
  padding-right: 20px!important;
  height: 80px!important;
  width: 100%!important;
}
/* dune end */
  .chatbot-footer-bottom {
    margin-bottom: -80px!important;
   }
  .chatbot-link-logo{
    margin-top: 19rem;
    z-index: 9998;
    position: fixed;
    max-width: 6rem;
    background: white;
    -moz-border-radius: 50px;
    -webkit-border-radius: 50px;
    border-radius: 50px;
    padding: -1rem;
  right: 2%;
    border: 3px var(--secondary-purple) solid;
     box-shadow: 0 0 0 var(--hover-purple);
    animation: pulse 2s infinite;
  }
  .advocate-link-logo{
    margin-top: 27rem;
    z-index: 9998;
    position: fixed;
    max-width: 6rem;
    background: white;
    -moz-border-radius: 50px;
    -webkit-border-radius: 50px;
    border-radius: 50px;
    padding: -1rem;
  right: 2%;
    border: 3px var(--secondary-purple) solid;
     box-shadow: 0 0 0 var(--hover-purple);
    animation: pulse 2s infinite;
  }
  @-webkit-keyframes pulse {
    0% {
      -webkit-box-shadow: 0 0 0 0  var(--text-purple);
    }
    70% {
        -webkit-box-shadow: 0 0 0 15px rgba(244,157,22, 0);
    }
    100% {
        -webkit-box-shadow: 0 0 0 0 rgba(244,157,22, 0);
    }
  }
  @keyframes pulse {
    0% {
      -moz-box-shadow: 0 0 0 0  var(--text-purple);
      box-shadow: 0 0 0 0  var(--text-purple);
    }
    70% {
        -moz-box-shadow: 0 0 0 15px rgba(244,157,22, 0);
        box-shadow: 0 0 0 15px rgba(244,157,22, 0);
    }
    100% {
        -moz-box-shadow: 0 0 0 0 rgba(244,157,22, 0);
        box-shadow: 0 0 0 0 rgba(244,157,22, 0);
    }
  }
  
  .chatbot-link-export{
    margin-top: 25rem;
    z-index: 9998;
    position: fixed;
    max-width: 6rem;
    background: white;
    -moz-border-radius: 50px;
    -webkit-border-radius: 50px;
    border-radius: 50px;
    padding: -1rem;
   right: 2%;
    border: 3px var(--secondary-purple) solid;
    box-shadow: 0 0 0 var(--hover-purple);
    animation: pulse 2s infinite;
  }
  
/* Chatbot */
.feedback-text {
  margin-top: 2rem;
}
.feedback-button {
  border: 2px var(--primary-purple) solid!important;
  background-color: var(--text-purple)!important;
  color: var(--primary-purple)!important;
  padding: auto!important;
  margin: auto!important;
}

  .feedback-button:link {
    cursor: pointer;
  }
  .feedback-button:visited {
    cursor: pointer;
  }
  .feedback-button.active,
  .feedback-button:focus {
    cursor: pointer;
  }
  .feedback-button:hover {
    background-color: var(--background)!important;
    color: var(--text-purple)!important;
    transform: scale(1.015);
    cursor: pointer;
  }
  .feedback-button-text {
    font-size: 1.5rem;
  }

.react-chatbot-kit-chat-inner-container {
  background-color: var(--text-purple)!important;
}

.react-chatbot-kit-chat-header {
  font-size: 1.1rem!important;
  color: var(--text-purple)!important;
  background-color: var(--primary-purple)!important;
  padding: 0rem!important;
}

.react-chatbot-kit-chat-input-container {
  background-color:var(--secondary-purple)!important;
  border: 1px solid var(--primary-purple)!important;
}

.react-chatbot-kit-chat-btn-send {
  background-color:var(--primary-purple)!important;
}

.react-chatbot-kit-chat-btn-send-icon:hover {
transform: scale(1.25);
}

.react-chatbot-kit-chat-bot-message-container {
  width: 100%;
}

.react-chatbot-kit-chat-bot-avatar-container {
}

.react-chatbot-kit-chat-bot-avatar-icon {
}

.react-chatbot-kit-chat-bot-avatar-letter {
  display: none;
}
.react-chatbot-kit-chat-container {
  width: 98%!important;
  text-align: center;
  margin: auto;
}

.react-chatbot-kit-chat-bot-avatar-container {
  width: 3.5rem!important;
  height: 3.5rem!important;
  background-color: white!important;
  border: 2px solid var(--primary-purple);
  background-image: url('./GRTDataHubChatBot.png')!important;
  background-repeat: no-repeat!important;
  background-size: 3rem;
  background-position-x: 50%;
  background-position-y: 20%;
}

.react-chatbot-kit-chat-bot-message {
  text-align: left!important;
  width: 100%!important;
  color: var(--text-purple)!important;
  background-color: #0C0B23!important;
  font-size: 1rem!important;
}

.react-chatbot-kit-chat-bot-message-arrow {
  color: #0C0B23!important;
  border-right: 8px solid #0C0B23!important;
}
  .indexer-table-header {
    text-align: center;
    margin-left: 1rem;
  }
  .indexer-table {
    text-align: center;
  }
  .chatbot-data {
    display: inline!important;
  }
  .telegramActiveDelegationCount {
    max-width: 92%;
    margin: auto; 
    text-align: center;
    margin-top: 0.5%;
    }
    .top-telegram {
      margin-top: 0.25rem;
    }
    .chatbot-top {
      margin-top: 5rem;
    }
    .chatbot-page-container {
      background: var(--background);
      color: var(--text-purple);
      width: 100%;
      margin: auto;
      margin-top: 150px;
      padding-left: 10px;
      padding-right: 10px;
      text-align: center;
    }
    .chatbot-text-container {
      background: whitesmoke;
      border-radius: 1rem;
      color: var(--secondary-purple);
      margin: auto!important;
      padding-bottom: 10px;
      padding-top: 10px;
      text-align: center!important;
    }
    .chatbot-page-title {
      font-size: 1.25rem;
      font-weight: 600;
      color: var(--secondary-purple);
      padding-bottom: 0.25rem;
      padding-left: 5px;
      padding-right: 5px;
    }
    .chatbot-page-subtitle {
      font-size: 1.75rem;
      padding-left: 5px;
      padding-right: 5px;
    }
    .chatbot-page-text {
      font-size: 1.25rem;
      margin-bottom: 1rem;
      margin-top: 1rem;
      padding-left: 10px;
      padding-right: 10px;
    }
    /* chatbot end */
  .dataHub-logos {
    max-width: 6rem !important;
    max-height: 35rem !important;
    position: absolute;
    top: 0;
    margin-top: 1rem;
  }
  .mobile-config {
    display: none!important;
  }
  .offcanvas-button, .offcanvas-menu {
    display: none!important;
  }
  .navbar-toggler {
    display: none;
  }
  .pages {
    margin-bottom: 0.1rem;
    margin-right: 2rem;
}
.indexer-title {
  text-align: center!important;
  font-weight: 400;
  font-size: 1.75rem;
  padding-top: 2rem;
  color: var(--text-purple);
  
  margin-top: 6rem;
}
.indexer-subtitle {
  text-align: center!important;
  font-weight: 400;
  font-size: 1.5rem;
  color: var(--text-purple);
  
  margin-top: 1rem;
  margin-right: 2rem;
  margin-left: 2rem;
}
.indexer {
  z-index: 999;
  position: absolute;
  left: 0%;
  top: 50%;
}

.curator {
  z-index: 999;
  position: absolute;
  text-align: center!important;
  top: 50%;
}

.map, .tokens, .rewards, .uncollected-rewards {
  margin-top: 2rem;
  width: 90%;
  height: 14rem;
  margin-left: 5%;
  box-shadow: #4D1D86 4px 5px 10px 3px;
}

.c-map {
  margin-top: 2rem;
  width: 100%;
  height: 30rem;
  margin-left: 5%;
  box-shadow: #4D1D86 4px 5px 10px 3px;
}

.d-map {
  margin-top: -3rem;
  margin-left: 1rem;
  width: 1240px;
  height: 3800px;
  text-align: center!important;
  box-shadow: #4D1D86 4px 5px 10px 3px;
}

.rewards-per-hour {
  margin-top: 2rem;
  padding-bottom: 2rem;
  width: 90%;
  height: 14rem;
  margin-left: 5%;
  box-shadow: #4D1D86 4px 5px 10px 3px;
}

.indexers {
  padding-left: 1rem;
  padding-left: 1rem;
  color: grey;
  font-size:1.25rem;
  margin-right: 1rem;
}
.indexers:link{
  color: grey;
  cursor: pointer;
  font-size:1.25rem;
  margin-right: 1rem;
}
.indexers:visited {
  color: grey;
  cursor: pointer;
  font-size:1.25rem;
  margin-right: 1rem;
}
.indexers.active,
.indexers:focus {
  border-bottom: 0rem solid var(--hover-purple);
  cursor: pointer;
  font-size:1.25rem;
  margin-right: 1rem;
}
.indexers:hover {
  color: var(--text-purple);
  transform: scale(1.05);
  cursor: pointer;
  font-size:1.25rem;
  margin-right: 1rem;
}
.indexer-link {
  color: white;
}
.indexer-link:link {
  color: white;
  cursor: pointer;
}
.indexer-link:visited {
  color: var(--text-purple);
  cursor: pointer;
}
.indexer-link.active,
.indexer-link:focus {
  border-bottom: 0rem solid var(--text-purple);
  cursor: pointer;
}
.indexer-link:hover {
  color: var(--text-purple);
  transform: scale(1.05);
  cursor: pointer;
}
  .cookie-page-text {
    padding-top: 1rem;
    font-size: 1.25rem;
    margin-bottom: 1rem;
    padding-left: 20px;
    padding-right: 20px;
  }
  .cookies-video {
    padding-top: 2rem;
  }
  .cookie-button {
    z-index: 9999;
    position: absolute;
  }

  body > div:nth-child(1) > button {
    z-index: 10000;
    font-size: 3rem;
    position: absolute;
    padding: auto;
    margin-top: -95px;
    width: 45px;
    height: 45px;
    margin-left: 85%;
    background-color: transparent;
    border: none;
    color: var(--text-purple);
  }
  .extra-data {
    margin-top: 2rem;
    font-size: 1.25rem;
    text-align: center;
    color: var(--text-purple);
   
  }
  .extra-data2 {
    font-size: 1.25rem;
    text-align: center;
    color: var(--text-purple);
   
  }
  .data-link {
    color: grey;
  }
  .data-link:link {
    color: grey;
    cursor: pointer;
  }
  .data-link:visited {
    color: grey;
    cursor: pointer;
  }
  .data-link.active,
  .data-link:focus {
    border-bottom: 0rem solid white;
    cursor: pointer;
  }
  .data-link:hover {
    color: white;
    transform: scale(1.05);
    cursor: pointer;
  }
  .question-bank-container {
    background: var(--background);
    color: var(--text-purple);
    width: 100%;
    margin: auto;
    margin-top: 150px;
    margin-bottom: 50px;
    padding-left: 50px;
    padding-right: 50px;
    text-align: center;
  }
  .about-page-container {
    background: var(--background);
    color: var(--text-purple);
    width: 100%;
    margin: auto;
    margin-top: 150px;
    margin-bottom: 50px;
    padding-left: 50px;
    padding-right: 50px;
    text-align: center;
  }
  .QB-text-container {
    background: white;
    border-radius: 1rem;
    padding: 30px;
    color: var(--secondary-purple);
  }
  .text-container {
    background: white;
    border-radius: 1rem;
    padding: 30px;
    color: var(--secondary-purple);
  }
  .QB-about-hr {
    border: 1.5px solid var(--background);
    color: var(--background);
    border-radius: 5px;
    width: 35%;
    margin: auto;
    margin-bottom: 1rem;
    box-shadow: 0 0 5px 1px var(--background);
  }
  .QB-about-page-title {
    font-size: 2rem;
    font-weight: 600;
    color: var(--secondary-purple);
    padding-bottom: 2rem;
  }
  .QB-about-page-subtitle {
    font-size: 1.75rem;
  }
  .QB-about-page-text {
    font-size: 1.25rem;
    margin-bottom: 1rem;
  }
  .about-hr {
    border: 1.5px solid var(--background);
    color: var(--background);
    border-radius: 5px;
    width: 35%;
    margin: auto;
    margin-bottom: 1rem;
    box-shadow: 0 0 5px 1px var(--background);
  }
  .about-page-title {
    font-size: 2rem;
    font-weight: 600;
    color: var(--secondary-purple);
    padding-bottom: 2rem;
  }
  .about-page-subtitle {
    font-size: 1.75rem;
  }
  .about-page-text {
    font-size: 1.25rem;
    margin-bottom: 1rem;
  }
  .graphiql-link {
    color: var(--secondary-purple);
  }
  .graphiql-link:link {
    color: var(--secondary-purple);
    cursor: pointer;
  }
  .graphiql-link:visited {
    color: var(--secondary-purple);
    cursor: pointer;
  }
  .graphiql-link.active,
  .graphiql-link:focus {
    border-bottom: 0rem solid var(--hover-purple);
    cursor: pointer;
  }
  .graphiql-link:hover {
    color: var(--primary-purple);
    transform: scale(1.05);
    cursor: pointer;
  }
  .cookies-policy-container {
    background: white;
    width: 90%;
    padding-bottom: 1rem;
    margin: auto;
    border-radius: 2rem;
    margin-top: 150px;
    margin-bottom: 50px;
  }
  .termly-cookie-preference-button {
   display: none;
  }

  .privacy-policy-container {
    background: white;
    width: 90%;
    padding-bottom: 1rem;
    margin: auto;
    border-radius: 2rem;
    margin-top: 150px;
    margin-bottom: 50px;
  }
  .privacy-policy {
    margin-top: 110px;
    font-weight: 700;
    font-size: 1.1rem;
    text-align: center;
    background-color: var(--background);
    color: var(--secondary-purple);
  }
  .privacy-policy-link,
  .about {
    padding-left: 1rem;
    padding-left: 1rem;
    color: grey;
  }
  .privacy-policy-link:link,
  .about:link {
    color: grey;
    cursor: pointer;
  }
  .privacy-policy-link:visited,
  .about:visited {
    color: grey;
    cursor: pointer;
  }
  .privacy-policy-link.active,
  .privacy-policy-link:focus,
  .about.active,
  .about:focus {
    border-bottom: 0rem solid var(--hover-purple);
    cursor: pointer;
  }
  .privacy-policy-link:hover,
  .about:hover {
    color: var(--text-purple);
    transform: scale(1.05);
    cursor: pointer;
  }
  .subscribeNote {
    margin-left: -15rem;
    width: 30rem;
    font-size: 0.75rem;
    color: white;
  }
  /* loader new start  */
  .loader:empty {
    position: absolute;
    top: calc(50% + 15.5rem);
    left: calc(50% + 2em);
    width: 4em;
    height: 4em;
    border: 0.35em solid white;
    border-left: 0.35em solid #10034B;
    border-radius: 50%;
    animation: load8 0.8s infinite linear;
  }
  .loadingTitle {
    position: absolute;
    top: 27rem;
    left: calc(50% - 3em);
    font-size: 2rem;
    color: #10034B;
    -webkit-box-reflect:below 0px linear-gradient(transparent, #0005);
  }
  .loaderLogo {
    position: absolute;
    top: 14rem;
    left: calc(50% - 8em);
    width: 15em;
    height: 12em;
  }
  @keyframes load8 {
    0% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(360deg);
    }
  }
  /* loader new end  */
  .C {
    font-size: 1rem;
  }
  .logo {
    width:  6.75rem;
    height: 5.75rem;
    margin-left: 2rem;
    margin-top: -0.5rem;
  }
  .data-logo {
    max-width: 1rem !important;
    height: 1rem !important;
    position: absolute;
  }
  .brand-text {
    color: var(--text-purple);
    display: inline;
    margin-left: 0rem;
    letter-spacing: 0.0375rem;
    font-size: 1.25rem;
  }
  .header-text {
    font-weight: 400;
    font-size: 1.75rem;
    margin-left: 5rem;
    margin-top: 105px;
    color: var(--text-purple);
 
  }
  .header-sub-text {
    font-weight: 400;
    font-size: 1.25rem;
    text-align: center!important;
    margin: auto!important;
    width: 90%;
    color: var(--text-purple);

    display: block;
  }
  nav a.TGN {
    display: none;
  }
  .navbar-nav > li > a.TGN:link {
    display: none;
  }
  .navbar-nav > li > a.TGN:visited {
    display: none;
  }
  .navbar-nav > li > a.TGN.active,
  .navbar-nav > li > a.TGN:focus {
    display: none;
  }
  .navbar-nav > li > a.TGN:hover {
    display: none;
  }
  nav a.nav-link {
    font-weight: 400;
    font-size: 1.25rem;
    color: var(--text-purple);
  }
  .navbar-nav > li > a {
    border-bottom: 0.01875rem solid var(--transparent);
    padding: 0;
  }
  .navbar-nav > li > a:link {
    color: var(--hover-purple);
  }
  .navbar-nav > li > a:visited {
    color: var(--text-purple);
  }
  .navbar-nav > li > a.active,
  .navbar-nav > li > a:focus {
    border-bottom: 0.01875rem solid var(--hover-purple);
    padding: 0;
    transition: all 0.5s ease-in-out;
  }
  .navbar-nav > li > a:hover {
    border-bottom: 0.01875rem solid var(--hover-purple);
    padding: 0;
    color: var(--hover-purple);
    transform: scale(1.05);
  }
  .line {
    margin-left: 1rem;
  }
  .links {
    text-align: right;
    padding-right: 0;
    margin: auto;
    padding: auto;
  }
  .dropdown {
    margin-left: auto;
    padding: auto;
  }
  .bar {
    margin-bottom: 0.25rem;
    margin-right: 2rem;
  }
  .line {
    margin-bottom: 0.25rem;
    margin-right: 2rem;
  }
  .about {
    margin-left: auto;
    margin-right: 0.8rem;
  }
  .menu-icon {
    color: var(--secondary-purple);
  }
  .main-info {
    background-image: url("TopBackground.svg");
    background-repeat: no-repeat;
    background-size: 100% auto;
    width: 100%;
    height: 100%;
    margin-top: 90px;
  }
  .community-links {
    margin-left: 4rem;
    padding-top: 1rem;
    position: absolute;
    z-index: 999;
  }
  .telegram-icon,
  .twitter-icon,
  .reddit-icon,
  .youtube-icon {
    display: inline;
    padding: 1rem;
    transition: all 0.5s ease-in-out;
    z-index: 999;
  }
  .icon:hover {
    color: var(--hover-purple);
    transform: scale(1.15);
  }
  .icon-youtube:hover {
    color: var(--text-purple);
    transform: scale(1.1);
  }
  .news-title {
    text-shadow: 2.25px 2.25px 3px var(--hover-purple);
    color: var(--text-purple);
    text-align: center;
    margin-top: 1rem;
    margin-bottom: 1rem;
    font-size: 2rem;
    margin-left: -23rem;
  }
  .newsGraph1,
  .newsGraph2,
  .newsGraph3 {
    margin-bottom: 1rem;
  }
  .youtube {
    z-index: 999;
    display: inline;
    position: absolute;
    text-align: center;
    color: var(--text-purple);
  }
  span.anchor {
    display: block;
    visibility: hidden;
    position: relative;
    padding-top: 20%;
  }
  .activeDelegators,
  .holders,
  .curatorCount,
  .subgraphCount {
    font-weight: 400;
    color: var(--text-purple);
    letter-spacing: 0.0375rem;
  }
  .hr {
    border: 3px solid var(--hr);
    border-radius: 5px;
    width: 90%;
    margin: auto;
    box-shadow: 0 0 10px 3px var(--hover-purple);
    position: relative;
    margin-top: 2.5rem;
  }
  .hr2 {
    border: 3px solid var(--hr);
    border-radius: 5px;
    width: 90%;
    margin: auto;
    box-shadow: 0 0 10px 3px var(--hover-purple);
    position: relative;
    margin-top: 1.5rem;
  }
  .video {
    position: absolute;
    box-shadow: #4D1D86 4px 8px 10px 0;
    left: 55%;
    top: 0%;
  }
  .g-ytsubscribe {
    position: absolute;
    font-family: Euclid Circular A;
    font-weight: 400;
    margin: 0;
    left: 78%;
    top: 41%;
  }
  .data-title {
    letter-spacing: 0.0375rem;
    font-weight: 400;
    color: var(--text-purple);
    font-size: 3.5rem;
    text-align: center;
    position: relative;
  }
  .chartsTitle {
    letter-spacing: 0.0375rem;
    font-weight: 400;
    color: var(--text-purple);
    font-size: 3.5rem;
    text-align: center;
 
    position: relative;
  }
  .chartsTitle2 {
    letter-spacing: 0.0375rem;
    font-weight: 400;
    color: var(--text-purple);
    font-size: 3.5rem;
    text-align: center;

  }
  .newsGraph {
    width: 100%;
    box-shadow: #4D1D86 4px 5px 10px 3px;
  }
  .chartImg {
    width: 100%;
    box-shadow: #4D1D86 6px 6px 10px 3px;
    margin: auto;
  }
  .center {
    margin: auto;
  }
  .line1 {
    margin-top: 5rem;
  }
  .chartImg1 {
    text-align: center;
    padding-top: -7rem;
    padding-bottom: 1rem;
    width: 100%;
    margin-top: -120px;
    margin-bottom: -120px;
  }
  .chartImg2,
  .chartImg3,
  .chartImg4,
  .chartImg5 {
    text-align: center;
    padding-top: 1rem;
    padding-bottom: 1rem;
    width: 100%;
    margin-top: -120px;
    margin-bottom: -120px;
  }
  .chartImg6 {
    text-align: center;
    padding-top: 1rem;
    width: 100%;
    margin-top: -120px;
  }
  .reveal {
    position: relative;
    transform: translateY(50px);
    opacity: 0;
    transition: 0.75s all ease;
  }
  .reveal.active {
    transform: translateY(0);
    opacity: 1;
  }
  .email {
    color: var(--hover-purple);
    font-weight: 500;
  }
  .footerLink {
    color: var(--hover-purple);
  }
  .footerLink:link {
    color: var(--hover-purple);
    cursor: pointer;
  }
  .footerLink:visited {
    color: var(--hover-purple);
    cursor: pointer;
  }
  .footerLink.active,
  .footerLink:focus {
    border-bottom: 0rem solid var(--hover-purple);
    cursor: pointer;
  }
  .footerLink:hover {
    color: var(--text-purple);
    transform: scale(1.05);
    cursor: pointer;
  }
  .footer {
    margin-bottom: -1rem;
    padding: 1rem;
    font-weight: 700;
    margin-top: 1rem;
    font-size: 1.1rem;
    text-align: center;
    background-color: var(--primary-purple);
    color: var(--secondary-purple);
  }
  .tsparticles {
    position: absolute;
    height: 100%;
    width: 100%;
  }
  .rotate-position,
  .about-rotate-position,
  .phone,
  .about-phone,
  .message,
  .about-message, .home-message {
    display: none;
  }
  .adminHeader-text {
    font-weight: 400;
    font-size: 1.5rem;
    text-align: center;
    margin: auto;
    padding-top: 1rem;
    color: var(--text-purple);

    margin-top: 6rem;
  }
  .adminHeader-text2 {
    font-weight: 400;
    font-size: 1.5rem;
    text-align: center;
    margin: auto;
    color: var(--text-purple);
  
    margin-top: 2rem;
  }
  .adminSubheader-text {
    font-weight: 400;
    font-size: 1rem;
    margin-left: 3rem;
    color: var(--text-purple);
    
    margin-top: 1rem;
  }
  .adminSubheader-text2 {
    font-weight: 400;
    font-size: 1rem;
    margin-left: 3rem;
    margin-right: 3rem;
    color: var(--text-purple);
 
    margin-top: 1rem;
  }
  .note {
    text-decoration: underline;
  }

  .adminHr2 {
    border: 3px solid var(--hr);
    border-radius: 5px;
    width: 70%;
    margin: auto;
    box-shadow: 0 0 10px 3px var(--hover-purple);
    margin-top: 1rem;
    margin-left: 1.9rem;
  }

 .admin-video {
    position: absolute;
    left: 48%;
    top: 20%;
    width: 10%;
    height: 10%;
  }

  .adminChartsTitle {
    letter-spacing: 0.0375rem;
    font-weight: 400;
    color: var(--text-purple);
    font-size: 2rem;
    text-align: center;
    padding-top: 1rem;
    text-shadow: 2.5px 2.5px 4px var(--hover-purple);
    position: relative;
  }
  .adminChartsTitle2 {
    letter-spacing: 0.0375rem;
    font-weight: 400;
    color: var(--text-purple);
    font-size: 2rem;
    margin-top: 1rem;
    text-align: center;
    text-shadow: 2.5px 2.5px 4px var(--hover-purple);
  }
}
/* *************************************************************************
****************************************************************************
***************************************************************************/
/* *************************************************************************
****************************************************************************
***************************************************************************/
@media screen and (min-width: 929px) and (max-width: 1023px) {
  .flashcards-title {
margin-top: 120px!important;
}
  .hide-line {
    color: transparent!important;
    display: block!important;
  }
  .Quiz-button {
    margin-bottom: 2rem;
  }
  .quiz-title {
    margin-top: -60px;
  }
  .quiz-button-position {
    padding-top: 2rem;
    margin: auto;
    text-align: center;
    margin-bottom: 2rem;
  }
  
  .final-exam-question {
      font-size: 1.3rem;
      text-align: center;
      color: #d8d2e4;
      /* remove for mobile */
      margin-top: 0rem;
      /* remove for mobile */
  }
  
  .final-exam-answer-form {
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin-top: 0rem;
    columns: 1!important;
    width: 100%!important;
  }
  
  .form-check-label {
    font-size: 20px;
    text-align: center;
    height: 110%;
  }
  .quiz-question {
    font-size: 1.3rem;
    padding-bottom: 2rem;
  text-align: center;
  width: 80%;
  margin: auto;
    text-align: center;
    color: #d8d2e4;
  }
  .quiz-question-container {
    margin-top: 20px;
    width: 100%;
  }

  .quiz-answer-form {
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin-top: 0rem;
    columns: 1!important;
    width: 100%!important;
  }

  .quiz-position {
    margin-top: -10px;
  }
  .mobile-portrait {
    display: none;
    }
  .no-show-monitor {
    display: none!important;
  }
  .no-monitor {
    display: none!important;
  }
  /* dune start */
.GRTDailyTransactionsPosition {
  margin-bottom: 0.5rem!important;
  margin-left: -15px!important;
 }
 .HoldersGraph {
  background-image: linear-gradient(90deg, #1B0D33, #D8D2E4, #D8D2E4,#D8D2E4, #D8D2E4,#D8D2E4,#D8D2E4,#D8D2E4,#D8D2E4,#D8D2E4,#D8D2E4,#D8D2E4,#D8D2E4,#D8D2E4,#D8D2E4,#D8D2E4,#D8D2E4,#D8D2E4,#1B0D33);
  margin-bottom: -36px!important;
  margin-left: -10px!important;
  padding-left: 20px!important;
  padding-right: 20px!important;
  height: 80px!important;
  width: 100%!important;
}
.HoldersGraphBottom {
  background-image: linear-gradient(90deg, #1B0D33, #D8D2E4,#D8D2E4,#D8D2E4,#D8D2E4,#D8D2E4,#D8D2E4,#D8D2E4,#D8D2E4,#D8D2E4,#D8D2E4,#D8D2E4,#D8D2E4,#D8D2E4,#D8D2E4,#D8D2E4,#D8D2E4,#D8D2E4, #1B0D33);
  padding-left: 20px!important;
  margin-left: -10px!important;
  padding-right: 20px!important;
  height: 80px!important;
  width: 100%!important;
}
/* dune end */
  .chatbot-footer-bottom {
    margin-bottom: -80px!important;
   }
  .chatbot-link-logo{
    margin-top: 13rem;
    z-index: 9998;
    position: fixed;
    max-width: 6rem;
    background: white;
    -moz-border-radius: 50px;
    -webkit-border-radius: 50px;
    border-radius: 50px;
    padding: -1rem;
   right: 2%;
    border: 3px var(--secondary-purple) solid;
    box-shadow: 0 0 0 var(--hover-purple);
    animation: pulse 2s infinite;
  }
  .advocate-link-logo{
    margin-top: 21rem;
    z-index: 9998;
    position: fixed;
    max-width: 6rem;
    background: white;
    -moz-border-radius: 50px;
    -webkit-border-radius: 50px;
    border-radius: 50px;
    padding: -1rem;
   right: 2%;
    border: 3px var(--secondary-purple) solid;
    box-shadow: 0 0 0 var(--hover-purple);
    animation: pulse 2s infinite;
  }
  @-webkit-keyframes pulse {
    0% {
      -webkit-box-shadow: 0 0 0 0  var(--text-purple);
    }
    70% {
        -webkit-box-shadow: 0 0 0 15px rgba(244,157,22, 0);
    }
    100% {
        -webkit-box-shadow: 0 0 0 0 rgba(244,157,22, 0);
    }
  }
  @keyframes pulse {
    0% {
      -moz-box-shadow: 0 0 0 0  var(--text-purple);
      box-shadow: 0 0 0 0  var(--text-purple);
    }
    70% {
        -moz-box-shadow: 0 0 0 15px rgba(244,157,22, 0);
        box-shadow: 0 0 0 15px rgba(244,157,22, 0);
    }
    100% {
        -moz-box-shadow: 0 0 0 0 rgba(244,157,22, 0);
        box-shadow: 0 0 0 0 rgba(244,157,22, 0);
    }
  }
  
  .chatbot-link-export{
    margin-top: 18rem;
    z-index: 9998;
    position: fixed;
    max-width: 6rem;
    background: white;
    -moz-border-radius: 50px;
    -webkit-border-radius: 50px;
    border-radius: 50px;
    padding: -1rem;
 right: 2%;
    border: 3px var(--secondary-purple) solid;
    box-shadow: 0 0 0 var(--hover-purple);
    animation: pulse 2s infinite;
  }
  
/* Chatbot */
.feedback-text {
  margin-top: 2rem;
}
.feedback-button {
  border: 2px var(--primary-purple) solid!important;
  background-color: var(--text-purple)!important;
  color: var(--primary-purple)!important;
  padding: auto!important;
  margin: auto!important;
}

  .feedback-button:link {
    cursor: pointer;
  }
  .feedback-button:visited {
    cursor: pointer;
  }
  .feedback-button.active,
  .feedback-button:focus {
    cursor: pointer;
  }
  .feedback-button:hover {
    background-color: var(--background)!important;
    color: var(--text-purple)!important;
    transform: scale(1.015);
    cursor: pointer;
  }
  .feedback-button-text {
    font-size: 1.5rem;
  }

.react-chatbot-kit-chat-inner-container {
  background-color: var(--text-purple)!important;
}

.react-chatbot-kit-chat-header {
  font-size: 1.1rem!important;
  color: var(--text-purple)!important;
  background-color: var(--primary-purple)!important;
  padding: 0rem!important;
}

.react-chatbot-kit-chat-input-container {
  background-color:var(--secondary-purple)!important;
  border: 1px solid var(--primary-purple)!important;
}

.react-chatbot-kit-chat-btn-send {
  background-color:var(--primary-purple)!important;
}

.react-chatbot-kit-chat-btn-send-icon:hover {
transform: scale(1.25);
}

.react-chatbot-kit-chat-bot-message-container {
  width: 100%;
}

.react-chatbot-kit-chat-bot-avatar-container {
}

.react-chatbot-kit-chat-bot-avatar-icon {
}

.react-chatbot-kit-chat-bot-avatar-letter {
  display: none;
}
.react-chatbot-kit-chat-container {
  width: 98%!important;
  text-align: center;
  margin: auto;
}

.react-chatbot-kit-chat-bot-avatar-container {
  width: 3.5rem!important;
  height: 3.5rem!important;
  background-color: white!important;
  border: 2px solid var(--primary-purple);
  background-image: url('./GRTDataHubChatBot.png')!important;
  background-repeat: no-repeat!important;
  background-size: 3rem;
  background-position-x: 50%;
  background-position-y: 20%;
}

.react-chatbot-kit-chat-bot-message {
  text-align: left!important;
  width: 100%!important;
  color: var(--text-purple)!important;
  background-color: #0C0B23!important;
  font-size: 1rem!important;
}

.react-chatbot-kit-chat-bot-message-arrow {
  color: #0C0B23!important;
  border-right: 8px solid #0C0B23!important;
}
  .indexer-table-header {
    text-align: center;
    margin-left: 1rem;
  }
  .indexer-table {
    text-align: center;
  }
  .chatbot-data {
    display: inline!important;
  }
  .telegramActiveDelegationCount {
    max-width: 92%;
    margin: auto; 
    text-align: center;
    margin-top: 0.5%;
    }
    .top-telegram {
      margin-top: 0.25rem;
    }
    .chatbot-top {
      margin-top: 5rem;
    }
    .chatbot-page-container {
      background: var(--background);
      color: var(--text-purple);
      width: 100%;
      margin: auto;
      margin-top: 150px;
      padding-left: 10px;
      padding-right: 10px;
      text-align: center;
    }
    .chatbot-text-container {
      background: whitesmoke;
      border-radius: 1rem;
      color: var(--secondary-purple);
      margin: auto!important;
      padding-bottom: 10px;
      padding-top: 10px;
      text-align: center!important;
    }
    .chatbot-page-title {
      font-size: 1.25rem;
      font-weight: 600;
      color: var(--secondary-purple);
      padding-bottom: 0.25rem;
      padding-left: 5px;
      padding-right: 5px;
    }
    .chatbot-page-subtitle {
      font-size: 1.75rem;
      padding-left: 5px;
      padding-right: 5px;
    }
    .chatbot-page-text {
      font-size: 1.25rem;
      margin-bottom: 1rem;
      margin-top: 1rem;
      padding-left: 10px;
      padding-right: 10px;
    }
    /* chatbot end */
  .dataHub-logos {
    max-width: 6rem !important;
    max-height: 35rem !important;
    position: absolute;
    top: 0;
    margin-top: 1rem;
  }
  .mobile-config {
    display: none!important;
  }
  .offcanvas-button, .offcanvas-menu {
    display: none!important;
  }
  .navbar-toggler {
    display: none;
  }
  .pages {
    margin-bottom: 0.1rem;
    margin-right: 2rem;
}
.indexer-title {
  text-align: center!important;
  font-weight: 400;
  font-size: 1.75rem;
  padding-top: 2rem;
  color: var(--text-purple);
  
  margin-top: 6rem;
}
.indexer-subtitle {
  text-align: center!important;
  font-weight: 400;
  font-size: 1.5rem;
  color: var(--text-purple);
  
  margin-top: 1rem;
  margin-right: 2rem;
  margin-left: 2rem;
}
.indexer {
  z-index: 999;
  position: absolute;
  left: 0%;
  top: 50%;
}

.curator {
  z-index: 999;
  position: absolute;
  text-align: center!important;
  top: 50%;
}

.map, .tokens, .rewards, .uncollected-rewards {
  margin-top: 2rem;
  width: 90%;
  height: 14rem;
  margin-left: 5%;
  box-shadow: #4D1D86 4px 5px 10px 3px;
}

.c-map {
  margin-top: 2rem;
  width: 100%;
  height: 30rem;
  margin-left: 5%;
  box-shadow: #4D1D86 4px 5px 10px 3px;
}

.d-map {
  margin-top: -3rem;
  margin-left: 1rem;
  width: 1240px;
  height: 3800px;
  text-align: center!important;
  box-shadow: #4D1D86 4px 5px 10px 3px;
}

.rewards-per-hour {
  margin-top: 2rem;
  padding-bottom: 2rem;
  width: 90%;
  height: 14rem;
  margin-left: 5%;
  box-shadow: #4D1D86 4px 5px 10px 3px;
}

.indexers {
  padding-left: 1rem;
  padding-left: 1rem;
  color: grey;
  font-size:1.25rem;
  margin-right: 1rem;
}
.indexers:link{
  color: grey;
  cursor: pointer;
  font-size:1.25rem;
  margin-right: 1rem;
}
.indexers:visited {
  color: grey;
  cursor: pointer;
  font-size:1.25rem;
  margin-right: 1rem;
}
.indexers.active,
.indexers:focus {
  border-bottom: 0rem solid var(--hover-purple);
  cursor: pointer;
  font-size:1.25rem;
  margin-right: 1rem;
}
.indexers:hover {
  color: var(--text-purple);
  transform: scale(1.05);
  cursor: pointer;
  font-size:1.25rem;
  margin-right: 1rem;
}
.indexer-link {
  color: white;
}
.indexer-link:link {
  color: white;
  cursor: pointer;
}
.indexer-link:visited {
  color: var(--text-purple);
  cursor: pointer;
}
.indexer-link.active,
.indexer-link:focus {
  border-bottom: 0rem solid var(--text-purple);
  cursor: pointer;
}
.indexer-link:hover {
  color: var(--text-purple);
  transform: scale(1.05);
  cursor: pointer;
}
  .cookie-page-text {
    padding-top: 1rem;
    font-size: 1.25rem;
    margin-bottom: 1rem;
    padding-left: 20px;
    padding-right: 20px;
  }
  .cookies-video {
    padding-top: 2rem;
  }
  .cookie-button {
    z-index: 9999;
    position: absolute;
  }

  body > div:nth-child(1) > button {
    z-index: 10000;
    font-size: 3rem;
    position: absolute;
    padding: auto;
    margin-top: -95px;
    width: 45px;
    height: 45px;
    margin-left: 85%;
    background-color: transparent;
    border: none;
    color: var(--text-purple);
  }
  .extra-data {
    margin-top: 2rem;
    font-size: 1.25rem;
    text-align: center;
    color: var(--text-purple);
   
  }
  .extra-data2 {
    font-size: 1.25rem;
    text-align: center;
    color: var(--text-purple);
   
  }
  .data-link {
    color: grey;
  }
  .data-link:link {
    color: grey;
    cursor: pointer;
  }
  .data-link:visited {
    color: grey;
    cursor: pointer;
  }
  .data-link.active,
  .data-link:focus {
    border-bottom: 0rem solid white;
    cursor: pointer;
  }
  .data-link:hover {
    color: white;
    transform: scale(1.05);
    cursor: pointer;
  }
  .question-bank-container {
    background: var(--background);
    color: var(--text-purple);
    width: 100%;
    margin: auto;
    margin-top: 150px;
    margin-bottom: 50px;
    padding-left: 50px;
    padding-right: 50px;
    text-align: center;
  }
  .about-page-container {
    background: var(--background);
    color: var(--text-purple);
    width: 100%;
    margin: auto;
    margin-top: 150px;
    margin-bottom: 50px;
    padding-left: 50px;
    padding-right: 50px;
    text-align: center;
  }
  .QB-text-container {
    background: white;
    border-radius: 1rem;
    padding: 30px;
    color: var(--secondary-purple);
  }
  .text-container {
    background: white;
    border-radius: 1rem;
    padding: 30px;
    color: var(--secondary-purple);
  }
  .QB-about-hr {
    border: 1.5px solid var(--background);
    color: var(--background);
    border-radius: 5px;
    width: 35%;
    margin: auto;
    margin-bottom: 1rem;
    box-shadow: 0 0 5px 1px var(--background);
  }
  .QB-about-page-title {
    font-size: 2rem;
    font-weight: 600;
    color: var(--secondary-purple);
    padding-bottom: 2rem;
  }
  .QB-about-page-subtitle {
    font-size: 1.75rem;
  }
  .QB-about-page-text {
    font-size: 1.25rem;
    margin-bottom: 1rem;
  }
  .about-hr {
    border: 1.5px solid var(--background);
    color: var(--background);
    border-radius: 5px;
    width: 35%;
    margin: auto;
    margin-bottom: 1rem;
    box-shadow: 0 0 5px 1px var(--background);
  }
  .about-page-title {
    font-size: 2rem;
    font-weight: 600;
    color: var(--secondary-purple);
    padding-bottom: 2rem;
  }
  .about-page-subtitle {
    font-size: 1.75rem;
  }
  .about-page-text {
    font-size: 1.25rem;
    margin-bottom: 1rem;
  }
  .graphiql-link {
    color: var(--secondary-purple);
  }
  .graphiql-link:link {
    color: var(--secondary-purple);
    cursor: pointer;
  }
  .graphiql-link:visited {
    color: var(--secondary-purple);
    cursor: pointer;
  }
  .graphiql-link.active,
  .graphiql-link:focus {
    border-bottom: 0rem solid var(--hover-purple);
    cursor: pointer;
  }
  .graphiql-link:hover {
    color: var(--primary-purple);
    transform: scale(1.05);
    cursor: pointer;
  }
  .cookies-policy-container {
    background: white;
    width: 90%;
    padding-bottom: 1rem;
    margin: auto;
    border-radius: 2rem;
    margin-top: 150px;
    margin-bottom: 50px;
  }
  .termly-cookie-preference-button {
   display: none;
  }

  .privacy-policy-container {
    background: white;
    width: 90%;
    padding-bottom: 1rem;
    margin: auto;
    border-radius: 2rem;
    margin-top: 150px;
    margin-bottom: 50px;
  }
  .privacy-policy {
    margin-top: 110px;
    font-weight: 700;
    font-size: 1.1rem;
    text-align: center;
    background-color: var(--background);
    color: var(--secondary-purple);
  }
  .privacy-policy-link,
  .about {
    padding-left: 1rem;
    padding-left: 1rem;
    color: grey;
  }
  .privacy-policy-link:link,
  .about:link {
    color: grey;
    cursor: pointer;
  }
  .privacy-policy-link:visited,
  .about:visited {
    color: grey;
    cursor: pointer;
  }
  .privacy-policy-link.active,
  .privacy-policy-link:focus,
  .about.active,
  .about:focus {
    border-bottom: 0rem solid var(--hover-purple);
    cursor: pointer;
  }
  .privacy-policy-link:hover,
  .about:hover {
    color: var(--text-purple);
    transform: scale(1.05);
    cursor: pointer;
  }
  .subscribeNote {
    margin-left: -15rem;
    width: 30rem;
    font-size: 0.75rem;
    color: white;
  }
  /* loader new start  */
  .loader:empty {
    position: absolute;
    top: calc(50% + 15.5rem);
    left: calc(50% + 2em);
    width: 4em;
    height: 4em;
    border: 0.35em solid white;
    border-left: 0.35em solid #10034B;
    border-radius: 50%;
    animation: load8 0.8s infinite linear;
  }
  .loadingTitle {
    position: absolute;
    top: 27rem;
    left: calc(50% - 3em);
    font-size: 2rem;
    color: #10034B;
    -webkit-box-reflect:below 0px linear-gradient(transparent, #0005);
  }
  .loaderLogo {
    position: absolute;
    top: 14rem;
    left: calc(50% - 8em);
    width: 15em;
    height: 12em;
  }
  @keyframes load8 {
    0% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(360deg);
    }
  }
  /* loader new end  */
  .C {
    font-size: 1rem;
  }
  .logo {
    width:  6.75rem;
    height: 5.75rem;
    margin-left: 2rem;
    margin-top: -0.5rem;
  }
  .data-logo {
    max-width: 1rem !important;
    height: 1rem !important;
    position: absolute;
  }
  .brand-text {
    color: var(--text-purple);
    display: inline;
    margin-left: 0rem;
    letter-spacing: 0.0375rem;
    font-size: 1.25rem;
  }
  .header-text {
    font-weight: 400;
    font-size: 1.75rem;
    margin-left: 5rem;
    margin-top: 105px;
    color: var(--text-purple);
  
  }
  .header-sub-text {
    font-weight: 400;
    font-size: 1.25rem;
    text-align: center!important;
    margin: auto!important;
    width: 90%;
    color: var(--text-purple);

    display: block;
  }
  nav a.TGN {
    display: none;
  }
  .navbar-nav > li > a.TGN:link {
    display: none;
  }
  .navbar-nav > li > a.TGN:visited {
    display: none;
  }
  .navbar-nav > li > a.TGN.active,
  .navbar-nav > li > a.TGN:focus {
    display: none;
  }
  .navbar-nav > li > a.TGN:hover {
    display: none;
  }
  nav a.nav-link {
    font-weight: 400;
    font-size: 1.25rem;
    color: var(--text-purple);
  }
  .navbar-nav > li > a {
    border-bottom: 0.01875rem solid var(--transparent);
    padding: 0;
  }
  .navbar-nav > li > a:link {
    color: var(--hover-purple);
  }
  .navbar-nav > li > a:visited {
    color: var(--text-purple);
  }
  .navbar-nav > li > a.active,
  .navbar-nav > li > a:focus {
    border-bottom: 0.01875rem solid var(--hover-purple);
    padding: 0;
    transition: all 0.5s ease-in-out;
  }
  .navbar-nav > li > a:hover {
    border-bottom: 0.01875rem solid var(--hover-purple);
    padding: 0;
    color: var(--hover-purple);
    transform: scale(1.05);
  }
  .line {
    margin-left: 1rem;
  }
  .links {
    text-align: right;
    padding-right: 0;
    margin: auto;
    padding: auto;
  }
  .dropdown {
    margin-left: auto;
    padding: auto;
  }
  .bar {
    margin-bottom: 0.25rem;
    margin-right: 2rem;
  }
  .line {
    margin-bottom: 0.25rem;
    margin-right: 2rem;
  }
  .about {
    margin-left: auto;
    margin-right: 0.8rem;
  }
  .menu-icon {
    color: var(--secondary-purple);
  }
  .main-info {
    background-image: url("TopBackground.svg");
    background-repeat: no-repeat;
    background-size: 100% auto;
    width: 100%;
    height: 100%;
    margin-top: 90px;
  }
  .community-links {
    margin-left: 4rem;
    padding-top: 1rem;
    position: absolute;
    z-index: 999;
  }
  .telegram-icon,
  .twitter-icon,
  .reddit-icon,
  .youtube-icon {
    display: inline;
    padding: 1rem;
    transition: all 0.5s ease-in-out;
    z-index: 999;
  }
  .icon:hover {
    color: var(--hover-purple);
    transform: scale(1.15);
  }
  .icon-youtube:hover {
    color: var(--text-purple);
    transform: scale(1.1);
  }
  .news-title {
    text-shadow: 2.25px 2.25px 3px var(--hover-purple);
    color: var(--text-purple);
    text-align: center;
    margin-top: 1rem;
    margin-bottom: 1rem;
    font-size: 2rem;
    margin-left: -10rem;
  }
  .newsGraph1,
  .newsGraph2,
  .newsGraph3 {
    margin-bottom: 1rem;
  }
  .youtube {
    z-index: 999;
    display: inline;
    position: absolute;
    text-align: center;
    color: var(--text-purple);
  }
  span.anchor {
    display: block;
    visibility: hidden;
    position: relative;
    padding-top: 20%;
  }
  .activeDelegators,
  .holders,
  .curatorCount,
  .subgraphCount {
    font-weight: 400;
    color: var(--text-purple);
    letter-spacing: 0.0375rem;
  }
  .hr {
    border: 3px solid var(--hr);
    border-radius: 5px;
    width: 90%;
    margin: auto;
    box-shadow: 0 0 10px 3px var(--hover-purple);
    position: relative;
    margin-top: 2.5rem;
  }
  .hr2 {
    border: 3px solid var(--hr);
    border-radius: 5px;
    width: 90%;
    margin: auto;
    box-shadow: 0 0 10px 3px var(--hover-purple);
    position: relative;
    margin-top: 1.5rem;
  }
  .video {
    position: absolute;
    box-shadow: #4D1D86 4px 8px 10px 0;
    left: 55%;
    top: 0%;
  }
  .g-ytsubscribe {
    position: absolute;
    font-family: Euclid Circular A;
    font-weight: 400;
    margin: 0;
    left: 78%;
    top: 41%;
  }
  .data-title {
    letter-spacing: 0.0375rem;
    font-weight: 400;
    color: var(--text-purple);
    font-size: 3.5rem;
    text-align: center;
    position: relative;
  }
  .chartsTitle {
    letter-spacing: 0.0375rem;
    font-weight: 400;
    color: var(--text-purple);
    font-size: 3.5rem;
    text-align: center;
  
    position: relative;
  }
  .chartsTitle2 {
    letter-spacing: 0.0375rem;
    font-weight: 400;
    color: var(--text-purple);
    font-size: 3.5rem;
    text-align: center;
   
  }
  .newsGraph {
    width: 100%;
    box-shadow: #4D1D86 4px 5px 10px 3px;
  }
  .chartImg {
    width: 100%;
    box-shadow: #4D1D86 6px 6px 10px 3px;
    margin: auto;
  }
  .center {
    margin: auto;
  }
  .line1 {
    margin-top: 5rem;
  }
  .chartImg1 {
    text-align: center;
    padding-top: -7rem;
    padding-bottom: 1rem;
    width: 100%;
    margin-top: -120px;
    margin-bottom: -120px;
  }
  .chartImg2,
  .chartImg3,
  .chartImg4,
  .chartImg5 {
    text-align: center;
    padding-top: 1rem;
    padding-bottom: 1rem;
    width: 100%;
    margin-top: -120px;
    margin-bottom: -120px;
  }
  .chartImg6 {
    text-align: center;
    padding-top: 1rem;
    width: 100%;
  
  }
  .reveal {
    position: relative;
    transform: translateY(50px);
    opacity: 0;
    transition: 0.75s all ease;
  }
  .reveal.active {
    transform: translateY(0);
    opacity: 1;
  }
  .email {
    color: var(--hover-purple);
    font-weight: 500;
  }
  .footerLink {
    color: var(--hover-purple);
  }
  .footerLink:link {
    color: var(--hover-purple);
    cursor: pointer;
  }
  .footerLink:visited {
    color: var(--hover-purple);
    cursor: pointer;
  }
  .footerLink.active,
  .footerLink:focus {
    border-bottom: 0rem solid var(--hover-purple);
    cursor: pointer;
  }
  .footerLink:hover {
    color: var(--text-purple);
    transform: scale(1.05);
    cursor: pointer;
  }
  .footer {
    margin-bottom: -1rem;
    padding: 1rem;
    font-weight: 700;
    margin-top: 1rem;
    font-size: 1.1rem;
    text-align: center;
    background-color: var(--primary-purple);
    color: var(--secondary-purple);
  }
  .tsparticles {
    position: absolute;
    height: 100%;
    width: 100%;
  }
  .rotate-position,
  .about-rotate-position,
  .phone,
  .about-phone,
  .message,
  .about-message, .home-message {
    display: none;
  }
  .adminHeader-text {
    font-weight: 400;
    font-size: 1.5rem;
    text-align: center;
    margin: auto;
    padding-top: 1rem;
    color: var(--text-purple);
  
    margin-top: 6rem;
  }
  .adminHeader-text2 {
    font-weight: 400;
    font-size: 1.5rem;
    text-align: center;
    margin: auto;
    color: var(--text-purple);
 
    margin-top: 2rem;
  }
  .adminSubheader-text {
    font-weight: 400;
    font-size: 1rem;
    margin-left: 3rem;
    color: var(--text-purple);
    
    margin-top: 1rem;
  }
  .adminSubheader-text2 {
    font-weight: 400;
    font-size: 1rem;
    margin-left: 3rem;
    margin-right: 3rem;
    color: var(--text-purple);
 
    margin-top: 1rem;
  }
  .note {
    text-decoration: underline;
  }

  .adminHr2 {
    border: 3px solid var(--hr);
    border-radius: 5px;
    width: 70%;
    margin: auto;
    box-shadow: 0 0 10px 3px var(--hover-purple);
    margin-top: 1rem;
    margin-left: 1.9rem;
  }

 .admin-video {
    position: absolute;
    left: 48%;
    top: 20%;
    width: 10%;
    height: 10%;
  }

  .adminChartsTitle {
    letter-spacing: 0.0375rem;
    font-weight: 400;
    color: var(--text-purple);
    font-size: 2rem;
    text-align: center;
    padding-top: 1rem;
    text-shadow: 2.5px 2.5px 4px var(--hover-purple);
    position: relative;
  }
  .adminChartsTitle2 {
    letter-spacing: 0.0375rem;
    font-weight: 400;
    color: var(--text-purple);
    font-size: 2rem;
    margin-top: 1rem;
    text-align: center;
    text-shadow: 2.5px 2.5px 4px var(--hover-purple);
  }
}
/* *************************************************************************
****************************************************************************
***************************************************************************/
/* *************************************************************************
****************************************************************************
***************************************************************************/
@media screen and (min-width: 927px) and (max-width: 928px) {
  .flashcards-title {
margin-top: 120px!important;
}
  .hide-line {
    color: transparent!important;
    display: block!important;
  }
  .Quiz-button {
    margin-bottom: 2rem;
  }
  .quiz-title {
    margin-top: -60px;
  }
  .quiz-button-position {
    padding-top: 2rem;
    margin: auto;
    text-align: center;
    margin-bottom: 2rem;
  }
  
  .final-exam-question {
      font-size: 1.3rem;
      text-align: center;
      color: #d8d2e4;
      /* remove for mobile */
      margin-top: 0rem;
      /* remove for mobile */
  }
  
  .final-exam-answer-form {
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin-top: 0rem;
    columns: 1!important;
    width: 100%!important;
  }
  
  .form-check-label {
    font-size: 20px;
    text-align: center;
    height: 110%;
  }
  .quiz-question {
    font-size: 1.3rem;
    padding-bottom: 2rem;
  text-align: center;
  width: 80%;
  margin: auto;
    text-align: center;
    color: #d8d2e4;
  }
  .quiz-question-container {
    margin-top: 20px;
    width: 100%;
  }

  .quiz-answer-form {
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin-top: 0rem;
    columns: 1!important;
    width: 100%!important;
  }

  .quiz-position {
    margin-top: -10px;
  }
  .mobile-portrait {
    display: none;
    }
  .adminHeader-text {
    font-weight: 400;
    font-size: 1.5rem;
    text-align: center;
    margin: auto;
    padding-top: 1rem;
    color: var(--text-purple);
    
    margin-top: 6rem;
  }
  .adminHeader-text2 {
    font-weight: 400;
    font-size: 1.5rem;
    text-align: center;
    margin: auto;
    color: var(--text-purple);
    
    margin-top: 2rem;
  }
  .adminSubheader-text {
    font-weight: 400;
    font-size: 1rem;
    margin-left: 3rem;
    color: var(--text-purple);
    
    margin-top: 1rem;
  }
  .adminSubheader-text2 {
    font-weight: 400;
    font-size: 1rem;
    margin-left: 3rem;
    margin-right: 3rem;
    color: var(--text-purple);

    margin-top: 1rem;
  }
  .monitor-line-carousel {
    display: none;
  }
  
  .monitor-bar-carousel {
   display: none;
  }
  
  .monitor-other-carousel {
    display: none;
  }
  
  .monitor-other-charts-position {
    display: none;
  }
  .mobile-bar-carousel {
    width: 80%;
  height: 500px;
  margin: auto auto;
  padding-top: -1rem;
  padding-bottom: -5rem;
  margin-bottom: -6rem;
  overflow: hidden;
  }
  .mobile-line-carousel {
    width: 80%;
    height: 500px;
    margin: auto auto;
    padding-top: 0rem;
    overflow: hidden;
  }

  .mobile-line-pos {
padding-top: 3rem;
  }
  .more-data-hr {
    margin-top: 1rem!important;
  }
  .more-data-title {
    margin-bottom: -12rem!important;
  }
  .mobile-other-carousel {
    width: 110%;
    height: 550px;
    margin: auto auto;
    margin-left: -2.5rem;
    padding-top: 1rem!important;
    overflow: hidden;
  }
  .map {
    width: 100%;
    margin-left: 5%;
    box-shadow: #4D1D86 4px 5px 10px 3px;
  }
  .holders-pos {
    margin-top: -8rem;
    margin-left: 1%;
    padding-bottom: 2rem;
  }
  .css-1fzpoyk {
    width: 100%;
    padding-top: 1rem;
  }

  .extra-data {
    margin-left: 2rem;
    margin-right: 2rem;
    font-size: 1.25rem;
    text-align: center;
    color: var(--text-purple);
  }

  .extra-data2 {
    
    font-size: 1.25rem;
    text-align: center;
    color: var(--text-purple);
  }
  .data-title {
    letter-spacing: 0.0375rem;
    font-weight: 400;
    color: var(--text-purple);
    font-size: 2rem;
    text-align: center;
    position: relative;
  }
  .chartsTitle {
    letter-spacing: 0.0375rem;
    font-weight: 400;
    color: var(--text-purple);
    font-size: 2rem;
    text-align: center;
    position: relative;
    margin-bottom: -13rem;
  }
  .chartsTitle2 {
    letter-spacing: 0.0375rem;
    font-weight: 400;
    color: var(--text-purple);
    font-size: 2rem;
    text-align: center;
    margin-bottom: -10rem;
  }
  .hr {
    border: 3px solid var(--hr);
    border-radius: 5px;
    width: 90%;
    margin: auto;
    box-shadow: 0 0 10px 3px var(--hover-purple);
    position: relative;
    margin-top: 3rem;
  }
  .hr2 {
    border: 3px solid var(--hr);
    border-radius: 5px;
    width: 90%;
    margin: auto;
    box-shadow: 0 0 10px 3px var(--hover-purple);
    position: relative;
    margin-top: 3rem;
  }
 
  .move-up {
    margin-top: -45rem;
  }
.hide-me {
  display: none;
}
  .css-1qzevvg img {
    height: 100%;
    margin-top: -10rem;
    text-align: center;
    margin-left: -1rem;
    z-index: 999999;
    position: relative;
}

/* above me */
  .no-show-monitor {
    display: none!important;
  }
  .no-monitor {
    display: none!important;
  }
  /* dune start */
.GRTDailyTransactionsPosition {
  margin-bottom: 0.5rem!important;
  margin-left: -15px!important;
 }
 .HoldersGraph {
  background-image: linear-gradient(90deg, #1B0D33, #D8D2E4, #D8D2E4,#D8D2E4, #D8D2E4,#D8D2E4,#D8D2E4,#D8D2E4,#D8D2E4,#D8D2E4,#D8D2E4,#D8D2E4,#D8D2E4,#D8D2E4,#D8D2E4,#D8D2E4,#D8D2E4,#D8D2E4,#1B0D33);
  margin-bottom: -36px!important;
  margin-left: -10px!important;
  padding-left: 20px!important;
  padding-right: 20px!important;
  height: 80px!important;
  width: 100%!important;
}
.HoldersGraphBottom {
  background-image: linear-gradient(90deg, #1B0D33, #D8D2E4,#D8D2E4,#D8D2E4,#D8D2E4,#D8D2E4,#D8D2E4,#D8D2E4,#D8D2E4,#D8D2E4,#D8D2E4,#D8D2E4,#D8D2E4,#D8D2E4,#D8D2E4,#D8D2E4,#D8D2E4,#D8D2E4, #1B0D33);
  padding-left: 20px!important;
  margin-left: -10px!important;
  padding-right: 20px!important;
  height: 80px!important;
  width: 100%!important;
}
/* dune end */
  .chatbot-footer-bottom {
    margin-bottom: -80px!important;
   }
  .chatbot-link-logo{
    margin-top: 13rem;
    z-index: 9998;
    position: fixed;
    max-width: 6rem;
    background: white;
    -moz-border-radius: 50px;
    -webkit-border-radius: 50px;
    border-radius: 50px;
    padding: -1rem;
   right: 2%;
    border: 3px var(--secondary-purple) solid;
    box-shadow: 0 0 0 var(--hover-purple);
    animation: pulse 2s infinite;
  }
  .advocate-link-logo{
    margin-top: 21rem;
    z-index: 9998;
    position: fixed;
    max-width: 6rem;
    background: white;
    -moz-border-radius: 50px;
    -webkit-border-radius: 50px;
    border-radius: 50px;
    padding: -1rem;
   right: 2%;
    border: 3px var(--secondary-purple) solid;
    box-shadow: 0 0 0 var(--hover-purple);
    animation: pulse 2s infinite;
  }
  @-webkit-keyframes pulse {
    0% {
      -webkit-box-shadow: 0 0 0 0  var(--text-purple);
    }
    70% {
        -webkit-box-shadow: 0 0 0 15px rgba(244,157,22, 0);
    }
    100% {
        -webkit-box-shadow: 0 0 0 0 rgba(244,157,22, 0);
    }
  }
  @keyframes pulse {
    0% {
      -moz-box-shadow: 0 0 0 0  var(--text-purple);
      box-shadow: 0 0 0 0  var(--text-purple);
    }
    70% {
        -moz-box-shadow: 0 0 0 15px rgba(244,157,22, 0);
        box-shadow: 0 0 0 15px rgba(244,157,22, 0);
    }
    100% {
        -moz-box-shadow: 0 0 0 0 rgba(244,157,22, 0);
        box-shadow: 0 0 0 0 rgba(244,157,22, 0);
    }
  }
  
  .chatbot-link-export{
    margin-top: 18rem;
    z-index: 9998;
    position: fixed;
    max-width: 6rem;
    background: white;
    -moz-border-radius: 50px;
    -webkit-border-radius: 50px;
    border-radius: 50px;
    padding: -1rem;
 right: 2%;
    border: 3px var(--secondary-purple) solid;
    box-shadow: 0 0 0 var(--hover-purple);
    animation: pulse 2s infinite;
  }
  
/* Chatbot */
.feedback-text {
  margin-top: 2rem;
}
.feedback-button {
  border: 2px var(--primary-purple) solid!important;
  background-color: var(--text-purple)!important;
  color: var(--primary-purple)!important;
  padding: auto!important;
  margin: auto!important;
}

  .feedback-button:link {
    cursor: pointer;
  }
  .feedback-button:visited {
    cursor: pointer;
  }
  .feedback-button.active,
  .feedback-button:focus {
    cursor: pointer;
  }
  .feedback-button:hover {
    background-color: var(--background)!important;
    color: var(--text-purple)!important;
    transform: scale(1.015);
    cursor: pointer;
  }
  .feedback-button-text {
    font-size: 1.5rem;
  }

.react-chatbot-kit-chat-inner-container {
  background-color: var(--text-purple)!important;
}

.react-chatbot-kit-chat-header {
  font-size: 1.1rem!important;
  color: var(--text-purple)!important;
  background-color: var(--primary-purple)!important;
  padding: 0rem!important;
}

.react-chatbot-kit-chat-input-container {
  background-color:var(--secondary-purple)!important;
  border: 1px solid var(--primary-purple)!important;
}

.react-chatbot-kit-chat-btn-send {
  background-color:var(--primary-purple)!important;
}

.react-chatbot-kit-chat-btn-send-icon:hover {
transform: scale(1.25);
}

.react-chatbot-kit-chat-bot-message-container {
  width: 100%;
}

.react-chatbot-kit-chat-bot-avatar-container {
}

.react-chatbot-kit-chat-bot-avatar-icon {
}

.react-chatbot-kit-chat-bot-avatar-letter {
  display: none;
}
.react-chatbot-kit-chat-container {
  width: 98%!important;
  text-align: center;
  margin: auto;
}

.react-chatbot-kit-chat-bot-avatar-container {
  width: 3.5rem!important;
  height: 3.5rem!important;
  background-color: white!important;
  border: 2px solid var(--primary-purple);
  background-image: url('./GRTDataHubChatBot.png')!important;
  background-repeat: no-repeat!important;
  background-size: 3rem;
  background-position-x: 50%;
  background-position-y: 20%;
}

.react-chatbot-kit-chat-bot-message {
  text-align: left!important;
  width: 100%!important;
  color: var(--text-purple)!important;
  background-color: #0C0B23!important;
  font-size: 1rem!important;
}

.react-chatbot-kit-chat-bot-message-arrow {
  color: #0C0B23!important;
  border-right: 8px solid #0C0B23!important;
}
  .indexer-table-header {
    text-align: center;
    margin-left: 1rem;
  }
  .indexer-table {
    text-align: center;
  }
  .chatbot-data {
    display: inline!important;
  }
  .telegramActiveDelegationCount {
    max-width: 92%;
    margin: auto; 
    text-align: center;
    margin-top: 0.5%;
    }
    .top-telegram {
      margin-top: 0.25rem;
    }
    .chatbot-top {
      margin-top: 5rem;
    }
    .chatbot-page-container {
      background: var(--background);
      color: var(--text-purple);
      width: 100%;
      margin: auto;
      margin-top: 150px;
      padding-left: 10px;
      padding-right: 10px;
      text-align: center;
    }
    .chatbot-text-container {
      background: whitesmoke;
      border-radius: 1rem;
      color: var(--secondary-purple);
      margin: auto!important;
      padding-bottom: 10px;
      padding-top: 10px;
      text-align: center!important;
    }
    .chatbot-page-title {
      font-size: 1.25rem;
      font-weight: 600;
      color: var(--secondary-purple);
      padding-bottom: 0.25rem;
      padding-left: 5px;
      padding-right: 5px;
    }
    .chatbot-page-subtitle {
      font-size: 1.75rem;
      padding-left: 5px;
      padding-right: 5px;
    }
    .chatbot-page-text {
      font-size: 1.25rem;
      margin-bottom: 1rem;
      margin-top: 1rem;
      padding-left: 10px;
      padding-right: 10px;
    }
    /* chatbot end */
  .dataHub-logos {
    max-width: 6rem !important;
    max-height: 35rem !important;
    position: absolute;
    top: 0;
    margin-top: 1rem;
  }
  .mobile-config {
    display: none!important;
  }
  .offcanvas-button, .offcanvas-menu {
    display: none!important;
  }
  .navbar-toggler {
    display: none;
  }
  .pages {
    margin-bottom: 0.1rem;
    margin-right: 2rem;
}
.indexer-title {
  text-align: center!important;
  font-weight: 400;
  font-size: 1.75rem;
  padding-top: 2rem;
  color: var(--text-purple);
  
  margin-top: 6rem;
}
.indexer-subtitle {
  text-align: center!important;
  font-weight: 400;
  font-size: 1.5rem;
  color: var(--text-purple);
  
  margin-top: 1rem;
  margin-right: 2rem;
  margin-left: 2rem;
}
.indexer {
  z-index: 999;
  position: absolute;
  left: 0%;
  top: 50%;
}

.curator {
  z-index: 999;
  position: absolute;
  text-align: center!important;
  top: 50%;
}

.map, .tokens, .rewards, .uncollected-rewards {
  margin-top: 2rem;
  width: 90%;
  height: 14rem;
  margin-left: 5%;
  box-shadow: #4D1D86 4px 5px 10px 3px;
}

.c-map {
  margin-top: 2rem;
  width: 100%;
  height: 30rem;
  margin-left: 5%;
  box-shadow: #4D1D86 4px 5px 10px 3px;
}

.d-map {
  margin-top: -3rem;
  margin-left: 1rem;
  width: 1240px;
  height: 3800px;
  text-align: center!important;
  box-shadow: #4D1D86 4px 5px 10px 3px;
}

.rewards-per-hour {
  margin-top: 2rem;
  padding-bottom: 2rem;
  width: 90%;
  height: 14rem;
  margin-left: 5%;
  box-shadow: #4D1D86 4px 5px 10px 3px;
}

.indexers {
  padding-left: 1rem;
  padding-left: 1rem;
  color: grey;
  font-size:1.25rem;
  margin-right: 1rem;
}
.indexers:link{
  color: grey;
  cursor: pointer;
  font-size:1.25rem;
  margin-right: 1rem;
}
.indexers:visited {
  color: grey;
  cursor: pointer;
  font-size:1.25rem;
  margin-right: 1rem;
}
.indexers.active,
.indexers:focus {
  border-bottom: 0rem solid var(--hover-purple);
  cursor: pointer;
  font-size:1.25rem;
  margin-right: 1rem;
}
.indexers:hover {
  color: var(--text-purple);
  transform: scale(1.05);
  cursor: pointer;
  font-size:1.25rem;
  margin-right: 1rem;
}
.indexer-link {
  color: white;
}
.indexer-link:link {
  color: white;
  cursor: pointer;
}
.indexer-link:visited {
  color: var(--text-purple);
  cursor: pointer;
}
.indexer-link.active,
.indexer-link:focus {
  border-bottom: 0rem solid var(--text-purple);
  cursor: pointer;
}
.indexer-link:hover {
  color: var(--text-purple);
  transform: scale(1.05);
  cursor: pointer;
}
  .cookie-page-text {
    padding-top: 1rem;
    font-size: 1.25rem;
    margin-bottom: 1rem;
    padding-left: 20px;
    padding-right: 20px;
  }
  .cookies-video {
    padding-top: 2rem;
  }
  .cookie-button {
    z-index: 9999;
    position: absolute;
  }

  body > div:nth-child(1) > button {
    z-index: 10000;
    font-size: 3rem;
    position: absolute;
    padding: auto;
    margin-top: -95px;
    width: 45px;
    height: 45px;
    margin-left: 85%;
    background-color: transparent;
    border: none;
    color: var(--text-purple);
  }
  .extra-data {
    margin-top: 2rem;
    font-size: 1.25rem;
    text-align: center;
    color: var(--text-purple);
   
  }
  .extra-data2 {
    font-size: 1.25rem;
    text-align: center;
    color: var(--text-purple);
   
  }
  .data-link {
    color: grey;
  }
  .data-link:link {
    color: grey;
    cursor: pointer;
  }
  .data-link:visited {
    color: grey;
    cursor: pointer;
  }
  .data-link.active,
  .data-link:focus {
    border-bottom: 0rem solid white;
    cursor: pointer;
  }
  .data-link:hover {
    color: white;
    transform: scale(1.05);
    cursor: pointer;
  }
  .question-bank-container {
    background: var(--background);
    color: var(--text-purple);
    width: 100%;
    margin: auto;
    margin-top: 150px;
    margin-bottom: 50px;
    padding-left: 50px;
    padding-right: 50px;
    text-align: center;
  }
  .about-page-container {
    background: var(--background);
    color: var(--text-purple);
    width: 100%;
    margin: auto;
    margin-top: 150px;
    margin-bottom: 50px;
    padding-left: 50px;
    padding-right: 50px;
    text-align: center;
  }
  .QB-text-container {
    background: white;
    border-radius: 1rem;
    padding: 30px;
    color: var(--secondary-purple);
  }
  .text-container {
    background: white;
    border-radius: 1rem;
    padding: 30px;
    color: var(--secondary-purple);
  }
  .QB-about-hr {
    border: 1.5px solid var(--background);
    color: var(--background);
    border-radius: 5px;
    width: 35%;
    margin: auto;
    margin-bottom: 1rem;
    box-shadow: 0 0 5px 1px var(--background);
  }
  .QB-about-page-title {
    font-size: 2rem;
    font-weight: 600;
    color: var(--secondary-purple);
    padding-bottom: 2rem;
  }
  .QB-about-page-subtitle {
    font-size: 1.75rem;
  }
  .QB-about-page-text {
    font-size: 1.25rem;
    margin-bottom: 1rem;
  }
  .about-hr {
    border: 1.5px solid var(--background);
    color: var(--background);
    border-radius: 5px;
    width: 35%;
    margin: auto;
    margin-bottom: 1rem;
    box-shadow: 0 0 5px 1px var(--background);
  }
  .about-page-title {
    font-size: 2rem;
    font-weight: 600;
    color: var(--secondary-purple);
    padding-bottom: 2rem;
  }
  .about-page-subtitle {
    font-size: 1.75rem;
  }
  .about-page-text {
    font-size: 1.25rem;
    margin-bottom: 1rem;
  }
  .graphiql-link {
    color: var(--secondary-purple);
  }
  .graphiql-link:link {
    color: var(--secondary-purple);
    cursor: pointer;
  }
  .graphiql-link:visited {
    color: var(--secondary-purple);
    cursor: pointer;
  }
  .graphiql-link.active,
  .graphiql-link:focus {
    border-bottom: 0rem solid var(--hover-purple);
    cursor: pointer;
  }
  .graphiql-link:hover {
    color: var(--primary-purple);
    transform: scale(1.05);
    cursor: pointer;
  }
  .cookies-policy-container {
    background: white;
    width: 90%;
    padding-bottom: 1rem;
    margin: auto;
    border-radius: 2rem;
    margin-top: 150px;
    margin-bottom: 50px;
  }
  .termly-cookie-preference-button {
   display: none;
  }

  .privacy-policy-container {
    background: white;
    width: 90%;
    padding-bottom: 1rem;
    margin: auto;
    border-radius: 2rem;
    margin-top: 150px;
    margin-bottom: 50px;
  }
  .privacy-policy {
    margin-top: 110px;
    font-weight: 700;
    font-size: 1.1rem;
    text-align: center;
    background-color: var(--background);
    color: var(--secondary-purple);
  }
  .privacy-policy-link,
  .about {
    padding-left: 1rem;
    padding-left: 1rem;
    color: grey;
  }
  .privacy-policy-link:link,
  .about:link {
    color: grey;
    cursor: pointer;
  }
  .privacy-policy-link:visited,
  .about:visited {
    color: grey;
    cursor: pointer;
  }
  .privacy-policy-link.active,
  .privacy-policy-link:focus,
  .about.active,
  .about:focus {
    border-bottom: 0rem solid var(--hover-purple);
    cursor: pointer;
  }
  .privacy-policy-link:hover,
  .about:hover {
    color: var(--text-purple);
    transform: scale(1.05);
    cursor: pointer;
  }
  .subscribeNote {
    margin-left: -15rem;
    width: 30rem;
    font-size: 0.75rem;
    color: white;
  }
  /* loader new start  */
  .loader:empty {
    position: absolute;
    top: calc(50% + 15.5rem);
    left: calc(50% + 2em);
    width: 4em;
    height: 4em;
    border: 0.35em solid white;
    border-left: 0.35em solid #10034B;
    border-radius: 50%;
    animation: load8 0.8s infinite linear;
  }
  .loadingTitle {
    position: absolute;
    top: 27rem;
    left: calc(50% - 3em);
    font-size: 2rem;
    color: #10034B;
    -webkit-box-reflect:below 0px linear-gradient(transparent, #0005);
  }
  .loaderLogo {
    position: absolute;
    top: 14rem;
    left: calc(50% - 8em);
    width: 15em;
    height: 12em;
  }
  @keyframes load8 {
    0% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(360deg);
    }
  }
  /* loader new end  */
  .C {
    font-size: 1rem;
  }
  .logo {
    width:  6.75rem;
    height: 5.75rem;
    margin-left: 2rem;
    margin-top: -0.5rem;
  }
  .data-logo {
    max-width: 1rem !important;
    height: 1rem !important;
    position: absolute;
  }
  .brand-text {
    color: var(--text-purple);
    display: inline;
    margin-left: 0rem;
    letter-spacing: 0.0375rem;
    font-size: 1.25rem;
  }
  .header-text {
    font-weight: 400;
    font-size: 1.75rem;
    margin-left: 5rem;
    margin-top: 105px;
    color: var(--text-purple);
  
  }
  .header-sub-text {
    font-weight: 400;
    font-size: 1.25rem;
    text-align: center!important;
    margin: auto!important;
    width: 90%;
    color: var(--text-purple);

    display: block;
  }
  nav a.TGN {
    display: none;
  }
  .navbar-nav > li > a.TGN:link {
    display: none;
  }
  .navbar-nav > li > a.TGN:visited {
    display: none;
  }
  .navbar-nav > li > a.TGN.active,
  .navbar-nav > li > a.TGN:focus {
    display: none;
  }
  .navbar-nav > li > a.TGN:hover {
    display: none;
  }
  nav a.nav-link {
    font-weight: 400;
    font-size: 1.25rem;
    color: var(--text-purple);
  }
  .navbar-nav > li > a {
    border-bottom: 0.01875rem solid var(--transparent);
    padding: 0;
  }
  .navbar-nav > li > a:link {
    color: var(--hover-purple);
  }
  .navbar-nav > li > a:visited {
    color: var(--text-purple);
  }
  .navbar-nav > li > a.active,
  .navbar-nav > li > a:focus {
    border-bottom: 0.01875rem solid var(--hover-purple);
    padding: 0;
    transition: all 0.5s ease-in-out;
  }
  .navbar-nav > li > a:hover {
    border-bottom: 0.01875rem solid var(--hover-purple);
    padding: 0;
    color: var(--hover-purple);
    transform: scale(1.05);
  }
  .line {
    margin-left: 1rem;
  }
  .links {
    text-align: right;
    padding-right: 0;
    margin: auto;
    padding: auto;
  }
  .dropdown {
    margin-left: auto;
    padding: auto;
  }
  .bar {
    margin-bottom: 0.25rem;
    margin-right: 2rem;
  }
  .line {
    margin-bottom: 0.25rem;
    margin-right: 2rem;
  }
  .about {
    margin-left: auto;
    margin-right: 0.8rem;
  }
  .menu-icon {
    color: var(--secondary-purple);
  }
  .main-info {
    background-image: url("TopBackground.svg");
    background-repeat: no-repeat;
    background-size: 100% auto;
    width: 100%;
    height: 100%;
    margin-top: 90px;
  }
  .community-links {
    margin-left: 4rem;
    padding-top: 1rem;
    position: absolute;
    z-index: 999;
  }
  .telegram-icon,
  .twitter-icon,
  .reddit-icon,
  .youtube-icon {
    display: inline;
    padding: 1rem;
    transition: all 0.5s ease-in-out;
    z-index: 999;
  }
  .icon:hover {
    color: var(--hover-purple);
    transform: scale(1.15);
  }
  .icon-youtube:hover {
    color: var(--text-purple);
    transform: scale(1.1);
  }
  .news-title {
    text-shadow: 2.25px 2.25px 3px var(--hover-purple);
    color: var(--text-purple);
    text-align: center;
    margin-top: 1rem;
    margin-bottom: 1rem;
    font-size: 2rem;
    margin-left: -10rem;
  }
  .newsGraph1,
  .newsGraph2,
  .newsGraph3 {
    margin-bottom: 1rem;
  }
  .youtube {
    z-index: 999;
    display: inline;
    position: absolute;
    text-align: center;
    color: var(--text-purple);
  }
  span.anchor {
    display: block;
    visibility: hidden;
    position: relative;
    padding-top: 20%;
  }
  .activeDelegators,
  .holders,
  .curatorCount,
  .subgraphCount {
    font-weight: 400;
    color: var(--text-purple);
    letter-spacing: 0.0375rem;
  }
  .hr {
    border: 3px solid var(--hr);
    border-radius: 5px;
    width: 90%;
    margin: auto;
    box-shadow: 0 0 10px 3px var(--hover-purple);
    position: relative;
    margin-top: 2.5rem;
  }
  .hr2 {
    border: 3px solid var(--hr);
    border-radius: 5px;
    width: 90%;
    margin: auto;
    box-shadow: 0 0 10px 3px var(--hover-purple);
    position: relative;
    margin-top: 1.5rem;
  }
  .video {
    position: absolute;
    box-shadow: #4D1D86 4px 8px 10px 0;
    left: 55%;
    top: 0%;
  }
  .g-ytsubscribe {
    position: absolute;
    font-family: Euclid Circular A;
    font-weight: 400;
    margin: 0;
    left: 78%;
    top: 41%;
  }
  .data-title {
    letter-spacing: 0.0375rem;
    font-weight: 400;
    color: var(--text-purple);
    font-size: 3.5rem;
    text-align: center;
    position: relative;
  }
  .chartsTitle {
    letter-spacing: 0.0375rem;
    font-weight: 400;
    color: var(--text-purple);
    font-size: 3.5rem;
    text-align: center;
  
    position: relative;
  }
  .chartsTitle2 {
    letter-spacing: 0.0375rem;
    font-weight: 400;
    color: var(--text-purple);
    font-size: 3.5rem;
    text-align: center;
   
  }
  .newsGraph {
    width: 100%;
    box-shadow: #4D1D86 4px 5px 10px 3px;
  }
  .chartImg {
    width: 100%;
    box-shadow: #4D1D86 6px 6px 10px 3px;
    margin: auto;
  }
  .center {
    margin: auto;
  }
  .line1 {
    margin-top: 5rem;
  }
  .chartImg1 {
    text-align: center;
    padding-top: -7rem;
    padding-bottom: 1rem;
    width: 100%;
    margin-top: -120px;
    margin-bottom: -120px;
  }
  .chartImg2,
  .chartImg3,
  .chartImg4,
  .chartImg5 {
    text-align: center;
    padding-top: 1rem;
    padding-bottom: 1rem;
    width: 100%;
    margin-top: -120px;
    margin-bottom: -120px;
  }
  .chartImg6 {
    text-align: center;
    padding-top: 1rem;
    width: 100%;
  
  }
  .reveal {
    position: relative;
    transform: translateY(50px);
    opacity: 0;
    transition: 0.75s all ease;
  }
  .reveal.active {
    transform: translateY(0);
    opacity: 1;
  }
  .email {
    color: var(--hover-purple);
    font-weight: 500;
  }
  .footerLink {
    color: var(--hover-purple);
  }
  .footerLink:link {
    color: var(--hover-purple);
    cursor: pointer;
  }
  .footerLink:visited {
    color: var(--hover-purple);
    cursor: pointer;
  }
  .footerLink.active,
  .footerLink:focus {
    border-bottom: 0rem solid var(--hover-purple);
    cursor: pointer;
  }
  .footerLink:hover {
    color: var(--text-purple);
    transform: scale(1.05);
    cursor: pointer;
  }
  .footer {
    margin-bottom: -1rem;
    padding: 1rem;
    font-weight: 700;
    margin-top: 1rem;
    font-size: 1.1rem;
    text-align: center;
    background-color: var(--primary-purple);
    color: var(--secondary-purple);
  }
  .tsparticles {
    position: absolute;
    height: 100%;
    width: 100%;
  }
  .rotate-position,
  .about-rotate-position,
  .phone,
  .about-phone,
  .message,
  .about-message, .home-message {
    display: none;
  }
  .adminHeader-text {
    font-weight: 400;
    font-size: 1.5rem;
    text-align: center;
    margin: auto;
    padding-top: 1rem;
    color: var(--text-purple);
  
    margin-top: 6rem;
  }
  .adminHeader-text2 {
    font-weight: 400;
    font-size: 1.5rem;
    text-align: center;
    margin: auto;
    color: var(--text-purple);
 
    margin-top: 2rem;
  }
  .adminSubheader-text {
    font-weight: 400;
    font-size: 1rem;
    margin-left: 3rem;
    color: var(--text-purple);
    
    margin-top: 1rem;
  }
  .adminSubheader-text2 {
    font-weight: 400;
    font-size: 1rem;
    margin-left: 3rem;
    margin-right: 3rem;
    color: var(--text-purple);
 
    margin-top: 1rem;
  }
  .note {
    text-decoration: underline;
  }

  .adminHr2 {
    border: 3px solid var(--hr);
    border-radius: 5px;
    width: 70%;
    margin: auto;
    box-shadow: 0 0 10px 3px var(--hover-purple);
    margin-top: 1rem;
    margin-left: 1.9rem;
  }

 .admin-video {
    position: absolute;
    left: 48%;
    top: 20%;
    width: 10%;
    height: 10%;
  }

  .adminChartsTitle {
    letter-spacing: 0.0375rem;
    font-weight: 400;
    color: var(--text-purple);
    font-size: 2rem;
    text-align: center;
    padding-top: 1rem;
    text-shadow: 2.5px 2.5px 4px var(--hover-purple);
    position: relative;
  }
  .adminChartsTitle2 {
    letter-spacing: 0.0375rem;
    font-weight: 400;
    color: var(--text-purple);
    font-size: 2rem;
    margin-top: 1rem;
    text-align: center;
    text-shadow: 2.5px 2.5px 4px var(--hover-purple);
  }
}
/* *************************************************************************
****************************************************************************
***************************************************************************/
/* *************************************************************************
****************************************************************************
***************************************************************************/
@media screen and (min-width: 925px) and (max-width: 926px) {
  .flashcards-title {
margin-top: 120px!important;
}
  .hide-line {
    color: transparent!important;
    display: block!important;
  }
  .Quiz-button {
    margin-bottom: 2rem;
  }
  .quiz-title {
    margin-top: -60px;
  }
  .quiz-button-position {
    padding-top: 2rem;
    margin: auto;
    text-align: center;
    margin-bottom: 2rem;
  }
  
  .final-exam-question {
      font-size: 1.3rem;
      text-align: center;
      color: #d8d2e4;
      /* remove for mobile */
      margin-top: 0rem;
      /* remove for mobile */
  }
  
  .final-exam-answer-form {
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin-top: 0rem;
    columns: 1!important;
    width: 100%!important;
  }
  
  .form-check-label {
    font-size: 20px;
    text-align: center;
    height: 110%;
  }
  .quiz-question {
    font-size: 1.3rem;
    padding-bottom: 2rem;
  text-align: center;
  width: 80%;
  margin: auto;
    text-align: center;
    color: #d8d2e4;
  }
  .quiz-question-container {
    margin-top: 20px;
    width: 100%;
  }

  .quiz-answer-form {
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin-top: 0rem;
    columns: 1!important;
    width: 100%!important;
  }

  .quiz-position {
    margin-top: -10px;
  }
  .mobile-portrait {
    display: none;
    }
  .adminHeader-text {
    font-weight: 400;
    font-size: 1.5rem;
    text-align: center;
    margin: auto;
    padding-top: 1rem;
    color: var(--text-purple);
    
    margin-top: 6rem;
  }
  .adminHeader-text2 {
    font-weight: 400;
    font-size: 1.5rem;
    text-align: center;
    margin: auto;
    color: var(--text-purple);
    
    margin-top: 2rem;
  }
  .adminSubheader-text {
    font-weight: 400;
    font-size: 1rem;
    margin-left: 3rem;
    color: var(--text-purple);
    
    margin-top: 1rem;
  }
  .adminSubheader-text2 {
    font-weight: 400;
    font-size: 1rem;
    margin-left: 3rem;
    margin-right: 3rem;
    color: var(--text-purple);

    margin-top: 1rem;
  }
  .monitor-line-carousel {
    display: none;
  }
  
  .monitor-bar-carousel {
   display: none;
  }
  
  .monitor-other-carousel {
    display: none;
  }
  
  .monitor-other-charts-position {
    display: none;
  }
  .mobile-bar-carousel {
    width: 80%;
  height: 500px;
  margin: auto auto;
  padding-top: -1rem;
  padding-bottom: -5rem;
  margin-bottom: -6rem;
  overflow: hidden;
  }
  .mobile-line-carousel {
    width: 80%;
    height: 500px;
    margin: auto auto;
    padding-top: 0rem;
    overflow: hidden;
  }

  .mobile-line-pos {
padding-top: 3rem;
  }
  .more-data-hr {
    margin-top: 1rem!important;
  }
  .more-data-title {
    margin-bottom: -12rem!important;
  }
  .mobile-other-carousel {
    width: 110%;
    height: 550px;
    margin: auto auto;
    margin-left: -2.5rem;
    padding-top: 1rem!important;
    overflow: hidden;
  }
  .map {
    width: 100%;
    margin-left: 5%;
    box-shadow: #4D1D86 4px 5px 10px 3px;
  }
  .holders-pos {
    margin-top: -8rem;
    margin-left: 1%;
    padding-bottom: 2rem;
  }
  .css-1fzpoyk {
    width: 100%;
    padding-top: 1rem;
  }

  .extra-data {
    margin-left: 2rem;
    margin-right: 2rem;
    font-size: 1.25rem;
    text-align: center;
    color: var(--text-purple);
  }

  .extra-data2 {
    
    font-size: 1.25rem;
    text-align: center;
    color: var(--text-purple);
  }
  .data-title {
    letter-spacing: 0.0375rem;
    font-weight: 400;
    color: var(--text-purple);
    font-size: 2rem;
    text-align: center;
    position: relative;
  }
  .chartsTitle {
    letter-spacing: 0.0375rem;
    font-weight: 400;
    color: var(--text-purple);
    font-size: 2rem;
    text-align: center;
    position: relative;
    margin-bottom: -13rem;
  }
  .chartsTitle2 {
    letter-spacing: 0.0375rem;
    font-weight: 400;
    color: var(--text-purple);
    font-size: 2rem;
    text-align: center;
    margin-bottom: -10rem;
  }
  .hr {
    border: 3px solid var(--hr);
    border-radius: 5px;
    width: 90%;
    margin: auto;
    box-shadow: 0 0 10px 3px var(--hover-purple);
    position: relative;
    margin-top: 3rem;
  }
  .hr2 {
    border: 3px solid var(--hr);
    border-radius: 5px;
    width: 90%;
    margin: auto;
    box-shadow: 0 0 10px 3px var(--hover-purple);
    position: relative;
    margin-top: 3rem;
  }
 
  .move-up {
    margin-top: -45rem;
  }
.hide-me {
  display: none;
}
  .css-1qzevvg img {
    height: 100%;
    margin-top: -10rem;
    text-align: center;
    margin-left: -1rem;
    z-index: 999999;
    position: relative;
}

/* above me */

   /* chatbot height start */
.react-chatbot-kit-chat-inner-container {
  background-color: var(--text-purple)!important;
  height: 290px!important;
}
.react-chatbot-kit-chat-message-container {
  height: 250px!important;
}
  /* chatbot height end */
 /* dune start */
 .GRTDailyTransactionsPosition {
  margin-left: -15px!important;
 }
 .HoldersGraph {
  background-image: linear-gradient(90deg, #1B0D33, #D8D2E4, #D8D2E4,#D8D2E4, #D8D2E4,#D8D2E4,#D8D2E4,#D8D2E4,#D8D2E4,#D8D2E4,#D8D2E4,#D8D2E4,#D8D2E4,#D8D2E4,#D8D2E4,#D8D2E4,#D8D2E4,#D8D2E4,#1B0D33);
  margin-bottom: -36px!important;
  margin-left: -10px!important;
  padding-left: 20px!important;
  padding-right: 20px!important;
  height: 80px!important;
  width: 100%!important;
}
.HoldersGraphBottom {
  background-image: linear-gradient(90deg, #1B0D33, #D8D2E4,#D8D2E4,#D8D2E4,#D8D2E4,#D8D2E4,#D8D2E4,#D8D2E4,#D8D2E4,#D8D2E4,#D8D2E4,#D8D2E4,#D8D2E4,#D8D2E4,#D8D2E4,#D8D2E4,#D8D2E4,#D8D2E4, #1B0D33);
  padding-left: 20px!important;
  margin-left: -10px!important;
  padding-right: 20px!important;
  height: 80px!important;
  width: 100%!important;
}
/* dune end */
  /* Navbar */
.navbar {
  background-color: var(--primary-purple);
  color: var(--text-purple);
  z-index: 9999;
  position: absolute;
}
.new-link {
  margin-right: 2rem;
  margin-top: -0.25rem;
}
.mobile-nav-link {
  padding-right: 2rem!important;
}
.mobile-nav-pages {
  color: var(--hover-purple)!important;
  font-size: 1.5rem!important;
}
.mobile-nav-dropdown {
  margin-right: 2rem!important;
}
.mobile-nav-pages:link {
  cursor: pointer;
}
.mobile-nav-pages:visited {
  cursor: pointer;
}
.mobile-nav-pages.active,
.mobile-nav-pages:focus {
  cursor: pointer;
  color: var(--text-purple)!important;
}
.mobile-nav-pages:hover {
  color: var(--text-purple)!important;
  cursor: pointer;
}

.offcanvas {
  width: 40%!important;
  background-color: var(--background)!important;
}
.offcanvas-body {
  width: 100%!important;
  margin-top: -3rem!important;
  opacity: 0.9;
}
.offcanvas-button {
  margin-right: 2rem;
  border: none!important;
}
.btn-close {
  z-index: 10000!important;
  margin-right: 3.25rem;
  margin-top: 2rem;
}
.mobile-dropdown {
  margin-left: 3.5rem!important;
}
.dropdown-toggle, .export {
  text-align: right;
  margin: auto;
  font-size: 1.5rem!important;
  color: var(--hover-purple)!important;
}

.dropdown-toggle:hover, .export:hover {
  text-align: right;
  margin: auto;
  font-size: 1.5rem!important;
  color: white!important;
}
  .chatbot-footer-bottom {
    margin-bottom: -80px!important;
   }
  .chatbot-link-logo{
    margin-top: 4rem;
    z-index: 9998;
    position: fixed;
    max-width: 4.5rem;
    background: white;
    -moz-border-radius: 50px;
    -webkit-border-radius: 50px;
    border-radius: 50px;
    padding: -1rem;
right: 2%;
    border: 3px var(--secondary-purple) solid;
    box-shadow: 0 0 0 var(--hover-purple);
    animation: pulse 2s infinite;
  }
  .advocate-link-logo{
    margin-top: 8rem;
    z-index: 9998;
    position: fixed;
    max-width: 4.5rem;
    background: white;
    -moz-border-radius: 50px;
    -webkit-border-radius: 50px;
    border-radius: 50px;
    padding: -1rem;
right: 2%;
    border: 3px var(--secondary-purple) solid;
    box-shadow: 0 0 0 var(--hover-purple);
    animation: pulse 2s infinite;
  }
  @-webkit-keyframes pulse {
    0% {
      -webkit-box-shadow: 0 0 0 0  var(--text-purple);
    }
    70% {
        -webkit-box-shadow: 0 0 0 15px rgba(244,157,22, 0);
    }
    100% {
        -webkit-box-shadow: 0 0 0 0 rgba(244,157,22, 0);
    }
  }
  @keyframes pulse {
    0% {
      -moz-box-shadow: 0 0 0 0  var(--text-purple);
      box-shadow: 0 0 0 0  var(--text-purple);
    }
    70% {
        -moz-box-shadow: 0 0 0 15px rgba(244,157,22, 0);
        box-shadow: 0 0 0 15px rgba(244,157,22, 0);
    }
    100% {
        -moz-box-shadow: 0 0 0 0 rgba(244,157,22, 0);
        box-shadow: 0 0 0 0 rgba(244,157,22, 0);
    }
  }
  
  .chatbot-link-export{
    margin-top: 9rem;
    z-index: 9998;
    position: fixed;
    max-width: 4.5rem;
    background: white;
    -moz-border-radius: 50px;
    -webkit-border-radius: 50px;
    border-radius: 50px;
    padding: -1rem;
    right: 2%;
    border: 3px var(--secondary-purple) solid;
    box-shadow: 0 0 0 var(--hover-purple);
    animation: pulse 2s infinite;
  }

/* Chatbot */
.feedback-text {
  margin-top: 2rem;
}
.feedback-button {
  border: 2px var(--primary-purple) solid!important;
  background-color: var(--text-purple)!important;
  color: var(--primary-purple)!important;
  padding: auto!important;
  margin: auto!important;
}

  .feedback-button:link {
    cursor: pointer;
  }
  .feedback-button:visited {
    cursor: pointer;
  }
  .feedback-button.active,
  .feedback-button:focus {
    cursor: pointer;
  }
  .feedback-button:hover {
    background-color: var(--background)!important;
    color: var(--text-purple)!important;
    transform: scale(1.015);
    cursor: pointer;
  }
  .feedback-button-text {
    font-size: 1.5rem;
  }

 /* chatbot height start */
 .react-chatbot-kit-chat-inner-container {
  background-color: var(--text-purple)!important;
  height: 290px!important;
}
.react-chatbot-kit-chat-message-container {
  height: 250px!important;
}
  /* chatbot height end */

.react-chatbot-kit-chat-header {
  font-size: 1.1rem!important;
  color: var(--text-purple)!important;
  background-color: var(--primary-purple)!important;
  padding: 0rem!important;
}

.react-chatbot-kit-chat-input-container {
  background-color:var(--secondary-purple)!important;
  border: 1px solid var(--primary-purple)!important;
}

.react-chatbot-kit-chat-btn-send {
  background-color:var(--primary-purple)!important;
}

.react-chatbot-kit-chat-btn-send-icon:hover {
transform: scale(1.25);
}

.react-chatbot-kit-chat-bot-message-container {
  width: 100%;
}

.react-chatbot-kit-chat-bot-avatar-container {
}

.react-chatbot-kit-chat-bot-avatar-icon {
}

.react-chatbot-kit-chat-bot-avatar-letter {
  display: none;
}
.react-chatbot-kit-chat-container {
  width: 98%!important;
  text-align: center;
  margin: auto;
}

.react-chatbot-kit-chat-bot-avatar-container {
  width: 3.5rem!important;
  height: 3.5rem!important;
  background-color: white!important;
  border: 2px solid var(--primary-purple);
  background-image: url('./GRTDataHubChatBot.png')!important;
  background-repeat: no-repeat!important;
  background-size: 3rem;
  background-position-x: 50%;
  background-position-y: 20%;
}

.react-chatbot-kit-chat-bot-message {
  text-align: left!important;
  width: 100%!important;
  color: var(--text-purple)!important;
  background-color: #0C0B23!important;
  font-size: 1rem!important;
}

.react-chatbot-kit-chat-bot-message-arrow {
  color: #0C0B23!important;
  border-right: 8px solid #0C0B23!important;
}
  .indexer-table-header {
    text-align: center;
    margin-left: 1rem;
  }
  .indexer-table {
    text-align: center;
  }
  .chatbot-data {
    display: inline!important;
  }
  .telegramActiveDelegationCount {
    max-width: 92%;
    margin: auto; 
    text-align: center;
    margin-top: 0.5%;
    }
    .top-telegram {
      margin-top: 0.25rem;
    }
    .chatbot-top {
      margin-top: 5rem;
    }
    .chatbot-page-container {
      background: var(--background);
      color: var(--text-purple);
      width: 100%;
      margin: auto;
      margin-top: 150px;
      padding-left: 10px;
      padding-right: 10px;
      text-align: center;
    }
    .chatbot-text-container {
      background: whitesmoke;
      border-radius: 1rem;
      color: var(--secondary-purple);
      margin: auto!important;
      padding-bottom: 10px;
      padding-top: 10px;
      text-align: center!important;
    }
    .chatbot-page-title {
      font-size: 1.25rem;
      font-weight: 600;
      color: var(--secondary-purple);
      padding-bottom: 0.25rem;
      padding-left: 5px;
      padding-right: 5px;
    }
    .chatbot-page-subtitle {
      font-size: 1.75rem;
      padding-left: 5px;
      padding-right: 5px;
    }
    .chatbot-page-text {
      font-size: 1.25rem;
      margin-bottom: 1rem;
      margin-top: 1rem;
      padding-left: 10px;
      padding-right: 10px;
    }
    /* chatbot end */
    .logo {
      width:  6.75rem;
      height: 5.75rem;
      margin-left: 2rem;
      margin-top: -0.5rem;
    }
  .dataHub-logos {
    max-width: 6rem !important;
    max-height: 35rem !important;
    position: absolute;
    top: 0;
    margin-top: 1rem;
  }
  .monitor-config {
    display: none!important;
  }
  .navbar-toggler {
    display: none;
  }
  .pages {
    margin-bottom: 0.1rem;
    margin-right: 2rem;
}
.indexer-title {
  text-align: center!important;
  font-weight: 400;
  font-size: 1.75rem;
  padding-top: 2rem;
  color: var(--text-purple);
  
  margin-top: 6rem;
}
.indexer-subtitle {
  text-align: center!important;
  font-weight: 400;
  font-size: 1.5rem;
  color: var(--text-purple);
  
  margin-top: 1rem;
  margin-right: 2rem;
  margin-left: 2rem;
}
.indexer {
  z-index: 999;
  position: absolute;
  left: 0%;
  top: 50%;
}

.curator {
  z-index: 999;
  position: absolute;
  text-align: center!important;
  top: 50%;
}
.tokens, .rewards, .uncollected-rewards {
  margin-top: 2rem;
  width: 90%;
  height: 14rem;
  margin-left: 5%;
  box-shadow: #4D1D86 4px 5px 10px 3px;
}

.b-map {
  margin-top: 2rem;
  margin-left: 1rem;
  width: 800px;
  height: 3000px;
  text-align: center!important;
  box-shadow: #4D1D86 4px 5px 10px 3px;
}

.c-map {
  margin-top: 2rem;
  margin-left: 1rem;
  width: 800px;
  height: 1600px;
  text-align: center!important;
  box-shadow: #4D1D86 4px 5px 10px 3px;
}

.d-map {
  margin-top: -3rem;
  margin-left: 3rem;
  width: 800px;
  height: 3800px;
  text-align: center!important;
  box-shadow: #4D1D86 4px 5px 10px 3px;
}

.rewards-per-hour {
  margin-top: 2rem;
  padding-bottom: 2rem;
  width: 90%;
  height: 14rem;
  margin-left: 5%;
  box-shadow: #4D1D86 4px 5px 10px 3px;
}

.indexers {
  padding-left: 1rem;
  padding-left: 1rem;
  color: grey;
  font-size:1.25rem;
  margin-right: 1rem;
}
.indexers:link{
  color: grey;
  cursor: pointer;
  font-size:1.25rem;
  margin-right: 1rem;
}
.indexers:visited {
  color: grey;
  cursor: pointer;
  font-size:1.25rem;
  margin-right: 1rem;
}
.indexers.active,
.indexers:focus {
  border-bottom: 0rem solid var(--hover-purple);
  cursor: pointer;
  font-size:1.25rem;
  margin-right: 1rem;
}
.indexers:hover {
  color: var(--text-purple);
  transform: scale(1.05);
  cursor: pointer;
  font-size:1.25rem;
  margin-right: 1rem;
}
.indexer-link {
  color: white;
}
.indexer-link:link {
  color: white;
  cursor: pointer;
}
.indexer-link:visited {
  color: var(--text-purple);
  cursor: pointer;
}
.indexer-link.active,
.indexer-link:focus {
  border-bottom: 0rem solid var(--text-purple);
  cursor: pointer;
}
.indexer-link:hover {
  color: var(--text-purple);
  transform: scale(1.05);
  cursor: pointer;
}
  .cookie-page-text {
    padding-top: 1rem;
    font-size: 1.25rem;
    margin-bottom: 1rem;
    padding-left: 20px;
    padding-right: 20px;
  }
  .cookies-video {
    padding-top: 2rem;
  }
  .cookie-button {
    z-index: 9999;
    position: absolute;
  }

  body > div:nth-child(1) > button {
    z-index: 10000;
    font-size: 3rem;
    position: absolute;
    padding: auto;
    margin-top: -95px;
    width: 45px;
    height: 45px;
    margin-left: 85%;
    background-color: transparent;
    border: none;
    color: var(--text-purple);
  }
 
  .extra-data2 {
    font-size: 1.25rem;
    text-align: center;
    color: var(--text-purple);
   
  }
  .data-link {
    color: grey;
  }
  .data-link:link {
    color: grey;
    cursor: pointer;
  }
  .data-link:visited {
    color: grey;
    cursor: pointer;
  }
  .data-link.active,
  .data-link:focus {
    border-bottom: 0rem solid white;
    cursor: pointer;
  }
  .data-link:hover {
    color: white;
    transform: scale(1.05);
    cursor: pointer;
  }
  .question-bank-container {
    background: var(--background);
    color: var(--text-purple);
    width: 100%;
    margin: auto;
    margin-top: 150px;
    margin-bottom: 50px;
    padding-left: 50px;
    padding-right: 50px;
    text-align: center;
  }
  .about-page-container {
    background: var(--background);
    color: var(--text-purple);
    width: 100%;
    margin: auto;
    margin-top: 150px;
    margin-bottom: 50px;
    padding-left: 50px;
    padding-right: 50px;
    text-align: center;
  }
  .QB-text-container {
    background: white;
    border-radius: 1rem;
    padding: 30px;
    color: var(--secondary-purple);
  }
  .text-container {
    background: white;
    border-radius: 1rem;
    padding: 30px;
    color: var(--secondary-purple);
  }
  .about-hr {
    border: 1.5px solid var(--background);
    color: var(--background);
    border-radius: 5px;
    width: 35%;
    margin: auto;
    margin-bottom: 1rem;
    box-shadow: 0 0 5px 1px var(--background);
  }
  .about-page-title {
    font-size: 2rem;
    font-weight: 600;
    color: var(--secondary-purple);
    padding-bottom: 2rem;
  }
  .about-page-subtitle {
    font-size: 1.75rem;
  }
  .about-page-text {
    font-size: 1.25rem;
    margin-bottom: 1rem;
  }
  .graphiql-link {
    color: var(--secondary-purple);
  }
  .graphiql-link:link {
    color: var(--secondary-purple);
    cursor: pointer;
  }
  .graphiql-link:visited {
    color: var(--secondary-purple);
    cursor: pointer;
  }
  .graphiql-link.active,
  .graphiql-link:focus {
    border-bottom: 0rem solid var(--hover-purple);
    cursor: pointer;
  }
  .graphiql-link:hover {
    color: var(--primary-purple);
    transform: scale(1.05);
    cursor: pointer;
  }
  .cookies-policy-container {
    background: white;
    width: 90%;
    padding-bottom: 1rem;
    margin: auto;
    border-radius: 2rem;
    margin-top: 150px;
    margin-bottom: 50px;
  }
  .termly-cookie-preference-button {
   display: none;
  }

  .privacy-policy-container {
    background: white;
    width: 90%;
    padding-bottom: 1rem;
    margin: auto;
    border-radius: 2rem;
    margin-top: 150px;
    margin-bottom: 50px;
  }
  .privacy-policy {
    margin-top: 110px;
    font-weight: 700;
    font-size: 1.1rem;
    text-align: center;
    background-color: var(--background);
    color: var(--secondary-purple);
  }
  .privacy-policy-link,
  .about {
    padding-left: 1rem;
    padding-left: 1rem;
    color: grey;
  }
  .privacy-policy-link:link,
  .about:link {
    color: grey;
    cursor: pointer;
  }
  .privacy-policy-link:visited,
  .about:visited {
    color: grey;
    cursor: pointer;
  }
  .privacy-policy-link.active,
  .privacy-policy-link:focus,
  .about.active,
  .about:focus {
    border-bottom: 0rem solid var(--hover-purple);
    cursor: pointer;
  }
  .privacy-policy-link:hover,
  .about:hover {
    color: var(--text-purple);
    transform: scale(1.05);
    cursor: pointer;
  }
  .subscribeNote {
    margin-left: -15rem;
    width: 30rem;
    font-size: 0.75rem;
    color: white;
  }
 /* loader new start  */
 .loader:empty {
  position: absolute;
  top: calc(50% + 5.5rem);
  left: calc(50% + 2em);
  width: 4em;
  height: 4em;
  border: 0.35em solid white;
  border-left: 0.35em solid #10034B;
  border-radius: 50%;
  animation: load8 0.8s infinite linear;
}
.loadingTitle {
  position: absolute;
  top: 17rem;
  left: calc(50% - 3em);
  font-size: 2rem;
  color: #10034B;
  -webkit-box-reflect:below 0px linear-gradient(transparent, #0005);
}
.loaderLogo {
  position: absolute;
  top: 4rem;
  left: calc(50% - 8em);
  width: 15em;
  height: 12em;
}
@keyframes load8 {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
/* loader new end  */
  .C {
    font-size: 1rem;
  }
  .logo {
    width:  6.75rem;
    height: 5.75rem;
    margin-left: 2rem;
    margin-top: -0.5rem;
  }
  .data-logo {
    max-width: 1rem !important;
    height: 1rem !important;
    position: absolute;
  }
  .brand-text {
    color: var(--text-purple);
    display: inline;
    margin-left: 0rem;
    letter-spacing: 0.0375rem;
    font-size: 1.25rem;
  }
  .header-text {
    font-weight: 400;
    font-size: 1.75rem;
    margin-left: 5rem;
    margin-top: 105px;
    color: var(--text-purple);
  
  }
  .header-sub-text {
    font-weight: 400;
    font-size: 1.25rem;
    text-align: center!important;
    margin: auto!important;
    width: 90%;
    color: var(--text-purple);
   
    display: block;
  }
  nav a.TGN {
    display: none;
  }
  .navbar-nav > li > a.TGN:link {
    display: none;
  }
  .navbar-nav > li > a.TGN:visited {
    display: none;
  }
  .navbar-nav > li > a.TGN.active,
  .navbar-nav > li > a.TGN:focus {
    display: none;
  }
  .navbar-nav > li > a.TGN:hover {
    display: none;
  }
  nav a.nav-link {
    font-weight: 400;
    font-size: 1.25rem;
    color: var(--text-purple);
  }
  .navbar-nav > li > a {
    border-bottom: 0.01875rem solid var(--transparent);
    padding: 0;
  }
  .navbar-nav > li > a:link {
    color: var(--hover-purple);
  }
  .navbar-nav > li > a:visited {
    color: var(--text-purple);
  }
  .navbar-nav > li > a.active,
  .navbar-nav > li > a:focus {
    border-bottom: 0.01875rem solid var(--hover-purple);
    padding: 0;
    transition: all 0.5s ease-in-out;
  }
  .navbar-nav > li > a:hover {
    border-bottom: 0.01875rem solid var(--hover-purple);
    padding: 0;
    color: var(--hover-purple);
    transform: scale(1.05);
  }
  .line {
    margin-left: 1rem;
  }
  .links {
    text-align: right;
    padding-right: 0;
    margin: auto;
    padding: auto;
  }
  .dropdown {
    margin-left: auto;
    padding: auto;
  }
  .bar {
    margin-bottom: 0.25rem;
    margin-right: 2rem;
  }
  .line {
    margin-bottom: 0.25rem;
    margin-right: 2rem;
  }
  .about {
    margin-left: auto;
    margin-right: 0.8rem;
  }
  .menu-icon {
    color: var(--secondary-purple);
  }
  .main-info {
    background-image: url("TopBackground.svg");
    background-repeat: no-repeat;
    background-size: 100% auto;
    width: 100%;
    height: 100%;
    margin-top: 90px;
  }
  .community-links {
    margin-left: 4rem;
    padding-top: 1rem;
    position: absolute;
    z-index: 999;
  }
  .telegram-icon,
  .twitter-icon,
  .reddit-icon,
  .youtube-icon {
    display: inline;
    padding: 1rem;
    transition: all 0.5s ease-in-out;
    z-index: 999;
  }
  .icon:hover {
    color: var(--hover-purple);
    transform: scale(1.15);
  }
  .icon-youtube:hover {
    color: var(--text-purple);
    transform: scale(1.1);
  }
  .news-title {
    text-shadow: 2.25px 2.25px 3px var(--hover-purple);
    color: var(--text-purple);
    text-align: center;
    margin-top: 1rem;
    margin-left: -25rem;
    margin-bottom: 1rem;
    font-size: 2rem;
  }
  .newsGraph1,
  .newsGraph2,
  .newsGraph3 {
    margin-bottom: 1rem;
  }
  .youtube {
    z-index: 999;
    display: inline;
    position: absolute;
    text-align: center;
    color: var(--text-purple);
  }
  span.anchor {
    display: block;
    visibility: hidden;
    position: relative;
    padding-top: 20%;
  }
  .activeDelegators,
  .holders,
  .curatorCount,
  .subgraphCount {
    font-weight: 400;
    color: var(--text-purple);
    letter-spacing: 0.0375rem;
  }

  .video {
    position: absolute;
    box-shadow: #4D1D86 4px 8px 10px 0;
    left: 55%;
    top: 0%;
  }
  .g-ytsubscribe {
    position: absolute;
    font-family: Euclid Circular A;
    font-weight: 400;
    margin: 0;
    left: 78%;
    top: 41%;
  }

  .newsGraph {
    width: 100%;
    box-shadow: #4D1D86 4px 5px 10px 3px;
  }
  .chartImg {
    width: 100%;
    box-shadow: #4D1D86 6px 6px 10px 3px;
    margin: auto;
  }
  .center {
    margin: auto;
  }
  .line1 {
    margin-top: 5rem;
  }

  .chartImg6 {
    text-align: center;
    padding-top: 1rem;
    width: 100%;
  
  }
  .reveal {
    position: relative;
    transform: translateY(50px);
    opacity: 0;
    transition: 0.75s all ease;
  }
  .reveal.active {
    transform: translateY(0);
    opacity: 1;
  }
  .email {
    color: var(--hover-purple);
    font-weight: 500;
  }
  .footerLink {
    color: var(--hover-purple);
  }
  .footerLink:link {
    color: var(--hover-purple);
    cursor: pointer;
  }
  .footerLink:visited {
    color: var(--hover-purple);
    cursor: pointer;
  }
  .footerLink.active,
  .footerLink:focus {
    border-bottom: 0rem solid var(--hover-purple);
    cursor: pointer;
  }
  .footerLink:hover {
    color: var(--text-purple);
    transform: scale(1.05);
    cursor: pointer;
  }
  .footer {
    margin-bottom: -1rem;
    padding: 1rem;
    font-weight: 700;
    margin-top: 1rem;
    font-size: 1.1rem;
    text-align: center;
    background-color: var(--primary-purple);
    color: var(--secondary-purple);
  }
  .tsparticles {
    position: absolute;
    height: 100%;
    width: 100%;
  }
  .rotate-position,
  .about-rotate-position,
  .phone,
  .about-phone,
  .message,
  .about-message, .home-message {
    display: none;
  }

  .note {
    text-decoration: underline;
  }


 .admin-video {
    position: absolute;
    left: 48%;
    top: 20%;
    width: 10%;
    height: 10%;
  }

}

/* *************************************************************************
****************************************************************************
***************************************************************************/
/* *************************************************************************
****************************************************************************
***************************************************************************/
@media screen and (min-width: 921px) and (max-width: 924px) {
  .no-show-monitor {
    display: none!important;
  }
  .no-monitor {
    display: none!important;
  }
  /* dune start */
.HoldersGraph {
  background-image: linear-gradient(90deg, #1B0D33, #D8D2E4, #D8D2E4,#D8D2E4, #D8D2E4,#D8D2E4,#D8D2E4,#D8D2E4,#D8D2E4,#D8D2E4,#D8D2E4,#D8D2E4,#D8D2E4,#D8D2E4,#D8D2E4,#D8D2E4,#D8D2E4,#D8D2E4,#1B0D33);
  margin-bottom: -36px!important;
  padding-left: 20px!important;
  padding-right: 20px!important;
}
.HoldersGraphBottom {
  background-image: linear-gradient(90deg, #1B0D33, #D8D2E4,#D8D2E4,#D8D2E4,#D8D2E4,#D8D2E4,#D8D2E4,#D8D2E4,#D8D2E4,#D8D2E4,#D8D2E4,#D8D2E4,#D8D2E4,#D8D2E4,#D8D2E4,#D8D2E4,#D8D2E4,#D8D2E4, #1B0D33);
  padding-left: 20px!important;
  padding-right: 20px!important;
}
/* dune end */
  .chatbot-footer-bottom {
    margin-bottom: -80px!important;
   }
  .chatbot-link-logo{
    margin-top: 13rem;
    z-index: 9998;
    position: fixed;
    max-width: 6rem;
    background: white;
    -moz-border-radius: 50px;
    -webkit-border-radius: 50px;
    border-radius: 50px;
    padding: -1rem;
   right: 2%;
    border: 3px var(--secondary-purple) solid;
    box-shadow: 0 0 0 var(--hover-purple);
    animation: pulse 2s infinite;
  }
  .advocate-link-logo{
    margin-top: 21rem;
    z-index: 9998;
    position: fixed;
    max-width: 6rem;
    background: white;
    -moz-border-radius: 50px;
    -webkit-border-radius: 50px;
    border-radius: 50px;
    padding: -1rem;
   right: 2%;
    border: 3px var(--secondary-purple) solid;
    box-shadow: 0 0 0 var(--hover-purple);
    animation: pulse 2s infinite;
  }
  @-webkit-keyframes pulse {
    0% {
      -webkit-box-shadow: 0 0 0 0  var(--text-purple);
    }
    70% {
        -webkit-box-shadow: 0 0 0 15px rgba(244,157,22, 0);
    }
    100% {
        -webkit-box-shadow: 0 0 0 0 rgba(244,157,22, 0);
    }
  }
  @keyframes pulse {
    0% {
      -moz-box-shadow: 0 0 0 0  var(--text-purple);
      box-shadow: 0 0 0 0  var(--text-purple);
    }
    70% {
        -moz-box-shadow: 0 0 0 15px rgba(244,157,22, 0);
        box-shadow: 0 0 0 15px rgba(244,157,22, 0);
    }
    100% {
        -moz-box-shadow: 0 0 0 0 rgba(244,157,22, 0);
        box-shadow: 0 0 0 0 rgba(244,157,22, 0);
    }
  }
  
  .chatbot-link-export{
    margin-top: 18rem;
    z-index: 9998;
    position: fixed;
    max-width: 6rem;
    background: white;
    -moz-border-radius: 50px;
    -webkit-border-radius: 50px;
    border-radius: 50px;
    padding: -1rem;
   right: 2%;
    border: 3px var(--secondary-purple) solid;
    box-shadow: 0 0 0 var(--hover-purple);
    animation: pulse 2s infinite;
  }
  
/* Chatbot */
.feedback-text {
  margin-top: 2rem;
}
.feedback-button {
  border: 2px var(--primary-purple) solid!important;
  background-color: var(--text-purple)!important;
  color: var(--primary-purple)!important;
  padding: auto!important;
  margin: auto!important;
}

  .feedback-button:link {
    cursor: pointer;
  }
  .feedback-button:visited {
    cursor: pointer;
  }
  .feedback-button.active,
  .feedback-button:focus {
    cursor: pointer;
  }
  .feedback-button:hover {
    background-color: var(--background)!important;
    color: var(--text-purple)!important;
    transform: scale(1.015);
    cursor: pointer;
  }
  .feedback-button-text {
    font-size: 1.5rem;
  }

.react-chatbot-kit-chat-inner-container {
  background-color: var(--text-purple)!important;
}

.react-chatbot-kit-chat-header {
  font-size: 1.1rem!important;
  color: var(--text-purple)!important;
  background-color: var(--primary-purple)!important;
  padding: 0rem!important;
}

.react-chatbot-kit-chat-input-container {
  background-color:var(--secondary-purple)!important;
  border: 1px solid var(--primary-purple)!important;
}

.react-chatbot-kit-chat-btn-send {
  background-color:var(--primary-purple)!important;
}

.react-chatbot-kit-chat-btn-send-icon:hover {
transform: scale(1.25);
}

.react-chatbot-kit-chat-bot-message-container {
  width: 100%;
}

.react-chatbot-kit-chat-bot-avatar-container {
}

.react-chatbot-kit-chat-bot-avatar-icon {
}

.react-chatbot-kit-chat-bot-avatar-letter {
  display: none;
}
.react-chatbot-kit-chat-container {
  width: 98%!important;
  text-align: center;
  margin: auto;
}

.react-chatbot-kit-chat-bot-avatar-container {
  width: 3.5rem!important;
  height: 3.5rem!important;
  background-color: white!important;
  border: 2px solid var(--primary-purple);
  background-image: url('./GRTDataHubChatBot.png')!important;
  background-repeat: no-repeat!important;
  background-size: 3rem;
  background-position-x: 50%;
  background-position-y: 20%;
}

.react-chatbot-kit-chat-bot-message {
  text-align: left!important;
  width: 100%!important;
  color: var(--text-purple)!important;
  background-color: #0C0B23!important;
  font-size: 1rem!important;
}

.react-chatbot-kit-chat-bot-message-arrow {
  color: #0C0B23!important;
  border-right: 8px solid #0C0B23!important;
}
  .indexer-table-header {
    text-align: center;
    margin-left: 1rem;
  }
  .indexer-table {
    text-align: center;
  }
  .chatbot-data {
    display: inline!important;
  }
  .telegramActiveDelegationCount {
    max-width: 92%;
    margin: auto; 
    text-align: center;
    margin-top: 0.5%;
    }
    .top-telegram {
      margin-top: 0.25rem;
    }
    .chatbot-top {
      margin-top: 5rem;
    }
    .chatbot-page-container {
      background: var(--background);
      color: var(--text-purple);
      width: 100%;
      margin: auto;
      margin-top: 150px;
      padding-left: 10px;
      padding-right: 10px;
      text-align: center;
    }
    .chatbot-text-container {
      background: whitesmoke;
      border-radius: 1rem;
      color: var(--secondary-purple);
      margin: auto!important;
      padding-bottom: 10px;
      padding-top: 10px;
      text-align: center!important;
    }
    .chatbot-page-title {
      font-size: 1.25rem;
      font-weight: 600;
      color: var(--secondary-purple);
      padding-bottom: 0.25rem;
      padding-left: 5px;
      padding-right: 5px;
    }
    .chatbot-page-subtitle {
      font-size: 1.75rem;
      padding-left: 5px;
      padding-right: 5px;
    }
    .chatbot-page-text {
      font-size: 1.25rem;
      margin-bottom: 1rem;
      margin-top: 1rem;
      padding-left: 10px;
      padding-right: 10px;
    }
    /* chatbot end */
  .dataHub-logos {
    max-width: 6rem !important;
    max-height: 35rem !important;
    position: absolute;
    top: 0;
    margin-top: 1rem;
  }
  .mobile-config {
    display: none!important;
  }
  
  .offcanvas-button, .offcanvas-menu {
    display: none!important;
  }
  .navbar-toggler {
    display: none;
  }
  .pages {
    margin-bottom: 0.1rem;
    margin-right: 2rem;
}
.indexer-title {
  text-align: center!important;
  font-weight: 400;
  font-size: 1.75rem;
  padding-top: 2rem;
  color: var(--text-purple);
  
  margin-top: 6rem;
}
.indexer-subtitle {
  text-align: center!important;
  font-weight: 400;
  font-size: 1.5rem;
  color: var(--text-purple);
  
  margin-top: 1rem;
  margin-right: 2rem;
  margin-left: 2rem;
}
.indexer {
  z-index: 999;
  position: absolute;
  left: 0%;
  top: 50%;
}

.curator {
  z-index: 999;
  position: absolute;
  text-align: center!important;
  top: 50%;
}

.map, .tokens, .rewards, .uncollected-rewards {
  margin-top: 2rem;
  width: 90%;
  height: 14rem;
  margin-left: 5%;
  box-shadow: #4D1D86 4px 5px 10px 3px;
}

.b-map {
  margin-top: 2rem;
  margin-left: 1rem;
  width: 800px;
  height: 3000px;
  text-align: center!important;
  box-shadow: #4D1D86 4px 5px 10px 3px;
}

.c-map {
  margin-top: 2rem;
  margin-left: 1rem;
  width: 800px;
  height: 1600px;
  text-align: center!important;
  box-shadow: #4D1D86 4px 5px 10px 3px;
}

.d-map {
  margin-top: -3rem;
  margin-left: 3rem;
  width: 800px;
  height: 3800px;
  text-align: center!important;
  box-shadow: #4D1D86 4px 5px 10px 3px;
}

.rewards-per-hour {
  margin-top: 2rem;
  padding-bottom: 2rem;
  width: 90%;
  height: 14rem;
  margin-left: 5%;
  box-shadow: #4D1D86 4px 5px 10px 3px;
}

.indexers {
  padding-left: 1rem;
  padding-left: 1rem;
  color: grey;
  font-size:1.25rem;
  margin-right: 1rem;
}
.indexers:link{
  color: grey;
  cursor: pointer;
  font-size:1.25rem;
  margin-right: 1rem;
}
.indexers:visited {
  color: grey;
  cursor: pointer;
  font-size:1.25rem;
  margin-right: 1rem;
}
.indexers.active,
.indexers:focus {
  border-bottom: 0rem solid var(--hover-purple);
  cursor: pointer;
  font-size:1.25rem;
  margin-right: 1rem;
}
.indexers:hover {
  color: var(--text-purple);
  transform: scale(1.05);
  cursor: pointer;
  font-size:1.25rem;
  margin-right: 1rem;
}
.indexer-link {
  color: white;
}
.indexer-link:link {
  color: white;
  cursor: pointer;
}
.indexer-link:visited {
  color: var(--text-purple);
  cursor: pointer;
}
.indexer-link.active,
.indexer-link:focus {
  border-bottom: 0rem solid var(--text-purple);
  cursor: pointer;
}
.indexer-link:hover {
  color: var(--text-purple);
  transform: scale(1.05);
  cursor: pointer;
}
  .cookie-page-text {
    padding-top: 1rem;
    font-size: 1.25rem;
    margin-bottom: 1rem;
    padding-left: 20px;
    padding-right: 20px;
  }
  .cookies-video {
    padding-top: 2rem;
  }
  .cookie-button {
    z-index: 9999;
    position: absolute;
  }

  body > div:nth-child(1) > button {
    z-index: 10000;
    font-size: 3rem;
    position: absolute;
    padding: auto;
    margin-top: -95px;
    width: 45px;
    height: 45px;
    margin-left: 85%;
    background-color: transparent;
    border: none;
    color: var(--text-purple);
  }
  .extra-data {
    margin-top: 2rem;
    font-size: 1.25rem;
    text-align: center;
    color: var(--text-purple);
   
  }
  .extra-data2 {
    font-size: 1.25rem;
    text-align: center;
    color: var(--text-purple);
   
  }
  .data-link {
    color: grey;
  }
  .data-link:link {
    color: grey;
    cursor: pointer;
  }
  .data-link:visited {
    color: grey;
    cursor: pointer;
  }
  .data-link.active,
  .data-link:focus {
    border-bottom: 0rem solid white;
    cursor: pointer;
  }
  .data-link:hover {
    color: white;
    transform: scale(1.05);
    cursor: pointer;
  }
  .question-bank-container {
    background: var(--background);
    color: var(--text-purple);
    width: 100%;
    margin: auto;
    margin-top: 150px;
    margin-bottom: 50px;
    padding-left: 50px;
    padding-right: 50px;
    text-align: center;
  }
  .about-page-container {
    background: var(--background);
    color: var(--text-purple);
    width: 100%;
    margin: auto;
    margin-top: 150px;
    margin-bottom: 50px;
    padding-left: 50px;
    padding-right: 50px;
    text-align: center;
  }
  .QB-text-container {
    background: white;
    border-radius: 1rem;
    padding: 30px;
    color: var(--secondary-purple);
  }
  .text-container {
    background: white;
    border-radius: 1rem;
    padding: 30px;
    color: var(--secondary-purple);
  }
  .QB-about-hr {
    border: 1.5px solid var(--background);
    color: var(--background);
    border-radius: 5px;
    width: 35%;
    margin: auto;
    margin-bottom: 1rem;
    box-shadow: 0 0 5px 1px var(--background);
  }
  .QB-about-page-title {
    font-size: 2rem;
    font-weight: 600;
    color: var(--secondary-purple);
    padding-bottom: 2rem;
  }
  .QB-about-page-subtitle {
    font-size: 1.75rem;
  }
  .QB-about-page-text {
    font-size: 1.25rem;
    margin-bottom: 1rem;
  }
  .about-hr {
    border: 1.5px solid var(--background);
    color: var(--background);
    border-radius: 5px;
    width: 35%;
    margin: auto;
    margin-bottom: 1rem;
    box-shadow: 0 0 5px 1px var(--background);
  }
  .about-page-title {
    font-size: 2rem;
    font-weight: 600;
    color: var(--secondary-purple);
    padding-bottom: 2rem;
  }
  .about-page-subtitle {
    font-size: 1.75rem;
  }
  .about-page-text {
    font-size: 1.25rem;
    margin-bottom: 1rem;
  }
  .graphiql-link {
    color: var(--secondary-purple);
  }
  .graphiql-link:link {
    color: var(--secondary-purple);
    cursor: pointer;
  }
  .graphiql-link:visited {
    color: var(--secondary-purple);
    cursor: pointer;
  }
  .graphiql-link.active,
  .graphiql-link:focus {
    border-bottom: 0rem solid var(--hover-purple);
    cursor: pointer;
  }
  .graphiql-link:hover {
    color: var(--primary-purple);
    transform: scale(1.05);
    cursor: pointer;
  }
  .cookies-policy-container {
    background: white;
    width: 90%;
    padding-bottom: 1rem;
    margin: auto;
    border-radius: 2rem;
    margin-top: 150px;
    margin-bottom: 50px;
  }
  .termly-cookie-preference-button {
   display: none;
  }

  .privacy-policy-container {
    background: white;
    width: 90%;
    padding-bottom: 1rem;
    margin: auto;
    border-radius: 2rem;
    margin-top: 150px;
    margin-bottom: 50px;
  }
  .privacy-policy {
    margin-top: 110px;
    font-weight: 700;
    font-size: 1.1rem;
    text-align: center;
    background-color: var(--background);
    color: var(--secondary-purple);
  }
  .privacy-policy-link,
  .about {
    padding-left: 1rem;
    padding-left: 1rem;
    color: grey;
  }
  .privacy-policy-link:link,
  .about:link {
    color: grey;
    cursor: pointer;
  }
  .privacy-policy-link:visited,
  .about:visited {
    color: grey;
    cursor: pointer;
  }
  .privacy-policy-link.active,
  .privacy-policy-link:focus,
  .about.active,
  .about:focus {
    border-bottom: 0rem solid var(--hover-purple);
    cursor: pointer;
  }
  .privacy-policy-link:hover,
  .about:hover {
    color: var(--text-purple);
    transform: scale(1.05);
    cursor: pointer;
  }
  .subscribeNote {
    margin-left: -15rem;
    width: 30rem;
    font-size: 0.75rem;
    color: white;
  }
  /* loader new start  */
  .loader:empty {
    position: absolute;
    top: calc(50% + 5.5rem);
    left: calc(50% + 2em);
    width: 4em;
    height: 4em;
    border: 0.35em solid white;
    border-left: 0.35em solid #10034B;
    border-radius: 50%;
    animation: load8 0.8s infinite linear;
  }
  .loadingTitle {
    position: absolute;
    top: 17rem;
    left: calc(50% - 3em);
    font-size: 2rem;
    color: #10034B;
  -webkit-box-reflect:below 0px linear-gradient(transparent, #0005);
  }
  .loaderLogo {
    position: absolute;
    top: 4rem;
    left: calc(50% - 8em);
    width: 15em;
    height: 12em;
  }
  @keyframes load8 {
    0% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(360deg);
    }
  }
  /* loader new end  */
  .C {
    font-size: 1rem;
  }
  .logo {
    width:  6.75rem;
    height: 5.75rem;
    margin-left: 2rem;
    margin-top: -0.5rem;
  }
  .data-logo {
    max-width: 1rem !important;
    height: 1rem !important;
    position: absolute;
  }
  .brand-text {
    color: var(--text-purple);
    display: inline;
    margin-left: 0rem;
    letter-spacing: 0.0375rem;
    font-size: 1.25rem;
  }
  .header-text {
    font-weight: 400;
    font-size: 1.75rem;
    margin-left: 5rem;
    margin-top: 105px;
    color: var(--text-purple);
 
  }
  .header-sub-text {
    font-weight: 400;
    font-size: 1.25rem;
    text-align: center!important;
    margin: auto!important;
    width: 90%;
    color: var(--text-purple);
  
    display: block;
  }
  nav a.TGN {
    display: none;
  }
  .navbar-nav > li > a.TGN:link {
    display: none;
  }
  .navbar-nav > li > a.TGN:visited {
    display: none;
  }
  .navbar-nav > li > a.TGN.active,
  .navbar-nav > li > a.TGN:focus {
    display: none;
  }
  .navbar-nav > li > a.TGN:hover {
    display: none;
  }
  nav a.nav-link {
    font-weight: 400;
    font-size: 1.25rem;
    color: var(--text-purple);
  }
  .navbar-nav > li > a {
    border-bottom: 0.01875rem solid var(--transparent);
    padding: 0;
  }
  .navbar-nav > li > a:link {
    color: var(--hover-purple);
  }
  .navbar-nav > li > a:visited {
    color: var(--text-purple);
  }
  .navbar-nav > li > a.active,
  .navbar-nav > li > a:focus {
    border-bottom: 0.01875rem solid var(--hover-purple);
    padding: 0;
    transition: all 0.5s ease-in-out;
  }
  .navbar-nav > li > a:hover {
    border-bottom: 0.01875rem solid var(--hover-purple);
    padding: 0;
    color: var(--hover-purple);
    transform: scale(1.05);
  }
  .line {
    margin-left: 1rem;
  }
  .links {
    text-align: right;
    padding-right: 0;
    margin: auto;
    padding: auto;
  }
  .dropdown {
    margin-left: auto;
    padding: auto;
  }
  .bar {
    margin-bottom: 0.25rem;
    margin-right: 2rem;
  }
  .line {
    margin-bottom: 0.25rem;
    margin-right: 2rem;
  }
  .about {
    margin-left: auto;
    margin-right: 0.8rem;
  }
  .menu-icon {
    color: var(--secondary-purple);
  }
  .main-info {
    background-image: url("TopBackground.svg");
    background-repeat: no-repeat;
    background-size: 100% auto;
    width: 100%;
    height: 100%;
    margin-top: 90px;
  }
  .community-links {
    margin-left: 4rem;
    padding-top: 1rem;
    position: absolute;
    z-index: 999;
  }
  .telegram-icon,
  .twitter-icon,
  .reddit-icon,
  .youtube-icon {
    display: inline;
    padding: 1rem;
    transition: all 0.5s ease-in-out;
    z-index: 999;
  }
  .icon:hover {
    color: var(--hover-purple);
    transform: scale(1.15);
  }
  .icon-youtube:hover {
    color: var(--text-purple);
    transform: scale(1.1);
  }
  .news-title {
    text-shadow: 2.25px 2.25px 3px var(--hover-purple);
    color: var(--text-purple);
    text-align: center;
    margin-top: 6rem;
    margin-bottom: 1rem;
    font-size: 2rem;
  }
  .newsGraph1,
  .newsGraph2,
  .newsGraph3 {
    margin-bottom: 1rem;
  }
  .youtube {
    z-index: 999;
    display: inline;
    position: absolute;
    text-align: center;
    color: var(--text-purple);
  }
  span.anchor {
    display: block;
    visibility: hidden;
    position: relative;
    padding-top: 20%;
  }
  .activeDelegators,
  .holders,
  .curatorCount,
  .subgraphCount {
    font-weight: 400;
    color: var(--text-purple);
    letter-spacing: 0.0375rem;
  }
  .hr {
    border: 3px solid var(--hr);
    border-radius: 5px;
    width: 90%;
    margin: auto;
    box-shadow: 0 0 10px 3px var(--hover-purple);
    position: relative;
    margin-top: 2.5rem;
  }
  .hr2 {
    border: 3px solid var(--hr);
    border-radius: 5px;
    width: 90%;
    margin: auto;
    box-shadow: 0 0 10px 3px var(--hover-purple);
    position: relative;
    margin-top: 1.5rem;
  }
  .video {
    position: absolute;
    box-shadow: #4D1D86 4px 8px 10px 0;
    left: 55%;
    top: 0%;
  }
  .g-ytsubscribe {
    position: absolute;
    font-family: Euclid Circular A;
    font-weight: 400;
    margin: 0;
    left: 78%;
    top: 41%;
  }
  .data-title {
    letter-spacing: 0.0375rem;
    font-weight: 400;
    color: var(--text-purple);
    font-size: 3.5rem;
    text-align: center;
    position: relative;
  }
  .chartsTitle {
    letter-spacing: 0.0375rem;
    font-weight: 400;
    color: var(--text-purple);
    font-size: 3.5rem;
    text-align: center;
  
    position: relative;
  }
  .chartsTitle2 {
    letter-spacing: 0.0375rem;
    font-weight: 400;
    color: var(--text-purple);
    font-size: 3.5rem;
    text-align: center;
   
  }
  .newsGraph {
    width: 100%;
    box-shadow: #4D1D86 4px 5px 10px 3px;
  }
  .chartImg {
    width: 100%;
    box-shadow: #4D1D86 6px 6px 10px 3px;
    margin: auto;
  }
  .center {
    margin: auto;
  }
  .line1 {
    margin-top: 5rem;
  }
  .chartImg1 {
    text-align: center;
    padding-top: -7rem;
    padding-bottom: 1rem;
    width: 100%;
    margin-top: -120px;
    margin-bottom: -120px;
  }
  .chartImg2,
  .chartImg3,
  .chartImg4,
  .chartImg5 {
    text-align: center;
    padding-top: 1rem;
    padding-bottom: 1rem;
    width: 100%;
    margin-top: -120px;
    margin-bottom: -120px;
  }
  .chartImg6 {
    text-align: center;
    padding-top: 1rem;
    width: 100%;
  
  }
  .reveal {
    position: relative;
    transform: translateY(50px);
    opacity: 0;
    transition: 0.75s all ease;
  }
  .reveal.active {
    transform: translateY(0);
    opacity: 1;
  }
  .email {
    color: var(--hover-purple);
    font-weight: 500;
  }
  .footerLink {
    color: var(--hover-purple);
  }
  .footerLink:link {
    color: var(--hover-purple);
    cursor: pointer;
  }
  .footerLink:visited {
    color: var(--hover-purple);
    cursor: pointer;
  }
  .footerLink.active,
  .footerLink:focus {
    border-bottom: 0rem solid var(--hover-purple);
    cursor: pointer;
  }
  .footerLink:hover {
    color: var(--text-purple);
    transform: scale(1.05);
    cursor: pointer;
  }
  .footer {
    margin-bottom: -1rem;
    padding: 1rem;
    font-weight: 700;
    margin-top: 1rem;
    font-size: 1.1rem;
    text-align: center;
    background-color: var(--primary-purple);
    color: var(--secondary-purple);
  }
  .tsparticles {
    position: absolute;
    height: 100%;
    width: 100%;
  }
  .rotate-position,
  .about-rotate-position,
  .phone,
  .about-phone,
  .message,
  .about-message, .home-message {
    display: none;
  }
  .adminHeader-text {
    font-weight: 400;
    font-size: 1.5rem;
    text-align: center;
    margin: auto;
    padding-top: 1rem;
    color: var(--text-purple);
    
    margin-top: 6rem;
  }
  .adminHeader-text2 {
    font-weight: 400;
    font-size: 1.5rem;
    text-align: center;
    margin: auto;
    color: var(--text-purple);
    
    margin-top: 2rem;
  }
  .adminSubheader-text {
    font-weight: 400;
    font-size: 1rem;
    margin-left: 3rem;
    color: var(--text-purple);
    
    margin-top: 1rem;
  }
  .adminSubheader-text2 {
    font-weight: 400;
    font-size: 1rem;
    margin-left: 3rem;
    margin-right: 3rem;
    color: var(--text-purple);
  
    margin-top: 1rem;
  }
  .note {
    text-decoration: underline;
  }

  .adminHr2 {
    border: 3px solid var(--hr);
    border-radius: 5px;
    width: 70%;
    margin: auto;
    box-shadow: 0 0 10px 3px var(--hover-purple);
    margin-top: 1rem;
    margin-left: 1.9rem;
  }

 .admin-video {
    position: absolute;
    left: 48%;
    top: 20%;
    width: 10%;
    height: 10%;
  }

  .adminChartsTitle {
    letter-spacing: 0.0375rem;
    font-weight: 400;
    color: var(--text-purple);
    font-size: 2rem;
    text-align: center;
    padding-top: 1rem;
    text-shadow: 2.5px 2.5px 4px var(--hover-purple);
    position: relative;
  }
  .adminChartsTitle2 {
    letter-spacing: 0.0375rem;
    font-weight: 400;
    color: var(--text-purple);
    font-size: 2rem;
    margin-top: 1rem;
    text-align: center;
    text-shadow: 2.5px 2.5px 4px var(--hover-purple);
  }
}
/* *************************************************************************
****************************************************************************
***************************************************************************/
/* *******************************MOBILE TO MONITOR******************************************
****************************************************************************
***************************************************************************/
@media screen and (min-width: 917px) and (max-width: 920px) {
  .mobile-dropdown {
    margin-left: 1.5rem!important;
  }
  .offcanvas-body {
    width: 100%!important;
    opacity: 0.9;
  }
  .offcanvas {
    width: 40%!important;
    background-color: var(--background)!important;
  }
  .no-show-monitor {
    display: none!important;
  }
  .no-monitor {
    display: none!important;
  }
   /* chatbot height start */
.react-chatbot-kit-chat-inner-container {
  background-color: var(--text-purple)!important;
  height: 290px!important;
}
.react-chatbot-kit-chat-message-container {
  height: 250px!important;
}
  /* chatbot height end */
 /* dune start */
.GRTDailyTransactionsPosition {
  margin-bottom: 1rem!important;
  margin-left: -15px!important;
 }
 .HoldersGraph {
  background-image: linear-gradient(90deg, #1B0D33, #D8D2E4, #D8D2E4,#D8D2E4, #D8D2E4,#D8D2E4,#D8D2E4,#D8D2E4,#D8D2E4,#D8D2E4,#D8D2E4,#D8D2E4,#D8D2E4,#D8D2E4,#D8D2E4,#D8D2E4,#D8D2E4,#D8D2E4,#1B0D33);
  margin-bottom: -36px!important;
  margin-left: -10px!important;
  padding-left: 20px!important;
  padding-right: 20px!important;
  height: 80px!important;
  width: 100%!important;
}
.HoldersGraphBottom {
  background-image: linear-gradient(90deg, #1B0D33, #D8D2E4,#D8D2E4,#D8D2E4,#D8D2E4,#D8D2E4,#D8D2E4,#D8D2E4,#D8D2E4,#D8D2E4,#D8D2E4,#D8D2E4,#D8D2E4,#D8D2E4,#D8D2E4,#D8D2E4,#D8D2E4,#D8D2E4, #1B0D33);
  padding-left: 20px!important;
  margin-left: -10px!important;
  padding-right: 20px!important;
  height: 80px!important;
  width: 100%!important;
}
  .chatbot-footer-bottom {
    margin-bottom: -80px!important;
   }
  .chatbot-link-logo{
    margin-top: 4rem;
    z-index: 9998;
    position: fixed;
    max-width: 5rem;
    background: white;
    -moz-border-radius: 50px;
    -webkit-border-radius: 50px;
    border-radius: 50px;
    padding: -1rem;
    right: 2%;
    border: 3px var(--secondary-purple) solid;
    box-shadow: 0 0 0 var(--hover-purple);
    animation: pulse 2s infinite;
  }
  .advocate-link-logo{
    margin-top: 8rem;
    z-index: 9998;
    position: fixed;
    max-width: 5rem;
    background: white;
    -moz-border-radius: 50px;
    -webkit-border-radius: 50px;
    border-radius: 50px;
    padding: -1rem;
    right: 2%;
    border: 3px var(--secondary-purple) solid;
    box-shadow: 0 0 0 var(--hover-purple);
    animation: pulse 2s infinite;
  }
  @-webkit-keyframes pulse {
    0% {
      -webkit-box-shadow: 0 0 0 0  var(--text-purple);
    }
    70% {
        -webkit-box-shadow: 0 0 0 15px rgba(244,157,22, 0);
    }
    100% {
        -webkit-box-shadow: 0 0 0 0 rgba(244,157,22, 0);
    }
  }
  @keyframes pulse {
    0% {
      -moz-box-shadow: 0 0 0 0  var(--text-purple);
      box-shadow: 0 0 0 0  var(--text-purple);
    }
    70% {
        -moz-box-shadow: 0 0 0 15px rgba(244,157,22, 0);
        box-shadow: 0 0 0 15px rgba(244,157,22, 0);
    }
    100% {
        -moz-box-shadow: 0 0 0 0 rgba(244,157,22, 0);
        box-shadow: 0 0 0 0 rgba(244,157,22, 0);
    }
  }
  
  .chatbot-link-export{
    margin-top: 8.5rem;
    z-index: 9998;
    position: fixed;
    max-width: 5rem;
    background: white;
    -moz-border-radius: 50px;
    -webkit-border-radius: 50px;
    border-radius: 50px;
    padding: -1rem;
    right: 2%;
    border: 3px var(--secondary-purple) solid;
    box-shadow: 0 0 0 var(--hover-purple);
    animation: pulse 2s infinite;
  }
  /* Chatbot */
.feedback-text {
  margin-top: 2rem;
}
.feedback-button {
  border: 2px var(--primary-purple) solid!important;
  background-color: var(--text-purple)!important;
  color: var(--primary-purple)!important;
  padding: auto!important;
  margin: auto!important;
}

  .feedback-button:link {
    cursor: pointer;
  }
  .feedback-button:visited {
    cursor: pointer;
  }
  .feedback-button.active,
  .feedback-button:focus {
    cursor: pointer;
  }
  .feedback-button:hover {
    background-color: var(--background)!important;
    color: var(--text-purple)!important;
    transform: scale(1.015);
    cursor: pointer;
  }
  .feedback-button-text {
    font-size: 1.5rem;
  }

.react-chatbot-kit-chat-inner-container {
  background-color: var(--text-purple)!important;
}

.react-chatbot-kit-chat-header {
  font-size: 1.1rem!important;
  color: var(--text-purple)!important;
  background-color: var(--primary-purple)!important;
  padding: 0rem!important;
}

.react-chatbot-kit-chat-input-container {
  background-color:var(--secondary-purple)!important;
  border: 1px solid var(--primary-purple)!important;
}

.react-chatbot-kit-chat-btn-send {
  background-color:var(--primary-purple)!important;
}

.react-chatbot-kit-chat-btn-send-icon:hover {
transform: scale(1.25);
}

.react-chatbot-kit-chat-bot-message-container {
  width: 100%;
}

.react-chatbot-kit-chat-bot-avatar-container {
}

.react-chatbot-kit-chat-bot-avatar-icon {
}

.react-chatbot-kit-chat-bot-avatar-letter {
  display: none;
}
.react-chatbot-kit-chat-container {
  width: 98%!important;
  text-align: center;
  margin: auto;
}

.react-chatbot-kit-chat-bot-avatar-container {
  width: 3.5rem!important;
  height: 3.5rem!important;
  background-color: white!important;
  border: 2px solid var(--primary-purple);
  background-image: url('./GRTDataHubChatBot.png')!important;
  background-repeat: no-repeat!important;
  background-size: 3rem;
  background-position-x: 50%;
  background-position-y: 20%;
}

.react-chatbot-kit-chat-bot-message {
  text-align: left!important;
  width: 100%!important;
  color: var(--text-purple)!important;
  background-color: #0C0B23!important;
  font-size: 1rem!important;
}

.react-chatbot-kit-chat-bot-message-arrow {
  color: #0C0B23!important;
  border-right: 8px solid #0C0B23!important;
}
  .indexer-table-header {
    text-align: center;
    margin-left: 1rem;
  }
  .indexer-table {
    text-align: center;
  }
  .chatbot-data {
    display: inline!important;
  }
  .telegramActiveDelegationCount {
    max-width: 92%;
    margin: auto; 
    text-align: center;
    margin-top: 0.5%;
    }
    .top-telegram {
      margin-top: 0.25rem;
    }
    .chatbot-top {
      margin-top: 5rem;
    }
    .chatbot-page-container {
      background: var(--background);
      color: var(--text-purple);
      width: 100%;
      margin: auto;
      margin-top: 120px;
      padding-left: 10px;
      padding-right: 10px;
      text-align: center;
    }
    .chatbot-text-container {
      background: whitesmoke;
      border-radius: 1rem;
      color: var(--secondary-purple);
      margin: auto!important;
      padding-bottom: 10px;
      padding-top: 10px;
      text-align: center!important;
      padding-left: 20px;
      padding-right: 20px;
    }
    .chatbot-page-title {
      font-size: 1.25rem;
      font-weight: 600;
      color: var(--secondary-purple);
      padding-bottom: 0.25rem;
      padding-left: 5px;
      padding-right: 5px;
    }
    .chatbot-page-subtitle {
      font-size: 1.75rem;
      padding-left: 5px;
      padding-right: 5px;
    }
    .chatbot-page-text {
      font-size: 1.25rem;
      margin-bottom: 1rem;
      margin-top: 1rem;
      padding-left: 10px;
      padding-right: 10px;
    }
    /* chatbot end */
      .logo {
    width:  6.75rem;
    height: 5.75rem;
    margin-left: 2rem;
    margin-top: -0.5rem;
  }
  .dataHub-logos {
    max-width: 6rem !important;
    max-height: 35rem !important;
    position: absolute;
    top: 0;
    margin-top: 1rem;
  }
  .monitor-config {
    display: none!important;
  }
  body > div:nth-child(1) > button {
    display: none!important;
  }
  .pages {
    margin-bottom: 0.1rem;
    margin-right: 2rem;
}
.indexer-title {
  text-align: center!important;
  font-weight: 400;
  font-size: 1.75rem;
  padding-top: 2rem;
  color: var(--text-purple);
  
  margin-top: 6rem;
}
.indexer-subtitle {
  text-align: center!important;
  font-weight: 400;
  font-size: 1.5rem;
  color: var(--text-purple);
  
  margin-top: 1rem;
  margin-right: 2rem;
  margin-left: 2rem;
}
.indexer {
  z-index: 999;
  position: absolute;
  left: 0%;
  top: 50%;
}

.curator {
  z-index: 999;
  position: absolute;
  text-align: center!important;
  top: 50%;
}

.map, .tokens, .rewards, .uncollected-rewards {
  margin-top: 2rem;
  width: 90%;
  height: 14rem;
  margin-left: 5%;
  box-shadow: #4D1D86 4px 5px 10px 3px;
}

.b-map {
  margin-top: 2rem;
  margin-left: 1rem;
  width: 800px;
  height: 3000px;
  text-align: center!important;
  box-shadow: #4D1D86 4px 5px 10px 3px;
}

.c-map {
  margin-top: 2rem;
  margin-left: 1rem;
  width: 800px;
  height: 1600px;
  text-align: center!important;
  box-shadow: #4D1D86 4px 5px 10px 3px;
}

.d-map {
  margin-top: -3rem;
  margin-left: 3rem;
  width: 800px;
  height: 3800px;
  text-align: center!important;
  box-shadow: #4D1D86 4px 5px 10px 3px;
}

.rewards-per-hour {
  margin-top: 2rem;
  padding-bottom: 2rem;
  width: 90%;
  height: 14rem;
  margin-left: 5%;
  box-shadow: #4D1D86 4px 5px 10px 3px;
}

.indexers {
  padding-left: 1rem;
  padding-left: 1rem;
  color: grey;
  font-size:1.25rem;
  margin-right: 1rem;
}
.indexers:link{
  color: grey;
  cursor: pointer;
  font-size:1.25rem;
  margin-right: 1rem;
}
.indexers:visited {
  color: grey;
  cursor: pointer;
  font-size:1.25rem;
  margin-right: 1rem;
}
.indexers.active,
.indexers:focus {
  border-bottom: 0rem solid var(--hover-purple);
  cursor: pointer;
  font-size:1.25rem;
  margin-right: 1rem;
}
.indexers:hover {
  color: var(--text-purple);
  transform: scale(1.05);
  cursor: pointer;
  font-size:1.25rem;
  margin-right: 1rem;
}
.indexer-link {
  color: white;
}
.indexer-link:link {
  color: white;
  cursor: pointer;
}
.indexer-link:visited {
  color: var(--text-purple);
  cursor: pointer;
}
.indexer-link.active,
.indexer-link:focus {
  border-bottom: 0rem solid var(--text-purple);
  cursor: pointer;
}
.indexer-link:hover {
  color: var(--text-purple);
  transform: scale(1.05);
  cursor: pointer;
}
  .cookie-page-text {
    padding-top: 1rem;
    font-size: 1.25rem;
    margin-bottom: 1rem;
    padding-left: 20px;
    padding-right: 20px;
  }
  .cookies-video {
    padding-top: 2rem;
  }
  .cookie-button {
    z-index: 9999;
    position: absolute;
  }

  body > div:nth-child(1) > button {
    z-index: 10000;
    font-size: 3rem;
    position: absolute;
    padding: auto;
    margin-top: -95px;
    width: 45px;
    height: 45px;
    margin-left: 85%;
    background-color: transparent;
    border: none;
    color: var(--text-purple);
  }
  .extra-data {
    margin-top: 2rem;
    font-size: 1.25rem;
    text-align: center;
    color: var(--text-purple);
   
  }
  .extra-data2 {
    font-size: 1.25rem;
    text-align: center;
    color: var(--text-purple);
   
  }
  .data-link {
    color: grey;
  }
  .data-link:link {
    color: grey;
    cursor: pointer;
  }
  .data-link:visited {
    color: grey;
    cursor: pointer;
  }
  .data-link.active,
  .data-link:focus {
    border-bottom: 0rem solid white;
    cursor: pointer;
  }
  .data-link:hover {
    color: white;
    transform: scale(1.05);
    cursor: pointer;
  }
  .question-bank-container {
    background: var(--background);
    color: var(--text-purple);
    width: 100%;
    margin: auto;
    margin-top: 150px;
    margin-bottom: 50px;
    padding-left: 50px;
    padding-right: 50px;
    text-align: center;
  }
  .about-page-container {
    background: var(--background);
    color: var(--text-purple);
    width: 100%;
    margin: auto;
    margin-top: 150px;
    margin-bottom: 50px;
    padding-left: 50px;
    padding-right: 50px;
    text-align: center;
  }
  .QB-text-container {
    background: white;
    border-radius: 1rem;
    padding: 30px;
    color: var(--secondary-purple);
  }
  .text-container {
    background: white;
    border-radius: 1rem;
    padding: 30px;
    color: var(--secondary-purple);
  }
  .about-hr {
    border: 1.5px solid var(--background);
    color: var(--background);
    border-radius: 5px;
    width: 35%;
    margin: auto;
    margin-bottom: 1rem;
    box-shadow: 0 0 5px 1px var(--background);
  }
  .about-page-title {
    font-size: 2rem;
    font-weight: 600;
    color: var(--secondary-purple);
    padding-bottom: 3rem;
  }
  .about-page-subtitle {
    font-size: 1.75rem;
  }
  .about-page-text {
    font-size: 1.25rem;
    margin-bottom: 1rem;
  }
  .graphiql-link {
    color: var(--secondary-purple);
  }
  .graphiql-link:link {
    color: var(--secondary-purple);
    cursor: pointer;
  }
  .graphiql-link:visited {
    color: var(--secondary-purple);
    cursor: pointer;
  }
  .graphiql-link.active,
  .graphiql-link:focus {
    border-bottom: 0rem solid var(--hover-purple);
    cursor: pointer;
  }
  .graphiql-link:hover {
    color: var(--primary-purple);
    transform: scale(1.05);
    cursor: pointer;
  }
  .cookies-policy-container {
    background: white;
    width: 90%;
    padding-bottom: 1rem;
    margin: auto;
    border-radius: 2rem;
    margin-top: 150px;
    margin-bottom: 50px;
  }
  .termly-cookie-preference-button {
   display: none;
  }

  .privacy-policy-container {
    background: white;
    width: 90%;
    padding-bottom: 1rem;
    margin: auto;
    border-radius: 2rem;
    margin-top: 150px;
    margin-bottom: 50px;
  }
  .privacy-policy {
    margin-top: 110px;
    font-weight: 700;
    font-size: 1.1rem;
    text-align: center;
    background-color: var(--background);
    color: var(--secondary-purple);
  }
  .privacy-policy-link,
  .about {
    padding-left: 1rem;
    padding-left: 1rem;
    color: grey;
  }
  .privacy-policy-link:link,
  .about:link {
    color: grey;
    cursor: pointer;
  }
  .privacy-policy-link:visited,
  .about:visited {
    color: grey;
    cursor: pointer;
  }
  .privacy-policy-link.active,
  .privacy-policy-link:focus,
  .about.active,
  .about:focus {
    border-bottom: 0rem solid var(--hover-purple);
    cursor: pointer;
  }
  .privacy-policy-link:hover,
  .about:hover {
    color: var(--text-purple);
    transform: scale(1.05);
    cursor: pointer;
  }
  .subscribeNote {
    margin-left: -15rem;
    width: 30rem;
    font-size: 0.75rem;
    color: white;
  }
/* loader new start  */
.loader:empty {
  position: absolute;
  top: calc(50% + 5.5rem);
  left: calc(50% + 2em);
  width: 4em;
  height: 4em;
  border: 0.35em solid white;
  border-left: 0.35em solid #10034B;
  border-radius: 50%;
  animation: load8 0.8s infinite linear;
}
.loadingTitle {
  position: absolute;
  top: 17rem;
  left: calc(50% - 3em);
  font-size: 2rem;
  color: #10034B;
  -webkit-box-reflect:below 0px linear-gradient(transparent, #0005);
}
.loaderLogo {
  position: absolute;
  top: 4rem;
  left: calc(50% - 8em);
  width: 15em;
  height: 12em;
}
@keyframes load8 {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
/* loader new end  */
  .C {
    font-size: 1rem;
  }
  .logo {
    width:  6.75rem;
    height: 5.75rem;
    margin-left: 2rem;
    margin-top: -0.5rem;
  }
  .data-logo {
    max-width: 1rem !important;
    height: 1rem !important;
    position: absolute;
  }
  .brand-text {
    color: var(--text-purple);
    display: inline;
    margin-left: 0rem;
    letter-spacing: 0.0375rem;
    font-size: 1.25rem;
  }
  .header-text {
    font-weight: 400;
    font-size: 1.75rem;
    margin-left: 5rem;
    margin-top: 105px;
    color: var(--text-purple);

  }
  .header-sub-text {
    font-family: Euclid Circular A;
    font-weight: 400;
    font-size: 1.25rem;
    text-align: center!important;
    margin: auto!important;
    width: 90%;
    color: var(--text-purple);
    display: block;
  
  }
  nav a.TGN {
    display: none;
  }
  .navbar-nav > li > a.TGN:link {
    display: none;
  }
  .navbar-nav > li > a.TGN:visited {
    display: none;
  }
  .navbar-nav > li > a.TGN.active,
  .navbar-nav > li > a.TGN:focus {
    display: none;
  }
  .navbar-nav > li > a.TGN:hover {
    display: none;
  }
  nav a.nav-link {
    font-family: Euclid Circular A;
    font-weight: 600;
    font-size: 1.15rem;
    color: var(--text-purple);
  }
  .navbar-nav > li > a {
    border-bottom: 0.01875rem solid var(--transparent);
    padding: 0;
  }
  .navbar-nav > li > a:link {
    color: var(--hover-purple);
  }
  .navbar-nav > li > a:visited {
    color: var(--text-purple);
  }
  .navbar-nav > li > a.active,
  .navbar-nav > li > a:focus {
    border-bottom: 0.01875rem solid var(--hover-purple);
    padding: 0;
    transition: all 0.5s ease-in-out;
  }
  .navbar-nav > li > a:hover {
    border-bottom: 0.01875rem solid var(--hover-purple);
    padding: 0;
    color: var(--hover-purple);
    transform: scale(1.05);
  }
  .line {
    margin-left: 1rem;
  }
  .links {
    text-align: right;
    padding-right: 0;
    margin: auto;
    padding: auto;
  }
  .dropdown {
    margin-left: auto;
    padding: auto;
  }
  .bar {
    margin-bottom: 0.1rem;
    margin-right: 2rem;
  }
  .line {
    margin-bottom: 0.1rem;
    margin-right: 2rem;
  }
  .about {
    margin-left: auto;
    margin-right: 0.8rem;
  }
  .menu-icon {
    color: var(--secondary-purple);
  }
  .main-info {
    background-image: url("TopBackground.svg");
    background-repeat: no-repeat;
    background-size: 100% auto;
    width: 100%;
    height: 100%;
    margin-top: 90px;
  }
  .community-links {
    margin-left: 4rem;
    padding-top: 1rem;
    position: absolute;
    z-index: 999;
  }
  .telegram-icon,
  .twitter-icon,
  .reddit-icon,
  .youtube-icon {
    display: inline;
    padding: 1rem;
    transition: all 0.5s ease-in-out;
    z-index: 999;
  }
  .icon:hover {
    color: var(--hover-purple);
    transform: scale(1.15);
  }
  .icon-youtube:hover {
    color: var(--text-purple);
    transform: scale(1.1);
  }
  .news-title {
    text-shadow: 2.25px 2.25px 3px var(--hover-purple);
    color: var(--text-purple);
    text-align: center;
    margin-top: 1rem;
    margin-left: -18rem;
    margin-bottom: 1rem;
    font-size: 1.5rem;
  }
  .newsGraph1,
  .newsGraph2,
  .newsGraph3 {
    margin-bottom: 1rem;
  }
  .youtube {
    z-index: 999;
    display: inline;
    position: absolute;
    text-align: center;
    color: var(--text-purple);
  }
  span.anchor {
    display: block;
    visibility: hidden;
    position: relative;
    padding-top: 28%;
  }
  .activeDelegators,
  .holders,
  .curatorCount,
  .subgraphCount {
    font-weight: 400;
    color: var(--text-purple);
    letter-spacing: 0.0375rem;
  }
  .hr {
    border: 3px solid var(--hr);
    border-radius: 5px;
    width: 90%;
    margin: auto;
    box-shadow: 0 0 10px 3px var(--hover-purple);
    position: relative;
    margin-top: 2.5rem;
  }
  .hr2 {
    border: 3px solid var(--hr);
    border-radius: 5px;
    width: 90%;
    margin: auto;
    box-shadow: 0 0 10px 3px var(--hover-purple);
    position: relative;
    margin-top: 1.5rem;
  }
  .video {
    position: absolute;
    box-shadow: #4D1D86 4px 8px 10px 0;
    left: 52%;
    top: 0%;
  }
  .g-ytsubscribe {
    position: absolute;
    font-weight: 400;
    margin: 0;
    left: 80%;
    top: 41%;
  }
  .data-title {
    letter-spacing: 0.0375rem;
    font-weight: 400;
    color: var(--text-purple);
    font-size: 3.5rem;
    text-align: center;
    position: relative;
  }
  .chartsTitle {
    letter-spacing: 0.0375rem;
    font-weight: 400;
    color: var(--text-purple);
    font-size: 3.5rem;
    text-align: center;

    position: relative;
  }
  .chartsTitle2 {
    letter-spacing: 0.0375rem;
    font-weight: 400;
    color: var(--text-purple);
    font-size: 3.5rem;
    text-align: center;
  
  }
  .newsGraph {
    width: 100%;
    box-shadow: #4D1D86 4px 5px 10px 3px;
  }
  .chartImg {
    width: 100%;
    box-shadow: #4D1D86 6px 6px 10px 3px;
    margin: auto;
  }
  .center {
    margin: auto;
  }
  .line1 {
    margin-top: 5rem;
  }
  .chartImg1 {
    text-align: center;
    padding-top: -7rem;
    padding-bottom: 1rem;
    width: 100%;
    margin-top: -200px;
    margin-bottom: -200px;
  }
  .chartImg2,
  .chartImg3,
  .chartImg4,
  .chartImg5 {
    text-align: center;
    padding-top: 1rem;
    padding-bottom: 1rem;
    width: 100%;
    margin-top: -200px;
    margin-bottom: -200px;
  }
  .chartImg6 {
    text-align: center;
    padding-top: 1rem;
    width: 100%;
  
  }
  .reveal {
    position: relative;
    transform: translateY(50px);
    opacity: 0;
    transition: 0.75s all ease;
  }
  .reveal.active {
    transform: translateY(0);
    opacity: 1;
  }
  .email {
    color: var(--hover-purple);
    font-weight: 500;
  }
  .footerLink {
    color: var(--hover-purple);
  }
  .footerLink:link {
    color: var(--hover-purple);
    cursor: pointer;
  }
  .footerLink:visited {
    color: var(--hover-purple);
    cursor: pointer;
  }
  .footerLink.active,
  .footerLink:focus {
    border-bottom: 0rem solid var(--hover-purple);
    cursor: pointer;
  }
  .footerLink:hover {
    color: var(--text-purple);
    transform: scale(1.05);
    cursor: pointer;
  }
  .footer {
    margin-bottom: -1rem;
    padding: 1rem;
    font-weight: 700;
    margin-top: 1rem;
    font-size: 1.1rem;
    text-align: center;
    background-color: var(--primary-purple);
    color: var(--secondary-purple);
  }
  .tsparticles {
    display: none;
  }
  .rotate-position,
  .about-rotate-position,
  .phone,
  .about-phone,
  .message,
  .about-message, .home-message {
    display: none;
  }
  .adminHeader-text {
    font-weight: 400;
    font-size: 1.5rem;
    text-align: center;
    margin: auto;
    padding-top: 1rem;
    color: var(--text-purple);
    
    margin-top: 6rem;
  }
  .adminHeader-text2 {
    font-weight: 400;
    font-size: 1.5rem;
    text-align: center;
    margin: auto;
    color: var(--text-purple);
    
    margin-top: 2rem;
  }
  .adminSubheader-text {
    font-weight: 400;
    font-size: 1rem;
    margin-left: 3rem;
    color: var(--text-purple);
    
    margin-top: 1rem;
  }
  .adminSubheader-text2 {
    font-weight: 400;
    font-size: 1rem;
    margin-left: 3rem;
    margin-right: 3rem;
    color: var(--text-purple);
  
    margin-top: 1rem;
  }
  .note {
    text-decoration: underline;
  }

  .adminHr2 {
    border: 3px solid var(--hr);
    border-radius: 5px;
    width: 70%;
    margin: auto;
    box-shadow: 0 0 10px 3px var(--hover-purple);
    margin-top: 1rem;
    margin-left: 1.9rem;
  }

 .admin-video {
    position: absolute;
    left: 48%;
    top: 20%;
    width: 10%;
    height: 10%;
  }

  .adminChartsTitle {
    letter-spacing: 0.0375rem;
    font-weight: 400;
    color: var(--text-purple);
    font-size: 2rem;
    text-align: center;
    padding-top: 1rem;
    text-shadow: 2.5px 2.5px 4px var(--hover-purple);
    position: relative;
  }
  .adminChartsTitle2 {
    letter-spacing: 0.0375rem;
    font-weight: 400;
    color: var(--text-purple);
    font-size: 2rem;
    margin-top: 1rem;
    text-align: center;
    text-shadow: 2.5px 2.5px 4px var(--hover-purple);
  }
}
/* *************************************************************************
****************************************************************************
***************************************************************************/
/* *************************************************************************
****************************************************************************
***************************************************************************/
@media screen and (min-width: 915px) and (max-width: 916px) {
  .flashcards-title {
margin-top: 120px!important;
}
  .hide-line {
    color: transparent!important;
    display: block!important;
  }
  .Quiz-button {
    margin-bottom: 2rem;
  }
  .quiz-title {
    margin-top: -60px;
  }
  .quiz-button-position {
    padding-top: 2rem;
    margin: auto;
    text-align: center;
    margin-bottom: 2rem;
  }
  
  .final-exam-question {
      font-size: 1.3rem;
      text-align: center;
      color: #d8d2e4;
      /* remove for mobile */
      margin-top: 0rem;
      /* remove for mobile */
  }
  
  .final-exam-answer-form {
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin-top: 0rem;
    columns: 1!important;
    width: 100%!important;
  }
  
  .form-check-label {
    font-size: 20px;
    text-align: center;
    height: 110%;
  }
  .quiz-question {
    font-size: 1.3rem;
    padding-bottom: 2rem;
  text-align: center;
  width: 80%;
  margin: auto;
    text-align: center;
    color: #d8d2e4;
  }
  .quiz-question-container {
    margin-top: 20px;
    width: 100%;
  }

  .quiz-answer-form {
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin-top: 0rem;
    columns: 1!important;
    width: 100%!important;
  }

  .quiz-position {
    margin-top: -10px;
  }
  .mobile-portrait {
    display: none;
    }
  .adminHeader-text {
    font-weight: 400;
    font-size: 1.5rem;
    text-align: center;
    margin: auto;
    padding-top: 1rem;
    color: var(--text-purple);
    
    margin-top: 6rem;
  }
  .adminHeader-text2 {
    font-weight: 400;
    font-size: 1.5rem;
    text-align: center;
    margin: auto;
    color: var(--text-purple);
    
    margin-top: 2rem;
  }
  .adminSubheader-text {
    font-weight: 400;
    font-size: 1rem;
    margin-left: 3rem;
    color: var(--text-purple);
    
    margin-top: 1rem;
  }
  .adminSubheader-text2 {
    font-weight: 400;
    font-size: 1rem;
    margin-left: 3rem;
    margin-right: 3rem;
    color: var(--text-purple);

    margin-top: 1rem;
  }
  .monitor-line-carousel {
    display: none;
  }
  
  .monitor-bar-carousel {
   display: none;
  }
  
  .monitor-other-carousel {
    display: none;
  }
  
  .monitor-other-charts-position {
    display: none;
  }
  .mobile-bar-carousel {
    width: 80%;
  height: 500px;
  margin: auto auto;
  padding-top: -1rem;
  padding-bottom: -5rem;
  margin-bottom: -6rem;
  overflow: hidden;
  }
  .mobile-line-carousel {
    width: 80%;
    height: 500px;
    margin: auto auto;
    padding-top: 0rem;
    overflow: hidden;
  }

  .mobile-line-pos {
padding-top: 3rem;
  }
  .more-data-hr {
    margin-top: 1rem!important;
  }
  .more-data-title {
    margin-bottom: -12rem!important;
  }
  .mobile-other-carousel {
    width: 110%;
    height: 550px;
    margin: auto auto;
    margin-left: -2.5rem;
    padding-top: 1rem!important;
    overflow: hidden;
  }
  .map {
    width: 100%;
    margin-left: 5%;
    box-shadow: #4D1D86 4px 5px 10px 3px;
  }
  .holders-pos {
    margin-top: -8rem;
    margin-left: 1%;
    padding-bottom: 2rem;
  }
  .css-1fzpoyk {
    width: 100%;
    padding-top: 1rem;
  }

  .extra-data {
    margin-left: 2rem;
    margin-right: 2rem;
    font-size: 1.25rem;
    text-align: center;
    color: var(--text-purple);
  }

  .extra-data2 {
    
    font-size: 1.25rem;
    text-align: center;
    color: var(--text-purple);
  }
  .data-title {
    letter-spacing: 0.0375rem;
    font-weight: 400;
    color: var(--text-purple);
    font-size: 2rem;
    text-align: center;
    position: relative;
  }
  .chartsTitle {
    letter-spacing: 0.0375rem;
    font-weight: 400;
    color: var(--text-purple);
    font-size: 2rem;
    text-align: center;
    position: relative;
    margin-bottom: -13rem;
  }
  .chartsTitle2 {
    letter-spacing: 0.0375rem;
    font-weight: 400;
    color: var(--text-purple);
    font-size: 2rem;
    text-align: center;
    margin-bottom: -10rem;
  }
  .hr {
    border: 3px solid var(--hr);
    border-radius: 5px;
    width: 90%;
    margin: auto;
    box-shadow: 0 0 10px 3px var(--hover-purple);
    position: relative;
    margin-top: 3rem;
  }
  .hr2 {
    border: 3px solid var(--hr);
    border-radius: 5px;
    width: 90%;
    margin: auto;
    box-shadow: 0 0 10px 3px var(--hover-purple);
    position: relative;
    margin-top: 3rem;
  }
 
  .move-up {
    margin-top: -45rem;
  }
.hide-me {
  display: none;
}
  .css-1qzevvg img {
    height: 100%;
    margin-top: -10rem;
    text-align: center;
    margin-left: -1rem;
    z-index: 999999;
    position: relative;
}

/* above me */

   /* chatbot height start */
.react-chatbot-kit-chat-inner-container {
  background-color: var(--text-purple)!important;
  height: 290px!important;
}
.react-chatbot-kit-chat-message-container {
  height: 250px!important;
}
  /* chatbot height end */
 /* dune start */
 .GRTDailyTransactionsPosition {
  margin-left: -15px!important;
 }
 .HoldersGraph {
  background-image: linear-gradient(90deg, #1B0D33, #D8D2E4, #D8D2E4,#D8D2E4, #D8D2E4,#D8D2E4,#D8D2E4,#D8D2E4,#D8D2E4,#D8D2E4,#D8D2E4,#D8D2E4,#D8D2E4,#D8D2E4,#D8D2E4,#D8D2E4,#D8D2E4,#D8D2E4,#1B0D33);
  margin-bottom: -36px!important;
  margin-left: -10px!important;
  padding-left: 20px!important;
  padding-right: 20px!important;
  height: 80px!important;
  width: 100%!important;
}
.HoldersGraphBottom {
  background-image: linear-gradient(90deg, #1B0D33, #D8D2E4,#D8D2E4,#D8D2E4,#D8D2E4,#D8D2E4,#D8D2E4,#D8D2E4,#D8D2E4,#D8D2E4,#D8D2E4,#D8D2E4,#D8D2E4,#D8D2E4,#D8D2E4,#D8D2E4,#D8D2E4,#D8D2E4, #1B0D33);
  padding-left: 20px!important;
  margin-left: -10px!important;
  padding-right: 20px!important;
  height: 80px!important;
  width: 100%!important;
}
/* dune end */
  /* Navbar */
.navbar {
  background-color: var(--primary-purple);
  color: var(--text-purple);
  z-index: 9999;
  position: absolute;
}
.new-link {
  margin-right: 2rem;
  margin-top: -0.25rem;
}
.mobile-nav-link {
  padding-right: 2rem!important;
}
.mobile-nav-pages {
  color: var(--hover-purple)!important;
  font-size: 1.5rem!important;
}
.mobile-nav-dropdown {
  margin-right: 2rem!important;
}
.mobile-nav-pages:link {
  cursor: pointer;
}
.mobile-nav-pages:visited {
  cursor: pointer;
}
.mobile-nav-pages.active,
.mobile-nav-pages:focus {
  cursor: pointer;
  color: var(--text-purple)!important;
}
.mobile-nav-pages:hover {
  color: var(--text-purple)!important;
  cursor: pointer;
}

.offcanvas {
  width: 40%!important;
  background-color: var(--background)!important;
}
.offcanvas-body {
  width: 100%!important;
  margin-top: -3rem!important;
  opacity: 0.9;
}
.offcanvas-button {
  margin-right: 2rem;
  border: none!important;
}
.btn-close {
  z-index: 10000!important;
  margin-right: 3.25rem;
  margin-top: 2rem;
}
.mobile-dropdown {
  margin-left: 3.5rem!important;
}
.dropdown-toggle, .export {
  text-align: right;
  margin: auto;
  font-size: 1.5rem!important;
  color: var(--hover-purple)!important;
}

.dropdown-toggle:hover, .export:hover {
  text-align: right;
  margin: auto;
  font-size: 1.5rem!important;
  color: white!important;
}
  .chatbot-footer-bottom {
    margin-bottom: -80px!important;
   }
  .chatbot-link-logo{
    margin-top: 4rem;
    z-index: 9998;
    position: fixed;
    max-width: 4.5rem;
    background: white;
    -moz-border-radius: 50px;
    -webkit-border-radius: 50px;
    border-radius: 50px;
    padding: -1rem;
right: 2%;
    border: 3px var(--secondary-purple) solid;
    box-shadow: 0 0 0 var(--hover-purple);
    animation: pulse 2s infinite;
  }
  .advocate-link-logo{
    margin-top: 8rem;
    z-index: 9998;
    position: fixed;
    max-width: 4.5rem;
    background: white;
    -moz-border-radius: 50px;
    -webkit-border-radius: 50px;
    border-radius: 50px;
    padding: -1rem;
right: 2%;
    border: 3px var(--secondary-purple) solid;
    box-shadow: 0 0 0 var(--hover-purple);
    animation: pulse 2s infinite;
  }
  @-webkit-keyframes pulse {
    0% {
      -webkit-box-shadow: 0 0 0 0  var(--text-purple);
    }
    70% {
        -webkit-box-shadow: 0 0 0 15px rgba(244,157,22, 0);
    }
    100% {
        -webkit-box-shadow: 0 0 0 0 rgba(244,157,22, 0);
    }
  }
  @keyframes pulse {
    0% {
      -moz-box-shadow: 0 0 0 0  var(--text-purple);
      box-shadow: 0 0 0 0  var(--text-purple);
    }
    70% {
        -moz-box-shadow: 0 0 0 15px rgba(244,157,22, 0);
        box-shadow: 0 0 0 15px rgba(244,157,22, 0);
    }
    100% {
        -moz-box-shadow: 0 0 0 0 rgba(244,157,22, 0);
        box-shadow: 0 0 0 0 rgba(244,157,22, 0);
    }
  }
  
  .chatbot-link-export{
    margin-top: 9rem;
    z-index: 9998;
    position: fixed;
    max-width: 4.5rem;
    background: white;
    -moz-border-radius: 50px;
    -webkit-border-radius: 50px;
    border-radius: 50px;
    padding: -1rem;
    right: 2%;
    border: 3px var(--secondary-purple) solid;
    box-shadow: 0 0 0 var(--hover-purple);
    animation: pulse 2s infinite;
  }

/* Chatbot */
.feedback-text {
  margin-top: 2rem;
}
.feedback-button {
  border: 2px var(--primary-purple) solid!important;
  background-color: var(--text-purple)!important;
  color: var(--primary-purple)!important;
  padding: auto!important;
  margin: auto!important;
}

  .feedback-button:link {
    cursor: pointer;
  }
  .feedback-button:visited {
    cursor: pointer;
  }
  .feedback-button.active,
  .feedback-button:focus {
    cursor: pointer;
  }
  .feedback-button:hover {
    background-color: var(--background)!important;
    color: var(--text-purple)!important;
    transform: scale(1.015);
    cursor: pointer;
  }
  .feedback-button-text {
    font-size: 1.5rem;
  }

 /* chatbot height start */
 .react-chatbot-kit-chat-inner-container {
  background-color: var(--text-purple)!important;
  height: 290px!important;
}
.react-chatbot-kit-chat-message-container {
  height: 250px!important;
}
  /* chatbot height end */

.react-chatbot-kit-chat-header {
  font-size: 1.1rem!important;
  color: var(--text-purple)!important;
  background-color: var(--primary-purple)!important;
  padding: 0rem!important;
}

.react-chatbot-kit-chat-input-container {
  background-color:var(--secondary-purple)!important;
  border: 1px solid var(--primary-purple)!important;
}

.react-chatbot-kit-chat-btn-send {
  background-color:var(--primary-purple)!important;
}

.react-chatbot-kit-chat-btn-send-icon:hover {
transform: scale(1.25);
}

.react-chatbot-kit-chat-bot-message-container {
  width: 100%;
}

.react-chatbot-kit-chat-bot-avatar-container {
}

.react-chatbot-kit-chat-bot-avatar-icon {
}

.react-chatbot-kit-chat-bot-avatar-letter {
  display: none;
}
.react-chatbot-kit-chat-container {
  width: 98%!important;
  text-align: center;
  margin: auto;
}

.react-chatbot-kit-chat-bot-avatar-container {
  width: 3.5rem!important;
  height: 3.5rem!important;
  background-color: white!important;
  border: 2px solid var(--primary-purple);
  background-image: url('./GRTDataHubChatBot.png')!important;
  background-repeat: no-repeat!important;
  background-size: 3rem;
  background-position-x: 50%;
  background-position-y: 20%;
}

.react-chatbot-kit-chat-bot-message {
  text-align: left!important;
  width: 100%!important;
  color: var(--text-purple)!important;
  background-color: #0C0B23!important;
  font-size: 1rem!important;
}

.react-chatbot-kit-chat-bot-message-arrow {
  color: #0C0B23!important;
  border-right: 8px solid #0C0B23!important;
}
  .indexer-table-header {
    text-align: center;
    margin-left: 1rem;
  }
  .indexer-table {
    text-align: center;
  }
  .chatbot-data {
    display: inline!important;
  }
  .telegramActiveDelegationCount {
    max-width: 92%;
    margin: auto; 
    text-align: center;
    margin-top: 0.5%;
    }
    .top-telegram {
      margin-top: 0.25rem;
    }
    .chatbot-top {
      margin-top: 5rem;
    }
    .chatbot-page-container {
      background: var(--background);
      color: var(--text-purple);
      width: 100%;
      margin: auto;
      margin-top: 150px;
      padding-left: 10px;
      padding-right: 10px;
      text-align: center;
    }
    .chatbot-text-container {
      background: whitesmoke;
      border-radius: 1rem;
      color: var(--secondary-purple);
      margin: auto!important;
      padding-bottom: 10px;
      padding-top: 10px;
      text-align: center!important;
    }
    .chatbot-page-title {
      font-size: 1.25rem;
      font-weight: 600;
      color: var(--secondary-purple);
      padding-bottom: 0.25rem;
      padding-left: 5px;
      padding-right: 5px;
    }
    .chatbot-page-subtitle {
      font-size: 1.75rem;
      padding-left: 5px;
      padding-right: 5px;
    }
    .chatbot-page-text {
      font-size: 1.25rem;
      margin-bottom: 1rem;
      margin-top: 1rem;
      padding-left: 10px;
      padding-right: 10px;
    }
    /* chatbot end */
    .logo {
      width:  6.75rem;
      height: 5.75rem;
      margin-left: 2rem;
      margin-top: -0.5rem;
    }
  .dataHub-logos {
    max-width: 6rem !important;
    max-height: 35rem !important;
    position: absolute;
    top: 0;
    margin-top: 1rem;
  }
  .monitor-config {
    display: none!important;
  }
  .navbar-toggler {
    display: none;
  }
  .pages {
    margin-bottom: 0.1rem;
    margin-right: 2rem;
}
.indexer-title {
  text-align: center!important;
  font-weight: 400;
  font-size: 1.75rem;
  padding-top: 2rem;
  color: var(--text-purple);
  
  margin-top: 6rem;
}
.indexer-subtitle {
  text-align: center!important;
  font-weight: 400;
  font-size: 1.5rem;
  color: var(--text-purple);
  
  margin-top: 1rem;
  margin-right: 2rem;
  margin-left: 2rem;
}
.indexer {
  z-index: 999;
  position: absolute;
  left: 0%;
  top: 50%;
}

.curator {
  z-index: 999;
  position: absolute;
  text-align: center!important;
  top: 50%;
}
.tokens, .rewards, .uncollected-rewards {
  margin-top: 2rem;
  width: 90%;
  height: 14rem;
  margin-left: 5%;
  box-shadow: #4D1D86 4px 5px 10px 3px;
}

.b-map {
  margin-top: 2rem;
  margin-left: 1rem;
  width: 800px;
  height: 3000px;
  text-align: center!important;
  box-shadow: #4D1D86 4px 5px 10px 3px;
}

.c-map {
  margin-top: 2rem;
  margin-left: 1rem;
  width: 800px;
  height: 1600px;
  text-align: center!important;
  box-shadow: #4D1D86 4px 5px 10px 3px;
}

.d-map {
  margin-top: -3rem;
  margin-left: 3rem;
  width: 800px;
  height: 3800px;
  text-align: center!important;
  box-shadow: #4D1D86 4px 5px 10px 3px;
}

.rewards-per-hour {
  margin-top: 2rem;
  padding-bottom: 2rem;
  width: 90%;
  height: 14rem;
  margin-left: 5%;
  box-shadow: #4D1D86 4px 5px 10px 3px;
}

.indexers {
  padding-left: 1rem;
  padding-left: 1rem;
  color: grey;
  font-size:1.25rem;
  margin-right: 1rem;
}
.indexers:link{
  color: grey;
  cursor: pointer;
  font-size:1.25rem;
  margin-right: 1rem;
}
.indexers:visited {
  color: grey;
  cursor: pointer;
  font-size:1.25rem;
  margin-right: 1rem;
}
.indexers.active,
.indexers:focus {
  border-bottom: 0rem solid var(--hover-purple);
  cursor: pointer;
  font-size:1.25rem;
  margin-right: 1rem;
}
.indexers:hover {
  color: var(--text-purple);
  transform: scale(1.05);
  cursor: pointer;
  font-size:1.25rem;
  margin-right: 1rem;
}
.indexer-link {
  color: white;
}
.indexer-link:link {
  color: white;
  cursor: pointer;
}
.indexer-link:visited {
  color: var(--text-purple);
  cursor: pointer;
}
.indexer-link.active,
.indexer-link:focus {
  border-bottom: 0rem solid var(--text-purple);
  cursor: pointer;
}
.indexer-link:hover {
  color: var(--text-purple);
  transform: scale(1.05);
  cursor: pointer;
}
  .cookie-page-text {
    padding-top: 1rem;
    font-size: 1.25rem;
    margin-bottom: 1rem;
    padding-left: 20px;
    padding-right: 20px;
  }
  .cookies-video {
    padding-top: 2rem;
  }
  .cookie-button {
    z-index: 9999;
    position: absolute;
  }

  body > div:nth-child(1) > button {
    z-index: 10000;
    font-size: 3rem;
    position: absolute;
    padding: auto;
    margin-top: -95px;
    width: 45px;
    height: 45px;
    margin-left: 85%;
    background-color: transparent;
    border: none;
    color: var(--text-purple);
  }
 
  .extra-data2 {
    font-size: 1.25rem;
    text-align: center;
    color: var(--text-purple);
   
  }
  .data-link {
    color: grey;
  }
  .data-link:link {
    color: grey;
    cursor: pointer;
  }
  .data-link:visited {
    color: grey;
    cursor: pointer;
  }
  .data-link.active,
  .data-link:focus {
    border-bottom: 0rem solid white;
    cursor: pointer;
  }
  .data-link:hover {
    color: white;
    transform: scale(1.05);
    cursor: pointer;
  }
  .question-bank-container {
    background: var(--background);
    color: var(--text-purple);
    width: 100%;
    margin: auto;
    margin-top: 150px;
    margin-bottom: 50px;
    padding-left: 50px;
    padding-right: 50px;
    text-align: center;
  }
  .about-page-container {
    background: var(--background);
    color: var(--text-purple);
    width: 100%;
    margin: auto;
    margin-top: 150px;
    margin-bottom: 50px;
    padding-left: 50px;
    padding-right: 50px;
    text-align: center;
  }
  .QB-text-container {
    background: white;
    border-radius: 1rem;
    padding: 30px;
    color: var(--secondary-purple);
  }
  .text-container {
    background: white;
    border-radius: 1rem;
    padding: 30px;
    color: var(--secondary-purple);
  }
  .about-hr {
    border: 1.5px solid var(--background);
    color: var(--background);
    border-radius: 5px;
    width: 35%;
    margin: auto;
    margin-bottom: 1rem;
    box-shadow: 0 0 5px 1px var(--background);
  }
  .about-page-title {
    font-size: 2rem;
    font-weight: 600;
    color: var(--secondary-purple);
    padding-bottom: 2rem;
  }
  .about-page-subtitle {
    font-size: 1.75rem;
  }
  .about-page-text {
    font-size: 1.25rem;
    margin-bottom: 1rem;
  }
  .graphiql-link {
    color: var(--secondary-purple);
  }
  .graphiql-link:link {
    color: var(--secondary-purple);
    cursor: pointer;
  }
  .graphiql-link:visited {
    color: var(--secondary-purple);
    cursor: pointer;
  }
  .graphiql-link.active,
  .graphiql-link:focus {
    border-bottom: 0rem solid var(--hover-purple);
    cursor: pointer;
  }
  .graphiql-link:hover {
    color: var(--primary-purple);
    transform: scale(1.05);
    cursor: pointer;
  }
  .cookies-policy-container {
    background: white;
    width: 90%;
    padding-bottom: 1rem;
    margin: auto;
    border-radius: 2rem;
    margin-top: 150px;
    margin-bottom: 50px;
  }
  .termly-cookie-preference-button {
   display: none;
  }

  .privacy-policy-container {
    background: white;
    width: 90%;
    padding-bottom: 1rem;
    margin: auto;
    border-radius: 2rem;
    margin-top: 150px;
    margin-bottom: 50px;
  }
  .privacy-policy {
    margin-top: 110px;
    font-weight: 700;
    font-size: 1.1rem;
    text-align: center;
    background-color: var(--background);
    color: var(--secondary-purple);
  }
  .privacy-policy-link,
  .about {
    padding-left: 1rem;
    padding-left: 1rem;
    color: grey;
  }
  .privacy-policy-link:link,
  .about:link {
    color: grey;
    cursor: pointer;
  }
  .privacy-policy-link:visited,
  .about:visited {
    color: grey;
    cursor: pointer;
  }
  .privacy-policy-link.active,
  .privacy-policy-link:focus,
  .about.active,
  .about:focus {
    border-bottom: 0rem solid var(--hover-purple);
    cursor: pointer;
  }
  .privacy-policy-link:hover,
  .about:hover {
    color: var(--text-purple);
    transform: scale(1.05);
    cursor: pointer;
  }
  .subscribeNote {
    margin-left: -15rem;
    width: 30rem;
    font-size: 0.75rem;
    color: white;
  }
 /* loader new start  */
 .loader:empty {
  position: absolute;
  top: calc(50% + 5.5rem);
  left: calc(50% + 2em);
  width: 4em;
  height: 4em;
  border: 0.35em solid white;
  border-left: 0.35em solid #10034B;
  border-radius: 50%;
  animation: load8 0.8s infinite linear;
}
.loadingTitle {
  position: absolute;
  top: 17rem;
  left: calc(50% - 3em);
  font-size: 2rem;
  color: #10034B;
  -webkit-box-reflect:below 0px linear-gradient(transparent, #0005);
}
.loaderLogo {
  position: absolute;
  top: 4rem;
  left: calc(50% - 8em);
  width: 15em;
  height: 12em;
}
@keyframes load8 {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
/* loader new end  */
  .C {
    font-size: 1rem;
  }
  .logo {
    width:  6.75rem;
    height: 5.75rem;
    margin-left: 2rem;
    margin-top: -0.5rem;
  }
  .data-logo {
    max-width: 1rem !important;
    height: 1rem !important;
    position: absolute;
  }
  .brand-text {
    color: var(--text-purple);
    display: inline;
    margin-left: 0rem;
    letter-spacing: 0.0375rem;
    font-size: 1.25rem;
  }
  .header-text {
    font-weight: 400;
    font-size: 1.75rem;
    margin-left: 5rem;
    margin-top: 105px;
    color: var(--text-purple);
  
  }
  .header-sub-text {
    font-weight: 400;
    font-size: 1.25rem;
    text-align: center!important;
    margin: auto!important;
    width: 90%;
    color: var(--text-purple);
   
    display: block;
  }
  nav a.TGN {
    display: none;
  }
  .navbar-nav > li > a.TGN:link {
    display: none;
  }
  .navbar-nav > li > a.TGN:visited {
    display: none;
  }
  .navbar-nav > li > a.TGN.active,
  .navbar-nav > li > a.TGN:focus {
    display: none;
  }
  .navbar-nav > li > a.TGN:hover {
    display: none;
  }
  nav a.nav-link {
    font-weight: 400;
    font-size: 1.25rem;
    color: var(--text-purple);
  }
  .navbar-nav > li > a {
    border-bottom: 0.01875rem solid var(--transparent);
    padding: 0;
  }
  .navbar-nav > li > a:link {
    color: var(--hover-purple);
  }
  .navbar-nav > li > a:visited {
    color: var(--text-purple);
  }
  .navbar-nav > li > a.active,
  .navbar-nav > li > a:focus {
    border-bottom: 0.01875rem solid var(--hover-purple);
    padding: 0;
    transition: all 0.5s ease-in-out;
  }
  .navbar-nav > li > a:hover {
    border-bottom: 0.01875rem solid var(--hover-purple);
    padding: 0;
    color: var(--hover-purple);
    transform: scale(1.05);
  }
  .line {
    margin-left: 1rem;
  }
  .links {
    text-align: right;
    padding-right: 0;
    margin: auto;
    padding: auto;
  }
  .dropdown {
    margin-left: auto;
    padding: auto;
  }
  .bar {
    margin-bottom: 0.25rem;
    margin-right: 2rem;
  }
  .line {
    margin-bottom: 0.25rem;
    margin-right: 2rem;
  }
  .about {
    margin-left: auto;
    margin-right: 0.8rem;
  }
  .menu-icon {
    color: var(--secondary-purple);
  }
  .main-info {
    background-image: url("TopBackground.svg");
    background-repeat: no-repeat;
    background-size: 100% auto;
    width: 100%;
    height: 100%;
    margin-top: 90px;
  }
  .community-links {
    margin-left: 4rem;
    padding-top: 1rem;
    position: absolute;
    z-index: 999;
  }
  .telegram-icon,
  .twitter-icon,
  .reddit-icon,
  .youtube-icon {
    display: inline;
    padding: 1rem;
    transition: all 0.5s ease-in-out;
    z-index: 999;
  }
  .icon:hover {
    color: var(--hover-purple);
    transform: scale(1.15);
  }
  .icon-youtube:hover {
    color: var(--text-purple);
    transform: scale(1.1);
  }
  .news-title {
    text-shadow: 2.25px 2.25px 3px var(--hover-purple);
    color: var(--text-purple);
    text-align: center;
    margin-top: 1rem;
    margin-left: -25rem;
    margin-bottom: 1rem;
    font-size: 2rem;
  }
  .newsGraph1,
  .newsGraph2,
  .newsGraph3 {
    margin-bottom: 1rem;
  }
  .youtube {
    z-index: 999;
    display: inline;
    position: absolute;
    text-align: center;
    color: var(--text-purple);
  }
  span.anchor {
    display: block;
    visibility: hidden;
    position: relative;
    padding-top: 20%;
  }
  .activeDelegators,
  .holders,
  .curatorCount,
  .subgraphCount {
    font-weight: 400;
    color: var(--text-purple);
    letter-spacing: 0.0375rem;
  }

  .video {
    position: absolute;
    box-shadow: #4D1D86 4px 8px 10px 0;
    left: 55%;
    top: 0%;
  }
  .g-ytsubscribe {
    position: absolute;
    font-family: Euclid Circular A;
    font-weight: 400;
    margin: 0;
    left: 78%;
    top: 41%;
  }

  .newsGraph {
    width: 100%;
    box-shadow: #4D1D86 4px 5px 10px 3px;
  }
  .chartImg {
    width: 100%;
    box-shadow: #4D1D86 6px 6px 10px 3px;
    margin: auto;
  }
  .center {
    margin: auto;
  }
  .line1 {
    margin-top: 5rem;
  }

  .chartImg6 {
    text-align: center;
    padding-top: 1rem;
    width: 100%;
  
  }
  .reveal {
    position: relative;
    transform: translateY(50px);
    opacity: 0;
    transition: 0.75s all ease;
  }
  .reveal.active {
    transform: translateY(0);
    opacity: 1;
  }
  .email {
    color: var(--hover-purple);
    font-weight: 500;
  }
  .footerLink {
    color: var(--hover-purple);
  }
  .footerLink:link {
    color: var(--hover-purple);
    cursor: pointer;
  }
  .footerLink:visited {
    color: var(--hover-purple);
    cursor: pointer;
  }
  .footerLink.active,
  .footerLink:focus {
    border-bottom: 0rem solid var(--hover-purple);
    cursor: pointer;
  }
  .footerLink:hover {
    color: var(--text-purple);
    transform: scale(1.05);
    cursor: pointer;
  }
  .footer {
    margin-bottom: -1rem;
    padding: 1rem;
    font-weight: 700;
    margin-top: 1rem;
    font-size: 1.1rem;
    text-align: center;
    background-color: var(--primary-purple);
    color: var(--secondary-purple);
  }
  .tsparticles {
    position: absolute;
    height: 100%;
    width: 100%;
  }
  .rotate-position,
  .about-rotate-position,
  .phone,
  .about-phone,
  .message,
  .about-message, .home-message {
    display: none;
  }

  .note {
    text-decoration: underline;
  }


 .admin-video {
    position: absolute;
    left: 48%;
    top: 20%;
    width: 10%;
    height: 10%;
  }
}
/* *************************************************************************
****************************************************************************
***************************************************************************/
/* *************************************************************************
****************************************************************************
***************************************************************************/
@media screen and (min-width: 890px) and (max-width: 914px) {
  .flashcards-title {
margin-top: 120px!important;
}
  .hide-line {
    color: transparent!important;
    display: block!important;
  }
  .Quiz-button {
    margin-bottom: 2rem;
  }
  .quiz-title {
    margin-top: -60px;
  }
  .quiz-button-position {
    padding-top: 2rem;
    margin: auto;
    text-align: center;
    margin-bottom: 2rem;
  }
  
  .final-exam-question {
      font-size: 1.3rem;
      text-align: center;
      color: #d8d2e4;
      /* remove for mobile */
      margin-top: 0rem;
      /* remove for mobile */
  }
  
  .final-exam-answer-form {
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin-top: 0rem;
    columns: 1!important;
    width: 100%!important;
  }
  
  .form-check-label {
    font-size: 20px;
    text-align: center;
    height: 110%;
  }
  .quiz-question {
    font-size: 1.3rem;
    padding-bottom: 2rem;
  text-align: center;
  width: 80%;
  margin: auto;
    text-align: center;
    color: #d8d2e4;
  }
  .quiz-question-container {
    margin-top: 20px;
    width: 100%;
  }

  .quiz-answer-form {
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin-top: 0rem;
    columns: 1!important;
    width: 100%!important;
  }

  .quiz-position {
    margin-top: -10px;
  }
  .mobile-portrait {
    display: none;
    }
  .adminHeader-text {
    font-weight: 400;
    font-size: 1.5rem;
    text-align: center;
    margin: auto;
    padding-top: 1rem;
    color: var(--text-purple);
    
    margin-top: 6rem;
  }
  .adminHeader-text2 {
    font-weight: 400;
    font-size: 1.5rem;
    text-align: center;
    margin: auto;
    color: var(--text-purple);
    
    margin-top: 2rem;
  }
  .adminSubheader-text {
    font-weight: 400;
    font-size: 1rem;
    margin-left: 3rem;
    color: var(--text-purple);
    
    margin-top: 1rem;
  }
  .adminSubheader-text2 {
    font-weight: 400;
    font-size: 1rem;
    margin-left: 3rem;
    margin-right: 3rem;
    color: var(--text-purple);

    margin-top: 1rem;
  }
  .monitor-line-carousel {
    display: none;
  }
  
  .monitor-bar-carousel {
   display: none;
  }
  
  .monitor-other-carousel {
    display: none;
  }
  
  .monitor-other-charts-position {
    display: none;
  }
  .mobile-bar-carousel {
    width: 80%;
  height: 500px;
  margin: auto auto;
  padding-top: -1rem;
  padding-bottom: -5rem;
  margin-bottom: -6rem;
  overflow: hidden;
  }
  .mobile-line-carousel {
    width: 80%;
    height: 500px;
    margin: auto auto;
    padding-top: 0rem;
    overflow: hidden;
  }

  .mobile-line-pos {
padding-top: 3rem;
  }
  .more-data-hr {
    margin-top: 1rem!important;
  }
  .more-data-title {
    margin-bottom: -12rem!important;
  }
  .mobile-other-carousel {
    width: 110%;
    height: 550px;
    margin: auto auto;
    margin-left: -2.5rem;
    padding-top: 1rem!important;
    overflow: hidden;
  }
  .map {
    width: 100%;
    margin-left: 5%;
    box-shadow: #4D1D86 4px 5px 10px 3px;
  }
  .holders-pos {
    margin-top: -8rem;
    margin-left: 1%;
    padding-bottom: 2rem;
  }
  .css-1fzpoyk {
    width: 100%;
    padding-top: 1rem;
  }

  .extra-data {
    margin-left: 2rem;
    margin-right: 2rem;
    font-size: 1.25rem;
    text-align: center;
    color: var(--text-purple);
  }

  .extra-data2 {
    
    font-size: 1.25rem;
    text-align: center;
    color: var(--text-purple);
  }
  .data-title {
    letter-spacing: 0.0375rem;
    font-weight: 400;
    color: var(--text-purple);
    font-size: 2rem;
    text-align: center;
    position: relative;
  }
  .chartsTitle {
    letter-spacing: 0.0375rem;
    font-weight: 400;
    color: var(--text-purple);
    font-size: 2rem;
    text-align: center;
    position: relative;
    margin-bottom: -13rem;
  }
  .chartsTitle2 {
    letter-spacing: 0.0375rem;
    font-weight: 400;
    color: var(--text-purple);
    font-size: 2rem;
    text-align: center;
    margin-bottom: -10rem;
  }
  .hr {
    border: 3px solid var(--hr);
    border-radius: 5px;
    width: 90%;
    margin: auto;
    box-shadow: 0 0 10px 3px var(--hover-purple);
    position: relative;
    margin-top: 3rem;
  }
  .hr2 {
    border: 3px solid var(--hr);
    border-radius: 5px;
    width: 90%;
    margin: auto;
    box-shadow: 0 0 10px 3px var(--hover-purple);
    position: relative;
    margin-top: 3rem;
  }
 
  .move-up {
    margin-top: -45rem;
  }
.hide-me {
  display: none;
}
  .css-1qzevvg img {
    height: 100%;
    margin-top: -10rem;
    text-align: center;
    margin-left: -1rem;
    z-index: 999999;
    position: relative;
}

/* above me */
  .mobile-dropdown {
    margin-left: 1.5rem!important;
  }
  .offcanvas-body {
    width: 100%!important;
    opacity: 0.9;
  }
  .offcanvas {
    width: 40%!important;
    background-color: var(--background)!important;
  }
 
   /* chatbot height start */
.react-chatbot-kit-chat-inner-container {
  background-color: var(--text-purple)!important;
  height: 290px!important;
}
.react-chatbot-kit-chat-message-container {
  height: 250px!important;
}
  /* chatbot height end */
 /* dune start */
.GRTDailyTransactionsPosition {
  margin-bottom: 1rem!important;
  margin-left: -15px!important;
 }
 .HoldersGraph {
  background-image: linear-gradient(90deg, #1B0D33, #D8D2E4, #D8D2E4,#D8D2E4, #D8D2E4,#D8D2E4,#D8D2E4,#D8D2E4,#D8D2E4,#D8D2E4,#D8D2E4,#D8D2E4,#D8D2E4,#D8D2E4,#D8D2E4,#D8D2E4,#D8D2E4,#D8D2E4,#1B0D33);
  margin-bottom: -36px!important;
  margin-left: -10px!important;
  padding-left: 20px!important;
  padding-right: 20px!important;
  height: 80px!important;
  width: 100%!important;
}
.HoldersGraphBottom {
  background-image: linear-gradient(90deg, #1B0D33, #D8D2E4,#D8D2E4,#D8D2E4,#D8D2E4,#D8D2E4,#D8D2E4,#D8D2E4,#D8D2E4,#D8D2E4,#D8D2E4,#D8D2E4,#D8D2E4,#D8D2E4,#D8D2E4,#D8D2E4,#D8D2E4,#D8D2E4, #1B0D33);
  padding-left: 20px!important;
  margin-left: -10px!important;
  padding-right: 20px!important;
  height: 80px!important;
  width: 100%!important;
}
  .chatbot-footer-bottom {
    margin-bottom: -80px!important;
   }
  .chatbot-link-logo{
    margin-top: 4rem;
    z-index: 9998;
    position: fixed;
    max-width: 5rem;
    background: white;
    -moz-border-radius: 50px;
    -webkit-border-radius: 50px;
    border-radius: 50px;
    padding: -1rem;
    right: 2%;
    border: 3px var(--secondary-purple) solid;
    box-shadow: 0 0 0 var(--hover-purple);
    animation: pulse 2s infinite;
  }
  .advocate-link-logo{
    margin-top: 8rem;
    z-index: 9998;
    position: fixed;
    max-width: 5rem;
    background: white;
    -moz-border-radius: 50px;
    -webkit-border-radius: 50px;
    border-radius: 50px;
    padding: -1rem;
    right: 2%;
    border: 3px var(--secondary-purple) solid;
    box-shadow: 0 0 0 var(--hover-purple);
    animation: pulse 2s infinite;
  }
  @-webkit-keyframes pulse {
    0% {
      -webkit-box-shadow: 0 0 0 0  var(--text-purple);
    }
    70% {
        -webkit-box-shadow: 0 0 0 15px rgba(244,157,22, 0);
    }
    100% {
        -webkit-box-shadow: 0 0 0 0 rgba(244,157,22, 0);
    }
  }
  @keyframes pulse {
    0% {
      -moz-box-shadow: 0 0 0 0  var(--text-purple);
      box-shadow: 0 0 0 0  var(--text-purple);
    }
    70% {
        -moz-box-shadow: 0 0 0 15px rgba(244,157,22, 0);
        box-shadow: 0 0 0 15px rgba(244,157,22, 0);
    }
    100% {
        -moz-box-shadow: 0 0 0 0 rgba(244,157,22, 0);
        box-shadow: 0 0 0 0 rgba(244,157,22, 0);
    }
  }
  
  .chatbot-link-export{
    margin-top: 7rem;
    z-index: 9998;
    position: fixed;
    max-width: 5rem;
    background: white;
    -moz-border-radius: 50px;
    -webkit-border-radius: 50px;
    border-radius: 50px;
    padding: -1rem;
    right: 2%;
    border: 3px var(--secondary-purple) solid;
    box-shadow: 0 0 0 var(--hover-purple);
    animation: pulse 2s infinite;
  }
  /* Chatbot */
.feedback-text {
  margin-top: 2rem;
}
.feedback-button {
  border: 2px var(--primary-purple) solid!important;
  background-color: var(--text-purple)!important;
  color: var(--primary-purple)!important;
  padding: auto!important;
  margin: auto!important;
}

  .feedback-button:link {
    cursor: pointer;
  }
  .feedback-button:visited {
    cursor: pointer;
  }
  .feedback-button.active,
  .feedback-button:focus {
    cursor: pointer;
  }
  .feedback-button:hover {
    background-color: var(--background)!important;
    color: var(--text-purple)!important;
    transform: scale(1.015);
    cursor: pointer;
  }
  .feedback-button-text {
    font-size: 1.5rem;
  }

.react-chatbot-kit-chat-inner-container {
  background-color: var(--text-purple)!important;
}

.react-chatbot-kit-chat-header {
  font-size: 1.1rem!important;
  color: var(--text-purple)!important;
  background-color: var(--primary-purple)!important;
  padding: 0rem!important;
}

.react-chatbot-kit-chat-input-container {
  background-color:var(--secondary-purple)!important;
  border: 1px solid var(--primary-purple)!important;
}

.react-chatbot-kit-chat-btn-send {
  background-color:var(--primary-purple)!important;
}

.react-chatbot-kit-chat-btn-send-icon:hover {
transform: scale(1.25);
}

.react-chatbot-kit-chat-bot-message-container {
  width: 100%;
}

.react-chatbot-kit-chat-bot-avatar-container {
}

.react-chatbot-kit-chat-bot-avatar-icon {
}

.react-chatbot-kit-chat-bot-avatar-letter {
  display: none;
}
.react-chatbot-kit-chat-container {
  width: 98%!important;
  text-align: center;
  margin: auto;
}

.react-chatbot-kit-chat-bot-avatar-container {
  width: 3.5rem!important;
  height: 3.5rem!important;
  background-color: white!important;
  border: 2px solid var(--primary-purple);
  background-image: url('./GRTDataHubChatBot.png')!important;
  background-repeat: no-repeat!important;
  background-size: 3rem;
  background-position-x: 50%;
  background-position-y: 20%;
}

.react-chatbot-kit-chat-bot-message {
  text-align: left!important;
  width: 100%!important;
  color: var(--text-purple)!important;
  background-color: #0C0B23!important;
  font-size: 1rem!important;
}

.react-chatbot-kit-chat-bot-message-arrow {
  color: #0C0B23!important;
  border-right: 8px solid #0C0B23!important;
}
  .indexer-table-header {
    text-align: center;
    margin-left: 1rem;
  }
  .indexer-table {
    text-align: center;
  }
  .chatbot-data {
    display: inline!important;
  }
  .telegramActiveDelegationCount {
    max-width: 92%;
    margin: auto; 
    text-align: center;
    margin-top: 0.5%;
    }
    .top-telegram {
      margin-top: 0.25rem;
    }
    .chatbot-top {
      margin-top: 5rem;
    }
    .chatbot-page-container {
      background: var(--background);
      color: var(--text-purple);
      width: 100%;
      margin: auto;
      margin-top: 120px;
      padding-left: 10px;
      padding-right: 10px;
      text-align: center;
    }
    .chatbot-text-container {
      background: whitesmoke;
      border-radius: 1rem;
      color: var(--secondary-purple);
      margin: auto!important;
      padding-bottom: 10px;
      padding-top: 10px;
      text-align: center!important;
      padding-left: 20px;
      padding-right: 20px;
    }
    .chatbot-page-title {
      font-size: 1.25rem;
      font-weight: 600;
      color: var(--secondary-purple);
      padding-bottom: 0.25rem;
      padding-left: 5px;
      padding-right: 5px;
    }
    .chatbot-page-subtitle {
      font-size: 1.75rem;
      padding-left: 5px;
      padding-right: 5px;
    }
    .chatbot-page-text {
      font-size: 1.25rem;
      margin-bottom: 1rem;
      margin-top: 1rem;
      padding-left: 10px;
      padding-right: 10px;
    }
    /* chatbot end */
      .logo {
    width:  7rem;
    height: 5.75rem;
    margin-left: 2rem;
    margin-top: -0.5rem;
  }
  .dataHub-logos {
    max-width: 6rem !important;
    max-height: 35rem !important;
    position: absolute;
    top: 0;
    margin-top: 1rem;
  }
  .monitor-config {
    display: none!important;
  }
  body > div:nth-child(1) > button {
    display: none!important;
  }
  .pages {
    margin-bottom: 0.1rem;
    margin-right: 2rem;
}
.indexer-title {
  text-align: center!important;
  font-weight: 400;
  font-size: 1.75rem;
  padding-top: 2rem;
  color: var(--text-purple);
  
  margin-top: 6rem;
}
.indexer-subtitle {
  text-align: center!important;
  font-weight: 400;
  font-size: 1.5rem;
  color: var(--text-purple);
  
  margin-top: 1rem;
  margin-right: 2rem;
  margin-left: 2rem;
}
.indexer {
  z-index: 999;
  position: absolute;
  left: 0%;
  top: 50%;
}

.curator {
  z-index: 999;
  position: absolute;
  text-align: center!important;
  top: 50%;
}

.tokens, .rewards, .uncollected-rewards {
  margin-top: 2rem;
  width: 90%;
  height: 14rem;
  margin-left: 5%;
  box-shadow: #4D1D86 4px 5px 10px 3px;
}

.b-map {
  margin-top: 2rem;
  margin-left: 1rem;
  width: 800px;
  height: 3000px;
  text-align: center!important;
  box-shadow: #4D1D86 4px 5px 10px 3px;
}

.c-map {
  margin-top: 2rem;
  margin-left: 1rem;
  width: 800px;
  height: 1600px;
  text-align: center!important;
  box-shadow: #4D1D86 4px 5px 10px 3px;
}

.d-map {
  margin-top: -3rem;
  margin-left: 3rem;
  width: 800px;
  height: 3800px;
  text-align: center!important;
  box-shadow: #4D1D86 4px 5px 10px 3px;
}

.rewards-per-hour {
  margin-top: 2rem;
  padding-bottom: 2rem;
  width: 90%;
  height: 14rem;
  margin-left: 5%;
  box-shadow: #4D1D86 4px 5px 10px 3px;
}

.indexers {
  padding-left: 1rem;
  padding-left: 1rem;
  color: grey;
  font-size:1.25rem;
  margin-right: 1rem;
}
.indexers:link{
  color: grey;
  cursor: pointer;
  font-size:1.25rem;
  margin-right: 1rem;
}
.indexers:visited {
  color: grey;
  cursor: pointer;
  font-size:1.25rem;
  margin-right: 1rem;
}
.indexers.active,
.indexers:focus {
  border-bottom: 0rem solid var(--hover-purple);
  cursor: pointer;
  font-size:1.25rem;
  margin-right: 1rem;
}
.indexers:hover {
  color: var(--text-purple);
  transform: scale(1.05);
  cursor: pointer;
  font-size:1.25rem;
  margin-right: 1rem;
}
.indexer-link {
  color: white;
}
.indexer-link:link {
  color: white;
  cursor: pointer;
}
.indexer-link:visited {
  color: var(--text-purple);
  cursor: pointer;
}
.indexer-link.active,
.indexer-link:focus {
  border-bottom: 0rem solid var(--text-purple);
  cursor: pointer;
}
.indexer-link:hover {
  color: var(--text-purple);
  transform: scale(1.05);
  cursor: pointer;
}
  .cookie-page-text {
    padding-top: 1rem;
    font-size: 1.25rem;
    margin-bottom: 1rem;
    padding-left: 20px;
    padding-right: 20px;
  }
  .cookies-video {
    padding-top: 2rem;
  }
  .cookie-button {
    z-index: 9999;
    position: absolute;
  }

  body > div:nth-child(1) > button {
    z-index: 10000;
    font-size: 3rem;
    position: absolute;
    padding: auto;
    margin-top: -95px;
    width: 45px;
    height: 45px;
    margin-left: 85%;
    background-color: transparent;
    border: none;
    color: var(--text-purple);
  }

  .data-link {
    color: grey;
  }
  .data-link:link {
    color: grey;
    cursor: pointer;
  }
  .data-link:visited {
    color: grey;
    cursor: pointer;
  }
  .data-link.active,
  .data-link:focus {
    border-bottom: 0rem solid white;
    cursor: pointer;
  }
  .data-link:hover {
    color: white;
    transform: scale(1.05);
    cursor: pointer;
  }
  .question-bank-container {
    background: var(--background);
    color: var(--text-purple);
    width: 100%;
    margin: auto;
    margin-top: 150px;
    margin-bottom: 50px;
    padding-left: 50px;
    padding-right: 50px;
    text-align: center;
  }
  .about-page-container {
    background: var(--background);
    color: var(--text-purple);
    width: 100%;
    margin: auto;
    margin-top: 150px;
    margin-bottom: 50px;
    padding-left: 50px;
    padding-right: 50px;
    text-align: center;
  }
  .QB-text-container {
    background: white;
    border-radius: 1rem;
    padding: 30px;
    color: var(--secondary-purple);
  }
  .text-container {
    background: white;
    border-radius: 1rem;
    padding: 30px;
    color: var(--secondary-purple);
  }
  .about-hr {
    border: 1.5px solid var(--background);
    color: var(--background);
    border-radius: 5px;
    width: 35%;
    margin: auto;
    margin-bottom: 1rem;
    box-shadow: 0 0 5px 1px var(--background);
  }
  .about-page-title {
    font-size: 2rem;
    font-weight: 600;
    color: var(--secondary-purple);
    padding-bottom: 3rem;
  }
  .about-page-subtitle {
    font-size: 1.75rem;
  }
  .about-page-text {
    font-size: 1.25rem;
    margin-bottom: 1rem;
  }
  .graphiql-link {
    color: var(--secondary-purple);
  }
  .graphiql-link:link {
    color: var(--secondary-purple);
    cursor: pointer;
  }
  .graphiql-link:visited {
    color: var(--secondary-purple);
    cursor: pointer;
  }
  .graphiql-link.active,
  .graphiql-link:focus {
    border-bottom: 0rem solid var(--hover-purple);
    cursor: pointer;
  }
  .graphiql-link:hover {
    color: var(--primary-purple);
    transform: scale(1.05);
    cursor: pointer;
  }
  .cookies-policy-container {
    background: white;
    width: 90%;
    padding-bottom: 1rem;
    margin: auto;
    border-radius: 2rem;
    margin-top: 150px;
    margin-bottom: 50px;
  }
  .termly-cookie-preference-button {
   display: none;
  }

  .privacy-policy-container {
    background: white;
    width: 90%;
    padding-bottom: 1rem;
    margin: auto;
    border-radius: 2rem;
    margin-top: 150px;
    margin-bottom: 50px;
  }
  .privacy-policy {
    margin-top: 110px;
    font-weight: 700;
    font-size: 1.1rem;
    text-align: center;
    background-color: var(--background);
    color: var(--secondary-purple);
  }
  .privacy-policy-link,
  .about {
    padding-left: 1rem;
    padding-left: 1rem;
    color: grey;
  }
  .privacy-policy-link:link,
  .about:link {
    color: grey;
    cursor: pointer;
  }
  .privacy-policy-link:visited,
  .about:visited {
    color: grey;
    cursor: pointer;
  }
  .privacy-policy-link.active,
  .privacy-policy-link:focus,
  .about.active,
  .about:focus {
    border-bottom: 0rem solid var(--hover-purple);
    cursor: pointer;
  }
  .privacy-policy-link:hover,
  .about:hover {
    color: var(--text-purple);
    transform: scale(1.05);
    cursor: pointer;
  }
  .subscribeNote {
    margin-left: -15rem;
    width: 30rem;
    font-size: 0.75rem;
    color: white;
  }
/* loader new start  */
.loader:empty {
  position: absolute;
  top: calc(50% + 5.5rem);
  left: calc(50% + 2em);
  width: 4em;
  height: 4em;
  border: 0.35em solid white;
  border-left: 0.35em solid #10034B;
  border-radius: 50%;
  animation: load8 0.8s infinite linear;
}
.loadingTitle {
  position: absolute;
  top: 17rem;
  left: calc(50% - 3em);
  font-size: 2rem;
  color: #10034B;
  -webkit-box-reflect:below 0px linear-gradient(transparent, #0005);
}
.loaderLogo {
  position: absolute;
  top: 4rem;
  left: calc(50% - 8em);
  width: 15em;
  height: 12em;
}
@keyframes load8 {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
/* loader new end  */
  .C {
    font-size: 1rem;
  }
  .logo {
    width:  6.75rem;
    height: 5.75rem;
    margin-left: 2rem;
    margin-top: -0.5rem;
  }
  .data-logo {
    max-width: 1rem !important;
    height: 1rem !important;
    position: absolute;
  }
  .brand-text {
    color: var(--text-purple);
    display: inline;
    margin-left: 0rem;
    letter-spacing: 0.0375rem;
    font-size: 1.25rem;
  }
  .header-text {
    font-weight: 400;
    font-size: 1.75rem;
    margin-left: 5rem;
    margin-top: 105px;
    color: var(--text-purple);

  }
  .header-sub-text {
    font-family: Euclid Circular A;
    font-weight: 400;
    font-size: 1.25rem;
    text-align: center!important;
    margin: auto!important;
    width: 90%;
    color: var(--text-purple);
    display: block;
 
  }
  nav a.TGN {
    display: none;
  }
  .navbar-nav > li > a.TGN:link {
    display: none;
  }
  .navbar-nav > li > a.TGN:visited {
    display: none;
  }
  .navbar-nav > li > a.TGN.active,
  .navbar-nav > li > a.TGN:focus {
    display: none;
  }
  .navbar-nav > li > a.TGN:hover {
    display: none;
  }
  nav a.nav-link {
    font-family: Euclid Circular A;
    font-weight: 600;
    font-size: 1.15rem;
    color: var(--text-purple);
  }
  .navbar-nav > li > a {
    border-bottom: 0.01875rem solid var(--transparent);
    padding: 0;
  }
  .navbar-nav > li > a:link {
    color: var(--hover-purple);
  }
  .navbar-nav > li > a:visited {
    color: var(--text-purple);
  }
  .navbar-nav > li > a.active,
  .navbar-nav > li > a:focus {
    border-bottom: 0.01875rem solid var(--hover-purple);
    padding: 0;
    transition: all 0.5s ease-in-out;
  }
  .navbar-nav > li > a:hover {
    border-bottom: 0.01875rem solid var(--hover-purple);
    padding: 0;
    color: var(--hover-purple);
    transform: scale(1.05);
  }
  .line {
    margin-left: 1rem;
  }
  .links {
    text-align: right;
    padding-right: 0;
    margin: auto;
    padding: auto;
  }
  .dropdown {
    margin-left: auto;
    padding: auto;
  }
  .bar {
    margin-bottom: 0.1rem;
    margin-right: 2rem;
  }
  .line {
    margin-bottom: 0.1rem;
    margin-right: 2rem;
  }
  .about {
    margin-left: auto;
    margin-right: 0.8rem;
  }
  .menu-icon {
    color: var(--secondary-purple);
  }
  .main-info {
    background-image: url("TopBackground.svg");
    background-repeat: no-repeat;
    background-size: 100% auto;
    width: 100%;
    height: 100%;
    margin-top: 90px;
  }
  .community-links {
    margin-left: 4rem;
    padding-top: 1rem;
    position: absolute;
    z-index: 999;
  }
  .telegram-icon,
  .twitter-icon,
  .reddit-icon,
  .youtube-icon {
    display: inline;
    padding: 1rem;
    transition: all 0.5s ease-in-out;
    z-index: 999;
  }
  .icon:hover {
    color: var(--hover-purple);
    transform: scale(1.15);
  }
  .icon-youtube:hover {
    color: var(--text-purple);
    transform: scale(1.1);
  }
  .news-title {
    text-shadow: 2.25px 2.25px 3px var(--hover-purple);
    color: var(--text-purple);
    text-align: center;
    margin-top: 1rem;
    margin-left: -18rem;
    margin-bottom: 1rem;
    font-size: 1.5rem;
  }
  .newsGraph1,
  .newsGraph2,
  .newsGraph3 {
    margin-bottom: 1rem;
  }
  .youtube {
    z-index: 999;
    display: inline;
    position: absolute;
    text-align: center;
    color: var(--text-purple);
  }
  span.anchor {
    display: block;
    visibility: hidden;
    position: relative;
    padding-top: 28%;
  }
  .activeDelegators,
  .holders,
  .curatorCount,
  .subgraphCount {
    font-weight: 400;
    color: var(--text-purple);
    letter-spacing: 0.0375rem;
  }

  .video {
    position: absolute;
    box-shadow: #4D1D86 4px 8px 10px 0;
    left: 52%;
    top: 0%;
  }
  .g-ytsubscribe {
    position: absolute;
    font-weight: 400;
    margin: 0;
    left: 80%;
    top: 41%;
  }
 
  .newsGraph {
    width: 100%;
    box-shadow: #4D1D86 4px 5px 10px 3px;
  }
  .chartImg {
    width: 100%;
    box-shadow: #4D1D86 6px 6px 10px 3px;
    margin: auto;
  }
  .center {
    margin: auto;
  }
  .line1 {
    margin-top: 5rem;
  }

  .chartImg6 {
    text-align: center;
    padding-top: 1rem;
    width: 100%;
  
  }
  .reveal {
    position: relative;
    transform: translateY(50px);
    opacity: 0;
    transition: 0.75s all ease;
  }
  .reveal.active {
    transform: translateY(0);
    opacity: 1;
  }
  .email {
    color: var(--hover-purple);
    font-weight: 500;
  }
  .footerLink {
    color: var(--hover-purple);
  }
  .footerLink:link {
    color: var(--hover-purple);
    cursor: pointer;
  }
  .footerLink:visited {
    color: var(--hover-purple);
    cursor: pointer;
  }
  .footerLink.active,
  .footerLink:focus {
    border-bottom: 0rem solid var(--hover-purple);
    cursor: pointer;
  }
  .footerLink:hover {
    color: var(--text-purple);
    transform: scale(1.05);
    cursor: pointer;
  }
  .footer {
    margin-bottom: -1rem;
    padding: 1rem;
    font-weight: 700;
    margin-top: 1rem;
    font-size: 1.1rem;
    text-align: center;
    background-color: var(--primary-purple);
    color: var(--secondary-purple);
  }
  .tsparticles {
    display: none;
  }
  .rotate-position,
  .about-rotate-position,
  .phone,
  .about-phone,
  .message,
  .about-message, .home-message {
    display: none;
  }

 
  .note {
    text-decoration: underline;
  }

 .admin-video {
    position: absolute;
    left: 48%;
    top: 20%;
    width: 10%;
    height: 10%;
  }
}
/* *************************************************************************
****************************************************************************
***************************************************************************/
/* *************************************************************************
****************************************************************************
***************************************************************************/
@media screen and (min-width: 882px) and (max-width: 889px) {
  .flashcards-title {
margin-top: 120px!important;
}
  .hide-line {
    color: transparent!important;
    display: block!important;
  }
  .Quiz-button {
    margin-bottom: 2rem;
  }
  .quiz-title {
    margin-top: -60px;
  }
  .quiz-button-position {
    padding-top: 2rem;
    margin: auto;
    text-align: center;
    margin-bottom: 2rem;
  }
  
  .final-exam-question {
      font-size: 1.3rem;
      text-align: center;
      color: #d8d2e4;
      /* remove for mobile */
      margin-top: 0rem;
      /* remove for mobile */
  }
  
  .final-exam-answer-form {
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin-top: 0rem;
    columns: 1!important;
    width: 100%!important;
  }
  
  .form-check-label {
    font-size: 20px;
    text-align: center;
    height: 110%;
  }
  .quiz-question {
    font-size: 1.3rem;
    padding-bottom: 2rem;
  text-align: center;
  width: 80%;
  margin: auto;
    text-align: center;
    color: #d8d2e4;
  }
  .quiz-question-container {
    margin-top: 20px;
    width: 100%;
  }

  .quiz-answer-form {
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin-top: 0rem;
    columns: 1!important;
    width: 100%!important;
  }

  .quiz-position {
    margin-top: -10px;
  }
  .mobile-portrait {
    display: none;
    }
  .mobile-dropdown {
    margin-left: 1.5rem!important;
  }
  .offcanvas-body {
    width: 100%!important;
    opacity: 0.9;
  }
  .offcanvas {
    width: 40%!important;
    background-color: var(--background)!important;
  }
 
   /* chatbot height start */
.react-chatbot-kit-chat-inner-container {
  background-color: var(--text-purple)!important;
  height: 290px!important;
}
.react-chatbot-kit-chat-message-container {
  height: 250px!important;
}
  /* chatbot height end */
/* dune start */
.GRTDailyTransactionsPosition {
  margin-bottom: 1rem!important;
  margin-left: -15px!important;
 }
 .HoldersGraph {
  background-image: linear-gradient(90deg, #1B0D33, #D8D2E4, #D8D2E4,#D8D2E4, #D8D2E4,#D8D2E4,#D8D2E4,#D8D2E4,#D8D2E4,#D8D2E4,#D8D2E4,#D8D2E4,#D8D2E4,#D8D2E4,#D8D2E4,#D8D2E4,#D8D2E4,#D8D2E4,#1B0D33);
  margin-bottom: -36px!important;
  margin-left: -10px!important;
  padding-left: 20px!important;
  padding-right: 20px!important;
  height: 80px!important;
  width: 100%!important;
}
.HoldersGraphBottom {
  background-image: linear-gradient(90deg, #1B0D33, #D8D2E4,#D8D2E4,#D8D2E4,#D8D2E4,#D8D2E4,#D8D2E4,#D8D2E4,#D8D2E4,#D8D2E4,#D8D2E4,#D8D2E4,#D8D2E4,#D8D2E4,#D8D2E4,#D8D2E4,#D8D2E4,#D8D2E4, #1B0D33);
  padding-left: 20px!important;
  margin-left: -10px!important;
  padding-right: 20px!important;
  height: 80px!important;
  width: 100%!important;
}
  .chatbot-footer-bottom {
    margin-bottom: -80px!important;
   }
  .chatbot-link-logo{
    margin-top: 2.5rem;
    z-index: 9998;
    position: fixed;
    max-width: 4rem;
    background: white;
    -moz-border-radius: 50px;
    -webkit-border-radius: 50px;
    border-radius: 50px;
    padding: -1rem;
   right: 2%;
    border: 3px var(--secondary-purple) solid;
    box-shadow: 0 0 0 var(--hover-purple);
    animation: pulse 2s infinite;
  }
  .advocate-link-logo{
    margin-top: 8rem;
    z-index: 9998;
    position: fixed;
    max-width: 4rem;
    background: white;
    -moz-border-radius: 50px;
    -webkit-border-radius: 50px;
    border-radius: 50px;
    padding: -1rem;
   right: 2%;
    border: 3px var(--secondary-purple) solid;
    box-shadow: 0 0 0 var(--hover-purple);
    animation: pulse 2s infinite;
  }
  @-webkit-keyframes pulse {
    0% {
      -webkit-box-shadow: 0 0 0 0  var(--text-purple);
    }
    70% {
        -webkit-box-shadow: 0 0 0 15px rgba(244,157,22, 0);
    }
    100% {
        -webkit-box-shadow: 0 0 0 0 rgba(244,157,22, 0);
    }
  }
  @keyframes pulse {
    0% {
      -moz-box-shadow: 0 0 0 0  var(--text-purple);
      box-shadow: 0 0 0 0  var(--text-purple);
    }
    70% {
        -moz-box-shadow: 0 0 0 15px rgba(244,157,22, 0);
        box-shadow: 0 0 0 15px rgba(244,157,22, 0);
    }
    100% {
        -moz-box-shadow: 0 0 0 0 rgba(244,157,22, 0);
        box-shadow: 0 0 0 0 rgba(244,157,22, 0);
    }
  }
  
  .chatbot-link-export{
    margin-top: 8rem;
    z-index: 9998;
    position: fixed;
    max-width: 4rem;
    background: white;
    -moz-border-radius: 50px;
    -webkit-border-radius: 50px;
    border-radius: 50px;
    padding: -1rem;
    right: 2%;
    border: 3px var(--secondary-purple) solid;
    box-shadow: 0 0 0 var(--hover-purple);
    animation: pulse 2s infinite;
  }

  /* Chatbot */
.feedback-text {
  margin-top: 2rem;
}
.feedback-button {
  border: 2px var(--primary-purple) solid!important;
  background-color: var(--text-purple)!important;
  color: var(--primary-purple)!important;
  padding: auto!important;
  margin: auto!important;
}

  .feedback-button:link {
    cursor: pointer;
  }
  .feedback-button:visited {
    cursor: pointer;
  }
  .feedback-button.active,
  .feedback-button:focus {
    cursor: pointer;
  }
  .feedback-button:hover {
    background-color: var(--background)!important;
    color: var(--text-purple)!important;
    transform: scale(1.015);
    cursor: pointer;
  }
  .feedback-button-text {
    font-size: 1.5rem;
  }

  /* chatbot height start */
.react-chatbot-kit-chat-inner-container {
  background-color: var(--text-purple)!important;
  height: 290px!important;
}
.react-chatbot-kit-chat-message-container {
  height: 250px!important;
}
  /* chatbot height end */
.react-chatbot-kit-chat-header {
  font-size: 1.1rem!important;
  color: var(--text-purple)!important;
  background-color: var(--primary-purple)!important;
  padding: 0rem!important;
}

.react-chatbot-kit-chat-input-container {
  background-color:var(--secondary-purple)!important;
  border: 1px solid var(--primary-purple)!important;
}

.react-chatbot-kit-chat-btn-send {
  background-color:var(--primary-purple)!important;
}

.react-chatbot-kit-chat-btn-send-icon:hover {
transform: scale(1.25);
}

.react-chatbot-kit-chat-bot-message-container {
  width: 100%;
}

.react-chatbot-kit-chat-bot-avatar-container {
}

.react-chatbot-kit-chat-bot-avatar-icon {
}

.react-chatbot-kit-chat-bot-avatar-letter {
  display: none;
}
.react-chatbot-kit-chat-container {
  width: 98%!important;
  text-align: center;
  margin: auto;
}

.react-chatbot-kit-chat-bot-avatar-container {
  width: 3.5rem!important;
  height: 3.5rem!important;
  background-color: white!important;
  border: 2px solid var(--primary-purple);
  background-image: url('./GRTDataHubChatBot.png')!important;
  background-repeat: no-repeat!important;
  background-size: 3rem;
  background-position-x: 50%;
  background-position-y: 20%;
}

.react-chatbot-kit-chat-bot-message {
  text-align: left!important;
  width: 100%!important;
  color: var(--text-purple)!important;
  background-color: #0C0B23!important;
  font-size: 1rem!important;
}

.react-chatbot-kit-chat-bot-message-arrow {
  color: #0C0B23!important;
  border-right: 8px solid #0C0B23!important;
}
  .indexer-table-header {
    text-align: center;
    margin-left: 1rem;
  }
  .indexer-table {
    text-align: center;
  }
  .chatbot-data {
    display: inline!important;
  }
  .telegramActiveDelegationCount {
    max-width: 92%;
    margin: auto; 
    text-align: center;
    margin-top: 0.5%;
    }
    .top-telegram {
      margin-top: 0.25rem;
    }
    .chatbot-top {
      margin-top: 5rem;
    }
    .chatbot-page-container {
      background: var(--background);
      color: var(--text-purple);
      width: 100%;
      margin: auto;
      margin-top: 100px;
      padding-left: 10px;
      padding-right: 10px;
      text-align: center;
    }
    .chatbot-text-container {
      background: whitesmoke;
      border-radius: 1rem;
      color: var(--secondary-purple);
      margin: auto!important;
      padding-bottom: 10px;
      padding-top: 10px;
      text-align: center!important;
      padding-left: 15px;
      padding-right: 15px;
    }
    .chatbot-page-title {
      font-size: 1.25rem;
      font-weight: 600;
      color: var(--secondary-purple);
      padding-bottom: 0.25rem;
      padding-left: 5px;
      padding-right: 5px;
    }
    .chatbot-page-subtitle {
      font-size: 1.75rem;
      padding-left: 5px;
      padding-right: 5px;
    }
    .chatbot-page-text {
      font-size: 1.25rem;
      margin-bottom: 1rem;
      margin-top: 1rem;
      padding-left: 10px;
      padding-right: 10px;
    }
    /* chatbot end */
    .logo {
      width:  6.75rem;
      height: 5.75rem;
      margin-left: 2rem;
      margin-top: -0.5rem;
    }
  .dataHub-logos {
    max-width: 6rem !important;
    max-height: 35rem !important;
    position: absolute;
    top: 0;
    margin-top: 1rem;
  }
  .monitor-config {
    display: none!important;
  }
  body > div:nth-child(1) > button {
    display: none!important;
  }
  .pages {
    margin-bottom: 0.1rem;
    margin-right: 2rem;
}
.indexer-title {
  text-align: center!important;
  font-weight: 400;
  font-size: 1.5rem;
  padding-top: 2rem;
  color: var(--text-purple);
  
  margin-top: 6rem;
}
.indexer-subtitle {
  text-align: center!important;
  font-weight: 400;
  font-size: 1.25rem;
  color: var(--text-purple);
  
  margin-top: 1rem;
  margin-right: 2rem;
  margin-left: 2rem;
}
.indexer {
  z-index: 999;
  position: absolute;
  left: 0%;
  top: 50%;
  width: 100%;
}

.curator {
  z-index: 999;
  position: absolute;
  text-align: center!important;
  top: 50%;
}

.map, .tokens, .rewards, .uncollected-rewards {
  margin-top: 2rem;
  width: 90%;
  height: 14rem;
  margin-left: 5%;
  box-shadow: #4D1D86 4px 5px 10px 3px;
}

.b-map {
  margin-top: 2rem;
  margin-left: 1rem;
  width: 800px;
  height: 3000px;
  text-align: center!important;
  box-shadow: #4D1D86 4px 5px 10px 3px;
}

.c-map {
  margin-top: 2rem;
  margin-left: 1rem;
  width: 800px;
  height: 1600px;
  text-align: center!important;
  box-shadow: #4D1D86 4px 5px 10px 3px;
}

.d-map {
  margin-top: -3rem;
  margin-left: 3rem;
  width: 800px;
  height: 3800px;
  text-align: center!important;
  box-shadow: #4D1D86 4px 5px 10px 3px;
}

.rewards-per-hour {
  margin-top: 2rem;
  padding-bottom: 2rem;
  width: 90%;
  height: 14rem;
  margin-left: 5%;
  box-shadow: #4D1D86 4px 5px 10px 3px;
}

.indexers {
  padding-left: 1rem;
  padding-left: 1rem;
  color: grey;
  font-size:1.25rem;
  margin-right: 1rem;
}
.indexers:link{
  color: grey;
  cursor: pointer;
  font-size:1.25rem;
  margin-right: 1rem;
}
.indexers:visited {
  color: grey;
  cursor: pointer;
  font-size:1.25rem;
  margin-right: 1rem;
}
.indexers.active,
.indexers:focus {
  border-bottom: 0rem solid var(--hover-purple);
  cursor: pointer;
  font-size:1.25rem;
  margin-right: 1rem;
}
.indexers:hover {
  color: var(--text-purple);
  transform: scale(1.05);
  cursor: pointer;
  font-size:1.25rem;
  margin-right: 1rem;
}
.indexer-link {
  color: white;
}
.indexer-link:link {
  color: white;
  cursor: pointer;
}
.indexer-link:visited {
  color: var(--text-purple);
  cursor: pointer;
}
.indexer-link.active,
.indexer-link:focus {
  border-bottom: 0rem solid var(--text-purple);
  cursor: pointer;
}
.indexer-link:hover {
  color: var(--text-purple);
  transform: scale(1.05);
  cursor: pointer;
}
  .cookie-page-text {
    padding-top: 1rem;
    font-size: 1.25rem;
    margin-bottom: 1rem;
    padding-left: 20px;
    padding-right: 20px;
  }
  .cookies-video {
    padding-top: 2rem;
  }
  .cookie-button {
    z-index: 9999;
    position: absolute;
  }

  body > div:nth-child(1) > button {
    z-index: 10000;
    font-size: 3rem;
    position: absolute;
    padding: auto;
    margin-top: -85px;
    width: 45px;
    height: 45px;
    margin-left: 85%;
    background-color: transparent;
    border: none;
    color: var(--text-purple);
  }
  .extra-data {
    margin-top: 2rem;
    font-size: 1.25rem;
    text-align: center;
    color: var(--text-purple);
   
  }
  .extra-data2 {
    font-size: 1.25rem;
    text-align: center;
    color: var(--text-purple);
   
  }
  .data-link {
    color: grey;
  }
  .data-link:link {
    color: grey;
    cursor: pointer;
  }
  .data-link:visited {
    color: grey;
    cursor: pointer;
  }
  .data-link.active,
  .data-link:focus {
    border-bottom: 0rem solid white;
    cursor: pointer;
  }
  .data-link:hover {
    color: white;
    transform: scale(1.05);
    cursor: pointer;
  }
  .question-bank-container {
    background: var(--background);
    color: var(--text-purple);
    width: 100%;
    margin: auto;
    margin-top: 150px;
    margin-bottom: 50px;
    padding-left: 50px;
    padding-right: 50px;
    text-align: center;
  }
  .about-page-container {
    background: var(--background);
    color: var(--text-purple);
    width: 100%;
    margin: auto;
    margin-top: 150px;
    margin-bottom: 50px;
    padding-left: 50px;
    padding-right: 50px;
    text-align: center;
  }
  .QB-text-container {
    background: white;
    border-radius: 1rem;
    padding: 30px;
    color: var(--secondary-purple);
  }
  .text-container {
    background: white;
    border-radius: 1rem;
    padding: 30px;
    color: var(--secondary-purple);
  }
  .QB-about-hr {
    border: 1.5px solid var(--background);
    color: var(--background);
    border-radius: 5px;
    width: 35%;
    margin: auto;
    margin-bottom: 1rem;
    box-shadow: 0 0 5px 1px var(--background);
  }
  .QB-about-page-title {
    font-size: 2rem;
    font-weight: 600;
    color: var(--secondary-purple);
    padding-bottom: 3rem;
  }
  .QB-about-page-subtitle {
    font-size: 1.75rem;
  }
  .QB-about-page-text {
    font-size: 1.25rem;
    margin-bottom: 1rem;
  }
  .about-hr {
    border: 1.5px solid var(--background);
    color: var(--background);
    border-radius: 5px;
    width: 35%;
    margin: auto;
    margin-bottom: 1rem;
    box-shadow: 0 0 5px 1px var(--background);
  }
  .about-page-title {
    font-size: 2rem;
    font-weight: 600;
    color: var(--secondary-purple);
    padding-bottom: 3rem;
  }
  .about-page-subtitle {
    font-size: 1.75rem;
  }
  .about-page-text {
    font-size: 1.25rem;
    margin-bottom: 1rem;
  }
  .graphiql-link {
    color: var(--secondary-purple);
  }
  .graphiql-link:link {
    color: var(--secondary-purple);
    cursor: pointer;
  }
  .graphiql-link:visited {
    color: var(--secondary-purple);
    cursor: pointer;
  }
  .graphiql-link.active,
  .graphiql-link:focus {
    border-bottom: 0rem solid var(--hover-purple);
    cursor: pointer;
  }
  .graphiql-link:hover {
    color: var(--primary-purple);
    transform: scale(1.05);
    cursor: pointer;
  }
  .cookies-policy-container {
    background: white;
    width: 90%;
    padding-bottom: 1rem;
    margin: auto;
    border-radius: 2rem;
    margin-top: 150px;
    margin-bottom: 50px;
  }
  .termly-cookie-preference-button {
   display: none;
  }

  .privacy-policy-container {
    background: white;
    width: 90%;
    padding-bottom: 1rem;
    margin: auto;
    border-radius: 2rem;
    margin-top: 150px;
    margin-bottom: 50px;
  }
  .privacy-policy {
    margin-top: 110px;
    font-weight: 700;
    font-size: 1.1rem;
    text-align: center;
    background-color: var(--background);
    color: var(--secondary-purple);
  }
  .privacy-policy-link,
  .about {
    padding-left: 1rem;
    padding-left: 1rem;
    color: grey;
  }
  .privacy-policy-link:link,
  .about:link {
    color: grey;
    cursor: pointer;
  }
  .privacy-policy-link:visited,
  .about:visited {
    color: grey;
    cursor: pointer;
  }
  .privacy-policy-link.active,
  .privacy-policy-link:focus,
  .about.active,
  .about:focus {
    border-bottom: 0rem solid var(--hover-purple);
    cursor: pointer;
  }
  .privacy-policy-link:hover,
  .about:hover {
    color: var(--text-purple);
    transform: scale(1.05);
    cursor: pointer;
  }
  .subscribeNote {
    margin-left: -15rem;
    width: 30rem;
    font-size: 0.75rem;
    color: white;
  }
  /* loader new start  */
  .loader:empty {
    position: absolute;
    top: calc(50% + 5.5rem);
    left: calc(50% + 2em);
    width: 4em;
    height: 4em;
    border: 0.35em solid white;
    border-left: 0.35em solid #10034B;
    border-radius: 50%;
    animation: load8 0.8s infinite linear;
  }
  .loadingTitle {
    position: absolute;
    top: 17rem;
    left: calc(50% - 3em);
    font-size: 2rem;
    color: #10034B;
  -webkit-box-reflect:below 0px linear-gradient(transparent, #0005);
  }
  .loaderLogo {
    position: absolute;
    top: 4rem;
    left: calc(50% - 8em);
    width: 15em;
    height: 12em;
  }
  @keyframes load8 {
    0% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(360deg);
    }
  }
  /* loader new end  */
  .C {
    font-size: 1rem;
  }
  .logo {
    width:  6.75rem;
    height: 5.75rem;
    margin-left: 2rem;
    margin-top: -0.5rem;
  }
  .data-logo {
    max-width: 1rem !important;
    height: 1rem !important;
    position: absolute;
  }
  .brand-text {
    color: var(--text-purple);
    display: inline;
    margin-left: 0rem;
    letter-spacing: 0.0375rem;
    font-size: 1.25rem;
  }
  .header-text {
    font-weight: 400;
    font-size: 1.75rem;
    margin-left: 3.25rem;
    margin-top: 105px;
    color: var(--text-purple);
   
  }
  .header-sub-text {
    font-weight: 400;
    font-size: 1.25rem;
    text-align: center!important;
    margin: auto!important;
    width: 90%;
    color: var(--text-purple);
    display: block;

  }
  nav a.TGN {
    display: none;
  }
  .navbar-nav > li > a.TGN:link {
    display: none;
  }
  .navbar-nav > li > a.TGN:visited {
    display: none;
  }
  .navbar-nav > li > a.TGN.active,
  .navbar-nav > li > a.TGN:focus {
    display: none;
  }
  .navbar-nav > li > a.TGN:hover {
    display: none;
  }
  nav a.nav-link {
    font-weight: 600;
    font-size: 1.15rem;
    color: var(--text-purple);
  }
  .navbar-nav > li > a {
    border-bottom: 0.01875rem solid var(--transparent);
    padding: 0;
  }
  .navbar-nav > li > a:link {
    color: var(--hover-purple);
  }
  .navbar-nav > li > a:visited {
    color: var(--text-purple);
  }
  .navbar-nav > li > a.active,
  .navbar-nav > li > a:focus {
    border-bottom: 0.01875rem solid var(--hover-purple);
    padding: 0;
    transition: all 0.5s ease-in-out;
  }
  .navbar-nav > li > a:hover {
    border-bottom: 0.01875rem solid var(--hover-purple);
    padding: 0;
    color: var(--hover-purple);
    transform: scale(1.05);
  }
  .line {
    margin-left: 1rem;
  }
  .links {
    text-align: right;
    padding-right: 0;
    margin: auto;
    padding: auto;
  }
  .dropdown {
    margin-left: auto;
    padding: auto;
  }
  .bar {
    margin-bottom: 0.1rem;
    margin-right: 2rem;
  }
  .line {
    margin-bottom: 0.1rem;
    margin-right: 2rem;
  }
  .about {
    margin-left: auto;
    margin-right: 0.8rem;
  }
  .menu-icon {
    color: var(--secondary-purple);
  }
  .main-info {
    background-image: url("TopBackground.svg");
    background-repeat: no-repeat;
    background-size: 100% auto;
    width: 100%;
    height: 100%;
    margin-top: 90px;
  }
  .community-links {
    margin-left: 2rem;
    padding-top: 0.5rem;
    position: absolute;
    z-index: 999;
  }
  .telegram-icon,
  .twitter-icon,
  .reddit-icon,
  .youtube-icon {
    display: inline;
    padding: 1rem;
    transition: all 0.5s ease-in-out;
    z-index: 999;
  }
  .icon:hover {
    color: var(--hover-purple);
    transform: scale(1.15);
  }
  .icon-youtube:hover {
    color: var(--text-purple);
    transform: scale(1.1);
  }
  .news-title {
    text-shadow: 2.25px 2.25px 3px var(--hover-purple);
    color: var(--text-purple);
    text-align: center;
    margin-top: 1rem;
    margin-left: -18rem;
    margin-bottom: 1rem;
    font-size: 1.5rem;
  }
  .newsGraph1,
  .newsGraph2,
  .newsGraph3 {
    margin-bottom: 1rem;
  }
  .youtube {
    z-index: 999;
    display: inline;
    position: absolute;
    text-align: center;
    color: var(--text-purple);
  }
  span.anchor {
    display: block;
    visibility: hidden;
    position: relative;
    padding-top: 20%;
  }
  .activeDelegators,
  .holders,
  .curatorCount,
  .subgraphCount {
    font-weight: 400;
    color: var(--text-purple);
    letter-spacing: 0.0375rem;
  }
  .hr {
    border: 3px solid var(--hr);
    border-radius: 5px;
    width: 90%;
    margin: auto;
    box-shadow: 0 0 10px 3px var(--hover-purple);
    position: relative;
    margin-top: 2.5rem;
  }
  .hr2 {
    border: 3px solid var(--hr);
    border-radius: 5px;
    width: 90%;
    margin: auto;
    box-shadow: 0 0 10px 3px var(--hover-purple);
    position: relative;
    margin-top: 1.5rem;
  }
  .video {
    position: absolute;
    box-shadow: #4D1D86 4px 8px 10px 0;
    text-align: center;
    top: 0%;
    left: 50%;
  }
  .g-ytsubscribe {
    position: absolute;
    font-family: Euclid Circular A;
    font-weight: 400;
    margin: 0;
    left: 80%;
    top: 41%;
  }
  .data-title {
    letter-spacing: 0.0375rem;
    font-weight: 400;
    color: var(--text-purple);
    font-size: 3.5rem;
    text-align: center;
    position: relative;
  }
  .chartsTitle {
    letter-spacing: 0.0375rem;
    font-weight: 400;
    color: var(--text-purple);
    font-size: 3.5rem;
    text-align: center;

    position: relative;
  }
  .chartsTitle2 {
    letter-spacing: 0.0375rem;
    font-weight: 400;
    color: var(--text-purple);
    font-size: 3.5rem;
    text-align: center;

  }
  .newsGraph {
    width: 100%;
    box-shadow: #4D1D86 4px 5px 10px 3px;
  }
  .chartImg {
    width: 100%;
    box-shadow: #4D1D86 6px 6px 10px 3px;
    margin: auto;
  }
  .center {
    margin: auto;
  }
  .line1 {
    margin-top: 5rem;
  }
  .chartImg1 {
    text-align: center;
    padding-top: -7rem;
    padding-bottom: 1rem;
    width: 100%;
    margin-top: -130px;
    margin-bottom: -150px;
  }
  .chartImg2,
  .chartImg3,
  .chartImg4,
  .chartImg5 {
    text-align: center;
    padding-top: 1rem;
    padding-bottom: 1rem;
    width: 100%;
    margin-top: -150px;
    margin-bottom: -150px;
  }
  .chartImg6 {
    text-align: center;
    padding-top: 1rem;
    width: 100%;

  }
  .reveal {
    position: relative;
    transform: translateY(50px);
    opacity: 0;
    transition: 0.75s all ease;
  }
  .reveal.active {
    transform: translateY(0);
    opacity: 1;
  }
  .email {
    color: var(--hover-purple);
    font-weight: 500;
  }
  .footerLink {
    color: var(--hover-purple);
  }
  .footerLink:link {
    color: var(--hover-purple);
    cursor: pointer;
  }
  .footerLink:visited {
    color: var(--hover-purple);
    cursor: pointer;
  }
  .footerLink.active,
  .footerLink:focus {
    border-bottom: 0rem solid var(--hover-purple);
    cursor: pointer;
  }
  .footerLink:hover {
    color: var(--text-purple);
    transform: scale(1.05);
    cursor: pointer;
  }
  .footer {
    margin-bottom: -1rem;
    padding: 1rem;
    font-weight: 700;
    margin-top: 1rem;
    font-size: 1.1rem;
    text-align: center;
    background-color: var(--primary-purple);
    color: var(--secondary-purple);
  }
  .tsparticles {
    display: none;
  }
  .rotate-position,
  .about-rotate-position,
  .phone,
  .about-phone,
  .message,
  .about-message, .home-message {
    display: none;
  }
  .adminHeader-text {
    font-weight: 400;
    font-size: 1.5rem;
    text-align: center;
    margin: auto;
    padding-top: 1rem;
    color: var(--text-purple);
    
    margin-top: 6rem;
  }
  .adminHeader-text2 {
    font-weight: 400;
    font-size: 1.5rem;
    text-align: center;
    margin: auto;
    margin-right: 3rem;
    color: var(--text-purple);
    
    margin-top: 2rem;
  }
  .adminSubheader-text {
    font-weight: 400;
    font-size: 1rem;
    margin-left: 3rem;
    color: var(--text-purple);
    
    margin-top: 1rem;
  }
  .adminSubheader-text2 {
    font-weight: 400;
    font-size: 1rem;
    margin-left: 3rem;
    color: var(--text-purple);
   
    margin-top: 1rem;
  }
  .note {
    text-decoration: underline;
  }

  .adminHr2 {
    border: 3px solid var(--hr);
    border-radius: 5px;
    width: 70%;
    margin: auto;
    box-shadow: 0 0 10px 3px var(--hover-purple);
    margin-top: 1rem;
    margin-left: 1.9rem;
  }

 .admin-video {
    position: absolute;
    left: 48%;
    top: 20%;
    width: 10%;
    height: 10%;
  }

  .adminChartsTitle {
    letter-spacing: 0.0375rem;
    font-weight: 400;
    color: var(--text-purple);
    font-size: 2rem;
    text-align: center;
    padding-top: 1rem;
    text-shadow: 2.5px 2.5px 4px var(--hover-purple);
    position: relative;
  }
  .adminChartsTitle2 {
    letter-spacing: 0.0375rem;
    font-weight: 400;
    color: var(--text-purple);
    font-size: 2rem;
    margin-top: 1rem;
    text-align: center;
    text-shadow: 2.5px 2.5px 4px var(--hover-purple);
  }
}
/* *************************************************************************
****************************************************************************
***************************************************************************/
/* *************************************************************************
****************************************************************************
***************************************************************************/
@media screen and (min-width: 880px) and (max-width: 881px) {
  .flashcards-title {
margin-top: 120px!important;
}
  .hide-line {
    color: transparent!important;
    display: block!important;
  }
  .Quiz-button {
    margin-bottom: 2rem;
  }
  .quiz-title {
    margin-top: -60px;
  }
  .quiz-button-position {
    padding-top: 2rem;
    margin: auto;
    text-align: center;
    margin-bottom: 2rem;
  }
  
  .final-exam-question {
      font-size: 1.3rem;
      text-align: center;
      color: #d8d2e4;
      /* remove for mobile */
      margin-top: 0rem;
      /* remove for mobile */
  }
  
  .final-exam-answer-form {
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin-top: 0rem;
    columns: 1!important;
    width: 100%!important;
  }
  
  .form-check-label {
    font-size: 20px;
    text-align: center;
    height: 110%;
  }
  .quiz-question {
    font-size: 1.3rem;
    padding-bottom: 2rem;
  text-align: center;
  width: 80%;
  margin: auto;
    text-align: center;
    color: #d8d2e4;
  }
  .quiz-question-container {
    margin-top: 20px;
    width: 100%;
  }

  .quiz-answer-form {
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin-top: 0rem;
    columns: 1!important;
    width: 100%!important;
  }

  .quiz-position {
    margin-top: -10px;
  }
  .mobile-portrait {
    display: none;
    }
  .adminHeader-text {
    font-weight: 400;
    font-size: 1.5rem;
    text-align: center;
    margin: auto;
    padding-top: 1rem;
    color: var(--text-purple);
    
    margin-top: 6rem;
  }
  .adminHeader-text2 {
    font-weight: 400;
    font-size: 1.5rem;
    text-align: center;
    margin: auto;
    color: var(--text-purple);
    
    margin-top: 2rem;
  }
  .adminSubheader-text {
    font-weight: 400;
    font-size: 1rem;
    margin-left: 3rem;
    color: var(--text-purple);
    
    margin-top: 1rem;
  }
  .adminSubheader-text2 {
    font-weight: 400;
    font-size: 1rem;
    margin-left: 3rem;
    margin-right: 3rem;
    color: var(--text-purple);

    margin-top: 1rem;
  }
  .monitor-line-carousel {
    display: none;
  }
  
  .monitor-bar-carousel {
   display: none;
  }
  
  .monitor-other-carousel {
    display: none;
  }
  
  .monitor-other-charts-position {
    display: none;
  }
  .mobile-bar-carousel {
    width: 80%;
  height: 500px;
  margin: auto auto;
  padding-top: -1rem;
  padding-bottom: -5rem;
  margin-bottom: -6rem;
  overflow: hidden;
  }
  .mobile-line-carousel {
    width: 80%;
    height: 500px;
    margin: auto auto;
    padding-top: 0rem;
    overflow: hidden;
  }

  .mobile-line-pos {
padding-top: 3rem;
  }
  .more-data-hr {
    margin-top: 1rem!important;
  }
  .more-data-title {
    margin-bottom: -12rem!important;
  }
  .mobile-other-carousel {
    width: 110%;
    height: 550px;
    margin: auto auto;
    margin-left: -2.5rem;
    padding-top: 1rem!important;
    overflow: hidden;
  }
  .map {
    width: 100%;
    margin-left: 5%;
    box-shadow: #4D1D86 4px 5px 10px 3px;
  }
  .holders-pos {
    margin-top: -8rem;
    margin-left: 1%;
    padding-bottom: 2rem;
  }
  .css-1fzpoyk {
    width: 100%;
    padding-top: 1rem;
  }

  .extra-data {
    margin-left: 2rem;
    margin-right: 2rem;
    font-size: 1.25rem;
    text-align: center;
    color: var(--text-purple);
  }

  .extra-data2 {
    
    font-size: 1.25rem;
    text-align: center;
    color: var(--text-purple);
  }
  .data-title {
    letter-spacing: 0.0375rem;
    font-weight: 400;
    color: var(--text-purple);
    font-size: 2rem;
    text-align: center;
    position: relative;
  }
  .chartsTitle {
    letter-spacing: 0.0375rem;
    font-weight: 400;
    color: var(--text-purple);
    font-size: 2rem;
    text-align: center;
    position: relative;
    margin-bottom: -13rem;
  }
  .chartsTitle2 {
    letter-spacing: 0.0375rem;
    font-weight: 400;
    color: var(--text-purple);
    font-size: 2rem;
    text-align: center;
    margin-bottom: -10rem;
  }
  .hr {
    border: 3px solid var(--hr);
    border-radius: 5px;
    width: 90%;
    margin: auto;
    box-shadow: 0 0 10px 3px var(--hover-purple);
    position: relative;
    margin-top: 3rem;
  }
  .hr2 {
    border: 3px solid var(--hr);
    border-radius: 5px;
    width: 90%;
    margin: auto;
    box-shadow: 0 0 10px 3px var(--hover-purple);
    position: relative;
    margin-top: 3rem;
  }
 
  .move-up {
    margin-top: -45rem;
  }
.hide-me {
  display: none;
}
  .css-1qzevvg img {
    height: 100%;
    margin-top: -10rem;
    text-align: center;
    margin-left: -1rem;
    z-index: 999999;
    position: relative;
}

/* above me */
  .mobile-dropdown {
    margin-left: 1.5rem!important;
  }
  .offcanvas-body {
    width: 100%!important;
    opacity: 0.9;
  }
  .offcanvas {
    width: 40%!important;
    background-color: var(--background)!important;
  }
 
   /* chatbot height start */
.react-chatbot-kit-chat-inner-container {
  background-color: var(--text-purple)!important;
  height: 290px!important;
}
.react-chatbot-kit-chat-message-container {
  height: 250px!important;
}
  /* chatbot height end */
 /* dune start */
.GRTDailyTransactionsPosition {
  margin-bottom: 1rem!important;
  margin-left: -15px!important;
 }
 .HoldersGraph {
  background-image: linear-gradient(90deg, #1B0D33, #D8D2E4, #D8D2E4,#D8D2E4, #D8D2E4,#D8D2E4,#D8D2E4,#D8D2E4,#D8D2E4,#D8D2E4,#D8D2E4,#D8D2E4,#D8D2E4,#D8D2E4,#D8D2E4,#D8D2E4,#D8D2E4,#D8D2E4,#1B0D33);
  margin-bottom: -36px!important;
  margin-left: -10px!important;
  padding-left: 20px!important;
  padding-right: 20px!important;
  height: 80px!important;
  width: 100%!important;
}
.HoldersGraphBottom {
  background-image: linear-gradient(90deg, #1B0D33, #D8D2E4,#D8D2E4,#D8D2E4,#D8D2E4,#D8D2E4,#D8D2E4,#D8D2E4,#D8D2E4,#D8D2E4,#D8D2E4,#D8D2E4,#D8D2E4,#D8D2E4,#D8D2E4,#D8D2E4,#D8D2E4,#D8D2E4, #1B0D33);
  padding-left: 20px!important;
  margin-left: -10px!important;
  padding-right: 20px!important;
  height: 80px!important;
  width: 100%!important;
}
  .chatbot-footer-bottom {
    margin-bottom: -80px!important;
   }
  .chatbot-link-logo{
    margin-top: 4rem;
    z-index: 9998;
    position: fixed;
    max-width: 5rem;
    background: white;
    -moz-border-radius: 50px;
    -webkit-border-radius: 50px;
    border-radius: 50px;
    padding: -1rem;
    right: 2%;
    border: 3px var(--secondary-purple) solid;
    box-shadow: 0 0 0 var(--hover-purple);
    animation: pulse 2s infinite;
  }
  .advocate-link-logo{
    margin-top: 8rem;
    z-index: 9998;
    position: fixed;
    max-width: 5rem;
    background: white;
    -moz-border-radius: 50px;
    -webkit-border-radius: 50px;
    border-radius: 50px;
    padding: -1rem;
    right: 2%;
    border: 3px var(--secondary-purple) solid;
    box-shadow: 0 0 0 var(--hover-purple);
    animation: pulse 2s infinite;
  }
  @-webkit-keyframes pulse {
    0% {
      -webkit-box-shadow: 0 0 0 0  var(--text-purple);
    }
    70% {
        -webkit-box-shadow: 0 0 0 15px rgba(244,157,22, 0);
    }
    100% {
        -webkit-box-shadow: 0 0 0 0 rgba(244,157,22, 0);
    }
  }
  @keyframes pulse {
    0% {
      -moz-box-shadow: 0 0 0 0  var(--text-purple);
      box-shadow: 0 0 0 0  var(--text-purple);
    }
    70% {
        -moz-box-shadow: 0 0 0 15px rgba(244,157,22, 0);
        box-shadow: 0 0 0 15px rgba(244,157,22, 0);
    }
    100% {
        -moz-box-shadow: 0 0 0 0 rgba(244,157,22, 0);
        box-shadow: 0 0 0 0 rgba(244,157,22, 0);
    }
  }
  
  .chatbot-link-export{
    margin-top: 7rem;
    z-index: 9998;
    position: fixed;
    max-width: 5rem;
    background: white;
    -moz-border-radius: 50px;
    -webkit-border-radius: 50px;
    border-radius: 50px;
    padding: -1rem;
    right: 2%;
    border: 3px var(--secondary-purple) solid;
    box-shadow: 0 0 0 var(--hover-purple);
    animation: pulse 2s infinite;
  }
  /* Chatbot */
.feedback-text {
  margin-top: 2rem;
}
.feedback-button {
  border: 2px var(--primary-purple) solid!important;
  background-color: var(--text-purple)!important;
  color: var(--primary-purple)!important;
  padding: auto!important;
  margin: auto!important;
}

  .feedback-button:link {
    cursor: pointer;
  }
  .feedback-button:visited {
    cursor: pointer;
  }
  .feedback-button.active,
  .feedback-button:focus {
    cursor: pointer;
  }
  .feedback-button:hover {
    background-color: var(--background)!important;
    color: var(--text-purple)!important;
    transform: scale(1.015);
    cursor: pointer;
  }
  .feedback-button-text {
    font-size: 1.5rem;
  }

.react-chatbot-kit-chat-inner-container {
  background-color: var(--text-purple)!important;
}

.react-chatbot-kit-chat-header {
  font-size: 1.1rem!important;
  color: var(--text-purple)!important;
  background-color: var(--primary-purple)!important;
  padding: 0rem!important;
}

.react-chatbot-kit-chat-input-container {
  background-color:var(--secondary-purple)!important;
  border: 1px solid var(--primary-purple)!important;
}

.react-chatbot-kit-chat-btn-send {
  background-color:var(--primary-purple)!important;
}

.react-chatbot-kit-chat-btn-send-icon:hover {
transform: scale(1.25);
}

.react-chatbot-kit-chat-bot-message-container {
  width: 100%;
}

.react-chatbot-kit-chat-bot-avatar-container {
}

.react-chatbot-kit-chat-bot-avatar-icon {
}

.react-chatbot-kit-chat-bot-avatar-letter {
  display: none;
}
.react-chatbot-kit-chat-container {
  width: 98%!important;
  text-align: center;
  margin: auto;
}

.react-chatbot-kit-chat-bot-avatar-container {
  width: 3.5rem!important;
  height: 3.5rem!important;
  background-color: white!important;
  border: 2px solid var(--primary-purple);
  background-image: url('./GRTDataHubChatBot.png')!important;
  background-repeat: no-repeat!important;
  background-size: 3rem;
  background-position-x: 50%;
  background-position-y: 20%;
}

.react-chatbot-kit-chat-bot-message {
  text-align: left!important;
  width: 100%!important;
  color: var(--text-purple)!important;
  background-color: #0C0B23!important;
  font-size: 1rem!important;
}

.react-chatbot-kit-chat-bot-message-arrow {
  color: #0C0B23!important;
  border-right: 8px solid #0C0B23!important;
}
  .indexer-table-header {
    text-align: center;
    margin-left: 1rem;
  }
  .indexer-table {
    text-align: center;
  }
  .chatbot-data {
    display: inline!important;
  }
  .telegramActiveDelegationCount {
    max-width: 92%;
    margin: auto; 
    text-align: center;
    margin-top: 0.5%;
    }
    .top-telegram {
      margin-top: 0.25rem;
    }
    .chatbot-top {
      margin-top: 5rem;
    }
    .chatbot-page-container {
      background: var(--background);
      color: var(--text-purple);
      width: 100%;
      margin: auto;
      margin-top: 120px;
      padding-left: 10px;
      padding-right: 10px;
      text-align: center;
    }
    .chatbot-text-container {
      background: whitesmoke;
      border-radius: 1rem;
      color: var(--secondary-purple);
      margin: auto!important;
      padding-bottom: 10px;
      padding-top: 10px;
      text-align: center!important;
      padding-left: 20px;
      padding-right: 20px;
    }
    .chatbot-page-title {
      font-size: 1.25rem;
      font-weight: 600;
      color: var(--secondary-purple);
      padding-bottom: 0.25rem;
      padding-left: 5px;
      padding-right: 5px;
    }
    .chatbot-page-subtitle {
      font-size: 1.75rem;
      padding-left: 5px;
      padding-right: 5px;
    }
    .chatbot-page-text {
      font-size: 1.25rem;
      margin-bottom: 1rem;
      margin-top: 1rem;
      padding-left: 10px;
      padding-right: 10px;
    }
    /* chatbot end */
      .logo {
    width:  7rem;
    height: 5.75rem;
    margin-left: 2rem;
    margin-top: -0.5rem;
  }
  .dataHub-logos {
    max-width: 6rem !important;
    max-height: 35rem !important;
    position: absolute;
    top: 0;
    margin-top: 1rem;
  }
  .monitor-config {
    display: none!important;
  }
  body > div:nth-child(1) > button {
    display: none!important;
  }
  .pages {
    margin-bottom: 0.1rem;
    margin-right: 2rem;
}
.indexer-title {
  text-align: center!important;
  font-weight: 400;
  font-size: 1.75rem;
  padding-top: 2rem;
  color: var(--text-purple);
  
  margin-top: 6rem;
}
.indexer-subtitle {
  text-align: center!important;
  font-weight: 400;
  font-size: 1.5rem;
  color: var(--text-purple);
  
  margin-top: 1rem;
  margin-right: 2rem;
  margin-left: 2rem;
}
.indexer {
  z-index: 999;
  position: absolute;
  left: 0%;
  top: 50%;
}

.curator {
  z-index: 999;
  position: absolute;
  text-align: center!important;
  top: 50%;
}

.tokens, .rewards, .uncollected-rewards {
  margin-top: 2rem;
  width: 90%;
  height: 14rem;
  margin-left: 5%;
  box-shadow: #4D1D86 4px 5px 10px 3px;
}

.b-map {
  margin-top: 2rem;
  margin-left: 1rem;
  width: 800px;
  height: 3000px;
  text-align: center!important;
  box-shadow: #4D1D86 4px 5px 10px 3px;
}

.c-map {
  margin-top: 2rem;
  margin-left: 1rem;
  width: 800px;
  height: 1600px;
  text-align: center!important;
  box-shadow: #4D1D86 4px 5px 10px 3px;
}

.d-map {
  margin-top: -3rem;
  margin-left: 3rem;
  width: 800px;
  height: 3800px;
  text-align: center!important;
  box-shadow: #4D1D86 4px 5px 10px 3px;
}

.rewards-per-hour {
  margin-top: 2rem;
  padding-bottom: 2rem;
  width: 90%;
  height: 14rem;
  margin-left: 5%;
  box-shadow: #4D1D86 4px 5px 10px 3px;
}

.indexers {
  padding-left: 1rem;
  padding-left: 1rem;
  color: grey;
  font-size:1.25rem;
  margin-right: 1rem;
}
.indexers:link{
  color: grey;
  cursor: pointer;
  font-size:1.25rem;
  margin-right: 1rem;
}
.indexers:visited {
  color: grey;
  cursor: pointer;
  font-size:1.25rem;
  margin-right: 1rem;
}
.indexers.active,
.indexers:focus {
  border-bottom: 0rem solid var(--hover-purple);
  cursor: pointer;
  font-size:1.25rem;
  margin-right: 1rem;
}
.indexers:hover {
  color: var(--text-purple);
  transform: scale(1.05);
  cursor: pointer;
  font-size:1.25rem;
  margin-right: 1rem;
}
.indexer-link {
  color: white;
}
.indexer-link:link {
  color: white;
  cursor: pointer;
}
.indexer-link:visited {
  color: var(--text-purple);
  cursor: pointer;
}
.indexer-link.active,
.indexer-link:focus {
  border-bottom: 0rem solid var(--text-purple);
  cursor: pointer;
}
.indexer-link:hover {
  color: var(--text-purple);
  transform: scale(1.05);
  cursor: pointer;
}
  .cookie-page-text {
    padding-top: 1rem;
    font-size: 1.25rem;
    margin-bottom: 1rem;
    padding-left: 20px;
    padding-right: 20px;
  }
  .cookies-video {
    padding-top: 2rem;
  }
  .cookie-button {
    z-index: 9999;
    position: absolute;
  }

  body > div:nth-child(1) > button {
    z-index: 10000;
    font-size: 3rem;
    position: absolute;
    padding: auto;
    margin-top: -95px;
    width: 45px;
    height: 45px;
    margin-left: 85%;
    background-color: transparent;
    border: none;
    color: var(--text-purple);
  }

  .data-link {
    color: grey;
  }
  .data-link:link {
    color: grey;
    cursor: pointer;
  }
  .data-link:visited {
    color: grey;
    cursor: pointer;
  }
  .data-link.active,
  .data-link:focus {
    border-bottom: 0rem solid white;
    cursor: pointer;
  }
  .data-link:hover {
    color: white;
    transform: scale(1.05);
    cursor: pointer;
  }
  .question-bank-container {
    background: var(--background);
    color: var(--text-purple);
    width: 100%;
    margin: auto;
    margin-top: 150px;
    margin-bottom: 50px;
    padding-left: 50px;
    padding-right: 50px;
    text-align: center;
  }
  .about-page-container {
    background: var(--background);
    color: var(--text-purple);
    width: 100%;
    margin: auto;
    margin-top: 150px;
    margin-bottom: 50px;
    padding-left: 50px;
    padding-right: 50px;
    text-align: center;
  }
  .QB-text-container {
    background: white;
    border-radius: 1rem;
    padding: 30px;
    color: var(--secondary-purple);
  }
  .text-container {
    background: white;
    border-radius: 1rem;
    padding: 30px;
    color: var(--secondary-purple);
  }
  .about-hr {
    border: 1.5px solid var(--background);
    color: var(--background);
    border-radius: 5px;
    width: 35%;
    margin: auto;
    margin-bottom: 1rem;
    box-shadow: 0 0 5px 1px var(--background);
  }
  .about-page-title {
    font-size: 2rem;
    font-weight: 600;
    color: var(--secondary-purple);
    padding-bottom: 3rem;
  }
  .about-page-subtitle {
    font-size: 1.75rem;
  }
  .about-page-text {
    font-size: 1.25rem;
    margin-bottom: 1rem;
  }
  .graphiql-link {
    color: var(--secondary-purple);
  }
  .graphiql-link:link {
    color: var(--secondary-purple);
    cursor: pointer;
  }
  .graphiql-link:visited {
    color: var(--secondary-purple);
    cursor: pointer;
  }
  .graphiql-link.active,
  .graphiql-link:focus {
    border-bottom: 0rem solid var(--hover-purple);
    cursor: pointer;
  }
  .graphiql-link:hover {
    color: var(--primary-purple);
    transform: scale(1.05);
    cursor: pointer;
  }
  .cookies-policy-container {
    background: white;
    width: 90%;
    padding-bottom: 1rem;
    margin: auto;
    border-radius: 2rem;
    margin-top: 150px;
    margin-bottom: 50px;
  }
  .termly-cookie-preference-button {
   display: none;
  }

  .privacy-policy-container {
    background: white;
    width: 90%;
    padding-bottom: 1rem;
    margin: auto;
    border-radius: 2rem;
    margin-top: 150px;
    margin-bottom: 50px;
  }
  .privacy-policy {
    margin-top: 110px;
    font-weight: 700;
    font-size: 1.1rem;
    text-align: center;
    background-color: var(--background);
    color: var(--secondary-purple);
  }
  .privacy-policy-link,
  .about {
    padding-left: 1rem;
    padding-left: 1rem;
    color: grey;
  }
  .privacy-policy-link:link,
  .about:link {
    color: grey;
    cursor: pointer;
  }
  .privacy-policy-link:visited,
  .about:visited {
    color: grey;
    cursor: pointer;
  }
  .privacy-policy-link.active,
  .privacy-policy-link:focus,
  .about.active,
  .about:focus {
    border-bottom: 0rem solid var(--hover-purple);
    cursor: pointer;
  }
  .privacy-policy-link:hover,
  .about:hover {
    color: var(--text-purple);
    transform: scale(1.05);
    cursor: pointer;
  }
  .subscribeNote {
    margin-left: -15rem;
    width: 30rem;
    font-size: 0.75rem;
    color: white;
  }
/* loader new start  */
.loader:empty {
  position: absolute;
  top: calc(50% + 5.5rem);
  left: calc(50% + 2em);
  width: 4em;
  height: 4em;
  border: 0.35em solid white;
  border-left: 0.35em solid #10034B;
  border-radius: 50%;
  animation: load8 0.8s infinite linear;
}
.loadingTitle {
  position: absolute;
  top: 17rem;
  left: calc(50% - 3em);
  font-size: 2rem;
  color: #10034B;
  -webkit-box-reflect:below 0px linear-gradient(transparent, #0005);
}
.loaderLogo {
  position: absolute;
  top: 4rem;
  left: calc(50% - 8em);
  width: 15em;
  height: 12em;
}
@keyframes load8 {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
/* loader new end  */
  .C {
    font-size: 1rem;
  }
  .logo {
    width:  6.75rem;
    height: 5.75rem;
    margin-left: 2rem;
    margin-top: -0.5rem;
  }
  .data-logo {
    max-width: 1rem !important;
    height: 1rem !important;
    position: absolute;
  }
  .brand-text {
    color: var(--text-purple);
    display: inline;
    margin-left: 0rem;
    letter-spacing: 0.0375rem;
    font-size: 1.25rem;
  }
  .header-text {
    font-weight: 400;
    font-size: 1.75rem;
    margin-left: 5rem;
    margin-top: 105px;
    color: var(--text-purple);

  }
  .header-sub-text {
    font-family: Euclid Circular A;
    font-weight: 400;
    font-size: 1.25rem;
    text-align: center!important;
    margin: auto!important;
    width: 90%;
    color: var(--text-purple);
    display: block;
 
  }
  nav a.TGN {
    display: none;
  }
  .navbar-nav > li > a.TGN:link {
    display: none;
  }
  .navbar-nav > li > a.TGN:visited {
    display: none;
  }
  .navbar-nav > li > a.TGN.active,
  .navbar-nav > li > a.TGN:focus {
    display: none;
  }
  .navbar-nav > li > a.TGN:hover {
    display: none;
  }
  nav a.nav-link {
    font-family: Euclid Circular A;
    font-weight: 600;
    font-size: 1.15rem;
    color: var(--text-purple);
  }
  .navbar-nav > li > a {
    border-bottom: 0.01875rem solid var(--transparent);
    padding: 0;
  }
  .navbar-nav > li > a:link {
    color: var(--hover-purple);
  }
  .navbar-nav > li > a:visited {
    color: var(--text-purple);
  }
  .navbar-nav > li > a.active,
  .navbar-nav > li > a:focus {
    border-bottom: 0.01875rem solid var(--hover-purple);
    padding: 0;
    transition: all 0.5s ease-in-out;
  }
  .navbar-nav > li > a:hover {
    border-bottom: 0.01875rem solid var(--hover-purple);
    padding: 0;
    color: var(--hover-purple);
    transform: scale(1.05);
  }
  .line {
    margin-left: 1rem;
  }
  .links {
    text-align: right;
    padding-right: 0;
    margin: auto;
    padding: auto;
  }
  .dropdown {
    margin-left: auto;
    padding: auto;
  }
  .bar {
    margin-bottom: 0.1rem;
    margin-right: 2rem;
  }
  .line {
    margin-bottom: 0.1rem;
    margin-right: 2rem;
  }
  .about {
    margin-left: auto;
    margin-right: 0.8rem;
  }
  .menu-icon {
    color: var(--secondary-purple);
  }
  .main-info {
    background-image: url("TopBackground.svg");
    background-repeat: no-repeat;
    background-size: 100% auto;
    width: 100%;
    height: 100%;
    margin-top: 90px;
  }
  .community-links {
    margin-left: 4rem;
    padding-top: 1rem;
    position: absolute;
    z-index: 999;
  }
  .telegram-icon,
  .twitter-icon,
  .reddit-icon,
  .youtube-icon {
    display: inline;
    padding: 1rem;
    transition: all 0.5s ease-in-out;
    z-index: 999;
  }
  .icon:hover {
    color: var(--hover-purple);
    transform: scale(1.15);
  }
  .icon-youtube:hover {
    color: var(--text-purple);
    transform: scale(1.1);
  }
  .news-title {
    text-shadow: 2.25px 2.25px 3px var(--hover-purple);
    color: var(--text-purple);
    text-align: center;
    margin-top: 1rem;
    margin-left: -18rem;
    margin-bottom: 1rem;
    font-size: 1.5rem;
  }
  .newsGraph1,
  .newsGraph2,
  .newsGraph3 {
    margin-bottom: 1rem;
  }
  .youtube {
    z-index: 999;
    display: inline;
    position: absolute;
    text-align: center;
    color: var(--text-purple);
  }
  span.anchor {
    display: block;
    visibility: hidden;
    position: relative;
    padding-top: 28%;
  }
  .activeDelegators,
  .holders,
  .curatorCount,
  .subgraphCount {
    font-weight: 400;
    color: var(--text-purple);
    letter-spacing: 0.0375rem;
  }

  .video {
    position: absolute;
    box-shadow: #4D1D86 4px 8px 10px 0;
    left: 52%;
    top: 0%;
  }
  .g-ytsubscribe {
    position: absolute;
    font-weight: 400;
    margin: 0;
    left: 80%;
    top: 41%;
  }
 
  .newsGraph {
    width: 100%;
    box-shadow: #4D1D86 4px 5px 10px 3px;
  }
  .chartImg {
    width: 100%;
    box-shadow: #4D1D86 6px 6px 10px 3px;
    margin: auto;
  }
  .center {
    margin: auto;
  }
  .line1 {
    margin-top: 5rem;
  }

  .chartImg6 {
    text-align: center;
    padding-top: 1rem;
    width: 100%;
  
  }
  .reveal {
    position: relative;
    transform: translateY(50px);
    opacity: 0;
    transition: 0.75s all ease;
  }
  .reveal.active {
    transform: translateY(0);
    opacity: 1;
  }
  .email {
    color: var(--hover-purple);
    font-weight: 500;
  }
  .footerLink {
    color: var(--hover-purple);
  }
  .footerLink:link {
    color: var(--hover-purple);
    cursor: pointer;
  }
  .footerLink:visited {
    color: var(--hover-purple);
    cursor: pointer;
  }
  .footerLink.active,
  .footerLink:focus {
    border-bottom: 0rem solid var(--hover-purple);
    cursor: pointer;
  }
  .footerLink:hover {
    color: var(--text-purple);
    transform: scale(1.05);
    cursor: pointer;
  }
  .footer {
    margin-bottom: -1rem;
    padding: 1rem;
    font-weight: 700;
    margin-top: 1rem;
    font-size: 1.1rem;
    text-align: center;
    background-color: var(--primary-purple);
    color: var(--secondary-purple);
  }
  .tsparticles {
    display: none;
  }
  .rotate-position,
  .about-rotate-position,
  .phone,
  .about-phone,
  .message,
  .about-message, .home-message {
    display: none;
  }

 
  .note {
    text-decoration: underline;
  }

 .admin-video {
    position: absolute;
    left: 48%;
    top: 20%;
    width: 10%;
    height: 10%;
  }
}
/* *************************************************************************
****************************************************************************
***************************************************************************/
/* *************************************************************************
****************************************************************************
***************************************************************************/
@media screen and (min-width: 853px) and (max-width: 879px) {
  .flashcards-title {
margin-top: 120px!important;
}
  .hide-line {
    color: transparent!important;
    display: block!important;
  }
  .Quiz-button {
    margin-bottom: 2rem;
  }
  .quiz-title {
    margin-top: -60px;
  }
  .quiz-button-position {
    padding-top: 2rem;
    margin: auto;
    text-align: center;
    margin-bottom: 2rem;
  }
  
  .final-exam-question {
      font-size: 1.3rem;
      text-align: center;
      color: #d8d2e4;
      /* remove for mobile */
      margin-top: 0rem;
      /* remove for mobile */
  }
  
  .final-exam-answer-form {
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin-top: 0rem;
    columns: 1!important;
    width: 100%!important;
  }
  
  .form-check-label {
    font-size: 20px;
    text-align: center;
    height: 110%;
  }
  .quiz-question {
    font-size: 1.3rem;
    padding-bottom: 2rem;
  text-align: center;
  width: 80%;
  margin: auto;
    text-align: center;
    color: #d8d2e4;
  }
  .quiz-question-container {
    margin-top: 20px;
    width: 100%;
  }

  .quiz-answer-form {
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin-top: 0rem;
    columns: 1!important;
    width: 100%!important;
  }

  .quiz-position {
    margin-top: -10px;
  }
  .mobile-landscape {
    display: none;
    }
  .mobile-dropdown {
    margin-left: 1.5rem!important;
  }
  .offcanvas-body {
    width: 100%!important;
    opacity: 0.9;
  }
  .offcanvas {
    width: 40%!important;
    background-color: var(--background)!important;
  }

   /* chatbot height start */
.react-chatbot-kit-chat-inner-container {
  background-color: var(--text-purple)!important;
  height: 290px!important;
}
.react-chatbot-kit-chat-message-container {
  height: 250px!important;
}
  /* chatbot height end */
/* dune start */
.GRTDailyTransactionsPosition {
  margin-bottom: 1rem!important;
  margin-left: -15px!important;
 }
 .HoldersGraph {
  background-image: linear-gradient(90deg, #1B0D33, #D8D2E4, #D8D2E4,#D8D2E4, #D8D2E4,#D8D2E4,#D8D2E4,#D8D2E4,#D8D2E4,#D8D2E4,#D8D2E4,#D8D2E4,#D8D2E4,#D8D2E4,#D8D2E4,#D8D2E4,#D8D2E4,#D8D2E4,#1B0D33);
  margin-bottom: -36px!important;
  margin-left: -10px!important;
  padding-left: 20px!important;
  padding-right: 20px!important;
  height: 80px!important;
  width: 100%!important;
}
.HoldersGraphBottom {
  background-image: linear-gradient(90deg, #1B0D33, #D8D2E4,#D8D2E4,#D8D2E4,#D8D2E4,#D8D2E4,#D8D2E4,#D8D2E4,#D8D2E4,#D8D2E4,#D8D2E4,#D8D2E4,#D8D2E4,#D8D2E4,#D8D2E4,#D8D2E4,#D8D2E4,#D8D2E4, #1B0D33);
  padding-left: 20px!important;
  margin-left: -10px!important;
  padding-right: 20px!important;
  height: 80px!important;
  width: 100%!important;
}
  .chatbot-footer-bottom {
    margin-bottom: -80px!important;
   }
  .chatbot-link-logo{
    margin-top: 3rem;
    z-index: 9998;
    position: fixed;
    max-width: 4rem;
    background: white;
    -moz-border-radius: 50px;
    -webkit-border-radius: 50px;
    border-radius: 50px;
    padding: -1rem;
    right: 2%;
    border: 3px var(--secondary-purple) solid;
    box-shadow: 0 0 0 var(--hover-purple);
    animation: pulse 2s infinite;
  }
  .advocate-link-logo{
    margin-top: 6rem;
    z-index: 9998;
    position: fixed;
    max-width: 4rem;
    background: white;
    -moz-border-radius: 50px;
    -webkit-border-radius: 50px;
    border-radius: 50px;
    padding: -1rem;
    right: 2%;
    border: 3px var(--secondary-purple) solid;
    box-shadow: 0 0 0 var(--hover-purple);
    animation: pulse 2s infinite;
  }
  @-webkit-keyframes pulse {
    0% {
      -webkit-box-shadow: 0 0 0 0  var(--text-purple);
    }
    70% {
        -webkit-box-shadow: 0 0 0 15px rgba(244,157,22, 0);
    }
    100% {
        -webkit-box-shadow: 0 0 0 0 rgba(244,157,22, 0);
    }
  }
  @keyframes pulse {
    0% {
      -moz-box-shadow: 0 0 0 0  var(--text-purple);
      box-shadow: 0 0 0 0  var(--text-purple);
    }
    70% {
        -moz-box-shadow: 0 0 0 15px rgba(244,157,22, 0);
        box-shadow: 0 0 0 15px rgba(244,157,22, 0);
    }
    100% {
        -moz-box-shadow: 0 0 0 0 rgba(244,157,22, 0);
        box-shadow: 0 0 0 0 rgba(244,157,22, 0);
    }
  }
  
  .chatbot-link-export{
    margin-top: 8rem;
    z-index: 9998;
    position: fixed;
    max-width: 4rem;
    background: white;
    -moz-border-radius: 50px;
    -webkit-border-radius: 50px;
    border-radius: 50px;
    padding: -1rem;
    right: 2%;
    border: 3px var(--secondary-purple) solid;
    box-shadow: 0 0 0 var(--hover-purple);
    animation: pulse 2s infinite;
  }

  /* Chatbot */
.feedback-text {
  margin-top: 2rem;
}
.feedback-button {
  border: 2px var(--primary-purple) solid!important;
  background-color: var(--text-purple)!important;
  color: var(--primary-purple)!important;
  padding: auto!important;
  margin: auto!important;
}

  .feedback-button:link {
    cursor: pointer;
  }
  .feedback-button:visited {
    cursor: pointer;
  }
  .feedback-button.active,
  .feedback-button:focus {
    cursor: pointer;
  }
  .feedback-button:hover {
    background-color: var(--background)!important;
    color: var(--text-purple)!important;
    transform: scale(1.015);
    cursor: pointer;
  }
  .feedback-button-text {
    font-size: 1.5rem;
  }

  /* chatbot height start */
.react-chatbot-kit-chat-inner-container {
  background-color: var(--text-purple)!important;
  height: 290px!important;
}
.react-chatbot-kit-chat-message-container {
  height: 250px!important;
}
  /* chatbot height end */
.react-chatbot-kit-chat-header {
  font-size: 1.1rem!important;
  color: var(--text-purple)!important;
  background-color: var(--primary-purple)!important;
  padding: 0rem!important;
}

.react-chatbot-kit-chat-input-container {
  background-color:var(--secondary-purple)!important;
  border: 1px solid var(--primary-purple)!important;
}

.react-chatbot-kit-chat-btn-send {
  background-color:var(--primary-purple)!important;
}

.react-chatbot-kit-chat-btn-send-icon:hover {
transform: scale(1.25);
}

.react-chatbot-kit-chat-bot-message-container {
  width: 100%;
}

.react-chatbot-kit-chat-bot-avatar-container {
}

.react-chatbot-kit-chat-bot-avatar-icon {
}

.react-chatbot-kit-chat-bot-avatar-letter {
  display: none;
}
.react-chatbot-kit-chat-container {
  width: 98%!important;
  text-align: center;
  margin: auto;
}

.react-chatbot-kit-chat-bot-avatar-container {
  width: 3.5rem!important;
  height: 3.5rem!important;
  background-color: white!important;
  border: 2px solid var(--primary-purple);
  background-image: url('./GRTDataHubChatBot.png')!important;
  background-repeat: no-repeat!important;
  background-size: 3rem;
  background-position-x: 50%;
  background-position-y: 20%;
}

.react-chatbot-kit-chat-bot-message {
  text-align: left!important;
  width: 100%!important;
  color: var(--text-purple)!important;
  background-color: #0C0B23!important;
  font-size: 1rem!important;
}

.react-chatbot-kit-chat-bot-message-arrow {
  color: #0C0B23!important;
  border-right: 8px solid #0C0B23!important;
}
  .indexer-table-header {
    text-align: center;
    margin-left: 1rem;
  }
  .indexer-table {
    text-align: center;
  }
  .chatbot-data {
    display: inline!important;
  }
  .telegramActiveDelegationCount {
    max-width: 92%;
    margin: auto; 
    text-align: center;
    margin-top: 0.5%;
    }
    .top-telegram {
      margin-top: 0.25rem;
    }
    .chatbot-top {
      margin-top: 5rem;
    }
    .chatbot-page-container {
      background: var(--background);
      color: var(--text-purple);
      width: 100%;
      margin: auto;
      margin-top: 100px;
      padding-left: 10px;
      padding-right: 10px;
      text-align: center;
    }
    .chatbot-text-container {
      background: whitesmoke;
      border-radius: 1rem;
      color: var(--secondary-purple);
      margin: auto!important;
      padding-bottom: 10px;
      padding-top: 10px;
      text-align: center!important;
      padding-left: 15px;
      padding-right: 15px;
    }
    .chatbot-page-title {
      font-size: 1.25rem;
      font-weight: 600;
      color: var(--secondary-purple);
      padding-bottom: 0.25rem;
      padding-left: 5px;
      padding-right: 5px;
    }
    .chatbot-page-subtitle {
      font-size: 1.75rem;
      padding-left: 5px;
      padding-right: 5px;
    }
    .chatbot-page-text {
      font-size: 1.25rem;
      margin-bottom: 1rem;
      margin-top: 1rem;
      padding-left: 10px;
      padding-right: 10px;
    }
    /* chatbot end */
    .logo {
      width:  6.75rem;
      height: 5.75rem;
      margin-left: 2rem;
      margin-top: -0.5rem;
    }
  .dataHub-logos {
    max-width: 6rem !important;
    max-height: 35rem !important;
    position: absolute;
    top: 0;
    margin-top: 1rem;
  }
  .monitor-config {
    display: none!important;
  }
  body > div:nth-child(1) > button {
    display: none!important;
  }
  .pages {
    margin-bottom: 0.1rem;
    margin-right: 2rem;
}
.indexer-title {
  text-align: center!important;
  font-weight: 400;
  font-size: 1.5rem;
  padding-top: 2rem;
  color: var(--text-purple);
  
  margin-top: 6rem;
}
.indexer-subtitle {
  text-align: center!important;
  font-weight: 400;
  font-size: 1.25rem;
  color: var(--text-purple);
  
  margin-top: 1rem;
  margin-right: 2rem;
  margin-left: 2rem;
}
.indexer {
  z-index: 999;
  position: absolute;
  left: 0%;
  top: 50%;
  width: 100%;
}

.curator {
  z-index: 999;
  position: absolute;
  text-align: center!important;
  top: 50%;
}

.map, .tokens, .rewards, .uncollected-rewards {
  margin-top: 2rem;
  width: 90%;
  height: 14rem;
  margin-left: 5%;
  box-shadow: #4D1D86 4px 5px 10px 3px;
}

.b-map {
  margin-top: 2rem;
  margin-left: 1rem;
  width: 800px;
  height: 3000px;
  text-align: center!important;
  box-shadow: #4D1D86 4px 5px 10px 3px;
}

.c-map {
  margin-top: 2rem;
  margin-left: 1rem;
  width: 800px;
  height: 1600px;
  text-align: center!important;
  box-shadow: #4D1D86 4px 5px 10px 3px;
}

.d-map {
  margin-top: -3rem;
  margin-left: 3rem;
  width: 800px;
  height: 3800px;
  text-align: center!important;
  box-shadow: #4D1D86 4px 5px 10px 3px;
}

.rewards-per-hour {
  margin-top: 2rem;
  padding-bottom: 2rem;
  width: 90%;
  height: 14rem;
  margin-left: 5%;
  box-shadow: #4D1D86 4px 5px 10px 3px;
}

.indexers {
  padding-left: 1rem;
  padding-left: 1rem;
  color: grey;
  font-size:1.25rem;
  margin-right: 1rem;
}
.indexers:link{
  color: grey;
  cursor: pointer;
  font-size:1.25rem;
  margin-right: 1rem;
}
.indexers:visited {
  color: grey;
  cursor: pointer;
  font-size:1.25rem;
  margin-right: 1rem;
}
.indexers.active,
.indexers:focus {
  border-bottom: 0rem solid var(--hover-purple);
  cursor: pointer;
  font-size:1.25rem;
  margin-right: 1rem;
}
.indexers:hover {
  color: var(--text-purple);
  transform: scale(1.05);
  cursor: pointer;
  font-size:1.25rem;
  margin-right: 1rem;
}
.indexer-link {
  color: white;
}
.indexer-link:link {
  color: white;
  cursor: pointer;
}
.indexer-link:visited {
  color: var(--text-purple);
  cursor: pointer;
}
.indexer-link.active,
.indexer-link:focus {
  border-bottom: 0rem solid var(--text-purple);
  cursor: pointer;
}
.indexer-link:hover {
  color: var(--text-purple);
  transform: scale(1.05);
  cursor: pointer;
}
  .cookie-page-text {
    padding-top: 1rem;
    font-size: 1.25rem;
    margin-bottom: 1rem;
    padding-left: 20px;
    padding-right: 20px;
  }
  .cookies-video {
    padding-top: 2rem;
  }
  .cookie-button {
    z-index: 9999;
    position: absolute;
  }

  body > div:nth-child(1) > button {
    z-index: 10000;
    font-size: 3rem;
    position: absolute;
    padding: auto;
    margin-top: -85px;
    width: 45px;
    height: 45px;
    margin-left: 85%;
    background-color: transparent;
    border: none;
    color: var(--text-purple);
  }
  .extra-data {
    margin-top: 2rem;
    font-size: 1.25rem;
    text-align: center;
    color: var(--text-purple);
   
  }
  .extra-data2 {
    font-size: 1.25rem;
    text-align: center;
    color: var(--text-purple);
   
  }
  .data-link {
    color: grey;
  }
  .data-link:link {
    color: grey;
    cursor: pointer;
  }
  .data-link:visited {
    color: grey;
    cursor: pointer;
  }
  .data-link.active,
  .data-link:focus {
    border-bottom: 0rem solid white;
    cursor: pointer;
  }
  .data-link:hover {
    color: white;
    transform: scale(1.05);
    cursor: pointer;
  }
  .question-bank-container {
    background: var(--background);
    color: var(--text-purple);
    width: 100%;
    margin: auto;
    margin-top: 150px;
    margin-bottom: 50px;
    padding-left: 50px;
    padding-right: 50px;
    text-align: center;
  }
  .about-page-container {
    background: var(--background);
    color: var(--text-purple);
    width: 100%;
    margin: auto;
    margin-top: 150px;
    margin-bottom: 50px;
    padding-left: 50px;
    padding-right: 50px;
    text-align: center;
  }
  .QB-text-container {
    background: white;
    border-radius: 1rem;
    padding: 30px;
    color: var(--secondary-purple);
  }
  .text-container {
    background: white;
    border-radius: 1rem;
    padding: 30px;
    color: var(--secondary-purple);
  }
  .QB-about-hr {
    border: 1.5px solid var(--background);
    color: var(--background);
    border-radius: 5px;
    width: 35%;
    margin: auto;
    margin-bottom: 1rem;
    box-shadow: 0 0 5px 1px var(--background);
  }
  .QB-about-page-title {
    font-size: 2rem;
    font-weight: 600;
    color: var(--secondary-purple);
    padding-bottom: 3rem;
  }
  .QB-about-page-subtitle {
    font-size: 1.75rem;
  }
  .QB-about-page-text {
    font-size: 1.25rem;
    margin-bottom: 1rem;
  }
  .about-hr {
    border: 1.5px solid var(--background);
    color: var(--background);
    border-radius: 5px;
    width: 35%;
    margin: auto;
    margin-bottom: 1rem;
    box-shadow: 0 0 5px 1px var(--background);
  }
  .about-page-title {
    font-size: 2rem;
    font-weight: 600;
    color: var(--secondary-purple);
    padding-bottom: 3rem;
  }
  .about-page-subtitle {
    font-size: 1.75rem;
  }
  .about-page-text {
    font-size: 1.25rem;
    margin-bottom: 1rem;
  }
  .graphiql-link {
    color: var(--secondary-purple);
  }
  .graphiql-link:link {
    color: var(--secondary-purple);
    cursor: pointer;
  }
  .graphiql-link:visited {
    color: var(--secondary-purple);
    cursor: pointer;
  }
  .graphiql-link.active,
  .graphiql-link:focus {
    border-bottom: 0rem solid var(--hover-purple);
    cursor: pointer;
  }
  .graphiql-link:hover {
    color: var(--primary-purple);
    transform: scale(1.05);
    cursor: pointer;
  }
  .cookies-policy-container {
    background: white;
    width: 90%;
    padding-bottom: 1rem;
    margin: auto;
    border-radius: 2rem;
    margin-top: 150px;
    margin-bottom: 50px;
  }
  .termly-cookie-preference-button {
   display: none;
  }

  .privacy-policy-container {
    background: white;
    width: 90%;
    padding-bottom: 1rem;
    margin: auto;
    border-radius: 2rem;
    margin-top: 150px;
    margin-bottom: 50px;
  }
  .privacy-policy {
    margin-top: 110px;
    font-weight: 700;
    font-size: 1.1rem;
    text-align: center;
    background-color: var(--background);
    color: var(--secondary-purple);
  }
  .privacy-policy-link,
  .about {
    padding-left: 1rem;
    padding-left: 1rem;
    color: grey;
  }
  .privacy-policy-link:link,
  .about:link {
    color: grey;
    cursor: pointer;
  }
  .privacy-policy-link:visited,
  .about:visited {
    color: grey;
    cursor: pointer;
  }
  .privacy-policy-link.active,
  .privacy-policy-link:focus,
  .about.active,
  .about:focus {
    border-bottom: 0rem solid var(--hover-purple);
    cursor: pointer;
  }
  .privacy-policy-link:hover,
  .about:hover {
    color: var(--text-purple);
    transform: scale(1.05);
    cursor: pointer;
  }
  .subscribeNote {
    margin-left: -15rem;
    width: 30rem;
    font-size: 0.75rem;
    color: white;
  }
  /* loader new start  */
  .loader:empty {
    position: absolute;
    top: calc(50% + 5.5rem);
    left: calc(50% + 2em);
    width: 4em;
    height: 4em;
    border: 0.35em solid white;
    border-left: 0.35em solid #10034B;
    border-radius: 50%;
    animation: load8 0.8s infinite linear;
  }
  .loadingTitle {
    position: absolute;
    top: 17rem;
    left: calc(50% - 3em);
    font-size: 2rem;
    color: #10034B;
    -webkit-box-reflect:below 0px linear-gradient(transparent, #0005);
  }
  .loaderLogo {
    position: absolute;
    top: 4rem;
    left: calc(50% - 8em);
    width: 15em;
    height: 12em;
  }
  @keyframes load8 {
    0% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(360deg);
    }
  }
  /* loader new end  */
  .C {
    font-size: 1rem;
  }
  .logo {
    width:  6.75rem;
    height: 5.75rem;
    margin-left: 2rem;
    margin-top: -0.5rem;
  }
  .data-logo {
    max-width: 1rem !important;
    height: 1rem !important;
    position: absolute;
  }
  .brand-text {
    color: var(--text-purple);
    display: inline;
    margin-left: 0rem;
    letter-spacing: 0.0375rem;
    font-size: 1.25rem;
  }
  .header-text {
    font-weight: 400;
    font-size: 1.75rem;
    margin-left: 3.25rem;
    margin-top: 105px;
    color: var(--text-purple);

  }
  .header-sub-text {
    font-weight: 400;
    font-size: 1.25rem;
    text-align: center!important;
    margin: auto!important;
    width: 90%;
    color: var(--text-purple);
    display: block;
 
  }
  nav a.TGN {
    display: none;
  }
  .navbar-nav > li > a.TGN:link {
    display: none;
  }
  .navbar-nav > li > a.TGN:visited {
    display: none;
  }
  .navbar-nav > li > a.TGN.active,
  .navbar-nav > li > a.TGN:focus {
    display: none;
  }
  .navbar-nav > li > a.TGN:hover {
    display: none;
  }
  nav a.nav-link {
    font-weight: 600;
    font-size: 1.15rem;
    color: var(--text-purple);
  }
  .navbar-nav > li > a {
    border-bottom: 0.01875rem solid var(--transparent);
    padding: 0;
  }
  .navbar-nav > li > a:link {
    color: var(--hover-purple);
  }
  .navbar-nav > li > a:visited {
    color: var(--text-purple);
  }
  .navbar-nav > li > a.active,
  .navbar-nav > li > a:focus {
    border-bottom: 0.01875rem solid var(--hover-purple);
    padding: 0;
    transition: all 0.5s ease-in-out;
  }
  .navbar-nav > li > a:hover {
    border-bottom: 0.01875rem solid var(--hover-purple);
    padding: 0;
    color: var(--hover-purple);
    transform: scale(1.05);
  }
  .line {
    margin-left: 1rem;
  }
  .links {
    text-align: right;
    padding-right: 0;
    margin: auto;
    padding: auto;
  }
  .dropdown {
    margin-left: auto;
    padding: auto;
  }
  .bar {
    margin-bottom: 0.1rem;
    margin-right: 2rem;
  }
  .line {
    margin-bottom: 0.1rem;
    margin-right: 2rem;
  }
  .about {
    margin-left: auto;
    margin-right: 0.8rem;
  }
  .menu-icon {
    color: var(--secondary-purple);
  }
  .main-info {
    background-image: url("TopBackground.svg");
    background-repeat: no-repeat;
    background-size: 100% auto;
    width: 100%;
    height: 100%;
    margin-top: 90px;
  }
  .community-links {
    margin-left: 2rem;
    padding-top: 0.5rem;
    position: absolute;
    z-index: 999;
  }
  .telegram-icon,
  .twitter-icon,
  .reddit-icon,
  .youtube-icon {
    display: inline;
    padding: 1rem;
    transition: all 0.5s ease-in-out;
    z-index: 999;
  }
  .icon:hover {
    color: var(--hover-purple);
    transform: scale(1.15);
  }
  .icon-youtube:hover {
    color: var(--text-purple);
    transform: scale(1.1);
  }
  .news-title {
    text-shadow: 2.25px 2.25px 3px var(--hover-purple);
    color: var(--text-purple);
    text-align: center;
    margin-top: 1rem;
    margin-left: -18rem;
    margin-bottom: 1rem;
    font-size: 1.5rem;
  }
  .newsGraph1,
  .newsGraph2,
  .newsGraph3 {
    margin-bottom: 1rem;
  }
  .youtube {
    z-index: 999;
    display: inline;
    position: absolute;
    text-align: center;
    color: var(--text-purple);
  }
  span.anchor {
    display: block;
    visibility: hidden;
    position: relative;
    padding-top: 20%;
  }
  .activeDelegators,
  .holders,
  .curatorCount,
  .subgraphCount {
    font-weight: 400;
    color: var(--text-purple);
    letter-spacing: 0.0375rem;
  }
  .hr {
    border: 3px solid var(--hr);
    border-radius: 5px;
    width: 90%;
    margin: auto;
    box-shadow: 0 0 10px 3px var(--hover-purple);
    position: relative;
    margin-top: 2.5rem;
  }
  .hr2 {
    border: 3px solid var(--hr);
    border-radius: 5px;
    width: 90%;
    margin: auto;
    box-shadow: 0 0 10px 3px var(--hover-purple);
    position: relative;
    margin-top: 1.5rem;
  }
  .video {
    position: absolute;
    box-shadow: #4D1D86 4px 8px 10px 0;
    text-align: center;
    top: 0%;
    left: 50%;
  }
  .g-ytsubscribe {
    position: absolute;
    font-family: Euclid Circular A;
    font-weight: 400;
    margin: 0;
    left: 80%;
    top: 41%;
  }
  .data-title {
    letter-spacing: 0.0375rem;
    font-weight: 400;
    color: var(--text-purple);
    font-size: 3.5rem;
    text-align: center;
    position: relative;
  }
  .chartsTitle {
    letter-spacing: 0.0375rem;
    font-weight: 400;
    color: var(--text-purple);
    font-size: 3.5rem;
    text-align: center;
   
    position: relative;
  }
  .chartsTitle2 {
    letter-spacing: 0.0375rem;
    font-weight: 400;
    color: var(--text-purple);
    font-size: 3.5rem;
    text-align: center;

  }
  .newsGraph {
    width: 100%;
    box-shadow: #4D1D86 4px 5px 10px 3px;
  }
  .chartImg {
    width: 100%;
    box-shadow: #4D1D86 6px 6px 10px 3px;
    margin: auto;
  }
  .center {
    margin: auto;
  }
  .line1 {
    margin-top: 5rem;
  }
  .chartImg1 {
    text-align: center;
    padding-top: -7rem;
    padding-bottom: 1rem;
    width: 100%;
    margin-top: -130px;
    margin-bottom: -150px;
  }
  .chartImg2,
  .chartImg3,
  .chartImg4,
  .chartImg5 {
    text-align: center;
    padding-top: 1rem;
    padding-bottom: 1rem;
    width: 100%;
    margin-top: -150px;
    margin-bottom: -150px;
  }
  .chartImg6 {
    text-align: center;
    padding-top: 1rem;
    width: 100%;

  }
  .reveal {
    position: relative;
    transform: translateY(50px);
    opacity: 0;
    transition: 0.75s all ease;
  }
  .reveal.active {
    transform: translateY(0);
    opacity: 1;
  }
  .email {
    color: var(--hover-purple);
    font-weight: 500;
  }
  .footerLink {
    color: var(--hover-purple);
  }
  .footerLink:link {
    color: var(--hover-purple);
    cursor: pointer;
  }
  .footerLink:visited {
    color: var(--hover-purple);
    cursor: pointer;
  }
  .footerLink.active,
  .footerLink:focus {
    border-bottom: 0rem solid var(--hover-purple);
    cursor: pointer;
  }
  .footerLink:hover {
    color: var(--text-purple);
    transform: scale(1.05);
    cursor: pointer;
  }
  .footer {
    margin-bottom: -1rem;
    padding: 1rem;
    font-weight: 700;
    margin-top: 1rem;
    font-size: 1.1rem;
    text-align: center;
    background-color: var(--primary-purple);
    color: var(--secondary-purple);
  }
  .tsparticles {
    display: none;
  }
  .rotate-position,
  .about-rotate-position,
  .phone,
  .about-phone,
  .message,
  .about-message, .home-message {
    display: none;
  }
  .adminHeader-text {
    font-weight: 400;
    font-size: 1.5rem;
    text-align: center;
    margin: auto;
    padding-top: 1rem;
    color: var(--text-purple);
    
    margin-top: 6rem;
  }
  .adminHeader-text2 {
    font-weight: 400;
    font-size: 1.5rem;
    text-align: center;
    margin: auto;
    margin-right: 3rem;
    color: var(--text-purple);
    
    margin-top: 2rem;
  }
  .adminSubheader-text {
    font-weight: 400;
    font-size: 1rem;
    margin-left: 3rem;
    color: var(--text-purple);
    
    margin-top: 1rem;
  }
  .adminSubheader-text2 {
    font-weight: 400;
    font-size: 1rem;
    margin-left: 3rem;
    color: var(--text-purple);
  
    margin-top: 1rem;
  }
  .note {
    text-decoration: underline;
  }

  .adminHr2 {
    border: 3px solid var(--hr);
    border-radius: 5px;
    width: 70%;
    margin: auto;
    box-shadow: 0 0 10px 3px var(--hover-purple);
    margin-top: 1rem;
    margin-left: 1.9rem;
  }

 .admin-video {
    position: absolute;
    left: 48%;
    top: 20%;
    width: 10%;
    height: 10%;
  }

  .adminChartsTitle {
    letter-spacing: 0.0375rem;
    font-weight: 400;
    color: var(--text-purple);
    font-size: 2rem;
    text-align: center;
    padding-top: 1rem;
    text-shadow: 2.5px 2.5px 4px var(--hover-purple);
    position: relative;
  }
  .adminChartsTitle2 {
    letter-spacing: 0.0375rem;
    font-weight: 400;
    color: var(--text-purple);
    font-size: 2rem;
    margin-top: 1rem;
    text-align: center;
    text-shadow: 2.5px 2.5px 4px var(--hover-purple);
  }
}
/* *************************************************************************
****************************************************************************
***************************************************************************/
/* *************************************************************************
****************************************************************************
***************************************************************************/
@media screen and (min-width: 851px) and (max-width: 852px) {
  .flashcards-title {
margin-top: 120px!important;
}
  .hide-line {
    color: transparent!important;
    display: block!important;
  }
  .Quiz-button {
    margin-bottom: 2rem;
  }
  .quiz-title {
    margin-top: -60px;
  }
  .quiz-button-position {
    padding-top: 2rem;
    margin: auto;
    text-align: center;
    margin-bottom: 2rem;
  }
  
  .final-exam-question {
      font-size: 1.3rem;
      text-align: center;
      color: #d8d2e4;
      /* remove for mobile */
      margin-top: 0rem;
      /* remove for mobile */
  }
  
  .final-exam-answer-form {
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin-top: 0rem;
    columns: 1!important;
    width: 100%!important;
  }
  
  .form-check-label {
    font-size: 20px;
    text-align: center;
    height: 110%;
  }
  .quiz-question {
    font-size: 1.3rem;
    padding-bottom: 2rem;
  text-align: center;
  width: 80%;
  margin: auto;
    text-align: center;
    color: #d8d2e4;
  }
  .quiz-question-container {
    margin-top: 20px;
    width: 100%;
  }

  .quiz-answer-form {
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin-top: 0rem;
    columns: 1!important;
    width: 100%!important;
  }

  .quiz-position {
    margin-top: -10px;
  }
  .mobile-portrait {
    display: none;
    }
  .adminHeader-text {
    font-weight: 400;
    font-size: 1.5rem;
    text-align: center;
    margin: auto;
    padding-top: 1rem;
    color: var(--text-purple);
    
    margin-top: 6rem;
  }
  .adminHeader-text2 {
    font-weight: 400;
    font-size: 1.5rem;
    text-align: center;
    margin: auto;
    color: var(--text-purple);
    
    margin-top: 2rem;
  }
  .adminSubheader-text {
    font-weight: 400;
    font-size: 1rem;
    margin-left: 3rem;
    color: var(--text-purple);
    
    margin-top: 1rem;
  }
  .adminSubheader-text2 {
    font-weight: 400;
    font-size: 1rem;
    margin-left: 3rem;
    margin-right: 3rem;
    color: var(--text-purple);

    margin-top: 1rem;
  }
  .monitor-line-carousel {
    display: none;
  }
  
  .monitor-bar-carousel {
   display: none;
  }
  
  .monitor-other-carousel {
    display: none;
  }
  
  .monitor-other-charts-position {
    display: none;
  }
  .mobile-bar-carousel {
    width: 80%;
  height: 500px;
  margin: auto auto;
  padding-top: -1rem;
  padding-bottom: -5rem;
  margin-bottom: -6rem;
  overflow: hidden;
  }
  .mobile-line-carousel {
    width: 80%;
    height: 500px;
    margin: auto auto;
    padding-top: 0rem;
    overflow: hidden;
  }

  .mobile-line-pos {
padding-top: 3rem;
  }
  .more-data-hr {
    margin-top: 1rem!important;
  }
  .more-data-title {
    margin-bottom: -12rem!important;
  }
  .mobile-other-carousel {
    width: 110%;
    height: 550px;
    margin: auto auto;
    margin-left: -2.5rem;
    padding-top: 1rem!important;
    overflow: hidden;
  }
  .map {
    width: 100%;
    margin-left: 5%;
    box-shadow: #4D1D86 4px 5px 10px 3px;
  }
  .holders-pos {
    margin-top: -8rem;
    margin-left: 1%;
    padding-bottom: 2rem;
  }
  .css-1fzpoyk {
    width: 100%;
    padding-top: 1rem;
  }

  .extra-data {
    margin-left: 2rem;
    margin-right: 2rem;
    font-size: 1.25rem;
    text-align: center;
    color: var(--text-purple);
  }
  .data-title {
    letter-spacing: 0.0375rem;
    font-weight: 400;
    color: var(--text-purple);
    font-size: 2rem;
    text-align: center;
    position: relative;
  }
  .chartsTitle {
    letter-spacing: 0.0375rem;
    font-weight: 400;
    color: var(--text-purple);
    font-size: 2rem;
    text-align: center;
    position: relative;
    margin-bottom: -13rem;
  }
  .chartsTitle2 {
    letter-spacing: 0.0375rem;
    font-weight: 400;
    color: var(--text-purple);
    font-size: 2rem;
    text-align: center;
    margin-bottom: -10rem;
  }
  .hr {
    border: 3px solid var(--hr);
    border-radius: 5px;
    width: 90%;
    margin: auto;
    box-shadow: 0 0 10px 3px var(--hover-purple);
    position: relative;
    margin-top: 3rem;
  }
  .hr2 {
    border: 3px solid var(--hr);
    border-radius: 5px;
    width: 90%;
    margin: auto;
    box-shadow: 0 0 10px 3px var(--hover-purple);
    position: relative;
    margin-top: 3rem;
  }
 
  .move-up {
    margin-top: -45rem;
  }
.hide-me {
  display: none;
}
  .css-1qzevvg img {
    height: 100%;
    margin-top: -10rem;
    text-align: center;
    margin-left: -1rem;
    z-index: 999999;
    position: relative;
}

/* above me */
  .mobile-dropdown {
    margin-left: 0.75rem!important;
  }
  .offcanvas-body {
    width: 100%!important;
    opacity: 0.9;
  }
  .offcanvas {
    width: 40%!important;
    background-color: var(--background)!important;
  }

 
   /* chatbot height start */
.react-chatbot-kit-chat-inner-container {
  background-color: var(--text-purple)!important;
  height: 260px!important;
}
.react-chatbot-kit-chat-message-container {
  height: 220px!important;
}
  /* chatbot height end */
/* dune start */
.GRTDailyTransactionsPosition {
  margin-bottom: 1rem!important;
  margin-left: -15px!important;
 }
 .HoldersGraph {
  background-image: linear-gradient(90deg, #1B0D33, #D8D2E4, #D8D2E4,#D8D2E4, #D8D2E4,#D8D2E4,#D8D2E4,#D8D2E4,#D8D2E4,#D8D2E4,#D8D2E4,#D8D2E4,#D8D2E4,#D8D2E4,#D8D2E4,#D8D2E4,#D8D2E4,#D8D2E4,#1B0D33);
  margin-bottom: -36px!important;
  margin-left: -10px!important;
  padding-left: 20px!important;
  padding-right: 20px!important;
  height: 80px!important;
  width: 100%!important;
}
.HoldersGraphBottom {
  background-image: linear-gradient(90deg, #1B0D33, #D8D2E4,#D8D2E4,#D8D2E4,#D8D2E4,#D8D2E4,#D8D2E4,#D8D2E4,#D8D2E4,#D8D2E4,#D8D2E4,#D8D2E4,#D8D2E4,#D8D2E4,#D8D2E4,#D8D2E4,#D8D2E4,#D8D2E4, #1B0D33);
  padding-left: 20px!important;
  margin-left: -10px!important;
  padding-right: 20px!important;
  height: 80px!important;
  width: 100%!important;
}
  .chatbot-footer-bottom {
    margin-bottom: -80px!important;
   }
  .chatbot-link-logo{
    margin-top: 2rem;
    z-index: 9998;
    position: fixed;
    max-width: 4rem;
    background: white;
    -moz-border-radius: 50px;
    -webkit-border-radius: 50px;
    border-radius: 50px;
    padding: -1rem;
    right: 2%;
    border: 3px var(--secondary-purple) solid;
    box-shadow: 0 0 0 var(--hover-purple);
    animation: pulse 2s infinite;
  }
  .advocate-link-logo{
    margin-top: 4rem;
    z-index: 9998;
    position: fixed;
    max-width: 4rem;
    background: white;
    -moz-border-radius: 50px;
    -webkit-border-radius: 50px;
    border-radius: 50px;
    padding: -1rem;
    right: 2%;
    border: 3px var(--secondary-purple) solid;
    box-shadow: 0 0 0 var(--hover-purple);
    animation: pulse 2s infinite;
  }
  @-webkit-keyframes pulse {
    0% {
      -webkit-box-shadow: 0 0 0 0  var(--text-purple);
    }
    70% {
        -webkit-box-shadow: 0 0 0 15px rgba(244,157,22, 0);
    }
    100% {
        -webkit-box-shadow: 0 0 0 0 rgba(244,157,22, 0);
    }
  }
  @keyframes pulse {
    0% {
      -moz-box-shadow: 0 0 0 0  var(--text-purple);
      box-shadow: 0 0 0 0  var(--text-purple);
    }
    70% {
        -moz-box-shadow: 0 0 0 15px rgba(244,157,22, 0);
        box-shadow: 0 0 0 15px rgba(244,157,22, 0);
    }
    100% {
        -moz-box-shadow: 0 0 0 0 rgba(244,157,22, 0);
        box-shadow: 0 0 0 0 rgba(244,157,22, 0);
    }
  }
  
  .chatbot-link-export{
    margin-top: 8rem;
    z-index: 9998;
    position: fixed;
    max-width: 4rem;
    background: white;
    -moz-border-radius: 50px;
    -webkit-border-radius: 50px;
    border-radius: 50px;
    padding: -1rem;
    right: 0.5rem;
    border: 3px var(--secondary-purple) solid;
    box-shadow: 0 0 0 var(--hover-purple);
    animation: pulse 2s infinite;
  }
/* Chatbot */
.feedback-text {
  margin-top: 2rem;
}
.feedback-button {
  border: 2px var(--primary-purple) solid!important;
  background-color: var(--text-purple)!important;
  color: var(--primary-purple)!important;
  padding: auto!important;
  margin: auto!important;
}

  .feedback-button:link {
    cursor: pointer;
  }
  .feedback-button:visited {
    cursor: pointer;
  }
  .feedback-button.active,
  .feedback-button:focus {
    cursor: pointer;
  }
  .feedback-button:hover {
    background-color: var(--background)!important;
    color: var(--text-purple)!important;
    transform: scale(1.015);
    cursor: pointer;
  }
  .feedback-button-text {
    font-size: 1.5rem;
  }

.react-chatbot-kit-chat-inner-container {
  background-color: var(--text-purple)!important;
}

.react-chatbot-kit-chat-header {
  font-size: 1.1rem!important;
  color: var(--text-purple)!important;
  background-color: var(--primary-purple)!important;
  padding: 0rem!important;
}

.react-chatbot-kit-chat-input-container {
  background-color:var(--secondary-purple)!important;
  border: 1px solid var(--primary-purple)!important;
}

.react-chatbot-kit-chat-btn-send {
  background-color:var(--primary-purple)!important;
}

.react-chatbot-kit-chat-btn-send-icon:hover {
transform: scale(1.25);
}

.react-chatbot-kit-chat-bot-message-container {
  width: 100%;
}

.react-chatbot-kit-chat-bot-avatar-container {
}

.react-chatbot-kit-chat-bot-avatar-icon {
}

.react-chatbot-kit-chat-bot-avatar-letter {
  display: none;
}
.react-chatbot-kit-chat-container {
  width: 98%!important;
  text-align: center;
  margin: auto;
}

.react-chatbot-kit-chat-bot-avatar-container {
  width: 3.5rem!important;
  height: 3.5rem!important;
  background-color: white!important;
  border: 2px solid var(--primary-purple);
  background-image: url('./GRTDataHubChatBot.png')!important;
  background-repeat: no-repeat!important;
  background-size: 3rem;
  background-position-x: 50%;
  background-position-y: 20%;
}

.react-chatbot-kit-chat-bot-message {
  text-align: left!important;
  width: 100%!important;
  color: var(--text-purple)!important;
  background-color: #0C0B23!important;
  font-size: 1rem!important;
}

.react-chatbot-kit-chat-bot-message-arrow {
  color: #0C0B23!important;
  border-right: 8px solid #0C0B23!important;
}
  .indexer-table-header {
    text-align: center;
    margin-left: 1rem;
  }
  .indexer-table {
    text-align: center;
  }
  .chatbot-data {
    display: inline!important;
  }
  .telegramActiveDelegationCount {
    max-width: 92%;
    margin: auto; 
    text-align: center;
    margin-top: 0.5%;
    }
    .top-telegram {
      margin-top: 0.25rem;
    }
    .chatbot-top {
      margin-top: 5rem;
    }
    .chatbot-page-container {
      background: var(--background);
      color: var(--text-purple);
      width: 100%;
      margin: auto;
      margin-top: 100px;
      padding-left: 10px;
      padding-right: 10px;
      text-align: center;
    }
    .chatbot-text-container {
      background: whitesmoke;
      border-radius: 1rem;
      color: var(--secondary-purple);
      margin: auto!important;
      padding-bottom: 10px;
      padding-top: 10px;
      text-align: center!important;
      padding-left: 15px;
      padding-right: 15px;
    }
    .chatbot-page-title {
      font-size: 1.25rem;
      font-weight: 600;
      color: var(--secondary-purple);
      padding-bottom: 0.25rem;
      padding-left: 5px;
      padding-right: 5px;
    }
    .chatbot-page-subtitle {
      font-size: 1.75rem;
      padding-left: 5px;
      padding-right: 5px;
    }
    .chatbot-page-text {
      font-size: 1.25rem;
      margin-bottom: 1rem;
      margin-top: 1rem;
      padding-left: 10px;
      padding-right: 10px;
    }
    /* chatbot end */
  .dataHub-logos {
    max-width: 6rem !important;
    max-height: 35rem !important;
    position: absolute;
    top: 0;
    margin-top: 1rem;
  }
  .monitor-config {
    display: none!important;
  }
  .hide-telegram {
    display: none;
  }
  body > div:nth-child(1) > button {
    display: none!important;
  }
  .pages {
    margin-bottom: 0.1rem;
    margin-right: 2rem;
}
.indexer-title {
  text-align: center!important;
  font-weight: 400;
  font-size: 1.5rem;
  padding-top: 2rem;
  color: var(--text-purple);
  
  margin-top: 6rem;
}
.indexer-subtitle {
  text-align: center!important;
  font-weight: 400;
  font-size: 1.25rem;
  color: var(--text-purple);
  
  margin-top: 1rem;
  margin-right: 2rem;
  margin-left: 2rem;
}
.indexer {
  z-index: 999;
  position: absolute;
  left: 0%;
  top: 50%;
}

.curator {
  z-index: 999;
  position: absolute;
  text-align: center!important;
  top: 50%;
}

.tokens, .rewards, .uncollected-rewards {
  margin-top: 2rem;
  width: 90%;
  height: 14rem;
  margin-left: 5%;
  box-shadow: #4D1D86 4px 5px 10px 3px;
}

.b-map {
  margin-top: -2rem;
  margin-left: 1rem;
  width: 720px;
  height: 3200px;
  text-align: center!important;
  box-shadow: #4D1D86 4px 5px 10px 3px;
}

.c-map {
  margin-top: 2rem;
  margin-left: 1rem;
  width: 720px;
  height: 1000px;
  text-align: center!important;
  box-shadow: #4D1D86 4px 5px 10px 3px;
}

.d-map {
  margin-top: -3rem;
  margin-left: 3rem;
  width: 720px;
  height: 3800px;
  text-align: center!important;
  box-shadow: #4D1D86 4px 5px 10px 3px;
}

.rewards-per-hour {
  margin-top: 2rem;
  padding-bottom: 2rem;
  width: 90%;
  height: 14rem;
  margin-left: 5%;
  box-shadow: #4D1D86 4px 5px 10px 3px;
}

.indexers {
  padding-left: 1rem;
  padding-left: 1rem;
  color: grey;
  font-size:1.25rem;
  margin-right: 1rem;
}
.indexers:link{
  color: grey;
  cursor: pointer;
  font-size:1.25rem;
  margin-right: 1rem;
}
.indexers:visited {
  color: grey;
  cursor: pointer;
  font-size:1.25rem;
  margin-right: 1rem;
}
.indexers.active,
.indexers:focus {
  border-bottom: 0rem solid var(--hover-purple);
  cursor: pointer;
  font-size:1.25rem;
  margin-right: 1rem;
}
.indexers:hover {
  color: var(--text-purple);
  transform: scale(1.05);
  cursor: pointer;
  font-size:1.25rem;
  margin-right: 1rem;
}
.indexer-link {
  color: white;
}
.indexer-link:link {
  color: white;
  cursor: pointer;
}
.indexer-link:visited {
  color: var(--text-purple);
  cursor: pointer;
}
.indexer-link.active,
.indexer-link:focus {
  border-bottom: 0rem solid var(--text-purple);
  cursor: pointer;
}
.indexer-link:hover {
  color: var(--text-purple);
  transform: scale(1.05);
  cursor: pointer;
}
  .cookie-page-text {
    padding-top: 1rem;
    font-size: 1.25rem;
    margin-bottom: 1rem;
    padding-left: 20px;
    padding-right: 20px;
  }
  .cookies-video {
    padding-top: 2rem;
  }
  .cookie-button {
    z-index: 9999;
    position: absolute;
  }

  body > div:nth-child(1) > button {
    z-index: 10000;
    font-size: 3rem;
    position: absolute;
    padding: auto;
    margin-top: -85px;
    width: 45px;
    height: 45px;
    margin-left: 83%;
    background-color: transparent;
    border: none;
    color: var(--text-purple);
  }
  .extra-data {
    margin-top: 2rem;
    font-size: 1.25rem;
    text-align: center;
    color: var(--text-purple);
   
  }
  .extra-data2 {
    font-size: 1.25rem;
    text-align: center;
    color: var(--text-purple);
   
  }
  .data-link {
    color: grey;
  }
  .data-link:link {
    color: grey;
    cursor: pointer;
  }
  .data-link:visited {
    color: grey;
    cursor: pointer;
  }
  .data-link.active,
  .data-link:focus {
    border-bottom: 0rem solid white;
    cursor: pointer;
  }
  .data-link:hover {
    color: white;
    transform: scale(1.05);
    cursor: pointer;
  }
  .question-bank-container {
    background: var(--background);
    color: var(--text-purple);
    width: 100%;
    margin: auto;
    margin-top: 150px;
    margin-bottom: 50px;
    padding-left: 50px;
    padding-right: 50px;
    text-align: center;
  }
  .about-page-container {
    background: var(--background);
    color: var(--text-purple);
    width: 100%;
    margin: auto;
    margin-top: 150px;
    margin-bottom: 50px;
    padding-left: 50px;
    padding-right: 50px;
    text-align: center;
  }
  .QB-text-container {
    background: white;
    border-radius: 1rem;
    padding: 30px;
    color: var(--secondary-purple);
  }
  .text-container {
    background: white;
    border-radius: 1rem;
    padding: 30px;
    color: var(--secondary-purple);
  }
  .about-hr {
    border: 1.5px solid var(--background);
    color: var(--background);
    border-radius: 5px;
    width: 35%;
    margin: auto;
    margin-bottom: 1rem;
    box-shadow: 0 0 5px 1px var(--background);
  }
  .about-page-title {
    font-size: 2rem;
    font-weight: 600;
    color: var(--secondary-purple);
    padding-bottom: 3rem;
  }
  .about-page-subtitle {
    font-size: 1.75rem;
  }
  .about-page-text {
    font-size: 1.25rem;
    margin-bottom: 1rem;
  }
  .graphiql-link {
    color: var(--secondary-purple);
  }
  .graphiql-link:link {
    color: var(--secondary-purple);
    cursor: pointer;
  }
  .graphiql-link:visited {
    color: var(--secondary-purple);
    cursor: pointer;
  }
  .graphiql-link.active,
  .graphiql-link:focus {
    border-bottom: 0rem solid var(--hover-purple);
    cursor: pointer;
  }
  .graphiql-link:hover {
    color: var(--primary-purple);
    transform: scale(1.05);
    cursor: pointer;
  }
  .cookies-policy-container {
    background: white;
    width: 90%;
    padding-bottom: 1rem;
    margin: auto;
    border-radius: 2rem;
    margin-top: 150px;
    margin-bottom: 50px;
  }
  .termly-cookie-preference-button {
   display: none;
  }


  .privacy-policy-container {
    background: white;
    width: 90%;
    padding-bottom: 1rem;
    margin: auto;
    border-radius: 2rem;
    margin-top: 150px;
    margin-bottom: 50px;
  }
  .privacy-policy {
    margin-top: 110px;
    font-weight: 700;
    font-size: 1.1rem;
    text-align: center;
    background-color: var(--background);
    color: var(--secondary-purple);
  }
  .privacy-policy-link,
  .about {
    padding-left: 1rem;
    padding-left: 1rem;
    color: grey;
  }
  .privacy-policy-link:link,
  .about:link {
    color: grey;
    cursor: pointer;
  }
  .privacy-policy-link:visited,
  .about:visited {
    color: grey;
    cursor: pointer;
  }
  .privacy-policy-link.active,
  .privacy-policy-link:focus,
  .about.active,
  .about:focus {
    border-bottom: 0rem solid var(--hover-purple);
    cursor: pointer;
  }
  .privacy-policy-link:hover,
  .about:hover {
    color: var(--text-purple);
    transform: scale(1.05);
    cursor: pointer;
  }
  .subscribeNote {
    margin-left: -15rem;
    width: 30rem;
    font-size: 0.75rem;
    color: white;
  }
  /* loader new start  */
  .loader:empty {
    position: absolute;
    top: calc(50% + 5.5rem);
    left: calc(50% + 2em);
    width: 4em;
    height: 4em;
    border: 0.35em solid white;
    border-left: 0.35em solid #10034B;
    border-radius: 50%;
    animation: load8 0.8s infinite linear;
  }
  .loadingTitle {
    position: absolute;
    top: 17rem;
    left: calc(50% - 3em);
    font-size: 2rem;
    color: #10034B;
    -webkit-box-reflect:below 0px linear-gradient(transparent, #0005);
  }
  .loaderLogo {
    position: absolute;
    top: 4rem;
    left: calc(50% - 8em);
    width: 15em;
    height: 12em;
  }
  @keyframes load8 {
    0% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(360deg);
    }
  }
  /* loader new end  */
  .C {
    font-size: 1rem;
  }
  .logo {
    width: 5.75rem;
    height: 4.75rem;
    margin-left: 2rem;
    margin-top: -0.5rem;
  }
  .data-logo {
    max-width: 1rem !important;
    height: 1rem !important;
    position: absolute;
  }
  .brand-text {
    color: var(--text-purple);
    display: inline;
    margin-left: 0rem;
    letter-spacing: 0.0375rem;
    font-size: 1.25rem;
  }
  .header-text {
    font-weight: 400;
    font-size: 2rem;
    padding-top: 1rem;
    text-align: center;
    margin-top: 105px;
    margin-left: 2rem;
    color: var(--text-purple);

  }
  .header-sub-text {
    font-weight: 400;
    text-align: center!important;
    margin: auto!important;
    font-size: 1.5rem;
    width: 90%;
    color: var(--text-purple);
    display: block;
    text-align: center;
   
  }
  nav a.TGN {
    display: none;
  }
  .navbar-nav > li > a.TGN:link {
    display: none;
  }
  .navbar-nav > li > a.TGN:visited {
    display: none;
  }
  .navbar-nav > li > a.TGN.active,
  .navbar-nav > li > a.TGN:focus {
    display: none;
  }
  .navbar-nav > li > a.TGN:hover {
    display: none;
  }
  nav a.nav-link {
    font-family: Euclid Circular A;
    font-weight: 600;
    font-size: 1.15rem;
    color: var(--text-purple);
  }
  .navbar-nav > li > a {
    border-bottom: 0.01875rem solid var(--transparent);
    padding: 0;
  }
  .navbar-nav > li > a:link {
    color: var(--hover-purple);
  }
  .navbar-nav > li > a:visited {
    color: var(--text-purple);
  }
  .navbar-nav > li > a.active,
  .navbar-nav > li > a:focus {
    border-bottom: 0.01875rem solid var(--hover-purple);
    padding: 0;
    transition: all 0.5s ease-in-out;
  }
  .navbar-nav > li > a:hover {
    border-bottom: 0.01875rem solid var(--hover-purple);
    padding: 0;
    color: var(--hover-purple);
    transform: scale(1.05);
  }
  .line {
    margin-left: 1rem;
  }
  .links {
    text-align: right;
    padding-right: 0;
    margin: auto;
    padding: auto;
  }
  .dropdown {
    margin-left: auto;
    padding: auto;
  }
  .bar {
    margin-bottom: 0.1rem;
    margin-right: 2rem;
  }
  .line {
    margin-bottom: 0.1rem;
    margin-right: 2rem;
  }
  .about {
    margin-left: auto;
    margin-right: 0.8rem;
  }
  .menu-icon {
    color: var(--secondary-purple);
  }
  .main-info {
    background-image: url("TopBackground.svg");
    background-repeat: no-repeat;
    background-size: 100% auto;
    width: 100%;
    height: 100%;
    margin-top: 90px;
  }
  .community-links {
    text-align: center;
    padding-top: 5rem;
    position: absolute;
    z-index: 999;
    left: 35%;
  }
  .telegram-icon,
  .twitter-icon,
  .reddit-icon,
  .youtube-icon {
    display: inline;
    padding: 1rem;
    transition: all 0.5s ease-in-out;
    z-index: 999;
  }
  .icon:hover {
    color: var(--hover-purple);
    transform: scale(1.15);
  }
  .icon-youtube:hover {
    color: var(--text-purple);
    transform: scale(1.1);
  }
  .news-title {
    text-shadow: 2.25px 2.25px 3px var(--hover-purple);
    color: var(--text-purple);
    text-align: center;
    margin-top: 1rem;
    margin-left: -18rem;
    margin-bottom: 1rem;
    font-size: 1.5rem;
  }
  .newsGraph1,
  .newsGraph2,
  .newsGraph3 {
    margin-bottom: 1rem;
  }
  .youtube {
    z-index: 999;
    display: inline;
    position: absolute;
    text-align: center;
    color: var(--text-purple);
  }
  span.anchor {
    display: block;
    visibility: hidden;
    position: relative;
    padding-top: 20%;
  }
  .activeDelegators,
  .holders,
  .curatorCount,
  .subgraphCount {
    font-weight: 400;
    color: var(--text-purple);
    letter-spacing: 0.0375rem;
  }

  .video {
    position: absolute;
    box-shadow: #4D1D86 4px 8px 10px 0;
    text-align: center;
    margin-top: 3rem;
    margin-left: 25%;
  }
  .g-ytsubscribe {
    position: absolute;
    font-family: Euclid Circular A;
    font-weight: 400;
    margin: 0;
    left: 58%;
    top: 84%;
  }
  
  .newsGraph {
    width: 100%;
    box-shadow: #4D1D86 4px 5px 10px 3px;
  }
  .chartImg {
    width: 100%;
    box-shadow: #4D1D86 6px 6px 10px 3px;
    margin: auto;
  }
  .center {
    margin: auto;
  }
  .line1 {
    margin-top: 5rem;
  }

  .chartImg6 {
    text-align: center;
    padding-top: 1rem;
    width: 100%;
  
  }
  .reveal {
    position: relative;
    transform: translateY(50px);
    opacity: 0;
    transition: 0.75s all ease;
  }
  .reveal.active {
    transform: translateY(0);
    opacity: 1;
  }
  .email {
    color: var(--hover-purple);
    font-weight: 500;
  }
  .footerLink {
    color: var(--hover-purple);
  }
  .footerLink:link {
    color: var(--hover-purple);
    cursor: pointer;
  }
  .footerLink:visited {
    color: var(--hover-purple);
    cursor: pointer;
  }
  .footerLink.active,
  .footerLink:focus {
    border-bottom: 0rem solid var(--hover-purple);
    cursor: pointer;
  }
  .footerLink:hover {
    color: var(--text-purple);
    transform: scale(1.05);
    cursor: pointer;
  }
  .footer {
    margin-bottom: -1rem;
    padding: 1rem;
    font-weight: 700;
    margin-top: 1rem;
    font-size: 1.1rem;
    text-align: center;
    background-color: var(--primary-purple);
    color: var(--secondary-purple);
  }
  .tsparticles {
    display: none;
  }
  .rotate-position,
  .about-rotate-position,
  .phone,
  .about-phone,
  .message,
  .about-message, .home-message {
    display: none;
  }
 
  .note {
    text-decoration: underline;
  }

 .admin-video {
    position: absolute;
    left: 48%;
    top: 20%;
    width: 10%;
    height: 10%;
  }
}
/* *************************************************************************
****************************************************************************
***************************************************************************/
/* *************************************************************************
****************************************************************************
***************************************************************************/
@media screen and (min-width: 821px) and (max-width: 850px) {
  .flashcards-title {
margin-top: 120px!important;
}
  .hide-line {
    color: transparent!important;
    display: block!important;
  }
  .Quiz-button {
    margin-bottom: 2rem;
  }
  .quiz-title {
    margin-top: -60px;
  }
  .quiz-button-position {
    padding-top: 2rem;
    margin: auto;
    text-align: center;
    margin-bottom: 2rem;
  }
  
  .final-exam-question {
      font-size: 1.3rem;
      text-align: center;
      color: #d8d2e4;
      /* remove for mobile */
      margin-top: 0rem;
      /* remove for mobile */
  }
  
  .final-exam-answer-form {
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin-top: 0rem;
    columns: 1!important;
    width: 100%!important;
  }
  
  .form-check-label {
    font-size: 20px;
    text-align: center;
    height: 110%;
  }
  .quiz-question {
    font-size: 1.3rem;
    padding-bottom: 2rem;
  text-align: center;
  width: 80%;
  margin: auto;
    text-align: center;
    color: #d8d2e4;
  }
  .quiz-question-container {
    margin-top: 20px;
    width: 100%;
  }

  .quiz-answer-form {
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin-top: 0rem;
    columns: 1!important;
    width: 100%!important;
  }

  .quiz-position {
    margin-top: -10px;
  }
  .mobile-portrait {
    display: none;
    }
  .adminHeader-text {
    font-weight: 400;
    font-size: 1.5rem;
    text-align: center;
    margin: auto;
    padding-top: 1rem;
    color: var(--text-purple);
    
    margin-top: 6rem;
  }
  .adminHeader-text2 {
    font-weight: 400;
    font-size: 1.5rem;
    text-align: center;
    margin: auto;
    color: var(--text-purple);
    
    margin-top: 2rem;
  }
  .adminSubheader-text {
    font-weight: 400;
    font-size: 1rem;
    margin-left: 3rem;
    color: var(--text-purple);
    
    margin-top: 1rem;
  }
  .adminSubheader-text2 {
    font-weight: 400;
    font-size: 1rem;
    margin-left: 3rem;
    margin-right: 3rem;
    color: var(--text-purple);

    margin-top: 1rem;
  }
  .monitor-line-carousel {
    display: none;
  }
  
  .monitor-bar-carousel {
   display: none;
  }
  
  .monitor-other-carousel {
    display: none;
  }
  
  .monitor-other-charts-position {
    display: none;
  }
  .mobile-bar-carousel {
    width: 80%;
  height: 500px;
  margin: auto auto;
  padding-top: -1rem;
  padding-bottom: -5rem;
  margin-bottom: -6rem;
  overflow: hidden;
  }
  .mobile-line-carousel {
    width: 80%;
    height: 500px;
    margin: auto auto;
    padding-top: 0rem;
    overflow: hidden;
  }

  .mobile-line-pos {
padding-top: 3rem;
  }
  .more-data-hr {
    margin-top: 1rem!important;
  }
  .more-data-title {
    margin-bottom: -12rem!important;
  }
  .mobile-other-carousel {
    width: 110%;
    height: 550px;
    margin: auto auto;
    margin-left: -2.5rem;
    padding-top: 1rem!important;
    overflow: hidden;
  }
  .map {
    width: 100%;
    margin-left: 5%;
    box-shadow: #4D1D86 4px 5px 10px 3px;
  }
  .holders-pos {
    margin-top: -6rem;
    margin-left: 1%;
    padding-bottom: 2rem;
  }
  .css-1fzpoyk {
    width: 100%;
    padding-top: 1rem;
  }

  .extra-data {
    margin-left: 2rem;
    margin-right: 2rem;
    font-size: 1.25rem;
    text-align: center;
    color: var(--text-purple);
  }
  .data-title {
    letter-spacing: 0.0375rem;
    font-weight: 400;
    color: var(--text-purple);
    font-size: 2rem;
    text-align: center;
    position: relative;
  }
  .chartsTitle {
    letter-spacing: 0.0375rem;
    font-weight: 400;
    color: var(--text-purple);
    font-size: 2rem;
    text-align: center;
    position: relative;
    margin-bottom: -13rem;
  }
  .chartsTitle2 {
    letter-spacing: 0.0375rem;
    font-weight: 400;
    color: var(--text-purple);
    font-size: 2rem;
    text-align: center;
    margin-bottom: -10rem;
  }
  .hr {
    border: 3px solid var(--hr);
    border-radius: 5px;
    width: 90%;
    margin: auto;
    box-shadow: 0 0 10px 3px var(--hover-purple);
    position: relative;
    margin-top: 3rem;
  }
  .hr2 {
    border: 3px solid var(--hr);
    border-radius: 5px;
    width: 90%;
    margin: auto;
    box-shadow: 0 0 10px 3px var(--hover-purple);
    position: relative;
    margin-top: 3rem;
  }
 
  .move-up {
    margin-top: -45rem;
  }
.hide-me {
  display: none;
}
  .css-1qzevvg img {
    height: 100%;
    margin-top: -10rem;
    text-align: center;
    margin-left: -1rem;
    z-index: 999999;
    position: relative;
}

/* above me */
  .mobile-dropdown {
    margin-left: 1.5rem!important;
  }
  .offcanvas-body {
    width: 100%!important;
    opacity: 0.9;
  }
  .offcanvas {
    width: 40%!important;
    background-color: var(--background)!important;
  }

   /* chatbot height start */
.react-chatbot-kit-chat-inner-container {
  background-color: var(--text-purple)!important;
  height: 290px!important;
}
.react-chatbot-kit-chat-message-container {
  height: 250px!important;
}
  /* chatbot height end */
/* dune start */
.GRTDailyTransactionsPosition {
  margin-bottom: 1rem!important;
  margin-left: -15px!important;
 }
 .HoldersGraph {
  background-image: linear-gradient(90deg, #1B0D33, #D8D2E4, #D8D2E4,#D8D2E4, #D8D2E4,#D8D2E4,#D8D2E4,#D8D2E4,#D8D2E4,#D8D2E4,#D8D2E4,#D8D2E4,#D8D2E4,#D8D2E4,#D8D2E4,#D8D2E4,#D8D2E4,#D8D2E4,#1B0D33);
  margin-bottom: -36px!important;
  margin-left: -10px!important;
  padding-left: 20px!important;
  padding-right: 20px!important;
  height: 80px!important;
  width: 100%!important;
}
.HoldersGraphBottom {
  background-image: linear-gradient(90deg, #1B0D33, #D8D2E4,#D8D2E4,#D8D2E4,#D8D2E4,#D8D2E4,#D8D2E4,#D8D2E4,#D8D2E4,#D8D2E4,#D8D2E4,#D8D2E4,#D8D2E4,#D8D2E4,#D8D2E4,#D8D2E4,#D8D2E4,#D8D2E4, #1B0D33);
  padding-left: 20px!important;
  margin-left: -10px!important;
  padding-right: 20px!important;
  height: 80px!important;
  width: 100%!important;
}
  .chatbot-footer-bottom {
    margin-bottom: -80px!important;
   }
  .chatbot-link-logo{
    margin-top: 3rem;
    z-index: 9998;
    position: fixed;
    max-width: 4rem;
    background: white;
    -moz-border-radius: 50px;
    -webkit-border-radius: 50px;
    border-radius: 50px;
    padding: -1rem;
    right: 2%;
    border: 3px var(--secondary-purple) solid;
    box-shadow: 0 0 0 var(--hover-purple);
    animation: pulse 2s infinite;
  }
  .advocate-link-logo{
    margin-top: 10rem;
    z-index: 9998;
    position: fixed;
    max-width: 4rem;
    background: white;
    -moz-border-radius: 50px;
    -webkit-border-radius: 50px;
    border-radius: 50px;
    padding: -1rem;
    right: 2%;
    border: 3px var(--secondary-purple) solid;
    box-shadow: 0 0 0 var(--hover-purple);
    animation: pulse 2s infinite;
  }
  @-webkit-keyframes pulse {
    0% {
      -webkit-box-shadow: 0 0 0 0  var(--text-purple);
    }
    70% {
        -webkit-box-shadow: 0 0 0 15px rgba(244,157,22, 0);
    }
    100% {
        -webkit-box-shadow: 0 0 0 0 rgba(244,157,22, 0);
    }
  }
  @keyframes pulse {
    0% {
      -moz-box-shadow: 0 0 0 0  var(--text-purple);
      box-shadow: 0 0 0 0  var(--text-purple);
    }
    70% {
        -moz-box-shadow: 0 0 0 15px rgba(244,157,22, 0);
        box-shadow: 0 0 0 15px rgba(244,157,22, 0);
    }
    100% {
        -moz-box-shadow: 0 0 0 0 rgba(244,157,22, 0);
        box-shadow: 0 0 0 0 rgba(244,157,22, 0);
    }
  }
  
  .chatbot-link-export{
    margin-top: 7rem;
    z-index: 9998;
    position: fixed;
    max-width: 4rem;
    background: white;
    -moz-border-radius: 50px;
    -webkit-border-radius: 50px;
    border-radius: 50px;
    padding: -1rem;
    right: 2%;
    border: 3px var(--secondary-purple) solid;
        box-shadow: 0 0 0 var(--hover-purple);
    animation: pulse 2s infinite;
  }

  /* Chatbot */
.feedback-text {
  margin-top: 2rem;
}
.feedback-button {
  border: 2px var(--primary-purple) solid!important;
  background-color: var(--text-purple)!important;
  color: var(--primary-purple)!important;
  padding: auto!important;
  margin: auto!important;
}

  .feedback-button:link {
    cursor: pointer;
  }
  .feedback-button:visited {
    cursor: pointer;
  }
  .feedback-button.active,
  .feedback-button:focus {
    cursor: pointer;
  }
  .feedback-button:hover {
    background-color: var(--background)!important;
    color: var(--text-purple)!important;
    transform: scale(1.015);
    cursor: pointer;
  }
  .feedback-button-text {
    font-size: 1.5rem;
  }

  /* chatbot height start */
.react-chatbot-kit-chat-inner-container {
  background-color: var(--text-purple)!important;
  height: 290px!important;
}
.react-chatbot-kit-chat-message-container {
  height: 250px!important;
}
  /* chatbot height end */
.react-chatbot-kit-chat-header {
  font-size: 1.1rem!important;
  color: var(--text-purple)!important;
  background-color: var(--primary-purple)!important;
  padding: 0rem!important;
}

.react-chatbot-kit-chat-input-container {
  background-color:var(--secondary-purple)!important;
  border: 1px solid var(--primary-purple)!important;
}

.react-chatbot-kit-chat-btn-send {
  background-color:var(--primary-purple)!important;
}

.react-chatbot-kit-chat-btn-send-icon:hover {
transform: scale(1.25);
}

.react-chatbot-kit-chat-bot-message-container {
  width: 100%;
}

.react-chatbot-kit-chat-bot-avatar-container {
}

.react-chatbot-kit-chat-bot-avatar-icon {
}

.react-chatbot-kit-chat-bot-avatar-letter {
  display: none;
}
.react-chatbot-kit-chat-container {
  width: 98%!important;
  text-align: center;
  margin: auto;
}

.react-chatbot-kit-chat-bot-avatar-container {
  width: 3.5rem!important;
  height: 3.5rem!important;
  background-color: white!important;
  border: 2px solid var(--primary-purple);
  background-image: url('./GRTDataHubChatBot.png')!important;
  background-repeat: no-repeat!important;
  background-size: 3rem;
  background-position-x: 50%;
  background-position-y: 20%;
}

.react-chatbot-kit-chat-bot-message {
  text-align: left!important;
  width: 100%!important;
  color: var(--text-purple)!important;
  background-color: #0C0B23!important;
  font-size: 1rem!important;
}

.react-chatbot-kit-chat-bot-message-arrow {
  color: #0C0B23!important;
  border-right: 8px solid #0C0B23!important;
}
  .indexer-table-header {
    text-align: center;
    margin-left: 1rem;
  }
  .indexer-table {
    text-align: center;
  }
  .chatbot-data {
    display: inline!important;
  }
  .telegramActiveDelegationCount {
    max-width: 92%;
    margin: auto; 
    text-align: center;
    margin-top: 0.5%;
    }
    .top-telegram {
      margin-top: 0.25rem;
    }
    .chatbot-top {
      margin-top: 5rem;
    }
    .chatbot-page-container {
      background: var(--background);
      color: var(--text-purple);
      width: 100%;
      margin: auto;
      margin-top: 100px;
      padding-left: 10px;
      padding-right: 10px;
      text-align: center;
    }
    .chatbot-text-container {
      background: whitesmoke;
      border-radius: 1rem;
      color: var(--secondary-purple);
      margin: auto!important;
      padding-bottom: 10px;
      padding-top: 10px;
      text-align: center!important;
      padding-left: 15px;
      padding-right: 15px;
    }
    .chatbot-page-title {
      font-size: 1.25rem;
      font-weight: 600;
      color: var(--secondary-purple);
      padding-bottom: 0.25rem;
      padding-left: 5px;
      padding-right: 5px;
    }
    .chatbot-page-subtitle {
      font-size: 1.75rem;
      padding-left: 5px;
      padding-right: 5px;
    }
    .chatbot-page-text {
      font-size: 1.25rem;
      margin-bottom: 1rem;
      margin-top: 1rem;
      padding-left: 10px;
      padding-right: 10px;
    }
    /* chatbot end */
    .logo {
      width:  6.75rem;
      height: 5.75rem;
      margin-left: 2rem;
      margin-top: -0.5rem;
    }
  .dataHub-logos {
    max-width: 6rem !important;
    max-height: 35rem !important;
    position: absolute;
    top: 0;
    margin-top: 1rem;
  }
  .monitor-config {
    display: none!important;
  }
  body > div:nth-child(1) > button {
    display: none!important;
  }
  .pages {
    margin-bottom: 0.1rem;
    margin-right: 2rem;
}
.indexer-title {
  text-align: center!important;
  font-weight: 400;
  font-size: 1.5rem;
  padding-top: 2rem;
  color: var(--text-purple);
  
  margin-top: 6rem;
}
.indexer-subtitle {
  text-align: center!important;
  font-weight: 400;
  font-size: 1.25rem;
  color: var(--text-purple);
  
  margin-top: 1rem;
  margin-right: 2rem;
  margin-left: 2rem;
}
.indexer {
  z-index: 999;
  position: absolute;
  left: 0%;
  top: 50%;
  width: 100%;
}

.curator {
  z-index: 999;
  position: absolute;
  text-align: center!important;
  top: 50%;
}

.tokens, .rewards, .uncollected-rewards {
  margin-top: 2rem;
  width: 90%;
  height: 14rem;
  margin-left: 5%;
  box-shadow: #4D1D86 4px 5px 10px 3px;
}

.b-map {
  margin-top: -2rem;
  margin-left: 1rem;
  width: 750px;
  height: 3200px;
  text-align: center!important;
  box-shadow: #4D1D86 4px 5px 10px 3px;
}

.c-map {
  margin-top: 2rem;
  margin-left: 1rem;
  width: 750px;
  height: 1000px;
  text-align: center!important;
  box-shadow: #4D1D86 4px 5px 10px 3px;
}

.d-map {
  margin-top: -3rem;
  margin-left: 3rem;
  width: 750px;
  height: 3800px;
  text-align: center!important;
  box-shadow: #4D1D86 4px 5px 10px 3px;
}

.rewards-per-hour {
  margin-top: 2rem;
  padding-bottom: 2rem;
  width: 90%;
  height: 14rem;
  margin-left: 5%;
  box-shadow: #4D1D86 4px 5px 10px 3px;
}

.indexers {
  padding-left: 1rem;
  padding-left: 1rem;
  color: grey;
  font-size:1.25rem;
  margin-right: 1rem;
}
.indexers:link{
  color: grey;
  cursor: pointer;
  font-size:1.25rem;
  margin-right: 1rem;
}
.indexers:visited {
  color: grey;
  cursor: pointer;
  font-size:1.25rem;
  margin-right: 1rem;
}
.indexers.active,
.indexers:focus {
  border-bottom: 0rem solid var(--hover-purple);
  cursor: pointer;
  font-size:1.25rem;
  margin-right: 1rem;
}
.indexers:hover {
  color: var(--text-purple);
  transform: scale(1.05);
  cursor: pointer;
  font-size:1.25rem;
  margin-right: 1rem;
}
.indexer-link {
  color: white;
}
.indexer-link:link {
  color: white;
  cursor: pointer;
}
.indexer-link:visited {
  color: var(--text-purple);
  cursor: pointer;
}
.indexer-link.active,
.indexer-link:focus {
  border-bottom: 0rem solid var(--text-purple);
  cursor: pointer;
}
.indexer-link:hover {
  color: var(--text-purple);
  transform: scale(1.05);
  cursor: pointer;
}
  .cookie-page-text {
    padding-top: 1rem;
    font-size: 1.25rem;
    margin-bottom: 1rem;
    padding-left: 20px;
    padding-right: 20px;
  }
  .cookies-video {
    padding-top: 2rem;
  }
  .cookie-button {
    z-index: 9999;
    position: absolute;
  }

  body > div:nth-child(1) > button {
    z-index: 10000;
    font-size: 3rem;
    position: absolute;
    padding: auto;
    margin-top: -85px;
    width: 45px;
    height: 45px;
    margin-left: 85%;
    background-color: transparent;
    border: none;
    color: var(--text-purple);
  }

  .extra-data2 {
    font-size: 1.25rem;
    text-align: center;
    color: var(--text-purple);
   
  }
  .data-link {
    color: grey;
  }
  .data-link:link {
    color: grey;
    cursor: pointer;
  }
  .data-link:visited {
    color: grey;
    cursor: pointer;
  }
  .data-link.active,
  .data-link:focus {
    border-bottom: 0rem solid white;
    cursor: pointer;
  }
  .data-link:hover {
    color: white;
    transform: scale(1.05);
    cursor: pointer;
  }
  .question-bank-container {
    background: var(--background);
    color: var(--text-purple);
    width: 100%;
    margin: auto;
    margin-top: 150px;
    margin-bottom: 50px;
    padding-left: 50px;
    padding-right: 50px;
    text-align: center;
  }
  .about-page-container {
    background: var(--background);
    color: var(--text-purple);
    width: 100%;
    margin: auto;
    margin-top: 150px;
    margin-bottom: 50px;
    padding-left: 50px;
    padding-right: 50px;
    text-align: center;
  }
  .QB-text-container {
    background: white;
    border-radius: 1rem;
    padding: 30px;
    color: var(--secondary-purple);
  }
  .text-container {
    background: white;
    border-radius: 1rem;
    padding: 30px;
    color: var(--secondary-purple);
  }
  .QB-about-hr {
    border: 1.5px solid var(--background);
    color: var(--background);
    border-radius: 5px;
    width: 35%;
    margin: auto;
    margin-bottom: 1rem;
    box-shadow: 0 0 5px 1px var(--background);
  }
  .QB-about-page-title {
    font-size: 2rem;
    font-weight: 600;
    color: var(--secondary-purple);
    padding-bottom: 3rem;
  }
  .QB-about-page-subtitle {
    font-size: 1.75rem;
  }
  .QB-about-page-text {
    font-size: 1.25rem;
    margin-bottom: 1rem;
  }
  .about-hr {
    border: 1.5px solid var(--background);
    color: var(--background);
    border-radius: 5px;
    width: 35%;
    margin: auto;
    margin-bottom: 1rem;
    box-shadow: 0 0 5px 1px var(--background);
  }
  .about-page-title {
    font-size: 2rem;
    font-weight: 600;
    color: var(--secondary-purple);
    padding-bottom: 3rem;
  }
  .about-page-subtitle {
    font-size: 1.75rem;
  }
  .about-page-text {
    font-size: 1.25rem;
    margin-bottom: 1rem;
  }
  .graphiql-link {
    color: var(--secondary-purple);
  }
  .graphiql-link:link {
    color: var(--secondary-purple);
    cursor: pointer;
  }
  .graphiql-link:visited {
    color: var(--secondary-purple);
    cursor: pointer;
  }
  .graphiql-link.active,
  .graphiql-link:focus {
    border-bottom: 0rem solid var(--hover-purple);
    cursor: pointer;
  }
  .graphiql-link:hover {
    color: var(--primary-purple);
    transform: scale(1.05);
    cursor: pointer;
  }
  .cookies-policy-container {
    background: white;
    width: 90%;
    padding-bottom: 1rem;
    margin: auto;
    border-radius: 2rem;
    margin-top: 150px;
    margin-bottom: 50px;
  }
  .termly-cookie-preference-button {
   display: none;
  }

  .privacy-policy-container {
    background: white;
    width: 90%;
    padding-bottom: 1rem;
    margin: auto;
    border-radius: 2rem;
    margin-top: 150px;
    margin-bottom: 50px;
  }
  .privacy-policy {
    margin-top: 110px;
    font-weight: 700;
    font-size: 1.1rem;
    text-align: center;
    background-color: var(--background);
    color: var(--secondary-purple);
  }
  .privacy-policy-link,
  .about {
    padding-left: 1rem;
    padding-left: 1rem;
    color: grey;
  }
  .privacy-policy-link:link,
  .about:link {
    color: grey;
    cursor: pointer;
  }
  .privacy-policy-link:visited,
  .about:visited {
    color: grey;
    cursor: pointer;
  }
  .privacy-policy-link.active,
  .privacy-policy-link:focus,
  .about.active,
  .about:focus {
    border-bottom: 0rem solid var(--hover-purple);
    cursor: pointer;
  }
  .privacy-policy-link:hover,
  .about:hover {
    color: var(--text-purple);
    transform: scale(1.05);
    cursor: pointer;
  }
  .subscribeNote {
    margin-left: -15rem;
    width: 30rem;
    font-size: 0.75rem;
    color: white;
  }
  /* loader new start  */
  .loader:empty {
    position: absolute;
    top: calc(50% + 5.5rem);
    left: calc(50% + 2em);
    width: 4em;
    height: 4em;
    border: 0.35em solid white;
    border-left: 0.35em solid #10034B;
    border-radius: 50%;
    animation: load8 0.8s infinite linear;
  }
  .loadingTitle {
    position: absolute;
    top: 17rem;
    left: calc(50% - 3em);
    font-size: 2rem;
    color: #10034B;
    -webkit-box-reflect:below 0px linear-gradient(transparent, #0005);
  }
  .loaderLogo {
    position: absolute;
    top: 4rem;
    left: calc(50% - 8em);
    width: 15em;
    height: 12em;
  }
  @keyframes load8 {
    0% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(360deg);
    }
  }
  /* loader new end  */
  .C {
    font-size: 1rem;
  }
  .logo {
    width:  6.75rem;
    height: 5.75rem;
    margin-left: 2rem;
    margin-top: -0.5rem;
  }
  .data-logo {
    max-width: 1rem !important;
    height: 1rem !important;
    position: absolute;
  }
  .brand-text {
    color: var(--text-purple);
    display: inline;
    margin-left: 0rem;
    letter-spacing: 0.0375rem;
    font-size: 1.25rem;
  }
  .header-text {
    font-weight: 400;
    font-size: 1.75rem;
    margin-left: 3.25rem;
    margin-top: 105px;
    color: var(--text-purple);
 
  }
  .header-sub-text {
    font-weight: 400;
    font-size: 1.25rem;
    text-align: center!important;
    margin: auto!important;
    width: 90%;
    color: var(--text-purple);
    display: block;
 
  }
  nav a.TGN {
    display: none;
  }
  .navbar-nav > li > a.TGN:link {
    display: none;
  }
  .navbar-nav > li > a.TGN:visited {
    display: none;
  }
  .navbar-nav > li > a.TGN.active,
  .navbar-nav > li > a.TGN:focus {
    display: none;
  }
  .navbar-nav > li > a.TGN:hover {
    display: none;
  }
  nav a.nav-link {
    font-weight: 600;
    font-size: 1.15rem;
    color: var(--text-purple);
  }
  .navbar-nav > li > a {
    border-bottom: 0.01875rem solid var(--transparent);
    padding: 0;
  }
  .navbar-nav > li > a:link {
    color: var(--hover-purple);
  }
  .navbar-nav > li > a:visited {
    color: var(--text-purple);
  }
  .navbar-nav > li > a.active,
  .navbar-nav > li > a:focus {
    border-bottom: 0.01875rem solid var(--hover-purple);
    padding: 0;
    transition: all 0.5s ease-in-out;
  }
  .navbar-nav > li > a:hover {
    border-bottom: 0.01875rem solid var(--hover-purple);
    padding: 0;
    color: var(--hover-purple);
    transform: scale(1.05);
  }
  .line {
    margin-left: 1rem;
  }
  .links {
    text-align: right;
    padding-right: 0;
    margin: auto;
    padding: auto;
  }
  .dropdown {
    margin-left: auto;
    padding: auto;
  }
  .bar {
    margin-bottom: 0.1rem;
    margin-right: 2rem;
  }
  .line {
    margin-bottom: 0.1rem;
    margin-right: 2rem;
  }
  .about {
    margin-left: auto;
    margin-right: 0.8rem;
  }
  .menu-icon {
    color: var(--secondary-purple);
  }
  .main-info {
    background-image: url("TopBackground.svg");
    background-repeat: no-repeat;
    background-size: 100% auto;
    width: 100%;
    height: 100%;
    margin-top: 90px;
  }
  .community-links {
    margin-left: 2rem;
    padding-top: 0.5rem;
    position: absolute;
    z-index: 999;
  }
  .telegram-icon,
  .twitter-icon,
  .reddit-icon,
  .youtube-icon {
    display: inline;
    padding: 1rem;
    transition: all 0.5s ease-in-out;
    z-index: 999;
  }
  .icon:hover {
    color: var(--hover-purple);
    transform: scale(1.15);
  }
  .icon-youtube:hover {
    color: var(--text-purple);
    transform: scale(1.1);
  }
  .news-title {
    text-shadow: 2.25px 2.25px 3px var(--hover-purple);
    color: var(--text-purple);
    text-align: center;
    margin-top: 1rem;
    margin-left: -18rem;
    margin-bottom: 1rem;
    font-size: 1.5rem;
  }
  .newsGraph1,
  .newsGraph2,
  .newsGraph3 {
    margin-bottom: 1rem;
  }
  .youtube {
    z-index: 999;
    display: inline;
    position: absolute;
    text-align: center;
    color: var(--text-purple);
  }
  span.anchor {
    display: block;
    visibility: hidden;
    position: relative;
    padding-top: 20%;
  }
  .activeDelegators,
  .holders,
  .curatorCount,
  .subgraphCount {
    font-weight: 400;
    color: var(--text-purple);
    letter-spacing: 0.0375rem;
  }
 
  .video {
    position: absolute;
    box-shadow: #4D1D86 4px 8px 10px 0;
    text-align: center;
    top: 0%;
    left: 50%;
  }
  .g-ytsubscribe {
    position: absolute;
    font-family: Euclid Circular A;
    font-weight: 400;
    margin: 0;
    left: 80%;
    top: 41%;
  }
  .newsGraph {
    width: 100%;
    box-shadow: #4D1D86 4px 5px 10px 3px;
  }
  .chartImg {
    width: 100%;
    box-shadow: #4D1D86 6px 6px 10px 3px;
    margin: auto;
  }
  .center {
    margin: auto;
  }
  .line1 {
    margin-top: 5rem;
  }
 
  .chartImg6 {
    text-align: center;
    padding-top: 1rem;
    width: 100%;

  }
  .reveal {
    position: relative;
    transform: translateY(50px);
    opacity: 0;
    transition: 0.75s all ease;
  }
  .reveal.active {
    transform: translateY(0);
    opacity: 1;
  }
  .email {
    color: var(--hover-purple);
    font-weight: 500;
  }
  .footerLink {
    color: var(--hover-purple);
  }
  .footerLink:link {
    color: var(--hover-purple);
    cursor: pointer;
  }
  .footerLink:visited {
    color: var(--hover-purple);
    cursor: pointer;
  }
  .footerLink.active,
  .footerLink:focus {
    border-bottom: 0rem solid var(--hover-purple);
    cursor: pointer;
  }
  .footerLink:hover {
    color: var(--text-purple);
    transform: scale(1.05);
    cursor: pointer;
  }
  .footer {
    margin-bottom: -1rem;
    padding: 1rem;
    font-weight: 700;
    margin-top: 1rem;
    font-size: 1.1rem;
    text-align: center;
    background-color: var(--primary-purple);
    color: var(--secondary-purple);
  }
  .tsparticles {
    display: none;
  }
  .rotate-position,
  .about-rotate-position,
  .phone,
  .about-phone,
  .message,
  .about-message, .home-message {
    display: none;
  }
 
  .note {
    text-decoration: underline;
  }

 

 .admin-video {
    position: absolute;
    left: 48%;
    top: 20%;
    width: 10%;
    height: 10%;
  }

}
/* *************************************************************************
****************************************************************************
***************************************************************************/
/* *************************************************************************
****************************************************************************
***************************************************************************/
@media screen and (min-width: 814px) and (max-width: 820px) {
  .flashcards-title {
margin-top: 120px!important;
}
  .hide-line {
    color: transparent!important;
    display: block!important;
  }
  .Quiz-button {
    margin-bottom: 2rem;
  }
  .quiz-title {
    margin-top: -60px;
  }
  .quiz-button-position {
    padding-top: 2rem;
    margin: auto;
    text-align: center;
    margin-bottom: 2rem;
  }
  
  .final-exam-question {
      font-size: 1.3rem;
      text-align: center;
      color: #d8d2e4;
      /* remove for mobile */
      margin-top: 0rem;
      /* remove for mobile */
  }
  
  .final-exam-answer-form {
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin-top: 0rem;
    columns: 1!important;
    width: 100%!important;
  }
  
  .form-check-label {
    font-size: 20px;
    text-align: center;
    height: 110%;
  }
  .quiz-question {
    font-size: 1.3rem;
    padding-bottom: 2rem;
  text-align: center;
  width: 80%;
  margin: auto;
    text-align: center;
    color: #d8d2e4;
  }
  .quiz-question-container {
    margin-top: 20px;
    width: 100%;
  }

  .quiz-answer-form {
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin-top: 0rem;
    columns: 1!important;
    width: 100%!important;
  }

  .quiz-position {
    margin-top: -10px;
  }
  .mobile-landscape {
    display: none;
    }
  .mobile-dropdown {
    margin-left: 0.75rem!important;
  }
  .offcanvas-body {
    width: 100%!important;
    opacity: 0.9;
  }
  .offcanvas {
    width: 40%!important;
    background-color: var(--background)!important;
  }

   /* chatbot height start */
.react-chatbot-kit-chat-inner-container {
  background-color: var(--text-purple)!important;
  height: 260px!important;
}
.react-chatbot-kit-chat-message-container {
  height: 220px!important;
}
  /* chatbot height end */
/* dune start */
.GRTDailyTransactionsPosition {
  margin-bottom: 1rem!important;
  margin-left: -15px!important;
 }
 .HoldersGraph {
  background-image: linear-gradient(90deg, #1B0D33, #D8D2E4, #D8D2E4,#D8D2E4, #D8D2E4,#D8D2E4,#D8D2E4,#D8D2E4,#D8D2E4,#D8D2E4,#D8D2E4,#D8D2E4,#D8D2E4,#D8D2E4,#D8D2E4,#D8D2E4,#D8D2E4,#D8D2E4,#1B0D33);
  margin-bottom: -36px!important;
  margin-left: -10px!important;
  padding-left: 20px!important;
  padding-right: 20px!important;
  height: 80px!important;
  width: 100%!important;
}
.HoldersGraphBottom {
  background-image: linear-gradient(90deg, #1B0D33, #D8D2E4,#D8D2E4,#D8D2E4,#D8D2E4,#D8D2E4,#D8D2E4,#D8D2E4,#D8D2E4,#D8D2E4,#D8D2E4,#D8D2E4,#D8D2E4,#D8D2E4,#D8D2E4,#D8D2E4,#D8D2E4,#D8D2E4, #1B0D33);
  padding-left: 20px!important;
  margin-left: -10px!important;
  padding-right: 20px!important;
  height: 80px!important;
  width: 100%!important;
}
  .chatbot-footer-bottom {
    margin-bottom: -80px!important;
   }
  .chatbot-link-logo{
    margin-top: 2rem;
    z-index: 9998;
    position: fixed;
    max-width: 4rem;
    background: white;
    -moz-border-radius: 50px;
    -webkit-border-radius: 50px;
    border-radius: 50px;
    padding: -1rem;
   right: 2%;
    border: 3px var(--secondary-purple) solid;
    box-shadow: 0 0 0 var(--hover-purple);
    animation: pulse 2s infinite;
  }
  .advocate-link-logo{
    margin-top: 8rem;
    z-index: 9998;
    position: fixed;
    max-width: 4rem;
    background: white;
    -moz-border-radius: 50px;
    -webkit-border-radius: 50px;
    border-radius: 50px;
    padding: -1rem;
   right: 2%;
    border: 3px var(--secondary-purple) solid;
    box-shadow: 0 0 0 var(--hover-purple);
    animation: pulse 2s infinite;
  }
  @-webkit-keyframes pulse {
    0% {
      -webkit-box-shadow: 0 0 0 0  var(--text-purple);
    }
    70% {
        -webkit-box-shadow: 0 0 0 15px rgba(244,157,22, 0);
    }
    100% {
        -webkit-box-shadow: 0 0 0 0 rgba(244,157,22, 0);
    }
  }
  @keyframes pulse {
    0% {
      -moz-box-shadow: 0 0 0 0  var(--text-purple);
      box-shadow: 0 0 0 0  var(--text-purple);
    }
    70% {
        -moz-box-shadow: 0 0 0 15px rgba(244,157,22, 0);
        box-shadow: 0 0 0 15px rgba(244,157,22, 0);
    }
    100% {
        -moz-box-shadow: 0 0 0 0 rgba(244,157,22, 0);
        box-shadow: 0 0 0 0 rgba(244,157,22, 0);
    }
  }
  
  .chatbot-link-export{
    margin-top: 7.25rem;
    z-index: 9998;
    position: fixed;
    max-width: 4rem;
    background: white;
    -moz-border-radius: 50px;
    -webkit-border-radius: 50px;
    border-radius: 50px;
    padding: -1rem;
   right: 2%;
    border: 3px var(--secondary-purple) solid;
    box-shadow: 0 0 0 var(--hover-purple);
    animation: pulse 2s infinite;
  }
/* Chatbot */
.feedback-text {
  margin-top: 2rem;
}
.feedback-button {
  border: 2px var(--primary-purple) solid!important;
  background-color: var(--text-purple)!important;
  color: var(--primary-purple)!important;
  padding: auto!important;
  margin: auto!important;
}

  .feedback-button:link {
    cursor: pointer;
  }
  .feedback-button:visited {
    cursor: pointer;
  }
  .feedback-button.active,
  .feedback-button:focus {
    cursor: pointer;
  }
  .feedback-button:hover {
    background-color: var(--background)!important;
    color: var(--text-purple)!important;
    transform: scale(1.015);
    cursor: pointer;
  }
  .feedback-button-text {
    font-size: 1.5rem;
  }

.react-chatbot-kit-chat-inner-container {
  background-color: var(--text-purple)!important;
}

.react-chatbot-kit-chat-header {
  font-size: 1.1rem!important;
  color: var(--text-purple)!important;
  background-color: var(--primary-purple)!important;
  padding: 0rem!important;
}

.react-chatbot-kit-chat-input-container {
  background-color:var(--secondary-purple)!important;
  border: 1px solid var(--primary-purple)!important;
}

.react-chatbot-kit-chat-btn-send {
  background-color:var(--primary-purple)!important;
}

.react-chatbot-kit-chat-btn-send-icon:hover {
transform: scale(1.25);
}

.react-chatbot-kit-chat-bot-message-container {
  width: 100%;
}

.react-chatbot-kit-chat-bot-avatar-container {
}

.react-chatbot-kit-chat-bot-avatar-icon {
}

.react-chatbot-kit-chat-bot-avatar-letter {
  display: none;
}
.react-chatbot-kit-chat-container {
  width: 98%!important;
  text-align: center;
  margin: auto;
}

.react-chatbot-kit-chat-bot-avatar-container {
  width: 3.5rem!important;
  height: 3.5rem!important;
  background-color: white!important;
  border: 2px solid var(--primary-purple);
  background-image: url('./GRTDataHubChatBot.png')!important;
  background-repeat: no-repeat!important;
  background-size: 3rem;
  background-position-x: 50%;
  background-position-y: 20%;
}

.react-chatbot-kit-chat-bot-message {
  text-align: left!important;
  width: 100%!important;
  color: var(--text-purple)!important;
  background-color: #0C0B23!important;
  font-size: 1rem!important;
}

.react-chatbot-kit-chat-bot-message-arrow {
  color: #0C0B23!important;
  border-right: 8px solid #0C0B23!important;
}
  .indexer-table-header {
    text-align: center;
    margin-left: 1rem;
  }
  .indexer-table {
    text-align: center;
  }
  .chatbot-data {
    display: inline!important;
  }
  .telegramActiveDelegationCount {
    max-width: 92%;
    margin: auto; 
    text-align: center;
    margin-top: 0.5%;
    }
    .top-telegram {
      margin-top: 0.25rem;
    }
    .chatbot-top {
      margin-top: 5rem;
    }
    .chatbot-page-container {
      background: var(--background);
      color: var(--text-purple);
      width: 100%;
      margin: auto;
      margin-top: 100px;
      padding-left: 10px;
      padding-right: 10px;
      text-align: center;
    }
    .chatbot-text-container {
      background: whitesmoke;
      border-radius: 1rem;
      color: var(--secondary-purple);
      margin: auto!important;
      padding-bottom: 10px;
      padding-top: 10px;
      text-align: center!important;
      padding-left: 15px;
      padding-right: 15px;
    }
    .chatbot-page-title {
      font-size: 1.25rem;
      font-weight: 600;
      color: var(--secondary-purple);
      padding-bottom: 0.25rem;
      padding-left: 5px;
      padding-right: 5px;
    }
    .chatbot-page-subtitle {
      font-size: 1.75rem;
      padding-left: 5px;
      padding-right: 5px;
    }
    .chatbot-page-text {
      font-size: 1.25rem;
      margin-bottom: 1rem;
      margin-top: 1rem;
      padding-left: 10px;
      padding-right: 10px;
    }
    /* chatbot end */
  .dataHub-logos {
    max-width: 6rem !important;
    max-height: 35rem !important;
    position: absolute;
    top: 0;
    margin-top: 1rem;
  }
  .monitor-config {
    display: none!important;
  }
  .hide-telegram {
    display: none;
  }
  body > div:nth-child(1) > button {
    display: none!important;
  }
  .pages {
    margin-bottom: 0.1rem;
    margin-right: 2rem;
}
.indexer-title {
  text-align: center!important;
  font-weight: 400;
  font-size: 1.5rem;
  padding-top: 2rem;
  color: var(--text-purple);
  
  margin-top: 6rem;
}
.indexer-subtitle {
  text-align: center!important;
  font-weight: 400;
  font-size: 1.25rem;
  color: var(--text-purple);
  
  margin-top: 1rem;
  margin-right: 2rem;
  margin-left: 2rem;
}
.indexer {
  z-index: 999;
  position: absolute;
  left: 0%;
  top: 50%;
}

.curator {
  z-index: 999;
  position: absolute;
  text-align: center!important;
  top: 50%;
}

.map, .tokens, .rewards, .uncollected-rewards {
  margin-top: 2rem;
  width: 90%;
  height: 14rem;
  margin-left: 5%;
  box-shadow: #4D1D86 4px 5px 10px 3px;
}

.b-map {
  margin-top: -2rem;
  margin-left: 1rem;
  width: 750px;
  height: 3200px;
  text-align: center!important;
  box-shadow: #4D1D86 4px 5px 10px 3px;
}

.c-map {
  margin-top: 2rem;
  margin-left: 1rem;
  width: 750px;
  height: 1000px;
  text-align: center!important;
  box-shadow: #4D1D86 4px 5px 10px 3px;
}

.d-map {
  margin-top: -3rem;
  margin-left: 3rem;
  width: 750px;
  height: 3800px;
  text-align: center!important;
  box-shadow: #4D1D86 4px 5px 10px 3px;
}

.rewards-per-hour {
  margin-top: 2rem;
  padding-bottom: 2rem;
  width: 90%;
  height: 14rem;
  margin-left: 5%;
  box-shadow: #4D1D86 4px 5px 10px 3px;
}

.indexers {
  padding-left: 1rem;
  padding-left: 1rem;
  color: grey;
  font-size:1.25rem;
  margin-right: 1rem;
}
.indexers:link{
  color: grey;
  cursor: pointer;
  font-size:1.25rem;
  margin-right: 1rem;
}
.indexers:visited {
  color: grey;
  cursor: pointer;
  font-size:1.25rem;
  margin-right: 1rem;
}
.indexers.active,
.indexers:focus {
  border-bottom: 0rem solid var(--hover-purple);
  cursor: pointer;
  font-size:1.25rem;
  margin-right: 1rem;
}
.indexers:hover {
  color: var(--text-purple);
  transform: scale(1.05);
  cursor: pointer;
  font-size:1.25rem;
  margin-right: 1rem;
}
.indexer-link {
  color: white;
}
.indexer-link:link {
  color: white;
  cursor: pointer;
}
.indexer-link:visited {
  color: var(--text-purple);
  cursor: pointer;
}
.indexer-link.active,
.indexer-link:focus {
  border-bottom: 0rem solid var(--text-purple);
  cursor: pointer;
}
.indexer-link:hover {
  color: var(--text-purple);
  transform: scale(1.05);
  cursor: pointer;
}
  .cookie-page-text {
    padding-top: 1rem;
    font-size: 1.25rem;
    margin-bottom: 1rem;
    padding-left: 20px;
    padding-right: 20px;
  }
  .cookies-video {
    padding-top: 2rem;
  }
  .cookie-button {
    z-index: 9999;
    position: absolute;
  }

  body > div:nth-child(1) > button {
    z-index: 10000;
    font-size: 3rem;
    position: absolute;
    padding: auto;
    margin-top: -85px;
    width: 45px;
    height: 45px;
    margin-left: 83%;
    background-color: transparent;
    border: none;
    color: var(--text-purple);
  }
  .extra-data {
    margin-top: 2rem;
    font-size: 1.25rem;
    text-align: center;
    color: var(--text-purple);
   
  }
  .extra-data2 {
    font-size: 1.25rem;
    text-align: center;
    color: var(--text-purple);
   
  }
  .data-link {
    color: grey;
  }
  .data-link:link {
    color: grey;
    cursor: pointer;
  }
  .data-link:visited {
    color: grey;
    cursor: pointer;
  }
  .data-link.active,
  .data-link:focus {
    border-bottom: 0rem solid white;
    cursor: pointer;
  }
  .data-link:hover {
    color: white;
    transform: scale(1.05);
    cursor: pointer;
  }
  .question-bank-container {
    background: var(--background);
    color: var(--text-purple);
    width: 100%;
    margin: auto;
    margin-top: 150px;
    margin-bottom: 50px;
    padding-left: 50px;
    padding-right: 50px;
    text-align: center;
  }
  .about-page-container {
    background: var(--background);
    color: var(--text-purple);
    width: 100%;
    margin: auto;
    margin-top: 150px;
    margin-bottom: 50px;
    padding-left: 50px;
    padding-right: 50px;
    text-align: center;
  }
  .QB-text-container {
    background: white;
    border-radius: 1rem;
    padding: 30px;
    color: var(--secondary-purple);
  }
  .text-container {
    background: white;
    border-radius: 1rem;
    padding: 30px;
    color: var(--secondary-purple);
  }
  .about-hr {
    border: 1.5px solid var(--background);
    color: var(--background);
    border-radius: 5px;
    width: 35%;
    margin: auto;
    margin-bottom: 1rem;
    box-shadow: 0 0 5px 1px var(--background);
  }
  .about-page-title {
    font-size: 2rem;
    font-weight: 600;
    color: var(--secondary-purple);
    padding-bottom: 3rem;
  }
  .about-page-subtitle {
    font-size: 1.75rem;
  }
  .about-page-text {
    font-size: 1.25rem;
    margin-bottom: 1rem;
  }
  .graphiql-link {
    color: var(--secondary-purple);
  }
  .graphiql-link:link {
    color: var(--secondary-purple);
    cursor: pointer;
  }
  .graphiql-link:visited {
    color: var(--secondary-purple);
    cursor: pointer;
  }
  .graphiql-link.active,
  .graphiql-link:focus {
    border-bottom: 0rem solid var(--hover-purple);
    cursor: pointer;
  }
  .graphiql-link:hover {
    color: var(--primary-purple);
    transform: scale(1.05);
    cursor: pointer;
  }
  .cookies-policy-container {
    background: white;
    width: 90%;
    padding-bottom: 1rem;
    margin: auto;
    border-radius: 2rem;
    margin-top: 150px;
    margin-bottom: 50px;
  }
  .termly-cookie-preference-button {
   display: none;
  }


  .privacy-policy-container {
    background: white;
    width: 90%;
    padding-bottom: 1rem;
    margin: auto;
    border-radius: 2rem;
    margin-top: 150px;
    margin-bottom: 50px;
  }
  .privacy-policy {
    margin-top: 110px;
    font-weight: 700;
    font-size: 1.1rem;
    text-align: center;
    background-color: var(--background);
    color: var(--secondary-purple);
  }
  .privacy-policy-link,
  .about {
    padding-left: 1rem;
    padding-left: 1rem;
    color: grey;
  }
  .privacy-policy-link:link,
  .about:link {
    color: grey;
    cursor: pointer;
  }
  .privacy-policy-link:visited,
  .about:visited {
    color: grey;
    cursor: pointer;
  }
  .privacy-policy-link.active,
  .privacy-policy-link:focus,
  .about.active,
  .about:focus {
    border-bottom: 0rem solid var(--hover-purple);
    cursor: pointer;
  }
  .privacy-policy-link:hover,
  .about:hover {
    color: var(--text-purple);
    transform: scale(1.05);
    cursor: pointer;
  }
  .subscribeNote {
    margin-left: -15rem;
    width: 30rem;
    font-size: 0.75rem;
    color: white;
  }
  /* loader new start  */
  .loader:empty {
    position: absolute;
    top: calc(50% + 5.5rem);
    left: calc(50% + 2em);
    width: 4em;
    height: 4em;
    border: 0.35em solid white;
    border-left: 0.35em solid #10034B;
    border-radius: 50%;
    animation: load8 0.8s infinite linear;
  }
  .loadingTitle {
    position: absolute;
    top: 17rem;
    left: calc(50% - 3em);
    font-size: 2rem;
    color: #10034B;
    -webkit-box-reflect:below 0px linear-gradient(transparent, #0005);
  }
  .loaderLogo {
    position: absolute;
    top: 4rem;
    left: calc(50% - 8em);
    width: 15em;
    height: 12em;
  }
  @keyframes load8 {
    0% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(360deg);
    }
  }
  /* loader new end  */
  .C {
    font-size: 1rem;
  }
  .logo {
    width: 5.75rem;
    height: 4.75rem;
    margin-left: 2rem;
    margin-top: -0.5rem;
  }
  .data-logo {
    max-width: 1rem !important;
    height: 1rem !important;
    position: absolute;
  }
  .brand-text {
    color: var(--text-purple);
    display: inline;
    margin-left: 0rem;
    letter-spacing: 0.0375rem;
    font-size: 1.25rem;
  }
  .header-text {
    font-weight: 400;
    font-size: 2rem;
    padding-top: 1rem;
    text-align: center;
    margin-top: 105px;
    margin-left: 2rem;
    color: var(--text-purple);
 
  }
  .header-sub-text {
    font-weight: 400;
    text-align: center!important;
    margin: auto!important;
    font-size: 1.5rem;
    width: 90%;
    color: var(--text-purple);
    display: block;
    text-align: center;
  
  }
  nav a.TGN {
    display: none;
  }
  .navbar-nav > li > a.TGN:link {
    display: none;
  }
  .navbar-nav > li > a.TGN:visited {
    display: none;
  }
  .navbar-nav > li > a.TGN.active,
  .navbar-nav > li > a.TGN:focus {
    display: none;
  }
  .navbar-nav > li > a.TGN:hover {
    display: none;
  }
  nav a.nav-link {
    font-family: Euclid Circular A;
    font-weight: 600;
    font-size: 1.15rem;
    color: var(--text-purple);
  }
  .navbar-nav > li > a {
    border-bottom: 0.01875rem solid var(--transparent);
    padding: 0;
  }
  .navbar-nav > li > a:link {
    color: var(--hover-purple);
  }
  .navbar-nav > li > a:visited {
    color: var(--text-purple);
  }
  .navbar-nav > li > a.active,
  .navbar-nav > li > a:focus {
    border-bottom: 0.01875rem solid var(--hover-purple);
    padding: 0;
    transition: all 0.5s ease-in-out;
  }
  .navbar-nav > li > a:hover {
    border-bottom: 0.01875rem solid var(--hover-purple);
    padding: 0;
    color: var(--hover-purple);
    transform: scale(1.05);
  }
  .line {
    margin-left: 1rem;
  }
  .links {
    text-align: right;
    padding-right: 0;
    margin: auto;
    padding: auto;
  }
  .dropdown {
    margin-left: auto;
    padding: auto;
  }
  .bar {
    margin-bottom: 0.1rem;
    margin-right: 2rem;
  }
  .line {
    margin-bottom: 0.1rem;
    margin-right: 2rem;
  }
  .about {
    margin-left: auto;
    margin-right: 0.8rem;
  }
  .menu-icon {
    color: var(--secondary-purple);
  }
  .main-info {
    background-image: url("TopBackground.svg");
    background-repeat: no-repeat;
    background-size: 100% auto;
    width: 100%;
    height: 100%;
    margin-top: 90px;
  }
  .community-links {
    text-align: center;
    padding-top: 5rem;
    position: absolute;
    z-index: 999;
    left: 35%;
  }
  .telegram-icon,
  .twitter-icon,
  .reddit-icon,
  .youtube-icon {
    display: inline;
    padding: 1rem;
    transition: all 0.5s ease-in-out;
    z-index: 999;
  }
  .icon:hover {
    color: var(--hover-purple);
    transform: scale(1.15);
  }
  .icon-youtube:hover {
    color: var(--text-purple);
    transform: scale(1.1);
  }
  .news-title {
    text-shadow: 2.25px 2.25px 3px var(--hover-purple);
    color: var(--text-purple);
    text-align: center;
    margin-top: 1rem;
    margin-left: -18rem;
    margin-bottom: 1rem;
    font-size: 1.5rem;
  }
  .newsGraph1,
  .newsGraph2,
  .newsGraph3 {
    margin-bottom: 1rem;
  }
  .youtube {
    z-index: 999;
    display: inline;
    position: absolute;
    text-align: center;
    color: var(--text-purple);
  }
  span.anchor {
    display: block;
    visibility: hidden;
    position: relative;
    padding-top: 20%;
  }
  .activeDelegators,
  .holders,
  .curatorCount,
  .subgraphCount {
    font-weight: 400;
    color: var(--text-purple);
    letter-spacing: 0.0375rem;
  }
  .hr {
    border: 3px solid var(--hr);
    border-radius: 5px;
    width: 90%;
    margin: auto;
    box-shadow: 0 0 10px 3px var(--hover-purple);
    position: relative;
    margin-top: 2.5rem;
  }
  .hr2 {
    border: 3px solid var(--hr);
    border-radius: 5px;
    width: 90%;
    margin: auto;
    box-shadow: 0 0 10px 3px var(--hover-purple);
    position: relative;
    margin-top: 1.5rem;
  }
  .video {
    position: absolute;
    box-shadow: #4D1D86 4px 8px 10px 0;
    text-align: center;
    margin-top: 3rem;
    margin-left: 25%;
  }
  .g-ytsubscribe {
    position: absolute;
    font-family: Euclid Circular A;
    font-weight: 400;
    margin: 0;
    left: 58%;
    top: 84%;
  }
  .data-title {
    letter-spacing: 0.0375rem;
    font-weight: 400;
    color: var(--text-purple);
    font-size: 3.5rem;
    text-align: center;
    position: relative;
  }
  .chartsTitle {
    letter-spacing: 0.0375rem;
    font-weight: 400;
    color: var(--text-purple);
    font-size: 3.5rem;
    text-align: center;
  
    position: relative;
  }
  .chartsTitle2 {
    letter-spacing: 0.0375rem;
    font-weight: 400;
    color: var(--text-purple);
    font-size: 3.5rem;
    text-align: center;

  }
  .newsGraph {
    width: 100%;
    box-shadow: #4D1D86 4px 5px 10px 3px;
  }
  .chartImg {
    width: 100%;
    box-shadow: #4D1D86 6px 6px 10px 3px;
    margin: auto;
  }
  .center {
    margin: auto;
  }
  .line1 {
    margin-top: 5rem;
  }
  .chartImg1 {
    text-align: center;
    padding-top: -7rem;
    padding-bottom: 1rem;
    width: 100%;
    margin-top: -120px;
    margin-bottom: -120px;
  }
  .chartImg2,
  .chartImg3,
  .chartImg4,
  .chartImg5 {
    text-align: center;
    padding-top: 1rem;
    padding-bottom: 1rem;
    width: 100%;
    margin-top: -120px;
    margin-bottom: -120px;
  }
  .chartImg6 {
    text-align: center;
    padding-top: 1rem;
    width: 100%;

  }
  .reveal {
    position: relative;
    transform: translateY(50px);
    opacity: 0;
    transition: 0.75s all ease;
  }
  .reveal.active {
    transform: translateY(0);
    opacity: 1;
  }
  .email {
    color: var(--hover-purple);
    font-weight: 500;
  }
  .footerLink {
    color: var(--hover-purple);
  }
  .footerLink:link {
    color: var(--hover-purple);
    cursor: pointer;
  }
  .footerLink:visited {
    color: var(--hover-purple);
    cursor: pointer;
  }
  .footerLink.active,
  .footerLink:focus {
    border-bottom: 0rem solid var(--hover-purple);
    cursor: pointer;
  }
  .footerLink:hover {
    color: var(--text-purple);
    transform: scale(1.05);
    cursor: pointer;
  }
  .footer {
    margin-bottom: -1rem;
    padding: 1rem;
    font-weight: 700;
    margin-top: 1rem;
    font-size: 1.1rem;
    text-align: center;
    background-color: var(--primary-purple);
    color: var(--secondary-purple);
  }
  .tsparticles {
    display: none;
  }
  .rotate-position,
  .about-rotate-position,
  .phone,
  .about-phone,
  .message,
  .about-message, .home-message {
    display: none;
  }
  .adminHeader-text {
    font-weight: 400;
    font-size: 1.5rem;
    text-align: center;
    margin: auto;
    padding-top: 1rem;
    color: var(--text-purple);
    
    margin-top: 6rem;
  }
  .adminHeader-text2 {
    font-weight: 400;
    font-size: 1.5rem;
    text-align: center;
    margin: auto;
    color: var(--text-purple);
    
    margin-top: 2rem;
  }
  .adminSubheader-text {
    font-weight: 400;
    font-size: 1rem;
    margin-left: 3rem;
    color: var(--text-purple);
    
    margin-top: 1rem;
  }
  .adminSubheader-text2 {
    font-weight: 400;
    font-size: 1rem;
    margin-left: 3rem;
    margin-right: 3rem;
    color: var(--text-purple);

    margin-top: 1rem;
  }
  .note {
    text-decoration: underline;
  }

  .adminHr2 {
    border: 3px solid var(--hr);
    border-radius: 5px;
    width: 70%;
    margin: auto;
    box-shadow: 0 0 10px 3px var(--hover-purple);
    margin-top: 1rem;
    margin-left: 1.9rem;
  }

 .admin-video {
    position: absolute;
    left: 48%;
    top: 20%;
    width: 10%;
    height: 10%;
  }

 
 
}
/* *************************************************************************
****************************************************************************
***************************************************************************/
/* *************************************************************************
****************************************************************************
***************************************************************************/
@media screen and (min-width: 812px) and (max-width: 813px) {
  .flashcards-title {
margin-top: 120px!important;
}
  .hide-line {
    color: transparent!important;
    display: block!important;
  }
  .Quiz-button {
    margin-bottom: 2rem;
  }
  .quiz-title {
    margin-top: -60px;
  }
  .quiz-button-position {
    padding-top: 2rem;
    margin: auto;
    text-align: center;
    margin-bottom: 2rem;
  }
  
  .final-exam-question {
      font-size: 1.3rem;
      text-align: center;
      color: #d8d2e4;
      /* remove for mobile */
      margin-top: 0rem;
      /* remove for mobile */
  }
  
  .final-exam-answer-form {
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin-top: 0rem;
    columns: 1!important;
    width: 100%!important;
  }
  
  .form-check-label {
    font-size: 20px;
    text-align: center;
    height: 110%;
  }
  .quiz-question {
    font-size: 1.3rem;
    padding-bottom: 2rem;
  text-align: center;
  width: 80%;
  margin: auto;
    text-align: center;
    color: #d8d2e4;
  }
  .quiz-question-container {
    margin-top: 20px;
    width: 100%;
  }

  .quiz-answer-form {
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin-top: 0rem;
    columns: 1!important;
    width: 100%!important;
  }

  .quiz-position {
    margin-top: -10px;
  }
  .mobile-portrait {
    display: none;
    }
  .adminHeader-text {
    font-weight: 400;
    font-size: 1.5rem;
    text-align: center;
    margin: auto;
    padding-top: 1rem;
    color: var(--text-purple);
    
    margin-top: 6rem;
  }
  .adminHeader-text2 {
    font-weight: 400;
    font-size: 1.5rem;
    text-align: center;
    margin: auto;
    color: var(--text-purple);
    
    margin-top: 2rem;
  }
  .adminSubheader-text {
    font-weight: 400;
    font-size: 1rem;
    margin-left: 3rem;
    color: var(--text-purple);
    
    margin-top: 1rem;
  }
  .adminSubheader-text2 {
    font-weight: 400;
    font-size: 1rem;
    margin-left: 3rem;
    margin-right: 3rem;
    color: var(--text-purple);

    margin-top: 1rem;
  }
  .monitor-line-carousel {
    display: none;
  }
  
  .monitor-bar-carousel {
   display: none;
  }
  
  .monitor-other-carousel {
    display: none;
  }
  
  .monitor-other-charts-position {
    display: none;
  }
  .mobile-bar-carousel {
    width: 80%;
  height: 500px;
  margin: auto auto;
  padding-top: -1rem;
  padding-bottom: -5rem;
  margin-bottom: -6rem;
  overflow: hidden;
  }
  .mobile-line-carousel {
    width: 80%;
    height: 500px;
    margin: auto auto;
    padding-top: 0rem;
    overflow: hidden;
  }

  .mobile-line-pos {
padding-top: 3rem;
  }
  .more-data-hr {
    margin-top: 1rem!important;
  }
  .more-data-title {
    margin-bottom: -12rem!important;
  }
  .mobile-other-carousel {
    width: 110%;
    height: 550px;
    margin: auto auto;
    margin-left: -2.5rem;
    padding-top: 1rem!important;
    overflow: hidden;
  }
  .map {
    width: 100%;
    margin-left: 5%;
    box-shadow: #4D1D86 4px 5px 10px 3px;
  }
  .holders-pos {
    margin-top: -8rem;
    margin-left: 1%;
    padding-bottom: 2rem;
  }
  .css-1fzpoyk {
    width: 100%;
    padding-top: 1rem;
  }

  .extra-data {
    margin-left: 2rem;
    margin-right: 2rem;
    font-size: 1.25rem;
    text-align: center;
    color: var(--text-purple);
  }
  .data-title {
    letter-spacing: 0.0375rem;
    font-weight: 400;
    color: var(--text-purple);
    font-size: 2rem;
    text-align: center;
    position: relative;
  }
  .chartsTitle {
    letter-spacing: 0.0375rem;
    font-weight: 400;
    color: var(--text-purple);
    font-size: 2rem;
    text-align: center;
    position: relative;
    margin-bottom: -13rem;
  }
  .chartsTitle2 {
    letter-spacing: 0.0375rem;
    font-weight: 400;
    color: var(--text-purple);
    font-size: 2rem;
    text-align: center;
    margin-bottom: -10rem;
  }
  .hr {
    border: 3px solid var(--hr);
    border-radius: 5px;
    width: 90%;
    margin: auto;
    box-shadow: 0 0 10px 3px var(--hover-purple);
    position: relative;
    margin-top: 3rem;
  }
  .hr2 {
    border: 3px solid var(--hr);
    border-radius: 5px;
    width: 90%;
    margin: auto;
    box-shadow: 0 0 10px 3px var(--hover-purple);
    position: relative;
    margin-top: 3rem;
  }
 
  .move-up {
    margin-top: -45rem;
  }
.hide-me {
  display: none;
}
  .css-1qzevvg img {
    height: 100%;
    margin-top: -10rem;
    text-align: center;
    margin-left: -1rem;
    z-index: 999999;
    position: relative;
}

/* above me */
  .mobile-dropdown {
    margin-left: 0.75rem!important;
  }
  .offcanvas-body {
    width: 100%!important;
    opacity: 0.9;
  }
  .offcanvas {
    width: 40%!important;
    background-color: var(--background)!important;
  }

 
   /* chatbot height start */
.react-chatbot-kit-chat-inner-container {
  background-color: var(--text-purple)!important;
  height: 260px!important;
}
.react-chatbot-kit-chat-message-container {
  height: 220px!important;
}
  /* chatbot height end */
/* dune start */
.GRTDailyTransactionsPosition {
  margin-bottom: 1rem!important;
  margin-left: -15px!important;
 }
 .HoldersGraph {
  background-image: linear-gradient(90deg, #1B0D33, #D8D2E4, #D8D2E4,#D8D2E4, #D8D2E4,#D8D2E4,#D8D2E4,#D8D2E4,#D8D2E4,#D8D2E4,#D8D2E4,#D8D2E4,#D8D2E4,#D8D2E4,#D8D2E4,#D8D2E4,#D8D2E4,#D8D2E4,#1B0D33);
  margin-bottom: -36px!important;
  margin-left: -10px!important;
  padding-left: 20px!important;
  padding-right: 20px!important;
  height: 80px!important;
  width: 100%!important;
}
.HoldersGraphBottom {
  background-image: linear-gradient(90deg, #1B0D33, #D8D2E4,#D8D2E4,#D8D2E4,#D8D2E4,#D8D2E4,#D8D2E4,#D8D2E4,#D8D2E4,#D8D2E4,#D8D2E4,#D8D2E4,#D8D2E4,#D8D2E4,#D8D2E4,#D8D2E4,#D8D2E4,#D8D2E4, #1B0D33);
  padding-left: 20px!important;
  margin-left: -10px!important;
  padding-right: 20px!important;
  height: 80px!important;
  width: 100%!important;
}
  .chatbot-footer-bottom {
    margin-bottom: -80px!important;
   }
  .chatbot-link-logo{
    margin-top: 2rem;
    z-index: 9998;
    position: fixed;
    max-width: 4rem;
    background: white;
    -moz-border-radius: 50px;
    -webkit-border-radius: 50px;
    border-radius: 50px;
    padding: -1rem;
    right: 2%;
    border: 3px var(--secondary-purple) solid;
    box-shadow: 0 0 0 var(--hover-purple);
    animation: pulse 2s infinite;
  }
  .advocate-link-logo{
    margin-top: 4rem;
    z-index: 9998;
    position: fixed;
    max-width: 4rem;
    background: white;
    -moz-border-radius: 50px;
    -webkit-border-radius: 50px;
    border-radius: 50px;
    padding: -1rem;
    right: 2%;
    border: 3px var(--secondary-purple) solid;
    box-shadow: 0 0 0 var(--hover-purple);
    animation: pulse 2s infinite;
  }
  @-webkit-keyframes pulse {
    0% {
      -webkit-box-shadow: 0 0 0 0  var(--text-purple);
    }
    70% {
        -webkit-box-shadow: 0 0 0 15px rgba(244,157,22, 0);
    }
    100% {
        -webkit-box-shadow: 0 0 0 0 rgba(244,157,22, 0);
    }
  }
  @keyframes pulse {
    0% {
      -moz-box-shadow: 0 0 0 0  var(--text-purple);
      box-shadow: 0 0 0 0  var(--text-purple);
    }
    70% {
        -moz-box-shadow: 0 0 0 15px rgba(244,157,22, 0);
        box-shadow: 0 0 0 15px rgba(244,157,22, 0);
    }
    100% {
        -moz-box-shadow: 0 0 0 0 rgba(244,157,22, 0);
        box-shadow: 0 0 0 0 rgba(244,157,22, 0);
    }
  }
  
  .chatbot-link-export{
    margin-top: 8rem;
    z-index: 9998;
    position: fixed;
    max-width: 4rem;
    background: white;
    -moz-border-radius: 50px;
    -webkit-border-radius: 50px;
    border-radius: 50px;
    padding: -1rem;
    right: 0.5rem;
    border: 3px var(--secondary-purple) solid;
    box-shadow: 0 0 0 var(--hover-purple);
    animation: pulse 2s infinite;
  }
/* Chatbot */
.feedback-text {
  margin-top: 2rem;
}
.feedback-button {
  border: 2px var(--primary-purple) solid!important;
  background-color: var(--text-purple)!important;
  color: var(--primary-purple)!important;
  padding: auto!important;
  margin: auto!important;
}

  .feedback-button:link {
    cursor: pointer;
  }
  .feedback-button:visited {
    cursor: pointer;
  }
  .feedback-button.active,
  .feedback-button:focus {
    cursor: pointer;
  }
  .feedback-button:hover {
    background-color: var(--background)!important;
    color: var(--text-purple)!important;
    transform: scale(1.015);
    cursor: pointer;
  }
  .feedback-button-text {
    font-size: 1.5rem;
  }

.react-chatbot-kit-chat-inner-container {
  background-color: var(--text-purple)!important;
}

.react-chatbot-kit-chat-header {
  font-size: 1.1rem!important;
  color: var(--text-purple)!important;
  background-color: var(--primary-purple)!important;
  padding: 0rem!important;
}

.react-chatbot-kit-chat-input-container {
  background-color:var(--secondary-purple)!important;
  border: 1px solid var(--primary-purple)!important;
}

.react-chatbot-kit-chat-btn-send {
  background-color:var(--primary-purple)!important;
}

.react-chatbot-kit-chat-btn-send-icon:hover {
transform: scale(1.25);
}

.react-chatbot-kit-chat-bot-message-container {
  width: 100%;
}

.react-chatbot-kit-chat-bot-avatar-container {
}

.react-chatbot-kit-chat-bot-avatar-icon {
}

.react-chatbot-kit-chat-bot-avatar-letter {
  display: none;
}
.react-chatbot-kit-chat-container {
  width: 98%!important;
  text-align: center;
  margin: auto;
}

.react-chatbot-kit-chat-bot-avatar-container {
  width: 3.5rem!important;
  height: 3.5rem!important;
  background-color: white!important;
  border: 2px solid var(--primary-purple);
  background-image: url('./GRTDataHubChatBot.png')!important;
  background-repeat: no-repeat!important;
  background-size: 3rem;
  background-position-x: 50%;
  background-position-y: 20%;
}

.react-chatbot-kit-chat-bot-message {
  text-align: left!important;
  width: 100%!important;
  color: var(--text-purple)!important;
  background-color: #0C0B23!important;
  font-size: 1rem!important;
}

.react-chatbot-kit-chat-bot-message-arrow {
  color: #0C0B23!important;
  border-right: 8px solid #0C0B23!important;
}
  .indexer-table-header {
    text-align: center;
    margin-left: 1rem;
  }
  .indexer-table {
    text-align: center;
  }
  .chatbot-data {
    display: inline!important;
  }
  .telegramActiveDelegationCount {
    max-width: 92%;
    margin: auto; 
    text-align: center;
    margin-top: 0.5%;
    }
    .top-telegram {
      margin-top: 0.25rem;
    }
    .chatbot-top {
      margin-top: 5rem;
    }
    .chatbot-page-container {
      background: var(--background);
      color: var(--text-purple);
      width: 100%;
      margin: auto;
      margin-top: 100px;
      padding-left: 10px;
      padding-right: 10px;
      text-align: center;
    }
    .chatbot-text-container {
      background: whitesmoke;
      border-radius: 1rem;
      color: var(--secondary-purple);
      margin: auto!important;
      padding-bottom: 10px;
      padding-top: 10px;
      text-align: center!important;
      padding-left: 15px;
      padding-right: 15px;
    }
    .chatbot-page-title {
      font-size: 1.25rem;
      font-weight: 600;
      color: var(--secondary-purple);
      padding-bottom: 0.25rem;
      padding-left: 5px;
      padding-right: 5px;
    }
    .chatbot-page-subtitle {
      font-size: 1.75rem;
      padding-left: 5px;
      padding-right: 5px;
    }
    .chatbot-page-text {
      font-size: 1.25rem;
      margin-bottom: 1rem;
      margin-top: 1rem;
      padding-left: 10px;
      padding-right: 10px;
    }
    /* chatbot end */
  .dataHub-logos {
    max-width: 6rem !important;
    max-height: 35rem !important;
    position: absolute;
    top: 0;
    margin-top: 1rem;
  }
  .monitor-config {
    display: none!important;
  }
  .hide-telegram {
    display: none;
  }
  body > div:nth-child(1) > button {
    display: none!important;
  }
  .pages {
    margin-bottom: 0.1rem;
    margin-right: 2rem;
}
.indexer-title {
  text-align: center!important;
  font-weight: 400;
  font-size: 1.5rem;
  padding-top: 2rem;
  color: var(--text-purple);
  
  margin-top: 6rem;
}
.indexer-subtitle {
  text-align: center!important;
  font-weight: 400;
  font-size: 1.25rem;
  color: var(--text-purple);
  
  margin-top: 1rem;
  margin-right: 2rem;
  margin-left: 2rem;
}
.indexer {
  z-index: 999;
  position: absolute;
  left: 0%;
  top: 50%;
}

.curator {
  z-index: 999;
  position: absolute;
  text-align: center!important;
  top: 50%;
}

.tokens, .rewards, .uncollected-rewards {
  margin-top: 2rem;
  width: 90%;
  height: 14rem;
  margin-left: 5%;
  box-shadow: #4D1D86 4px 5px 10px 3px;
}

.b-map {
  margin-top: -2rem;
  margin-left: 1rem;
  width: 720px;
  height: 3200px;
  text-align: center!important;
  box-shadow: #4D1D86 4px 5px 10px 3px;
}

.c-map {
  margin-top: 2rem;
  margin-left: 1rem;
  width: 720px;
  height: 1000px;
  text-align: center!important;
  box-shadow: #4D1D86 4px 5px 10px 3px;
}

.d-map {
  margin-top: -3rem;
  margin-left: 3rem;
  width: 720px;
  height: 3800px;
  text-align: center!important;
  box-shadow: #4D1D86 4px 5px 10px 3px;
}

.rewards-per-hour {
  margin-top: 2rem;
  padding-bottom: 2rem;
  width: 90%;
  height: 14rem;
  margin-left: 5%;
  box-shadow: #4D1D86 4px 5px 10px 3px;
}

.indexers {
  padding-left: 1rem;
  padding-left: 1rem;
  color: grey;
  font-size:1.25rem;
  margin-right: 1rem;
}
.indexers:link{
  color: grey;
  cursor: pointer;
  font-size:1.25rem;
  margin-right: 1rem;
}
.indexers:visited {
  color: grey;
  cursor: pointer;
  font-size:1.25rem;
  margin-right: 1rem;
}
.indexers.active,
.indexers:focus {
  border-bottom: 0rem solid var(--hover-purple);
  cursor: pointer;
  font-size:1.25rem;
  margin-right: 1rem;
}
.indexers:hover {
  color: var(--text-purple);
  transform: scale(1.05);
  cursor: pointer;
  font-size:1.25rem;
  margin-right: 1rem;
}
.indexer-link {
  color: white;
}
.indexer-link:link {
  color: white;
  cursor: pointer;
}
.indexer-link:visited {
  color: var(--text-purple);
  cursor: pointer;
}
.indexer-link.active,
.indexer-link:focus {
  border-bottom: 0rem solid var(--text-purple);
  cursor: pointer;
}
.indexer-link:hover {
  color: var(--text-purple);
  transform: scale(1.05);
  cursor: pointer;
}
  .cookie-page-text {
    padding-top: 1rem;
    font-size: 1.25rem;
    margin-bottom: 1rem;
    padding-left: 20px;
    padding-right: 20px;
  }
  .cookies-video {
    padding-top: 2rem;
  }
  .cookie-button {
    z-index: 9999;
    position: absolute;
  }

  body > div:nth-child(1) > button {
    z-index: 10000;
    font-size: 3rem;
    position: absolute;
    padding: auto;
    margin-top: -85px;
    width: 45px;
    height: 45px;
    margin-left: 83%;
    background-color: transparent;
    border: none;
    color: var(--text-purple);
  }
  .extra-data {
    margin-top: 2rem;
    font-size: 1.25rem;
    text-align: center;
    color: var(--text-purple);
   
  }
  .extra-data2 {
    font-size: 1.25rem;
    text-align: center;
    color: var(--text-purple);
   
  }
  .data-link {
    color: grey;
  }
  .data-link:link {
    color: grey;
    cursor: pointer;
  }
  .data-link:visited {
    color: grey;
    cursor: pointer;
  }
  .data-link.active,
  .data-link:focus {
    border-bottom: 0rem solid white;
    cursor: pointer;
  }
  .data-link:hover {
    color: white;
    transform: scale(1.05);
    cursor: pointer;
  }
  .question-bank-container {
    background: var(--background);
    color: var(--text-purple);
    width: 100%;
    margin: auto;
    margin-top: 150px;
    margin-bottom: 50px;
    padding-left: 50px;
    padding-right: 50px;
    text-align: center;
  }
  .about-page-container {
    background: var(--background);
    color: var(--text-purple);
    width: 100%;
    margin: auto;
    margin-top: 150px;
    margin-bottom: 50px;
    padding-left: 50px;
    padding-right: 50px;
    text-align: center;
  }
  .QB-text-container {
    background: white;
    border-radius: 1rem;
    padding: 30px;
    color: var(--secondary-purple);
  }
  .text-container {
    background: white;
    border-radius: 1rem;
    padding: 30px;
    color: var(--secondary-purple);
  }
  .about-hr {
    border: 1.5px solid var(--background);
    color: var(--background);
    border-radius: 5px;
    width: 35%;
    margin: auto;
    margin-bottom: 1rem;
    box-shadow: 0 0 5px 1px var(--background);
  }
  .about-page-title {
    font-size: 2rem;
    font-weight: 600;
    color: var(--secondary-purple);
    padding-bottom: 3rem;
  }
  .about-page-subtitle {
    font-size: 1.75rem;
  }
  .about-page-text {
    font-size: 1.25rem;
    margin-bottom: 1rem;
  }
  .graphiql-link {
    color: var(--secondary-purple);
  }
  .graphiql-link:link {
    color: var(--secondary-purple);
    cursor: pointer;
  }
  .graphiql-link:visited {
    color: var(--secondary-purple);
    cursor: pointer;
  }
  .graphiql-link.active,
  .graphiql-link:focus {
    border-bottom: 0rem solid var(--hover-purple);
    cursor: pointer;
  }
  .graphiql-link:hover {
    color: var(--primary-purple);
    transform: scale(1.05);
    cursor: pointer;
  }
  .cookies-policy-container {
    background: white;
    width: 90%;
    padding-bottom: 1rem;
    margin: auto;
    border-radius: 2rem;
    margin-top: 150px;
    margin-bottom: 50px;
  }
  .termly-cookie-preference-button {
   display: none;
  }


  .privacy-policy-container {
    background: white;
    width: 90%;
    padding-bottom: 1rem;
    margin: auto;
    border-radius: 2rem;
    margin-top: 150px;
    margin-bottom: 50px;
  }
  .privacy-policy {
    margin-top: 110px;
    font-weight: 700;
    font-size: 1.1rem;
    text-align: center;
    background-color: var(--background);
    color: var(--secondary-purple);
  }
  .privacy-policy-link,
  .about {
    padding-left: 1rem;
    padding-left: 1rem;
    color: grey;
  }
  .privacy-policy-link:link,
  .about:link {
    color: grey;
    cursor: pointer;
  }
  .privacy-policy-link:visited,
  .about:visited {
    color: grey;
    cursor: pointer;
  }
  .privacy-policy-link.active,
  .privacy-policy-link:focus,
  .about.active,
  .about:focus {
    border-bottom: 0rem solid var(--hover-purple);
    cursor: pointer;
  }
  .privacy-policy-link:hover,
  .about:hover {
    color: var(--text-purple);
    transform: scale(1.05);
    cursor: pointer;
  }
  .subscribeNote {
    margin-left: -15rem;
    width: 30rem;
    font-size: 0.75rem;
    color: white;
  }
  /* loader new start  */
  .loader:empty {
    position: absolute;
    top: calc(50% + 5.5rem);
    left: calc(50% + 2em);
    width: 4em;
    height: 4em;
    border: 0.35em solid white;
    border-left: 0.35em solid #10034B;
    border-radius: 50%;
    animation: load8 0.8s infinite linear;
  }
  .loadingTitle {
    position: absolute;
    top: 17rem;
    left: calc(50% - 3em);
    font-size: 2rem;
    color: #10034B;
    -webkit-box-reflect:below 0px linear-gradient(transparent, #0005);
  }
  .loaderLogo {
    position: absolute;
    top: 4rem;
    left: calc(50% - 8em);
    width: 15em;
    height: 12em;
  }
  @keyframes load8 {
    0% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(360deg);
    }
  }
  /* loader new end  */
  .C {
    font-size: 1rem;
  }
  .logo {
    width: 5.75rem;
    height: 4.75rem;
    margin-left: 2rem;
    margin-top: -0.5rem;
  }
  .data-logo {
    max-width: 1rem !important;
    height: 1rem !important;
    position: absolute;
  }
  .brand-text {
    color: var(--text-purple);
    display: inline;
    margin-left: 0rem;
    letter-spacing: 0.0375rem;
    font-size: 1.25rem;
  }
  .header-text {
    font-weight: 400;
    font-size: 2rem;
    padding-top: 1rem;
    text-align: center;
    margin-top: 105px;
    margin-left: 2rem;
    color: var(--text-purple);

  }
  .header-sub-text {
    font-weight: 400;
    text-align: center!important;
    margin: auto!important;
    font-size: 1.5rem;
    width: 90%;
    color: var(--text-purple);
    display: block;
    text-align: center;
   
  }
  nav a.TGN {
    display: none;
  }
  .navbar-nav > li > a.TGN:link {
    display: none;
  }
  .navbar-nav > li > a.TGN:visited {
    display: none;
  }
  .navbar-nav > li > a.TGN.active,
  .navbar-nav > li > a.TGN:focus {
    display: none;
  }
  .navbar-nav > li > a.TGN:hover {
    display: none;
  }
  nav a.nav-link {
    font-family: Euclid Circular A;
    font-weight: 600;
    font-size: 1.15rem;
    color: var(--text-purple);
  }
  .navbar-nav > li > a {
    border-bottom: 0.01875rem solid var(--transparent);
    padding: 0;
  }
  .navbar-nav > li > a:link {
    color: var(--hover-purple);
  }
  .navbar-nav > li > a:visited {
    color: var(--text-purple);
  }
  .navbar-nav > li > a.active,
  .navbar-nav > li > a:focus {
    border-bottom: 0.01875rem solid var(--hover-purple);
    padding: 0;
    transition: all 0.5s ease-in-out;
  }
  .navbar-nav > li > a:hover {
    border-bottom: 0.01875rem solid var(--hover-purple);
    padding: 0;
    color: var(--hover-purple);
    transform: scale(1.05);
  }
  .line {
    margin-left: 1rem;
  }
  .links {
    text-align: right;
    padding-right: 0;
    margin: auto;
    padding: auto;
  }
  .dropdown {
    margin-left: auto;
    padding: auto;
  }
  .bar {
    margin-bottom: 0.1rem;
    margin-right: 2rem;
  }
  .line {
    margin-bottom: 0.1rem;
    margin-right: 2rem;
  }
  .about {
    margin-left: auto;
    margin-right: 0.8rem;
  }
  .menu-icon {
    color: var(--secondary-purple);
  }
  .main-info {
    background-image: url("TopBackground.svg");
    background-repeat: no-repeat;
    background-size: 100% auto;
    width: 100%;
    height: 100%;
    margin-top: 90px;
  }
  .community-links {
    text-align: center;
    padding-top: 5rem;
    position: absolute;
    z-index: 999;
    left: 35%;
  }
  .telegram-icon,
  .twitter-icon,
  .reddit-icon,
  .youtube-icon {
    display: inline;
    padding: 1rem;
    transition: all 0.5s ease-in-out;
    z-index: 999;
  }
  .icon:hover {
    color: var(--hover-purple);
    transform: scale(1.15);
  }
  .icon-youtube:hover {
    color: var(--text-purple);
    transform: scale(1.1);
  }
  .news-title {
    text-shadow: 2.25px 2.25px 3px var(--hover-purple);
    color: var(--text-purple);
    text-align: center;
    margin-top: 1rem;
    margin-left: -18rem;
    margin-bottom: 1rem;
    font-size: 1.5rem;
  }
  .newsGraph1,
  .newsGraph2,
  .newsGraph3 {
    margin-bottom: 1rem;
  }
  .youtube {
    z-index: 999;
    display: inline;
    position: absolute;
    text-align: center;
    color: var(--text-purple);
  }
  span.anchor {
    display: block;
    visibility: hidden;
    position: relative;
    padding-top: 20%;
  }
  .activeDelegators,
  .holders,
  .curatorCount,
  .subgraphCount {
    font-weight: 400;
    color: var(--text-purple);
    letter-spacing: 0.0375rem;
  }

  .video {
    position: absolute;
    box-shadow: #4D1D86 4px 8px 10px 0;
    text-align: center;
    margin-top: 3rem;
    margin-left: 25%;
  }
  .g-ytsubscribe {
    position: absolute;
    font-family: Euclid Circular A;
    font-weight: 400;
    margin: 0;
    left: 58%;
    top: 84%;
  }
  
  .newsGraph {
    width: 100%;
    box-shadow: #4D1D86 4px 5px 10px 3px;
  }
  .chartImg {
    width: 100%;
    box-shadow: #4D1D86 6px 6px 10px 3px;
    margin: auto;
  }
  .center {
    margin: auto;
  }
  .line1 {
    margin-top: 5rem;
  }

  .chartImg6 {
    text-align: center;
    padding-top: 1rem;
    width: 100%;
  
  }
  .reveal {
    position: relative;
    transform: translateY(50px);
    opacity: 0;
    transition: 0.75s all ease;
  }
  .reveal.active {
    transform: translateY(0);
    opacity: 1;
  }
  .email {
    color: var(--hover-purple);
    font-weight: 500;
  }
  .footerLink {
    color: var(--hover-purple);
  }
  .footerLink:link {
    color: var(--hover-purple);
    cursor: pointer;
  }
  .footerLink:visited {
    color: var(--hover-purple);
    cursor: pointer;
  }
  .footerLink.active,
  .footerLink:focus {
    border-bottom: 0rem solid var(--hover-purple);
    cursor: pointer;
  }
  .footerLink:hover {
    color: var(--text-purple);
    transform: scale(1.05);
    cursor: pointer;
  }
  .footer {
    margin-bottom: -1rem;
    padding: 1rem;
    font-weight: 700;
    margin-top: 1rem;
    font-size: 1.1rem;
    text-align: center;
    background-color: var(--primary-purple);
    color: var(--secondary-purple);
  }
  .tsparticles {
    display: none;
  }
  .rotate-position,
  .about-rotate-position,
  .phone,
  .about-phone,
  .message,
  .about-message, .home-message {
    display: none;
  }
 
  .note {
    text-decoration: underline;
  }

 .admin-video {
    position: absolute;
    left: 48%;
    top: 20%;
    width: 10%;
    height: 10%;
  }
}
/* *************************************************************************
****************************************************************************
***************************************************************************/
/* *************************************************************************
****************************************************************************
***************************************************************************/
@media screen and (min-width: 805px) and (max-width: 811px) {
  .flashcards-title {
margin-top: 120px!important;
}
  .hide-line {
    color: transparent!important;
    display: block!important;
  }
  .Quiz-button {
    margin-bottom: 2rem;
  }
  .quiz-title {
    margin-top: -60px;
  }
  .quiz-button-position {
    padding-top: 2rem;
    margin: auto;
    text-align: center;
    margin-bottom: 2rem;
  }
  
  .final-exam-question {
      font-size: 1.3rem;
      text-align: center;
      color: #d8d2e4;
      /* remove for mobile */
      margin-top: 0rem;
      /* remove for mobile */
  }
  
  .final-exam-answer-form {
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin-top: 0rem;
    columns: 1!important;
    width: 100%!important;
  }
  
  .form-check-label {
    font-size: 20px;
    text-align: center;
    height: 110%;
  }
  .quiz-question {
    font-size: 1.3rem;
    padding-bottom: 2rem;
  text-align: center;
  width: 80%;
  margin: auto;
    text-align: center;
    color: #d8d2e4;
  }
  .quiz-question-container {
    margin-top: 20px;
    width: 100%;
  }

  .quiz-answer-form {
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin-top: 0rem;
    columns: 1!important;
    width: 100%!important;
  }

  .quiz-position {
    margin-top: -10px;
  }
  .mobile-landscape {
    display: none;
    }
  .monitor-line-carousel {
    display: none;
  }
  
  .monitor-bar-carousel {
   display: none;
  }
  
  .monitor-other-carousel {
    display: none;
  }
  
  .monitor-other-charts-position {
    display: none;
  }
  .mobile-bar-carousel {
    width: 100%;
  height: 500px;
  margin: auto auto;
  padding-top: -1rem;
  padding-bottom: -5rem;
  margin-bottom: -6rem;
  overflow: hidden;
  }
  .mobile-line-carousel {
    width: 100%;
    height: 500px;
    margin: auto auto;
    padding-top: 0rem;
    overflow: hidden;
  }

  .mobile-line-pos {
padding-top: 3rem;
  }
  .more-data-hr {
    margin-top: 1rem!important;
  }
  .more-data-title {
    padding-bottom: 2rem!important;
  }
  .mobile-other-carousel {
    width: 110%;
    height: 500px;
    margin: auto auto;
    margin-left: -1.5rem;
    padding-top: 1rem!important;
    overflow: hidden;
  }
  .map {
    width: 100%;
    margin-left: 5%;
    box-shadow: #4D1D86 4px 5px 10px 3px;
  }
  .holders-pos {
    margin-left: -5%;
    padding-bottom: 2rem;
  }
  .css-1fzpoyk {
    width: 100%;
    padding-top: 1rem;
  }

  .extra-data {
    
    font-size: 1.25rem;
    text-align: center;
    color: var(--text-purple);
  }
  .data-title {
    letter-spacing: 0.0375rem;
    font-weight: 400;
    color: var(--text-purple);
    font-size: 2rem;
    text-align: center;
    position: relative;
  }
  .chartsTitle {
    letter-spacing: 0.0375rem;
    font-weight: 400;
    color: var(--text-purple);
    font-size: 2rem;
    text-align: center;
    position: relative;
    margin-bottom: -13rem;
  }
  .chartsTitle2 {
    letter-spacing: 0.0375rem;
    font-weight: 400;
    color: var(--text-purple);
    font-size: 2rem;
    text-align: center;
    margin-bottom: -10rem;
  }
  .hr {
    border: 3px solid var(--hr);
    border-radius: 5px;
    width: 90%;
    margin: auto;
    box-shadow: 0 0 10px 3px var(--hover-purple);
    position: relative;
    margin-top: 7rem;
  }
  .hr2 {
    border: 3px solid var(--hr);
    border-radius: 5px;
    width: 90%;
    margin: auto;
    box-shadow: 0 0 10px 3px var(--hover-purple);
    position: relative;
    margin-top: 6rem;
  }
 
  .move-up {
    margin-top: -45rem;
  }
.hide-me {
  display: none;
}
  .css-1qzevvg img {
    height: 100%;
    margin-top: -6rem;
    text-align: center;
    margin-left: -1rem;
    z-index: 999999;
    position: relative;
}
.adminHeader-text {
  font-weight: 400;
  font-size: 1.5rem;
  text-align: center;
  margin: auto;
  padding-top: 1rem;
  color: var(--text-purple);
  
  margin-top: 6rem;
}
.adminHeader-text2 {
  font-weight: 400;
  font-size: 1.5rem;
  text-align: center;
  margin: auto;
  color: var(--text-purple);
  
  margin-top: 2rem;
}
.adminSubheader-text {
  font-weight: 400;
  font-size: 1rem;
  margin-left: 3rem;
  color: var(--text-purple);
  
  margin-top: 1rem;
}
.adminSubheader-text2 {
  font-weight: 400;
  font-size: 1rem;
  margin-left: 3rem;
  margin-right: 3rem;
  color: var(--text-purple);

  margin-top: 1rem;
}
/* above me */
  .mobile-dropdown {
    margin-left: 0.75rem!important;
  }
  .offcanvas-body {
    width: 100%!important;
    opacity: 0.9;
  }
  .offcanvas {
    width: 40%!important;
    background-color: var(--background)!important;
  }
 
   /* chatbot height start */
.react-chatbot-kit-chat-inner-container {
  background-color: var(--text-purple)!important;
  height: 260px!important;
}
.react-chatbot-kit-chat-message-container {
  height: 220px!important;
}
  /* chatbot height end */
/* dune start */
.GRTDailyTransactionsPosition {
  margin-bottom: 1rem!important;
  margin-left: -15px!important;
 }
 .HoldersGraph {
  background-image: linear-gradient(90deg, #1B0D33, #D8D2E4, #D8D2E4,#D8D2E4, #D8D2E4,#D8D2E4,#D8D2E4,#D8D2E4,#D8D2E4,#D8D2E4,#D8D2E4,#D8D2E4,#D8D2E4,#D8D2E4,#D8D2E4,#D8D2E4,#D8D2E4,#D8D2E4,#1B0D33);
  margin-bottom: -36px!important;
  margin-left: -10px!important;
  padding-left: 20px!important;
  padding-right: 20px!important;
  height: 80px!important;
  width: 100%!important;
}
.HoldersGraphBottom {
  background-image: linear-gradient(90deg, #1B0D33, #D8D2E4,#D8D2E4,#D8D2E4,#D8D2E4,#D8D2E4,#D8D2E4,#D8D2E4,#D8D2E4,#D8D2E4,#D8D2E4,#D8D2E4,#D8D2E4,#D8D2E4,#D8D2E4,#D8D2E4,#D8D2E4,#D8D2E4, #1B0D33);
  padding-left: 20px!important;
  margin-left: -10px!important;
  padding-right: 20px!important;
  height: 80px!important;
  width: 100%!important;
}
  .chatbot-footer-bottom {
    margin-bottom: -80px!important;
   }
  .chatbot-link-logo{
    margin-top: 2rem;
    z-index: 9998;
    position: fixed;
    max-width: 4rem;
    background: white;
    -moz-border-radius: 50px;
    -webkit-border-radius: 50px;
    border-radius: 50px;
    padding: -1rem;
    right: 2%;
    border: 3px var(--secondary-purple) solid;
    box-shadow: 0 0 0 var(--hover-purple);
    animation: pulse 2s infinite;
  }
  .advocate-link-logo{
    margin-top: 4rem;
    z-index: 9998;
    position: fixed;
    max-width: 4rem;
    background: white;
    -moz-border-radius: 50px;
    -webkit-border-radius: 50px;
    border-radius: 50px;
    padding: -1rem;
    right: 2%;
    border: 3px var(--secondary-purple) solid;
    box-shadow: 0 0 0 var(--hover-purple);
    animation: pulse 2s infinite;
  }
  @-webkit-keyframes pulse {
    0% {
      -webkit-box-shadow: 0 0 0 0  var(--text-purple);
    }
    70% {
        -webkit-box-shadow: 0 0 0 15px rgba(244,157,22, 0);
    }
    100% {
        -webkit-box-shadow: 0 0 0 0 rgba(244,157,22, 0);
    }
  }
  @keyframes pulse {
    0% {
      -moz-box-shadow: 0 0 0 0  var(--text-purple);
      box-shadow: 0 0 0 0  var(--text-purple);
    }
    70% {
        -moz-box-shadow: 0 0 0 15px rgba(244,157,22, 0);
        box-shadow: 0 0 0 15px rgba(244,157,22, 0);
    }
    100% {
        -moz-box-shadow: 0 0 0 0 rgba(244,157,22, 0);
        box-shadow: 0 0 0 0 rgba(244,157,22, 0);
    }
  }
  
  .chatbot-link-export{
    margin-top: 7.25rem;
    z-index: 9998;
    position: fixed;
    max-width: 4rem;
    background: white;
    -moz-border-radius: 50px;
    -webkit-border-radius: 50px;
    border-radius: 50px;
    padding: -1rem;
  right: 2%;
    border: 3px var(--secondary-purple) solid;
    box-shadow: 0 0 0 var(--hover-purple);
    animation: pulse 2s infinite;
  }
/* Chatbot */
.feedback-text {
  margin-top: 2rem;
}
.feedback-button {
  border: 2px var(--primary-purple) solid!important;
  background-color: var(--text-purple)!important;
  color: var(--primary-purple)!important;
  padding: auto!important;
  margin: auto!important;
}

  .feedback-button:link {
    cursor: pointer;
  }
  .feedback-button:visited {
    cursor: pointer;
  }
  .feedback-button.active,
  .feedback-button:focus {
    cursor: pointer;
  }
  .feedback-button:hover {
    background-color: var(--background)!important;
    color: var(--text-purple)!important;
    transform: scale(1.015);
    cursor: pointer;
  }
  .feedback-button-text {
    font-size: 1.5rem;
  }

.react-chatbot-kit-chat-inner-container {
  background-color: var(--text-purple)!important;
}

.react-chatbot-kit-chat-header {
  font-size: 1.1rem!important;
  color: var(--text-purple)!important;
  background-color: var(--primary-purple)!important;
  padding: 0rem!important;
}

.react-chatbot-kit-chat-input-container {
  background-color:var(--secondary-purple)!important;
  border: 1px solid var(--primary-purple)!important;
}

.react-chatbot-kit-chat-btn-send {
  background-color:var(--primary-purple)!important;
}

.react-chatbot-kit-chat-btn-send-icon:hover {
transform: scale(1.25);
}

.react-chatbot-kit-chat-bot-message-container {
  width: 100%;
}

.react-chatbot-kit-chat-bot-avatar-container {
}

.react-chatbot-kit-chat-bot-avatar-icon {
}

.react-chatbot-kit-chat-bot-avatar-letter {
  display: none;
}
.react-chatbot-kit-chat-container {
  width: 98%!important;
  text-align: center;
  margin: auto;
}

.react-chatbot-kit-chat-bot-avatar-container {
  width: 3.5rem!important;
  height: 3.5rem!important;
  background-color: white!important;
  border: 2px solid var(--primary-purple);
  background-image: url('./GRTDataHubChatBot.png')!important;
  background-repeat: no-repeat!important;
  background-size: 3rem;
  background-position-x: 50%;
  background-position-y: 20%;
}

.react-chatbot-kit-chat-bot-message {
  text-align: left!important;
  width: 100%!important;
  color: var(--text-purple)!important;
  background-color: #0C0B23!important;
  font-size: 1rem!important;
}

.react-chatbot-kit-chat-bot-message-arrow {
  color: #0C0B23!important;
  border-right: 8px solid #0C0B23!important;
}
  .indexer-table-header {
    text-align: center;
    margin-left: 1rem;
  }
  .indexer-table {
    text-align: center;
  }
  .chatbot-data {
    display: inline!important;
  }
  .telegramActiveDelegationCount {
    max-width: 92%;
    margin: auto; 
    text-align: center;
    margin-top: 0.5%;
    }
    .top-telegram {
      margin-top: 0.25rem;
    }
    .chatbot-top {
      margin-top: 5rem;
    }
    .chatbot-page-container {
      background: var(--background);
      color: var(--text-purple);
      width: 100%;
      margin: auto;
      margin-top: 100px;
      padding-left: 10px;
      padding-right: 10px;
      text-align: center;
    }
    .chatbot-text-container {
      background: whitesmoke;
      border-radius: 1rem;
      color: var(--secondary-purple);
      margin: auto!important;
      padding-bottom: 10px;
      padding-top: 10px;
      text-align: center!important;
      padding-left: 15px;
      padding-right: 15px;
    }
    .chatbot-page-title {
      font-size: 1.25rem;
      font-weight: 600;
      color: var(--secondary-purple);
      padding-bottom: 0.25rem;
      padding-left: 5px;
      padding-right: 5px;
    }
    .chatbot-page-subtitle {
      font-size: 1.75rem;
      padding-left: 5px;
      padding-right: 5px;
    }
    .chatbot-page-text {
      font-size: 1.25rem;
      margin-bottom: 1rem;
      margin-top: 1rem;
      padding-left: 10px;
      padding-right: 10px;
    }
    /* chatbot end */
  .dataHub-logos {
    max-width: 6rem !important;
    max-height: 35rem !important;
    position: absolute;
    top: 0;
    margin-top: 1rem;
  }
  .monitor-config {
    display: none!important;
  }
  .hide-telegram {
    display: none;
  }
  body > div:nth-child(1) > button {
    display: none!important;
  }
  .pages {
    margin-bottom: 0.1rem;
    margin-right: 2rem;
}
.indexer-title {
  text-align: center!important;
  font-weight: 400;
  font-size: 1.5rem;
  padding-top: 2rem;
  color: var(--text-purple);
  
  margin-top: 6rem;
}
.indexer-subtitle {
  text-align: center!important;
  font-weight: 400;
  font-size: 1.25rem;
  color: var(--text-purple);
  
  margin-top: 1rem;
  margin-right: 2rem;
  margin-left: 2rem;
}
.indexer {
  z-index: 999;
  position: absolute;
  left: 0%;
  top: 50%;
}

.curator {
  z-index: 999;
  position: absolute;
  text-align: center!important;
  top: 50%;
}

.tokens, .rewards, .uncollected-rewards {
  margin-top: 2rem;
  width: 90%;
  height: 14rem;
  margin-left: 5%;
  box-shadow: #4D1D86 4px 5px 10px 3px;
}

.b-map {
  margin-top: -2rem;
  margin-left: 1rem;
  width: 750px;
  height: 3200px;
  text-align: center!important;
  box-shadow: #4D1D86 4px 5px 10px 3px;
}

.c-map {
  margin-top: 2rem;
  margin-left: 1rem;
  width: 750px;
  height: 1000px;
  text-align: center!important;
  box-shadow: #4D1D86 4px 5px 10px 3px;
}

.d-map {
  margin-top: -3rem;
  margin-left: 3rem;
  width: 750px;
  height: 3800px;
  text-align: center!important;
  box-shadow: #4D1D86 4px 5px 10px 3px;
}

.rewards-per-hour {
  margin-top: 2rem;
  padding-bottom: 2rem;
  width: 90%;
  height: 14rem;
  margin-left: 5%;
  box-shadow: #4D1D86 4px 5px 10px 3px;
}

.indexers {
  padding-left: 1rem;
  padding-left: 1rem;
  color: grey;
  font-size:1.25rem;
  margin-right: 1rem;
}
.indexers:link{
  color: grey;
  cursor: pointer;
  font-size:1.25rem;
  margin-right: 1rem;
}
.indexers:visited {
  color: grey;
  cursor: pointer;
  font-size:1.25rem;
  margin-right: 1rem;
}
.indexers.active,
.indexers:focus {
  border-bottom: 0rem solid var(--hover-purple);
  cursor: pointer;
  font-size:1.25rem;
  margin-right: 1rem;
}
.indexers:hover {
  color: var(--text-purple);
  transform: scale(1.05);
  cursor: pointer;
  font-size:1.25rem;
  margin-right: 1rem;
}
.indexer-link {
  color: white;
}
.indexer-link:link {
  color: white;
  cursor: pointer;
}
.indexer-link:visited {
  color: var(--text-purple);
  cursor: pointer;
}
.indexer-link.active,
.indexer-link:focus {
  border-bottom: 0rem solid var(--text-purple);
  cursor: pointer;
}
.indexer-link:hover {
  color: var(--text-purple);
  transform: scale(1.05);
  cursor: pointer;
}
  .cookie-page-text {
    padding-top: 1rem;
    font-size: 1.25rem;
    margin-bottom: 1rem;
    padding-left: 20px;
    padding-right: 20px;
  }
  .cookies-video {
    padding-top: 2rem;
  }
  .cookie-button {
    z-index: 9999;
    position: absolute;
  }

  body > div:nth-child(1) > button {
    z-index: 10000;
    font-size: 3rem;
    position: absolute;
    padding: auto;
    margin-top: -85px;
    width: 45px;
    height: 45px;
    margin-left: 83%;
    background-color: transparent;
    border: none;
    color: var(--text-purple);
  }
  
  .extra-data2 {
    font-size: 1.25rem;
    text-align: center;
    color: var(--text-purple);
   
  }
  .data-link {
    color: grey;
  }
  .data-link:link {
    color: grey;
    cursor: pointer;
  }
  .data-link:visited {
    color: grey;
    cursor: pointer;
  }
  .data-link.active,
  .data-link:focus {
    border-bottom: 0rem solid white;
    cursor: pointer;
  }
  .data-link:hover {
    color: white;
    transform: scale(1.05);
    cursor: pointer;
  }
  .question-bank-container {
    background: var(--background);
    color: var(--text-purple);
    width: 100%;
    margin: auto;
    margin-top: 150px;
    margin-bottom: 50px;
    padding-left: 50px;
    padding-right: 50px;
    text-align: center;
  }
  .about-page-container {
    background: var(--background);
    color: var(--text-purple);
    width: 100%;
    margin: auto;
    margin-top: 150px;
    margin-bottom: 50px;
    padding-left: 50px;
    padding-right: 50px;
    text-align: center;
  }
  .QB-text-container {
    background: white;
    border-radius: 1rem;
    padding: 30px;
    color: var(--secondary-purple);
  }
  .text-container {
    background: white;
    border-radius: 1rem;
    padding: 30px;
    color: var(--secondary-purple);
  }
  .about-hr {
    border: 1.5px solid var(--background);
    color: var(--background);
    border-radius: 5px;
    width: 35%;
    margin: auto;
    margin-bottom: 1rem;
    box-shadow: 0 0 5px 1px var(--background);
  }
  .about-page-title {
    font-size: 2rem;
    font-weight: 600;
    color: var(--secondary-purple);
    padding-bottom: 3rem;
  }
  .about-page-subtitle {
    font-size: 1.75rem;
  }
  .about-page-text {
    font-size: 1.25rem;
    margin-bottom: 1rem;
  }
  .graphiql-link {
    color: var(--secondary-purple);
  }
  .graphiql-link:link {
    color: var(--secondary-purple);
    cursor: pointer;
  }
  .graphiql-link:visited {
    color: var(--secondary-purple);
    cursor: pointer;
  }
  .graphiql-link.active,
  .graphiql-link:focus {
    border-bottom: 0rem solid var(--hover-purple);
    cursor: pointer;
  }
  .graphiql-link:hover {
    color: var(--primary-purple);
    transform: scale(1.05);
    cursor: pointer;
  }
  .cookies-policy-container {
    background: white;
    width: 90%;
    padding-bottom: 1rem;
    margin: auto;
    border-radius: 2rem;
    margin-top: 150px;
    margin-bottom: 50px;
  }
  .termly-cookie-preference-button {
   display: none;
  }


  .privacy-policy-container {
    background: white;
    width: 90%;
    padding-bottom: 1rem;
    margin: auto;
    border-radius: 2rem;
    margin-top: 150px;
    margin-bottom: 50px;
  }
  .privacy-policy {
    margin-top: 110px;
    font-weight: 700;
    font-size: 1.1rem;
    text-align: center;
    background-color: var(--background);
    color: var(--secondary-purple);
  }
  .privacy-policy-link,
  .about {
    padding-left: 1rem;
    padding-left: 1rem;
    color: grey;
  }
  .privacy-policy-link:link,
  .about:link {
    color: grey;
    cursor: pointer;
  }
  .privacy-policy-link:visited,
  .about:visited {
    color: grey;
    cursor: pointer;
  }
  .privacy-policy-link.active,
  .privacy-policy-link:focus,
  .about.active,
  .about:focus {
    border-bottom: 0rem solid var(--hover-purple);
    cursor: pointer;
  }
  .privacy-policy-link:hover,
  .about:hover {
    color: var(--text-purple);
    transform: scale(1.05);
    cursor: pointer;
  }
  .subscribeNote {
    margin-left: -15rem;
    width: 30rem;
    font-size: 0.75rem;
    color: white;
  }
  /* loader new start  */
  .loader:empty {
    position: absolute;
    top: calc(50% + 5.5rem);
    left: calc(50% + 2em);
    width: 4em;
    height: 4em;
    border: 0.35em solid white;
    border-left: 0.35em solid #10034B;
    border-radius: 50%;
    animation: load8 0.8s infinite linear;
  }
  .loadingTitle {
    position: absolute;
    top: 17rem;
    left: calc(50% - 3em);
    font-size: 2rem;
    color: #10034B;
    -webkit-box-reflect:below 0px linear-gradient(transparent, #0005);
  }
  .loaderLogo {
    position: absolute;
    top: 4rem;
    left: calc(50% - 8em);
    width: 15em;
    height: 12em;
  }
  @keyframes load8 {
    0% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(360deg);
    }
  }
  /* loader new end  */
  .C {
    font-size: 1rem;
  }
  .logo {
    width: 5.75rem;
    height: 4.75rem;
    margin-left: 2rem;
    margin-top: -0.5rem;
  }
  .data-logo {
    max-width: 1rem !important;
    height: 1rem !important;
    position: absolute;
  }
  .brand-text {
    color: var(--text-purple);
    display: inline;
    margin-left: 0rem;
    letter-spacing: 0.0375rem;
    font-size: 1.25rem;
  }
  .header-text {
    font-weight: 400;
    font-size: 2rem;
    padding-top: 1rem;
    text-align: center;
    margin-top: 105px;
    margin-left: 2rem;
    color: var(--text-purple);
   
  }
  .header-sub-text {
    font-weight: 400;
    text-align: center!important;
    margin: auto!important;
    font-size: 1.5rem;
    width: 90%;
    color: var(--text-purple);
    display: block;
    text-align: center;
 
  }
  nav a.TGN {
    display: none;
  }
  .navbar-nav > li > a.TGN:link {
    display: none;
  }
  .navbar-nav > li > a.TGN:visited {
    display: none;
  }
  .navbar-nav > li > a.TGN.active,
  .navbar-nav > li > a.TGN:focus {
    display: none;
  }
  .navbar-nav > li > a.TGN:hover {
    display: none;
  }
  nav a.nav-link {
    font-family: Euclid Circular A;
    font-weight: 600;
    font-size: 1.15rem;
    color: var(--text-purple);
  }
  .navbar-nav > li > a {
    border-bottom: 0.01875rem solid var(--transparent);
    padding: 0;
  }
  .navbar-nav > li > a:link {
    color: var(--hover-purple);
  }
  .navbar-nav > li > a:visited {
    color: var(--text-purple);
  }
  .navbar-nav > li > a.active,
  .navbar-nav > li > a:focus {
    border-bottom: 0.01875rem solid var(--hover-purple);
    padding: 0;
    transition: all 0.5s ease-in-out;
  }
  .navbar-nav > li > a:hover {
    border-bottom: 0.01875rem solid var(--hover-purple);
    padding: 0;
    color: var(--hover-purple);
    transform: scale(1.05);
  }
  .line {
    margin-left: 1rem;
  }
  .links {
    text-align: right;
    padding-right: 0;
    margin: auto;
    padding: auto;
  }
  .dropdown {
    margin-left: auto;
    padding: auto;
  }
  .bar {
    margin-bottom: 0.1rem;
    margin-right: 2rem;
  }
  .line {
    margin-bottom: 0.1rem;
    margin-right: 2rem;
  }
  .about {
    margin-left: auto;
    margin-right: 0.8rem;
  }
  .menu-icon {
    color: var(--secondary-purple);
  }
  .main-info {
    background-image: url("TopBackground.svg");
    background-repeat: no-repeat;
    background-size: 100% auto;
    width: 100%;
    height: 100%;
    margin-top: 90px;
  }
  .community-links {
    text-align: center;
    padding-top: 5rem;
    position: absolute;
    z-index: 999;
    left: 35%;
  }
  .telegram-icon,
  .twitter-icon,
  .reddit-icon,
  .youtube-icon {
    display: inline;
    padding: 1rem;
    transition: all 0.5s ease-in-out;
    z-index: 999;
  }
  .icon:hover {
    color: var(--hover-purple);
    transform: scale(1.15);
  }
  .icon-youtube:hover {
    color: var(--text-purple);
    transform: scale(1.1);
  }
  .news-title {
    text-shadow: 2.25px 2.25px 3px var(--hover-purple);
    color: var(--text-purple);
    text-align: center;
    margin-top: 1rem;
    margin-left: -18rem;
    margin-bottom: 1rem;
    font-size: 1.5rem;
  }
  .newsGraph1,
  .newsGraph2,
  .newsGraph3 {
    margin-bottom: 1rem;
  }
  .youtube {
    z-index: 999;
    display: inline;
    position: absolute;
    text-align: center;
    color: var(--text-purple);
  }
  span.anchor {
    display: block;
    visibility: hidden;
    position: relative;
    padding-top: 20%;
  }
  .activeDelegators,
  .holders,
  .curatorCount,
  .subgraphCount {
    font-weight: 400;
    color: var(--text-purple);
    letter-spacing: 0.0375rem;
  }

  .video {
    position: absolute;
    box-shadow: #4D1D86 4px 8px 10px 0;
    text-align: center;
    margin-top: 3rem;
    margin-left: 25%;
  }
  .g-ytsubscribe {
    position: absolute;
    font-family: Euclid Circular A;
    font-weight: 400;
    margin: 0;
    left: 58%;
    top: 84%;
  }
 
  .newsGraph {
    width: 100%;
    box-shadow: #4D1D86 4px 5px 10px 3px;
  }
  .chartImg {
    width: 100%;
    box-shadow: #4D1D86 6px 6px 10px 3px;
    margin: auto;
  }
  .center {
    margin: auto;
  }
  .line1 {
    margin-top: 5rem;
  }
  
  .chartImg6 {
    text-align: center;
    padding-top: 1rem;
    width: 100%;
  
  }
  .reveal {
    position: relative;
    transform: translateY(50px);
    opacity: 0;
    transition: 0.75s all ease;
  }
  .reveal.active {
    transform: translateY(0);
    opacity: 1;
  }
  .email {
    color: var(--hover-purple);
    font-weight: 500;
  }
  .footerLink {
    color: var(--hover-purple);
  }
  .footerLink:link {
    color: var(--hover-purple);
    cursor: pointer;
  }
  .footerLink:visited {
    color: var(--hover-purple);
    cursor: pointer;
  }
  .footerLink.active,
  .footerLink:focus {
    border-bottom: 0rem solid var(--hover-purple);
    cursor: pointer;
  }
  .footerLink:hover {
    color: var(--text-purple);
    transform: scale(1.05);
    cursor: pointer;
  }
  .footer {
    margin-bottom: -1rem;
    padding: 1rem;
    font-weight: 700;
    margin-top: 1rem;
    font-size: 1.1rem;
    text-align: center;
    background-color: var(--primary-purple);
    color: var(--secondary-purple);
  }
  .tsparticles {
    display: none;
  }
  .rotate-position,
  .about-rotate-position,
  .phone,
  .about-phone,
  .message,
  .about-message, .home-message {
    display: none;
  }

  .note {
    text-decoration: underline;
  }


 .admin-video {
    position: absolute;
    left: 48%;
    top: 20%;
    width: 10%;
    height: 10%;
  }
}
/* *************************************************************************
****************************************************************************
***************************************************************************/
/* *************************************************************************
****************************************************************************
***************************************************************************/
@media screen and (min-width: 802px) and (max-width: 804px) {
  .flashcards-title {
margin-top: 120px!important;
}
  .hide-line {
    color: transparent!important;
    display: block!important;
  }
  .Quiz-button {
    margin-bottom: 2rem;
  }
  .quiz-title {
    margin-top: -60px;
  }
  .quiz-button-position {
    padding-top: 2rem;
    margin: auto;
    text-align: center;
    margin-bottom: 2rem;
  }
  
  .final-exam-question {
      font-size: 1.3rem;
      text-align: center;
      color: #d8d2e4;
      /* remove for mobile */
      margin-top: 0rem;
      /* remove for mobile */
  }
  
  .final-exam-answer-form {
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin-top: 0rem;
    columns: 1!important;
    width: 100%!important;
  }
  
  .form-check-label {
    font-size: 20px;
    text-align: center;
    height: 110%;
  }
  .quiz-question {
    font-size: 1.3rem;
    padding-bottom: 2rem;
  text-align: center;
  width: 80%;
  margin: auto;
    text-align: center;
    color: #d8d2e4;
  }
  .quiz-question-container {
    margin-top: 20px;
    width: 100%;
  }

  .quiz-answer-form {
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin-top: 0rem;
    columns: 1!important;
    width: 100%!important;
  }

  .quiz-position {
    margin-top: -10px;
  }
  .mobile-portrait {
    display: none;
    }
  .adminHeader-text {
    font-weight: 400;
    font-size: 1.5rem;
    text-align: center;
    margin: auto;
    padding-top: 1rem;
    color: var(--text-purple);
    
    margin-top: 6rem;
  }
  .adminHeader-text2 {
    font-weight: 400;
    font-size: 1.5rem;
    text-align: center;
    margin: auto;
    color: var(--text-purple);
    
    margin-top: 2rem;
  }
  .adminSubheader-text {
    font-weight: 400;
    font-size: 1rem;
    margin-left: 3rem;
    color: var(--text-purple);
    
    margin-top: 1rem;
  }
  .adminSubheader-text2 {
    font-weight: 400;
    font-size: 1rem;
    margin-left: 3rem;
    margin-right: 3rem;
    color: var(--text-purple);

    margin-top: 1rem;
  }
  .monitor-line-carousel {
    display: none;
  }
  
  .monitor-bar-carousel {
   display: none;
  }
  
  .monitor-other-carousel {
    display: none;
  }
  
  .monitor-other-charts-position {
    display: none;
  }
  .mobile-bar-carousel {
    width: 80%;
  height: 500px;
  margin: auto auto;
  padding-top: -1rem;
  padding-bottom: -5rem;
  margin-bottom: -6rem;
  overflow: hidden;
  }
  .mobile-line-carousel {
    width: 80%;
    height: 500px;
    margin: auto auto;
    padding-top: 0rem;
    overflow: hidden;
  }

  .mobile-line-pos {
padding-top: 3rem;
  }
  .more-data-hr {
    margin-top: 1rem!important;
  }
  .more-data-title {
    margin-bottom: -12rem!important;
  }
  .mobile-other-carousel {
    width: 110%;
    height: 550px;
    margin: auto auto;
    margin-left: -2.5rem;
    padding-top: 1rem!important;
    overflow: hidden;
  }
  .map {
    width: 100%;
    margin-left: 5%;
    box-shadow: #4D1D86 4px 5px 10px 3px;
  }
  .holders-pos {
    margin-top: -7rem;
    margin-left: 1%;
    padding-bottom: 2rem;
  }
  .css-1fzpoyk {
    width: 100%;
    padding-top: 1rem;
  }

  .extra-data {
    margin-left: 2rem;
    margin-right: 2rem;
    font-size: 1.25rem;
    text-align: center;
    color: var(--text-purple);
  }
  .data-title {
    letter-spacing: 0.0375rem;
    font-weight: 400;
    color: var(--text-purple);
    font-size: 2rem;
    text-align: center;
    position: relative;
  }
  .chartsTitle {
    letter-spacing: 0.0375rem;
    font-weight: 400;
    color: var(--text-purple);
    font-size: 2rem;
    text-align: center;
    position: relative;
    margin-bottom: -13rem;
  }
  .chartsTitle2 {
    letter-spacing: 0.0375rem;
    font-weight: 400;
    color: var(--text-purple);
    font-size: 2rem;
    text-align: center;
    margin-bottom: -10rem;
  }
  .hr {
    border: 3px solid var(--hr);
    border-radius: 5px;
    width: 90%;
    margin: auto;
    box-shadow: 0 0 10px 3px var(--hover-purple);
    position: relative;
    margin-top: 3rem;
  }
  .hr2 {
    border: 3px solid var(--hr);
    border-radius: 5px;
    width: 90%;
    margin: auto;
    box-shadow: 0 0 10px 3px var(--hover-purple);
    position: relative;
    margin-top: 3rem;
  }
 
  .move-up {
    margin-top: -45rem;
  }
.hide-me {
  display: none;
}
  .css-1qzevvg img {
    height: 100%;
    margin-top: -10rem;
    text-align: center;
    margin-left: -1rem;
    z-index: 999999;
    position: relative;
}

/* above me */
  .mobile-dropdown {
    margin-left: 0.75rem!important;
  }
  .offcanvas-body {
    width: 100%!important;
    opacity: 0.9;
  }
  .offcanvas {
    width: 40%!important;
    background-color: var(--background)!important;
  }

 
   /* chatbot height start */
.react-chatbot-kit-chat-inner-container {
  background-color: var(--text-purple)!important;
  height: 260px!important;
}
.react-chatbot-kit-chat-message-container {
  height: 220px!important;
}
  /* chatbot height end */
/* dune start */
.GRTDailyTransactionsPosition {
  margin-bottom: 1rem!important;
  margin-left: -15px!important;
 }
 .HoldersGraph {
  background-image: linear-gradient(90deg, #1B0D33, #D8D2E4, #D8D2E4,#D8D2E4, #D8D2E4,#D8D2E4,#D8D2E4,#D8D2E4,#D8D2E4,#D8D2E4,#D8D2E4,#D8D2E4,#D8D2E4,#D8D2E4,#D8D2E4,#D8D2E4,#D8D2E4,#D8D2E4,#1B0D33);
  margin-bottom: -36px!important;
  margin-left: -10px!important;
  padding-left: 20px!important;
  padding-right: 20px!important;
  height: 80px!important;
  width: 100%!important;
}
.HoldersGraphBottom {
  background-image: linear-gradient(90deg, #1B0D33, #D8D2E4,#D8D2E4,#D8D2E4,#D8D2E4,#D8D2E4,#D8D2E4,#D8D2E4,#D8D2E4,#D8D2E4,#D8D2E4,#D8D2E4,#D8D2E4,#D8D2E4,#D8D2E4,#D8D2E4,#D8D2E4,#D8D2E4, #1B0D33);
  padding-left: 20px!important;
  margin-left: -10px!important;
  padding-right: 20px!important;
  height: 80px!important;
  width: 100%!important;
}
  .chatbot-footer-bottom {
    margin-bottom: -80px!important;
   }
  .chatbot-link-logo{
    margin-top: 2rem;
    z-index: 9998;
    position: fixed;
    max-width: 4rem;
    background: white;
    -moz-border-radius: 50px;
    -webkit-border-radius: 50px;
    border-radius: 50px;
    padding: -1rem;
    right: 2%;
    border: 3px var(--secondary-purple) solid;
    box-shadow: 0 0 0 var(--hover-purple);
    animation: pulse 2s infinite;
  }
  .advocate-link-logo{
    margin-top: 4rem;
    z-index: 9998;
    position: fixed;
    max-width: 4rem;
    background: white;
    -moz-border-radius: 50px;
    -webkit-border-radius: 50px;
    border-radius: 50px;
    padding: -1rem;
    right: 2%;
    border: 3px var(--secondary-purple) solid;
    box-shadow: 0 0 0 var(--hover-purple);
    animation: pulse 2s infinite;
  }
  @-webkit-keyframes pulse {
    0% {
      -webkit-box-shadow: 0 0 0 0  var(--text-purple);
    }
    70% {
        -webkit-box-shadow: 0 0 0 15px rgba(244,157,22, 0);
    }
    100% {
        -webkit-box-shadow: 0 0 0 0 rgba(244,157,22, 0);
    }
  }
  @keyframes pulse {
    0% {
      -moz-box-shadow: 0 0 0 0  var(--text-purple);
      box-shadow: 0 0 0 0  var(--text-purple);
    }
    70% {
        -moz-box-shadow: 0 0 0 15px rgba(244,157,22, 0);
        box-shadow: 0 0 0 15px rgba(244,157,22, 0);
    }
    100% {
        -moz-box-shadow: 0 0 0 0 rgba(244,157,22, 0);
        box-shadow: 0 0 0 0 rgba(244,157,22, 0);
    }
  }
  
  .chatbot-link-export{
    margin-top: 8rem;
    z-index: 9998;
    position: fixed;
    max-width: 4rem;
    background: white;
    -moz-border-radius: 50px;
    -webkit-border-radius: 50px;
    border-radius: 50px;
    padding: -1rem;
    right: 0.5rem;
    border: 3px var(--secondary-purple) solid;
    box-shadow: 0 0 0 var(--hover-purple);
    animation: pulse 2s infinite;
  }
/* Chatbot */
.feedback-text {
  margin-top: 2rem;
}
.feedback-button {
  border: 2px var(--primary-purple) solid!important;
  background-color: var(--text-purple)!important;
  color: var(--primary-purple)!important;
  padding: auto!important;
  margin: auto!important;
}

  .feedback-button:link {
    cursor: pointer;
  }
  .feedback-button:visited {
    cursor: pointer;
  }
  .feedback-button.active,
  .feedback-button:focus {
    cursor: pointer;
  }
  .feedback-button:hover {
    background-color: var(--background)!important;
    color: var(--text-purple)!important;
    transform: scale(1.015);
    cursor: pointer;
  }
  .feedback-button-text {
    font-size: 1.5rem;
  }

.react-chatbot-kit-chat-inner-container {
  background-color: var(--text-purple)!important;
}

.react-chatbot-kit-chat-header {
  font-size: 1.1rem!important;
  color: var(--text-purple)!important;
  background-color: var(--primary-purple)!important;
  padding: 0rem!important;
}

.react-chatbot-kit-chat-input-container {
  background-color:var(--secondary-purple)!important;
  border: 1px solid var(--primary-purple)!important;
}

.react-chatbot-kit-chat-btn-send {
  background-color:var(--primary-purple)!important;
}

.react-chatbot-kit-chat-btn-send-icon:hover {
transform: scale(1.25);
}

.react-chatbot-kit-chat-bot-message-container {
  width: 100%;
}

.react-chatbot-kit-chat-bot-avatar-container {
}

.react-chatbot-kit-chat-bot-avatar-icon {
}

.react-chatbot-kit-chat-bot-avatar-letter {
  display: none;
}
.react-chatbot-kit-chat-container {
  width: 98%!important;
  text-align: center;
  margin: auto;
}

.react-chatbot-kit-chat-bot-avatar-container {
  width: 3.5rem!important;
  height: 3.5rem!important;
  background-color: white!important;
  border: 2px solid var(--primary-purple);
  background-image: url('./GRTDataHubChatBot.png')!important;
  background-repeat: no-repeat!important;
  background-size: 3rem;
  background-position-x: 50%;
  background-position-y: 20%;
}

.react-chatbot-kit-chat-bot-message {
  text-align: left!important;
  width: 100%!important;
  color: var(--text-purple)!important;
  background-color: #0C0B23!important;
  font-size: 1rem!important;
}

.react-chatbot-kit-chat-bot-message-arrow {
  color: #0C0B23!important;
  border-right: 8px solid #0C0B23!important;
}
  .indexer-table-header {
    text-align: center;
    margin-left: 1rem;
  }
  .indexer-table {
    text-align: center;
  }
  .chatbot-data {
    display: inline!important;
  }
  .telegramActiveDelegationCount {
    max-width: 92%;
    margin: auto; 
    text-align: center;
    margin-top: 0.5%;
    }
    .top-telegram {
      margin-top: 0.25rem;
    }
    .chatbot-top {
      margin-top: 5rem;
    }
    .chatbot-page-container {
      background: var(--background);
      color: var(--text-purple);
      width: 100%;
      margin: auto;
      margin-top: 100px;
      padding-left: 10px;
      padding-right: 10px;
      text-align: center;
    }
    .chatbot-text-container {
      background: whitesmoke;
      border-radius: 1rem;
      color: var(--secondary-purple);
      margin: auto!important;
      padding-bottom: 10px;
      padding-top: 10px;
      text-align: center!important;
      padding-left: 15px;
      padding-right: 15px;
    }
    .chatbot-page-title {
      font-size: 1.25rem;
      font-weight: 600;
      color: var(--secondary-purple);
      padding-bottom: 0.25rem;
      padding-left: 5px;
      padding-right: 5px;
    }
    .chatbot-page-subtitle {
      font-size: 1.75rem;
      padding-left: 5px;
      padding-right: 5px;
    }
    .chatbot-page-text {
      font-size: 1.25rem;
      margin-bottom: 1rem;
      margin-top: 1rem;
      padding-left: 10px;
      padding-right: 10px;
    }
    /* chatbot end */
  .dataHub-logos {
    max-width: 6rem !important;
    max-height: 35rem !important;
    position: absolute;
    top: 0;
    margin-top: 1rem;
  }
  .monitor-config {
    display: none!important;
  }
  .hide-telegram {
    display: none;
  }
  body > div:nth-child(1) > button {
    display: none!important;
  }
  .pages {
    margin-bottom: 0.1rem;
    margin-right: 2rem;
}
.indexer-title {
  text-align: center!important;
  font-weight: 400;
  font-size: 1.5rem;
  padding-top: 2rem;
  color: var(--text-purple);
  
  margin-top: 6rem;
}
.indexer-subtitle {
  text-align: center!important;
  font-weight: 400;
  font-size: 1.25rem;
  color: var(--text-purple);
  
  margin-top: 1rem;
  margin-right: 2rem;
  margin-left: 2rem;
}
.indexer {
  z-index: 999;
  position: absolute;
  left: 0%;
  top: 50%;
}

.curator {
  z-index: 999;
  position: absolute;
  text-align: center!important;
  top: 50%;
}

.tokens, .rewards, .uncollected-rewards {
  margin-top: 2rem;
  width: 90%;
  height: 14rem;
  margin-left: 5%;
  box-shadow: #4D1D86 4px 5px 10px 3px;
}

.b-map {
  margin-top: -2rem;
  margin-left: 1rem;
  width: 720px;
  height: 3200px;
  text-align: center!important;
  box-shadow: #4D1D86 4px 5px 10px 3px;
}

.c-map {
  margin-top: 2rem;
  margin-left: 1rem;
  width: 720px;
  height: 1000px;
  text-align: center!important;
  box-shadow: #4D1D86 4px 5px 10px 3px;
}

.d-map {
  margin-top: -3rem;
  margin-left: 3rem;
  width: 720px;
  height: 3800px;
  text-align: center!important;
  box-shadow: #4D1D86 4px 5px 10px 3px;
}

.rewards-per-hour {
  margin-top: 2rem;
  padding-bottom: 2rem;
  width: 90%;
  height: 14rem;
  margin-left: 5%;
  box-shadow: #4D1D86 4px 5px 10px 3px;
}

.indexers {
  padding-left: 1rem;
  padding-left: 1rem;
  color: grey;
  font-size:1.25rem;
  margin-right: 1rem;
}
.indexers:link{
  color: grey;
  cursor: pointer;
  font-size:1.25rem;
  margin-right: 1rem;
}
.indexers:visited {
  color: grey;
  cursor: pointer;
  font-size:1.25rem;
  margin-right: 1rem;
}
.indexers.active,
.indexers:focus {
  border-bottom: 0rem solid var(--hover-purple);
  cursor: pointer;
  font-size:1.25rem;
  margin-right: 1rem;
}
.indexers:hover {
  color: var(--text-purple);
  transform: scale(1.05);
  cursor: pointer;
  font-size:1.25rem;
  margin-right: 1rem;
}
.indexer-link {
  color: white;
}
.indexer-link:link {
  color: white;
  cursor: pointer;
}
.indexer-link:visited {
  color: var(--text-purple);
  cursor: pointer;
}
.indexer-link.active,
.indexer-link:focus {
  border-bottom: 0rem solid var(--text-purple);
  cursor: pointer;
}
.indexer-link:hover {
  color: var(--text-purple);
  transform: scale(1.05);
  cursor: pointer;
}
  .cookie-page-text {
    padding-top: 1rem;
    font-size: 1.25rem;
    margin-bottom: 1rem;
    padding-left: 20px;
    padding-right: 20px;
  }
  .cookies-video {
    padding-top: 2rem;
  }
  .cookie-button {
    z-index: 9999;
    position: absolute;
  }

  body > div:nth-child(1) > button {
    z-index: 10000;
    font-size: 3rem;
    position: absolute;
    padding: auto;
    margin-top: -85px;
    width: 45px;
    height: 45px;
    margin-left: 83%;
    background-color: transparent;
    border: none;
    color: var(--text-purple);
  }
  .extra-data {
    margin-top: 2rem;
    font-size: 1.25rem;
    text-align: center;
    color: var(--text-purple);
   
  }
  .extra-data2 {
    font-size: 1.25rem;
    text-align: center;
    color: var(--text-purple);
   
  }
  .data-link {
    color: grey;
  }
  .data-link:link {
    color: grey;
    cursor: pointer;
  }
  .data-link:visited {
    color: grey;
    cursor: pointer;
  }
  .data-link.active,
  .data-link:focus {
    border-bottom: 0rem solid white;
    cursor: pointer;
  }
  .data-link:hover {
    color: white;
    transform: scale(1.05);
    cursor: pointer;
  }
  .question-bank-container {
    background: var(--background);
    color: var(--text-purple);
    width: 100%;
    margin: auto;
    margin-top: 150px;
    margin-bottom: 50px;
    padding-left: 50px;
    padding-right: 50px;
    text-align: center;
  }
  .about-page-container {
    background: var(--background);
    color: var(--text-purple);
    width: 100%;
    margin: auto;
    margin-top: 150px;
    margin-bottom: 50px;
    padding-left: 50px;
    padding-right: 50px;
    text-align: center;
  }
  .QB-text-container {
    background: white;
    border-radius: 1rem;
    padding: 30px;
    color: var(--secondary-purple);
  }
  .text-container {
    background: white;
    border-radius: 1rem;
    padding: 30px;
    color: var(--secondary-purple);
  }
  .about-hr {
    border: 1.5px solid var(--background);
    color: var(--background);
    border-radius: 5px;
    width: 35%;
    margin: auto;
    margin-bottom: 1rem;
    box-shadow: 0 0 5px 1px var(--background);
  }
  .about-page-title {
    font-size: 2rem;
    font-weight: 600;
    color: var(--secondary-purple);
    padding-bottom: 3rem;
  }
  .about-page-subtitle {
    font-size: 1.75rem;
  }
  .about-page-text {
    font-size: 1.25rem;
    margin-bottom: 1rem;
  }
  .graphiql-link {
    color: var(--secondary-purple);
  }
  .graphiql-link:link {
    color: var(--secondary-purple);
    cursor: pointer;
  }
  .graphiql-link:visited {
    color: var(--secondary-purple);
    cursor: pointer;
  }
  .graphiql-link.active,
  .graphiql-link:focus {
    border-bottom: 0rem solid var(--hover-purple);
    cursor: pointer;
  }
  .graphiql-link:hover {
    color: var(--primary-purple);
    transform: scale(1.05);
    cursor: pointer;
  }
  .cookies-policy-container {
    background: white;
    width: 90%;
    padding-bottom: 1rem;
    margin: auto;
    border-radius: 2rem;
    margin-top: 150px;
    margin-bottom: 50px;
  }
  .termly-cookie-preference-button {
   display: none;
  }


  .privacy-policy-container {
    background: white;
    width: 90%;
    padding-bottom: 1rem;
    margin: auto;
    border-radius: 2rem;
    margin-top: 150px;
    margin-bottom: 50px;
  }
  .privacy-policy {
    margin-top: 110px;
    font-weight: 700;
    font-size: 1.1rem;
    text-align: center;
    background-color: var(--background);
    color: var(--secondary-purple);
  }
  .privacy-policy-link,
  .about {
    padding-left: 1rem;
    padding-left: 1rem;
    color: grey;
  }
  .privacy-policy-link:link,
  .about:link {
    color: grey;
    cursor: pointer;
  }
  .privacy-policy-link:visited,
  .about:visited {
    color: grey;
    cursor: pointer;
  }
  .privacy-policy-link.active,
  .privacy-policy-link:focus,
  .about.active,
  .about:focus {
    border-bottom: 0rem solid var(--hover-purple);
    cursor: pointer;
  }
  .privacy-policy-link:hover,
  .about:hover {
    color: var(--text-purple);
    transform: scale(1.05);
    cursor: pointer;
  }
  .subscribeNote {
    margin-left: -15rem;
    width: 30rem;
    font-size: 0.75rem;
    color: white;
  }
  /* loader new start  */
  .loader:empty {
    position: absolute;
    top: calc(50% + 5.5rem);
    left: calc(50% + 2em);
    width: 4em;
    height: 4em;
    border: 0.35em solid white;
    border-left: 0.35em solid #10034B;
    border-radius: 50%;
    animation: load8 0.8s infinite linear;
  }
  .loadingTitle {
    position: absolute;
    top: 17rem;
    left: calc(50% - 3em);
    font-size: 2rem;
    color: #10034B;
    -webkit-box-reflect:below 0px linear-gradient(transparent, #0005);
  }
  .loaderLogo {
    position: absolute;
    top: 4rem;
    left: calc(50% - 8em);
    width: 15em;
    height: 12em;
  }
  @keyframes load8 {
    0% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(360deg);
    }
  }
  /* loader new end  */
  .C {
    font-size: 1rem;
  }
  .logo {
    width: 5.75rem;
    height: 4.75rem;
    margin-left: 2rem;
    margin-top: -0.5rem;
  }
  .data-logo {
    max-width: 1rem !important;
    height: 1rem !important;
    position: absolute;
  }
  .brand-text {
    color: var(--text-purple);
    display: inline;
    margin-left: 0rem;
    letter-spacing: 0.0375rem;
    font-size: 1.25rem;
  }
  .header-text {
    font-weight: 400;
    font-size: 2rem;
    padding-top: 1rem;
    text-align: center;
    margin-top: 105px;
    margin-left: 2rem;
    color: var(--text-purple);

  }
  .header-sub-text {
    font-weight: 400;
    text-align: center!important;
    margin: auto!important;
    font-size: 1.5rem;
    width: 90%;
    color: var(--text-purple);
    display: block;
    text-align: center;
   
  }
  nav a.TGN {
    display: none;
  }
  .navbar-nav > li > a.TGN:link {
    display: none;
  }
  .navbar-nav > li > a.TGN:visited {
    display: none;
  }
  .navbar-nav > li > a.TGN.active,
  .navbar-nav > li > a.TGN:focus {
    display: none;
  }
  .navbar-nav > li > a.TGN:hover {
    display: none;
  }
  nav a.nav-link {
    font-family: Euclid Circular A;
    font-weight: 600;
    font-size: 1.15rem;
    color: var(--text-purple);
  }
  .navbar-nav > li > a {
    border-bottom: 0.01875rem solid var(--transparent);
    padding: 0;
  }
  .navbar-nav > li > a:link {
    color: var(--hover-purple);
  }
  .navbar-nav > li > a:visited {
    color: var(--text-purple);
  }
  .navbar-nav > li > a.active,
  .navbar-nav > li > a:focus {
    border-bottom: 0.01875rem solid var(--hover-purple);
    padding: 0;
    transition: all 0.5s ease-in-out;
  }
  .navbar-nav > li > a:hover {
    border-bottom: 0.01875rem solid var(--hover-purple);
    padding: 0;
    color: var(--hover-purple);
    transform: scale(1.05);
  }
  .line {
    margin-left: 1rem;
  }
  .links {
    text-align: right;
    padding-right: 0;
    margin: auto;
    padding: auto;
  }
  .dropdown {
    margin-left: auto;
    padding: auto;
  }
  .bar {
    margin-bottom: 0.1rem;
    margin-right: 2rem;
  }
  .line {
    margin-bottom: 0.1rem;
    margin-right: 2rem;
  }
  .about {
    margin-left: auto;
    margin-right: 0.8rem;
  }
  .menu-icon {
    color: var(--secondary-purple);
  }
  .main-info {
    background-image: url("TopBackground.svg");
    background-repeat: no-repeat;
    background-size: 100% auto;
    width: 100%;
    height: 100%;
    margin-top: 90px;
  }
  .community-links {
    text-align: center;
    padding-top: 5rem;
    position: absolute;
    z-index: 999;
    left: 35%;
  }
  .telegram-icon,
  .twitter-icon,
  .reddit-icon,
  .youtube-icon {
    display: inline;
    padding: 1rem;
    transition: all 0.5s ease-in-out;
    z-index: 999;
  }
  .icon:hover {
    color: var(--hover-purple);
    transform: scale(1.15);
  }
  .icon-youtube:hover {
    color: var(--text-purple);
    transform: scale(1.1);
  }
  .news-title {
    text-shadow: 2.25px 2.25px 3px var(--hover-purple);
    color: var(--text-purple);
    text-align: center;
    margin-top: 1rem;
    margin-left: -18rem;
    margin-bottom: 1rem;
    font-size: 1.5rem;
  }
  .newsGraph1,
  .newsGraph2,
  .newsGraph3 {
    margin-bottom: 1rem;
  }
  .youtube {
    z-index: 999;
    display: inline;
    position: absolute;
    text-align: center;
    color: var(--text-purple);
  }
  span.anchor {
    display: block;
    visibility: hidden;
    position: relative;
    padding-top: 20%;
  }
  .activeDelegators,
  .holders,
  .curatorCount,
  .subgraphCount {
    font-weight: 400;
    color: var(--text-purple);
    letter-spacing: 0.0375rem;
  }

  .video {
    position: absolute;
    box-shadow: #4D1D86 4px 8px 10px 0;
    text-align: center;
    margin-top: 3rem;
    margin-left: 25%;
  }
  .g-ytsubscribe {
    position: absolute;
    font-family: Euclid Circular A;
    font-weight: 400;
    margin: 0;
    left: 58%;
    top: 84%;
  }
  
  .newsGraph {
    width: 100%;
    box-shadow: #4D1D86 4px 5px 10px 3px;
  }
  .chartImg {
    width: 100%;
    box-shadow: #4D1D86 6px 6px 10px 3px;
    margin: auto;
  }
  .center {
    margin: auto;
  }
  .line1 {
    margin-top: 5rem;
  }

  .chartImg6 {
    text-align: center;
    padding-top: 1rem;
    width: 100%;
  
  }
  .reveal {
    position: relative;
    transform: translateY(50px);
    opacity: 0;
    transition: 0.75s all ease;
  }
  .reveal.active {
    transform: translateY(0);
    opacity: 1;
  }
  .email {
    color: var(--hover-purple);
    font-weight: 500;
  }
  .footerLink {
    color: var(--hover-purple);
  }
  .footerLink:link {
    color: var(--hover-purple);
    cursor: pointer;
  }
  .footerLink:visited {
    color: var(--hover-purple);
    cursor: pointer;
  }
  .footerLink.active,
  .footerLink:focus {
    border-bottom: 0rem solid var(--hover-purple);
    cursor: pointer;
  }
  .footerLink:hover {
    color: var(--text-purple);
    transform: scale(1.05);
    cursor: pointer;
  }
  .footer {
    margin-bottom: -1rem;
    padding: 1rem;
    font-weight: 700;
    margin-top: 1rem;
    font-size: 1.1rem;
    text-align: center;
    background-color: var(--primary-purple);
    color: var(--secondary-purple);
  }
  .tsparticles {
    display: none;
  }
  .rotate-position,
  .about-rotate-position,
  .phone,
  .about-phone,
  .message,
  .about-message, .home-message {
    display: none;
  }
 
  .note {
    text-decoration: underline;
  }

 .admin-video {
    position: absolute;
    left: 48%;
    top: 20%;
    width: 10%;
    height: 10%;
  }

 
}
/* *************************************************************************
****************************************************************************
***************************************************************************/
/* *************************************************************************
****************************************************************************
***************************************************************************/
@media screen and (min-width: 800px) and (max-width: 801px) {
  .flashcards-title {
margin-top: 120px!important;
}
  .hide-line {
    color: transparent!important;
    display: block!important;
  }
  .Quiz-button {
    margin-bottom: 2rem;
  }
  .quiz-title {
    margin-top: -60px;
  }
  .quiz-button-position {
    padding-top: 2rem;
    margin: auto;
    text-align: center;
    margin-bottom: 2rem;
  }
  
  .final-exam-question {
      font-size: 1.3rem;
      text-align: center;
      color: #d8d2e4;
      /* remove for mobile */
      margin-top: 0rem;
      /* remove for mobile */
  }
  
  .final-exam-answer-form {
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin-top: 0rem;
    columns: 1!important;
    width: 100%!important;
  }
  
  .form-check-label {
    font-size: 20px;
    text-align: center;
    height: 110%;
  }
  .quiz-question {
    font-size: 1.3rem;
    padding-bottom: 2rem;
  text-align: center;
  width: 80%;
  margin: auto;
    text-align: center;
    color: #d8d2e4;
  }
  .quiz-question-container {
    margin-top: 20px;
    width: 100%;
  }

  .quiz-answer-form {
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin-top: 0rem;
    columns: 1!important;
    width: 100%!important;
  }

  .quiz-position {
    margin-top: -10px;
  }
  .mobile-portrait {
    display: none;
    }
  .adminHeader-text {
    font-weight: 400;
    font-size: 1.5rem;
    text-align: center;
    margin: auto;
    padding-top: 1rem;
    color: var(--text-purple);
    
    margin-top: 6rem;
  }
  .adminHeader-text2 {
    font-weight: 400;
    font-size: 1.5rem;
    text-align: center;
    margin: auto;
    color: var(--text-purple);
    
    margin-top: 2rem;
  }
  .adminSubheader-text {
    font-weight: 400;
    font-size: 1rem;
    margin-left: 3rem;
    color: var(--text-purple);
    
    margin-top: 1rem;
  }
  .adminSubheader-text2 {
    font-weight: 400;
    font-size: 1rem;
    margin-left: 3rem;
    margin-right: 3rem;
    color: var(--text-purple);

    margin-top: 1rem;
  }
  .monitor-line-carousel {
    display: none;
  }
  
  .monitor-bar-carousel {
   display: none;
  }
  
  .monitor-other-carousel {
    display: none;
  }
  
  .monitor-other-charts-position {
    display: none;
  }
  .mobile-bar-carousel {
    width: 80%;
  height: 500px;
  margin: auto auto;
  padding-top: -1rem;
  padding-bottom: -5rem;
  margin-bottom: -6rem;
  overflow: hidden;
  }
  .mobile-line-carousel {
    width: 80%;
    height: 500px;
    margin: auto auto;
    padding-top: 0rem;
    overflow: hidden;
  }

  .mobile-line-pos {
padding-top: 3rem;
  }
  .more-data-hr {
    margin-top: 1rem!important;
  }
  .more-data-title {
    margin-bottom: -12rem!important;
  }
  .mobile-other-carousel {
    width: 110%;
    height: 550px;
    margin: auto auto;
    margin-left: -2.5rem;
    padding-top: 1rem!important;
    overflow: hidden;
  }
  .map {
    width: 100%;
    margin-left: 5%;
    box-shadow: #4D1D86 4px 5px 10px 3px;
  }
  .holders-pos {
    margin-top: -8rem;
    margin-left: 1%;
    padding-bottom: 2rem;
  }
  .css-1fzpoyk {
    width: 100%;
    padding-top: 1rem;
  }

  .extra-data {
    margin-left: 2rem;
    margin-right: 2rem;
    font-size: 1.25rem;
    text-align: center;
    color: var(--text-purple);
  }
  .data-title {
    letter-spacing: 0.0375rem;
    font-weight: 400;
    color: var(--text-purple);
    font-size: 2rem;
    text-align: center;
    position: relative;
  }
  .chartsTitle {
    letter-spacing: 0.0375rem;
    font-weight: 400;
    color: var(--text-purple);
    font-size: 2rem;
    text-align: center;
    position: relative;
    margin-bottom: -13rem;
  }
  .chartsTitle2 {
    letter-spacing: 0.0375rem;
    font-weight: 400;
    color: var(--text-purple);
    font-size: 2rem;
    text-align: center;
    margin-bottom: -10rem;
  }
  .hr {
    border: 3px solid var(--hr);
    border-radius: 5px;
    width: 90%;
    margin: auto;
    box-shadow: 0 0 10px 3px var(--hover-purple);
    position: relative;
    margin-top: 3rem;
  }
  .hr2 {
    border: 3px solid var(--hr);
    border-radius: 5px;
    width: 90%;
    margin: auto;
    box-shadow: 0 0 10px 3px var(--hover-purple);
    position: relative;
    margin-top: 3rem;
  }
 
  .move-up {
    margin-top: -45rem;
  }
.hide-me {
  display: none;
}
  .css-1qzevvg img {
    height: 100%;
    margin-top: -10rem;
    text-align: center;
    margin-left: -1rem;
    z-index: 999999;
    position: relative;
}

/* above me */
  .mobile-dropdown {
    margin-left: 0.75rem!important;
  }
  .offcanvas-body {
    width: 100%!important;
    opacity: 0.9;
  }
  .offcanvas {
    width: 40%!important;
    background-color: var(--background)!important;
  }

 
   /* chatbot height start */
.react-chatbot-kit-chat-inner-container {
  background-color: var(--text-purple)!important;
  height: 260px!important;
}
.react-chatbot-kit-chat-message-container {
  height: 220px!important;
}
  /* chatbot height end */
/* dune start */
.GRTDailyTransactionsPosition {
  margin-bottom: 1rem!important;
  margin-left: -15px!important;
 }
 .HoldersGraph {
  background-image: linear-gradient(90deg, #1B0D33, #D8D2E4, #D8D2E4,#D8D2E4, #D8D2E4,#D8D2E4,#D8D2E4,#D8D2E4,#D8D2E4,#D8D2E4,#D8D2E4,#D8D2E4,#D8D2E4,#D8D2E4,#D8D2E4,#D8D2E4,#D8D2E4,#D8D2E4,#1B0D33);
  margin-bottom: -36px!important;
  margin-left: -10px!important;
  padding-left: 20px!important;
  padding-right: 20px!important;
  height: 80px!important;
  width: 100%!important;
}
.HoldersGraphBottom {
  background-image: linear-gradient(90deg, #1B0D33, #D8D2E4,#D8D2E4,#D8D2E4,#D8D2E4,#D8D2E4,#D8D2E4,#D8D2E4,#D8D2E4,#D8D2E4,#D8D2E4,#D8D2E4,#D8D2E4,#D8D2E4,#D8D2E4,#D8D2E4,#D8D2E4,#D8D2E4, #1B0D33);
  padding-left: 20px!important;
  margin-left: -10px!important;
  padding-right: 20px!important;
  height: 80px!important;
  width: 100%!important;
}
  .chatbot-footer-bottom {
    margin-bottom: -80px!important;
   }
  .chatbot-link-logo{
    margin-top: 2rem;
    z-index: 9998;
    position: fixed;
    max-width: 4rem;
    background: white;
    -moz-border-radius: 50px;
    -webkit-border-radius: 50px;
    border-radius: 50px;
    padding: -1rem;
    right: 2%;
    border: 3px var(--secondary-purple) solid;
    box-shadow: 0 0 0 var(--hover-purple);
    animation: pulse 2s infinite;
  }
  .advocate-link-logo{
    margin-top: 8rem;
    z-index: 9998;
    position: fixed;
    max-width: 4rem;
    background: white;
    -moz-border-radius: 50px;
    -webkit-border-radius: 50px;
    border-radius: 50px;
    padding: -1rem;
    right: 2%;
    border: 3px var(--secondary-purple) solid;
    box-shadow: 0 0 0 var(--hover-purple);
    animation: pulse 2s infinite;
  }
  @-webkit-keyframes pulse {
    0% {
      -webkit-box-shadow: 0 0 0 0  var(--text-purple);
    }
    70% {
        -webkit-box-shadow: 0 0 0 15px rgba(244,157,22, 0);
    }
    100% {
        -webkit-box-shadow: 0 0 0 0 rgba(244,157,22, 0);
    }
  }
  @keyframes pulse {
    0% {
      -moz-box-shadow: 0 0 0 0  var(--text-purple);
      box-shadow: 0 0 0 0  var(--text-purple);
    }
    70% {
        -moz-box-shadow: 0 0 0 15px rgba(244,157,22, 0);
        box-shadow: 0 0 0 15px rgba(244,157,22, 0);
    }
    100% {
        -moz-box-shadow: 0 0 0 0 rgba(244,157,22, 0);
        box-shadow: 0 0 0 0 rgba(244,157,22, 0);
    }
  }
  
  .chatbot-link-export{
    margin-top: 8rem;
    z-index: 9998;
    position: fixed;
    max-width: 4rem;
    background: white;
    -moz-border-radius: 50px;
    -webkit-border-radius: 50px;
    border-radius: 50px;
    padding: -1rem;
    right: 0.5rem;
    border: 3px var(--secondary-purple) solid;
    box-shadow: 0 0 0 var(--hover-purple);
    animation: pulse 2s infinite;
  }
/* Chatbot */
.feedback-text {
  margin-top: 2rem;
}
.feedback-button {
  border: 2px var(--primary-purple) solid!important;
  background-color: var(--text-purple)!important;
  color: var(--primary-purple)!important;
  padding: auto!important;
  margin: auto!important;
}

  .feedback-button:link {
    cursor: pointer;
  }
  .feedback-button:visited {
    cursor: pointer;
  }
  .feedback-button.active,
  .feedback-button:focus {
    cursor: pointer;
  }
  .feedback-button:hover {
    background-color: var(--background)!important;
    color: var(--text-purple)!important;
    transform: scale(1.015);
    cursor: pointer;
  }
  .feedback-button-text {
    font-size: 1.5rem;
  }

.react-chatbot-kit-chat-inner-container {
  background-color: var(--text-purple)!important;
}

.react-chatbot-kit-chat-header {
  font-size: 1.1rem!important;
  color: var(--text-purple)!important;
  background-color: var(--primary-purple)!important;
  padding: 0rem!important;
}

.react-chatbot-kit-chat-input-container {
  background-color:var(--secondary-purple)!important;
  border: 1px solid var(--primary-purple)!important;
}

.react-chatbot-kit-chat-btn-send {
  background-color:var(--primary-purple)!important;
}

.react-chatbot-kit-chat-btn-send-icon:hover {
transform: scale(1.25);
}

.react-chatbot-kit-chat-bot-message-container {
  width: 100%;
}

.react-chatbot-kit-chat-bot-avatar-container {
}

.react-chatbot-kit-chat-bot-avatar-icon {
}

.react-chatbot-kit-chat-bot-avatar-letter {
  display: none;
}
.react-chatbot-kit-chat-container {
  width: 98%!important;
  text-align: center;
  margin: auto;
}

.react-chatbot-kit-chat-bot-avatar-container {
  width: 3.5rem!important;
  height: 3.5rem!important;
  background-color: white!important;
  border: 2px solid var(--primary-purple);
  background-image: url('./GRTDataHubChatBot.png')!important;
  background-repeat: no-repeat!important;
  background-size: 3rem;
  background-position-x: 50%;
  background-position-y: 20%;
}

.react-chatbot-kit-chat-bot-message {
  text-align: left!important;
  width: 100%!important;
  color: var(--text-purple)!important;
  background-color: #0C0B23!important;
  font-size: 1rem!important;
}

.react-chatbot-kit-chat-bot-message-arrow {
  color: #0C0B23!important;
  border-right: 8px solid #0C0B23!important;
}
  .indexer-table-header {
    text-align: center;
    margin-left: 1rem;
  }
  .indexer-table {
    text-align: center;
  }
  .chatbot-data {
    display: inline!important;
  }
  .telegramActiveDelegationCount {
    max-width: 92%;
    margin: auto; 
    text-align: center;
    margin-top: 0.5%;
    }
    .top-telegram {
      margin-top: 0.25rem;
    }
    .chatbot-top {
      margin-top: 5rem;
    }
    .chatbot-page-container {
      background: var(--background);
      color: var(--text-purple);
      width: 100%;
      margin: auto;
      margin-top: 100px;
      padding-left: 10px;
      padding-right: 10px;
      text-align: center;
    }
    .chatbot-text-container {
      background: whitesmoke;
      border-radius: 1rem;
      color: var(--secondary-purple);
      margin: auto!important;
      padding-bottom: 10px;
      padding-top: 10px;
      text-align: center!important;
      padding-left: 15px;
      padding-right: 15px;
    }
    .chatbot-page-title {
      font-size: 1.25rem;
      font-weight: 600;
      color: var(--secondary-purple);
      padding-bottom: 0.25rem;
      padding-left: 5px;
      padding-right: 5px;
    }
    .chatbot-page-subtitle {
      font-size: 1.75rem;
      padding-left: 5px;
      padding-right: 5px;
    }
    .chatbot-page-text {
      font-size: 1.25rem;
      margin-bottom: 1rem;
      margin-top: 1rem;
      padding-left: 10px;
      padding-right: 10px;
    }
    /* chatbot end */
  .dataHub-logos {
    max-width: 6rem !important;
    max-height: 35rem !important;
    position: absolute;
    top: 0;
    margin-top: 1rem;
  }
  .monitor-config {
    display: none!important;
  }
  .hide-telegram {
    display: none;
  }
  body > div:nth-child(1) > button {
    display: none!important;
  }
  .pages {
    margin-bottom: 0.1rem;
    margin-right: 2rem;
}
.indexer-title {
  text-align: center!important;
  font-weight: 400;
  font-size: 1.5rem;
  padding-top: 2rem;
  color: var(--text-purple);
  
  margin-top: 6rem;
}
.indexer-subtitle {
  text-align: center!important;
  font-weight: 400;
  font-size: 1.25rem;
  color: var(--text-purple);
  
  margin-top: 1rem;
  margin-right: 2rem;
  margin-left: 2rem;
}
.indexer {
  z-index: 999;
  position: absolute;
  left: 0%;
  top: 50%;
}

.curator {
  z-index: 999;
  position: absolute;
  text-align: center!important;
  top: 50%;
}

.tokens, .rewards, .uncollected-rewards {
  margin-top: 2rem;
  width: 90%;
  height: 14rem;
  margin-left: 5%;
  box-shadow: #4D1D86 4px 5px 10px 3px;
}

.b-map {
  margin-top: -2rem;
  margin-left: 1rem;
  width: 720px;
  height: 3200px;
  text-align: center!important;
  box-shadow: #4D1D86 4px 5px 10px 3px;
}

.c-map {
  margin-top: 2rem;
  margin-left: 1rem;
  width: 720px;
  height: 1000px;
  text-align: center!important;
  box-shadow: #4D1D86 4px 5px 10px 3px;
}

.d-map {
  margin-top: -3rem;
  margin-left: 3rem;
  width: 720px;
  height: 3800px;
  text-align: center!important;
  box-shadow: #4D1D86 4px 5px 10px 3px;
}

.rewards-per-hour {
  margin-top: 2rem;
  padding-bottom: 2rem;
  width: 90%;
  height: 14rem;
  margin-left: 5%;
  box-shadow: #4D1D86 4px 5px 10px 3px;
}

.indexers {
  padding-left: 1rem;
  padding-left: 1rem;
  color: grey;
  font-size:1.25rem;
  margin-right: 1rem;
}
.indexers:link{
  color: grey;
  cursor: pointer;
  font-size:1.25rem;
  margin-right: 1rem;
}
.indexers:visited {
  color: grey;
  cursor: pointer;
  font-size:1.25rem;
  margin-right: 1rem;
}
.indexers.active,
.indexers:focus {
  border-bottom: 0rem solid var(--hover-purple);
  cursor: pointer;
  font-size:1.25rem;
  margin-right: 1rem;
}
.indexers:hover {
  color: var(--text-purple);
  transform: scale(1.05);
  cursor: pointer;
  font-size:1.25rem;
  margin-right: 1rem;
}
.indexer-link {
  color: white;
}
.indexer-link:link {
  color: white;
  cursor: pointer;
}
.indexer-link:visited {
  color: var(--text-purple);
  cursor: pointer;
}
.indexer-link.active,
.indexer-link:focus {
  border-bottom: 0rem solid var(--text-purple);
  cursor: pointer;
}
.indexer-link:hover {
  color: var(--text-purple);
  transform: scale(1.05);
  cursor: pointer;
}
  .cookie-page-text {
    padding-top: 1rem;
    font-size: 1.25rem;
    margin-bottom: 1rem;
    padding-left: 20px;
    padding-right: 20px;
  }
  .cookies-video {
    padding-top: 2rem;
  }
  .cookie-button {
    z-index: 9999;
    position: absolute;
  }

  body > div:nth-child(1) > button {
    z-index: 10000;
    font-size: 3rem;
    position: absolute;
    padding: auto;
    margin-top: -85px;
    width: 45px;
    height: 45px;
    margin-left: 83%;
    background-color: transparent;
    border: none;
    color: var(--text-purple);
  }
  .extra-data {
    margin-top: 2rem;
    font-size: 1.25rem;
    text-align: center;
    color: var(--text-purple);
   
  }
  .extra-data2 {
    font-size: 1.25rem;
    text-align: center;
    color: var(--text-purple);
   
  }
  .data-link {
    color: grey;
  }
  .data-link:link {
    color: grey;
    cursor: pointer;
  }
  .data-link:visited {
    color: grey;
    cursor: pointer;
  }
  .data-link.active,
  .data-link:focus {
    border-bottom: 0rem solid white;
    cursor: pointer;
  }
  .data-link:hover {
    color: white;
    transform: scale(1.05);
    cursor: pointer;
  }
  .question-bank-container {
    background: var(--background);
    color: var(--text-purple);
    width: 100%;
    margin: auto;
    margin-top: 150px;
    margin-bottom: 50px;
    padding-left: 50px;
    padding-right: 50px;
    text-align: center;
  }
  .about-page-container {
    background: var(--background);
    color: var(--text-purple);
    width: 100%;
    margin: auto;
    margin-top: 150px;
    margin-bottom: 50px;
    padding-left: 50px;
    padding-right: 50px;
    text-align: center;
  }
  .QB-text-container {
    background: white;
    border-radius: 1rem;
    padding: 30px;
    color: var(--secondary-purple);
  }
  .text-container {
    background: white;
    border-radius: 1rem;
    padding: 30px;
    color: var(--secondary-purple);
  }
  .about-hr {
    border: 1.5px solid var(--background);
    color: var(--background);
    border-radius: 5px;
    width: 35%;
    margin: auto;
    margin-bottom: 1rem;
    box-shadow: 0 0 5px 1px var(--background);
  }
  .about-page-title {
    font-size: 2rem;
    font-weight: 600;
    color: var(--secondary-purple);
    padding-bottom: 3rem;
  }
  .about-page-subtitle {
    font-size: 1.75rem;
  }
  .about-page-text {
    font-size: 1.25rem;
    margin-bottom: 1rem;
  }
  .graphiql-link {
    color: var(--secondary-purple);
  }
  .graphiql-link:link {
    color: var(--secondary-purple);
    cursor: pointer;
  }
  .graphiql-link:visited {
    color: var(--secondary-purple);
    cursor: pointer;
  }
  .graphiql-link.active,
  .graphiql-link:focus {
    border-bottom: 0rem solid var(--hover-purple);
    cursor: pointer;
  }
  .graphiql-link:hover {
    color: var(--primary-purple);
    transform: scale(1.05);
    cursor: pointer;
  }
  .cookies-policy-container {
    background: white;
    width: 90%;
    padding-bottom: 1rem;
    margin: auto;
    border-radius: 2rem;
    margin-top: 150px;
    margin-bottom: 50px;
  }
  .termly-cookie-preference-button {
   display: none;
  }


  .privacy-policy-container {
    background: white;
    width: 90%;
    padding-bottom: 1rem;
    margin: auto;
    border-radius: 2rem;
    margin-top: 150px;
    margin-bottom: 50px;
  }
  .privacy-policy {
    margin-top: 110px;
    font-weight: 700;
    font-size: 1.1rem;
    text-align: center;
    background-color: var(--background);
    color: var(--secondary-purple);
  }
  .privacy-policy-link,
  .about {
    padding-left: 1rem;
    padding-left: 1rem;
    color: grey;
  }
  .privacy-policy-link:link,
  .about:link {
    color: grey;
    cursor: pointer;
  }
  .privacy-policy-link:visited,
  .about:visited {
    color: grey;
    cursor: pointer;
  }
  .privacy-policy-link.active,
  .privacy-policy-link:focus,
  .about.active,
  .about:focus {
    border-bottom: 0rem solid var(--hover-purple);
    cursor: pointer;
  }
  .privacy-policy-link:hover,
  .about:hover {
    color: var(--text-purple);
    transform: scale(1.05);
    cursor: pointer;
  }
  .subscribeNote {
    margin-left: -15rem;
    width: 30rem;
    font-size: 0.75rem;
    color: white;
  }
  /* loader new start  */
  .loader:empty {
    position: absolute;
    top: calc(50% + 5.5rem);
    left: calc(50% + 2em);
    width: 4em;
    height: 4em;
    border: 0.35em solid white;
    border-left: 0.35em solid #10034B;
    border-radius: 50%;
    animation: load8 0.8s infinite linear;
  }
  .loadingTitle {
    position: absolute;
    top: 17rem;
    left: calc(50% - 3em);
    font-size: 2rem;
    color: #10034B;
    -webkit-box-reflect:below 0px linear-gradient(transparent, #0005);
  }
  .loaderLogo {
    position: absolute;
    top: 4rem;
    left: calc(50% - 8em);
    width: 15em;
    height: 12em;
  }
  @keyframes load8 {
    0% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(360deg);
    }
  }
  /* loader new end  */
  .C {
    font-size: 1rem;
  }
  .logo {
    width: 5.75rem;
    height: 4.75rem;
    margin-left: 2rem;
    margin-top: -0.5rem;
  }
  .data-logo {
    max-width: 1rem !important;
    height: 1rem !important;
    position: absolute;
  }
  .brand-text {
    color: var(--text-purple);
    display: inline;
    margin-left: 0rem;
    letter-spacing: 0.0375rem;
    font-size: 1.25rem;
  }
  .header-text {
    font-weight: 400;
    font-size: 2rem;
    padding-top: 1rem;
    text-align: center;
    margin-top: 105px;
    margin-left: 2rem;
    color: var(--text-purple);

  }
  .header-sub-text {
    font-weight: 400;
    text-align: center!important;
    margin: auto!important;
    font-size: 1.5rem;
    width: 90%;
    color: var(--text-purple);
    display: block;
    text-align: center;
   
  }
  nav a.TGN {
    display: none;
  }
  .navbar-nav > li > a.TGN:link {
    display: none;
  }
  .navbar-nav > li > a.TGN:visited {
    display: none;
  }
  .navbar-nav > li > a.TGN.active,
  .navbar-nav > li > a.TGN:focus {
    display: none;
  }
  .navbar-nav > li > a.TGN:hover {
    display: none;
  }
  nav a.nav-link {
    font-family: Euclid Circular A;
    font-weight: 600;
    font-size: 1.15rem;
    color: var(--text-purple);
  }
  .navbar-nav > li > a {
    border-bottom: 0.01875rem solid var(--transparent);
    padding: 0;
  }
  .navbar-nav > li > a:link {
    color: var(--hover-purple);
  }
  .navbar-nav > li > a:visited {
    color: var(--text-purple);
  }
  .navbar-nav > li > a.active,
  .navbar-nav > li > a:focus {
    border-bottom: 0.01875rem solid var(--hover-purple);
    padding: 0;
    transition: all 0.5s ease-in-out;
  }
  .navbar-nav > li > a:hover {
    border-bottom: 0.01875rem solid var(--hover-purple);
    padding: 0;
    color: var(--hover-purple);
    transform: scale(1.05);
  }
  .line {
    margin-left: 1rem;
  }
  .links {
    text-align: right;
    padding-right: 0;
    margin: auto;
    padding: auto;
  }
  .dropdown {
    margin-left: auto;
    padding: auto;
  }
  .bar {
    margin-bottom: 0.1rem;
    margin-right: 2rem;
  }
  .line {
    margin-bottom: 0.1rem;
    margin-right: 2rem;
  }
  .about {
    margin-left: auto;
    margin-right: 0.8rem;
  }
  .menu-icon {
    color: var(--secondary-purple);
  }
  .main-info {
    background-image: url("TopBackground.svg");
    background-repeat: no-repeat;
    background-size: 100% auto;
    width: 100%;
    height: 100%;
    margin-top: 90px;
  }
  .community-links {
    text-align: center;
    padding-top: 5rem;
    position: absolute;
    z-index: 999;
    left: 35%;
  }
  .telegram-icon,
  .twitter-icon,
  .reddit-icon,
  .youtube-icon {
    display: inline;
    padding: 1rem;
    transition: all 0.5s ease-in-out;
    z-index: 999;
  }
  .icon:hover {
    color: var(--hover-purple);
    transform: scale(1.15);
  }
  .icon-youtube:hover {
    color: var(--text-purple);
    transform: scale(1.1);
  }
  .news-title {
    text-shadow: 2.25px 2.25px 3px var(--hover-purple);
    color: var(--text-purple);
    text-align: center;
    margin-top: 1rem;
    margin-left: -18rem;
    margin-bottom: 1rem;
    font-size: 1.5rem;
  }
  .newsGraph1,
  .newsGraph2,
  .newsGraph3 {
    margin-bottom: 1rem;
  }
  .youtube {
    z-index: 999;
    display: inline;
    position: absolute;
    text-align: center;
    color: var(--text-purple);
  }
  span.anchor {
    display: block;
    visibility: hidden;
    position: relative;
    padding-top: 20%;
  }
  .activeDelegators,
  .holders,
  .curatorCount,
  .subgraphCount {
    font-weight: 400;
    color: var(--text-purple);
    letter-spacing: 0.0375rem;
  }

  .video {
    position: absolute;
    box-shadow: #4D1D86 4px 8px 10px 0;
    text-align: center;
    margin-top: 3rem;
    margin-left: 25%;
  }
  .g-ytsubscribe {
    position: absolute;
    font-family: Euclid Circular A;
    font-weight: 400;
    margin: 0;
    left: 58%;
    top: 84%;
  }
  
  .newsGraph {
    width: 100%;
    box-shadow: #4D1D86 4px 5px 10px 3px;
  }
  .chartImg {
    width: 100%;
    box-shadow: #4D1D86 6px 6px 10px 3px;
    margin: auto;
  }
  .center {
    margin: auto;
  }
  .line1 {
    margin-top: 5rem;
  }

  .chartImg6 {
    text-align: center;
    padding-top: 1rem;
    width: 100%;
  
  }
  .reveal {
    position: relative;
    transform: translateY(50px);
    opacity: 0;
    transition: 0.75s all ease;
  }
  .reveal.active {
    transform: translateY(0);
    opacity: 1;
  }
  .email {
    color: var(--hover-purple);
    font-weight: 500;
  }
  .footerLink {
    color: var(--hover-purple);
  }
  .footerLink:link {
    color: var(--hover-purple);
    cursor: pointer;
  }
  .footerLink:visited {
    color: var(--hover-purple);
    cursor: pointer;
  }
  .footerLink.active,
  .footerLink:focus {
    border-bottom: 0rem solid var(--hover-purple);
    cursor: pointer;
  }
  .footerLink:hover {
    color: var(--text-purple);
    transform: scale(1.05);
    cursor: pointer;
  }
  .footer {
    margin-bottom: -1rem;
    padding: 1rem;
    font-weight: 700;
    margin-top: 1rem;
    font-size: 1.1rem;
    text-align: center;
    background-color: var(--primary-purple);
    color: var(--secondary-purple);
  }
  .tsparticles {
    display: none;
  }
  .rotate-position,
  .about-rotate-position,
  .phone,
  .about-phone,
  .message,
  .about-message, .home-message {
    display: none;
  }
 
  .note {
    text-decoration: underline;
  }

 .admin-video {
    position: absolute;
    left: 48%;
    top: 20%;
    width: 10%;
    height: 10%;
  }


}
/* *************************************************************************
****************************************************************************
***************************************************************************/
/* *************************************************************************
****************************************************************************
***************************************************************************/
@media screen and (min-width: 782px) and (max-width: 799px) {
  .flashcards-title {
margin-top: 120px!important;
}
  .hide-line {
    color: transparent!important;
    display: block!important;
  }
  .Quiz-button {
    margin-bottom: 2rem;
  }
  .quiz-title {
    margin-top: -60px;
  }
  .quiz-button-position {
    padding-top: 2rem;
    margin: auto;
    text-align: center;
    margin-bottom: 2rem;
  }
  
  .final-exam-question {
      font-size: 1.3rem;
      text-align: center;
      color: #d8d2e4;
      /* remove for mobile */
      margin-top: 0rem;
      /* remove for mobile */
  }
  
  .final-exam-answer-form {
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin-top: 0rem;
    columns: 1!important;
    width: 100%!important;
  }
  
  .form-check-label {
    font-size: 20px;
    text-align: center;
    height: 110%;
  }
  .quiz-question {
    font-size: 1.3rem;
    padding-bottom: 2rem;
  text-align: center;
  width: 80%;
  margin: auto;
    text-align: center;
    color: #d8d2e4;
  }
  .quiz-question-container {
    margin-top: 20px;
    width: 100%;
  }

  .quiz-answer-form {
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin-top: 0rem;
    columns: 1!important;
    width: 100%!important;
  }

  .quiz-position {
    margin-top: -10px;
  }
  .mobile-landscape {
    display: none;
    }
  .monitor-line-carousel {
    display: none;
  }
  
  .monitor-bar-carousel {
   display: none;
  }
  
  .monitor-other-carousel {
    display: none;
  }
  
  .monitor-other-charts-position {
    display: none;
  }
  .mobile-bar-carousel {
    width: 100%;
  height: 500px;
  margin: auto auto;
  padding-top: -1rem;
  padding-bottom: -5rem;
  margin-bottom: -6rem;
  overflow: hidden;
  }
  .mobile-line-carousel {
    width: 100%;
    height: 500px;
    margin: auto auto;
    padding-top: 0rem;
    overflow: hidden;
  }

  .mobile-line-pos {
padding-top: 3rem;
  }
  .more-data-hr {
    margin-top: 1rem!important;
  }
  .more-data-title {
    padding-bottom: 2rem!important;
  }
  .mobile-other-carousel {
    width: 110%;
    height: 500px;
    margin: auto auto;
    margin-left: -1.5rem;
    padding-top: 1rem!important;
    overflow: hidden;
  }
  .map {
    width: 100%;
    margin-left: 5%;
    box-shadow: #4D1D86 4px 5px 10px 3px;
  }
  .holders-pos {
    margin-left: -5%;
    padding-bottom: 2rem;
  }
  .css-1fzpoyk {
    width: 100%;
    padding-top: 1rem;
  }

  .extra-data {
    
    font-size: 1.25rem;
    text-align: center;
    color: var(--text-purple);
  }
  .data-title {
    letter-spacing: 0.0375rem;
    font-weight: 400;
    color: var(--text-purple);
    font-size: 2rem;
    text-align: center;
    position: relative;
  }
  .chartsTitle {
    letter-spacing: 0.0375rem;
    font-weight: 400;
    color: var(--text-purple);
    font-size: 2rem;
    text-align: center;
    position: relative;
    margin-bottom: -13rem;
  }
  .chartsTitle2 {
    letter-spacing: 0.0375rem;
    font-weight: 400;
    color: var(--text-purple);
    font-size: 2rem;
    text-align: center;
    margin-bottom: -10rem;
  }
  .hr {
    border: 3px solid var(--hr);
    border-radius: 5px;
    width: 90%;
    margin: auto;
    box-shadow: 0 0 10px 3px var(--hover-purple);
    position: relative;
    margin-top: 7rem;
  }
  .hr2 {
    border: 3px solid var(--hr);
    border-radius: 5px;
    width: 90%;
    margin: auto;
    box-shadow: 0 0 10px 3px var(--hover-purple);
    position: relative;
    margin-top: 6rem;
  }
 
  .move-up {
    margin-top: -45rem;
  }
.hide-me {
  display: none;
}
  .css-1qzevvg img {
    height: 100%;
    margin-top: -6rem;
    text-align: center;
    margin-left: -1rem;
    z-index: 999999;
    position: relative;
}
.adminHeader-text {
  font-weight: 400;
  font-size: 1.5rem;
  text-align: center;
  margin: auto;
  padding-top: 1rem;
  color: var(--text-purple);
  
  margin-top: 6rem;
}
.adminHeader-text2 {
  font-weight: 400;
  font-size: 1.5rem;
  text-align: center;
  margin: auto;
  color: var(--text-purple);
  
  margin-top: 2rem;
}
.adminSubheader-text {
  font-weight: 400;
  font-size: 1rem;
  margin-left: 3rem;
  color: var(--text-purple);
  
  margin-top: 1rem;
}
.adminSubheader-text2 {
  font-weight: 400;
  font-size: 1rem;
  margin-left: 3rem;
  margin-right: 3rem;
  color: var(--text-purple);

  margin-top: 1rem;
}
/* above me */
  .mobile-dropdown {
    margin-left: 0.75rem!important;
  }
  .offcanvas-body {
    width: 100%!important;
    opacity: 0.9;
  }
  .offcanvas {
    width: 40%!important;
    background-color: var(--background)!important;
  }
 
   /* chatbot height start */
.react-chatbot-kit-chat-inner-container {
  background-color: var(--text-purple)!important;
  height: 260px!important;
}
.react-chatbot-kit-chat-message-container {
  height: 220px!important;
}
  /* chatbot height end */
/* dune start */
.GRTDailyTransactionsPosition {
  margin-bottom: 1rem!important;
  margin-left: -15px!important;
 }
 .HoldersGraph {
  background-image: linear-gradient(90deg, #1B0D33, #D8D2E4, #D8D2E4,#D8D2E4, #D8D2E4,#D8D2E4,#D8D2E4,#D8D2E4,#D8D2E4,#D8D2E4,#D8D2E4,#D8D2E4,#D8D2E4,#D8D2E4,#D8D2E4,#D8D2E4,#D8D2E4,#D8D2E4,#1B0D33);
  margin-bottom: -36px!important;
  margin-left: -10px!important;
  padding-left: 20px!important;
  padding-right: 20px!important;
  height: 80px!important;
  width: 100%!important;
}
.HoldersGraphBottom {
  background-image: linear-gradient(90deg, #1B0D33, #D8D2E4,#D8D2E4,#D8D2E4,#D8D2E4,#D8D2E4,#D8D2E4,#D8D2E4,#D8D2E4,#D8D2E4,#D8D2E4,#D8D2E4,#D8D2E4,#D8D2E4,#D8D2E4,#D8D2E4,#D8D2E4,#D8D2E4, #1B0D33);
  padding-left: 20px!important;
  margin-left: -10px!important;
  padding-right: 20px!important;
  height: 80px!important;
  width: 100%!important;
}
  .chatbot-footer-bottom {
    margin-bottom: -80px!important;
   }
  .chatbot-link-logo{
    margin-top: 2rem;
    z-index: 9998;
    position: fixed;
    max-width: 4rem;
    background: white;
    -moz-border-radius: 50px;
    -webkit-border-radius: 50px;
    border-radius: 50px;
    padding: -1rem;
    right: 2%;
    border: 3px var(--secondary-purple) solid;
    box-shadow: 0 0 0 var(--hover-purple);
    animation: pulse 2s infinite;
  }
  .advocate-link-logo{
    margin-top: 4rem;
    z-index: 9998;
    position: fixed;
    max-width: 4rem;
    background: white;
    -moz-border-radius: 50px;
    -webkit-border-radius: 50px;
    border-radius: 50px;
    padding: -1rem;
    right: 2%;
    border: 3px var(--secondary-purple) solid;
    box-shadow: 0 0 0 var(--hover-purple);
    animation: pulse 2s infinite;
  }
  @-webkit-keyframes pulse {
    0% {
      -webkit-box-shadow: 0 0 0 0  var(--text-purple);
    }
    70% {
        -webkit-box-shadow: 0 0 0 15px rgba(244,157,22, 0);
    }
    100% {
        -webkit-box-shadow: 0 0 0 0 rgba(244,157,22, 0);
    }
  }
  @keyframes pulse {
    0% {
      -moz-box-shadow: 0 0 0 0  var(--text-purple);
      box-shadow: 0 0 0 0  var(--text-purple);
    }
    70% {
        -moz-box-shadow: 0 0 0 15px rgba(244,157,22, 0);
        box-shadow: 0 0 0 15px rgba(244,157,22, 0);
    }
    100% {
        -moz-box-shadow: 0 0 0 0 rgba(244,157,22, 0);
        box-shadow: 0 0 0 0 rgba(244,157,22, 0);
    }
  }
  
  .chatbot-link-export{
    margin-top: 7.25rem;
    z-index: 9998;
    position: fixed;
    max-width: 4rem;
    background: white;
    -moz-border-radius: 50px;
    -webkit-border-radius: 50px;
    border-radius: 50px;
    padding: -1rem;
right: 2%;
    border: 3px var(--secondary-purple) solid;
    box-shadow: 0 0 0 var(--hover-purple);
    animation: pulse 2s infinite;
  }
/* Chatbot */
.feedback-text {
  margin-top: 2rem;
}
.feedback-button {
  border: 2px var(--primary-purple) solid!important;
  background-color: var(--text-purple)!important;
  color: var(--primary-purple)!important;
  padding: auto!important;
  margin: auto!important;
}

  .feedback-button:link {
    cursor: pointer;
  }
  .feedback-button:visited {
    cursor: pointer;
  }
  .feedback-button.active,
  .feedback-button:focus {
    cursor: pointer;
  }
  .feedback-button:hover {
    background-color: var(--background)!important;
    color: var(--text-purple)!important;
    transform: scale(1.015);
    cursor: pointer;
  }
  .feedback-button-text {
    font-size: 1.5rem;
  }

.react-chatbot-kit-chat-inner-container {
  background-color: var(--text-purple)!important;
}

.react-chatbot-kit-chat-header {
  font-size: 1.1rem!important;
  color: var(--text-purple)!important;
  background-color: var(--primary-purple)!important;
  padding: 0rem!important;
}

.react-chatbot-kit-chat-input-container {
  background-color:var(--secondary-purple)!important;
  border: 1px solid var(--primary-purple)!important;
}

.react-chatbot-kit-chat-btn-send {
  background-color:var(--primary-purple)!important;
}

.react-chatbot-kit-chat-btn-send-icon:hover {
transform: scale(1.25);
}

.react-chatbot-kit-chat-bot-message-container {
  width: 100%;
}

.react-chatbot-kit-chat-bot-avatar-container {
}

.react-chatbot-kit-chat-bot-avatar-icon {
}

.react-chatbot-kit-chat-bot-avatar-letter {
  display: none;
}
.react-chatbot-kit-chat-container {
  width: 98%!important;
  text-align: center;
  margin: auto;
}

.react-chatbot-kit-chat-bot-avatar-container {
  width: 3.5rem!important;
  height: 3.5rem!important;
  background-color: white!important;
  border: 2px solid var(--primary-purple);
  background-image: url('./GRTDataHubChatBot.png')!important;
  background-repeat: no-repeat!important;
  background-size: 3rem;
  background-position-x: 50%;
  background-position-y: 20%;
}

.react-chatbot-kit-chat-bot-message {
  text-align: left!important;
  width: 100%!important;
  color: var(--text-purple)!important;
  background-color: #0C0B23!important;
  font-size: 1rem!important;
}

.react-chatbot-kit-chat-bot-message-arrow {
  color: #0C0B23!important;
  border-right: 8px solid #0C0B23!important;
}
  .indexer-table-header {
    text-align: center;
    margin-left: 1rem;
  }
  .indexer-table {
    text-align: center;
  }
  .chatbot-data {
    display: inline!important;
  }
  .telegramActiveDelegationCount {
    max-width: 92%;
    margin: auto; 
    text-align: center;
    margin-top: 0.5%;
    }
    .top-telegram {
      margin-top: 0.25rem;
    }
    .chatbot-top {
      margin-top: 5rem;
    }
    .chatbot-page-container {
      background: var(--background);
      color: var(--text-purple);
      width: 100%;
      margin: auto;
      margin-top: 100px;
      padding-left: 10px;
      padding-right: 10px;
      text-align: center;
    }
    .chatbot-text-container {
      background: whitesmoke;
      border-radius: 1rem;
      color: var(--secondary-purple);
      margin: auto!important;
      padding-bottom: 10px;
      padding-top: 10px;
      text-align: center!important;
      padding-left: 15px;
      padding-right: 15px;
    }
    .chatbot-page-title {
      font-size: 1.25rem;
      font-weight: 600;
      color: var(--secondary-purple);
      padding-bottom: 0.25rem;
      padding-left: 5px;
      padding-right: 5px;
    }
    .chatbot-page-subtitle {
      font-size: 1.75rem;
      padding-left: 5px;
      padding-right: 5px;
    }
    .chatbot-page-text {
      font-size: 1.25rem;
      margin-bottom: 1rem;
      margin-top: 1rem;
      padding-left: 10px;
      padding-right: 10px;
    }
    /* chatbot end */
  .dataHub-logos {
    max-width: 6rem !important;
    max-height: 35rem !important;
    position: absolute;
    top: 0;
    margin-top: 1rem;
  }
  .monitor-config {
    display: none!important;
  }
  .hide-telegram {
    display: none;
  }
  body > div:nth-child(1) > button {
    display: none!important;
  }
  .pages {
    margin-bottom: 0.1rem;
    margin-right: 2rem;
}
.indexer-title {
  text-align: center!important;
  font-weight: 400;
  font-size: 1.5rem;
  padding-top: 2rem;
  color: var(--text-purple);
  
  margin-top: 6rem;
}
.indexer-subtitle {
  text-align: center!important;
  font-weight: 400;
  font-size: 1.25rem;
  color: var(--text-purple);
  
  margin-top: 1rem;
  margin-right: 2rem;
  margin-left: 2rem;
}
.indexer {
  z-index: 999;
  position: absolute;
  left: 0%;
  top: 50%;
}

.curator {
  z-index: 999;
  position: absolute;
  text-align: center!important;
  top: 50%;
}

.tokens, .rewards, .uncollected-rewards {
  margin-top: 2rem;
  width: 90%;
  height: 14rem;
  margin-left: 5%;
  box-shadow: #4D1D86 4px 5px 10px 3px;
}

.b-map {
  margin-top: -2rem;
  margin-left: 1rem;
  width: 750px;
  height: 4050px;
  text-align: center!important;
  box-shadow: #4D1D86 4px 5px 10px 3px;
}

.c-map {
  margin-top: 2rem;
  margin-left: 1rem;
  width: 750px;
  height: 1000px;
  text-align: center!important;
  box-shadow: #4D1D86 4px 5px 10px 3px;
}

.d-map {
  margin-top: -3rem;
  margin-left: 3rem;
  width: 720px;
  height: 3800px;
  text-align: center!important;
  box-shadow: #4D1D86 4px 5px 10px 3px;
}

.rewards-per-hour {
  margin-top: 2rem;
  padding-bottom: 2rem;
  width: 90%;
  height: 14rem;
  margin-left: 5%;
  box-shadow: #4D1D86 4px 5px 10px 3px;
}

.indexers {
  padding-left: 1rem;
  padding-left: 1rem;
  color: grey;
  font-size:1.25rem;
  margin-right: 1rem;
}
.indexers:link{
  color: grey;
  cursor: pointer;
  font-size:1.25rem;
  margin-right: 1rem;
}
.indexers:visited {
  color: grey;
  cursor: pointer;
  font-size:1.25rem;
  margin-right: 1rem;
}
.indexers.active,
.indexers:focus {
  border-bottom: 0rem solid var(--hover-purple);
  cursor: pointer;
  font-size:1.25rem;
  margin-right: 1rem;
}
.indexers:hover {
  color: var(--text-purple);
  transform: scale(1.05);
  cursor: pointer;
  font-size:1.25rem;
  margin-right: 1rem;
}
.indexer-link {
  color: white;
}
.indexer-link:link {
  color: white;
  cursor: pointer;
}
.indexer-link:visited {
  color: var(--text-purple);
  cursor: pointer;
}
.indexer-link.active,
.indexer-link:focus {
  border-bottom: 0rem solid var(--text-purple);
  cursor: pointer;
}
.indexer-link:hover {
  color: var(--text-purple);
  transform: scale(1.05);
  cursor: pointer;
}
  .cookie-page-text {
    padding-top: 1rem;
    font-size: 1.25rem;
    margin-bottom: 1rem;
    padding-left: 20px;
    padding-right: 20px;
  }
  .cookies-video {
    padding-top: 2rem;
  }
  .cookie-button {
    z-index: 9999;
    position: absolute;
  }

  body > div:nth-child(1) > button {
    z-index: 10000;
    font-size: 3rem;
    position: absolute;
    padding: auto;
    margin-top: -85px;
    width: 45px;
    height: 45px;
    margin-left: 83%;
    background-color: transparent;
    border: none;
    color: var(--text-purple);
  }

  .extra-data2 {
    font-size: 1.25rem;
    text-align: center;
    color: var(--text-purple);
   
  }
  .data-link {
    color: grey;
  }
  .data-link:link {
    color: grey;
    cursor: pointer;
  }
  .data-link:visited {
    color: grey;
    cursor: pointer;
  }
  .data-link.active,
  .data-link:focus {
    border-bottom: 0rem solid white;
    cursor: pointer;
  }
  .data-link:hover {
    color: white;
    transform: scale(1.05);
    cursor: pointer;
  }
  .question-bank-container {
    background: var(--background);
    color: var(--text-purple);
    width: 100%;
    margin: auto;
    margin-top: 150px;
    margin-bottom: 50px;
    padding-left: 50px;
    padding-right: 50px;
    text-align: center;
  }
  .about-page-container {
    background: var(--background);
    color: var(--text-purple);
    width: 100%;
    margin: auto;
    margin-top: 150px;
    margin-bottom: 50px;
    padding-left: 50px;
    padding-right: 50px;
    text-align: center;
  }
  .QB-text-container {
    background: white;
    border-radius: 1rem;
    padding: 30px;
    color: var(--secondary-purple);
  }
  .text-container {
    background: white;
    border-radius: 1rem;
    padding: 30px;
    color: var(--secondary-purple);
  }
  .about-hr {
    border: 1.5px solid var(--background);
    color: var(--background);
    border-radius: 5px;
    width: 35%;
    margin: auto;
    margin-bottom: 1rem;
    box-shadow: 0 0 5px 1px var(--background);
  }
  .about-page-title {
    font-size: 2rem;
    font-weight: 600;
    color: var(--secondary-purple);
    padding-bottom: 3rem;
  }
  .about-page-subtitle {
    font-size: 1.75rem;
  }
  .about-page-text {
    font-size: 1.25rem;
    margin-bottom: 1rem;
  }
  .graphiql-link {
    color: var(--secondary-purple);
  }
  .graphiql-link:link {
    color: var(--secondary-purple);
    cursor: pointer;
  }
  .graphiql-link:visited {
    color: var(--secondary-purple);
    cursor: pointer;
  }
  .graphiql-link.active,
  .graphiql-link:focus {
    border-bottom: 0rem solid var(--hover-purple);
    cursor: pointer;
  }
  .graphiql-link:hover {
    color: var(--primary-purple);
    transform: scale(1.05);
    cursor: pointer;
  }
  .cookies-policy-container {
    background: white;
    width: 90%;
    padding-bottom: 1rem;
    margin: auto;
    border-radius: 2rem;
    margin-top: 150px;
    margin-bottom: 50px;
  }
  .termly-cookie-preference-button {
   display: none;
  }


  .privacy-policy-container {
    background: white;
    width: 90%;
    padding-bottom: 1rem;
    margin: auto;
    border-radius: 2rem;
    margin-top: 150px;
    margin-bottom: 50px;
  }
  .privacy-policy {
    margin-top: 110px;
    font-weight: 700;
    font-size: 1.1rem;
    text-align: center;
    background-color: var(--background);
    color: var(--secondary-purple);
  }
  .privacy-policy-link,
  .about {
    padding-left: 1rem;
    padding-left: 1rem;
    color: grey;
  }
  .privacy-policy-link:link,
  .about:link {
    color: grey;
    cursor: pointer;
  }
  .privacy-policy-link:visited,
  .about:visited {
    color: grey;
    cursor: pointer;
  }
  .privacy-policy-link.active,
  .privacy-policy-link:focus,
  .about.active,
  .about:focus {
    border-bottom: 0rem solid var(--hover-purple);
    cursor: pointer;
  }
  .privacy-policy-link:hover,
  .about:hover {
    color: var(--text-purple);
    transform: scale(1.05);
    cursor: pointer;
  }
  .subscribeNote {
    margin-left: -15rem;
    width: 30rem;
    font-size: 0.75rem;
    color: white;
  }
  /* loader new start  */
  .loader:empty {
    position: absolute;
    top: calc(50% + 5.5rem);
    left: calc(50% + 2em);
    width: 4em;
    height: 4em;
    border: 0.35em solid white;
    border-left: 0.35em solid #10034B;
    border-radius: 50%;
    animation: load8 0.8s infinite linear;
  }
  .loadingTitle {
    position: absolute;
    top: 17rem;
    left: calc(50% - 3em);
    font-size: 2rem;
    color: #10034B;
    -webkit-box-reflect:below 0px linear-gradient(transparent, #0005);
  }
  .loaderLogo {
    position: absolute;
    top: 4rem;
    left: calc(50% - 8em);
    width: 15em;
    height: 12em;
  }
  @keyframes load8 {
    0% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(360deg);
    }
  }
  /* loader new end  */
  .C {
    font-size: 1rem;
  }
  .logo {
    width: 5.75rem;
    height: 4.75rem;
    margin-left: 2rem;
    margin-top: -0.5rem;
  }
  .data-logo {
    max-width: 1rem !important;
    height: 1rem !important;
    position: absolute;
  }
  .brand-text {
    color: var(--text-purple);
    display: inline;
    margin-left: 0rem;
    letter-spacing: 0.0375rem;
    font-size: 1.25rem;
  }
  .header-text {
    font-weight: 400;
    font-size: 2rem;
    padding-top: 1rem;
    text-align: center;
    margin-top: 105px;
    margin-left: 2rem;
    color: var(--text-purple);

  }
  .header-sub-text {
    font-weight: 400;
    text-align: center!important;
    margin: auto!important;
    font-size: 1.5rem;
    width: 90%;
    color: var(--text-purple);
    display: block;
    text-align: center;
   
  }
  nav a.TGN {
    display: none;
  }
  .navbar-nav > li > a.TGN:link {
    display: none;
  }
  .navbar-nav > li > a.TGN:visited {
    display: none;
  }
  .navbar-nav > li > a.TGN.active,
  .navbar-nav > li > a.TGN:focus {
    display: none;
  }
  .navbar-nav > li > a.TGN:hover {
    display: none;
  }
  nav a.nav-link {
    font-family: Euclid Circular A;
    font-weight: 600;
    font-size: 1.15rem;
    color: var(--text-purple);
  }
  .navbar-nav > li > a {
    border-bottom: 0.01875rem solid var(--transparent);
    padding: 0;
  }
  .navbar-nav > li > a:link {
    color: var(--hover-purple);
  }
  .navbar-nav > li > a:visited {
    color: var(--text-purple);
  }
  .navbar-nav > li > a.active,
  .navbar-nav > li > a:focus {
    border-bottom: 0.01875rem solid var(--hover-purple);
    padding: 0;
    transition: all 0.5s ease-in-out;
  }
  .navbar-nav > li > a:hover {
    border-bottom: 0.01875rem solid var(--hover-purple);
    padding: 0;
    color: var(--hover-purple);
    transform: scale(1.05);
  }
  .line {
    margin-left: 1rem;
  }
  .links {
    text-align: right;
    padding-right: 0;
    margin: auto;
    padding: auto;
  }
  .dropdown {
    margin-left: auto;
    padding: auto;
  }
  .bar {
    margin-bottom: 0.1rem;
    margin-right: 2rem;
  }
  .line {
    margin-bottom: 0.1rem;
    margin-right: 2rem;
  }
  .about {
    margin-left: auto;
    margin-right: 0.8rem;
  }
  .menu-icon {
    color: var(--secondary-purple);
  }
  .main-info {
    background-image: url("TopBackground.svg");
    background-repeat: no-repeat;
    background-size: 100% auto;
    width: 100%;
    height: 100%;
    margin-top: 90px;
  }
  .community-links {
    text-align: center;
    padding-top: 5rem;
    position: absolute;
    z-index: 999;
    left: 35%;
  }
  .telegram-icon,
  .twitter-icon,
  .reddit-icon,
  .youtube-icon {
    display: inline;
    padding: 1rem;
    transition: all 0.5s ease-in-out;
    z-index: 999;
  }
  .icon:hover {
    color: var(--hover-purple);
    transform: scale(1.15);
  }
  .icon-youtube:hover {
    color: var(--text-purple);
    transform: scale(1.1);
  }
  .news-title {
    text-shadow: 2.25px 2.25px 3px var(--hover-purple);
    color: var(--text-purple);
    text-align: center;
    margin-top: 1rem;
    margin-left: -18rem;
    margin-bottom: 1rem;
    font-size: 1.5rem;
  }
  .newsGraph1,
  .newsGraph2,
  .newsGraph3 {
    margin-bottom: 1rem;
  }
  .youtube {
    z-index: 999;
    display: inline;
    position: absolute;
    text-align: center;
    color: var(--text-purple);
  }
  span.anchor {
    display: block;
    visibility: hidden;
    position: relative;
    padding-top: 20%;
  }
  .activeDelegators,
  .holders,
  .curatorCount,
  .subgraphCount {
    font-weight: 400;
    color: var(--text-purple);
    letter-spacing: 0.0375rem;
  }

  .video {
    position: absolute;
    box-shadow: #4D1D86 4px 8px 10px 0;
    text-align: center;
    margin-top: 3rem;
    margin-left: 25%;
  }
  .g-ytsubscribe {
    position: absolute;
    font-family: Euclid Circular A;
    font-weight: 400;
    margin: 0;
    left: 58%;
    top: 84%;
  }

  .newsGraph {
    width: 100%;
    box-shadow: #4D1D86 4px 5px 10px 3px;
  }
  .chartImg {
    width: 100%;
    box-shadow: #4D1D86 6px 6px 10px 3px;
    margin: auto;
  }
  .center {
    margin: auto;
  }
  .line1 {
    margin-top: 5rem;
  }

  .chartImg6 {
    text-align: center;
    padding-top: 1rem;
    width: 100%;
  
  }
  .reveal {
    position: relative;
    transform: translateY(50px);
    opacity: 0;
    transition: 0.75s all ease;
  }
  .reveal.active {
    transform: translateY(0);
    opacity: 1;
  }
  .email {
    color: var(--hover-purple);
    font-weight: 500;
  }
  .footerLink {
    color: var(--hover-purple);
  }
  .footerLink:link {
    color: var(--hover-purple);
    cursor: pointer;
  }
  .footerLink:visited {
    color: var(--hover-purple);
    cursor: pointer;
  }
  .footerLink.active,
  .footerLink:focus {
    border-bottom: 0rem solid var(--hover-purple);
    cursor: pointer;
  }
  .footerLink:hover {
    color: var(--text-purple);
    transform: scale(1.05);
    cursor: pointer;
  }
  .footer {
    margin-bottom: -1rem;
    padding: 1rem;
    font-weight: 700;
    margin-top: 1rem;
    font-size: 1.1rem;
    text-align: center;
    background-color: var(--primary-purple);
    color: var(--secondary-purple);
  }
  .tsparticles {
    display: none;
  }
  .rotate-position,
  .about-rotate-position,
  .phone,
  .about-phone,
  .message,
  .about-message, .home-message {
    display: none;
  }

  .note {
    text-decoration: underline;
  }



 .admin-video {
    position: absolute;
    left: 48%;
    top: 20%;
    width: 10%;
    height: 10%;
  }

 
}
/* *************************************************************************
****************************************************************************
***************************************************************************/
/* *************************************************************************
****************************************************************************
***************************************************************************/
@media screen and (min-width: 780px) and (max-width: 781px) {
  .flashcards-title {
margin-top: 120px!important;
}
  .hide-line {
    color: transparent!important;
    display: block!important;
  }
  .Quiz-button {
    margin-bottom: 2rem;
  }
  .quiz-title {
    margin-top: -60px;
  }
  .quiz-button-position {
    padding-top: 2rem;
    margin: auto;
    text-align: center;
    margin-bottom: 2rem;
  }
  
  .final-exam-question {
      font-size: 1.3rem;
      text-align: center;
      color: #d8d2e4;
      /* remove for mobile */
      margin-top: 0rem;
      /* remove for mobile */
  }
  
  .final-exam-answer-form {
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin-top: 0rem;
    columns: 1!important;
    width: 100%!important;
  }
  
  .form-check-label {
    font-size: 20px;
    text-align: center;
    height: 110%;
  }
  .quiz-question {
    font-size: 1.3rem;
    padding-bottom: 2rem;
  text-align: center;
  width: 80%;
  margin: auto;
    text-align: center;
    color: #d8d2e4;
  }
  .quiz-question-container {
    margin-top: 20px;
    width: 100%;
  }

  .quiz-answer-form {
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin-top: 0rem;
    columns: 1!important;
    width: 100%!important;
  }

  .quiz-position {
    margin-top: -10px;
  }
  .mobile-portrait {
    display: none;
    }
  .monitor-line-carousel {
    display: none;
  }
  
  .monitor-bar-carousel {
   display: none;
  }
  
  .monitor-other-carousel {
    display: none;
  }
  
  .monitor-other-charts-position {
    display: none;
  }
  .mobile-bar-carousel {
    width: 100%;
  height: 500px;
  margin: auto auto;
  padding-top: -1rem;
  padding-bottom: -5rem;
  margin-bottom: -6rem;
  overflow: hidden;
  }
  .mobile-line-carousel {
    width: 100%;
    height: 500px;
    margin: auto auto;
    padding-top: 0rem;
    overflow: hidden;
  }

  .mobile-line-pos {
padding-top: 3rem;
  }
  .more-data-hr {
    margin-top: 1rem!important;
  }
  .more-data-title {
    padding-bottom: 2rem!important;
  }
  .mobile-other-carousel {
    width: 110%;
    height: 500px;
    margin: auto auto;
    margin-left: -2rem;
    padding-top: 1rem!important;
    overflow: hidden;
  }
  .map {
    width: 100%;
    margin-left: 5%;
    box-shadow: #4D1D86 4px 5px 10px 3px;
  }
  .holders-pos {
    margin-left: 1%;
    padding-bottom: 2rem;
  }
  .css-1fzpoyk {
    width: 100%;
    padding-top: 1rem;
  }

  .extra-data {
    margin-left: 2rem;
    margin-right: 2rem;
    font-size: 1.25rem;
    text-align: center;
    color: var(--text-purple);
  }
  .data-title {
    letter-spacing: 0.0375rem;
    font-weight: 400;
    color: var(--text-purple);
    font-size: 2rem;
    text-align: center;
    position: relative;
  }
  .chartsTitle {
    letter-spacing: 0.0375rem;
    font-weight: 400;
    color: var(--text-purple);
    font-size: 2rem;
    text-align: center;
    position: relative;
    margin-bottom: -13rem;
  }
  .chartsTitle2 {
    letter-spacing: 0.0375rem;
    font-weight: 400;
    color: var(--text-purple);
    font-size: 2rem;
    text-align: center;
    margin-bottom: -10rem;
  }
  .hr {
    border: 3px solid var(--hr);
    border-radius: 5px;
    width: 90%;
    margin: auto;
    box-shadow: 0 0 10px 3px var(--hover-purple);
    position: relative;
    margin-top: 7rem;
  }
  .hr2 {
    border: 3px solid var(--hr);
    border-radius: 5px;
    width: 90%;
    margin: auto;
    box-shadow: 0 0 10px 3px var(--hover-purple);
    position: relative;
    margin-top: 6rem;
  }
 
  .move-up {
    margin-top: -45rem;
  }
.hide-me {
  display: none;
}
  .css-1qzevvg img {
    height: 100%;
    margin-top: -6rem;
    text-align: center;
    margin-left: -1rem;
    z-index: 999999;
    position: relative;
}
.adminHeader-text {
  font-weight: 400;
  font-size: 1.5rem;
  text-align: center;
  margin: auto;
  padding-top: 1rem;
  color: var(--text-purple);
  
  margin-top: 6rem;
}
.adminHeader-text2 {
  font-weight: 400;
  font-size: 1.5rem;
  text-align: center;
  margin: auto;
  color: var(--text-purple);
  
  margin-top: 2rem;
}
.adminSubheader-text {
  font-weight: 400;
  font-size: 1rem;
  margin-left: 3rem;
  color: var(--text-purple);
  
  margin-top: 1rem;
}
.adminSubheader-text2 {
  font-weight: 400;
  font-size: 1rem;
  margin-left: 3rem;
  margin-right: 3rem;
  color: var(--text-purple);

  margin-top: 1rem;
}
/* above me */
  .mobile-dropdown {
    margin-left: 0.75rem!important;
  }
  .offcanvas-body {
    width: 100%!important;
    opacity: 0.9;  }
  .offcanvas {
    width: 40%!important;
    background-color: var(--background)!important;
  }

   /* chatbot height start */
.react-chatbot-kit-chat-inner-container {
  background-color: var(--text-purple)!important;
  height: 260px!important;
}
.react-chatbot-kit-chat-message-container {
  height: 220px!important;
}
  /* chatbot height end */
/* dune start */
.GRTDailyTransactionsPosition {
  margin-bottom: 1rem!important;
  margin-left: -15px!important;
 }
 .HoldersGraph {
  background-image: linear-gradient(90deg, #1B0D33, #D8D2E4, #D8D2E4,#D8D2E4, #D8D2E4,#D8D2E4,#D8D2E4,#D8D2E4,#D8D2E4,#D8D2E4,#D8D2E4,#D8D2E4,#D8D2E4,#D8D2E4,#D8D2E4,#D8D2E4,#D8D2E4,#D8D2E4,#1B0D33);
  margin-bottom: -36px!important;
  margin-left: -10px!important;
  padding-left: 20px!important;
  padding-right: 20px!important;
  height: 80px!important;
  width: 100%!important;
}
.HoldersGraphBottom {
  background-image: linear-gradient(90deg, #1B0D33, #D8D2E4,#D8D2E4,#D8D2E4,#D8D2E4,#D8D2E4,#D8D2E4,#D8D2E4,#D8D2E4,#D8D2E4,#D8D2E4,#D8D2E4,#D8D2E4,#D8D2E4,#D8D2E4,#D8D2E4,#D8D2E4,#D8D2E4, #1B0D33);
  padding-left: 20px!important;
  margin-left: -10px!important;
  padding-right: 20px!important;
  height: 80px!important;
  width: 100%!important;
}
  .chatbot-footer-bottom {
    margin-bottom: -80px!important;
   }
  .chatbot-link-logo{
    margin-top: 1rem;
    z-index: 9998;
    position: fixed;
    max-width: 4rem;
    background: white;
    -moz-border-radius: 50px;
    -webkit-border-radius: 50px;
    border-radius: 50px;
    padding: -1rem;
    right: 2%;
    border: 3px var(--secondary-purple) solid;
    box-shadow: 0 0 0 var(--hover-purple);
    animation: pulse 2s infinite;
  }
  .advocate-link-logo{
    margin-top: 3rem;
    z-index: 9998;
    position: fixed;
    max-width: 4rem;
    background: white;
    -moz-border-radius: 50px;
    -webkit-border-radius: 50px;
    border-radius: 50px;
    padding: -1rem;
    right: 2%;
    border: 3px var(--secondary-purple) solid;
    box-shadow: 0 0 0 var(--hover-purple);
    animation: pulse 2s infinite;
  }
  @-webkit-keyframes pulse {
    0% {
      -webkit-box-shadow: 0 0 0 0  var(--text-purple);
    }
    70% {
        -webkit-box-shadow: 0 0 0 15px rgba(244,157,22, 0);
    }
    100% {
        -webkit-box-shadow: 0 0 0 0 rgba(244,157,22, 0);
    }
  }
  @keyframes pulse {
    0% {
      -moz-box-shadow: 0 0 0 0  var(--text-purple);
      box-shadow: 0 0 0 0  var(--text-purple);
    }
    70% {
        -moz-box-shadow: 0 0 0 15px rgba(244,157,22, 0);
        box-shadow: 0 0 0 15px rgba(244,157,22, 0);
    }
    100% {
        -moz-box-shadow: 0 0 0 0 rgba(244,157,22, 0);
        box-shadow: 0 0 0 0 rgba(244,157,22, 0);
    }
  }
  
  .chatbot-link-export{
    margin-top: 4rem;
    z-index: 9998;
    position: fixed;
    max-width: 4rem;
    background: white;
    -moz-border-radius: 50px;
    -webkit-border-radius: 50px;
    border-radius: 50px;
    padding: -1rem;
    right: 2%;
    border: 3px var(--secondary-purple) solid;
    box-shadow: 0 0 0 var(--hover-purple);
    animation: pulse 2s infinite;
  }
/* Chatbot */
.feedback-text {
  margin-top: 2rem;
}
.feedback-button {
  border: 2px var(--primary-purple) solid!important;
  background-color: var(--text-purple)!important;
  color: var(--primary-purple)!important;
  padding: auto!important;
  margin: auto!important;
}

  .feedback-button:link {
    cursor: pointer;
  }
  .feedback-button:visited {
    cursor: pointer;
  }
  .feedback-button.active,
  .feedback-button:focus {
    cursor: pointer;
  }
  .feedback-button:hover {
    background-color: var(--background)!important;
    color: var(--text-purple)!important;
    transform: scale(1.015);
    cursor: pointer;
  }
  .feedback-button-text {
    font-size: 1.5rem;
  }

.react-chatbot-kit-chat-inner-container {
  background-color: var(--text-purple)!important;
}

.react-chatbot-kit-chat-header {
  font-size: 1.1rem!important;
  color: var(--text-purple)!important;
  background-color: var(--primary-purple)!important;
  padding: 0rem!important;
}

.react-chatbot-kit-chat-input-container {
  background-color:var(--secondary-purple)!important;
  border: 1px solid var(--primary-purple)!important;
}

.react-chatbot-kit-chat-btn-send {
  background-color:var(--primary-purple)!important;
}

.react-chatbot-kit-chat-btn-send-icon:hover {
transform: scale(1.25);
}

.react-chatbot-kit-chat-bot-message-container {
  width: 100%;
}

.react-chatbot-kit-chat-bot-avatar-container {
}

.react-chatbot-kit-chat-bot-avatar-icon {
}

.react-chatbot-kit-chat-bot-avatar-letter {
  display: none;
}
.react-chatbot-kit-chat-container {
  width: 98%!important;
  text-align: center;
  margin: auto;
}

.react-chatbot-kit-chat-bot-avatar-container {
  width: 3.5rem!important;
  height: 3.5rem!important;
  background-color: white!important;
  border: 2px solid var(--primary-purple);
  background-image: url('./GRTDataHubChatBot.png')!important;
  background-repeat: no-repeat!important;
  background-size: 3rem;
  background-position-x: 50%;
  background-position-y: 20%;
}

.react-chatbot-kit-chat-bot-message {
  text-align: left!important;
  width: 100%!important;
  color: var(--text-purple)!important;
  background-color: #0C0B23!important;
  font-size: 1rem!important;
}

.react-chatbot-kit-chat-bot-message-arrow {
  color: #0C0B23!important;
  border-right: 8px solid #0C0B23!important;
}
  .indexer-table-header {
    text-align: center;
    margin-left: 1rem;
  }
  .indexer-table {
    text-align: center;
  }
  .chatbot-data {
    display: inline!important;
  }
  .telegramActiveDelegationCount {
    max-width: 92%;
    margin: auto; 
    text-align: center;
    margin-top: 0.5%;
    }
    .top-telegram {
      margin-top: 0.25rem;
    }
    .chatbot-top {
      margin-top: 5rem;
    }
    .chatbot-page-container {
      background: var(--background);
      color: var(--text-purple);
      width: 100%;
      margin: auto;
      margin-top: 100px;
      padding-left: 10px;
      padding-right: 10px;
      text-align: center;
    }
    .chatbot-text-container {
      background: whitesmoke;
      border-radius: 1rem;
      color: var(--secondary-purple);
      margin: auto!important;
      padding-bottom: 10px;
      padding-top: 10px;
      text-align: center!important;
      padding-left: 15px;
      padding-right: 15px;
    }
    .chatbot-page-title {
      font-size: 1.25rem;
      font-weight: 600;
      color: var(--secondary-purple);
      padding-bottom: 0.25rem;
      padding-left: 5px;
      padding-right: 5px;
    }
    .chatbot-page-subtitle {
      font-size: 1.75rem;
      padding-left: 5px;
      padding-right: 5px;
    }
    .chatbot-page-text {
      font-size: 1.25rem;
      margin-bottom: 1rem;
      margin-top: 1rem;
      padding-left: 10px;
      padding-right: 10px;
    }
    /* chatbot end */
  .dataHub-logos {
    max-width: 6rem !important;
    max-height: 35rem !important;
    position: absolute;
    top: 0;
    margin-top: 1rem;
  }
  .monitor-config {
    display: none!important;
  }
  .hide-telegram {
    display: none;
  }
  body > div:nth-child(1) > button {
    display: none!important;
  }
  .pages {
    margin-bottom: 0.1rem;
    margin-right: 2rem;
}
.indexer-title {
  text-align: center!important;
  font-weight: 400;
  font-size: 1.5rem;
  padding-top: 2rem;
  color: var(--text-purple);
  
  margin-top: 6rem;
}
.indexer-subtitle {
  text-align: center!important;
  font-weight: 400;
  font-size: 1.25rem;
  color: var(--text-purple);
  
  margin-top: 1rem;
  margin-right: 2rem;
  margin-left: 2rem;
}
.indexer {
  z-index: 999;
  position: absolute;
  left: 0%;
  top: 50%;
}

.curator {
  z-index: 999;
  position: absolute;
  text-align: center!important;
  top: 50%;
}

.tokens, .rewards, .uncollected-rewards {
  margin-top: 2rem;
  width: 90%;
  height: 14rem;
  margin-left: 5%;
  box-shadow: #4D1D86 4px 5px 10px 3px;
}

.b-map {
  margin-top: -2rem;
  margin-left: 1rem;
  width: 650px;
  height: 4050px;
  text-align: center!important;
  box-shadow: #4D1D86 4px 5px 10px 3px;
}

.c-map {
  margin-top: 2rem;
  margin-left: 1rem;
  width: 650px;
  height: 1000px;
  text-align: center!important;
  box-shadow: #4D1D86 4px 5px 10px 3px;
}

.d-map {
  margin-top: -3rem;
  margin-left: 3rem;
  width: 650px;
  height: 3800px;
  text-align: center!important;
  box-shadow: #4D1D86 4px 5px 10px 3px;
}

.rewards-per-hour {
  margin-top: 2rem;
  padding-bottom: 2rem;
  width: 90%;
  height: 14rem;
  margin-left: 5%;
  box-shadow: #4D1D86 4px 5px 10px 3px;
}

.indexers {
  padding-left: 1rem;
  padding-left: 1rem;
  color: grey;
  font-size:1.25rem;
  margin-right: 1rem;
}
.indexers:link{
  color: grey;
  cursor: pointer;
  font-size:1.25rem;
  margin-right: 1rem;
}
.indexers:visited {
  color: grey;
  cursor: pointer;
  font-size:1.25rem;
  margin-right: 1rem;
}
.indexers.active,
.indexers:focus {
  border-bottom: 0rem solid var(--hover-purple);
  cursor: pointer;
  font-size:1.25rem;
  margin-right: 1rem;
}
.indexers:hover {
  color: var(--text-purple);
  transform: scale(1.05);
  cursor: pointer;
  font-size:1.25rem;
  margin-right: 1rem;
}
.indexer-link {
  color: white;
}
.indexer-link:link {
  color: white;
  cursor: pointer;
}
.indexer-link:visited {
  color: var(--text-purple);
  cursor: pointer;
}
.indexer-link.active,
.indexer-link:focus {
  border-bottom: 0rem solid var(--text-purple);
  cursor: pointer;
}
.indexer-link:hover {
  color: var(--text-purple);
  transform: scale(1.05);
  cursor: pointer;
}
  .cookie-page-text {
    padding-top: 1rem;
    font-size: 1.25rem;
    margin-bottom: 1rem;
    padding-left: 20px;
    padding-right: 20px;
  }
  .cookies-video {
    padding-top: 2rem;
  }
  .cookie-button {
    z-index: 9999;
    position: absolute;
  }

  body > div:nth-child(1) > button {
    z-index: 10000;
    font-size: 3rem;
    position: absolute;
    padding: auto;
    margin-top: -85px;
    width: 45px;
    height: 45px;
    margin-left: 83%;
    background-color: transparent;
    border: none;
    color: var(--text-purple);
  }

  .extra-data2 {
    font-size: 1.25rem;
    text-align: center;
    color: var(--text-purple);
   
  }
  .data-link {
    color: grey;
  }
  .data-link:link {
    color: grey;
    cursor: pointer;
  }
  .data-link:visited {
    color: grey;
    cursor: pointer;
  }
  .data-link.active,
  .data-link:focus {
    border-bottom: 0rem solid white;
    cursor: pointer;
  }
  .data-link:hover {
    color: white;
    transform: scale(1.05);
    cursor: pointer;
  }
  .question-bank-container {
    background: var(--background);
    color: var(--text-purple);
    width: 100%;
    margin: auto;
    margin-top: 150px;
    margin-bottom: 50px;
    padding-left: 50px;
    padding-right: 50px;
    text-align: center;
  }
  .about-page-container {
    background: var(--background);
    color: var(--text-purple);
    width: 100%;
    margin: auto;
    margin-top: 150px;
    margin-bottom: 50px;
    padding-left: 50px;
    padding-right: 50px;
    text-align: center;
  }
  .QB-text-container {
    background: white;
    border-radius: 1rem;
    padding: 30px;
    color: var(--secondary-purple);
  }
  .text-container {
    background: white;
    border-radius: 1rem;
    padding: 30px;
    color: var(--secondary-purple);
  }
  .about-hr {
    border: 1.5px solid var(--background);
    color: var(--background);
    border-radius: 5px;
    width: 35%;
    margin: auto;
    margin-bottom: 1rem;
    box-shadow: 0 0 5px 1px var(--background);
  }
  .about-page-title {
    font-size: 2rem;
    font-weight: 600;
    color: var(--secondary-purple);
    padding-bottom: 3rem;
  }
  .about-page-subtitle {
    font-size: 1.75rem;
  }
  .about-page-text {
    font-size: 1.25rem;
    margin-bottom: 1rem;
  }
  .graphiql-link {
    color: var(--secondary-purple);
  }
  .graphiql-link:link {
    color: var(--secondary-purple);
    cursor: pointer;
  }
  .graphiql-link:visited {
    color: var(--secondary-purple);
    cursor: pointer;
  }
  .graphiql-link.active,
  .graphiql-link:focus {
    border-bottom: 0rem solid var(--hover-purple);
    cursor: pointer;
  }
  .graphiql-link:hover {
    color: var(--primary-purple);
    transform: scale(1.05);
    cursor: pointer;
  }
  .cookies-policy-container {
    background: white;
    width: 90%;
    padding-bottom: 1rem;
    margin: auto;
    border-radius: 2rem;
    margin-top: 150px;
    margin-bottom: 50px;
  }
  .termly-cookie-preference-button {
   display: none;
  }


  .privacy-policy-container {
    background: white;
    width: 90%;
    padding-bottom: 1rem;
    margin: auto;
    border-radius: 2rem;
    margin-top: 150px;
    margin-bottom: 50px;
  }
  .privacy-policy {
    margin-top: 110px;
    font-weight: 700;
    font-size: 1.1rem;
    text-align: center;
    background-color: var(--background);
    color: var(--secondary-purple);
  }
  .privacy-policy-link,
  .about {
    padding-left: 1rem;
    padding-left: 1rem;
    color: grey;
  }
  .privacy-policy-link:link,
  .about:link {
    color: grey;
    cursor: pointer;
  }
  .privacy-policy-link:visited,
  .about:visited {
    color: grey;
    cursor: pointer;
  }
  .privacy-policy-link.active,
  .privacy-policy-link:focus,
  .about.active,
  .about:focus {
    border-bottom: 0rem solid var(--hover-purple);
    cursor: pointer;
  }
  .privacy-policy-link:hover,
  .about:hover {
    color: var(--text-purple);
    transform: scale(1.05);
    cursor: pointer;
  }
  .subscribeNote {
    margin-left: -15rem;
    width: 30rem;
    font-size: 0.75rem;
    color: white;
  }
  /* loader new start  */
  .loader:empty {
    position: absolute;
    top: calc(50% + 5.5rem);
    left: calc(50% + 2em);
    width: 4em;
    height: 4em;
    border: 0.35em solid white;
    border-left: 0.35em solid #10034B;
    border-radius: 50%;
    animation: load8 0.8s infinite linear;
  }
  .loadingTitle {
    position: absolute;
    top: 17rem;
    left: calc(50% - 3em);
    font-size: 2rem;
    color: #10034B;
    -webkit-box-reflect:below 0px linear-gradient(transparent, #0005);
  }
  .loaderLogo {
    position: absolute;
    top: 4rem;
    left: calc(50% - 8em);
    width: 15em;
    height: 12em;
  }
  @keyframes load8 {
    0% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(360deg);
    }
  }
  /* loader new end  */
  .C {
    font-size: 1rem;
  }
  .logo {
    width: 5.75rem;
    height: 4.75rem;
    margin-left: 2rem;
    margin-top: -0.5rem;
  }
  .data-logo {
    max-width: 1rem !important;
    height: 1rem !important;
    position: absolute;
  }
  .brand-text {
    color: var(--text-purple);
    display: inline;
    margin-left: 0rem;
    letter-spacing: 0.0375rem;
    font-size: 1.25rem;
  }
  .header-text {
    font-weight: 400;
    font-size: 2rem;
    padding-top: 1rem;
    text-align: center;
    margin-top: 105px;
    margin-left: 2rem;
    color: var(--text-purple);
   
  }
  .header-sub-text {
    font-weight: 400;
    text-align: center!important;
    margin: auto!important;
    font-size: 1.5rem;
    width: 90%;
    color: var(--text-purple);
    display: block;
    text-align: center;
 
  }
  nav a.TGN {
    display: none;
  }
  .navbar-nav > li > a.TGN:link {
    display: none;
  }
  .navbar-nav > li > a.TGN:visited {
    display: none;
  }
  .navbar-nav > li > a.TGN.active,
  .navbar-nav > li > a.TGN:focus {
    display: none;
  }
  .navbar-nav > li > a.TGN:hover {
    display: none;
  }
  nav a.nav-link {
    font-family: Euclid Circular A;
    font-weight: 600;
    font-size: 1.15rem;
    color: var(--text-purple);
  }
  .navbar-nav > li > a {
    border-bottom: 0.01875rem solid var(--transparent);
    padding: 0;
  }
  .navbar-nav > li > a:link {
    color: var(--hover-purple);
  }
  .navbar-nav > li > a:visited {
    color: var(--text-purple);
  }
  .navbar-nav > li > a.active,
  .navbar-nav > li > a:focus {
    border-bottom: 0.01875rem solid var(--hover-purple);
    padding: 0;
    transition: all 0.5s ease-in-out;
  }
  .navbar-nav > li > a:hover {
    border-bottom: 0.01875rem solid var(--hover-purple);
    padding: 0;
    color: var(--hover-purple);
    transform: scale(1.05);
  }
  .line {
    margin-left: 1rem;
  }
  .links {
    text-align: right;
    padding-right: 0;
    margin: auto;
    padding: auto;
  }
  .dropdown {
    margin-left: auto;
    padding: auto;
  }
  .bar {
    margin-bottom: 0.1rem;
    margin-right: 2rem;
  }
  .line {
    margin-bottom: 0.1rem;
    margin-right: 2rem;
  }
  .about {
    margin-left: auto;
    margin-right: 0.8rem;
  }
  .menu-icon {
    color: var(--secondary-purple);
  }
  .main-info {
    background-image: url("TopBackground.svg");
    background-repeat: no-repeat;
    background-size: 100% auto;
    width: 100%;
    height: 100%;
    margin-top: 90px;
  }
  .community-links {
    text-align: center;
    padding-top: 5rem;
    position: absolute;
    z-index: 999;
    left: 35%;
  }
  .telegram-icon,
  .twitter-icon,
  .reddit-icon,
  .youtube-icon {
    display: inline;
    padding: 1rem;
    transition: all 0.5s ease-in-out;
    z-index: 999;
  }
  .icon:hover {
    color: var(--hover-purple);
    transform: scale(1.15);
  }
  .icon-youtube:hover {
    color: var(--text-purple);
    transform: scale(1.1);
  }
  .news-title {
    text-shadow: 2.25px 2.25px 3px var(--hover-purple);
    color: var(--text-purple);
    text-align: center;
    margin-top: 1rem;
    margin-left: -18rem;
    margin-bottom: 1rem;
    font-size: 1.5rem;
  }
  .newsGraph1,
  .newsGraph2,
  .newsGraph3 {
    margin-bottom: 1rem;
  }
  .youtube {
    z-index: 999;
    display: inline;
    position: absolute;
    text-align: center;
    color: var(--text-purple);
  }
  span.anchor {
    display: block;
    visibility: hidden;
    position: relative;
    padding-top: 20%;
  }
  .activeDelegators,
  .holders,
  .curatorCount,
  .subgraphCount {
    font-weight: 400;
    color: var(--text-purple);
    letter-spacing: 0.0375rem;
  }

  .video {
    position: absolute;
    box-shadow: #4D1D86 4px 8px 10px 0;
    text-align: center;
    margin-top: 3rem;
    margin-left: 25%;
  }
  .g-ytsubscribe {
    position: absolute;
    font-family: Euclid Circular A;
    font-weight: 400;
    margin: 0;
    left: 58%;
    top: 84%;
  }
 
  .newsGraph {
    width: 100%;
    box-shadow: #4D1D86 4px 5px 10px 3px;
  }
  .chartImg {
    width: 100%;
    box-shadow: #4D1D86 6px 6px 10px 3px;
    margin: auto;
  }
  .center {
    margin: auto;
  }
  .line1 {
    margin-top: 5rem;
  }

  .chartImg6 {
    text-align: center;
    padding-top: 1rem;
    width: 100%;
   
  }
  .reveal {
    position: relative;
    transform: translateY(50px);
    opacity: 0;
    transition: 0.75s all ease;
  }
  .reveal.active {
    transform: translateY(0);
    opacity: 1;
  }
  .email {
    color: var(--hover-purple);
    font-weight: 500;
  }
  .footerLink {
    color: var(--hover-purple);
  }
  .footerLink:link {
    color: var(--hover-purple);
    cursor: pointer;
  }
  .footerLink:visited {
    color: var(--hover-purple);
    cursor: pointer;
  }
  .footerLink.active,
  .footerLink:focus {
    border-bottom: 0rem solid var(--hover-purple);
    cursor: pointer;
  }
  .footerLink:hover {
    color: var(--text-purple);
    transform: scale(1.05);
    cursor: pointer;
  }
  .footer {
    margin-bottom: -1rem;
    padding: 1rem;
    font-weight: 700;
    margin-top: 1rem;
    font-size: 1.1rem;
    text-align: center;
    background-color: var(--primary-purple);
    color: var(--secondary-purple);
  }
  .tsparticles {
    display: none;
  }
  .rotate-position,
  .about-rotate-position,
  .phone,
  .about-phone,
  .message,
  .about-message, .home-message {
    display: none;
  }

  .note {
    text-decoration: underline;
  }

 .admin-video {
    position: absolute;
    left: 48%;
    top: 20%;
    width: 10%;
    height: 10%;
  }
}
/* *************************************************************************
****************************************************************************
***************************************************************************/
/* *************************************************************************
****************************************************************************
***************************************************************************/
@media screen and (min-width: 768px) and (max-width: 779px) {
  .flashcards-title {
margin-top: 120px!important;
}
  .hide-line {
    color: transparent!important;
    display: block!important;
  }
  .Quiz-button {
    margin-bottom: 2rem;
  }
  .quiz-title {
    margin-top: -60px;
  }
  .quiz-button-position {
    padding-top: 2rem;
    margin: auto;
    text-align: center;
    margin-bottom: 2rem;
  }
  
  .final-exam-question {
      font-size: 1.3rem;
      text-align: center;
      color: #d8d2e4;
      /* remove for mobile */
      margin-top: 0rem;
      /* remove for mobile */
  }
  
  .final-exam-answer-form {
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin-top: 0rem;
    columns: 1!important;
    width: 100%!important;
  }
  
  .form-check-label {
    font-size: 20px;
    text-align: center;
    height: 110%;
  }
  .quiz-question {
    font-size: 1.3rem;
    padding-bottom: 2rem;
  text-align: center;
  width: 80%;
  margin: auto;
    text-align: center;
    color: #d8d2e4;
  }
  .quiz-question-container {
    margin-top: 20px;
    width: 100%;
  }

  .quiz-answer-form {
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin-top: 0rem;
    columns: 1!important;
    width: 100%!important;
  }

  .quiz-position {
    margin-top: -10px;
  }
  .mobile-portrait {
    display: none;
    }
  .monitor-line-carousel {
    display: none;
  }
  
  .monitor-bar-carousel {
   display: none;
  }
  
  .monitor-other-carousel {
    display: none;
  }
  
  .monitor-other-charts-position {
    display: none;
  }
  .mobile-bar-carousel {
    width: 100%;
  height: 500px;
  margin: auto auto;
  padding-top: -1rem;
  padding-bottom: -5rem;
  margin-bottom: -6rem;
  overflow: hidden;
  }
  .mobile-line-carousel {
    width: 100%;
    height: 500px;
    margin: auto auto;
    padding-top: 0rem;
    overflow: hidden;
  }

  .mobile-line-pos {
padding-top: 3rem;
  }
  .more-data-hr {
    margin-top: 1rem!important;
  }
  .more-data-title {
    padding-bottom: 2rem!important;
  }
  .mobile-other-carousel {
    width: 110%;
    height: 500px;
    margin: auto auto;
    margin-left: -1.5rem;
    padding-top: 1rem!important;
    overflow: hidden;
  }
  .map {
    width: 100%;
    margin-left: 5%;
    box-shadow: #4D1D86 4px 5px 10px 3px;
  }
  .holders-pos {
    margin-left: -5%;
    padding-bottom: 2rem;
  }
  .css-1fzpoyk {
    width: 100%;
    padding-top: 1rem;
  }

  .extra-data {
    
    font-size: 1.25rem;
    text-align: center;
    color: var(--text-purple);
  }
  .data-title {
    letter-spacing: 0.0375rem;
    font-weight: 400;
    color: var(--text-purple);
    font-size: 2rem;
    text-align: center;
    position: relative;
  }
  .chartsTitle {
    letter-spacing: 0.0375rem;
    font-weight: 400;
    color: var(--text-purple);
    font-size: 2rem;
    text-align: center;
    position: relative;
    margin-bottom: -13rem;
  }
  .chartsTitle2 {
    letter-spacing: 0.0375rem;
    font-weight: 400;
    color: var(--text-purple);
    font-size: 2rem;
    text-align: center;
    margin-bottom: -10rem;
  }
  .hr {
    border: 3px solid var(--hr);
    border-radius: 5px;
    width: 90%;
    margin: auto;
    box-shadow: 0 0 10px 3px var(--hover-purple);
    position: relative;
    margin-top: 7rem;
  }
  .hr2 {
    border: 3px solid var(--hr);
    border-radius: 5px;
    width: 90%;
    margin: auto;
    box-shadow: 0 0 10px 3px var(--hover-purple);
    position: relative;
    margin-top: 6rem;
  }
 
  .move-up {
    margin-top: -45rem;
  }
.hide-me {
  display: none;
}
  .css-1qzevvg img {
    height: 100%;
    margin-top: -6rem;
    text-align: center;
    margin-left: -1rem;
    z-index: 999999;
    position: relative;
}
.adminHeader-text {
  font-weight: 400;
  font-size: 1.5rem;
  text-align: center;
  margin: auto;
  padding-top: 1rem;
  color: var(--text-purple);
  
  margin-top: 6rem;
}
.adminHeader-text2 {
  font-weight: 400;
  font-size: 1.5rem;
  text-align: center;
  margin: auto;
  color: var(--text-purple);
  
  margin-top: 2rem;
}
.adminSubheader-text {
  font-weight: 400;
  font-size: 1rem;
  margin-left: 3rem;
  color: var(--text-purple);
  
  margin-top: 1rem;
}
.adminSubheader-text2 {
  font-weight: 400;
  font-size: 1rem;
  margin-left: 3rem;
  margin-right: 3rem;
  color: var(--text-purple);

  margin-top: 1rem;
}
/* above me */
  .mobile-dropdown {
    margin-left: 0.75rem!important;
  }
  .offcanvas-body {
    width: 100%!important;
    opacity: 0.9;
  }
  .offcanvas {
    width: 40%!important;
    background-color: var(--background)!important;
  }

   /* chatbot height start */
.react-chatbot-kit-chat-inner-container {
  background-color: var(--text-purple)!important;
  height: 260px!important;
}
.react-chatbot-kit-chat-message-container {
  height: 220px!important;
}
  /* chatbot height end */
/* dune start */
.GRTDailyTransactionsPosition {
  margin-bottom: 1rem!important;
  margin-left: -15px!important;
 }
 .HoldersGraph {
  background-image: linear-gradient(90deg, #1B0D33, #D8D2E4, #D8D2E4,#D8D2E4, #D8D2E4,#D8D2E4,#D8D2E4,#D8D2E4,#D8D2E4,#D8D2E4,#D8D2E4,#D8D2E4,#D8D2E4,#D8D2E4,#D8D2E4,#D8D2E4,#D8D2E4,#D8D2E4,#1B0D33);
  margin-bottom: -36px!important;
  margin-left: -10px!important;
  padding-left: 20px!important;
  padding-right: 20px!important;
  height: 80px!important;
  width: 100%!important;
}
.HoldersGraphBottom {
  background-image: linear-gradient(90deg, #1B0D33, #D8D2E4,#D8D2E4,#D8D2E4,#D8D2E4,#D8D2E4,#D8D2E4,#D8D2E4,#D8D2E4,#D8D2E4,#D8D2E4,#D8D2E4,#D8D2E4,#D8D2E4,#D8D2E4,#D8D2E4,#D8D2E4,#D8D2E4, #1B0D33);
  padding-left: 20px!important;
  margin-left: -10px!important;
  padding-right: 20px!important;
  height: 80px!important;
  width: 100%!important;
}
  .chatbot-footer-bottom {
    margin-bottom: -80px!important;
   }
  .chatbot-link-logo{
    margin-top: 25rem;
    z-index: 9998;
    position: fixed;
    max-width: 4rem;
    background: white;
    -moz-border-radius: 50px;
    -webkit-border-radius: 50px;
    border-radius: 50px;
    padding: -1rem;
    right: 2%;
    border: 3px var(--secondary-purple) solid;
    box-shadow: 0 0 0 var(--hover-purple);
    animation: pulse 2s infinite;
  }
  .advocate-link-logo{
    margin-top: 33rem;
    z-index: 9998;
    position: fixed;
    max-width: 4rem;
    background: white;
    -moz-border-radius: 50px;
    -webkit-border-radius: 50px;
    border-radius: 50px;
    padding: -1rem;
    right: 2%;
    border: 3px var(--secondary-purple) solid;
    box-shadow: 0 0 0 var(--hover-purple);
    animation: pulse 2s infinite;
  }
  @-webkit-keyframes pulse {
    0% {
      -webkit-box-shadow: 0 0 0 0  var(--text-purple);
    }
    70% {
        -webkit-box-shadow: 0 0 0 15px rgba(244,157,22, 0);
    }
    100% {
        -webkit-box-shadow: 0 0 0 0 rgba(244,157,22, 0);
    }
  }
  @keyframes pulse {
    0% {
      -moz-box-shadow: 0 0 0 0  var(--text-purple);
      box-shadow: 0 0 0 0  var(--text-purple);
    }
    70% {
        -moz-box-shadow: 0 0 0 15px rgba(244,157,22, 0);
        box-shadow: 0 0 0 15px rgba(244,157,22, 0);
    }
    100% {
        -moz-box-shadow: 0 0 0 0 rgba(244,157,22, 0);
        box-shadow: 0 0 0 0 rgba(244,157,22, 0);
    }
  }
  
  .chatbot-link-export{
    margin-top: 45rem;
    z-index: 9998;
    position: fixed;
    max-width: 4rem;
    background: white;
    -moz-border-radius: 50px;
    -webkit-border-radius: 50px;
    border-radius: 50px;
    padding: -1rem;
   right: 2%;
    border: 3px var(--secondary-purple) solid;
    box-shadow: 0 0 0 var(--hover-purple);
    animation: pulse 2s infinite;
  }
/* Chatbot */
.feedback-text {
  margin-top: 2rem;
}
.feedback-button {
  border: 2px var(--primary-purple) solid!important;
  background-color: var(--text-purple)!important;
  color: var(--primary-purple)!important;
  padding: auto!important;
  margin: auto!important;
}

  .feedback-button:link {
    cursor: pointer;
  }
  .feedback-button:visited {
    cursor: pointer;
  }
  .feedback-button.active,
  .feedback-button:focus {
    cursor: pointer;
  }
  .feedback-button:hover {
    background-color: var(--background)!important;
    color: var(--text-purple)!important;
    transform: scale(1.015);
    cursor: pointer;
  }
  .feedback-button-text {
    font-size: 1.5rem;
  }

.react-chatbot-kit-chat-inner-container {
  background-color: var(--text-purple)!important;
}

.react-chatbot-kit-chat-header {
  font-size: 1.1rem!important;
  color: var(--text-purple)!important;
  background-color: var(--primary-purple)!important;
  padding: 0rem!important;
}

.react-chatbot-kit-chat-input-container {
  background-color:var(--secondary-purple)!important;
  border: 1px solid var(--primary-purple)!important;
}

.react-chatbot-kit-chat-btn-send {
  background-color:var(--primary-purple)!important;
}

.react-chatbot-kit-chat-btn-send-icon:hover {
transform: scale(1.25);
}

.react-chatbot-kit-chat-bot-message-container {
  width: 100%;
}

.react-chatbot-kit-chat-bot-avatar-container {
}

.react-chatbot-kit-chat-bot-avatar-icon {
}

.react-chatbot-kit-chat-bot-avatar-letter {
  display: none;
}
.react-chatbot-kit-chat-container {
  width: 98%!important;
  text-align: center;
  margin: auto;
}

.react-chatbot-kit-chat-bot-avatar-container {
  width: 3.5rem!important;
  height: 3.5rem!important;
  background-color: white!important;
  border: 2px solid var(--primary-purple);
  background-image: url('./GRTDataHubChatBot.png')!important;
  background-repeat: no-repeat!important;
  background-size: 3rem;
  background-position-x: 50%;
  background-position-y: 20%;
}

.react-chatbot-kit-chat-bot-message {
  text-align: left!important;
  width: 100%!important;
  color: var(--text-purple)!important;
  background-color: #0C0B23!important;
  font-size: 1rem!important;
}

.react-chatbot-kit-chat-bot-message-arrow {
  color: #0C0B23!important;
  border-right: 8px solid #0C0B23!important;
}
  .indexer-table-header {
    text-align: center;
    margin-left: 1rem;
  }
  .indexer-table {
    text-align: center;
  }
  .chatbot-data {
    display: inline!important;
  }
  .telegramActiveDelegationCount {
    max-width: 92%;
    margin: auto; 
    text-align: center;
    margin-top: 0.5%;
    }
    .top-telegram {
      margin-top: 0.25rem;
    }
    .chatbot-top {
      margin-top: 5rem;
    }
    .chatbot-page-container {
      background: var(--background);
      color: var(--text-purple);
      width: 100%;
      margin: auto;
      margin-top: 100px;
      padding-left: 10px;
      padding-right: 10px;
      text-align: center;
    }
    .chatbot-text-container {
      background: whitesmoke;
      border-radius: 1rem;
      color: var(--secondary-purple);
      margin: auto!important;
      padding-bottom: 10px;
      padding-top: 10px;
      text-align: center!important;
      padding-left: 15px;
      padding-right: 15px;
    }
    .chatbot-page-title {
      font-size: 1.25rem;
      font-weight: 600;
      color: var(--secondary-purple);
      padding-bottom: 0.25rem;
      padding-left: 5px;
      padding-right: 5px;
    }
    .chatbot-page-subtitle {
      font-size: 1.75rem;
      padding-left: 5px;
      padding-right: 5px;
    }
    .chatbot-page-text {
      font-size: 1.25rem;
      margin-bottom: 1rem;
      margin-top: 1rem;
      padding-left: 10px;
      padding-right: 10px;
    }
    /* chatbot end */
  .dataHub-logos {
    max-width: 6rem !important;
    max-height: 35rem !important;
    position: absolute;
    top: 0;
    margin-top: 1rem;
  }
  .monitor-config {
    display: none!important;
  }
  .hide-telegram {
    display: none;
  }
  body > div:nth-child(1) > button {
    display: none!important;
  }
  .pages {
    margin-bottom: 0.1rem;
    margin-right: 2rem;
}
.indexer-title {
  text-align: center!important;
  font-weight: 400;
  font-size: 1.5rem;
  padding-top: 2rem;
  color: var(--text-purple);
  
  margin-top: 6rem;
}
.indexer-subtitle {
  text-align: center!important;
  font-weight: 400;
  font-size: 1.25rem;
  color: var(--text-purple);
  
  margin-top: 1rem;
  margin-right: 2rem;
  margin-left: 2rem;
}
.indexer {
  z-index: 999;
  position: absolute;
  left: 0%;
  top: 50%;
}

.curator {
  z-index: 999;
  position: absolute;
  text-align: center!important;
  top: 50%;
}

.tokens, .rewards, .uncollected-rewards {
  margin-top: 2rem;
  width: 90%;
  height: 14rem;
  margin-left: 5%;
  box-shadow: #4D1D86 4px 5px 10px 3px;
}

.c-map {
  margin-top: 2rem;
  width: 100%;
  height: 30rem;
  margin-left: 5%;
  box-shadow: #4D1D86 4px 5px 10px 3px;
}

.d-map {
  margin-top: -3rem;
  margin-left: 3rem;
  width: 100%;
  height: 3800px;
  text-align: center!important;
  box-shadow: #4D1D86 4px 5px 10px 3px;
}

.rewards-per-hour {
  margin-top: 2rem;
  padding-bottom: 2rem;
  width: 90%;
  height: 14rem;
  margin-left: 5%;
  box-shadow: #4D1D86 4px 5px 10px 3px;
}

.indexers {
  padding-left: 1rem;
  padding-left: 1rem;
  color: grey;
  font-size:1.25rem;
  margin-right: 1rem;
}
.indexers:link{
  color: grey;
  cursor: pointer;
  font-size:1.25rem;
  margin-right: 1rem;
}
.indexers:visited {
  color: grey;
  cursor: pointer;
  font-size:1.25rem;
  margin-right: 1rem;
}
.indexers.active,
.indexers:focus {
  border-bottom: 0rem solid var(--hover-purple);
  cursor: pointer;
  font-size:1.25rem;
  margin-right: 1rem;
}
.indexers:hover {
  color: var(--text-purple);
  transform: scale(1.05);
  cursor: pointer;
  font-size:1.25rem;
  margin-right: 1rem;
}
.indexer-link {
  color: white;
}
.indexer-link:link {
  color: white;
  cursor: pointer;
}
.indexer-link:visited {
  color: var(--text-purple);
  cursor: pointer;
}
.indexer-link.active,
.indexer-link:focus {
  border-bottom: 0rem solid var(--text-purple);
  cursor: pointer;
}
.indexer-link:hover {
  color: var(--text-purple);
  transform: scale(1.05);
  cursor: pointer;
}
  .cookie-page-text {
    padding-top: 1rem;
    font-size: 1.25rem;
    margin-bottom: 1rem;
    padding-left: 20px;
    padding-right: 20px;
  }
  .cookies-video {
    padding-top: 2rem;
  }
  .cookie-button {
    z-index: 9999;
    position: absolute;
  }

  body > div:nth-child(1) > button {
    z-index: 10000;
    font-size: 3rem;
    position: absolute;
    padding: auto;
    margin-top: -85px;
    width: 45px;
    height: 45px;
    margin-left: 83%;
    background-color: transparent;
    border: none;
    color: var(--text-purple);
  }

  .extra-data2 {
    font-size: 1.25rem;
    text-align: center;
    color: var(--text-purple);
   
  }
  .data-link {
    color: grey;
  }
  .data-link:link {
    color: grey;
    cursor: pointer;
  }
  .data-link:visited {
    color: grey;
    cursor: pointer;
  }
  .data-link.active,
  .data-link:focus {
    border-bottom: 0rem solid white;
    cursor: pointer;
  }
  .data-link:hover {
    color: white;
    transform: scale(1.05);
    cursor: pointer;
  }
  .question-bank-container {
    background: var(--background);
    color: var(--text-purple);
    width: 100%;
    margin: auto;
    margin-top: 150px;
    margin-bottom: 50px;
    padding-left: 50px;
    padding-right: 50px;
    text-align: center;
  }
  .about-page-container {
    background: var(--background);
    color: var(--text-purple);
    width: 100%;
    margin: auto;
    margin-top: 150px;
    margin-bottom: 50px;
    padding-left: 50px;
    padding-right: 50px;
    text-align: center;
  }
  .QB-text-container {
    background: white;
    border-radius: 1rem;
    padding: 30px;
    color: var(--secondary-purple);
  }
  .text-container {
    background: white;
    border-radius: 1rem;
    padding: 30px;
    color: var(--secondary-purple);
  }
  .about-hr {
    border: 1.5px solid var(--background);
    color: var(--background);
    border-radius: 5px;
    width: 35%;
    margin: auto;
    margin-bottom: 1rem;
    box-shadow: 0 0 5px 1px var(--background);
  }
  .about-page-title {
    font-size: 2rem;
    font-weight: 600;
    color: var(--secondary-purple);
    padding-bottom: 3rem;
  }
  .about-page-subtitle {
    font-size: 1.75rem;
  }
  .about-page-text {
    font-size: 1.25rem;
    margin-bottom: 1rem;
  }
  .graphiql-link {
    color: var(--secondary-purple);
  }
  .graphiql-link:link {
    color: var(--secondary-purple);
    cursor: pointer;
  }
  .graphiql-link:visited {
    color: var(--secondary-purple);
    cursor: pointer;
  }
  .graphiql-link.active,
  .graphiql-link:focus {
    border-bottom: 0rem solid var(--hover-purple);
    cursor: pointer;
  }
  .graphiql-link:hover {
    color: var(--primary-purple);
    transform: scale(1.05);
    cursor: pointer;
  }
  .cookies-policy-container {
    background: white;
    width: 90%;
    padding-bottom: 1rem;
    margin: auto;
    border-radius: 2rem;
    margin-top: 150px;
    margin-bottom: 50px;
  }
  .termly-cookie-preference-button {
   display: none;
  }


  .privacy-policy-container {
    background: white;
    width: 90%;
    padding-bottom: 1rem;
    margin: auto;
    border-radius: 2rem;
    margin-top: 150px;
    margin-bottom: 50px;
  }
  .privacy-policy {
    margin-top: 110px;
    font-weight: 700;
    font-size: 1.1rem;
    text-align: center;
    background-color: var(--background);
    color: var(--secondary-purple);
  }
  .privacy-policy-link,
  .about {
    padding-left: 1rem;
    padding-left: 1rem;
    color: grey;
  }
  .privacy-policy-link:link,
  .about:link {
    color: grey;
    cursor: pointer;
  }
  .privacy-policy-link:visited,
  .about:visited {
    color: grey;
    cursor: pointer;
  }
  .privacy-policy-link.active,
  .privacy-policy-link:focus,
  .about.active,
  .about:focus {
    border-bottom: 0rem solid var(--hover-purple);
    cursor: pointer;
  }
  .privacy-policy-link:hover,
  .about:hover {
    color: var(--text-purple);
    transform: scale(1.05);
    cursor: pointer;
  }
  .subscribeNote {
    margin-left: -15rem;
    width: 30rem;
    font-size: 0.75rem;
    color: white;
  }
  /* loader new start  */
  .loader:empty {
    position: absolute;
    top: calc(50% + 5.5rem);
    left: calc(50% + 2em);
    width: 4em;
    height: 4em;
    border: 0.35em solid white;
    border-left: 0.35em solid #10034B;
    border-radius: 50%;
    animation: load8 0.8s infinite linear;
  }
  .loadingTitle {
    position: absolute;
    top: 17rem;
    left: calc(50% - 3em);
    font-size: 2rem;
    color: #10034B;
    -webkit-box-reflect:below 0px linear-gradient(transparent, #0005);
  }
  .loaderLogo {
    position: absolute;
    top: 4rem;
    left: calc(50% - 8em);
    width: 15em;
    height: 12em;
  }
  @keyframes load8 {
    0% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(360deg);
    }
  }
  /* loader new end  */
  .C {
    font-size: 1rem;
  }
  .logo {
    width: 5.75rem;
    height: 4.75rem;
    margin-left: 2rem;
    margin-top: -0.5rem;
  }
  .data-logo {
    max-width: 1rem !important;
    height: 1rem !important;
    position: absolute;
  }
  .brand-text {
    color: var(--text-purple);
    display: inline;
    margin-left: 0rem;
    letter-spacing: 0.0375rem;
    font-size: 1.25rem;
  }
  .header-text {
    font-weight: 400;
    font-size: 2rem;
    padding-top: 1rem;
    text-align: center;
    margin-top: 105px;
    margin-left: 2rem;
    color: var(--text-purple);
 
  }
  .header-sub-text {
    font-weight: 400;
    text-align: center!important;
    margin: auto!important;
    font-size: 1.5rem;
    width: 90%;
    color: var(--text-purple);
    display: block;
    text-align: center;
   
  }
  nav a.TGN {
    display: none;
  }
  .navbar-nav > li > a.TGN:link {
    display: none;
  }
  .navbar-nav > li > a.TGN:visited {
    display: none;
  }
  .navbar-nav > li > a.TGN.active,
  .navbar-nav > li > a.TGN:focus {
    display: none;
  }
  .navbar-nav > li > a.TGN:hover {
    display: none;
  }
  nav a.nav-link {
    font-family: Euclid Circular A;
    font-weight: 600;
    font-size: 1.15rem;
    color: var(--text-purple);
  }
  .navbar-nav > li > a {
    border-bottom: 0.01875rem solid var(--transparent);
    padding: 0;
  }
  .navbar-nav > li > a:link {
    color: var(--hover-purple);
  }
  .navbar-nav > li > a:visited {
    color: var(--text-purple);
  }
  .navbar-nav > li > a.active,
  .navbar-nav > li > a:focus {
    border-bottom: 0.01875rem solid var(--hover-purple);
    padding: 0;
    transition: all 0.5s ease-in-out;
  }
  .navbar-nav > li > a:hover {
    border-bottom: 0.01875rem solid var(--hover-purple);
    padding: 0;
    color: var(--hover-purple);
    transform: scale(1.05);
  }
  .line {
    margin-left: 1rem;
  }
  .links {
    text-align: right;
    padding-right: 0;
    margin: auto;
    padding: auto;
  }
  .dropdown {
    margin-left: auto;
    padding: auto;
  }
  .bar {
    margin-bottom: 0.1rem;
    margin-right: 2rem;
  }
  .line {
    margin-bottom: 0.1rem;
    margin-right: 2rem;
  }
  .about {
    margin-left: auto;
    margin-right: 0.8rem;
  }
  .menu-icon {
    color: var(--secondary-purple);
  }
  .main-info {
    background-image: url("TopBackground.svg");
    background-repeat: no-repeat;
    background-size: 100% auto;
    width: 100%;
    height: 100%;
    margin-top: 90px;
  }
  .community-links {
    text-align: center;
    padding-top: 5rem;
    position: absolute;
    z-index: 999;
    left: 35%;
  }
  .telegram-icon,
  .twitter-icon,
  .reddit-icon,
  .youtube-icon {
    display: inline;
    padding: 1rem;
    transition: all 0.5s ease-in-out;
    z-index: 999;
  }
  .icon:hover {
    color: var(--hover-purple);
    transform: scale(1.15);
  }
  .icon-youtube:hover {
    color: var(--text-purple);
    transform: scale(1.1);
  }
  .news-title {
    text-shadow: 2.25px 2.25px 3px var(--hover-purple);
    color: var(--text-purple);
    text-align: center;
    margin-top: 1rem;
    margin-left: -18rem;
    margin-bottom: 1rem;
    font-size: 1.5rem;
  }
  .newsGraph1,
  .newsGraph2,
  .newsGraph3 {
    margin-bottom: 1rem;
  }
  .youtube {
    z-index: 999;
    display: inline;
    position: absolute;
    text-align: center;
    color: var(--text-purple);
  }
  span.anchor {
    display: block;
    visibility: hidden;
    position: relative;
    padding-top: 20%;
  }
  .activeDelegators,
  .holders,
  .curatorCount,
  .subgraphCount {
    font-weight: 400;
    color: var(--text-purple);
    letter-spacing: 0.0375rem;
  }

  .video {
    position: absolute;
    box-shadow: #4D1D86 4px 8px 10px 0;
    text-align: center;
    margin-top: 3rem;
    margin-left: 25%;
  }
  .g-ytsubscribe {
    position: absolute;
    font-family: Euclid Circular A;
    font-weight: 400;
    margin: 0;
    left: 58%;
    top: 84%;
  }

  .newsGraph {
    width: 100%;
    box-shadow: #4D1D86 4px 5px 10px 3px;
  }
  .chartImg {
    width: 100%;
    box-shadow: #4D1D86 6px 6px 10px 3px;
    margin: auto;
  }
  .center {
    margin: auto;
  }
  .line1 {
    margin-top: 5rem;
  }
  .chartImg6 {
    text-align: center;
    padding-top: 1rem;
    width: 100%;
  
  }
  .reveal {
    position: relative;
    transform: translateY(50px);
    opacity: 0;
    transition: 0.75s all ease;
  }
  .reveal.active {
    transform: translateY(0);
    opacity: 1;
  }
  .email {
    color: var(--hover-purple);
    font-weight: 500;
  }
  .footerLink {
    color: var(--hover-purple);
  }
  .footerLink:link {
    color: var(--hover-purple);
    cursor: pointer;
  }
  .footerLink:visited {
    color: var(--hover-purple);
    cursor: pointer;
  }
  .footerLink.active,
  .footerLink:focus {
    border-bottom: 0rem solid var(--hover-purple);
    cursor: pointer;
  }
  .footerLink:hover {
    color: var(--text-purple);
    transform: scale(1.05);
    cursor: pointer;
  }
  .footer {
    margin-bottom: -1rem;
    padding: 1rem;
    font-weight: 700;
    margin-top: 1rem;
    font-size: 1.1rem;
    text-align: center;
    background-color: var(--primary-purple);
    color: var(--secondary-purple);
  }
  .tsparticles {
    display: none;
  }
  .rotate-position,
  .about-rotate-position,
  .phone,
  .about-phone,
  .message,
  .about-message, .home-message {
    display: none;
  }

  .note {
    text-decoration: underline;
  }

  .adminHr2 {
    border: 3px solid var(--hr);
    border-radius: 5px;
    width: 70%;
    margin: auto;
    box-shadow: 0 0 10px 3px var(--hover-purple);
    margin-top: 1rem;
    margin-left: 1.9rem;
  }

 .admin-video {
    position: absolute;
    left: 48%;
    top: 20%;
    width: 10%;
    height: 10%;
  }
}
/* *************************************************************************
****************************************************************************
***************************************************************************/
/* *************************************************************************
****************************************************************************
***************************************************************************/
@media screen and (min-width: 650px) and (max-width: 767px) {
  .flashcards-title {
margin-top: 120px!important;
}
  .hide-line {
    color: transparent!important;
    display: block!important;
  }
  .Quiz-button {
    margin-bottom: 2rem;
  }
  .quiz-title {
    margin-top: -60px;
  }
  .quiz-button-position {
    padding-top: 2rem;
    margin: auto;
    text-align: center;
    margin-bottom: 2rem;
  }
  
  .final-exam-question {
      font-size: 1.3rem;
      text-align: center;
      color: #d8d2e4;
      /* remove for mobile */
      margin-top: 0rem;
      /* remove for mobile */
  }
  
  .final-exam-answer-form {
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin-top: 0rem;
    columns: 1!important;
    width: 100%!important;
  }
  
  .form-check-label {
    font-size: 20px;
    text-align: center;
    height: 110%;
  }
  .quiz-question {
    font-size: 1.3rem;
    padding-bottom: 2rem;
  text-align: center;
  width: 80%;
  margin: auto;
    text-align: center;
    color: #d8d2e4;
  }
  .quiz-question-container {
    margin-top: 20px;
    width: 100%;
  }

  .quiz-answer-form {
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin-top: 0rem;
    columns: 1!important;
    width: 100%!important;
  }

  .quiz-position {
    margin-top: -10px;
  }
  .mobile-landscape {
    display: none;
    }
  .monitor-line-carousel {
    display: none;
  }
  
  .monitor-bar-carousel {
   display: none;
  }
  
  .monitor-other-carousel {
    display: none;
  }
  
  .monitor-other-charts-position {
    display: none;
  }
  .mobile-bar-carousel {
    width: 100%;
  height: 500px;
  margin: auto auto;
  padding-top: -1rem;
  padding-bottom: -5rem;
  margin-bottom: -6rem;
  overflow: hidden;
  }
  .mobile-line-carousel {
    width: 100%;
    height: 500px;
    margin: auto auto;
    padding-top: 0rem;
    overflow: hidden;
  }

  .mobile-line-pos {
padding-top: 3rem;
  }
  .more-data-hr {
    margin-top: 1rem!important;
  }
  .more-data-title {
    padding-bottom: 2rem!important;
  }
  .mobile-other-carousel {
    width: 110%;
    height: 500px;
    margin: auto auto;
    margin-left: -1.5rem;
    padding-top: 1rem!important;
    overflow: hidden;
  }
  .map {
    width: 100%;
    margin-left: 5%;
    box-shadow: #4D1D86 4px 5px 10px 3px;
  }
  .holders-pos {
    margin-left: -5%;
    padding-bottom: 2rem;
  }
  .css-1fzpoyk {
    width: 100%;
    padding-top: 1rem;
  }

  .extra-data {
    
    font-size: 1.25rem;
    text-align: center;
    color: var(--text-purple);
  }
  .data-title {
    letter-spacing: 0.0375rem;
    font-weight: 400;
    color: var(--text-purple);
    font-size: 2rem;
    text-align: center;
    position: relative;
  }
  .chartsTitle {
    letter-spacing: 0.0375rem;
    font-weight: 400;
    color: var(--text-purple);
    font-size: 2rem;
    text-align: center;
    position: relative;
    margin-bottom: -13rem;
  }
  .chartsTitle2 {
    letter-spacing: 0.0375rem;
    font-weight: 400;
    color: var(--text-purple);
    font-size: 2rem;
    text-align: center;
    margin-bottom: -10rem;
  }
  .hr {
    border: 3px solid var(--hr);
    border-radius: 5px;
    width: 90%;
    margin: auto;
    box-shadow: 0 0 10px 3px var(--hover-purple);
    position: relative;
    margin-top: 7rem;
  }
  .hr2 {
    border: 3px solid var(--hr);
    border-radius: 5px;
    width: 90%;
    margin: auto;
    box-shadow: 0 0 10px 3px var(--hover-purple);
    position: relative;
    margin-top: 6rem;
  }
 
  .move-up {
    margin-top: -45rem;
  }
.hide-me {
  display: none;
}
  .css-1qzevvg img {
    height: 100%;
    margin-top: -6rem;
    text-align: center;
    margin-left: -1rem;
    z-index: 999999;
    position: relative;
}
.adminHeader-text {
  font-weight: 400;
  font-size: 1.5rem;
  text-align: center;
  margin: auto;
  padding-top: 1rem;
  color: var(--text-purple);
  
  margin-top: 6rem;
}
.adminHeader-text2 {
  font-weight: 400;
  font-size: 1.5rem;
  text-align: center;
  margin: auto;
  color: var(--text-purple);
  
  margin-top: 2rem;
}
.adminSubheader-text {
  font-weight: 400;
  font-size: 1rem;
  margin-left: 3rem;
  color: var(--text-purple);
  
  margin-top: 1rem;
}
.adminSubheader-text2 {
  font-weight: 400;
  font-size: 1rem;
  margin-left: 3rem;
  margin-right: 3rem;
  color: var(--text-purple);

  margin-top: 1rem;
}
/* above me */
 
  /* dune start */
.HoldersGraph {
  background-image: linear-gradient(90deg, #1B0D33, #D8D2E4, #D8D2E4,#D8D2E4, #D8D2E4,#D8D2E4,#D8D2E4,#D8D2E4,#D8D2E4,#D8D2E4,#D8D2E4,#D8D2E4,#D8D2E4,#D8D2E4,#D8D2E4,#D8D2E4,#D8D2E4,#D8D2E4,#1B0D33);
  margin-bottom: -36px!important;
  padding-left: 20px!important;
  padding-right: 20px!important;
}
.HoldersGraphBottom {
  background-image: linear-gradient(90deg, #1B0D33, #D8D2E4,#D8D2E4,#D8D2E4,#D8D2E4,#D8D2E4,#D8D2E4,#D8D2E4,#D8D2E4,#D8D2E4,#D8D2E4,#D8D2E4,#D8D2E4,#D8D2E4,#D8D2E4,#D8D2E4,#D8D2E4,#D8D2E4, #1B0D33);
  padding-left: 20px!important;
  padding-right: 20px!important;
}
/* dune end */
  .chatbot-footer-bottom {
    margin-bottom: -80px!important;
   }
  .chatbot-link-logo{
    margin-top: 10rem;
    z-index: 9998;
    position: fixed;
    max-width: 4rem;
    background: white;
    -moz-border-radius: 50px;
    -webkit-border-radius: 50px;
    border-radius: 50px;
    padding: -1rem;
 right: 2%;
    border: 3px var(--secondary-purple) solid;
    box-shadow: 0 0 0 var(--hover-purple);
    animation: pulse 2s infinite;
  }
  .advocate-link-logo{
    margin-top: 15rem;
    z-index: 9998;
    position: fixed;
    max-width: 4rem;
    background: white;
    -moz-border-radius: 50px;
    -webkit-border-radius: 50px;
    border-radius: 50px;
    padding: -1rem;
 right: 2%;
    border: 3px var(--secondary-purple) solid;
    box-shadow: 0 0 0 var(--hover-purple);
    animation: pulse 2s infinite;
  }
  @-webkit-keyframes pulse {
    0% {
      -webkit-box-shadow: 0 0 0 0  var(--text-purple);
    }
    70% {
        -webkit-box-shadow: 0 0 0 15px rgba(244,157,22, 0);
    }
    100% {
        -webkit-box-shadow: 0 0 0 0 rgba(244,157,22, 0);
    }
  }
  @keyframes pulse {
    0% {
      -moz-box-shadow: 0 0 0 0  var(--text-purple);
      box-shadow: 0 0 0 0  var(--text-purple);
    }
    70% {
        -moz-box-shadow: 0 0 0 15px rgba(244,157,22, 0);
        box-shadow: 0 0 0 15px rgba(244,157,22, 0);
    }
    100% {
        -moz-box-shadow: 0 0 0 0 rgba(244,157,22, 0);
        box-shadow: 0 0 0 0 rgba(244,157,22, 0);
    }
  }
  
  .chatbot-link-export{
    margin-top: 21rem;
    z-index: 9998;
    position: fixed;
    max-width: 4rem;
    background: white;
    -moz-border-radius: 50px;
    -webkit-border-radius: 50px;
    border-radius: 50px;
    padding: -1rem;
    right:2%;
    border: 3px var(--secondary-purple) solid;
    box-shadow: 0 0 0 var(--hover-purple);
    animation: pulse 2s infinite;
  }
  /* Chatbot */
.feedback-text {
  margin-top: 2rem;
}
.feedback-button {
  border: 2px var(--primary-purple) solid!important;
  background-color: var(--text-purple)!important;
  color: var(--primary-purple)!important;
  padding: auto!important;
  margin: auto!important;
}

  .feedback-button:link {
    cursor: pointer;
  }
  .feedback-button:visited {
    cursor: pointer;
  }
  .feedback-button.active,
  .feedback-button:focus {
    cursor: pointer;
  }
  .feedback-button:hover {
    background-color: var(--background)!important;
    color: var(--text-purple)!important;
    transform: scale(1.015);
    cursor: pointer;
  }
  .feedback-button-text {
    font-size: 1.5rem;
  }

.react-chatbot-kit-chat-inner-container {
  background-color: var(--text-purple)!important;
}

.react-chatbot-kit-chat-header {
  font-size: 1.1rem!important;
  color: var(--text-purple)!important;
  background-color: var(--primary-purple)!important;
  padding: 0rem!important;
}

.react-chatbot-kit-chat-input-container {
  background-color:var(--secondary-purple)!important;
  border: 1px solid var(--primary-purple)!important;
}

.react-chatbot-kit-chat-btn-send {
  background-color:var(--primary-purple)!important;
}

.react-chatbot-kit-chat-btn-send-icon:hover {
transform: scale(1.25);
}

.react-chatbot-kit-chat-bot-message-container {
  width: 100%;
}

.react-chatbot-kit-chat-bot-avatar-container {
}

.react-chatbot-kit-chat-bot-avatar-icon {
}

.react-chatbot-kit-chat-bot-avatar-letter {
  display: none;
}
.react-chatbot-kit-chat-container {
  width: 98%!important;
  text-align: center;
  margin: auto;
}

.react-chatbot-kit-chat-bot-avatar-container {
  width: 3.5rem!important;
  height: 3.5rem!important;
  background-color: white!important;
  border: 2px solid var(--primary-purple);
  background-image: url('./GRTDataHubChatBot.png')!important;
  background-repeat: no-repeat!important;
  background-size: 3rem;
  background-position-x: 50%;
  background-position-y: 20%;
}

.react-chatbot-kit-chat-bot-message {
  text-align: left!important;
  width: 100%!important;
  color: var(--text-purple)!important;
  background-color: #0C0B23!important;
  font-size: 1rem!important;
}

.react-chatbot-kit-chat-bot-message-arrow {
  color: #0C0B23!important;
  border-right: 8px solid #0C0B23!important;
}
  .indexer-table-header {
    text-align: center;
    margin-left: 1rem;
  }
  .indexer-table {
    text-align: center;
  }
  .chatbot-data {
    display: inline!important;
  }
  .telegramActiveDelegationCount {
    max-width: 92%;
    margin: auto; 
    text-align: center;
    margin-top: 0.5%;
    }
    .top-telegram {
      margin-top: 0.25rem;
    }
    .chatbot-top {
      margin-top: 5rem;
    }
    .chatbot-page-container {
      background: var(--background);
      color: var(--text-purple);
      width: 100%;
      margin: auto;
      margin-top: 100px;
      padding-left: 10px;
      padding-right: 10px;
      text-align: center;
    }
    .chatbot-text-container {
      background: whitesmoke;
      border-radius: 1rem;
      color: var(--secondary-purple);
      margin: auto!important;
      padding-bottom: 10px;
      padding-top: 10px;
      text-align: center!important;
    }
    .chatbot-page-title {
      font-size: 1.25rem;
      font-weight: 600;
      color: var(--secondary-purple);
      padding-bottom: 0.25rem;
      padding-left: 5px;
      padding-right: 5px;
    }
    .chatbot-page-subtitle {
      font-size: 1.75rem;
      padding-left: 5px;
      padding-right: 5px;
    }
    .chatbot-page-text {
      font-size: 1.25rem;
      margin-bottom: 1rem;
      margin-top: 1rem;
      padding-left: 10px;
      padding-right: 10px;
    }
    /* chatbot end */
  .dataHub-logos {
    max-width: 6rem !important;
    max-height: 35rem !important;
    position: absolute;
    top: 0;
    margin-top: 1rem;
  }
  .monitor-config {
    display: none!important;
  }
  .hide-telegram {
    display: none;
  }
  body > div:nth-child(1) > button {
    display: none!important;
  }
  .pages {
    margin-bottom: 0.1rem;
    margin-right: 2rem;
}
.indexer-title {
  text-align: center!important;
  font-weight: 400;
  font-size: 1.5rem;
  padding-top: 2rem;
  color: var(--text-purple);
  
  margin-top: 6rem;
}
.indexer-subtitle {
  text-align: center!important;
  font-weight: 400;
  font-size: 1.25rem;
  color: var(--text-purple);
  
  margin-top: 1rem;
  margin-right: 2rem;
  margin-left: 2rem;
}
.indexer {
  z-index: 999;
  position: absolute;
  left: 0%;
  top: 50%;
}

.curator {
  z-index: 999;
  position: absolute;
  text-align: center!important;
  top: 50%;
}

.tokens, .rewards, .uncollected-rewards {
  margin-top: 2rem;
  width: 90%;
  height: 14rem;
  margin-left: 5%;
  box-shadow: #4D1D86 4px 5px 10px 3px;
}

.c-map {
  margin-top: 2rem;
  width: 100%;
  height: 30rem;
  margin-left: 5%;
  box-shadow: #4D1D86 4px 5px 10px 3px;
}

.d-map {
  margin-top: -3rem;
  margin-left: 3rem;
  width: 100%;
  height: 3800px;
  text-align: center!important;
  box-shadow: #4D1D86 4px 5px 10px 3px;
}
.rewards-per-hour {
  margin-top: 2rem;
  padding-bottom: 2rem;
  width: 90%;
  height: 14rem;
  margin-left: 5%;
  box-shadow: #4D1D86 4px 5px 10px 3px;
}

.indexers {
  padding-left: 1rem;
  padding-left: 1rem;
  color: grey;
  font-size:1.25rem;
  margin-right: 1rem;
}
.indexers:link{
  color: grey;
  cursor: pointer;
  font-size:1.25rem;
  margin-right: 1rem;
}
.indexers:visited {
  color: grey;
  cursor: pointer;
  font-size:1.25rem;
  margin-right: 1rem;
}
.indexers.active,
.indexers:focus {
  border-bottom: 0rem solid var(--hover-purple);
  cursor: pointer;
  font-size:1.25rem;
  margin-right: 1rem;
}
.indexers:hover {
  color: var(--text-purple);
  transform: scale(1.05);
  cursor: pointer;
  font-size:1.25rem;
  margin-right: 1rem;
}
.indexer-link {
  color: white;
}
.indexer-link:link {
  color: white;
  cursor: pointer;
}
.indexer-link:visited {
  color: var(--text-purple);
  cursor: pointer;
}
.indexer-link.active,
.indexer-link:focus {
  border-bottom: 0rem solid var(--text-purple);
  cursor: pointer;
}
.indexer-link:hover {
  color: var(--text-purple);
  transform: scale(1.05);
  cursor: pointer;
}
  .cookie-page-text {
    padding-top: 1rem;
    font-size: 1.25rem;
    margin-bottom: 1rem;
    padding-left: 20px;
    padding-right: 20px;
  }
  .cookies-video {
    padding-top: 2rem;
  }
  .cookie-button {
    z-index: 9999;
    position: absolute;
  }

  body > div:nth-child(1) > button {
    z-index: 10000;
    font-size: 3rem;
    position: absolute;
    padding: auto;
    margin-top: -85px;
    width: 45px;
    height: 45px;
    margin-left: 80%;
    background-color: transparent;
    border: none;
    color: var(--text-purple);
  }

  .extra-data2 {
    font-size: 1.25rem;
    text-align: center;
    color: var(--text-purple);
   
  }
  .data-link {
    color: grey;
  }
  .data-link:link {
    color: grey;
    cursor: pointer;
  }
  .data-link:visited {
    color: grey;
    cursor: pointer;
  }
  .data-link.active,
  .data-link:focus {
    border-bottom: 0rem solid white;
    cursor: pointer;
  }
  .data-link:hover {
    color: white;
    transform: scale(1.05);
    cursor: pointer;
  }
  .question-bank-container {
    background: var(--background);
    color: var(--text-purple);
    width: 100%;
    margin: auto;
    margin-top: 150px;
    margin-bottom: 50px;
    padding-left: 50px;
    padding-right: 50px;
    text-align: center;
  }
  .about-page-container {
    background: var(--background);
    color: var(--text-purple);
    width: 100%;
    margin: auto;
    margin-top: 150px;
    margin-bottom: 50px;
    padding-left: 50px;
    padding-right: 50px;
    text-align: center;
  }
  .QB-text-container {
    background: white;
    border-radius: 1rem;
    padding: 30px;
    color: var(--secondary-purple);
  }
  .text-container {
    background: white;
    border-radius: 1rem;
    padding: 30px;
    color: var(--secondary-purple);
  }
  .QB-about-hr {
    border: 1.5px solid var(--background);
    color: var(--background);
    border-radius: 5px;
    width: 35%;
    margin: auto;
    margin-bottom: 1rem;
    box-shadow: 0 0 5px 1px var(--background);
  }
  .QB-about-page-title {
    font-size: 2rem;
    font-weight: 600;
    color: var(--secondary-purple);
    padding-bottom: 3rem;
  }
  .QB-about-page-subtitle {
    font-size: 1.75rem;
  }
  .QB-about-page-text {
    font-size: 1.25rem;
    margin-bottom: 1rem;
  }
  .about-hr {
    border: 1.5px solid var(--background);
    color: var(--background);
    border-radius: 5px;
    width: 35%;
    margin: auto;
    margin-bottom: 1rem;
    box-shadow: 0 0 5px 1px var(--background);
  }
  .about-page-title {
    font-size: 2rem;
    font-weight: 600;
    color: var(--secondary-purple);
    padding-bottom: 3rem;
  }
  .about-page-subtitle {
    font-size: 1.75rem;
  }
  .about-page-text {
    font-size: 1.25rem;
    margin-bottom: 1rem;
  }
  .graphiql-link {
    color: var(--secondary-purple);
  }
  .graphiql-link:link {
    color: var(--secondary-purple);
    cursor: pointer;
  }
  .graphiql-link:visited {
    color: var(--secondary-purple);
    cursor: pointer;
  }
  .graphiql-link.active,
  .graphiql-link:focus {
    border-bottom: 0rem solid var(--hover-purple);
    cursor: pointer;
  }
  .graphiql-link:hover {
    color: var(--primary-purple);
    transform: scale(1.05);
    cursor: pointer;
  }
  .cookies-policy-container {
    background: white;
    width: 90%;
    padding-bottom: 1rem;
    margin: auto;
    border-radius: 2rem;
    margin-top: 150px;
    margin-bottom: 50px;
  }
  .termly-cookie-preference-button {
   display: none;
  }

  .privacy-policy-container {
    background: white;
    width: 90%;
    padding-bottom: 1rem;
    margin: auto;
    border-radius: 2rem;
    margin-top: 150px;
    margin-bottom: 50px;
  }
  .privacy-policy {
    margin-top: 110px;
    font-weight: 700;
    font-size: 1.1rem;
    text-align: center;
    background-color: var(--background);
    color: var(--secondary-purple);
  }
  .privacy-policy-link,
  .about {
    padding-left: 1rem;
    padding-left: 1rem;
    color: grey;
  }
  .privacy-policy-link:link,
  .about:link {
    color: grey;
    cursor: pointer;
  }
  .privacy-policy-link:visited,
  .about:visited {
    color: grey;
    cursor: pointer;
  }
  .privacy-policy-link.active,
  .privacy-policy-link:focus,
  .about.active,
  .about:focus {
    border-bottom: 0rem solid var(--hover-purple);
    cursor: pointer;
  }
  .privacy-policy-link:hover,
  .about:hover {
    color: var(--text-purple);
    transform: scale(1.05);
    cursor: pointer;
  }
  .subscribeNote {
    margin-left: -13rem;
    width: 30rem;
    font-size: 0.75rem;
    color: white;
  }
   /* loader new start  */
   .loader:empty {
    position: absolute;
    top: calc(50% + 15.5rem);
    left: calc(50% + 2em);
    width: 4em;
    height: 4em;
    border: 0.35em solid white;
    border-left: 0.35em solid #10034B;
    border-radius: 50%;
    animation: load8 0.8s infinite linear;
  }
  .loadingTitle {
    position: absolute;
    top: 27rem;
    left: calc(50% - 3em);
    font-size: 2rem;
    color: #10034B;
    -webkit-box-reflect:below 0px linear-gradient(transparent, #0005);
  }
  .loaderLogo {
    position: absolute;
    top: 14rem;
    left: calc(50% - 8em);
    width: 15em;
    height: 12em;
  }
  @keyframes load8 {
    0% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(360deg);
    }
  }
  /* loader new end  */
  .C {
    font-size: 1rem;
  }
  .logo {
    width: 5.75rem;
    height: 3.75rem;
    margin-left: 2rem;
    padding-top: 0.5rem;
  }
  .data-logo {
    max-width: 1rem !important;
    height: 1rem !important;
    position: absolute;
  }
  .brand-text {
    color: var(--text-purple);
    display: inline;
    margin-left: -0.5rem;
    letter-spacing: 0.0375rem;
    font-size: 1.25rem;
  }
  .header-text {
    font-weight: 400;
    font-size: 2rem;
    padding-top: 1rem;
    text-align: center;
    margin-top: 105px;
    margin-left: 1rem;
    color: var(--text-purple);
   
  }
  .header-sub-text {
    font-weight: 400;
    font-size: 1.7rem;
    width: 90%;
    color: var(--text-purple);
    display: block;
    text-align: center!important;
    margin: auto!important;
    text-align: center;
   
  }
  nav a.TGN {
    display: none;
  }
  .navbar-nav > li > a.TGN:link {
    display: none;
  }
  .navbar-nav > li > a.TGN:visited {
    display: none;
  }
  .navbar-nav > li > a.TGN.active,
  .navbar-nav > li > a.TGN:focus {
    display: none;
  }
  .navbar-nav > li > a.TGN:hover {
    display: none;
  }
  nav a.nav-link {
    font-weight: 600;
    font-size: 1.15rem;
    color: var(--text-purple);
    margin-left: auto;
  }
  .navbar-nav > li > a {
    border-bottom: 0.01875rem solid var(--transparent);
    padding: 0;
  }
  .navbar-nav > li > a:link {
    color: var(--hover-purple);
  }
  .navbar-nav > li > a:visited {
    color: var(--text-purple);
  }
  .navbar-nav > li > a.active,
  .navbar-nav > li > a:focus {
    border-bottom: 0.01875rem solid var(--hover-purple);
    padding: 0;
    transition: all 0.5s ease-in-out;
  }
  .navbar-nav > li > a:hover {
    border-bottom: 0.01875rem solid var(--hover-purple);
    padding: 0;
    color: var(--hover-purple);
    transform: scale(1.05);
  }
  .line {
    margin-left: 1rem;
  }
  .links {
    text-align: right;
    padding-right: 0;
    margin: auto;
    padding: auto;
  }
  .dropdown {
    margin-left: auto;
    padding: auto;
  }
  .bar {
    margin-bottom: 0.1rem;
    margin-right: 2rem;
  }
  .line {
    margin-bottom: 0.1rem;
    margin-right: 2rem;
  }
  .about {
    margin-left: auto;
    margin-right: 0.8rem;
  }
  .menu-icon {
    color: var(--secondary-purple);
  }
  .main-info {
    background-image: url("TopBackground.svg");
    background-repeat: no-repeat;
    background-size: 100% auto;
    width: 100%;
    height: 100%;
    margin-top: 90px;
  }
  .community-links {
    text-align: center;
    padding-top: 5rem;
    position: absolute;
    z-index: 999;
    left: 32%;
  }
  .telegram-icon,
  .twitter-icon,
  .reddit-icon,
  .youtube-icon {
    display: inline;
    padding: 1rem;
    transition: all 0.5s ease-in-out;
    z-index: 999;
  }
  .icon:hover {
    color: var(--hover-purple);
    transform: scale(1.15);
  }
  .icon-youtube:hover {
    color: var(--text-purple);
    transform: scale(1.1);
  }
  .news-title {
    text-shadow: 2.25px 2.25px 3px var(--hover-purple);
    color: var(--text-purple);
    text-align: center;
    margin-top: 1rem;
    margin-bottom: 1rem;
    font-size: 2rem;
  }
  .newsGraph1,
  .newsGraph2,
  .newsGraph3 {
    margin-bottom: 1rem;
  }
  .youtube {
    z-index: 999;
    display: inline;
    position: absolute;
    text-align: center;
    color: var(--text-purple);
  }
  span.anchor {
    display: block;
    visibility: hidden;
    position: relative;
    padding-top: 28%;
  }
  .activeDelegators,
  .holders,
  .curatorCount,
  .subgraphCount {
    font-weight: 400;
    color: var(--text-purple);
    letter-spacing: 0.0375rem;
  }
 
  .video {
    position: absolute;
    box-shadow: #4D1D86 4px 8px 10px 0;
    text-align: center;
    margin-top: 3rem;
    margin-left: 23%;
  }
  .g-ytsubscribe {
    position: absolute;
    font-weight: 400;
    margin: 0;
    left: 58%;
    top: 84%;
  }
 
  .newsGraph {
    width: 100%;
    box-shadow: #4D1D86 4px 5px 10px 3px;
  }
  .chartImg {
    width: 100%;
    box-shadow: #4D1D86 6px 6px 10px 3px;
    margin: auto;
  }
  .center {
    margin: auto;
  }
  .line1 {
    margin-top: 5rem;
  }
 
  .chartImg6 {
    text-align: center;
    padding-top: 1rem;
    width: 100%;

  }
  .reveal {
    position: relative;
    transform: translateY(50px);
    opacity: 0;
    transition: 0.75s all ease;
  }
  .reveal.active {
    transform: translateY(0);
    opacity: 1;
  }
  .email {
    color: var(--hover-purple);
    font-weight: 500;
  }
  .footerLink {
    color: var(--hover-purple);
  }
  .footerLink:link {
    color: var(--hover-purple);
    cursor: pointer;
  }
  .footerLink:visited {
    color: var(--hover-purple);
    cursor: pointer;
  }
  .footerLink.active,
  .footerLink:focus {
    border-bottom: 0rem solid var(--hover-purple);
    cursor: pointer;
  }
  .footerLink:hover {
    color: var(--text-purple);
    transform: scale(1.05);
    cursor: pointer;
  }
  .footer {
    margin-bottom: -1rem;
    padding: 1rem;
    font-weight: 700;
    margin-top: 1rem;
    font-size: 1.1rem;
    text-align: center;
    background-color: var(--primary-purple);
    color: var(--secondary-purple);
  }
  .tsparticles {
    display: none;
  }
  .rotate-position,
  .about-rotate-position,
  .phone,
  .about-phone,
  .message,
  .about-message, .home-message  {
    display: none;
  }
  .adminHeader-text {
    font-weight: 400;
    font-size: 1.5rem;
    text-align: center;
    margin: auto;
    padding-top: 1rem;
    color: var(--text-purple);
    
    margin-top: 6rem;
  }
  .adminHeader-text2 {
    font-weight: 400;
    font-size: 1.5rem;
    text-align: center;
    margin: auto;
    color: var(--text-purple);
    
    margin-top: 2rem;
  }
  .adminSubheader-text {
    font-weight: 400;
    font-size: 1rem;
    margin-left: 3rem;
    color: var(--text-purple);
    
    margin-top: 1rem;
  }
  .adminSubheader-text2 {
    font-weight: 400;
    font-size: 1rem;
    margin-left: 3rem;
    margin-right: 3rem;
    color: var(--text-purple);
   
    margin-top: 1rem;
  }
  .note {
    text-decoration: underline;
  }

  .adminHr2 {
    border: 3px solid var(--hr);
    border-radius: 5px;
    width: 70%;
    margin: auto;
    box-shadow: 0 0 10px 3px var(--hover-purple);
    margin-top: 1rem;
    margin-left: 1.9rem;
  }

 .admin-video {
    position: absolute;
    left: 48%;
    top: 20%;
    width: 10%;
    height: 10%;
  }

  .adminChartsTitle {
    letter-spacing: 0.0375rem;
    font-weight: 400;
    color: var(--text-purple);
    font-size: 2rem;
    text-align: center;
    padding-top: 1rem;
    text-shadow: 2.5px 2.5px 4px var(--hover-purple);
    position: relative;
  }
  .adminChartsTitle2 {
    letter-spacing: 0.0375rem;
    font-weight: 400;
    color: var(--text-purple);
    font-size: 2rem;
    margin-top: 1rem;
    text-align: center;
    text-shadow: 2.5px 2.5px 4px var(--hover-purple);
  }
}

/* *************************************************************************
****************************************************************************
*************************************NEW ANDY MOBILE NEW**************************************/
/* *************************************************************************
****************************************************************************
***************************************************************************/

@media screen and (min-width: 551px) and (max-width: 649px) {
  .flashcards-title {
margin-top: 120px!important;
}
  .hide-line {
    color: transparent!important;
    display: block!important;
  }
  .Quiz-button {
    margin-bottom: 2rem;
  }
  .quiz-title {
    margin-top: -60px;
  }
  .quiz-button-position {
    padding-top: 2rem;
    margin: auto;
    text-align: center;
    margin-bottom: 2rem;
  }
  
  .final-exam-question {
      font-size: 1.3rem;
      text-align: center;
      color: #d8d2e4;
      /* remove for mobile */
      margin-top: 0rem;
      /* remove for mobile */
  }
  
  .final-exam-answer-form {
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin-top: 0rem;
    columns: 1!important;
    width: 100%!important;
  }
  
  .form-check-label {
    font-size: 20px;
    text-align: center;
    height: 110%;
  }
  .quiz-question {
    font-size: 1.3rem;
    padding-bottom: 2rem;
  text-align: center;
  width: 80%;
  margin: auto;
    text-align: center;
    color: #d8d2e4;
  }
  .quiz-question-container {
    margin-top: 20px;
    width: 100%;
  }

  .quiz-answer-form {
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin-top: 0rem;
    columns: 1!important;
    width: 100%!important;
  }

  .quiz-position {
    margin-top: -10px;
  }
  .mobile-portrait {
    display: none;
    }
  .adminHeader-text {
    font-weight: 400;
    font-size: 1.5rem;
    text-align: center;
    margin: auto;
    padding-top: 1rem;
    color: var(--text-purple);
    
    margin-top: 6rem;
  }
  .adminHeader-text2 {
    font-weight: 400;
    font-size: 1.5rem;
    text-align: center;
    margin: auto;
    color: var(--text-purple);
    
    margin-top: 2rem;
  }
  .adminSubheader-text {
    font-weight: 400;
    font-size: 1rem;
    margin-left: 3rem;
    color: var(--text-purple);
    
    margin-top: 1rem;
  }
  .adminSubheader-text2 {
    font-weight: 400;
    font-size: 1rem;
    margin-left: 3rem;
    margin-right: 3rem;
    color: var(--text-purple);

    margin-top: 1rem;
  }
  .monitor-line-carousel {
    display: none;
  }
  
  .monitor-bar-carousel {
   display: none;
  }
  
  .monitor-other-carousel {
    display: none;
  }
  
  .monitor-other-charts-position {
    display: none;
  }
  .mobile-bar-carousel {
    width: 85%;
  height: 500px;
  margin: auto auto;
  padding-top: -1rem;
  padding-bottom: -5rem;
  margin-bottom: -6rem;
  overflow: hidden;
  }
  .mobile-line-carousel {
    width: 85%;
    height: 500px;
    margin: auto auto;
    padding-top: 0rem;
    overflow: hidden;
  }

  .mobile-line-pos {
padding-top: 3rem;
  }
  .more-data-hr {
    margin-top: 1rem!important;
  }
  .more-data-title {
    margin-bottom: -12rem!important;
  }
  .mobile-other-carousel {
    width: 110%;
    height: 550px;
    margin: auto auto;
    margin-left: -2.5rem;
    padding-top: 1rem!important;
    overflow: hidden;
  }
  .map {
    width: 100%;
    margin-left: 5%;
    box-shadow: #4D1D86 4px 5px 10px 3px;
  }
  .holders-pos {
    margin-top: -8rem;
    margin-left: 1%;
    padding-bottom: 2rem;
  }
  .css-1fzpoyk {
    width: 100%;
    padding-top: 1rem;
  }

  .extra-data {
    
    font-size: 1.25rem;
    text-align: center;
    color: var(--text-purple);
  }
  .data-title {
    letter-spacing: 0.0375rem;
    font-weight: 400;
    color: var(--text-purple);
    font-size: 2rem;
    text-align: center;
    position: relative;
  }
  .chartsTitle {
    letter-spacing: 0.0375rem;
    font-weight: 400;
    color: var(--text-purple);
    font-size: 2rem;
    text-align: center;
    position: relative;
    margin-bottom: -13rem;
  }
  .chartsTitle2 {
    letter-spacing: 0.0375rem;
    font-weight: 400;
    color: var(--text-purple);
    font-size: 2rem;
    text-align: center;
    margin-bottom: -10rem;
  }
  .hr {
    border: 3px solid var(--hr);
    border-radius: 5px;
    width: 90%;
    margin: auto;
    box-shadow: 0 0 10px 3px var(--hover-purple);
    position: relative;
    margin-top: 3rem;
  }
  .hr2 {
    border: 3px solid var(--hr);
    border-radius: 5px;
    width: 90%;
    margin: auto;
    box-shadow: 0 0 10px 3px var(--hover-purple);
    position: relative;
    margin-top: 3rem;
  }
 
  .move-up {
    margin-top: -45rem;
  }
.hide-me {
  display: none;
}
  .css-1qzevvg img {
    height: 100%;
    margin-top: -10rem;
    text-align: center;
    margin-left: -1rem;
    z-index: 999999;
    position: relative;
}

/* above me */
 
  .graphtronauts-link {
    color: var(--hr);
    z-index: 999;
    position: absolute;
    display: inline;
    margin-left: 0.5rem;
  }
  .graphtronauts-link:link {
    color: var(--hr);
    cursor: pointer;
  }
  .graphtronauts-link:visited {
    color: var(--hr);
    cursor: pointer;
  }
  .graphtronauts-link.active, graphtronauts-link:focus {
    border-bottom: 0rem solid var(--hr);;
    cursor: pointer;
  }
  .graphtronauts-link:hover {
    color: var(--hr);
    transform: scale(1.025);
    cursor: pointer;
  }
   /* chatbot height start */
.react-chatbot-kit-chat-inner-container {
  background-color: var(--text-purple)!important;
  height: 210px!important;
}
.react-chatbot-kit-chat-message-container {
  height: 170px!important;
}
  /* chatbot height end */
  /* dune start */
.HoldersGraph {
  background-image: linear-gradient(90deg, #1B0D33, #D8D2E4, #D8D2E4,#D8D2E4, #D8D2E4,#D8D2E4,#D8D2E4,#D8D2E4,#D8D2E4,#D8D2E4,#D8D2E4,#D8D2E4,#D8D2E4,#D8D2E4,#D8D2E4,#D8D2E4,#D8D2E4,#D8D2E4,#1B0D33);
  margin-bottom: -36px!important;
  margin-left: -10px!important;
  padding-left: 10px!important;
  padding-right: 10px!important;
  height: 100px!important;
  width: 100%!important;
}
.HoldersGraphBottom {
  background-image: linear-gradient(90deg, #1B0D33, #D8D2E4,#D8D2E4,#D8D2E4,#D8D2E4,#D8D2E4,#D8D2E4,#D8D2E4,#D8D2E4,#D8D2E4,#D8D2E4,#D8D2E4,#D8D2E4,#D8D2E4,#D8D2E4,#D8D2E4,#D8D2E4,#D8D2E4, #1B0D33);
  padding-left: 10px!important;
  margin-left: -10px!important;
  padding-right: 10px!important;
  height: 100px!important;
  width: 100%!important;
}
/* dune end */
  .chatbot-footer {
    display:none;
  }
  .chatbot-link-logo{
    margin-top: -0.5rem;
    z-index: 9998;
    position: fixed;
    max-width: 3.75rem;
    background: white;
    -moz-border-radius: 50px;
    -webkit-border-radius: 50px;
    border-radius: 50px;
    padding: -1rem;
    right: 2%;
    border: 3px var(--secondary-purple) solid;
    box-shadow: 0 0 0 var(--hover-purple);
    animation: pulse 2s infinite;
  }
  .advocate-link-logo{
    margin-top: 4rem;
    z-index: 9998;
    position: fixed;
    max-width: 3.75rem;
    background: white;
    -moz-border-radius: 50px;
    -webkit-border-radius: 50px;
    border-radius: 50px;
    padding: -1rem;
    right: 2%;
    border: 3px var(--secondary-purple) solid;
    box-shadow: 0 0 0 var(--hover-purple);
    animation: pulse 2s infinite;
  }
  @-webkit-keyframes pulse {
    0% {
      -webkit-box-shadow: 0 0 0 0  var(--text-purple);
    }
    70% {
        -webkit-box-shadow: 0 0 0 15px rgba(244,157,22, 0);
    }
    100% {
        -webkit-box-shadow: 0 0 0 0 rgba(244,157,22, 0);
    }
  }
  @keyframes pulse {
    0% {
      -moz-box-shadow: 0 0 0 0  var(--text-purple);
      box-shadow: 0 0 0 0  var(--text-purple);
    }
    70% {
        -moz-box-shadow: 0 0 0 15px rgba(244,157,22, 0);
        box-shadow: 0 0 0 15px rgba(244,157,22, 0);
    }
    100% {
        -moz-box-shadow: 0 0 0 0 rgba(244,157,22, 0);
        box-shadow: 0 0 0 0 rgba(244,157,22, 0);
    }
  }
  
  .chatbot-link-export{
    margin-top: 3.5rem;
    z-index: 9998;
    position: fixed;
    max-width: 3.75rem;
    background: white;
    -moz-border-radius: 50px;
    -webkit-border-radius: 50px;
    border-radius: 50px;
    padding: -1rem;
    right: 2%;
    border: 3px var(--secondary-purple) solid;
    box-shadow: 0 0 0 var(--hover-purple);
    animation: pulse 2s infinite;
  }

/* Chatbot */
.feedback-text {
  margin-top: 2rem;
}
.feedback-button {
  border: 2px var(--primary-purple) solid!important;
  background-color: var(--text-purple)!important;
  color: var(--primary-purple)!important;
  padding: auto!important;
  margin: auto!important;
}

  .feedback-button:link {
    cursor: pointer;
  }
  .feedback-button:visited {
    cursor: pointer;
  }
  .feedback-button.active,
  .feedback-button:focus {
    cursor: pointer;
  }
  .feedback-button:hover {
    background-color: var(--background)!important;
    color: var(--text-purple)!important;
    transform: scale(1.015);
    cursor: pointer;
  }
  .feedback-button-text {
    font-size: 1.5rem;
  }

.react-chatbot-kit-chat-inner-container {
  background-color: var(--text-purple)!important;
}

.react-chatbot-kit-chat-header {
  font-size: 1.1rem!important;
  color: var(--text-purple)!important;
  background-color: var(--primary-purple)!important;
  padding: 0rem!important;
}

.react-chatbot-kit-chat-input-container {
  background-color:var(--secondary-purple)!important;
  border: 1px solid var(--primary-purple)!important;
}

.react-chatbot-kit-chat-btn-send {
  background-color:var(--primary-purple)!important;
}

.react-chatbot-kit-chat-btn-send-icon:hover {
transform: scale(1.25);
}

.react-chatbot-kit-chat-bot-message-container {
  width: 100%;
}

.react-chatbot-kit-chat-bot-avatar-container {
}

.react-chatbot-kit-chat-bot-avatar-icon {
}

.react-chatbot-kit-chat-bot-avatar-letter {
  display: none;
}
.react-chatbot-kit-chat-container {
  width: 98%!important;
  text-align: center;
  margin: auto;
}

.react-chatbot-kit-chat-bot-avatar-container {
  width: 3.5rem!important;
  height: 3.5rem!important;
  background-color: white!important;
  border: 2px solid var(--primary-purple);
  background-image: url('./GRTDataHubChatBot.png')!important;
  background-repeat: no-repeat!important;
  background-size: 3rem;
  background-position-x: 50%;
  background-position-y: 20%;
}

.react-chatbot-kit-chat-bot-message {
  text-align: left!important;
  width: 100%!important;
  color: var(--text-purple)!important;
  background-color: #0C0B23!important;
  font-size: 1rem!important;
}

.react-chatbot-kit-chat-bot-message-arrow {
  color: #0C0B23!important;
  border-right: 8px solid #0C0B23!important;
}
  .indexer-table-header {
    text-align: center;
    margin-left: 1rem;
  }
  .indexer-table {
    text-align: center;
  }
  .chatbot-data {
    display: inline!important;
  }
  .telegramActiveDelegationCount {
    max-width: 92%;
    margin: auto; 
    text-align: center;
    margin-top: 0.5%;
    }
    .top-telegram {
      margin-top: 0.25rem;
    }
    .chatbot-top {
      margin-top: 5rem;
    }
    .chatbot-page-container {
      background: var(--background);
      color: var(--text-purple);
      width: 100%;
      margin: auto;
      margin-top: 120px;
      padding-left: 10px;
      padding-right: 10px;
      text-align: center;
    }
    .chatbot-text-container {
      background: whitesmoke;
      border-radius: 1rem;
      color: var(--secondary-purple);
      margin: auto!important;
      padding-bottom: 10px;
      padding-top: 10px;
      text-align: center!important;
    }
    .chatbot-page-title {
      font-size: 1.25rem;
      font-weight: 600;
      color: var(--secondary-purple);
      padding-bottom: 0.25rem;
      padding-left: 5px;
      padding-right: 5px;
    }
    .chatbot-page-subtitle {
      font-size: 1.75rem;
      padding-left: 5px;
      padding-right: 5px;
    }
    .chatbot-page-text {
      font-size: 1.25rem;
      margin-bottom: 1rem;
      margin-top: 1rem;
      padding-left: 10px;
      padding-right: 10px;
    }
    /* chatbot end */
  .dataHub-logos {
    max-width: 5rem !important;
    max-height: 35rem !important;
    position: absolute;
    top: 0;
    margin-top: 0.25rem;
  }
  .monitor-config {
    display: none!important;
  }
  .hide-telegram {
    display: none;
  }
  body > div:nth-child(1) > button {
    display: none!important;
  }
  .pages {
      margin-bottom: 0.1rem;
      margin-right: 2rem;
  }
  .indexer-title {
    text-align: center!important;
    font-weight: 400;
    font-size: 1.5rem;
    padding-top: 2rem;
    color: var(--text-purple);
    
    margin-top: 6rem;
  }
  .indexer-subtitle {
    text-align: center!important;
    font-weight: 400;
    font-size: 1.25rem;
    color: var(--text-purple);
    
    margin-top: 1rem;
    margin-right: 2rem;
    margin-left: 2rem;
  }
  .indexer {
    z-index: 999;
    position: absolute;
    left: 0%;
    top: 50%;
  }

  .curator {
    z-index: 999;
    position: absolute;
    text-align: center!important;
    top: 50%;
  }

  .tokens, .rewards, .uncollected-rewards {
    margin-top: 2rem;
    width: 90%;
    height: 14rem;
    margin-left: 5%;
    box-shadow: #4D1D86 4px 5px 10px 3px;
  }

  .b-map {
    margin-top: 2rem;
    margin-left: 0.5rem;
    width: 500px;
    height: 4000px;
    text-align: center!important;
    box-shadow: #4D1D86 4px 5px 10px 3px;
  }
  
  .c-map {
    margin-top: 2rem;
    margin-left: 0.5rem;
    width: 500px;
    height: 1000px;
    text-align: center!important;
    box-shadow: #4D1D86 4px 5px 10px 3px;
  }

  .d-map {
    margin-top: -3rem;
    margin-left: 3rem;
    width: 500px;
    height: 3800px;
    text-align: center!important;
    box-shadow: #4D1D86 4px 5px 10px 3px;
  }

  .rewards-per-hour {
    margin-top: 2rem;
    padding-bottom: 2rem;
    width: 90%;
    height: 14rem;
    margin-left: 5%;
    box-shadow: #4D1D86 4px 5px 10px 3px;
  }

  .indexers {
    padding-left: 1rem;
    padding-left: 1rem;
    color: grey;
    font-size:1.25rem;
    margin-right: 1rem;
  }
  .indexers:link{
    color: grey;
    cursor: pointer;
    font-size:1.25rem;
    margin-right: 1rem;
  }
  .indexers:visited {
    color: grey;
    cursor: pointer;
    font-size:1.25rem;
    margin-right: 1rem;
  }
  .indexers.active,
  .indexers:focus {
    border-bottom: 0rem solid var(--hover-purple);
    cursor: pointer;
    font-size:1.25rem;
    margin-right: 1rem;
  }
  .indexers:hover {
    color: var(--text-purple);
    transform: scale(1.05);
    cursor: pointer;
    font-size:1.25rem;
    margin-right: 1rem;
  }
  .indexer-link {
    color: white;
  }
  .indexer-link:link {
    color: white;
    cursor: pointer;
  }
  .indexer-link:visited {
    color: var(--text-purple);
    cursor: pointer;
  }
  .indexer-link.active,
  .indexer-link:focus {
    border-bottom: 0rem solid var(--text-purple);
    cursor: pointer;
  }
  .indexer-link:hover {
    color: var(--text-purple);
    transform: scale(1.05);
    cursor: pointer;
  }
  .cookie-page-text {
    padding-top: 1rem;
    font-size: 1.25rem;
    margin-bottom: 1rem;
    padding-left: 20px;
    padding-right: 20px;
  }
  .cookies-video {
    display: none;
  }
  .cookie-button {
    z-index: 9999;
    position: absolute;
  }

  body > div:nth-child(1) > button {
    z-index: 10000;
    font-size: 3rem;
    position: absolute;
    padding: auto;
    margin-top: -97px;
    width: 40px;
    height: 40px;
    margin-left: 75%;
    background-color: transparent;
    border: none;
    color: var(--text-purple);
  }

  .extra-data2 {
    font-size: 1.25rem;
    text-align: center;
    color: var(--text-purple);
   
  }
  .data-link {
    color: grey;
  }
  .data-link:link {
    color: grey;
    cursor: pointer;
  }
  .data-link:visited {
    color: grey;
    cursor: pointer;
  }
  .data-link.active,
  .data-link:focus {
    border-bottom: 0rem solid white;
    cursor: pointer;
  }
  .data-link:hover {
    color: white;
    transform: scale(1.05);
    cursor: pointer;
  }
  .question-bank-container {
    background: var(--background);
    color: var(--text-purple);
    width: 100%;
    margin: auto;
    margin-top: 150px;
    margin-bottom: 50px;
    padding-left: 50px;
    padding-right: 50px;
    text-align: center;
  }
  .about-page-container {
    background: var(--background);
    color: var(--text-purple);
    width: 100%;
    margin: auto;
    margin-top: 150px;
    margin-bottom: 50px;
    padding-left: 50px;
    padding-right: 50px;
    text-align: center;
  }
  .QB-text-container {
    background: white;
    border-radius: 1rem;
    padding: 30px;
    color: var(--secondary-purple);
  }
  .text-container {
    background: white;
    border-radius: 1rem;
    padding: 30px;
    color: var(--secondary-purple);
  }
  .QB-about-hr {
    border: 1.5px solid var(--background);
    color: var(--background);
    border-radius: 5px;
    width: 35%;
    margin: auto;
    margin-bottom: 1rem;
    box-shadow: 0 0 5px 1px var(--background);
  }
  .QB-about-page-title {
    font-size: 2rem;
    font-weight: 600;
    color: var(--secondary-purple);
    padding-bottom: 3rem;
  }
  .QB-about-page-subtitle {
    font-size: 1.75rem;
  }
  .QB-about-page-text {
    font-size: 1.25rem;
    margin-bottom: 1rem;
  }
  .about-hr {
    border: 1.5px solid var(--background);
    color: var(--background);
    border-radius: 5px;
    width: 35%;
    margin: auto;
    margin-bottom: 1rem;
    box-shadow: 0 0 5px 1px var(--background);
  }
  .about-page-title {
    font-size: 2rem;
    font-weight: 600;
    color: var(--secondary-purple);
    padding-bottom: 3rem;
  }
  .about-page-subtitle {
    font-size: 1.75rem;
  }
  .about-page-text {
    font-size: 1.25rem;
    margin-bottom: 1rem;
  }
  .graphiql-link {
    color: var(--secondary-purple);
  }
  .graphiql-link:link {
    color: var(--secondary-purple);
    cursor: pointer;
  }
  .graphiql-link:visited {
    color: var(--secondary-purple);
    cursor: pointer;
  }
  .graphiql-link.active,
  .graphiql-link:focus {
    border-bottom: 0rem solid var(--hover-purple);
    cursor: pointer;
  }
  .graphiql-link:hover {
    color: var(--primary-purple);
    transform: scale(1.05);
    cursor: pointer;
  }
  .cookies-policy-container {
    background: white;
    width: 90%;
    padding-bottom: 1rem;
    margin: auto;
    border-radius: 2rem;
    margin-top: 150px;
    margin-bottom: 50px;
  }
  .termly-cookie-preference-button {
   display: none;
  }

  .privacy-policy-container {
    background: white;
    width: 90%;
    padding-bottom: 1rem;
    margin: auto;
    border-radius: 2rem;
    margin-top: 150px;
    margin-bottom: 50px;
  }
  .privacy-policy {
    margin-top: 110px;
    font-weight: 700;
    font-size: 1.1rem;
    text-align: center;
    background-color: var(--background);
    color: var(--secondary-purple);
  }
  .privacy-policy-link,
  .about {
    padding-left: 1rem;
    padding-left: 1rem;
    color: grey;
  }
  .privacy-policy-link:link,
  .about:link {
    color: grey;
    cursor: pointer;
  }
  .privacy-policy-link:visited,
  .about:visited {
    color: grey;
    cursor: pointer;
  }
  .privacy-policy-link.active,
  .privacy-policy-link:focus,
  .about.active,
  .about:focus {
    border-bottom: 0rem solid var(--hover-purple);
    cursor: pointer;
  }
  .privacy-policy-link:hover,
  .about:hover {
    color: var(--text-purple);
    transform: scale(1.05);
    cursor: pointer;
  }
  .subscribeNote {
    margin-left: -14rem;
    width: 30rem;
    font-size: 0.75rem;
    color: white;
  }
    /* loader new start  */
    .loader:empty {
      position: absolute;
      top: calc(50% + 4rem);
      left: calc(50% + 2em);
      width: 4em;
      height: 4em;
      border: 0.35em solid white;
      border-left: 0.35em solid #10034B;
      border-radius: 50%;
      animation: load8 0.8s infinite linear;
    }
    .loadingTitle {
      position: absolute;
      top: 15rem;
      left: calc(50% - 3em);
      font-size: 2rem;
      color: #10034B;
      -webkit-box-reflect:below 0px linear-gradient(transparent, #0005);
    }
    .loaderLogo {
      position: absolute;
      top: 3rem;
      left: calc(50% - 8em);
      width: 15em;
      height: 12em;
    }
    @keyframes load8 {
      0% {
        transform: rotate(0deg);
      }
      100% {
        transform: rotate(360deg);
      }
    }
    /* loader new end  */
    /* New logo start */
    .logo {
      width: 5rem;
      height: 4rem;
      margin-left: 1rem;
      margin-top: -0.5rem;
    }
    .data-logo {
      max-width: 1.25rem !important;
      height: 1.25rem !important;
      position: absolute;
    }
    .brand-text {
      color: var(--text-purple);
      display: inline;
      margin-left: -0.5rem;
      letter-spacing: 0.0375rem;
      font-size: 1.25rem;
    }
     /* New logo end */
  .header-text {
    font-weight: 400;
    font-size: 2rem;
    padding-top: 1rem;
    text-align: center;
    margin-top: 105px;
    color: var(--text-purple);
  
  }
  .header-sub-text {
    font-weight: 400;
    font-size: 1.5rem;
    text-align: center!important;
    margin: auto!important;
    width: 90%;
    color: var(--text-purple);
    display: block;
    text-align: center;
  
  }
  nav a.TGN {
    display: none;
  }
  .navbar-nav > li > a.TGN:link {
    display: none;
  }
  .navbar-nav > li > a.TGN:visited {
    display: none;
  }
  .navbar-nav > li > a.TGN.active,
  .navbar-nav > li > a.TGN:focus {
    display: none;
  }
  .navbar-nav > li > a.TGN:hover {
    display: none;
  }
  .navbar-nav > li > a.TGN:link {
    display: none;
  }
  .navbar-nav > li > a.TGN:visited {
    display: none;
  }
  .navbar-nav > li > a.TGN.active,
  .navbar-nav > li > a.TGN:focus {
    display: none;
  }
  .navbar-nav > li > a.TGN:hover {
    display: none;
  }
  nav a.nav-link {
    font-weight: 600;
    font-size: 1.15rem;
    color: var(--text-purple);
  }
  .navbar-nav > li > a {
    border-bottom: 0.01875rem solid var(--transparent);
    padding: 0;
  }
  .navbar-nav > li > a:link {
    color: var(--hover-purple);
  }
  .navbar-nav > li > a:visited {
    color: var(--text-purple);
  }
  .navbar-nav > li > a.active,
  .navbar-nav > li > a:focus {
    border-bottom: 0.01875rem solid var(--hover-purple);
    padding: 0;
    transition: all 0.5s ease-in-out;
  }
  .navbar-nav > li > a:hover {
    border-bottom: 0.01875rem solid var(--hover-purple);
    padding: 0;
    color: var(--hover-purple);
    transform: scale(1.05);
  }
  .line {
    margin-left: 1rem;
  }
  .links {
    text-align: right;
    padding-right: 0;
    margin: auto;
    padding: auto;
  }
  .dropdown {
    margin-left: auto;
    padding: auto;
  }
  .bar {
    margin-bottom: 0.1rem;
    margin-right: 2rem;
  }
  .line {
    margin-bottom: 0.1rem;
    margin-right: 2rem;
  }
  .about {
    margin-left: auto;
    margin-right: 0.8rem;
  }
  .menu-icon {
    color: var(--secondary-purple);
  }
  .main-info {
    background-image: url("TopBackground.svg");
    background-repeat: no-repeat;
    background-size: 100% auto;
    width: 100%;
    height: 100%;
    margin-top: 90px;
  }
  .community-links {
    text-align: center;
    padding-top: 5rem;
    position: absolute;
    z-index: 999;
    left: 30%;
  }
  .telegram-icon,
  .twitter-icon,
  .reddit-icon,
  .youtube-icon {
    display: inline;
    padding: 1rem;
    transition: all 0.5s ease-in-out;
    z-index: 999;
  }
  .icon:hover {
    color: var(--hover-purple);
    transform: scale(1.15);
  }
  .icon-youtube:hover {
    color: var(--text-purple);
    transform: scale(1.1);
  }
  .news-title {
    text-shadow: 2.25px 2.25px 3px var(--hover-purple);
    color: var(--text-purple);
    text-align: center;
    margin-top: 1rem;
    margin-bottom: 1rem;
    font-size: 1.3rem;
    margin-left: -15rem;
  }
  .newsGraph1,
  .newsGraph2,
  .newsGraph3 {
    margin-bottom: 1rem;
  }
  .youtube {
    z-index: 999;
    display: inline;
    position: absolute;
    text-align: center;
    color: var(--text-purple);
  }
  span.anchor {
    display: block;
    visibility: hidden;
    position: relative;
    padding-top: 27%;
  }
  .activeDelegators,
  .holders,
  .curatorCount,
  .subgraphCount {
    font-weight: 400;
    color: var(--text-purple);
    letter-spacing: 0.0375rem;
  }
 
  .video {
    position: absolute;
    box-shadow: #4D1D86 4px 8px 10px 0;
    text-align: center;
    margin-top: 3rem;
    margin-left: 20%;
  }
  .g-ytsubscribe {
    position: absolute;
    font-weight: 400;
    margin: 0;
    left: 58%;
    top: 84%;
  }

  .newsGraph {
    width: 100%;
    box-shadow: #4D1D86 4px 5px 10px 3px;
  }
  .chartImg {
    width: 100%;
    box-shadow: #4D1D86 6px 6px 10px 3px;
    margin: auto;
  }
  .center {
    margin: auto;
  }
  .line1 {
    margin-top: 5rem;
  }

  .chartImg6 {
    text-align: center;
    padding-top: 1rem;
    width: 100%;
  
  }
  .reveal {
    position: relative;
    transform: translateY(50px);
    opacity: 0;
    transition: 0.75s all ease;
  }
  .reveal.active {
    transform: translateY(0);
    opacity: 1;
  }
  .email {
    color: var(--hover-purple);
    font-weight: 500;
  }
  .footerLink {
    color: var(--hover-purple);
  }
  .footerLink:link {
    color: var(--hover-purple);
    cursor: pointer;
  }
  .footerLink:visited {
    color: var(--hover-purple);
    cursor: pointer;
  }
  .footerLink.active,
  .footerLink:focus {
    border-bottom: 0rem solid var(--hover-purple);
    cursor: pointer;
  }
  .footerLink:hover {
    color: var(--text-purple);
    transform: scale(1.05);
    cursor: pointer;
  }
  .footer {
    margin-bottom: -1rem;
    padding: 1rem;
    font-weight: 700;
    margin-top: 1rem;
    font-size: 1.1rem;
    text-align: center;
    background-color: var(--primary-purple);
    color: var(--secondary-purple);
  }
  .tsparticles {
    display: none;
  }
  .C {
    font-size: 1rem;
  }
  .rotate-position,
  .about-rotate-position,
  .phone,
  .about-phone,
  .message,
  .about-message, .home-message  {
    display: none;
  }
  .adminHeader-text {
    font-weight: 400;
    font-size: 1.5rem;
    text-align: center;
    margin: auto;
    padding-top: 1rem;
    color: var(--text-purple);
    
    margin-top: 6rem;
  }
  .adminHeader-text2 {
    font-weight: 400;
    font-size: 1.5rem;
   text-align: center;
    margin: auto;
    color: var(--text-purple);
    
    margin-top: 2rem;
  }
  .adminSubheader-text {
    font-weight: 400;
    font-size: 1rem;
    text-align: center;
    color: var(--text-purple);
    
    margin-top: 1rem;
    margin-left: 1rem;
    margin-right: 1rem;
  }
  .adminSubheader-text2 {
    font-weight: 400;
    font-size: 1rem;
    text-align: center;
    color: var(--text-purple);
   
    margin-top: 1rem;
    margin-left: 1rem;
    margin-right: 3rem;
  }
  .note {
    text-decoration: underline;
  }

  .adminHr2 {
    border: 3px solid var(--hr);
    border-radius: 5px;
    width: 70%;
    margin: auto;
    box-shadow: 0 0 10px 3px var(--hover-purple);
    margin-top: 1rem;
    margin-left: 1.9rem;
  }
.move-down {
  margin-top: 17rem;
}
 .admin-video {
    position: absolute;
    left: 13%;
    top: 19%;
    width: 10%;
    height: 10%;
  }

  .adminChartsTitle {
    letter-spacing: 0.0375rem;
    font-weight: 400;
    color: var(--text-purple);
    font-size: 2rem;
    text-align: center;
    padding-top: 1rem;
    text-shadow: 2.5px 2.5px 4px var(--hover-purple);
    position: relative;
  }
  .adminChartsTitle2 {
    letter-spacing: 0.0375rem;
    font-weight: 400;
    color: var(--text-purple);
    font-size: 2rem;
    margin-top: 1rem;
    text-align: center;
    text-shadow: 2.5px 2.5px 4px var(--hover-purple);
  }
}
/* *************************************************************************
****************************************************************************
***************************************************************************/
/* *************************************************************************
****************************************************************************
***************************************************************************/

@media screen and (min-width: 507px) and (max-width: 550px) {
  .mobile-landscape {
    display: none;
    }
  .monitor-line-carousel {
    display: none;
  }
  
  .monitor-bar-carousel {
   display: none;
  }
  
  .monitor-other-carousel {
    display: none;
  }
  
  .monitor-other-charts-position {
    display: none;
  }
  .mobile-bar-carousel {
    width: 100%;
  height: 500px;
  margin: auto auto;
  padding-top: -1rem;
  padding-bottom: -5rem;
  margin-bottom: -6rem;
  overflow: hidden;
  }
  .mobile-line-carousel {
    width: 100%;
    height: 500px;
    margin: auto auto;
    padding-top: 0rem;
    overflow: hidden;
  }

  .mobile-line-pos {
padding-top: 3rem;
  }
  .more-data-hr {
    margin-top: 1rem!important;
  }
  .more-data-title {
    padding-bottom: 2rem!important;
  }
  .mobile-other-carousel {
    width: 110%;
    height: 500px;
    margin: auto auto;
    margin-left: -1.5rem;
    padding-top: 1rem!important;
    overflow: hidden;
  }
  .map {
    width: 100%;
    margin-left: 5%;
    box-shadow: #4D1D86 4px 5px 10px 3px;
  }
  .holders-pos {
    margin-left: -5%;
    padding-bottom: 2rem;
  }
  .css-1fzpoyk {
    width: 100%;
    padding-top: 1rem;
  }

  .extra-data {
    
    font-size: 1.25rem;
    text-align: center;
    color: var(--text-purple);
  }
  .data-title {
    letter-spacing: 0.0375rem;
    font-weight: 400;
    color: var(--text-purple);
    font-size: 2rem;
    text-align: center;
    position: relative;
  }
  .chartsTitle {
    letter-spacing: 0.0375rem;
    font-weight: 400;
    color: var(--text-purple);
    font-size: 2rem;
    text-align: center;
    position: relative;
    margin-bottom: -13rem;
  }
  .chartsTitle2 {
    letter-spacing: 0.0375rem;
    font-weight: 400;
    color: var(--text-purple);
    font-size: 2rem;
    text-align: center;
    margin-bottom: -10rem;
  }
  .hr {
    border: 3px solid var(--hr);
    border-radius: 5px;
    width: 90%;
    margin: auto;
    box-shadow: 0 0 10px 3px var(--hover-purple);
    position: relative;
    margin-top: 7rem;
  }
  .hr2 {
    border: 3px solid var(--hr);
    border-radius: 5px;
    width: 90%;
    margin: auto;
    box-shadow: 0 0 10px 3px var(--hover-purple);
    position: relative;
    margin-top: 6rem;
  }
 
  .move-up {
    margin-top: -45rem;
  }
.hide-me {
  display: none;
}
  .css-1qzevvg img {
    height: 100%;
    margin-top: -6rem;
    text-align: center;
    margin-left: -1rem;
    z-index: 999999;
    position: relative;
}
.adminHeader-text {
  display: none;
}
.adminHeader-text2 {
 display: none;
}
.adminSubheader-text {
  display: none;
}
.adminSubheader-text2 {
  display: none;
}
.adminChartsTitle2 {
  display: none;
}
.hide-export {
  display: none;
}
/* above me */
  .data-link {
    color: grey;
  }
  .data-link:link {
    color: grey;
    cursor: pointer;
  }
  .data-link:visited {
    color: grey;
    cursor: pointer;
  }
  .data-link.active,
  .data-link:focus {
    border-bottom: 0rem solid white;
    cursor: pointer;
  }
  .data-link:hover {
    color: white;
    transform: scale(1.05);
    cursor: pointer;
  }
  .no-monitor {
    display: none!important;
  }
  .graphtronauts-link {
    color: var(--hr);
    z-index: 999;
    position: absolute;
    display: inline;
    margin-left: 0.5rem;
  }
  .graphtronauts-link:link {
    color: var(--hr);
    cursor: pointer;
  }
  .graphtronauts-link:visited {
    color: var(--hr);
    cursor: pointer;
  }
  .graphtronauts-link.active, graphtronauts-link:focus {
    border-bottom: 0rem solid var(--hr);;
    cursor: pointer;
  }
  .graphtronauts-link:hover {
    color: var(--hr);
    transform: scale(1.025);
    cursor: pointer;
  }
   /* dune start */
.HoldersGraph {
  background-image: linear-gradient(90deg, #1B0D33, #D8D2E4, #D8D2E4,#D8D2E4, #D8D2E4,#D8D2E4,#D8D2E4,#D8D2E4,#D8D2E4,#D8D2E4,#D8D2E4,#D8D2E4,#D8D2E4,#D8D2E4,#D8D2E4,#D8D2E4,#D8D2E4,#D8D2E4,#1B0D33);
  margin-bottom: -36px!important;
  margin-left: -10px!important;
  padding-left: 10px!important;
  padding-right: 10px!important;
  height: 100px!important;
  width: 100%!important;
}
.HoldersGraphBottom {
  background-image: linear-gradient(90deg, #1B0D33, #D8D2E4,#D8D2E4,#D8D2E4,#D8D2E4,#D8D2E4,#D8D2E4,#D8D2E4,#D8D2E4,#D8D2E4,#D8D2E4,#D8D2E4,#D8D2E4,#D8D2E4,#D8D2E4,#D8D2E4,#D8D2E4,#D8D2E4, #1B0D33);
  padding-left: 10px!important;
  margin-left: -10px!important;
  padding-right: 10px!important;
  height: 100px!important;
  width: 100%!important;
}
/* dune end */
  .chatbot-footer {
    display:none;
  }
  .chatbot-link-logo{
    margin-top: 1.5rem;
    z-index: 9998;
    position: fixed;
    max-width: 3rem;
    background: white;
    -moz-border-radius: 50px;
    -webkit-border-radius: 50px;
    border-radius: 50px;
    padding: -1rem;
    right: 2%;
    border: 3px var(--secondary-purple) solid;
    box-shadow: 0 0 0 var(--hover-purple);
    animation: pulse 2s infinite;
  }
  .advocate-link-logo{
    margin-top: 4rem;
    z-index: 9998;
    position: fixed;
    max-width: 3rem;
    background: white;
    -moz-border-radius: 50px;
    -webkit-border-radius: 50px;
    border-radius: 50px;
    padding: -1rem;
    right: 2%;
    border: 3px var(--secondary-purple) solid;
    box-shadow: 0 0 0 var(--hover-purple);
    animation: pulse 2s infinite;
  }
  @-webkit-keyframes pulse {
    0% {
      -webkit-box-shadow: 0 0 0 0  var(--text-purple);
    }
    70% {
        -webkit-box-shadow: 0 0 0 15px rgba(244,157,22, 0);
    }
    100% {
        -webkit-box-shadow: 0 0 0 0 rgba(244,157,22, 0);
    }
  }
  @keyframes pulse {
    0% {
      -moz-box-shadow: 0 0 0 0  var(--text-purple);
      box-shadow: 0 0 0 0  var(--text-purple);
    }
    70% {
        -moz-box-shadow: 0 0 0 15px rgba(244,157,22, 0);
        box-shadow: 0 0 0 15px rgba(244,157,22, 0);
    }
    100% {
        -moz-box-shadow: 0 0 0 0 rgba(244,157,22, 0);
        box-shadow: 0 0 0 0 rgba(244,157,22, 0);
    }
  }
  
  .chatbot-link-export{
    margin-top: 3rem;
    z-index: 9998;
    position: fixed;
    max-width: 3rem;
    background: white;
    -moz-border-radius: 50px;
    -webkit-border-radius: 50px;
    border-radius: 50px;
    padding: -1rem;
    left: 42%;
    border: 3px var(--secondary-purple) solid;
    box-shadow: 0 0 0 var(--hover-purple);
    animation: pulse 2s infinite;
  }
  /* Chatbot */
.feedback-text {
  margin-top: 2rem;
}
.feedback-button {
  border: 2px var(--primary-purple) solid!important;
  background-color: var(--text-purple)!important;
  color: var(--primary-purple)!important;
  padding: auto!important;
  margin: auto!important;
}

  .feedback-button:link {
    cursor: pointer;
  }
  .feedback-button:visited {
    cursor: pointer;
  }
  .feedback-button.active,
  .feedback-button:focus {
    cursor: pointer;
  }
  .feedback-button:hover {
    background-color: var(--background)!important;
    color: var(--text-purple)!important;
    transform: scale(1.015);
    cursor: pointer;
  }
  .feedback-button-text {
    font-size: 1.5rem;
  }

.react-chatbot-kit-chat-inner-container {
  background-color: var(--text-purple)!important;
}

.react-chatbot-kit-chat-header {
  font-size: 1.1rem!important;
  color: var(--text-purple)!important;
  background-color: var(--primary-purple)!important;
  padding: 0rem!important;
}

.react-chatbot-kit-chat-input-container {
  background-color:var(--secondary-purple)!important;
  border: 1px solid var(--primary-purple)!important;
}

.react-chatbot-kit-chat-btn-send {
  background-color:var(--primary-purple)!important;
}

.react-chatbot-kit-chat-btn-send-icon:hover {
transform: scale(1.25);
}

.react-chatbot-kit-chat-bot-message-container {
  width: 100%;
}

.react-chatbot-kit-chat-bot-avatar-container {
}

.react-chatbot-kit-chat-bot-avatar-icon {
}

.react-chatbot-kit-chat-bot-avatar-letter {
  display: none;
}
.react-chatbot-kit-chat-container {
  width: 98%!important;
  text-align: center;
  margin: auto;
}

.react-chatbot-kit-chat-bot-avatar-container {
  width: 3.5rem!important;
  height: 3.5rem!important;
  background-color: white!important;
  border: 2px solid var(--primary-purple);
  background-image: url('./GRTDataHubChatBot.png')!important;
  background-repeat: no-repeat!important;
  background-size: 3rem;
  background-position-x: 50%;
  background-position-y: 20%;
}

.react-chatbot-kit-chat-bot-message {
  text-align: left!important;
  width: 100%!important;
  color: var(--text-purple)!important;
  background-color: #0C0B23!important;
  font-size: 1rem!important;
}

.react-chatbot-kit-chat-bot-message-arrow {
  color: #0C0B23!important;
  border-right: 8px solid #0C0B23!important;
}
  .indexer-table-header {
    text-align: center;
    margin-left: 1rem;
  }
  .indexer-table {
    text-align: center;
  }
  .chatbot-data {
    display: inline!important;
  }
  .telegramActiveDelegationCount {
    max-width: 92%;
    margin: auto; 
    text-align: center;
    margin-top: 0.5%;
    }
    .top-telegram {
      margin-top: 0.25rem;
    }
    .chatbot-top {
      margin-top: 5rem;
    }
    .chatbot-page-container {
      background: var(--background);
      color: var(--text-purple);
      width: 100%;
      margin: auto;
      margin-top: 120px;
      padding-left: 10px;
      padding-right: 10px;
      text-align: center;
    }
    .chatbot-text-container {
      background: whitesmoke;
      border-radius: 1rem;
      color: var(--secondary-purple);
      margin: auto!important;
      padding-bottom: 10px;
      padding-top: 10px;
      text-align: center!important;
    }
    .chatbot-page-title {
      font-size: 1.25rem;
      font-weight: 600;
      color: var(--secondary-purple);
      padding-bottom: 0.25rem;
      padding-left: 5px;
      padding-right: 5px;
    }
    .chatbot-page-subtitle {
      font-size: 1.75rem;
      padding-left: 5px;
      padding-right: 5px;
    }
    .chatbot-page-text {
      font-size: 1.25rem;
      margin-bottom: 1rem;
      margin-top: 1rem;
      padding-left: 10px;
      padding-right: 10px;
    }
    /* chatbot end */
  .dataHub-logos {
    display: none;
  }
  .monitor-config {
    display: none!important;
  }
  body > div:nth-child(1) > button {
    display: none!important;
  }
  .offcanvas-button, .offcanvas-menu {
    display: none!important;
  }
  .indexer-title {
    display: none;
   }
   .indexer-subtitle {
     display: none;
   }
   .indexer {
     display: none;
   }

   .curator {
    display: none;
  }
  .tokens, .rewards, .uncollected-rewards {
     display: none;
   }

   .c-map {
  display: none;
  }

  .d-map {
    display: none;
  }
 
   .rewards-per-hour {
     display: none;
   }
 
   .indexers {
     display: none;
   }
   .indexers:link{
     display: none;
   }
   .indexers:visited {
     display: none;
   }
   .indexers.active,
   .indexers:focus {
     display: none;
   }
   .indexers:hover {
     display: none;
   }
   .indexer-link {
     display: none;
   }
   .indexer-link:link {
     display: none;
   }
   .indexer-link:visited {
     display: none;
   }
   .indexer-link.active,
   .indexer-link:focus {
     display: none;
   }
   .indexer-link:hover {
     display: none;
   }
  .cookie-page-text {
    padding-top: 1rem;
    font-size: 1.25rem;
    margin-bottom: 1rem;
    padding-left: 20px;
    padding-right: 20px;
  }
  .cookies-video {
    display: none;
  }
  .cookie-button {
    display: none;
  }
  body > div:nth-child(1) > button {
    display: none;
  }
  body > div:nth-child(1) > button:hover {
    display: none;
  }

  .extra-data2 {
    font-size: 1.25rem;
    text-align: center;
    color: var(--text-purple);
   
  }
  .question-bank-container {
    background: var(--background);
    color: var(--text-purple);
    width: 100%;
    margin: auto;
    margin-top: 150px;
    margin-bottom: 50px;
    padding-left: 50px;
    padding-right: 50px;
    text-align: center;
  }
  .about-page-container {
    background: var(--background);
    color: var(--text-purple);
    width: 100%;
    margin: auto;
    margin-top: 150px;
    margin-bottom: 50px;
    padding-left: 50px;
    padding-right: 50px;
    text-align: center;
  }
  .QB-text-container {
    background: white;
    border-radius: 1rem;
    padding: 30px;
    color: var(--secondary-purple);
  }
  .text-container {
    display: none;
  }
  .QB-about-hr {
    border: 1.5px solid var(--background);
    color: var(--background);
    border-radius: 5px;
    width: 35%;
    margin: auto;
    margin-bottom: 1rem;
    box-shadow: 0 0 5px 1px var(--background);
  }
  .QB-about-page-title {
    font-size: 2rem;
    font-weight: 600;
    color: var(--secondary-purple);
    padding-bottom: 3rem;
  }
  .QB-about-page-subtitle {
    font-size: 1.75rem;
  }
  .QB-about-page-text {
    font-size: 1.25rem;
    margin-bottom: 1rem;
  }
  
  .about-hr {
    display: none;
  }
  .about-page-title {
    display: none;
  }
  .about-page-subtitle {
    display: none;
  }
  .about-page-text {
    display: none;
  }
  .graphiql-link {
    color: var(--secondary-purple);
  }
  .graphiql-link:link {
    color: var(--secondary-purple);
    cursor: pointer;
  }
  .graphiql-link:visited {
    color: var(--secondary-purple);
    cursor: pointer;
  }
  .graphiql-link.active,
  .graphiql-link:focus {
    border-bottom: 0rem solid var(--hover-purple);
    cursor: pointer;
  }
  .graphiql-link:hover {
    color: var(--primary-purple);
    transform: scale(1.05);
    cursor: pointer;
  }
  .cookies-policy-container {
    background: white;
    width: 90%;
    padding-bottom: 1rem;
    margin: auto;
    border-radius: 2rem;
    margin-top: 150px;
    margin-bottom: 50px;
  }
  .privacy-policy-container {
    background: white;
    width: 90%;
    padding-bottom: 1rem;
    margin: auto;
    border-radius: 2rem;
    margin-top: 150px;
    margin-bottom: 50px;
  }
  .privacy-policy {
    margin-top: 110px;
    font-weight: 700;
    font-size: 1.1rem;
    text-align: center;
    background-color: var(--background);
    color: var(--secondary-purple);
  }
  .privacy-policy-link,
  .about {
    padding-left: 1rem;
    padding-left: 1rem;
    color: grey;
  }
  .privacy-policy-link:link,
  .about:link {
    color: grey;
    cursor: pointer;
  }
  .privacy-policy-link:visited,
  .about:visited {
    color: grey;
    cursor: pointer;
  }
  .privacy-policy-link.active,
  .privacy-policy-link:focus,
  .about.active,
  .about:focus {
    border-bottom: 0rem solid var(--hover-purple);
    cursor: pointer;
  }
  .privacy-policy-link:hover,
  .about:hover {
    color: var(--text-purple);
    transform: scale(1.05);
    cursor: pointer;
  }
  .rotate-position,
  .about-rotate-position {
    margin-top: 6rem;
  }
  .phone,
  .about-phone {
    height: 50px;
    padding-top: 10rem;
    width: 100px;
    border: 3px solid white;
    border-radius: 10px;
    animation: rotate 1.5s ease-in-out infinite alternate;
    text-align: center;
    margin: auto;
  }
  .message,
  .about-message {
    color: var(--text-purple);
    text-shadow: 2.5px 2.5px 4px var(--hover-purple);
    font-weight: 400;
    font-size: 1.25rem;
    padding-top: 1rem;
    padding-left: 1rem;
    padding-right: 1rem;
    text-align: center;
    margin: auto;
  }
  .home-message {
    color: var(--text-purple);
    text-shadow: 2.5px 2.5px 4px var(--hover-purple);
    font-weight: 400;
    font-size: 1.25rem;
    padding-top: 3rem;
    padding-bottom: 2rem;
    padding-left: 1rem;
    padding-right: 1rem;
    text-align: center;
    margin: auto;
  }
  @keyframes rotate {
    0% {
      transform: rotate(0deg);
    }
    50% {
      transform: rotate(-90deg);
    }
    100% {
      transform: rotate(-90deg);
    }
  }
  .subscribeNote {
    display: none;
  }
   /* loader new start  */
   .loader:empty {
    position: absolute;
    top: calc(50% + 15.5rem);
    left: calc(50% + 2em);
    width: 4em;
    height: 4em;
    border: 0.35em solid white;
    border-left: 0.35em solid #10034B;
    border-radius: 50%;
    animation: load8 0.8s infinite linear;
  }
  .loadingTitle {
    position: absolute;
    top: 27rem;
    left: calc(50% - 3em);
    font-size: 2rem;
    color: #10034B;
    -webkit-box-reflect:below 0px linear-gradient(transparent, #0005);
  }
  .loaderLogo {
    position: absolute;
    top: 14rem;
    left: calc(50% - 8em);
    width: 15em;
    height: 12em;
  }
  @keyframes load8 {
    0% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(360deg);
    }
  }
    /* New logo start */
    .logo {
      width: 8rem;
      height: 6rem;
      margin-left: 1rem;
      margin-top: -0.5rem;
    }
    .data-logo {
      max-width: 1.25rem !important;
      height: 1.25rem !important;
      position: absolute;
    }
    .brand-text {
      color: var(--text-purple);
      display: inline;
      margin-left: -0.5rem;
      letter-spacing: 0.0375rem;
      font-size: 1.25rem;
    }
     /* New logo end */
  .header-text {
    font-weight: 400;
    font-size: 1.5rem;
    padding-top: 1rem;
    text-align: center;
    margin-top: 105px;
    color: var(--text-purple);
 
  }
  .header-sub-text {
    font-weight: 400;
    font-size: 1rem;
    text-align: center!important;
    width: 90%;
    margin: auto!important;
    color: var(--text-purple);
    display: block;
    text-align: center;

  }
  .navbar-toggler {
    display: none;
  }
  nav a.TGN {
    display: none;
  }
  .navbar-nav > li > a.TGN:link {
    display: none;
  }
  .navbar-nav > li > a.TGN:visited {
    display: none;
  }
  .navbar-nav > li > a.TGN.active,
  .navbar-nav > li > a.TGN:focus {
    display: none;
  }
  .navbar-nav > li > a.TGN:hover {
    display: none;
  }
  nav a.nav-link {
    font-weight: 600;
    font-size: 1.15rem;
    color: var(--text-purple);
  }
  .navbar-nav > li > a {
    border-bottom: 0.01875rem solid var(--transparent);
    padding: 0;
  }
  .navbar-nav > li > a:link {
    color: var(--hover-purple);
  }
  .navbar-nav > li > a:visited {
    color: var(--text-purple);
  }
  .navbar-nav > li > a.active,
  .navbar-nav > li > a:focus {
    border-bottom: 0.01875rem solid var(--hover-purple);
    padding: 0;
    transition: all 0.5s ease-in-out;
  }
  .navbar-nav > li > a:hover {
    border-bottom: 0.01875rem solid var(--hover-purple);
    padding: 0;
    color: var(--hover-purple);
    transform: scale(1.05);
  }
  .line {
    margin-left: 1rem;
  }
  .links {
    text-align: right;
    padding-right: 0;
    margin: auto;
    padding: auto;
  }
  .dropdown {
    margin-left: auto;
    padding: auto;
  }
  .bar {
    margin-bottom: 0.1rem;
    margin-right: 2rem;
  }
  .line {
    margin-bottom: 0.1rem;
    margin-right: 2rem;
  }
  .about {
    margin-left: auto;
    margin-right: 0.8rem;
  }
  .menu-icon {
    color: var(--secondary-purple);
  }
  .main-info {
    background-image: url("TopBackground.svg");
    background-repeat: no-repeat;
    background-size: 100% auto;
    width: 100%;
    height: 100%;
    margin-top: 90px;
  }
  .community-links {
    text-align: center;
    padding-top: 4rem;
    position: absolute;
    z-index: 999;
    left: 25%;
  }
  .telegram-icon,
  .twitter-icon,
  .reddit-icon,
  .youtube-icon {
    display: inline;
    padding: 1rem;
    transition: all 0.5s ease-in-out;
    z-index: 999;
  }
  .icon:hover {
    color: var(--hover-purple);
    transform: scale(1.15);
  }
  .icon-youtube:hover {
    color: var(--text-purple);
    transform: scale(1.1);
  }
  .news-title {
    text-shadow: 2.25px 2.25px 3px var(--hover-purple);
    color: var(--text-purple);
    text-align: center;
    margin-top: 1rem;
    margin-bottom: 1rem;
    margin-left: -12rem;
    font-size: 1rem;
  }
  .newsGraph1,
  .newsGraph2,
  .newsGraph3,
  .newsGraph4 {
    display: none;
  }
  .youtube {
    z-index: 999;
    display: inline;
    position: absolute;
    text-align: center;
    color: var(--text-purple);
  }
  span.anchor {
    display: block;
    visibility: hidden;
    position: relative;
    padding-top: 17.5%;
  }
  .activeDelegators,
  .holders,
  .curatorCount,
  .subgraphCount {
    display: none;
  }
 
  .video {
    display: none;
  }
  .g-ytsubscribe {
    display: none;
  }
  .admin-video {
    position: absolute;
    left: 60%;
    top: 30%;
  }
  
  .newsGraph {
    display: none;
  }
 
  .center {
    margin: auto;
  }
  .line1 {
    display: none;
  }
 
  .chartImg6 {
    text-align: center;
    padding-top: 1rem;
    width: 100%;
  }
  .reveal {
    position: relative;
    transform: translateY(50px);
    opacity: 0;
    transition: 0.75s all ease;
  }
  .reveal.active {
    transform: translateY(0);
    opacity: 1;
  }
  .email {
    color: var(--hover-purple);
    font-weight: 500;
  }
  .footerLink {
    color: var(--hover-purple);
  }
  .footerLink:link {
    color: var(--hover-purple);
    cursor: pointer;
  }
  .footerLink:visited {
    color: var(--hover-purple);
    cursor: pointer;
  }
  .footerLink.active,
  .footerLink:focus {
    border-bottom: 0rem solid var(--hover-purple);
    cursor: pointer;
  }
  .footerLink:hover {
    color: var(--text-purple);
    transform: scale(1.05);
    cursor: pointer;
  }
  .footer {
    margin-bottom: -1rem;
    padding: 1rem;
    font-weight: 700;
    margin-top: 1rem;
    font-size: 1rem;
    text-align: center;
    background-color: var(--primary-purple);
    color: var(--secondary-purple);
  }
  .tsparticles {
    display: none;
  }
}
/* *************************************************************************
****************************************************************************
***************************************************************************/
/* **********************************414*428**************************************
****************************************************************************
***************************************************************************/

@media screen and (min-width: 392px) and (max-width: 506px) {
  .flashcards-title {
    margin-top: 120px!important;
    }
  .hide-line {
    color: transparent!important;
    display: block!important;
  }
  .Quiz-button {
    margin-bottom: 2rem;
  }
  .quiz-title {
    margin-top: -60px;
  }
  .quiz-button-position {
    padding-top: 2rem;
    margin: auto;
    text-align: center;
    margin-bottom: 2rem;
  }
  
  .final-exam-question {
      font-size: 1.3rem;
      text-align: center;
      color: #d8d2e4;
      /* remove for mobile */
      margin-top: 0rem;
      /* remove for mobile */
  }
  
  .final-exam-answer-form {
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin-top: 0rem;
    columns: 1!important;
    width: 100%!important;
  }
  
  .form-check-label {
    font-size: 20px;
    text-align: center;
    height: 110%;
  }
  .quiz-question {
    font-size: 1.3rem;
    padding-bottom: 2rem;
  text-align: center;
  width: 80%;
  margin: auto;
    text-align: center;
    color: #d8d2e4;
  }
  .quiz-question-container {
    margin-top: 20px;
    width: 100%;
  }

  .quiz-answer-form {
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin-top: 0rem;
    columns: 1!important;
    width: 100%!important;
  }

  .quiz-position {
    margin-top: -10px;
  }
  
  .mobile-landscape {
    display: none;
    }
  .monitor-line-carousel {
    display: none;
  }
  
  .monitor-bar-carousel {
   display: none;
  }
  
  .monitor-other-carousel {
    display: none;
  }
  
  .monitor-other-charts-position {
    display: none;
  }
  .mobile-bar-carousel {
    width: 80%;
  height: 500px;
  margin: auto auto;
  padding-top: -1rem;
  padding-bottom: -5rem;
  margin-bottom: -6rem;
  overflow: hidden;
  }
  .mobile-line-carousel {
    width: 80%;
    height: 500px;
    margin: auto auto;
    padding-top: 0rem;
    overflow: hidden;
  }

  .mobile-line-pos {
padding-top: 3rem;
  }
  .more-data-hr {
    margin-top: 1rem!important;
  }
  .more-data-title {
    padding-bottom: 2rem!important;
  }
  .mobile-other-carousel {
    width: 100%;
    height: 500px;
    margin: auto auto;
    margin-left: -0.9rem;
    padding-top: 1rem!important;
    overflow: hidden;
  }
  .map {
    width: 100%;
    margin-left: 5%;
    box-shadow: #4D1D86 4px 5px 10px 3px;
  }
  .holders-pos {
    margin-left: -5%;
    padding-bottom: 2rem;
  }
  .css-1fzpoyk {
    width: 100%;
    padding-top: 1rem;
  }

  .extra-data {
    
    font-size: 1.25rem;
    text-align: center;
    color: var(--text-purple);
  }
  .data-title {
    letter-spacing: 0.0375rem;
    font-weight: 400;
    color: var(--text-purple);
    font-size: 2rem;
    text-align: center;
    position: relative;
  }
  .chartsTitle {
    letter-spacing: 0.0375rem;
    font-weight: 400;
    color: var(--text-purple);
    font-size: 2rem;
    text-align: center;
    position: relative;
    margin-bottom: -13rem;
  }
  .chartsTitle2 {
    letter-spacing: 0.0375rem;
    font-weight: 400;
    color: var(--text-purple);
    font-size: 2rem;
    text-align: center;
    margin-bottom: -10rem;
  }
  .hr {
    border: 3px solid var(--hr);
    border-radius: 5px;
    width: 90%;
    margin: auto;
    box-shadow: 0 0 10px 3px var(--hover-purple);
    position: relative;
    margin-top: 7rem;
  }
  .hr2 {
    border: 3px solid var(--hr);
    border-radius: 5px;
    width: 90%;
    margin: auto;
    box-shadow: 0 0 10px 3px var(--hover-purple);
    position: relative;
    margin-top: 6rem;
  }
 
  .move-up {
    margin-top: -45rem;
  }
.hide-me {
  display: none;
}
  .css-1qzevvg img {
    height: 100%;
    margin-top: -6rem;
    text-align: center;
    margin-left: -1rem;
    z-index: 999999;
    position: relative;
}
.adminHeader-text {
  display: none;
}
.adminHeader-text2 {
 display: none;
}
.adminSubheader-text {
  display: none;
}
.adminSubheader-text2 {
  display: none;
}
.adminChartsTitle2 {
  display: none;
}
.hide-export {
  display: none;
}
/* above me */
  .data-link {
    color: grey;
  }
  .data-link:link {
    color: grey;
    cursor: pointer;
  }
  .data-link:visited {
    color: grey;
    cursor: pointer;
  }
  .data-link.active,
  .data-link:focus {
    border-bottom: 0rem solid white;
    cursor: pointer;
  }
  .data-link:hover {
    color: white;
    transform: scale(1.05);
    cursor: pointer;
  }
  .no-show {
    display: none!important;
  }
  .mobile-dropdown {
    margin-left: 0.5rem!important;
  }
  .news-title {
    display: none!important;
  }
  #root > div > div > div > div > div.react-chatbot-kit-chat-message-container > div:nth-child(3) > div.react-chatbot-kit-chat-bot-message > span > a > div {
    margin-left: 6.5px!important;  
  }
  .chatbot-link-logo{
    margin-top: 9rem;
    z-index: 9998;
    position: fixed;
    max-width: 4rem;
    background: white;
    -moz-border-radius: 50px;
    -webkit-border-radius: 50px;
    border-radius: 50px;
    padding: -1rem;
    right: 2%;
    border: 3px var(--secondary-purple) solid;
    box-shadow: 0 0 0 var(--hover-purple);
    animation: pulse 2s infinite;
  }
  .advocate-link-logo{
    margin-top: 14rem;
    z-index: 9998;
    position: fixed;
    max-width: 4rem;
    background: white;
    -moz-border-radius: 50px;
    -webkit-border-radius: 50px;
    border-radius: 50px;
    padding: -1rem;
    right: 2%;
    border: 3px var(--secondary-purple) solid;
    box-shadow: 0 0 0 var(--hover-purple);
    animation: pulse 2s infinite;
  }
  @-webkit-keyframes pulse {
    0% {
      -webkit-box-shadow: 0 0 0 0  var(--text-purple);
    }
    70% {
        -webkit-box-shadow: 0 0 0 15px rgba(244,157,22, 0);
    }
    100% {
        -webkit-box-shadow: 0 0 0 0 rgba(244,157,22, 0);
    }
  }
  @keyframes pulse {
    0% {
      -moz-box-shadow: 0 0 0 0  var(--text-purple);
      box-shadow: 0 0 0 0  var(--text-purple);
    }
    70% {
        -moz-box-shadow: 0 0 0 15px rgba(244,157,22, 0);
        box-shadow: 0 0 0 15px rgba(244,157,22, 0);
    }
    100% {
        -moz-box-shadow: 0 0 0 0 rgba(244,157,22, 0);
        box-shadow: 0 0 0 0 rgba(244,157,22, 0);
    }
  }
  
  .chatbot-link-export{
    margin-top: 6rem;
    z-index: 9998;
    position: fixed;
    max-width: 5rem;
    background: white;
    -moz-border-radius: 50px;
    -webkit-border-radius: 50px;
    border-radius: 50px;
    padding: -1rem;
    right: 39%;
    border: 3px var(--secondary-purple) solid;
    box-shadow: 0 0 0 var(--hover-purple);
    animation: pulse 2s infinite;
  }
/* Chatbot */
.feedback-text {
  margin-top: 2rem;
}
.feedback-button {
  border: 2px var(--primary-purple) solid!important;
  background-color: var(--text-purple)!important;
  color: var(--primary-purple)!important;
  padding: auto!important;
  margin: auto!important;
}

  .feedback-button:link {
    cursor: pointer;
  }
  .feedback-button:visited {
    cursor: pointer;
  }
  .feedback-button.active,
  .feedback-button:focus {
    cursor: pointer;
  }
  .feedback-button:hover {
    background-color: var(--background)!important;
    color: var(--text-purple)!important;
    transform: scale(1.015);
    cursor: pointer;
  }
  .feedback-button-text {
    font-size: 1.5rem;
  }

.react-chatbot-kit-chat-inner-container {
  background-color: var(--text-purple)!important;
}

.react-chatbot-kit-chat-header {
  font-size: 1.1rem!important;
  color: var(--text-purple)!important;
  background-color: var(--primary-purple)!important;
  padding: 0rem!important;
}

.react-chatbot-kit-chat-input-container {
  background-color:var(--secondary-purple)!important;
  border: 1px solid var(--primary-purple)!important;
}

.react-chatbot-kit-chat-btn-send {
  background-color:var(--primary-purple)!important;
}

.react-chatbot-kit-chat-btn-send-icon:hover {
transform: scale(1.25);
}

.react-chatbot-kit-chat-bot-message-container {
  width: 100%;
}

.react-chatbot-kit-chat-bot-avatar-container {
}

.react-chatbot-kit-chat-bot-avatar-icon {
}

.react-chatbot-kit-chat-bot-avatar-letter {
  display: none;
}
.react-chatbot-kit-chat-container {
  width: 98%!important;
  text-align: center;
  margin: auto;
}

.react-chatbot-kit-chat-bot-avatar-container {
  width: 3.5rem!important;
  height: 3.5rem!important;
  background-color: white!important;
  border: 2px solid var(--primary-purple);
  background-image: url('./GRTDataHubChatBot.png')!important;
  background-repeat: no-repeat!important;
  background-size: 3rem;
  background-position-x: 50%;
  background-position-y: 20%;
}

.react-chatbot-kit-chat-bot-message {
  text-align: left!important;
  width: 100%!important;
  color: var(--text-purple)!important;
  background-color: #0C0B23!important;
  font-size: 1rem!important;
}

.react-chatbot-kit-chat-bot-message-arrow {
  color: #0C0B23!important;
  border-right: 8px solid #0C0B23!important;
}
  .indexer-table-header {
    text-align: center;
    margin-left: 1rem;
  }
  .indexer-table {
    text-align: center;
  }
  .chatbot-data {
    display: inline!important;
  }
  .telegramActiveDelegationCount {
    max-width: 92%;
    margin: auto; 
    text-align: center;
    margin-top: 0.5%;
    }
    .top-telegram {
      margin-top: 0.25rem;
    }
    .chatbot-top {
      margin-top: 5rem;
    }
    .chatbot-page-container {
      background: var(--background);
      color: var(--text-purple);
      width: 100%;
      margin: auto;
      margin-top: 120px;
      margin-bottom: 20px;
      padding-left: 10px;
      padding-right: 10px;
      text-align: center;
    }
    .chatbot-text-container {
      background: whitesmoke;
      border-radius: 1rem;
      color: var(--secondary-purple);
      margin: auto!important;
      padding-bottom: 10px;
      padding-top: 10px;
      text-align: center!important;
    }
    .chatbot-page-title {
      font-size: 1.25rem;
      font-weight: 600;
      color: var(--secondary-purple);
      padding-bottom: 0.25rem;
      padding-left: 5px;
      padding-right: 5px;
    }
    .chatbot-page-subtitle {
      font-size: 1.75rem;
      padding-left: 5px;
      padding-right: 5px;
    }
    .chatbot-page-text {
      font-size: 1.25rem;
      margin-bottom: 1rem;
      margin-top: 1rem;
      padding-left: 10px;
      padding-right: 10px;
    }
    /* chatbot end */
  .dataHub-logos {
    display: none;
  }
  .monitor-config {
    display: none!important;
  }
  .hide-telegram {
    display: none!important;
  }
  body > div:nth-child(1) > button {
    display: none!important;
  }
  .mobile-nav-link {
    padding-right: 2rem!important;
  }
  .mobile-nav-pages {
    color: var(--hover-purple)!important;
    font-size: 1.5rem!important;
  }
  .mobile-nav-dropdown {
    margin-right: 2rem!important;
  }
  .mobile-nav-pages:link {
    cursor: pointer;
  }
  .mobile-nav-pages:visited {
    cursor: pointer;
  }
  .mobile-nav-pages.active,
  .mobile-nav-pages:focus {
    cursor: pointer;
    color: var(--text-purple)!important;
  }
  .mobile-nav-pages:hover {
    color: var(--text-purple)!important;
    cursor: pointer;
  }
  
  .offcanvas {
    width: 80%!important;
    background-color: var(--background)!important;
  }
  .offcanvas-body {
    width: 100%!important;
    margin-top: -3rem!important;
    opacity: 0.9;
  }
  .offcanvas-button {
    margin-right: 2rem;
    border: none!important;
  }
  .indexer-title {
    display: none;
   }
   .indexer-subtitle {
     display: none;
   }
   .indexer {
     display: none;
   }
   .curator {
    display: none;
  }
   .tokens, .rewards, .uncollected-rewards {
     display: none;
   }

   .c-map {
    display: none;
    }
 
    .d-map {
      display: none;
    }

   .rewards-per-hour {
     display: none;
   }
 
   .indexers {
     display: none;
   }
   .indexers:link{
     display: none;
   }
   .indexers:visited {
     display: none;
   }
   .indexers.active,
   .indexers:focus {
     display: none;
   }
   .indexers:hover {
     display: none;
   }
   .indexer-link {
     display: none;
   }
   .indexer-link:link {
     display: none;
   }
   .indexer-link:visited {
     display: none;
   }
   .indexer-link.active,
   .indexer-link:focus {
     display: none;
   }
   .indexer-link:hover {
     display: none;
   }
 
  .cookie-page-text {
    padding-top: 1rem;
    font-size: 1.25rem;
    margin-bottom: 1rem;
    padding-left: 20px;
    padding-right: 20px;
  }
  .cookies-video {
    display: none;
  }
  .cookie-button {
    display: none;
  }
  body > div:nth-child(1) > button {
    display: none;
  }
  body > div:nth-child(1) > button:hover {
    display: none;
  }
 
  .extra-data2 {
    font-size: 1.25rem;
    text-align: center;
    color: var(--text-purple);
   
  }
  .question-bank-container {
    background: var(--background);
    color: var(--text-purple);
    width: 100%;
    margin: auto;
    margin-top: 120px;
    margin-bottom: 20px;
    padding-left: 10px;
    padding-right: 10px;
    text-align: center;
  }
  .about-page-container {
    background: var(--background);
    color: var(--text-purple);
    width: 100%;
    margin: auto;
    margin-top: 150px;
    margin-bottom: 50px;
    padding-left: 50px;
    padding-right: 50px;
    text-align: center;
  }
  .QB-text-container {
    background: white;
    border-radius: 1rem;
    padding: 10px;
    color: var(--secondary-purple);
  }
  .text-container {
    display: none;
  }
  .QB-about-hr {
    border: 1.5px solid var(--background);
    color: var(--background);
    border-radius: 5px;
    width: 90%;
    margin: auto;
    margin-bottom: 1rem;
    box-shadow: 0 0 5px 1px var(--background);
  }
  .QB-about-page-title {
    font-size: 1.5rem;
    font-weight: 600;
    color: var(--secondary-purple);
    padding-bottom: 1.5rem;
  }
  .QB-about-page-subtitle {
    font-size: 1.75rem;
  }
  .QB-about-page-text {
    font-size: 1.rem;
    margin-bottom: 1rem;
  }
  .about-hr {
    display: none;
  }
  .about-page-title {
    display: none;
  }
  .about-page-subtitle {
    display: none;
  }
  .about-page-text {
    display: none;
  }
  .graphiql-link {
    color: var(--secondary-purple);
  }
  .header-sub-text {
    margin-bottom: 1rem;
  }
  .graphiql-link:link {
    color: var(--secondary-purple);
    cursor: pointer;
  }
  .graphiql-link:visited {
    color: var(--secondary-purple);
    cursor: pointer;
  }
  .graphiql-link.active,
  .graphiql-link:focus {
    border-bottom: 0rem solid var(--hover-purple);
    cursor: pointer;
  }
  .graphiql-link:hover {
    color: var(--primary-purple);
    transform: scale(1.05);
    cursor: pointer;
  }
  .cookies-policy-container {
    background: white;
    width: 90%;
    padding-bottom: 1rem;
    margin: auto;
    border-radius: 2rem;
    margin-top: 150px;
    margin-bottom: 50px;
  }
  .privacy-policy-container {
    background: white;
    width: 90%;
    padding-bottom: 1rem;
    margin: auto;
    border-radius: 2rem;
    margin-top: 150px;
    margin-bottom: 50px;
  }
  .privacy-policy {
    margin-top: 110px;
    font-weight: 700;
    font-size: 1.1rem;
    text-align: center;
    background-color: var(--background);
    color: var(--secondary-purple);
  }
  .privacy-policy-link,
  .about {
    padding-left: 1rem;
    padding-left: 1rem;
    color: grey;
  }
  .privacy-policy-link:link,
  .about:link {
    color: grey;
    cursor: pointer;
  }
  .privacy-policy-link:visited,
  .about:visited {
    color: grey;
    cursor: pointer;
  }
  .privacy-policy-link.active,
  .privacy-policy-link:focus,
  .about.active,
  .about:focus {
    border-bottom: 0rem solid var(--hover-purple);
    cursor: pointer;
  }
  .privacy-policy-link:hover,
  .about:hover {
    color: var(--text-purple);
    transform: scale(1.05);
    cursor: pointer;
  }
  .rotate-position,
  .about-rotate-position {
    margin-top: 6rem;
    padding-top: 2rem;
  }
  .phone,
  .about-phone {
    height: 50px;
    padding-top: 10rem;
    width: 100px;
    border: 3px solid white;
    border-radius: 10px;
    animation: rotate 1.5s ease-in-out infinite alternate;
    text-align: center;
    margin: auto;
  }
  .message,
  .about-message {
    color: var(--text-purple);
    text-shadow: 2.5px 2.5px 4px var(--hover-purple);
    font-weight: 400;
    font-size: 1.25rem;
    padding-top: 1rem;
    padding-left: 1rem;
    padding-right: 1rem;
    text-align: center;
    margin: auto;
  }
  .home-message {
    color: var(--text-purple);
    text-shadow: 2.5px 2.5px 4px var(--hover-purple);
    font-weight: 400;
    font-size: 1.25rem;
    padding-top: 3rem;
    padding-bottom: 2rem;
    padding-left: 1rem;
    padding-right: 1rem;
    text-align: center;
    margin: auto;
  }
  @keyframes rotate {
    0% {
      transform: rotate(0deg);
    }
    50% {
      transform: rotate(-90deg);
    }
    100% {
      transform: rotate(-90deg);
    }
  }
  .subscribeNote {
    display: none;
  }
  /* loader new start  */
  .loader:empty {
    position: absolute;
    top: calc(50% + 15.5rem);
    left: calc(50% + 2em);
    width: 4em;
    height: 4em;
    border: 0.35em solid white;
    border-left: 0.35em solid #10034B;
    border-radius: 50%;
    animation: load8 0.8s infinite linear;
  }
  .loadingTitle {
    position: absolute;
    top: 27rem;
    left: calc(50% - 3em);
    font-size: 2rem;
    color: #10034B;
    -webkit-box-reflect:below 0px linear-gradient(transparent, #0005);
  }
  .loaderLogo {
    position: absolute;
    top: 14rem;
    left: calc(50% - 8em);
    width: 15em;
    height: 12em;
  }
  @keyframes load8 {
    0% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(360deg);
    }
  }
  /* loader new end  */
  /* New logo start */
  .logo {
    width: 8rem;
    height: 6rem;
    margin-left: 1rem;
    margin-top: -0.5rem;
  }
  .data-logo {
    max-width: 1.25rem !important;
    height: 1.25rem !important;
    position: absolute;
  }
  .brand-text {
    color: var(--text-purple);
    display: inline;
    margin-left: -0.5rem;
    letter-spacing: 0.0375rem;
    font-size: 1.25rem;
  }
   /* New logo end */
  .header-text {
    font-weight: 400;
    font-size: 1.5rem;
    padding-top: 1rem;
    text-align: center;
    margin-top: 105px;
    color: var(--text-purple);

  }
  .header-sub-text {
    font-weight: 400;
    font-size: 1rem;
    color: var(--text-purple);
    text-align: center!important;
    margin: auto!important;
    display: block;
    width: 90%;
    text-align: center;
   
  }
  .navbar-toggler {
    display: none;
  }
  nav a.TGN {
    display: none;
  }
  .navbar-nav > li > a.TGN:link {
    display: none;
  }
  .navbar-nav > li > a.TGN:visited {
    display: none;
  }
  .navbar-nav > li > a.TGN.active,
  .navbar-nav > li > a.TGN:focus {
    display: none;
  }
  .navbar-nav > li > a.TGN:hover {
    display: none;
  }
  nav a.nav-link {
    font-weight: 600;
    font-size: 1.15rem;
    color: var(--text-purple);
  }
  .navbar-nav > li > a {
    border-bottom: 0.01875rem solid var(--transparent);
    padding: 0;
  }
  .navbar-nav > li > a:link {
    color: var(--hover-purple);
  }
  .navbar-nav > li > a:visited {
    color: var(--text-purple);
  }
  .navbar-nav > li > a.active,
  .navbar-nav > li > a:focus {
    border-bottom: 0.01875rem solid var(--hover-purple);
    padding: 0;
    transition: all 0.5s ease-in-out;
  }
  .navbar-nav > li > a:hover {
    border-bottom: 0.01875rem solid var(--hover-purple);
    padding: 0;
    color: var(--hover-purple);
    transform: scale(1.05);
  }
  .line {
    margin-left: 1rem;
  }
  .links {
    text-align: right;
    padding-right: 0;
    margin: auto;
    padding: auto;
  }
  .dropdown {
    margin-left: auto;
    padding: auto;
  }
  .bar {
    margin-bottom: 0.1rem;
    margin-right: 2rem;
  }
  .line {
    margin-bottom: 0.1rem;
    margin-right: 2rem;
  }
  .about {
    margin-left: auto;
    margin-right: 0.8rem;
  }
  .menu-icon {
    color: var(--secondary-purple);
  }
  .main-info {
    background-image: url("TopBackground.svg");
    background-repeat: no-repeat;
    background-size: 100% auto;
    width: 100%;
    height: 100%;
    margin-top: 90px;
  }
  .community-links {
    text-align: center;
    padding-top: 4rem;
    position: absolute;
    z-index: 999;
    left: 18%;
  }
  .telegram-icon,
  .twitter-icon,
  .reddit-icon,
  .youtube-icon {
    display: inline;
    padding: 1rem;
    transition: all 0.5s ease-in-out;
    z-index: 999;
  }
  .icon:hover {
    color: var(--hover-purple);
    transform: scale(1.15);
  }
  .icon-youtube:hover {
    color: var(--text-purple);
    transform: scale(1.1);
  }
  .news-title {
    text-shadow: 2.25px 2.25px 3px var(--hover-purple);
    color: var(--text-purple);
    text-align: center;
    margin-top: 2rem!important;
    text-align: center!important;
    margin: auto!important;
    margin-bottom: 1rem;
    font-size: 1rem;
  }
  .newsGraph1,
  .newsGraph2,
  .newsGraph3,
  .newsGraph4 {
    display: none;
  }
  .youtube {
    z-index: 999;
    display: inline;
    position: absolute;
    text-align: center;
    color: var(--text-purple);
  }
  span.anchor {
    display: block;
    visibility: hidden;
    position: relative;
    padding-top: 17.5%;
  }
  .activeDelegators,
  .holders,
  .curatorCount,
  .subgraphCount {
    display: none;
  }
 
  .video {
    display: none;
  }
  .g-ytsubscribe {
    display: none;
  }
  .admin-video {
    position: absolute;
    left: 60%;
    top: 30%;
  }
 
  .newsGraph {
    display: none;
  }
 
  .center {
    margin: auto;
  }
  .line1 {
    display: none;
  }
 
  .chartImg6 {
    text-align: center;
    padding-top: 1rem;
    width: 100%;
  }
  .reveal {
    position: relative;
    transform: translateY(50px);
    opacity: 0;
    transition: 0.75s all ease;
  }
  .reveal.active {
    transform: translateY(0);
    opacity: 1;
  }
  .email {
    color: var(--hover-purple);
    font-weight: 500;
  }
  .footerLink {
    color: var(--hover-purple);
  }
  .footerLink:link {
    color: var(--hover-purple);
    cursor: pointer;
  }
  .footerLink:visited {
    color: var(--hover-purple);
    cursor: pointer;
  }
  .footerLink.active,
  .footerLink:focus {
    border-bottom: 0rem solid var(--hover-purple);
    cursor: pointer;
  }
  .footerLink:hover {
    color: var(--text-purple);
    transform: scale(1.05);
    cursor: pointer;
  }
  .footer {
    margin-bottom: -1rem;
    padding: 1rem;
    font-weight: 700;
    margin-top: 1rem;
    font-size: 1rem;
    text-align: center;
    background-color: var(--primary-purple);
    color: var(--secondary-purple);
  }
  .tsparticles {
    display: none;
  }
}
/* *************************************************************************
****************************************************************************
***************************************************************************/
/* ***********************************390**************************************
****************************************************************************
***************************************************************************/
@media screen and (min-width: 390px) and (max-width: 391px) {
  .flashcards-title {
    margin-top: 120px!important;
    }
  .hide-line {
    color: transparent!important;
    display: block!important;
  }
  .Quiz-button {
    margin-bottom: 2rem;
  }
  .quiz-title {
    margin-top: -60px;
  }
  .quiz-button-position {
    padding-top: 2rem;
    margin: auto;
    text-align: center;
    margin-bottom: 2rem;
  }
  
  .final-exam-question {
      font-size: 1.3rem;
      text-align: center;
      color: #d8d2e4;
      /* remove for mobile */
      margin-top: 0rem;
      /* remove for mobile */
  }
  
  .final-exam-answer-form {
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin-top: 0rem;
    columns: 1!important;
    width: 100%!important;
  }
  
  .form-check-label {
    font-size: 20px;
    text-align: center;
    height: 110%;
  }
  .quiz-question {
    font-size: 1.3rem;
    padding-bottom: 2rem;
  text-align: center;
  width: 80%;
  margin: auto;
    text-align: center;
    color: #d8d2e4;
  }
  .quiz-question-container {
    margin-top: 20px;
    width: 100%;
  }

  .quiz-answer-form {
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin-top: 0rem;
    columns: 1!important;
    width: 100%!important;
  }

  .quiz-position {
    margin-top: -10px;
  }
  
  .mobile-landscape {
    display: none;
    }
  .monitor-line-carousel {
    display: none;
  }
  
  .monitor-bar-carousel {
   display: none;
  }
  
  .monitor-other-carousel {
    display: none;
  }
  
  .monitor-other-charts-position {
    display: none;
  }
  .mobile-bar-carousel {
    width: 85%;
  height: 500px;
  margin: auto auto;
  padding-top: -1rem;
  padding-bottom: -5rem;
  margin-bottom: -6rem;
  overflow: hidden;
  }
  .mobile-line-carousel {
    width: 85%;
    height: 500px;
    margin: auto auto;
    padding-top: 0rem;
    overflow: hidden;
  }

  .mobile-line-pos {
padding-top: 3rem;
  }
  .more-data-hr {
    margin-top: 1rem!important;
  }
  .more-data-title {
    padding-bottom: 2rem!important;
  }
  .mobile-other-carousel {
    width: 100%;
    height: 500px;
    margin: auto auto;
    margin-left: -0.8rem;
    padding-top: 1rem!important;
    overflow: hidden;
  }
  .map {
    width: 100%;
    margin-left: 5%;
    box-shadow: #4D1D86 4px 5px 10px 3px;
  }
  .holders-pos {
    margin-left: -5%;
    padding-bottom: 2rem;
  }
  .css-1fzpoyk {
    width: 100%;
    padding-top: 1rem;
  }

  .extra-data {
    
    font-size: 1.25rem;
    text-align: center;
    color: var(--text-purple);
  }
  .data-title {
    letter-spacing: 0.0375rem;
    font-weight: 400;
    color: var(--text-purple);
    font-size: 2rem;
    text-align: center;
    position: relative;
  }
  .chartsTitle {
    letter-spacing: 0.0375rem;
    font-weight: 400;
    color: var(--text-purple);
    font-size: 2rem;
    text-align: center;
    position: relative;
    margin-bottom: -13rem;
  }
  .chartsTitle2 {
    letter-spacing: 0.0375rem;
    font-weight: 400;
    color: var(--text-purple);
    font-size: 2rem;
    text-align: center;
    margin-bottom: -10rem;
  }
  .hr {
    border: 3px solid var(--hr);
    border-radius: 5px;
    width: 90%;
    margin: auto;
    box-shadow: 0 0 10px 3px var(--hover-purple);
    position: relative;
    margin-top: 7rem;
  }
  .hr2 {
    border: 3px solid var(--hr);
    border-radius: 5px;
    width: 90%;
    margin: auto;
    box-shadow: 0 0 10px 3px var(--hover-purple);
    position: relative;
    margin-top: 6rem;
  }
 
  .move-up {
    margin-top: -45rem;
  }
.hide-me {
  display: none;
}
  .css-1qzevvg img {
    height: 100%;
    margin-top: -6rem;
    text-align: center;
    margin-left: -1rem;
    z-index: 999999;
    position: relative;
}
.adminHeader-text {
  display: none;
}
.adminHeader-text2 {
 display: none;
}
.adminSubheader-text {
  display: none;
}
.adminSubheader-text2 {
  display: none;
}
.adminChartsTitle2 {
  display: none;
}
.hide-export {
  display: none;
}
/* above me */
  .data-link {
    color: grey;
  }
  .data-link:link {
    color: grey;
    cursor: pointer;
  }
  .data-link:visited {
    color: grey;
    cursor: pointer;
  }
  .data-link.active,
  .data-link:focus {
    border-bottom: 0rem solid white;
    cursor: pointer;
  }
  .data-link:hover {
    color: white;
    transform: scale(1.05);
    cursor: pointer;
  }
  .no-show {
    display: none!important;
  }
  .mobile-dropdown {
    margin-left: 0rem!important;
  }
  .graphtronauts-link {
    color: var(--hr);
    z-index: 999;
    position: absolute;
    display: block;
    text-align: center!important;
    left: 25%!important;
  }
  .graphtronauts-link:link {
    color: var(--hr);
    cursor: pointer;
  }
  .graphtronauts-link:visited {
    color: var(--hr);
    cursor: pointer;
  }
  .graphtronauts-link.active, graphtronauts-link:focus {
    border-bottom: 0rem solid var(--hr);;
    cursor: pointer;
  }
  .graphtronauts-link:hover {
    color: var(--hr);
    transform: scale(1.025);
    cursor: pointer;
  }
  .chatbot-footer {
    display:none;
  }
  #root > div > div > div > div > div.react-chatbot-kit-chat-message-container > div:nth-child(3) > div.react-chatbot-kit-chat-bot-message > span > a > div {
    margin-left: -5.5px!important;
  }
  .chatbot-link-logo{
    margin-top: 8rem;
    z-index: 9998;
    position: fixed;
    max-width: 4rem;
    background: white;
    -moz-border-radius: 50px;
    -webkit-border-radius: 50px;
    border-radius: 50px;
    padding: -1rem;
    right: 2%;
    border: 3px var(--secondary-purple) solid;
    box-shadow: 0 0 0 var(--hover-purple);
    animation: pulse 2s infinite;
  }
  .advocate-link-logo{
    margin-top: 14rem;
    z-index: 9998;
    position: fixed;
    max-width: 4rem;
    background: white;
    -moz-border-radius: 50px;
    -webkit-border-radius: 50px;
    border-radius: 50px;
    padding: -1rem;
    right: 2%;
    border: 3px var(--secondary-purple) solid;
    box-shadow: 0 0 0 var(--hover-purple);
    animation: pulse 2s infinite;
  }
  @-webkit-keyframes pulse {
    0% {
      -webkit-box-shadow: 0 0 0 0  var(--text-purple);
    }
    70% {
        -webkit-box-shadow: 0 0 0 15px rgba(244,157,22, 0);
    }
    100% {
        -webkit-box-shadow: 0 0 0 0 rgba(244,157,22, 0);
    }
  }
  @keyframes pulse {
    0% {
      -moz-box-shadow: 0 0 0 0  var(--text-purple);
      box-shadow: 0 0 0 0  var(--text-purple);
    }
    70% {
        -moz-box-shadow: 0 0 0 15px rgba(244,157,22, 0);
        box-shadow: 0 0 0 15px rgba(244,157,22, 0);
    }
    100% {
        -moz-box-shadow: 0 0 0 0 rgba(244,157,22, 0);
        box-shadow: 0 0 0 0 rgba(244,157,22, 0);
    }
  }
  
  .chatbot-link-export{
    margin-top: 6rem;
    z-index: 9998;
    position: fixed;
    max-width: 5rem;
    background: white;
    -moz-border-radius: 50px;
    -webkit-border-radius: 50px;
    border-radius: 50px;
    padding: -1rem;
    left: 39%;
    border: 3px var(--secondary-purple) solid;
    box-shadow: 0 0 0 var(--hover-purple);
    animation: pulse 2s infinite;
  }

   /* Chatbot */
.feedback-text {
  margin-top: 2rem;
}
.feedback-button {
  border: 2px var(--primary-purple) solid!important;
  background-color: var(--text-purple)!important;
  color: var(--primary-purple)!important;
  padding: auto!important;
  margin: auto!important;
}

  .feedback-button:link {
    cursor: pointer;
  }
  .feedback-button:visited {
    cursor: pointer;
  }
  .feedback-button.active,
  .feedback-button:focus {
    cursor: pointer;
  }
  .feedback-button:hover {
    background-color: var(--background)!important;
    color: var(--text-purple)!important;
    transform: scale(1.015);
    cursor: pointer;
  }
  .feedback-button-text {
    font-size: 1.5rem;
  }

.react-chatbot-kit-chat-inner-container {
  background-color: var(--text-purple)!important;
}

.react-chatbot-kit-chat-header {
  font-size: 1.1rem!important;
  color: var(--text-purple)!important;
  background-color: var(--primary-purple)!important;
  padding: 0rem!important;
}

.react-chatbot-kit-chat-input-container {
  background-color:var(--secondary-purple)!important;
  border: 1px solid var(--primary-purple)!important;
}

.react-chatbot-kit-chat-btn-send {
  background-color:var(--primary-purple)!important;
}

.react-chatbot-kit-chat-btn-send-icon:hover {
transform: scale(1.25);
}

.react-chatbot-kit-chat-bot-message-container {
  width: 100%;
}

.react-chatbot-kit-chat-bot-avatar-container {
}

.react-chatbot-kit-chat-bot-avatar-icon {
}

.react-chatbot-kit-chat-bot-avatar-letter {
  display: none;
}
.react-chatbot-kit-chat-container {
  width: 98%!important;
  text-align: center;
  margin: auto;
}

.react-chatbot-kit-chat-bot-avatar-container {
  width: 3.5rem!important;
  height: 3.5rem!important;
  background-color: white!important;
  border: 2px solid var(--primary-purple);
  background-image: url('./GRTDataHubChatBot.png')!important;
  background-repeat: no-repeat!important;
  background-size: 3rem;
  background-position-x: 50%;
  background-position-y: 20%;
}

.react-chatbot-kit-chat-bot-message {
  text-align: left!important;
  width: 100%!important;
  color: var(--text-purple)!important;
  background-color: #0C0B23!important;
  font-size: 1rem!important;

}

.react-chatbot-kit-chat-bot-message-arrow {
  color: #0C0B23!important;
  border-right: 8px solid #0C0B23!important;
}
  .indexer-table-header {
    text-align: center;
    margin-left: 1rem;
  }
  .indexer-table {
    text-align: center;
  }
  .chatbot-data {
    display: inline!important;
  }
  .telegramActiveDelegationCount {
    max-width: 92%;
    margin: auto; 
    text-align: center;
    margin-top: 0.5%;
    }
    .top-telegram {
      margin-top: 0.25rem;
    }
    .chatbot-top {
      margin-top: 5rem;
    }
    .chatbot-page-container {
      background: var(--background);
      color: var(--text-purple);
      width: 100%;
      margin: auto;
      margin-top: 120px;
      margin-bottom: 20px;
      padding-left: 10px;
      padding-right: 10px;
      text-align: center;
    }
    .chatbot-text-container {
      background: whitesmoke;
      border-radius: 1rem;
      color: var(--secondary-purple);
      margin: auto!important;
      padding-bottom: 10px;
      padding-top: 10px;
      text-align: center!important;
    }
    .chatbot-page-title {
      font-size: 1.25rem;
      font-weight: 600;
      color: var(--secondary-purple);
      padding-bottom: 0.25rem;
      padding-left: 5px;
      padding-right: 5px;
    }
    .chatbot-page-subtitle {
      font-size: 1.75rem;
      padding-left: 5px;
      padding-right: 5px;
    }
    .chatbot-page-text {
      font-size: 1.25rem;
      margin-bottom: 1rem;
      margin-top: 1rem;
      padding-left: 10px;
      padding-right: 10px;
    }
    /* chatbot end */
  .dataHub-logos {
   display: none;
  }
  .monitor-config {
    display: none!important;
  }
  .hide-telegram {
    display: none!important;
  }
  body > div:nth-child(1) > button {
    display: none!important;
  }
  .mobile-nav-link {
    padding-right: 2rem!important;
  }
  .mobile-nav-pages {
    color: var(--hover-purple)!important;
    font-size: 1.5rem!important;
  }
  .mobile-nav-dropdown {
    margin-right: 2rem!important;
  }
  .mobile-nav-pages:link {
    cursor: pointer;
  }
  .mobile-nav-pages:visited {
    cursor: pointer;
  }
  .mobile-nav-pages.active,
  .mobile-nav-pages:focus {
    cursor: pointer;
    color: var(--text-purple)!important;
  }
  .mobile-nav-pages:hover {
    color: var(--text-purple)!important;
    cursor: pointer;
  }
  
  .offcanvas {
    width: 80%!important;
    background-color: var(--background)!important;
  }
  .offcanvas-body {
    width: 100%!important;
    margin-top: -3rem!important;
    opacity: 0.9;
  }
  .offcanvas-button {
    margin-right: 2rem;
    border: none!important;
  }
  .indexer-title {
   display: none;
  }
  .indexer-subtitle {
    display: none;
  }
  .indexer {
    display: none;
  }
  .curator {
    display: none;
  }
  .tokens, .rewards, .uncollected-rewards {
    display: none;
  }

  .c-map {
    display: none;
    }

    .d-map {
      display: none;
    }

  .rewards-per-hour {
    display: none;
  }

  .indexers {
    display: none;
  }
  .indexers:link{
    display: none;
  }
  .indexers:visited {
    display: none;
  }
  .indexers.active,
  .indexers:focus {
    display: none;
  }
  .indexers:hover {
    display: none;
  }
  .indexer-link {
    display: none;
  }
  .indexer-link:link {
    display: none;
  }
  .indexer-link:visited {
    display: none;
  }
  .indexer-link.active,
  .indexer-link:focus {
    display: none;
  }
  .indexer-link:hover {
    display: none;
  }

  .cookie-page-text {
    padding-top: 1rem;
    font-size: 1.25rem;
    margin-bottom: 1rem;
    padding-left: 20px;
    padding-right: 20px;
  }
  .cookies-video {
    display: none;
  }
  .cookie-button {
    display: none;
  }
  body > div:nth-child(1) > button {
    display: none;
  }
  body > div:nth-child(1) > button:hover {
    display: none;
  }

  .extra-data2 {
    font-size: 1.25rem;
    text-align: center;
    color: var(--text-purple);
   
  }
  .question-bank-container {
    background: var(--background);
    color: var(--text-purple);
    width: 100%;
    margin: auto;
    margin-top: 120px;
    margin-bottom: 20px;
    padding-left: 10px;
    padding-right: 10px;
    text-align: center;
  }
  .about-page-container {
    background: var(--background);
    color: var(--text-purple);
    width: 100%;
    margin: auto;
    margin-top: 150px;
    margin-bottom: 50px;
    padding-left: 50px;
    padding-right: 50px;
    text-align: center;
  }
  .QB-text-container {
    background: white;
    border-radius: 1rem;
    padding: 10px;
    color: var(--secondary-purple);
  }
  .text-container {
    display: none;
  }
  .QB-about-hr {
    border: 1.5px solid var(--background);
    color: var(--background);
    border-radius: 5px;
    width: 90%;
    margin: auto;
    margin-bottom: 1rem;
    box-shadow: 0 0 5px 1px var(--background);
  }
  .QB-about-page-title {
    font-size: 1.5rem;
    font-weight: 600;
    color: var(--secondary-purple);
    padding-bottom: 1.5rem;
  }
  .QB-about-page-subtitle {
    font-size: 1.75rem;
  }
  .QB-about-page-text {
    font-size: 1.rem;
    margin-bottom: 1rem;
  }
  .about-page-title {
    display: none;
  }
  .about-page-subtitle {
    display: none;
  }
  .about-page-text {
    display: none;
  }
  .graphiql-link {
    color: var(--secondary-purple);
  }
  .graphiql-link:link {
    color: var(--secondary-purple);
    cursor: pointer;
  }
  .graphiql-link:visited {
    color: var(--secondary-purple);
    cursor: pointer;
  }
  .graphiql-link.active,
  .graphiql-link:focus {
    border-bottom: 0rem solid var(--hover-purple);
    cursor: pointer;
  }
  .graphiql-link:hover {
    color: var(--primary-purple);
    transform: scale(1.05);
    cursor: pointer;
  }
  .cookies-policy-container {
    background: white;
    width: 90%;
    padding-bottom: 1rem;
    margin: auto;
    border-radius: 2rem;
    margin-top: 150px;
    margin-bottom: 50px;
  }

  .privacy-policy-container {
    background: white;
    width: 90%;
    padding-bottom: 1rem;
    margin: auto;
    border-radius: 2rem;
    margin-top: 150px;
    margin-bottom: 50px;
  }
  .privacy-policy {
    margin-top: 110px;
    font-weight: 700;
    font-size: 1.1rem;
    text-align: center;
    background-color: var(--background);
    color: var(--secondary-purple);
  }
  .privacy-policy-link,
  .about {
    padding-left: 1rem;
    padding-left: 1rem;
    color: grey;
  }
  .privacy-policy-link:link,
  .about:link {
    color: grey;
    cursor: pointer;
  }
  .privacy-policy-link:visited,
  .about:visited {
    color: grey;
    cursor: pointer;
  }
  .privacy-policy-link.active,
  .privacy-policy-link:focus,
  .about.active,
  .about:focus {
    border-bottom: 0rem solid var(--hover-purple);
    cursor: pointer;
  }
  .privacy-policy-link:hover,
  .about:hover {
    color: var(--text-purple);
    transform: scale(1.05);
    cursor: pointer;
  }
  .subscribeNote {
    display: none;
  }
  /* loader new start  */
  .loader:empty {
    position: absolute;
    top: calc(50% + 15.5rem);
    left: calc(50% + 2em);
    width: 4em;
    height: 4em;
    border: 0.35em solid white;
    border-left: 0.35em solid #10034B;
    border-radius: 50%;
    animation: load8 0.8s infinite linear;
  }
  .loadingTitle {
    position: absolute;
    top: 27rem;
    left: calc(50% - 3em);
    font-size: 2rem;
    color: #10034B;
    -webkit-box-reflect:below 0px linear-gradient(transparent, #0005);
  }
  .loaderLogo {
    position: absolute;
    top: 14rem;
    left: calc(50% - 8em);
    width: 15em;
    height: 12em;
  }
  @keyframes load8 {
    0% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(360deg);
    }
  }
  /* loader new end  */

  /* New logo start */
  .logo {
    width: 7rem;
    height: 6rem;
    margin-left: 1rem;
    margin-top: -0.5rem;
  }
  .data-logo {
    max-width: 1.25rem !important;
    height: 1.25rem !important;
    position: absolute;
  }
  .brand-text {
    color: var(--text-purple);
    display: inline;
    margin-left: -0.5rem;
    letter-spacing: 0.0375rem;
    font-size: 1.25rem;
  }
   /* New logo end */
  .header-text {
    font-weight: 400;
    font-size: 1.5rem;
    padding-top: 1rem;
    text-align: center;
    margin-top: 105px;
    color: var(--text-purple);
  
  }
  .header-sub-text {
    font-weight: 400;
    font-size: 1rem;
    text-align: center!important;
    width: 90%;
    margin: auto!important;
    color: var(--text-purple);
    display: block;
    text-align: center;

  }
  .navbar-toggler {
    display: none;
  }
  nav a.TGN {
    display: none;
  }
  .navbar-nav > li > a.TGN:link {
    display: none;
  }
  .navbar-nav > li > a.TGN:visited {
    display: none;
  }
  .navbar-nav > li > a.TGN.active,
  .navbar-nav > li > a.TGN:focus {
    display: none;
  }
  .navbar-nav > li > a.TGN:hover {
    display: none;
  }
  nav a.nav-link {
    font-weight: 600;
    font-size: 1.15rem;
    color: var(--text-purple);
  }
  .navbar-nav > li > a {
    border-bottom: 0.01875rem solid var(--transparent);
    padding: 0;
  }
  .navbar-nav > li > a:link {
    color: var(--hover-purple);
  }
  .navbar-nav > li > a:visited {
    color: var(--text-purple);
  }
  .navbar-nav > li > a.active,
  .navbar-nav > li > a:focus {
    border-bottom: 0.01875rem solid var(--hover-purple);
    padding: 0;
    transition: all 0.5s ease-in-out;
  }
  .navbar-nav > li > a:hover {
    border-bottom: 0.01875rem solid var(--hover-purple);
    padding: 0;
    color: var(--hover-purple);
    transform: scale(1.05);
  }
  .line {
    margin-left: 1rem;
  }
  .links {
    text-align: right;
    padding-right: 0;
    margin: auto;
    padding: auto;
  }
  .dropdown {
    margin-left: auto;
    padding: auto;
  }
  .bar {
    margin-bottom: 0.25rem;
    margin-right: 2rem;
  }
  .line {
    margin-bottom: 0.25rem;
    margin-right: 2rem;
  }
  .about {
    margin-left: auto;
    margin-right: 0.8rem;
  }
  .rotate-position,
  .about-rotate-position {
    margin-top: 6rem;
    padding-top: 2rem;
  }
  .phone,
  .about-phone {
    height: 50px;
    padding-top: 10rem;
    width: 100px;
    border: 3px solid white;
    border-radius: 10px;
    animation: rotate 1.5s ease-in-out infinite alternate;
    text-align: center;
    margin: auto;
  }
  .message,
  .about-message {
    color: var(--text-purple);
    text-shadow: 2.5px 2.5px 4px var(--hover-purple);
    font-weight: 400;
    font-size: 1.25rem;
    padding-top: 1rem;
    padding-left: 1rem;
    padding-right: 1rem;
    text-align: center;
    margin: auto;
  }
  .home-message {
    color: var(--text-purple);
    text-shadow: 2.5px 2.5px 4px var(--hover-purple);
    font-weight: 400;
    font-size: 1.25rem;
    padding-top: 3rem;
    padding-bottom: 2rem;
    padding-left: 1rem;
    padding-right: 1rem;
    text-align: center;
    margin: auto;
  }
  @keyframes rotate {
    0% {
      transform: rotate(0deg);
    }
    50% {
      transform: rotate(-90deg);
    }
    100% {
      transform: rotate(-90deg);
    }
  }
  .menu-icon {
    color: var(--secondary-purple);
  }
  .main-info {
    background-image: url("TopBackground.svg");
    background-repeat: no-repeat;
    background-size: 100% auto;
    width: 100%;
    height: 100%;
    margin-top: 90px;
  }
  .community-links {
    text-align: center;
    padding-top: 4rem;
    position: absolute;
    z-index: 999;
    left: 18%;
  }
  .telegram-icon,
  .twitter-icon,
  .reddit-icon,
  .youtube-icon {
    display: inline;
    padding: 1rem;
    transition: all 0.5s ease-in-out;
    z-index: 999;
  }
  .icon:hover {
    color: var(--hover-purple);
    transform: scale(1.15);
  }
  .icon-youtube:hover {
    color: var(--text-purple);
    transform: scale(1.1);
  }
  .news-title {
    display: none!important;
  }
  .newsGraph1,
  .newsGraph2,
  .newsGraph3,
  .newsGraph4 {
    display: none;
  }
  .youtube {
    z-index: 999;
    display: inline;
    position: absolute;
    text-align: center;
    color: var(--text-purple);
  }
  span.anchor {
    display: block;
    visibility: hidden;
    position: relative;
    padding-top: 17.5%;
  }
  .activeDelegators,
  .holders,
  .curatorCount,
  .subgraphCount {
    display: none;
  }

  .video {
    display: none;
  }
  .g-ytsubscribe {
    display: none;
  }
 
  .newsGraph {
    display: none;
  }
 
  .center {
    margin: auto;
  }
  .line1 {
    display: none;
  }
 
  .chartImg6 {
    text-align: center;
    padding-top: 1rem;
    width: 100%;
  }
  .reveal {
    position: relative;
    transform: translateY(50px);
    opacity: 0;
    transition: 0.75s all ease;
  }
  .reveal.active {
    transform: translateY(0);
    opacity: 1;
  }
  .email {
    color: var(--hover-purple);
    font-weight: 500;
  }
  .footerLink {
    color: var(--hover-purple);
  }
  .footerLink:link {
    color: var(--hover-purple);
    cursor: pointer;
  }
  .footerLink:visited {
    color: var(--hover-purple);
    cursor: pointer;
  }
  .footerLink.active,
  .footerLink:focus {
    border-bottom: 0rem solid var(--hover-purple);
    cursor: pointer;
  }
  .footerLink:hover {
    color: var(--text-purple);
    transform: scale(1.05);
    cursor: pointer;
  }
  .footer {
    margin-bottom: -1rem;
    padding: 1rem;
    font-weight: 700;
    margin-top: 1rem;
    font-size: 1rem;
    text-align: center;
    background-color: var(--primary-purple);
    color: var(--secondary-purple);
  }
  .tsparticles {
    display: none;
  }
}
/* *************************************************************************
****************************************************************************
***************************************************************************/
/* *********************************375***************************************
****************************************************************************
***************************************************************************/
@media screen and (min-width: 361px) and (max-width: 389px) {
  .flashcards-title {
    margin-top: 120px!important;
    }
  .hide-line {
    color: transparent!important;
    display: block!important;
  }
  .Quiz-button {
    margin-bottom: 2rem;
  }
  .quiz-title {
    margin-top: -60px;
  }
   .quiz-button-position {
    padding-top: 2rem;
    margin: auto;
    text-align: center;
    margin-bottom: 2rem;
  }
  
  .final-exam-question {
      font-size: 1.3rem;
      text-align: center;
      color: #d8d2e4;
      /* remove for mobile */
      margin-top: 0rem;
      /* remove for mobile */
  }
  
  .final-exam-answer-form {
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin-top: 0rem;
    columns: 1!important;
    width: 100%!important;
  }
  
  .form-check-label {
    font-size: 20px;
    text-align: center;
    height: 110%;
  }
  .quiz-question {
    font-size: 1.3rem;
    padding-bottom: 2rem;
  text-align: center;
  width: 80%;
  margin: auto;
    text-align: center;
    color: #d8d2e4;
  }
  .quiz-question-container {
    margin-top: 20px;
    width: 100%;
  }

  .quiz-answer-form {
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin-top: 0rem;
    columns: 1!important;
    width: 100%!important;
  }

  .quiz-position {
    margin-top: -10px;
  }
  
  .mobile-landscape {
    display: none;
    }
  .monitor-line-carousel {
    display: none;
  }
  
  .monitor-bar-carousel {
   display: none;
  }
  
  .monitor-other-carousel {
    display: none;
  }
  
  .monitor-other-charts-position {
    display: none;
  }
  .mobile-bar-carousel {
    width: 90%;
  height: 500px;
  margin: auto auto;
  padding-top: -1rem;
  padding-bottom: -5rem;
  margin-bottom: -6rem;
  overflow: hidden;
  }
  .mobile-line-carousel {
    width: 90%;
    height: 500px;
    margin: auto auto;
    padding-top: 0rem;
    overflow: hidden;
  }

  .mobile-line-pos {
padding-top: 3rem;
  }
  .more-data-hr {
    margin-top: 1rem!important;
  }
  .more-data-title {
    padding-bottom: 2rem!important;
  }
  .mobile-other-carousel {
    width: 110%;
    height: 500px;
    margin: auto auto;
    margin-left: -1.5rem;
    padding-top: 1rem!important;
    overflow: hidden;
  }
  .map {
    width: 100%;
    margin-left: 5%;
    box-shadow: #4D1D86 4px 5px 10px 3px;
  }
  .holders-pos {
    margin-left: -5%;
    padding-bottom: 2rem;
  }
  .css-1fzpoyk {
    width: 100%;
    padding-top: 1rem;
  }

  .extra-data {
    
    font-size: 1.25rem;
    text-align: center;
    color: var(--text-purple);
  }
  .data-title {
    letter-spacing: 0.0375rem;
    font-weight: 400;
    color: var(--text-purple);
    font-size: 2rem;
    text-align: center;
    position: relative;
  }
  .chartsTitle {
    letter-spacing: 0.0375rem;
    font-weight: 400;
    color: var(--text-purple);
    font-size: 2rem;
    text-align: center;
    position: relative;
    margin-bottom: -13rem;
  }
  .chartsTitle2 {
    letter-spacing: 0.0375rem;
    font-weight: 400;
    color: var(--text-purple);
    font-size: 2rem;
    text-align: center;
    margin-bottom: -10rem;
  }
  .hr {
    border: 3px solid var(--hr);
    border-radius: 5px;
    width: 90%;
    margin: auto;
    box-shadow: 0 0 10px 3px var(--hover-purple);
    position: relative;
    margin-top: 7rem;
  }
  .hr2 {
    border: 3px solid var(--hr);
    border-radius: 5px;
    width: 90%;
    margin: auto;
    box-shadow: 0 0 10px 3px var(--hover-purple);
    position: relative;
    margin-top: 6rem;
  }
 
  .move-up {
    margin-top: -45rem;
  }
.hide-me {
  display: none;
}
  .css-1qzevvg img {
    height: 100%;
    margin-top: -6rem;
    text-align: center;
    margin-left: -1rem;
    z-index: 999999;
    position: relative;
}
.adminHeader-text {
  display: none;
}
.adminHeader-text2 {
 display: none;
}
.adminSubheader-text {
  display: none;
}
.adminSubheader-text2 {
  display: none;
}
.adminChartsTitle2 {
  display: none;
}
.hide-export {
  display: none;
}
/* above me */
  .data-link {
    color: grey;
  }
  .data-link:link {
    color: grey;
    cursor: pointer;
  }
  .data-link:visited {
    color: grey;
    cursor: pointer;
  }
  .data-link.active,
  .data-link:focus {
    border-bottom: 0rem solid white;
    cursor: pointer;
  }
  .data-link:hover {
    color: white;
    transform: scale(1.05);
    cursor: pointer;
  }
  .no-show {
    display: none!important;
  }
  .mobile-dropdown {
    margin-left: 0rem!important;
  }
  .graphtronauts-link {
    color: var(--hr);
    z-index: 999;
    position: absolute;
    display: block;
    text-align: center!important;
    left: 25%!important;
  }
  .graphtronauts-link:link {
    color: var(--hr);
    cursor: pointer;
  }
  .graphtronauts-link:visited {
    color: var(--hr);
    cursor: pointer;
  }
  .graphtronauts-link.active, graphtronauts-link:focus {
    border-bottom: 0rem solid var(--hr);;
    cursor: pointer;
  }
  .graphtronauts-link:hover {
    color: var(--hr);
    transform: scale(1.025);
    cursor: pointer;
  }
  .chatbot-footer {
    display:none;
  }
  #root > div > div > div > div > div.react-chatbot-kit-chat-message-container > div:nth-child(3) > div.react-chatbot-kit-chat-bot-message > span > a > div {
    margin-left: -5.5px!important;
  }
  .chatbot-link-logo{
    margin-top: 8rem;
    z-index: 9998;
    position: fixed;
    max-width: 4rem;
    background: white;
    -moz-border-radius: 50px;
    -webkit-border-radius: 50px;
    border-radius: 50px;
    padding: -1rem;
    right: 2%;
    border: 3px var(--secondary-purple) solid;
    box-shadow: 0 0 0 var(--hover-purple);
    animation: pulse 2s infinite;
  }
  .advocate-link-logo{
    margin-top: 14rem;
    z-index: 9998;
    position: fixed;
    max-width: 4rem;
    background: white;
    -moz-border-radius: 50px;
    -webkit-border-radius: 50px;
    border-radius: 50px;
    padding: -1rem;
    right: 2%;
    border: 3px var(--secondary-purple) solid;
    box-shadow: 0 0 0 var(--hover-purple);
    animation: pulse 2s infinite;
  }
  @-webkit-keyframes pulse {
    0% {
      -webkit-box-shadow: 0 0 0 0  var(--text-purple);
    }
    70% {
        -webkit-box-shadow: 0 0 0 15px rgba(244,157,22, 0);
    }
    100% {
        -webkit-box-shadow: 0 0 0 0 rgba(244,157,22, 0);
    }
  }
  @keyframes pulse {
    0% {
      -moz-box-shadow: 0 0 0 0  var(--text-purple);
      box-shadow: 0 0 0 0  var(--text-purple);
    }
    70% {
        -moz-box-shadow: 0 0 0 15px rgba(244,157,22, 0);
        box-shadow: 0 0 0 15px rgba(244,157,22, 0);
    }
    100% {
        -moz-box-shadow: 0 0 0 0 rgba(244,157,22, 0);
        box-shadow: 0 0 0 0 rgba(244,157,22, 0);
    }
  }
  
  .chatbot-link-export{
    margin-top: 6rem;
    z-index: 9998;
    position: fixed;
    max-width: 5rem;
    background: white;
    -moz-border-radius: 50px;
    -webkit-border-radius: 50px;
    border-radius: 50px;
    padding: -1rem;
    right: 39%;
    border: 3px var(--secondary-purple) solid;
    box-shadow: 0 0 0 var(--hover-purple);
    animation: pulse 2s infinite;
  }

   /* Chatbot */
.feedback-text {
  margin-top: 2rem;
}
.feedback-button {
  border: 2px var(--primary-purple) solid!important;
  background-color: var(--text-purple)!important;
  color: var(--primary-purple)!important;
  padding: auto!important;
  margin: auto!important;
}

  .feedback-button:link {
    cursor: pointer;
  }
  .feedback-button:visited {
    cursor: pointer;
  }
  .feedback-button.active,
  .feedback-button:focus {
    cursor: pointer;
  }
  .feedback-button:hover {
    background-color: var(--background)!important;
    color: var(--text-purple)!important;
    transform: scale(1.015);
    cursor: pointer;
  }
  .feedback-button-text {
    font-size: 1.5rem;
  }

.react-chatbot-kit-chat-inner-container {
  background-color: var(--text-purple)!important;
}

.react-chatbot-kit-chat-header {
  font-size: 1.1rem!important;
  color: var(--text-purple)!important;
  background-color: var(--primary-purple)!important;
  padding: 0rem!important;
}

.react-chatbot-kit-chat-input-container {
  background-color:var(--secondary-purple)!important;
  border: 1px solid var(--primary-purple)!important;
}

.react-chatbot-kit-chat-btn-send {
  background-color:var(--primary-purple)!important;
}

.react-chatbot-kit-chat-btn-send-icon:hover {
transform: scale(1.25);
}

.react-chatbot-kit-chat-bot-message-container {
  width: 100%;
}

.react-chatbot-kit-chat-bot-avatar-container {
}

.react-chatbot-kit-chat-bot-avatar-icon {
}

.react-chatbot-kit-chat-bot-avatar-letter {
  display: none;
}
.react-chatbot-kit-chat-container {
  width: 98%!important;
  text-align: center;
  margin: auto;
}

.react-chatbot-kit-chat-bot-avatar-container {
  width: 3.5rem!important;
  height: 3.5rem!important;
  background-color: white!important;
  border: 2px solid var(--primary-purple);
  background-image: url('./GRTDataHubChatBot.png')!important;
  background-repeat: no-repeat!important;
  background-size: 3rem;
  background-position-x: 50%;
  background-position-y: 20%;
}

.react-chatbot-kit-chat-bot-message {
  text-align: left!important;
  width: 100%!important;
  color: var(--text-purple)!important;
  background-color: #0C0B23!important;
  font-size: 1rem!important;

}

.react-chatbot-kit-chat-bot-message-arrow {
  color: #0C0B23!important;
  border-right: 8px solid #0C0B23!important;
}
  .indexer-table-header {
    text-align: center;
    margin-left: 1rem;
  }
  .indexer-table {
    text-align: center;
  }
  .chatbot-data {
    display: inline!important;
  }
  .telegramActiveDelegationCount {
    max-width: 92%;
    margin: auto; 
    text-align: center;
    margin-top: 0.5%;
    }
    .top-telegram {
      margin-top: 0.25rem;
    }
    .chatbot-top {
      margin-top: 5rem;
    }
    .chatbot-page-container {
      background: var(--background);
      color: var(--text-purple);
      width: 100%;
      margin: auto;
      margin-top: 120px;
      margin-bottom: 20px;
      padding-left: 10px;
      padding-right: 10px;
      text-align: center;
    }
    .chatbot-text-container {
      background: whitesmoke;
      border-radius: 1rem;
      color: var(--secondary-purple);
      margin: auto!important;
      padding-bottom: 10px;
      padding-top: 10px;
      text-align: center!important;
    }
    .chatbot-page-title {
      font-size: 1.25rem;
      font-weight: 600;
      color: var(--secondary-purple);
      padding-bottom: 0.25rem;
      padding-left: 5px;
      padding-right: 5px;
    }
    .chatbot-page-subtitle {
      font-size: 1.75rem;
      padding-left: 5px;
      padding-right: 5px;
    }
    .chatbot-page-text {
      font-size: 1.25rem;
      margin-bottom: 1rem;
      margin-top: 1rem;
      padding-left: 10px;
      padding-right: 10px;
    }
    /* chatbot end */
  .dataHub-logos {
   display: none;
  }
  .monitor-config {
    display: none!important;
  }
  .hide-telegram {
    display: none!important;
  }
  body > div:nth-child(1) > button {
    display: none!important;
  }
  .mobile-nav-link {
    padding-right: 2rem!important;
  }
  .mobile-nav-pages {
    color: var(--hover-purple)!important;
    font-size: 1.5rem!important;
  }
  .mobile-nav-dropdown {
    margin-right: 2rem!important;
  }
  .mobile-nav-pages:link {
    cursor: pointer;
  }
  .mobile-nav-pages:visited {
    cursor: pointer;
  }
  .mobile-nav-pages.active,
  .mobile-nav-pages:focus {
    cursor: pointer;
    color: var(--text-purple)!important;
  }
  .mobile-nav-pages:hover {
    color: var(--text-purple)!important;
    cursor: pointer;
  }
  
  .offcanvas {
    width: 80%!important;
    background-color: var(--background)!important;
  }
  .offcanvas-body {
    width: 100%!important;
    margin-top: -3rem!important;
    opacity: 0.9;
  }
  .offcanvas-button {
    margin-right: 2rem;
    border: none!important;
  }
  .indexer-title {
   display: none;
  }
  .indexer-subtitle {
    display: none;
  }
  .indexer {
    display: none;
  }
  .curator {
    display: none;
  }
  .tokens, .rewards, .uncollected-rewards {
    display: none;
  }

  .c-map {
    display: none;
    }

    .d-map {
      display: none;
    }  
    

  .rewards-per-hour {
    display: none;
  }

  .indexers {
    display: none;
  }
  .indexers:link{
    display: none;
  }
  .indexers:visited {
    display: none;
  }
  .indexers.active,
  .indexers:focus {
    display: none;
  }
  .indexers:hover {
    display: none;
  }
  .indexer-link {
    display: none;
  }
  .indexer-link:link {
    display: none;
  }
  .indexer-link:visited {
    display: none;
  }
  .indexer-link.active,
  .indexer-link:focus {
    display: none;
  }
  .indexer-link:hover {
    display: none;
  }

  .cookie-page-text {
    padding-top: 1rem;
    font-size: 1.25rem;
    margin-bottom: 1rem;
    padding-left: 20px;
    padding-right: 20px;
  }
  .cookies-video {
    display: none;
  }
  .cookie-button {
    display: none;
  }
  body > div:nth-child(1) > button {
    display: none;
  }
  body > div:nth-child(1) > button:hover {
    display: none;
  }
  .extra-data2 {
    font-size: 1.25rem;
    text-align: center;
    color: var(--text-purple);
   
  }
  .question-bank-container {
    background: var(--background);
    color: var(--text-purple);
    width: 100%;
    margin: auto;
    margin-top: 120px;
    margin-bottom: 20px;
    padding-left: 10px;
    padding-right: 10px;
    text-align: center;
  }
  .about-page-container {
    background: var(--background);
    color: var(--text-purple);
    width: 100%;
    margin: auto;
    margin-top: 150px;
    margin-bottom: 50px;
    padding-left: 50px;
    padding-right: 50px;
    text-align: center;
  }
  .QB-text-container {
    background: white;
    border-radius: 1rem;
    padding: 10px;
    color: var(--secondary-purple);
  }
  .text-container {
    display: none;
  }
  .QB-about-hr {
    border: 1.5px solid var(--background);
    color: var(--background);
    border-radius: 5px;
    width: 90%;
    margin: auto;
    margin-bottom: 1rem;
    box-shadow: 0 0 5px 1px var(--background);
  }
  .QB-about-page-title {
    font-size: 1.5rem;
    font-weight: 600;
    color: var(--secondary-purple);
    padding-bottom: 1.5rem;
  }
  .QB-about-page-subtitle {
    font-size: 1.75rem;
  }
  .QB-about-page-text {
    font-size: 1.rem;
    margin-bottom: 1rem;
  }
  .about-page-title {
    display: none;
  }
  .about-page-subtitle {
    display: none;
  }
  .about-page-text {
    display: none;
  }
  .graphiql-link {
    color: var(--secondary-purple);
  }
  .graphiql-link:link {
    color: var(--secondary-purple);
    cursor: pointer;
  }
  .graphiql-link:visited {
    color: var(--secondary-purple);
    cursor: pointer;
  }
  .graphiql-link.active,
  .graphiql-link:focus {
    border-bottom: 0rem solid var(--hover-purple);
    cursor: pointer;
  }
  .graphiql-link:hover {
    color: var(--primary-purple);
    transform: scale(1.05);
    cursor: pointer;
  }
  .cookies-policy-container {
    background: white;
    width: 90%;
    padding-bottom: 1rem;
    margin: auto;
    border-radius: 2rem;
    margin-top: 150px;
    margin-bottom: 50px;
  }

  .privacy-policy-container {
    background: white;
    width: 90%;
    padding-bottom: 1rem;
    margin: auto;
    border-radius: 2rem;
    margin-top: 150px;
    margin-bottom: 50px;
  }
  .privacy-policy {
    margin-top: 110px;
    font-weight: 700;
    font-size: 1.1rem;
    text-align: center;
    background-color: var(--background);
    color: var(--secondary-purple);
  }
  .privacy-policy-link,
  .about {
    padding-left: 1rem;
    padding-left: 1rem;
    color: grey;
  }
  .privacy-policy-link:link,
  .about:link {
    color: grey;
    cursor: pointer;
  }
  .privacy-policy-link:visited,
  .about:visited {
    color: grey;
    cursor: pointer;
  }
  .privacy-policy-link.active,
  .privacy-policy-link:focus,
  .about.active,
  .about:focus {
    border-bottom: 0rem solid var(--hover-purple);
    cursor: pointer;
  }
  .privacy-policy-link:hover,
  .about:hover {
    color: var(--text-purple);
    transform: scale(1.05);
    cursor: pointer;
  }
  .subscribeNote {
    display: none;
  }
  /* loader new start  */
  .loader:empty {
    position: absolute;
    top: calc(50% + 15.5rem);
    left: calc(50% + 2em);
    width: 4em;
    height: 4em;
    border: 0.35em solid white;
    border-left: 0.35em solid #10034B;
    border-radius: 50%;
    animation: load8 0.8s infinite linear;
  }
  .loadingTitle {
    position: absolute;
    top: 27rem;
    left: calc(50% - 3em);
    font-size: 2rem;
    color: #10034B;
    -webkit-box-reflect:below 0px linear-gradient(transparent, #0005);
  }
  .loaderLogo {
    position: absolute;
    top: 14rem;
    left: calc(50% - 8em);
    width: 15em;
    height: 12em;
  }
  @keyframes load8 {
    0% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(360deg);
    }
  }
  /* loader new end  */

  /* New logo start */
  .logo {
    width: 7.5rem;
    height: 6rem;
    margin-left: 1rem;
    margin-top: -0.5rem;
  }
  .data-logo {
    max-width: 1.25rem !important;
    height: 1.25rem !important;
    position: absolute;
  }
  .brand-text {
    color: var(--text-purple);
    display: inline;
    margin-left: -0.5rem;
    letter-spacing: 0.0375rem;
    font-size: 1.25rem;
  }
   /* New logo end */
  .header-text {
    font-weight: 400;
    font-size: 1.5rem;
    padding-top: 1rem;
    text-align: center;
    margin-top: 105px;
    color: var(--text-purple);
   
  }
  .header-sub-text {
    font-weight: 400;
    font-size: 1rem;
    text-align: center!important;
    width: 90%;
    margin: auto!important;
    color: var(--text-purple);
    display: block;
    text-align: center;
  
  }
  .navbar-toggler {
    display: none;
  }
  nav a.TGN {
    display: none;
  }
  .navbar-nav > li > a.TGN:link {
    display: none;
  }
  .navbar-nav > li > a.TGN:visited {
    display: none;
  }
  .navbar-nav > li > a.TGN.active,
  .navbar-nav > li > a.TGN:focus {
    display: none;
  }
  .navbar-nav > li > a.TGN:hover {
    display: none;
  }
  nav a.nav-link {
    font-weight: 600;
    font-size: 1.15rem;
    color: var(--text-purple);
  }
  .navbar-nav > li > a {
    border-bottom: 0.01875rem solid var(--transparent);
    padding: 0;
  }
  .navbar-nav > li > a:link {
    color: var(--hover-purple);
  }
  .navbar-nav > li > a:visited {
    color: var(--text-purple);
  }
  .navbar-nav > li > a.active,
  .navbar-nav > li > a:focus {
    border-bottom: 0.01875rem solid var(--hover-purple);
    padding: 0;
    transition: all 0.5s ease-in-out;
  }
  .navbar-nav > li > a:hover {
    border-bottom: 0.01875rem solid var(--hover-purple);
    padding: 0;
    color: var(--hover-purple);
    transform: scale(1.05);
  }
  .line {
    margin-left: 1rem;
  }
  .links {
    text-align: right;
    padding-right: 0;
    margin: auto;
    padding: auto;
  }
  .dropdown {
    margin-left: auto;
    padding: auto;
  }
  .bar {
    margin-bottom: 0.25rem;
    margin-right: 2rem;
  }
  .line {
    margin-bottom: 0.25rem;
    margin-right: 2rem;
  }
  .about {
    margin-left: auto;
    margin-right: 0.8rem;
  }
  .rotate-position,
  .about-rotate-position {
    margin-top: 6rem;
    padding-top: 2rem;
  }
  .phone,
  .about-phone {
    height: 50px;
    padding-top: 10rem;
    width: 100px;
    border: 3px solid white;
    border-radius: 10px;
    animation: rotate 1.5s ease-in-out infinite alternate;
    text-align: center;
    margin: auto;
  }
  .message,
  .about-message {
    color: var(--text-purple);
    text-shadow: 2.5px 2.5px 4px var(--hover-purple);
    font-weight: 400;
    font-size: 1.25rem;
    padding-top: 1rem;
    padding-left: 1rem;
    padding-right: 1rem;
    text-align: center;
    margin: auto;
  }
  .home-message {
    color: var(--text-purple);
    text-shadow: 2.5px 2.5px 4px var(--hover-purple);
    font-weight: 400;
    font-size: 1.25rem;
    padding-top: 3rem;
    padding-bottom: 2rem;
    padding-left: 1rem;
    padding-right: 1rem;
    text-align: center;
    margin: auto;
  }
  @keyframes rotate {
    0% {
      transform: rotate(0deg);
    }
    50% {
      transform: rotate(-90deg);
    }
    100% {
      transform: rotate(-90deg);
    }
  }
  .menu-icon {
    color: var(--secondary-purple);
  }
  .main-info {
    background-image: url("TopBackground.svg");
    background-repeat: no-repeat;
    background-size: 100% auto;
    width: 100%;
    height: 100%;
    margin-top: 90px;
  }
  .community-links {
    text-align: center;
    padding-top: 4rem;
    position: absolute;
    z-index: 999;
    left: 18%;
  }
  .telegram-icon,
  .twitter-icon,
  .reddit-icon,
  .youtube-icon {
    display: inline;
    padding: 1rem;
    transition: all 0.5s ease-in-out;
    z-index: 999;
  }
  .icon:hover {
    color: var(--hover-purple);
    transform: scale(1.15);
  }
  .icon-youtube:hover {
    color: var(--text-purple);
    transform: scale(1.1);
  }
  .news-title {
    display: none!important;
  }
  .newsGraph1,
  .newsGraph2,
  .newsGraph3,
  .newsGraph4 {
    display: none;
  }
  .youtube {
    z-index: 999;
    display: inline;
    position: absolute;
    text-align: center;
    color: var(--text-purple);
  }
  span.anchor {
    display: block;
    visibility: hidden;
    position: relative;
    padding-top: 17.5%;
  }
  .activeDelegators,
  .holders,
  .curatorCount,
  .subgraphCount {
    display: none;
  }
  .video {
    display: none;
  }
  .g-ytsubscribe {
    display: none;
  }
  
  .newsGraph {
    display: none;
  }
  
  .center {
    margin: auto;
  }
  .line1 {
    display: none;
  }
 
  .chartImg6 {
    text-align: center;
    padding-top: 1rem;
    width: 100%;
  }
  .reveal {
    position: relative;
    transform: translateY(50px);
    opacity: 0;
    transition: 0.75s all ease;
  }
  .reveal.active {
    transform: translateY(0);
    opacity: 1;
  }
  .email {
    color: var(--hover-purple);
    font-weight: 500;
  }
  .footerLink {
    color: var(--hover-purple);
  }
  .footerLink:link {
    color: var(--hover-purple);
    cursor: pointer;
  }
  .footerLink:visited {
    color: var(--hover-purple);
    cursor: pointer;
  }
  .footerLink.active,
  .footerLink:focus {
    border-bottom: 0rem solid var(--hover-purple);
    cursor: pointer;
  }
  .footerLink:hover {
    color: var(--text-purple);
    transform: scale(1.05);
    cursor: pointer;
  }
  .footer {
    margin-bottom: -1rem;
    padding: 1rem;
    font-weight: 700;
    margin-top: 1rem;
    font-size: 1rem;
    text-align: center;
    background-color: var(--primary-purple);
    color: var(--secondary-purple);
  }
  .tsparticles {
    display: none;
  }
}

/* *************************************************************************
****************************************************************************
***************************************************************************/
/* *********************************360****************************************
****************************************************************************
***************************************************************************/
@media screen and  (min-width: 321px) and (max-width: 360px) {
  .flashcards-title {
    margin-top: 120px!important;
    }
  .hide-line {
    color: transparent!important;
    display: block!important;
  }
  .Quiz-button {
    margin-bottom: 2rem;
  }
  .quiz-title {
    margin-top: -60px;
  }
   .quiz-button-position {
    padding-top: 2rem;
    margin: auto;
    text-align: center;
    margin-bottom: 2rem;
  }
  
  .final-exam-question {
      font-size: 1.3rem;
      text-align: center;
      color: #d8d2e4;
      /* remove for mobile */
      margin-top: 0rem;
      /* remove for mobile */
  }
  
  .final-exam-answer-form {
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin-top: 0rem;
    columns: 1!important;
    width: 100%!important;
  }
  
  .form-check-label {
    font-size: 20px;
    text-align: center;
    height: 110%;
  }
  .quiz-question {
    font-size: 1.3rem;
    padding-bottom: 2rem;
  text-align: center;
  width: 80%;
  margin: auto;
    text-align: center;
    color: #d8d2e4;
  }
  .quiz-question-container {
    margin-top: 20px;
    width: 100%;
  }

  .quiz-answer-form {
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin-top: 0rem;
    columns: 1!important;
    width: 100%!important;
  }

  .quiz-position {
    margin-top: -10px;
  }
  
  .mobile-landscape {
    display: none;
    }
  .monitor-line-carousel {
    display: none;
  }
  
  .monitor-bar-carousel {
   display: none;
  }
  
  .monitor-other-carousel {
    display: none;
  }
  
  .monitor-other-charts-position {
    display: none;
  }
  .mobile-bar-carousel {
    width: 90%;
  height: 500px;
  margin: auto auto;
  padding-top: -1rem;
  padding-bottom: -5rem;
  margin-bottom: -6rem;
  overflow: hidden;
  }
  .mobile-line-carousel {
    width: 90%;
    height: 500px;
    margin: auto auto;
    padding-top: 0rem;
    overflow: hidden;
  }

  .mobile-line-pos {
padding-top: 3rem;
  }
  .more-data-hr {
    margin-top: 1rem!important;
  }
  .more-data-title {
    padding-bottom: 2rem!important;
  }
  .mobile-other-carousel {
    width: 110%;
    height: 500px;
    margin: auto auto;
    margin-left: -1.5rem;
    padding-top: 1rem!important;
    overflow: hidden;
  }
  .map {
    width: 100%;
    margin-left: 5%;
    box-shadow: #4D1D86 4px 5px 10px 3px;
  }
  .holders-pos {
    margin-left: -5%;
    padding-bottom: 2rem;
  }
  .css-1fzpoyk {
    width: 100%;
    padding-top: 1rem;
  }

  .extra-data {
    
    font-size: 1.25rem;
    text-align: center;
    color: var(--text-purple);
  }
  .data-title {
    letter-spacing: 0.0375rem;
    font-weight: 400;
    color: var(--text-purple);
    font-size: 2rem;
    text-align: center;
    position: relative;
  }
  .chartsTitle {
    letter-spacing: 0.0375rem;
    font-weight: 400;
    color: var(--text-purple);
    font-size: 2rem;
    text-align: center;
    position: relative;
    margin-bottom: -13rem;
  }
  .chartsTitle2 {
    letter-spacing: 0.0375rem;
    font-weight: 400;
    color: var(--text-purple);
    font-size: 2rem;
    text-align: center;
    margin-bottom: -10rem;
  }
  .hr {
    border: 3px solid var(--hr);
    border-radius: 5px;
    width: 90%;
    margin: auto;
    box-shadow: 0 0 10px 3px var(--hover-purple);
    position: relative;
    margin-top: 7rem;
  }
  .hr2 {
    border: 3px solid var(--hr);
    border-radius: 5px;
    width: 90%;
    margin: auto;
    box-shadow: 0 0 10px 3px var(--hover-purple);
    position: relative;
    margin-top: 6rem;
  }
 
  .move-up {
    margin-top: -45rem;
  }
.hide-me {
  display: none;
}
  .css-1qzevvg img {
    height: 100%;
    margin-top: -6rem;
    text-align: center;
    margin-left: -1rem;
    z-index: 999999;
    position: relative;
}
.adminHeader-text {
  display: none;
}
.adminHeader-text2 {
 display: none;
}
.adminSubheader-text {
  display: none;
}
.adminSubheader-text2 {
  display: none;
}
.adminChartsTitle2 {
  display: none;
}
.hide-export {
  display: none;
}
/* above me */
  .data-link {
    color: grey;
  }
  .data-link:link {
    color: grey;
    cursor: pointer;
  }
  .data-link:visited {
    color: grey;
    cursor: pointer;
  }
  .data-link.active,
  .data-link:focus {
    border-bottom: 0rem solid white;
    cursor: pointer;
  }
  .data-link:hover {
    color: white;
    transform: scale(1.05);
    cursor: pointer;
  }
  .no-show {
    display: none!important;
  }
  .mobile-dropdown {
    margin-left: 0rem!important;
  }
  .graphtronauts-link {
    color: var(--hr);
    z-index: 999;
    position: absolute;
    display: block;
    text-align: center!important;
    margin: auto!important;
  }
  .graphtronauts-link:link {
    color: var(--hr);
    cursor: pointer;
  }
  .graphtronauts-link:visited {
    color: var(--hr);
    cursor: pointer;
  }
  .graphtronauts-link.active, graphtronauts-link:focus {
    border-bottom: 0rem solid var(--hr);;
    cursor: pointer;
  }
  .graphtronauts-link:hover {
    color: var(--hr);
    transform: scale(1.025);
    cursor: pointer;
  }
  .chatbot-footer {
    display:none;
  }
  #root > div > div > div > div > div.react-chatbot-kit-chat-message-container > div:nth-child(3) > div.react-chatbot-kit-chat-bot-message > span > a > div {
    margin-left: -5.5px!important;
  }
  .chatbot-link-logo{
    margin-top: 7rem;
    z-index: 9998;
    position: fixed;
    max-width: 4rem;
    background: white;
    -moz-border-radius: 50px;
    -webkit-border-radius: 50px;
    border-radius: 50px;
    padding: -1rem;
    right: 2%;
    border: 3px var(--secondary-purple) solid;
    box-shadow: 0 0 0 var(--hover-purple);
    animation: pulse 2s infinite;
  }
  .advocate-link-logo{
    margin-top: 13rem;
    z-index: 9998;
    position: fixed;
    max-width: 4rem;
    background: white;
    -moz-border-radius: 50px;
    -webkit-border-radius: 50px;
    border-radius: 50px;
    padding: -1rem;
    right: 2%;
    border: 3px var(--secondary-purple) solid;
    box-shadow: 0 0 0 var(--hover-purple);
    animation: pulse 2s infinite;
  }
  @-webkit-keyframes pulse {
    0% {
      -webkit-box-shadow: 0 0 0 0  var(--text-purple);
    }
    70% {
        -webkit-box-shadow: 0 0 0 15px rgba(244,157,22, 0);
    }
    100% {
        -webkit-box-shadow: 0 0 0 0 rgba(244,157,22, 0);
    }
  }
  @keyframes pulse {
    0% {
      -moz-box-shadow: 0 0 0 0  var(--text-purple);
      box-shadow: 0 0 0 0  var(--text-purple);
    }
    70% {
        -moz-box-shadow: 0 0 0 15px rgba(244,157,22, 0);
        box-shadow: 0 0 0 15px rgba(244,157,22, 0);
    }
    100% {
        -moz-box-shadow: 0 0 0 0 rgba(244,157,22, 0);
        box-shadow: 0 0 0 0 rgba(244,157,22, 0);
    }
  }
  
  .chatbot-link-export{
    margin-top: 6rem;
    z-index: 9998;
    position: fixed;
    max-width: 5rem;
    background: white;
    -moz-border-radius: 50px;
    -webkit-border-radius: 50px;
    border-radius: 50px;
    padding: -1rem;
    left: 40%;
    border: 3px var(--secondary-purple) solid;
    box-shadow: 0 0 0 var(--hover-purple);
    animation: pulse 2s infinite;
  }
   /* Chatbot */
.feedback-text {
  margin-top: 2rem;
}
.feedback-button {
  border: 2px var(--primary-purple) solid!important;
  background-color: var(--text-purple)!important;
  color: var(--primary-purple)!important;
  padding: auto!important;
  margin: auto!important;
}

  .feedback-button:link {
    cursor: pointer;
  }
  .feedback-button:visited {
    cursor: pointer;
  }
  .feedback-button.active,
  .feedback-button:focus {
    cursor: pointer;
  }
  .feedback-button:hover {
    background-color: var(--background)!important;
    color: var(--text-purple)!important;
    transform: scale(1.015);
    cursor: pointer;
  }
  .feedback-button-text {
    font-size: 1.5rem;
  }

.react-chatbot-kit-chat-inner-container {
  background-color: var(--text-purple)!important;
}

.react-chatbot-kit-chat-header {
  font-size: 1.1rem!important;
  color: var(--text-purple)!important;
  background-color: var(--primary-purple)!important;
  padding: 0rem!important;
}

.react-chatbot-kit-chat-input-container {
  background-color:var(--secondary-purple)!important;
  border: 1px solid var(--primary-purple)!important;
}

.react-chatbot-kit-chat-btn-send {
  background-color:var(--primary-purple)!important;
}

.react-chatbot-kit-chat-btn-send-icon:hover {
transform: scale(1.25);
}

.react-chatbot-kit-chat-bot-message-container {
  width: 100%;
}

.react-chatbot-kit-chat-bot-avatar-container {
}

.react-chatbot-kit-chat-bot-avatar-icon {
}

.react-chatbot-kit-chat-bot-avatar-letter {
  display: none;
}
.react-chatbot-kit-chat-container {
  width: 98%!important;
  text-align: center;
  margin: auto;
}

.react-chatbot-kit-chat-bot-avatar-container {
  width: 3.5rem!important;
  height: 3.5rem!important;
  background-color: white!important;
  border: 2px solid var(--primary-purple);
  background-image: url('./GRTDataHubChatBot.png')!important;
  background-repeat: no-repeat!important;
  background-size: 3rem;
  background-position-x: 50%;
  background-position-y: 20%;
}

.react-chatbot-kit-chat-bot-message {
  text-align: left!important;
  width: 100%!important;
  color: var(--text-purple)!important;
  background-color: #0C0B23!important;
  font-size: 1rem!important;

}

.react-chatbot-kit-chat-bot-message-arrow {
  color: #0C0B23!important;
  border-right: 8px solid #0C0B23!important;
}
  .indexer-table-header {
    text-align: center;
    margin-left: 1rem;
  }
  .indexer-table {
    text-align: center;
  }
  .chatbot-data {
    display: inline!important;
  }
  .telegramActiveDelegationCount {
    max-width: 92%;
    margin: auto; 
    text-align: center;
    margin-top: 0.5%;
    }
    .top-telegram {
      margin-top: 0.25rem;
    }
    .chatbot-top {
      margin-top: 5rem;
    }
    .chatbot-page-container {
      background: var(--background);
      color: var(--text-purple);
      width: 100%;
      margin: auto;
      margin-top: 120px;
      margin-bottom: 20px;
      padding-left: 10px;
      padding-right: 10px;
      text-align: center;
    }
    .chatbot-text-container {
      background: whitesmoke;
      border-radius: 1rem;
      color: var(--secondary-purple);
      margin: auto!important;
      padding-bottom: 10px;
      padding-top: 10px;
      text-align: center!important;
    }
    .chatbot-page-title {
      font-size: 1.25rem;
      font-weight: 600;
      color: var(--secondary-purple);
      padding-bottom: 0.25rem;
      padding-left: 5px;
      padding-right: 5px;
    }
    .chatbot-page-subtitle {
      font-size: 1.75rem;
      padding-left: 5px;
      padding-right: 5px;
    }
    .chatbot-page-text {
      font-size: 1.25rem;
      margin-bottom: 1rem;
      margin-top: 1rem;
      padding-left: 10px;
      padding-right: 10px;
    }
    /* chatbot end */
  .dataHub-logos {
   display: none;
  }
  .monitor-config {
    display: none!important;
  }
  .hide-telegram {
    display: none!important;
  }
  body > div:nth-child(1) > button {
    display: none!important;
  }
  .navbar {
    background-color: var(--primary-purple);
    color: var(--text-purple);
    z-index: 9999;
    position: absolute;
  }
  .new-link {
    margin-right: 2rem;
    margin-top: -0.25rem;
  }
  .mobile-nav-link {
    padding-right: 2rem!important;
  }
  .mobile-nav-pages {
    color: var(--hover-purple)!important;
    font-size: 1.5rem!important;
  }
  .mobile-nav-dropdown {
    margin-right: 2rem!important;
  }
  .mobile-nav-pages:link {
    cursor: pointer;
  }
  .mobile-nav-pages:visited {
    cursor: pointer;
  }
  .mobile-nav-pages.active,
  .mobile-nav-pages:focus {
    cursor: pointer;
    color: var(--text-purple)!important;
  }
  .mobile-nav-pages:hover {
    color: var(--text-purple)!important;
    cursor: pointer;
  }
  
  .offcanvas {
    width: 80%!important;
    background-color: var(--background)!important;
  }
  .offcanvas-body {
    width: 100%!important;
    margin-top: -3rem!important;
    opacity: 0.9;
  }
  .offcanvas-button {
    margin-right: 2rem;
    border: none!important;
  }
  .indexer-title {
   display: none;
  }
  .indexer-subtitle {
    display: none;
  }
  .indexer {
    display: none;
  }
  .curator {
    display: none;
  }
  .tokens, .rewards, .uncollected-rewards {
    display: none;
  }

  .c-map {
    display: none;
    }

    .d-map {
      display: none;
    }

  .rewards-per-hour {
    display: none;
  }

  .indexers {
    display: none;
  }
  .indexers:link{
    display: none;
  }
  .indexers:visited {
    display: none;
  }
  .indexers.active,
  .indexers:focus {
    display: none;
  }
  .indexers:hover {
    display: none;
  }
  .indexer-link {
    display: none;
  }
  .indexer-link:link {
    display: none;
  }
  .indexer-link:visited {
    display: none;
  }
  .indexer-link.active,
  .indexer-link:focus {
    display: none;
  }
  .indexer-link:hover {
    display: none;
  }

  .cookie-page-text {
    padding-top: 1rem;
    font-size: 1.25rem;
    margin-bottom: 1rem;
    padding-left: 20px;
    padding-right: 20px;
  }
  .cookies-video {
    display: none;
  }
  .cookie-button {
    display: none;
  }
  body > div:nth-child(1) > button {
    display: none;
  }
  body > div:nth-child(1) > button:hover {
    display: none;
  }
  .extra-data2 {
    font-size: 1.25rem;
    text-align: center;
    color: var(--text-purple);
   
  }
  .question-bank-container {
    background: var(--background);
    color: var(--text-purple);
    width: 100%;
    margin: auto;
    margin-top: 120px;
    margin-bottom: 20px;
    padding-left: 10px;
    padding-right: 10px;
    text-align: center;
  }
  .about-page-container {
    background: var(--background);
    color: var(--text-purple);
    width: 100%;
    margin: auto;
    margin-top: 150px;
    margin-bottom: 50px;
    padding-left: 50px;
    padding-right: 50px;
    text-align: center;
  }
  .QB-text-container {
    background: white;
    border-radius: 1rem;
    padding: 10px;
    color: var(--secondary-purple);
  }
  .text-container {
    display: none;
  }
  .QB-about-hr {
    border: 1.5px solid var(--background);
    color: var(--background);
    border-radius: 5px;
    width: 90%;
    margin: auto;
    margin-bottom: 1rem;
    box-shadow: 0 0 5px 1px var(--background);
  }
  .QB-about-page-title {
    font-size: 1.5rem;
    font-weight: 600;
    color: var(--secondary-purple);
    padding-bottom: 1.5rem;
  }
  .QB-about-page-subtitle {
    font-size: 1.75rem;
  }
  .QB-about-page-text {
    font-size: 1.rem;
    margin-bottom: 1rem;
  }
  .about-page-title {
    display: none;
  }
  .about-page-subtitle {
    display: none;
  }
  .about-page-text {
    display: none;
  }
  .graphiql-link {
    color: var(--secondary-purple);
  }
  .graphiql-link:link {
    color: var(--secondary-purple);
    cursor: pointer;
  }
  .graphiql-link:visited {
    color: var(--secondary-purple);
    cursor: pointer;
  }
  .graphiql-link.active,
  .graphiql-link:focus {
    border-bottom: 0rem solid var(--hover-purple);
    cursor: pointer;
  }
  .graphiql-link:hover {
    color: var(--primary-purple);
    transform: scale(1.05);
    cursor: pointer;
  }
  .cookies-policy-container {
    background: white;
    width: 90%;
    padding-bottom: 1rem;
    margin: auto;
    border-radius: 2rem;
    margin-top: 150px;
    margin-bottom: 50px;
  }

  .privacy-policy-container {
    background: white;
    width: 90%;
    padding-bottom: 1rem;
    margin: auto;
    border-radius: 2rem;
    margin-top: 150px;
    margin-bottom: 50px;
  }
  .privacy-policy {
    margin-top: 110px;
    font-weight: 700;
    font-size: 1.1rem;
    text-align: center;
    background-color: var(--background);
    color: var(--secondary-purple);
  }
  .privacy-policy-link,
  .about {
    padding-left: 1rem;
    padding-left: 1rem;
    color: grey;
  }
  .privacy-policy-link:link,
  .about:link {
    color: grey;
    cursor: pointer;
  }
  .privacy-policy-link:visited,
  .about:visited {
    color: grey;
    cursor: pointer;
  }
  .privacy-policy-link.active,
  .privacy-policy-link:focus,
  .about.active,
  .about:focus {
    border-bottom: 0rem solid var(--hover-purple);
    cursor: pointer;
  }
  .privacy-policy-link:hover,
  .about:hover {
    color: var(--text-purple);
    transform: scale(1.05);
    cursor: pointer;
  }
  .subscribeNote {
    display: none;
  }
  /* loader new start  */
  .loader:empty {
    position: absolute;
    top: calc(50% + 15.5rem);
    left: calc(50% + 2em);
    width: 4em;
    height: 4em;
    border: 0.35em solid white;
    border-left: 0.35em solid #10034B;
    border-radius: 50%;
    animation: load8 0.8s infinite linear;
  }
  .loadingTitle {
    position: absolute;
    top: 27rem;
    left: calc(50% - 3em);
    font-size: 2rem;
    color: #10034B;
    -webkit-box-reflect:below 0px linear-gradient(transparent, #0005);
  }
  .loaderLogo {
    position: absolute;
    top: 14rem;
    left: calc(50% - 8em);
    width: 15em;
    height: 12em;
  }
  @keyframes load8 {
    0% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(360deg);
    }
  }
  /* loader new end  */

  /* New logo start */
  .logo {
    width: 7rem;
    height: 5.5rem;
    margin-left: 0.5rem;
    margin-top: -0.5rem;
  }
  .data-logo {
    max-width: 1.25rem !important;
    height: 1.25rem !important;
    position: absolute;
  }
  .brand-text {
    color: var(--text-purple);
    display: inline;
    margin-left: -0.5rem;
    margin-right: -1rem;
    letter-spacing: 0.0375rem;
    font-size: 1.25rem;
  }
   /* New logo end */
  .header-text {
    font-weight: 400;
    font-size: 1.5rem;
    padding-top: 1rem;
    text-align: center;
    margin-top: 105px;
    color: var(--text-purple);
   
  }
  .header-sub-text {
    font-weight: 400;
    font-size: 1rem;
    text-align: center!important;
    width: 90%;
    margin: auto!important;
    color: var(--text-purple);
    display: block;
    text-align: center;
  
  }
  .navbar-toggler {
    display: none;
  }
  nav a.TGN {
    display: none;
  }
  .navbar-nav > li > a.TGN:link {
    display: none;
  }
  .navbar-nav > li > a.TGN:visited {
    display: none;
  }
  .navbar-nav > li > a.TGN.active,
  .navbar-nav > li > a.TGN:focus {
    display: none;
  }
  .navbar-nav > li > a.TGN:hover {
    display: none;
  }
  nav a.nav-link {
    font-weight: 600;
    font-size: 1.15rem;
    color: var(--text-purple);
  }
  .navbar-nav > li > a {
    border-bottom: 0.01875rem solid var(--transparent);
    padding: 0;
  }
  .navbar-nav > li > a:link {
    color: var(--hover-purple);
  }
  .navbar-nav > li > a:visited {
    color: var(--text-purple);
  }
  .navbar-nav > li > a.active,
  .navbar-nav > li > a:focus {
    border-bottom: 0.01875rem solid var(--hover-purple);
    padding: 0;
    transition: all 0.5s ease-in-out;
  }
  .navbar-nav > li > a:hover {
    border-bottom: 0.01875rem solid var(--hover-purple);
    padding: 0;
    color: var(--hover-purple);
    transform: scale(1.05);
  }
  .line {
    margin-left: 1rem;
  }
  .links {
    text-align: right;
    padding-right: 0;
    margin: auto;
    padding: auto;
  }
  .dropdown {
    margin-left: auto;
    padding: auto;
  }
  .bar {
    margin-bottom: 0.25rem;
    margin-right: 2rem;
  }
  .line {
    margin-bottom: 0.25rem;
    margin-right: 2rem;
  }
  .about {
    margin-left: auto;
    margin-right: 0.8rem;
  }
  .rotate-position,
  .about-rotate-position {
    margin-top: 6rem;
    padding-top: 2rem;
  }
  .phone,
  .about-phone {
    height: 50px;
    padding-top: 10rem;
    width: 100px;
    border: 3px solid white;
    border-radius: 10px;
    animation: rotate 1.5s ease-in-out infinite alternate;
    text-align: center;
    margin: auto;
  }
  .message,
  .about-message {
    color: var(--text-purple);
    text-shadow: 2.5px 2.5px 4px var(--hover-purple);
    font-weight: 400;
    font-size: 1.25rem;
    padding-top: 1rem;
    padding-left: 1rem;
    padding-right: 1rem;
    text-align: center;
    margin: auto;
  }
  .home-message {
    color: var(--text-purple);
    text-shadow: 2.5px 2.5px 4px var(--hover-purple);
    font-weight: 400;
    font-size: 1.25rem;
    padding-top: 3rem;
    padding-bottom: 2rem;
    padding-left: 1rem;
    padding-right: 1rem;
    text-align: center;
    margin: auto;
  }
  @keyframes rotate {
    0% {
      transform: rotate(0deg);
    }
    50% {
      transform: rotate(-90deg);
    }
    100% {
      transform: rotate(-90deg);
    }
  }
  .menu-icon {
    color: var(--secondary-purple);
  }
  .main-info {
    background-image: url("TopBackground.svg");
    background-repeat: no-repeat;
    background-size: 100% auto;
    width: 100%;
    height: 100%;
    margin-top: 90px;
  }
  .community-links {
    text-align: center;
    padding-top: 4rem;
    position: absolute;
    z-index: 999;
    left: 18%;
  }
  .telegram-icon,
  .twitter-icon,
  .reddit-icon,
  .youtube-icon {
    display: inline;
    padding: 1rem;
    transition: all 0.5s ease-in-out;
    z-index: 999;
  }
  .icon:hover {
    color: var(--hover-purple);
    transform: scale(1.15);
  }
  .icon-youtube:hover {
    color: var(--text-purple);
    transform: scale(1.1);
  }
    .news-title {
      display: none!important;
    }
  .newsGraph1,
  .newsGraph2,
  .newsGraph3,
  .newsGraph4 {
    display: none;
  }
  .youtube {
    z-index: 999;
    display: inline;
    position: absolute;
    text-align: center;
    color: var(--text-purple);
  }
  span.anchor {
    display: block;
    visibility: hidden;
    position: relative;
    padding-top: 17.5%;
  }
  .activeDelegators,
  .holders,
  .curatorCount,
  .subgraphCount {
    display: none;
  }
  .video {
    display: none;
  }
  .g-ytsubscribe {
    display: none;
  }
 
  .newsGraph {
    display: none;
  }

  .center {
    margin: auto;
  }
  .line1 {
    display: none;
  }
 
  .chartImg6 {
    text-align: center;
    padding-top: 1rem;
    width: 100%;
  }
  .reveal {
    position: relative;
    transform: translateY(50px);
    opacity: 0;
    transition: 0.75s all ease;
  }
  .reveal.active {
    transform: translateY(0);
    opacity: 1;
  }
  .email {
    color: var(--hover-purple);
    font-weight: 500;
  }
  .footerLink {
    color: var(--hover-purple);
  }
  .footerLink:link {
    color: var(--hover-purple);
    cursor: pointer;
  }
  .footerLink:visited {
    color: var(--hover-purple);
    cursor: pointer;
  }
  .footerLink.active,
  .footerLink:focus {
    border-bottom: 0rem solid var(--hover-purple);
    cursor: pointer;
  }
  .footerLink:hover {
    color: var(--text-purple);
    transform: scale(1.05);
    cursor: pointer;
  }
  .footer {
    margin-bottom: -1rem;
    padding: 1rem;
    font-weight: 700;
    margin-top: 1rem;
    font-size: 1rem;
    text-align: center;
    background-color: var(--primary-purple);
    color: var(--secondary-purple);
  }
  .tsparticles {
    display: none;
  }
}

/* *************************************************************************
****************************************************************************
***************************************************************************/
/* *************************************************************************
****************************************************************************
***************************************************************************/
@media screen and (max-width: 320px) {  
.flashcards-title {
margin-top: 120px!important;
}
  .hide-line {
    color: transparent!important;
    display: block!important;
  }
  .Quiz-button {
    margin-bottom: 2rem;
  }
  .quiz-title {
    margin-top: -60px;
  }
  .quiz-button-position {
    padding-top: 2rem;
    margin: auto;
    text-align: center;
    margin-bottom: 2rem;
  }
  
  .final-exam-question {
      font-size: 1.3rem;
      text-align: center;
      color: #d8d2e4;
      /* remove for mobile */
      margin-top: 0rem;
      /* remove for mobile */
  }
  
  .final-exam-answer-form {
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin-top: 0rem;
    columns: 1!important;
    width: 100%!important;
  }
  
  .form-check-label {
    font-size: 20px;
    text-align: center;
    height: 110%;
  }
  .quiz-question {
    font-size: 1.3rem;
    padding-bottom: 2rem;
  text-align: center;
  width: 80%;
  margin: auto;
    text-align: center;
    color: #d8d2e4;
  }
  .quiz-question-container {
    margin-top: 20px;
    width: 100%;
  }

  .quiz-answer-form {
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin-top: 0rem;
    columns: 1!important;
    width: 100%!important;
  }

  .quiz-position {
    margin-top: -10px;
  }

  .mobile-landscape {
  display: none;
  }
  .monitor-line-carousel {
    display: none;
  }
  
  .monitor-bar-carousel {
   display: none;
  }
  
  .monitor-other-carousel {
    display: none;
  }
  
  .monitor-other-charts-position {
    display: none;
  }
  .mobile-bar-carousel {
    width: 100%;
  height: 500px;
  margin: auto auto;
  padding-top: -1rem;
  padding-bottom: -5rem;
  margin-bottom: -6rem;
  overflow: hidden;
  }
  .mobile-line-carousel {
    width: 100%;
    height: 500px;
    margin: auto auto;
    padding-top: 0rem;
    overflow: hidden;
  }

  .mobile-line-pos {
padding-top: 3rem;
  }
  .more-data-hr {
    margin-top: 1rem!important;
  }
  .more-data-title {
    padding-bottom: 2rem!important;
  }
  .mobile-other-carousel {
    width: 110%;
    height: 500px;
    margin: auto auto;
    margin-left: -1.5rem;
    padding-top: 1rem!important;
    overflow: hidden;
  }
  .map {
    width: 100%;
    margin-left: 5%;
    box-shadow: #4D1D86 4px 5px 10px 3px;
  }
  .holders-pos {
    margin-left: -5%;
    padding-bottom: 2rem;
  }
  .css-1fzpoyk {
    width: 100%;
    padding-top: 1rem;
  }

  .extra-data {
    
    font-size: 1.25rem;
    text-align: center;
    color: var(--text-purple);
  }
  .data-title {
    letter-spacing: 0.0375rem;
    font-weight: 400;
    color: var(--text-purple);
    font-size: 2rem;
    text-align: center;
    position: relative;
  }
  .chartsTitle {
    letter-spacing: 0.0375rem;
    font-weight: 400;
    color: var(--text-purple);
    font-size: 2rem;
    text-align: center;
    position: relative;
    margin-bottom: -13rem;
  }
  .chartsTitle2 {
    letter-spacing: 0.0375rem;
    font-weight: 400;
    color: var(--text-purple);
    font-size: 2rem;
    text-align: center;
    margin-bottom: -10rem;
  }
  .hr {
    border: 3px solid var(--hr);
    border-radius: 5px;
    width: 90%;
    margin: auto;
    box-shadow: 0 0 10px 3px var(--hover-purple);
    position: relative;
    margin-top: 7rem;
  }
  .hr2 {
    border: 3px solid var(--hr);
    border-radius: 5px;
    width: 90%;
    margin: auto;
    box-shadow: 0 0 10px 3px var(--hover-purple);
    position: relative;
    margin-top: 6rem;
  }
 
  .move-up {
    margin-top: -45rem;
  }
.hide-me {
  display: none;
}
  .css-1qzevvg img {
    height: 100%;
    margin-top: -6rem;
    text-align: center;
    margin-left: -1rem;
    z-index: 999999;
    position: relative;
}
.adminHeader-text {
  display: none;
}
.adminHeader-text2 {
 display: none;
}
.adminSubheader-text {
  display: none;
}
.adminSubheader-text2 {
  display: none;
}
.adminChartsTitle2 {
  display: none;
}
.hide-export {
  display: none;
}
/* above me */
  .data-link {
    color: grey;
  }
  .data-link:link {
    color: grey;
    cursor: pointer;
  }
  .data-link:visited {
    color: grey;
    cursor: pointer;
  }
  .data-link.active,
  .data-link:focus {
    border-bottom: 0rem solid white;
    cursor: pointer;
  }
  .data-link:hover {
    color: white;
    transform: scale(1.05);
    cursor: pointer;
  }
  .no-show {
    display: none!important;
  }
  .mobile-dropdown {
    margin-left: 0rem!important;
  }
  .graphtronauts-link {
    color: var(--hr);
    z-index: 999;
    position: absolute;
    display: block;
    text-align: center!important;
    margin: auto!important;
  }
  .graphtronauts-link:link {
    color: var(--hr);
    cursor: pointer;
  }
  .graphtronauts-link:visited {
    color: var(--hr);
    cursor: pointer;
  }
  .graphtronauts-link.active, graphtronauts-link:focus {
    border-bottom: 0rem solid var(--hr);;
    cursor: pointer;
  }
  .graphtronauts-link:hover {
    color: var(--hr);
    transform: scale(1.025);
    cursor: pointer;
  }
  .chatbot-footer {
    display:none;
  }
  #root > div > div > div > div > div.react-chatbot-kit-chat-message-container > div:nth-child(3) > div.react-chatbot-kit-chat-bot-message > span > a > div {
    margin-left: -5.5px!important;
  }
  .chatbot-link-logo{
    margin-top: 6rem;
    z-index: 9998;
    position: fixed;
    max-width: 4rem;
    background: white;
    -moz-border-radius: 50px;
    -webkit-border-radius: 50px;
    border-radius: 50px;
    padding: -1rem;
    right: 2%;
    border: 3px var(--secondary-purple) solid;
    box-shadow: 0 0 0 var(--hover-purple);
    animation: pulse 2s infinite;
  }
  .advocate-link-logo{
    margin-top: 13rem;
    z-index: 9998;
    position: fixed;
    max-width: 4rem;
    background: white;
    -moz-border-radius: 50px;
    -webkit-border-radius: 50px;
    border-radius: 50px;
    padding: -1rem;
    right: 2%;
    border: 3px var(--secondary-purple) solid;
    box-shadow: 0 0 0 var(--hover-purple);
    animation: pulse 2s infinite;
  }
  @-webkit-keyframes pulse {
    0% {
      -webkit-box-shadow: 0 0 0 0  var(--text-purple);
    }
    70% {
        -webkit-box-shadow: 0 0 0 15px rgba(244,157,22, 0);
    }
    100% {
        -webkit-box-shadow: 0 0 0 0 rgba(244,157,22, 0);
    }
  }
  @keyframes pulse {
    0% {
      -moz-box-shadow: 0 0 0 0  var(--text-purple);
      box-shadow: 0 0 0 0  var(--text-purple);
    }
    70% {
        -moz-box-shadow: 0 0 0 15px rgba(244,157,22, 0);
        box-shadow: 0 0 0 15px rgba(244,157,22, 0);
    }
    100% {
        -moz-box-shadow: 0 0 0 0 rgba(244,157,22, 0);
        box-shadow: 0 0 0 0 rgba(244,157,22, 0);
    }
  }
  .chatbot-link-export{
    margin-top: 6rem;
    z-index: 9998;
    position: fixed;
    max-width: 5rem;
    background: white;
    -moz-border-radius: 50px;
    -webkit-border-radius: 50px;
    border-radius: 50px;
    padding: -1rem;
    right: 40%;
    border: 3px var(--secondary-purple) solid;
    box-shadow: 0 0 0 var(--hover-purple);
    animation: pulse 2s infinite;
  }
   /* Chatbot */
.feedback-text {
  margin-top: 2rem;
}
.feedback-button {
  border: 2px var(--primary-purple) solid!important;
  background-color: var(--text-purple)!important;
  color: var(--primary-purple)!important;
  padding: auto!important;
  margin: auto!important;
}

  .feedback-button:link {
    cursor: pointer;
  }
  .feedback-button:visited {
    cursor: pointer;
  }
  .feedback-button.active,
  .feedback-button:focus {
    cursor: pointer;
  }
  .feedback-button:hover {
    background-color: var(--background)!important;
    color: var(--text-purple)!important;
    transform: scale(1.015);
    cursor: pointer;
  }
  .feedback-button-text {
    font-size: 1.5rem;
  }

.react-chatbot-kit-chat-inner-container {
  background-color: var(--text-purple)!important;
}

.react-chatbot-kit-chat-header {
  font-size: 1.1rem!important;
  color: var(--text-purple)!important;
  background-color: var(--primary-purple)!important;
  padding: 0rem!important;
}

.react-chatbot-kit-chat-input-container {
  background-color:var(--secondary-purple)!important;
  border: 1px solid var(--primary-purple)!important;
}

.react-chatbot-kit-chat-btn-send {
  background-color:var(--primary-purple)!important;
}

.react-chatbot-kit-chat-btn-send-icon:hover {
transform: scale(1.25);
}

.react-chatbot-kit-chat-bot-message-container {
  width: 100%;
}

.react-chatbot-kit-chat-bot-avatar-container {
}

.react-chatbot-kit-chat-bot-avatar-icon {
}

.react-chatbot-kit-chat-bot-avatar-letter {
  display: none;
}
.react-chatbot-kit-chat-container {
  width: 98%!important;
  text-align: center;
  margin: auto;
}

.react-chatbot-kit-chat-bot-avatar-container {
  width: 3.5rem!important;
  height: 3.5rem!important;
  background-color: white!important;
  border: 2px solid var(--primary-purple);
  background-image: url('./GRTDataHubChatBot.png')!important;
  background-repeat: no-repeat!important;
  background-size: 3rem;
  background-position-x: 50%;
  background-position-y: 20%;
}

.react-chatbot-kit-chat-bot-message {
  text-align: left!important;
  width: 100%!important;
  color: var(--text-purple)!important;
  background-color: #0C0B23!important;
  font-size: 1rem!important;

}

.react-chatbot-kit-chat-bot-message-arrow {
  color: #0C0B23!important;
  border-right: 8px solid #0C0B23!important;
}
  .indexer-table-header {
    text-align: center;
    margin-left: 1rem;
  }
  .indexer-table {
    text-align: center;
  }
  .chatbot-data {
    display: inline!important;
  }
  .telegramActiveDelegationCount {
    max-width: 92%;
    margin: auto; 
    text-align: center;
    margin-top: 0.5%;
    }
    .top-telegram {
      margin-top: 0.25rem;
    }
    .chatbot-top {
      margin-top: 5rem;
    }
    .chatbot-page-container {
      background: var(--background);
      color: var(--text-purple);
      width: 100%;
      margin: auto;
      margin-top: 120px;
      margin-bottom: 20px;
      padding-left: 10px;
      padding-right: 10px;
      text-align: center;
    }
    .chatbot-text-container {
      background: whitesmoke;
      border-radius: 1rem;
      color: var(--secondary-purple);
      margin: auto!important;
      padding-bottom: 10px;
      padding-top: 10px;
      text-align: center!important;
    }
    .chatbot-page-title {
      font-size: 1.25rem;
      font-weight: 600;
      color: var(--secondary-purple);
      padding-bottom: 0.25rem;
      padding-left: 5px;
      padding-right: 5px;
    }
    .chatbot-page-subtitle {
      font-size: 1.75rem;
      padding-left: 5px;
      padding-right: 5px;
    }
    .chatbot-page-text {
      font-size: 1.25rem;
      margin-bottom: 1rem;
      margin-top: 1rem;
      padding-left: 10px;
      padding-right: 10px;
    }
    /* chatbot end */
  .dataHub-logos {
   display: none;
  }
  .monitor-config {
    display: none!important;
  }
  .hide-telegram {
    display: none!important;
  }
  body > div:nth-child(1) > button {
    display: none!important;
  }
  .navbar {
    background-color: var(--primary-purple);
    color: var(--text-purple);
    z-index: 9999;
    position: absolute;
  }
  .new-link {
    margin-right: 2rem;
    margin-top: -0.25rem;
  }
  .mobile-nav-link {
    padding-right: 2rem!important;
  }
  .mobile-nav-pages {
    color: var(--hover-purple)!important;
    font-size: 1.5rem!important;
  }
  .mobile-nav-dropdown {
    margin-right: 2rem!important;
  }
  .mobile-nav-pages:link {
    cursor: pointer;
  }
  .mobile-nav-pages:visited {
    cursor: pointer;
  }
  .mobile-nav-pages.active,
  .mobile-nav-pages:focus {
    cursor: pointer;
    color: var(--text-purple)!important;
  }
  .mobile-nav-pages:hover {
    color: var(--text-purple)!important;
    cursor: pointer;
  }
  
  .offcanvas {
    width: 80%!important;
    background-color: var(--background)!important;
  }
  .offcanvas-body {
    width: 100%!important;
    margin-top: -3rem!important;
    opacity: 0.9;
  }
  .offcanvas-button {
    margin-right: 2rem;
    border: none!important;
  }
  .indexer-title {
   display: none;
  }
  .indexer-subtitle {
    display: none;
  }
  .indexer {
    display: none;
  }
  .curator {
    display: none;
  }

  .c-map {
    display: none;
    }

    .d-map {
      display: none;
    }

  .rewards-per-hour {
    display: none;
  }

  .indexers {
    display: none;
  }
  .indexers:link{
    display: none;
  }
  .indexers:visited {
    display: none;
  }
  .indexers.active,
  .indexers:focus {
    display: none;
  }
  .indexers:hover {
    display: none;
  }
  .indexer-link {
    display: none;
  }
  .indexer-link:link {
    display: none;
  }
  .indexer-link:visited {
    display: none;
  }
  .indexer-link.active,
  .indexer-link:focus {
    display: none;
  }
  .indexer-link:hover {
    display: none;
  }

  .cookie-page-text {
    padding-top: 1rem;
    font-size: 1.25rem;
    margin-bottom: 1rem;
    padding-left: 20px;
    padding-right: 20px;
  }
  .cookies-video {
    display: none;
  }
  .cookie-button {
    display: none;
  }
  body > div:nth-child(1) > button {
    display: none;
  }
  body > div:nth-child(1) > button:hover {
    display: none;
  }
  .extra-data2 {
    font-size: 1.25rem;
    text-align: center;
    color: var(--text-purple);
   
  }
  .question-bank-container {
    background: var(--background);
    color: var(--text-purple);
    width: 100%;
    margin: auto;
    margin-top: 120px;
    margin-bottom: 20px;
    padding-left: 10px;
    padding-right: 10px;
    text-align: center;
  }
  .about-page-container {
    background: var(--background);
    color: var(--text-purple);
    width: 100%;
    margin: auto;
    margin-top: 150px;
    margin-bottom: 50px;
    padding-left: 50px;
    padding-right: 50px;
    text-align: center;
  }
  .QB-text-container {
    background: white;
    border-radius: 1rem;
    padding: 10px;
    color: var(--secondary-purple);
  }
  .text-container {
    display: none;
  }
  .QB-about-hr {
    border: 1.5px solid var(--background);
    color: var(--background);
    border-radius: 5px;
    width: 90%;
    margin: auto;
    margin-bottom: 1rem;
    box-shadow: 0 0 5px 1px var(--background);
  }
  .QB-about-page-title {
    font-size: 1.5rem;
    font-weight: 600;
    color: var(--secondary-purple);
    padding-bottom: 1.5rem;
  }
  .QB-about-page-subtitle {
    font-size: 1.75rem;
  }
  .QB-about-page-text {
    font-size: 1.rem;
    margin-bottom: 1rem;
  }
  .about-page-title {
    display: none;
  }
  .about-page-subtitle {
    display: none;
  }
  .about-page-text {
    display: none;
  }
  .graphiql-link {
    color: var(--secondary-purple);
  }
  .graphiql-link:link {
    color: var(--secondary-purple);
    cursor: pointer;
  }
  .graphiql-link:visited {
    color: var(--secondary-purple);
    cursor: pointer;
  }
  .graphiql-link.active,
  .graphiql-link:focus {
    border-bottom: 0rem solid var(--hover-purple);
    cursor: pointer;
  }
  .graphiql-link:hover {
    color: var(--primary-purple);
    transform: scale(1.05);
    cursor: pointer;
  }
  .cookies-policy-container {
    background: white;
    width: 90%;
    padding-bottom: 1rem;
    margin: auto;
    border-radius: 2rem;
    margin-top: 150px;
    margin-bottom: 50px;
  }

  .privacy-policy-container {
    background: white;
    width: 90%;
    padding-bottom: 1rem;
    margin: auto;
    border-radius: 2rem;
    margin-top: 150px;
    margin-bottom: 50px;
  }
  .privacy-policy {
    margin-top: 110px;
    font-weight: 700;
    font-size: 1.1rem;
    text-align: center;
    background-color: var(--background);
    color: var(--secondary-purple);
  }
  .privacy-policy-link,
  .about {
    padding-left: 1rem;
    padding-left: 1rem;
    color: grey;
  }
  .privacy-policy-link:link,
  .about:link {
    color: grey;
    cursor: pointer;
  }
  .privacy-policy-link:visited,
  .about:visited {
    color: grey;
    cursor: pointer;
  }
  .privacy-policy-link.active,
  .privacy-policy-link:focus,
  .about.active,
  .about:focus {
    border-bottom: 0rem solid var(--hover-purple);
    cursor: pointer;
  }
  .privacy-policy-link:hover,
  .about:hover {
    color: var(--text-purple);
    transform: scale(1.05);
    cursor: pointer;
  }
  .subscribeNote {
    display: none;
  }
  /* loader new start  */
  .loader:empty {
    position: absolute;
    top: calc(50% + 15.5rem);
    left: calc(50% + 2em);
    width: 4em;
    height: 4em;
    border: 0.35em solid white;
    border-left: 0.35em solid #10034B;
    border-radius: 50%;
    animation: load8 0.8s infinite linear;
  }
  .loadingTitle {
    position: absolute;
    top: 27rem;
    left: calc(50% - 3em);
    font-size: 2rem;
    color: #10034B;
    -webkit-box-reflect:below 0px linear-gradient(transparent, #0005);
  }
  .loaderLogo {
    position: absolute;
    top: 14rem;
    left: calc(50% - 8em);
    width: 15em;
    height: 12em;
  }
  @keyframes load8 {
    0% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(360deg);
    }
  }
  /* loader new end  */

  /* New logo start */
  .logo {
    width: 6.5rem;
    height: 5.5rem;
    margin-left: -0.5rem;
    margin-top: -0.5rem;
    margin-right: -0.5rem;
  }
  .data-logo {
    max-width: 1.25rem !important;
    height: 1.25rem !important;
    position: absolute;
  }
  .brand-text {
    color: var(--text-purple);
    display: inline;
    margin-left: -0.5rem;
    letter-spacing: 0.0375rem;
    font-size: 1.25rem;
    margin-right: -0.25rem;
  }
   /* New logo end */
  .header-text {
    font-weight: 400;
    font-size: 1.5rem;
    padding-top: 1rem;
    text-align: center;
    margin-top: 105px;
    color: var(--text-purple);
 
  }
  .header-sub-text {
    font-weight: 400;
    font-size: 1rem;
    text-align: center!important;
    width: 90%;
    margin: auto!important;
    color: var(--text-purple);
    display: block;
    text-align: center;
  
  }
  .navbar-toggler {
    display: none;
  }
  nav a.TGN {
    display: none;
  }
  .navbar-nav > li > a.TGN:link {
    display: none;
  }
  .navbar-nav > li > a.TGN:visited {
    display: none;
  }
  .navbar-nav > li > a.TGN.active,
  .navbar-nav > li > a.TGN:focus {
    display: none;
  }
  .navbar-nav > li > a.TGN:hover {
    display: none;
  }
  nav a.nav-link {
    font-weight: 600;
    font-size: 1.15rem;
    color: var(--text-purple);
  }
  .navbar-nav > li > a {
    border-bottom: 0.01875rem solid var(--transparent);
    padding: 0;
  }
  .navbar-nav > li > a:link {
    color: var(--hover-purple);
  }
  .navbar-nav > li > a:visited {
    color: var(--text-purple);
  }
  .navbar-nav > li > a.active,
  .navbar-nav > li > a:focus {
    border-bottom: 0.01875rem solid var(--hover-purple);
    padding: 0;
    transition: all 0.5s ease-in-out;
  }
  .navbar-nav > li > a:hover {
    border-bottom: 0.01875rem solid var(--hover-purple);
    padding: 0;
    color: var(--hover-purple);
    transform: scale(1.05);
  }
  .line {
    margin-left: 1rem;
  }
  .links {
    text-align: right;
    padding-right: 0;
    margin: auto;
    padding: auto;
  }
  .dropdown {
    margin-left: auto;
    padding: auto;
  }
  .bar {
    margin-bottom: 0.25rem;
    margin-right: 2rem;
  }
  .line {
    margin-bottom: 0.25rem;
    margin-right: 2rem;
  }
  .about {
    margin-left: auto;
    margin-right: 0.8rem;
  }
  .rotate-position,
  .about-rotate-position {
    margin-top: 6rem;
    padding-top: 2rem;
  }
  .phone,
  .about-phone {
    height: 50px;
    padding-top: 10rem;
    width: 100px;
    border: 3px solid white;
    border-radius: 10px;
    animation: rotate 1.5s ease-in-out infinite alternate;
    text-align: center;
    margin: auto;
  }
  .message,
  .about-message {
    color: var(--text-purple);
    text-shadow: 2.5px 2.5px 4px var(--hover-purple);
    font-weight: 400;
    font-size: 1.25rem;
    padding-top: 1rem;
    padding-left: 1rem;
    padding-right: 1rem;
    text-align: center;
    margin: auto;
  }
  .home-message {
    color: var(--text-purple);
    text-shadow: 2.5px 2.5px 4px var(--hover-purple);
    font-weight: 400;
    font-size: 1.25rem;
    padding-top: 3rem;
    padding-bottom: 2rem;
    padding-left: 1rem;
    padding-right: 1rem;
    text-align: center;
    margin: auto;
  }
  @keyframes rotate {
    0% {
      transform: rotate(0deg);
    }
    50% {
      transform: rotate(-90deg);
    }
    100% {
      transform: rotate(-90deg);
    }
  }
  .menu-icon {
    color: var(--secondary-purple);
  }
  .main-info {
    background-image: url("TopBackground.svg");
    background-repeat: no-repeat;
    background-size: 100% auto;
    width: 100%;
    height: 100%;
    margin-top: 90px;
  }
  .community-links {
    text-align: center;
    padding-top: 4rem;
    position: absolute;
    z-index: 999;
    left: 18%;
  }
  .telegram-icon,
  .twitter-icon,
  .reddit-icon,
  .youtube-icon {
    display: inline;
    padding: 1rem;
    transition: all 0.5s ease-in-out;
    z-index: 999;
  }
  .icon:hover {
    color: var(--hover-purple);
    transform: scale(1.15);
  }
  .icon-youtube:hover {
    color: var(--text-purple);
    transform: scale(1.1);
  }
    .news-title {
      display: none!important;
    }
  .newsGraph1,
  .newsGraph2,
  .newsGraph3,
  .newsGraph4 {
    display: none;
  }
  .youtube {
    z-index: 999;
    display: inline;
    position: absolute;
    text-align: center;
    color: var(--text-purple);
  }
  span.anchor {
    display: block;
    visibility: hidden;
    position: relative;
    padding-top: 17.5%;
  }
  .activeDelegators,
  .holders,
  .curatorCount,
  .subgraphCount {
    display: none;
  }
  .video {
    display: none;
  }
  .g-ytsubscribe {
    display: none;
  }
  .newsGraph {
    display: none;
  }

  .center {
    margin: auto;
  }
  .chartImg6 {
    text-align: center;
    padding-top: 1rem;
    width: 100%;
  }
  .reveal {
    position: relative;
    transform: translateY(50px);
    opacity: 0;
    transition: 0.75s all ease;
  }
  .reveal.active {
    transform: translateY(0);
    opacity: 1;
  }
  .email {
    color: var(--hover-purple);
    font-weight: 500;
  }
  .footerLink {
    color: var(--hover-purple);
  }
  .footerLink:link {
    color: var(--hover-purple);
    cursor: pointer;
  }
  .footerLink:visited {
    color: var(--hover-purple);
    cursor: pointer;
  }
  .footerLink.active,
  .footerLink:focus {
    border-bottom: 0rem solid var(--hover-purple);
    cursor: pointer;
  }
  .footerLink:hover {
    color: var(--text-purple);
    transform: scale(1.05);
    cursor: pointer;
  }
  .footer {
    margin-bottom: -1rem;
    padding: 1rem;
    font-weight: 700;
    margin-top: 1rem;
    font-size: 1rem;
    text-align: center;
    background-color: var(--primary-purple);
    color: var(--secondary-purple);
  }
  .tsparticles {
    display: none;
  }
}
