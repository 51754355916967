.documentation-finder {
  max-width: 95%;
  margin: 0 auto;
  margin-top: 40px;
  margin-bottom: 40px;
  padding: 30px;
  background-color: #f9f9f9;
  border: 1px solid #ddd;
  border-radius: 8px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
    text-align: center;
}

/* Styles for the h1 heading */
h1 {
  text-align: center;
  margin-bottom: 20px;
  color: #333;
}

/* Styles for the input */
input[type="text"] {
  width: 30%; 
  padding: 10px;
  font-size: 16px;
  border: 1px solid #ccc;
  border-radius: 4px;
  box-shadow: none;
  text-align: center; /* Center the text within the input */
}

/* Styles for the suggestions list */
ul.suggestions-list {
  list-style-type: none;
  padding: 0;
  margin: 0;
  border: 1px solid #ccc;
  border-top: none;
  position: absolute;
  background-color: #fff;
  box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.1);
  width: 100%;
}

ul.suggestions-list li {
  padding: 8px;
  cursor: pointer;
}

ul.suggestions-list li:hover {
  background-color: #f2f2f2;
}

/* Styles for the filtered item list */
ul {
  list-style-type: none;
  padding: 0;
  margin-top: 20px;
}

ul li {
  padding: 8px;
  border-bottom: 1px solid #ccc;
}

ul li:last-child {
  border-bottom: none;
}
