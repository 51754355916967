.central {
  align-items: center;
  justify-content: center;
  display: flex;
  background-color: #f9f9f9;  
}


input[type="text"] {
  width: 30%;
  padding: 10px;
  font-size: 16px;
  border: 1px solid #ccc;
  border-radius: 4px;
  box-shadow: none;
  transition: border-color 0.2s ease-in-out;
}

input[type="text"]:hover {
  border-color: #888;
}
