/* Styles for the container div */
.container {
  background-color: #f9f9f9;
  border: 1px solid #ddd;
  border-radius: 8px;
  padding: 20px;
  margin-top: 20px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

/* Styles for the h2 heading */
h2 {
  color: #333;
  margin-bottom: 10px;
}

/* Styles for the description paragraph */
p {
  color: #666;
}

